import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { format, getDay } from 'date-fns';
import {
  createDateByWeekday,
  getWeekdayIndex,
  weekdays,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-coach-dashboard-side-menu',
  template: `
    <div
      class="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div *ngIf="isSlideOver" class="fixed inset-0"></div>
      <div
        @translateX
        *ngIf="isSlideOver"
        (click)="toggleSlideOver()"
        class="fixed inset-0 overflow-hidden"
      >
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 top-[4.05rem] flex max-w-full w-[16rem] pl-10"
          >
            <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
            <div
              @translateX
              *ngIf="isSlideOver"
              class="pointer-events-auto w-screen max-w-md"
            >
              <div class="flex h-full flex-col  bg-white py-6 shadow-xl">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2
                      class="text-base font-semibold leading-6 text-gray-900"
                      id="slide-over-title"
                    >
                      Check Ins Overview
                    </h2>
                    <div class=" flex h-7 items-center -mr-4">
                      <button
                        type="button"
                        (click)="toggleSlideOver()"
                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                      >
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative mt-6 flex-1 px-4 sm:px-6">
                  <ul role="list" class="space-y-3">
                    <li
                      class="overflow-hidden cursor-pointer hover:bg-gray-200 hover:ring-2 hover:ring-gray-600 bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
                      *ngFor="let day of weekdays"
                      (click)="onSelectWeekday(day)"
                      [ngClass]="{
                        'ring-2 ring-teal-600 bg-teal-100': checkToday(day)
                      }"
                    >
                      {{ day }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 0, transform: 'translateX(100%)' })
        ),
      ]),
    ]),
  ],
})
export class CoachDashboardSideMenuComponent implements OnInit {
  ngOnInit(): void {
    // this.selectedDay.emit(this.selectedWeekday);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() isSlideOver!: boolean;
  @Output() changeSlideover = new EventEmitter<boolean>();

  @Output() selectedDay = new EventEmitter<Date>();

  weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  today = new Date();
  selectedWeekday = this.today;
  onSelectWeekday(weekday: string) {
    this.selectedWeekday = this.createDateByWD(weekday);

    this.selectedDay.emit(this.selectedWeekday);
    this.toggleSlideOver();
  }

  toggleSlideOver() {
    this.isSlideOver = !this.isSlideOver;
    this.changeSlideover.emit(this.isSlideOver);
    this.cdr.detectChanges();
  }

  checkToday(day: string): boolean {
    if (
      day.toLowerCase() ===
      this.weekdays[
        this.getWDIndex(format(this.selectedWeekday, 'EEEE'))
      ].toLowerCase()
    ) {
      return true;
    }

    return false;
  }

  createDateByWD(weekday: string) {
    return createDateByWeekday(weekday);
  }

  getWDIndex(weekday: string): number {
    return getWeekdayIndex(weekday);
  }
}
