import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, first, map, of, switchMap, take } from 'rxjs';
import { AppUser, UserRole } from '../core/thecoach';

@Injectable({
  providedIn: 'root',
})
export class VerifyGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log('starting verify-email guard');
    return this.authService.appUser$.pipe(
      map((appUser) => {
        console.log('email-guard', appUser);
        if (!appUser) {
          // User not logged in, redirect to login page
          return this.router.parseUrl('/login');
        }

        if (!appUser.emailVerified) {
          console.log('allowing access to verified');
          // If email is not verified, allow access to verify-email
          return true;
        } else {
          // If email is verified, redirect to appropriate dashboard
          switch (appUser.role as string) {
            case 'Client':
              return this.router.parseUrl('/dashboard');
            case 'Coach':
              return this.router.parseUrl('/coach-dashboard');
            case 'Admin':
              return this.router.parseUrl('/admin/dashboard');
            default:
              return this.router.parseUrl('/dashboard');
          }
        }
      }),
    );
  }
}
