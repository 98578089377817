import { Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { UserRole } from '../core/thecoach';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminguardGuard {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.appUser$.pipe(
      map((appUser) => {
        if (!appUser || appUser === null) return this.router.parseUrl('/home');

        return (
          appUser?.role === UserRole.Admin
        );
      })
    );
  }
}