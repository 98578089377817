import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserRole } from '../core/thecoach';

@Injectable({
  providedIn: 'root',
})
export class ClientguardGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.appUser$.pipe(
      map((appUser) => {
        if (!appUser || appUser === null) return this.router.parseUrl('/home');

        return (
          appUser?.role === UserRole.Client ||
          appUser?.role === UserRole.Coach ||
          appUser?.role === UserRole.Admin
        );
      })
    );
  }
}
