import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Chart, ChartItem, ChartType, registerables } from 'chart.js';
import { Timestamp } from 'firebase/firestore';
import { WeightEntryData } from 'src/app/core/thecoach';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { format } from 'date-fns';

@Component({
  selector: 'app-mini-line-chart',
  template: `
    <div
      *ngIf="!fullContent; else fullcontent"
      (window:resize)="onResize($event)"
    >
      <canvas id="myChart-{{ position }}" class="relative w-fit h-fit">{{
        weightLogChart
      }}</canvas>
    </div>

    <ng-template #fullcontent>
      <div (window:resize)="onResize($event)" class="w-full">
        <canvas id="myChart-{{ position }}">{{ weightLogChart }}</canvas>
      </div>
    </ng-template>
  `,
  styles: [],
})
export class MiniLineChartComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.createChart();

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['logs'].currentValue &&
      changes['logs'].currentValue.length !==
        changes['logs'].previousValue?.length
    ) {
      if (!this.firstrun) {
        this.createChart();
      }
      this.firstrun = false;
    }
  }

  @Input() logs: WeightEntryData[] = [];
  @Input() position?: number;
  @Input() fullContent?: boolean;

  weightLogChart: ChartItem | undefined;
  lineChartType: ChartType = 'line';

  firstrun = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onResize(event: any) {
    this.createChart();
  }

  createChart() {
    const dates = this.logs.map((log) => format(log.date!, 'dd-MM')).reverse();
    const weights = this.logs.map((log) => log.weight).reverse();

    let minDataValue = Math.min(...(weights as number[])) - 0.5;
    let maxDataValue = Math.max(...(weights as number[])) + 0.5;

    let num = 0;
    if (this.position) {
      num = this.position;
    }

    if (Chart.getChart('myChart-' + num.toString()) != undefined) {
      Chart.getChart('myChart-' + num.toString())!.destroy();
    }

    this.weightLogChart = new Chart('myChart-' + num.toString(), {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Weight',
            data: weights,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.2,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          y: {
            ticks: {
              display: false,
            },
            suggestedMin: minDataValue,
            suggestedMax: maxDataValue,
          },
          x: {
            ticks: {
              display: true,
            },
          },
        },
      },
    });
  }
}
