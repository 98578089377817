import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://cfbb041bcce34eda85d9eecedc1ca874@o4505170931286016.ingest.sentry.io/4505170935218176',

  debug: false,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['dev.thecoach.cloud', 'thecoach.cloud'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    if (
      event.exception?.values?.some((value) => {
        if (value.value?.toLowerCase().includes('auth/')) {
          return true; // Return true to indicate the condition is met
        }
        return false; // Return false for other cases
      })
    ) {
      return null;
    }

    return event;
  },

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
