import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppUser, WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-modal-macros',
  template: `
           <div
                  class="flex w-full "
                  [ngClass]="{
                    'justify-between':
                      selectedWeightEntry && selectedWeightEntry.achivedMacros,
                    'justify-end':
                      selectedWeightEntry && !selectedWeightEntry.achivedMacros
                  }"
                >
                  <div
                    class="mt-2 flex w-full"
                    *ngIf="selectedWeightEntry?.achivedMacros"
                  >
                    <div
                      class="mt-2 ml-4 w-full flex items-center justify-between space-x-3 text-xs"
                    >
                      <app-macrographs
                        class="w-4/5"
                        [user]="user"
                        [weightEntry]="selectedWeightEntry"
                        [updater]="toggleGraphUpdater"
                      ></app-macrographs>
              
             
                      <button
                        type="button"
                       (click)="
                          onDeleteMacros(); $event.stopPropagation()
                        "
                        class="w-fit mr-4 h-fit rounded-md bg-red-600 py-0.5 px-0.5 text-sm font-semibold text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
  
              
                      <button
                        (click)="toggleMacrosModal(); $event.stopPropagation()"
                        type="button"
                        class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <img
                          class="w-6 h-6 invert"
                          src="../../assets/icons/macros-icon-V4-checked.png"
                        />
                      </button>
                    </div>
                  </div>

                  <button
                    *ngIf="selectedWeightEntry.weight && !selectedWeightEntry?.achivedMacros"
                    (click)="toggleMacrosModal(); $event.stopPropagation()"
                    type="button"
                    class="mt-2 w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-6 h-6 invert"
                      src="../../assets/icons/macros-icon-V4.png"
                    />
                  </button>
                </div>
  `,
  styles: [
  ]
})
export class ModalMacrosComponent {
  @Input() selectedWeightEntry!: WeightEntryData;
  @Input() user!: AppUser
  @Output() showMacrosModal: EventEmitter<boolean> = new EventEmitter();
  @Input() toggleGraphUpdater!: boolean;
  @Output() deleteMacrosModal: EventEmitter<boolean> = new EventEmitter();

  toggleMacrosModal() {
    this.showMacrosModal.emit(true);
  }

  onDeleteMacros() {
    this.deleteMacrosModal.emit(true);
  }
}
