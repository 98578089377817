import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomCheckinModel } from 'src/app/core/thecoach';
import { CustomCheckinModelService } from 'src/app/services/custom-checkin-model.service';

@Component({
  selector: 'app-custom-checkin-forms',
  template: `

   <div class="py-4 flex w-full flex-row justify-between items-center">
      <div class="w-10/12 flex flex-col ">
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Model Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            class="block w-6/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Model Name..."
            />
        </div>
</div>
                    <button
                      type="button"
                      [routerLink]="'/coach-custom-checkin-form/new'"
                      class="rounded-md bg-teal-600 whitespace-nowrap h-fit w-fit px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                    New Checkin Form
                    </button>
        </div>

        <div *ngIf=" checkinModels$ | async as checkinModels">

            <div
              *ngFor="
                let model of checkinModels
                let i = index
              "
              class="shadow-md p-4 mb-8 rounded-md bg-white"
            >
              <div class="">
                <div class="flex flex-row justify-between">
                  <h2
                    class="text-md flex flew-row font-bold w-fit whitespace-nowrap"
                  >
                    {{ model.name }}
                  </h2>
                  <div
                    class="text-gray-600  text-xs italic flex flex-row items-center px-4 "
                  >
                    <p class="line-clamp-3">
                      {{ model.description }}
                    </p>
                  </div>
                  <div
                    class="flex flex-row justify-center items-center space-x-2"
                  >
                    <button
                      type="button"
                      class="rounded-md bg-teal-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      (click)="onEditModel(model)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                    <div>
                      <button
                        type="button"
                        class="rounded-md bg-red-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        (click)="onDeleteModel(model)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
      </div>


  `,
  styles: [
  ]
})
export class CustomCheckinFormsComponent {
  searchNameValue: string | undefined
  checkinModels$ = this.customCheckinService.getModels();
  constructor(private customCheckinService: CustomCheckinModelService,
    private router: Router
  ) { }

  onEditModel(model: CustomCheckinModel) {
    this.customCheckinService.setSelectedCheckiModel(model)
    this.router.navigate(['/coach-custom-checkin-form/' + model.id]);
  }
  onDeleteModel(model: CustomCheckinModel) {
    if (model.id) {
      this.customCheckinService.deleteCheckinModel(model.id).then((() => {
        console.log('CheckinModel deleted successfully')
      }))
    }
  }
  onOpenModel(model: CustomCheckinModel) { }


}
