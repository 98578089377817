import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartItem, ChartType, Chart } from 'chart.js';
import { isSameDay } from 'date-fns';
import {
  AppUser,
  CalendarEventData,
  TemplateTrainingDay,
  TrainingLog,
  WeightEntryData,
  convertFormToModel,
  durationFromSessionMaster,
} from 'src/app/core/thecoach';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-calendar-day-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div (click)="toggleModal()" class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full  sm:p-6"
          >
            <div>
              <div class="flex flex-row justify-center items-center">
                <div
                  class="flex h-8 w-8 items-center justify-center rounded-full bg-green-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 stroke-teal-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </div>
                <h3
                  class="text-base font-semibold leading-6 text-gray-900"
                  id="modal-title"
                >
                  {{ inputDate | date: 'dd-MM-yyyy' }}
                </h3>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <div
                  class="flex w-full "
                  [ngClass]="{
                    'justify-between': inputWeightEntry,
                    'justify-end': !inputWeightEntry
                  }"
                >
                  <div
                    class="flex w-full justify-between items-center"
                    *ngIf="inputWeightEntry"
                  >
                    <div class="flex w-4/5">
                      <div class="flex items-center space-x-3 text-xs">
                        <dt class="">
                          <div
                            class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                          >
                            <img
                              src="../../assets/icons/maintain-weight-icon.png"
                            />
                          </div>
                        </dt>
                        <dd>
                          {{ inputWeightEntry.weight | number: '1.1-2' }} kg
                        </dd>
                      </div>
                      <div
                        class="mt-2 flex items-center space-x-3 "
                        *ngIf="inputWeightEntry.comment !== ''"
                      >
                        <dt class="">
                          <div
                            class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                          >
                            <img
                              src="../../assets/icons/comment-bubble-icon.png"
                            />
                          </div>
                        </dt>
                        <dd class="flex text-justify text-xs">
                          <div class="">
                            {{ inputWeightEntry.comment }}
                          </div>
                        </dd>
                      </div>
                    </div>

                    <button
                      type="button"
                      (click)="
                        toggleWeightEntryMenu(); $event.stopPropagation()
                      "
                      class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      <img
                        class="w-6 h-6 invert"
                        src="../../assets/icons/maintain-weight-icon.png"
                      />
                    </button>
                  </div>

                  <button
                    *ngIf="!inputWeightEntry"
                    type="button"
                    (click)="toggleWeightEntryMenu(); $event.stopPropagation()"
                    class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-6 h-6 invert"
                      src="../../assets/icons/body-weight-scales-icon.png"
                    />
                  </button>
                </div>

                <div
                  class="flex w-full "
                  [ngClass]="{
                    'justify-between':
                      inputWeightEntry && inputWeightEntry.achivedMacros,
                    'justify-end':
                      inputWeightEntry && !inputWeightEntry.achivedMacros
                  }"
                >
                  <div
                    class="mt-2 flex w-full"
                    *ngIf="inputWeightEntry?.achivedMacros"
                  >
                    <div
                      class="mt-2 ml-4 w-full flex items-center justify-between space-x-3 text-xs"
                    >
                      <app-macrographs
                        class="w-4/5"
                        [user]="user"
                        [weightEntry]="inputWeightEntry"
                        [updater]="toggleGraphUpdater"
                      ></app-macrographs>
                      <button
                        (click)="toggleMacrosModal(); $event.stopPropagation()"
                        type="button"
                        class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <img
                          class="w-6 h-6 invert"
                          src="../../assets/icons/macros-icon-V4-checked.png"
                        />
                      </button>
                    </div>
                  </div>

                  <button
                    *ngIf="inputWeightEntry && !inputWeightEntry?.achivedMacros"
                    (click)="toggleMacrosModal(); $event.stopPropagation()"
                    type="button"
                    class="mt-2 w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-6 h-6 invert"
                      src="../../assets/icons/macros-icon-V4.png"
                    />
                  </button>
                </div>

                <div
                  class="flex w-full"
                  [ngClass]="{
                    'justify-between':
                      inputWeightEntry && inputWeightEntry.customTrackingLog,
                    'justify-end':
                      inputWeightEntry && !inputWeightEntry.customTrackingLog
                  }"
                >
                  <div
                    class="mt-2 flex w-full"
                    *ngIf="
                      inputWeightEntry && inputWeightEntry.customTrackingLog
                    "
                  >
                    <div
                      class="mt-2 flex w-full justify-between items-center space-x-3 text-xs"
                      *ngIf="inputWeightEntry.customTrackingLog"
                    >
                      <div class="flex space-x-3 flex-row w-4/5">
                        <dt class="mt-0.5 ">
                          <div
                            class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                              />
                            </svg>
                          </div>
                        </dt>
                        <dd class="flex text-justify">
                          <ul class="">
                            <li
                              *ngFor="
                                let cctv of inputWeightEntry.customTrackingLog
                                  .ccts
                              "
                            >
                              {{ cctv.name }} - {{ cctv.value }}
                            </li>
                          </ul>
                        </dd>
                      </div>
                      <button
                        *ngIf="
                          user.ctmId &&
                          inputWeightEntry &&
                          inputWeightEntry.weight &&
                          inputWeightEntry.customTrackingLog
                        "
                        type="button"
                        (click)="
                          toggleCustomsEditMenu(); $event.stopPropagation()
                        "
                        class="w-fit rounded-md h-fit bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <img
                          class="w-6 h-6 invert"
                          src="../../assets/icons/3d-modeling-icon-checked.png"
                        />
                      </button>
                    </div>
                  </div>
                  <button
                    *ngIf="
                      user.ctmId &&
                      inputWeightEntry &&
                      inputWeightEntry.weight &&
                      !inputWeightEntry.customTrackingLog
                    "
                    type="button"
                    (click)="toggleCustomsEditMenu(); $event.stopPropagation()"
                    class="mt-2 w-fit rounded-md h-fit  bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-6 h-6 invert"
                      src="../../assets/icons/3d-modeling-icon.png"
                    />
                  </button>
                </div>
                <div class="mt-2" *ngIf="inputTrainingDay">
                  <div class="text-xs">
                    <div class="mt-2 flex items-start space-x-3 text-xs">
                      <dt class="mt-0.5">
                        <div
                          class="mx-auto flex h-3 w-3 items-start justify-start rounded-full"
                        >
                          <img src="../../assets/icons/dumbbell.png" />
                        </div>
                      </dt>
                      <dd class="flex justify-start flex-col">
                        <p>
                          Trainingday: {{ inputTrainingDay.trainingDayName }}
                        </p>
                        <p>
                          {{ inputTrainingDay.sessionRating }}/10 -
                          {{ getDurationFromSession(inputTrainingDay) }}
                        </p>
                      </dd>
                    </div>
                  </div>
                </div>
                <div
                  class="flex w-full flex-col"
                  [ngClass]="{
                    'justify-between items-center': inputEventsData!.length > 0,
                    'justify-end  items-end': inputEventsData!.length === 0
                  }"
                >
                  <div
                    *ngFor="let inputEventData of inputEventsData"
                    class="mt-2 flex w-full"
                  >
                    <div class="flex w-full items-center">
                      <div
                        class="mt-2 flex w-full justify-between"
                        *ngIf="inputEventsData"
                      >
                        <div
                          class="mt-2 flex items-start space-x-3 text-xs w-4/5"
                        >
                          <dt class="mt-0.5 ">
                            <div
                              class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                                />
                              </svg>
                            </div>
                          </dt>
                          <dd class="flex text-justify text-xs flex-col">
                            <div>{{ inputEventData.title }}</div>
                            <div>{{ inputEventData.description }}</div>
                          </dd>
                        </div>
                      </div>

                      <button
                        type="button"
                        (click)="
                          deleteEvent(inputEventData); $event.stopPropagation()
                        "
                        class="w-fit mr-4 h-fit rounded-md bg-red-600 py-0.5 px-0.5 text-sm font-semibold text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>

                      <button
                        type="button"
                        (click)="
                          newCalendarEventToggle(inputEventData);
                          $event.stopPropagation()
                        "
                        class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <img
                          class="w-6 h-6 invert"
                          src="../../assets/icons/event-checked.png"
                        />
                      </button>
                    </div>
                  </div>
                  <button
                    *ngIf="!checkIfDateIsOnDay(inputDate)"
                    type="button"
                    (click)="newCalendarEventToggle(); $event.stopPropagation()"
                    class="mt-2 w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-6 h-6 invert"
                      src="../../assets/icons/event-unchecked.png"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                type="button"
                (click)="toggleModal()"
                class="inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Go back to Calendar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="showWeightEntryMenu"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-weight-entry
        (onClick)="onClickEvent($event)"
        (onSaveData)="onSaveDataToServer($event)"
        @AnimationTrigger1
        *ngIf="showWeightEntryMenu"
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
        [day]="this.inputDate"
        [weightEntry]="this.inputWeightEntry"
      ></app-weight-entry>
    </div>

    <div
      *ngIf="macrosModalToggle"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-macro-entry-modal
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
        (onSaveData)="onSaveDataToServer($event)"
        (onClick)="toggleMacrosModal($event)"
        [day]="inputDate"
        [weightEntry]="inputWeightEntry"
      >
      </app-macro-entry-modal>
    </div>

    <div
      *ngIf="
        user.ctmId &&
          inputWeightEntry &&
          !inputWeightEntry.customTrackingLog &&
          (this.ctmService.getModelByID(user) | async) as customTrackingModel;
        else editModel
      "
    >
      <app-custom-model-modal
        *ngIf="customModalToggle"
        [ctm]="customTrackingModel"
        (close)="onCloseTrackingModal($event.event, $event.form, user)"
      >
      </app-custom-model-modal>
    </div>

    <ng-template #editModel>
      <app-custom-model-modal
        *ngIf="customModalToggle"
        [ctm]="inputWeightEntry!.customTrackingLog"
        (close)="onCloseTrackingModal($event.event, $event.form, user)"
      >
      </app-custom-model-modal>
    </ng-template>

    <div *ngIf="newCalendarEventToggler">
      <app-calendar-new-entry-modal
        [inputDate]="inputDate"
        [eventEntry]="selectedEventData"
        (close)="newCalendarEventToggle($event)"
        (save)="onSaveCalendarEvent($event)"
      ></app-calendar-new-entry-modal>
    </div>

    <div *ngIf="deleteEventToggler">
      <app-simple-modal
        [title]="'Delete Event entry?'"
        [message]="'Do you want to delete this event?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onDeleteModal($event)"
      ></app-simple-modal>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
  ],
})
export class CalendarDayModalComponent {
  @Input() user!: AppUser;
  @Input() inputDate!: Date;
  @Input() inputWeightEntry!: WeightEntryData | undefined;
  @Input() inputTrainingDay!: TemplateTrainingDay | undefined;
  @Input() inputEventsData!: CalendarEventData[] | undefined;
  @Output() close = new EventEmitter<boolean>();

  isModal = true;
  showWeightEntryMenu = false;
  macrosModalToggle = false;
  toggleGraphUpdater = false;
  customModalToggle = false;
  newCalendarEventToggler = false;
  deleteEventToggler = false;
  updateData = false;
  selectedEventData: CalendarEventData | undefined;

  constructor(
    private weightLogService: WeightlogService,
    protected ctmService: CustomTrackingModelService,
    private calendarService: CalendarService,
  ) {}

  toggleModal() {
    this.close.emit(this.updateData);

    this.isModal = !this.isModal;
  }

  getDurationFromSession(td: TemplateTrainingDay) {
    return durationFromSessionMaster(td);
  }

  checkIfDateIsOnDay(day: Date) {
    if (this.inputEventsData) {
      return this.inputEventsData.find((event) => isSameDay(day, event.start!));
    }

    return false;
  }

  toggleWeightEntryMenu() {
    this.showWeightEntryMenu = !this.showWeightEntryMenu;
  }

  onClickEvent(event: boolean) {
    this.toggleWeightEntryMenu();
  }

  onSaveDataToServer(data: WeightEntryData) {
    this.weightLogService.addDataToLog(this.inputDate, data);

    this.inputWeightEntry = data;
    this.updateGraph();
    if (this.macrosModalToggle) this.toggleMacrosModal();
    if (this.showWeightEntryMenu) this.toggleWeightEntryMenu();
  }

  toggleMacrosModal(event?: boolean) {
    this.macrosModalToggle = !this.macrosModalToggle;
  }

  updateGraph() {
    this.toggleGraphUpdater = !this.toggleGraphUpdater;
  }

  toggleCustomsEditMenu() {
    this.customModalToggle = !this.customModalToggle;
  }

  onCloseTrackingModal(
    event: boolean,
    form: FormGroup | undefined,
    user: AppUser,
  ) {
    this.customModalToggle = !this.customModalToggle;
    if (event) {
      const ctModel = convertFormToModel(form as FormGroup);
      if (this.inputWeightEntry) {
        this.inputWeightEntry.customTrackingLog = ctModel;

        this.weightLogService.addSpecificParametersToLog(
          user.id as string,
          this.inputWeightEntry,
        );
      }
    }
  }

  newCalendarEventToggle(event?: CalendarEventData) {
    this.newCalendarEventToggler = !this.newCalendarEventToggler;
    if (event) this.selectedEventData = event;
  }

  deleteEvent(event: CalendarEventData) {
    if (event) this.selectedEventData = event;
    this.deleteEventToggler = !this.deleteEventToggler;
  }

  onSaveCalendarEvent(event: CalendarEventData) {
    if (event) {
      if (!event.id) {
        this.calendarService.saveNewCalendarEntry(event);
      } else {
        this.calendarService.updateCalendarEntry(event);
      }
      //this.inputEventData = event;
    }
  }

  onDeleteModal(event: boolean) {
    if (event && this.selectedEventData && this.inputEventsData) {
      this.calendarService.deleteCalendarEntry(this.selectedEventData);
      this.inputEventsData = this.removeItemById(
        this.inputEventsData,
        this.selectedEventData.id as string,
      );
      this.updateData = true;
    }
    this.deleteEventToggler = !this.deleteEventToggler;
  }

  removeItemById(
    array: CalendarEventData[],
    idToRemove: string,
  ): CalendarEventData[] {
    return array.filter((item) => item.id !== idToRemove);
  }
}
