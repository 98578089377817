import { SharedService } from './../../services/shared.service';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUser, CheckinStatus, ClientCheckin } from 'src/app/core/thecoach';

@Component({
  selector: 'app-coach-dashboard-client-avatar',
  template: `
    <div>
      <span
        class="relative inline-block hover:scale-125 hover:z-10 transition duration-200  cursor-pointer text-center"
      >
        <img
          class="w-10 h-10 xl:h-14 xl:w-14 aspect-square rounded-full "
          src="{{
            client.imgUrl || '.././assets/images/default-person-image.webp'
          }}"
          alt=""
        />
        <div class="absolute group w-8 h-8 top-3 left-3">
          <span
            class="absolute pointer-events-none text-[0.6rem]  bg-gray-200 text-gray-700 p-1 rounded-md whitespace-nowrap ring-1 ring-gray-700 bottom-[-2.25rem] z-20 opacity-0 group-hover:opacity-75 translate-x-[-50%]"
          >
            <ng-container *ngIf="client.displayName; else noDisplayName">
              {{ client.displayName }}
            </ng-container>
            <ng-template #noDisplayName>
              {{ client.email }}
            </ng-template>
          </span>
        </div>
        <ng-container *ngIf="toggleCheckinStatus">
          <div [ngSwitch]="clientCheckin?.status">
            <span
              *ngSwitchCase="checkinStatus.Sent"
              class="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-yellow-400 ring-2 ring-white"
            ></span>

            <span
              *ngSwitchCase="checkinStatus.Answered"
              class="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-green-400 ring-2 ring-white"
            ></span>

            <span
              *ngSwitchCase="checkinStatus.Closed"
              class="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-green-400 ring-2 ring-white"
            ></span>

            <span
              *ngSwitchDefault
              class="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-red-400 ring-2 ring-white"
            ></span>
          </div>
        </ng-container>
      </span>
    </div>
  `,
  styles: [],
})
export class CoachDashboardClientAvatarComponent implements OnInit {
  ngOnInit(): void {}
  @Input() client!: AppUser;
  @Input() clientCheckin?: ClientCheckin | undefined | null;
  @Input() toggleCheckinStatus!: boolean;

  checkinStatus = CheckinStatus;
}
