import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomCheckinFormType } from 'src/app/core/thecoach';

@Component({
  selector: 'app-custom-checkin-inputs-edit',
  template: `

<div @opacity *ngIf='isModal' class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div  class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div @opacityTranslateY *ngIf='isModal && this.editForm' class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Edit: {{editForm.get('name')?.value}}</h3>
            <div class="mt-2">
<!-- Range Input Form -->
<div>

          <div id="range-input" class="mt-2" *ngIf="editForm.get('type')?.value ===customCheckinFormType.Range">
            <div class="flex items-center justify-center">
              <form
                [formGroup]="editForm"
                (ngSubmit)="save()"
                class="w-full max-w-md flex flex-row"
              >
                <div class="w-full flex flex-col">
                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        for="name"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Name</label
                      >
                      <input
                        type="text"
                        id="name"
                        formControlName="name"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Name"
                      />
                    </div>
                    <div>
                      <label
                        for="min"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Min</label
                      >
                      <input
                        type="number"
                        id="min"
                        formControlName="min"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Min"
                      />
                    </div>
                    <div>
                      <label
                        for="max"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Max</label
                      >
                      <input
                        type="number"
                        id="max"
                        formControlName="max"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Max"
                      />
                    </div>
                    <div>
                      <label
                        for="value"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Default Value</label
                      >
                      <input
                        type="number"
                        id="value"
                        formControlName="value"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Default Value"
                      />
                    </div>
                  </div>

                  <div class="mt-4">
                    <label class="block text-gray-700 text-sm font-medium mb-1"
                      >Step Size</label
                    >
                    <p class="text-sm text-gray-500 mb-2">
                      Which step size do you prefer?
                    </p>
                    <div
                      class="pt-2 flex flex-row w-full justify-between items-center"
                    >
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="1"
                          checked
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">1.0 - Coarse</span>
                      </label>
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="0.5"
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">0.5 - Medium</span>
                      </label>
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="0.1"
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">0.1 - Fine</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <textarea
                      formControlName="description"
                      rows="2"
                      class="border border-gray-300 px-3 py-2 rounded-md mt-4 w-full"
                      placeholder="Description..."
                    ></textarea>
                  </div>
                </div>
                <div class="pl-4 pt-2 w-fit">
                  <button
                    type="submit"
                    [disabled]="!editForm.valid"
                    class="mt-4 bg-teal-500 text-white py-2 disabled:opacity-50 px-4 rounded-md flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-4 h-4 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- Text Input -->
                    <div
            id="text-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="editForm.get('type')?.value === customCheckinFormType.Text"
          >
            <form
              [formGroup]="editForm"
              (ngSubmit)="save()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="shortName"
                    type="text"
                    placeholder="Short Text Input"
                    formControlName="name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!editForm.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 flex disabled:opacity-50 pr-2 flex-row text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>
<!-- number input -->
          <div
            id="number-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="editForm.get('type')?.value === customCheckinFormType.Number"
          >
            <form
              [formGroup]="editForm"
              (ngSubmit)="save()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="number"
                    type="text"
                    formControlName="name"
                    placeholder="Name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <input
                    id="defaultValue"
                    type="number"
                    placeholder="Enter a default value..."
                    formControlName="defaultValue"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!editForm.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 flex disabled:opacity-50 flex-row pr-2 text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>
            </div>

              <div class="mt-4">
          <button type="button" (click)="cancel()" class="mt-3 inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-yellow-700 sm:col-start-1 sm:mt-0">Cancel</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

  `,
  styles: [
  ],
   animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class CustomCheckinInputsEditComponent {
 isModal = true;
@Input() editForm!: FormGroup;
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  customCheckinFormType = CustomCheckinFormType;

  constructor() { }

  toggleModal(){
    this.isModal = !this.isModal;
  }

  cancel(){
    this.onCancel.emit()
    this.toggleModal()
  }

  save(){
  this.onSave.emit();
    this.toggleModal()
  }
}
