import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarEventData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-calendar-multievent-modal',
  template: `
<div @opacity *ngIf="isModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div (click)="toggleModal()" class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div @opacityTranslateY *ngIf="isModal" class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <div>
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-green-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
              </svg>

          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Select Eventtype</h3>
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{{inputStart| date: 'dd-MM-yyyy'}} - {{inputEnd | date: 'dd-MM-yyyy'}}</h3>
            <div class="mt-2">
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 flex w-full flex-col px-4">
              <div class="flex w-full flex-row items-center">

              <button (click)='onNewMultidayEvent()' type="button" class="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Event</button>

              <p class="ml-2 text-xs text-gray-500 italic">Holiday / Sickness / Event</p>
              </div>
        
            <div class="flex flex-row w-full mt-6 items-center">


              <button (click)='onNewMultidaySpec()' type="button" class="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Spec.</button> 
                   <p class="ml-2 text-xs italic text-gray-500">Specific Message, Macros or Trainingday</p>
              </div>

            <div class="flex mt-6 justify-center w-full items-center">

              <button (click)='toggleModal()' type="button" class="rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">Cancel</button> 
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
  `,
  styles: [
  ],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class CalendarMultieventModalComponent {
  isModal = true;
  @Output() triggerNewCalEvent = new EventEmitter<boolean>();
  @Output() triggerNewSpecEvent = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();
  @Input() inputEnd?: Date;
  @Input() inputStart!: Date;

  constructor() { }

  toggleModal() {
    this.isModal = !this.isModal;
    this.onClose.emit(true);
  }

  onNewMultidayEvent() {
    this.triggerNewCalEvent.emit(true);
    this.isModal = !this.isModal;
  }
  onNewMultidaySpec() {
    this.triggerNewSpecEvent.emit(true);
    this.isModal = !this.isModal;
  }
}
