import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { contactMessage } from 'src/app/core/thecoach';
import { EmailMessagingService } from 'src/app/services/email-messaging.service';

@Component({
  selector: 'app-contact',
  template: `
    <!--
  This example requires some changes to your config:
  
  
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  
-->
    <div class="relative isolate bg-white">
      <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div
          class="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48"
        >
          <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div
              class="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2"
            >
              <svg
                class="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width="200"
                    height="200"
                    x="100%"
                    y="-1"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="white"
                />
                <svg x="100%" y="-1" class="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" stroke-width="0" />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                />
              </svg>
            </div>
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">
              Get in touch with us!
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-600 italic">
              Get in touch with us to explore new opportunities, ask questions,
              or discuss how we can help you. We're here to assist you, and you
              can reach us via email. Alternatively, you can use the contact
              form on the side to send us a message directly, and we'll get back
              to you as soon as possible. We value your feedback and look
              forward to connecting with you!
            </p>
            <dl class="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">Email</span>
                  <svg
                    class="h-7 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </dt>
                <dd>
                  <a
                    class="hover:text-gray-900"
                    href="mailto:office@thecoach.cloud"
                    >office@thecoach.cloud</a
                  >
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form
          [formGroup]="contactForm"
          (ngSubmit)="onSubmit()"
          class="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
        >
          <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  for="first-name"
                  class="block text-sm font-semibold leading-6 text-gray-900"
                  >First name</label
                >
                <div class="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    formControlName="firstName"
                    id="first-name"
                    autocomplete="given-name"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  for="last-name"
                  class="block text-sm font-semibold leading-6 text-gray-900"
                  >Last name</label
                >
                <div class="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    formControlName="lastName"
                    id="last-name"
                    autocomplete="family-name"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="email"
                  class="block text-sm font-semibold leading-6 text-gray-900"
                  >Email</label
                >
                <div class="mt-2.5">
                  <input
                    type="email"
                    formControlName="email"
                    name="email"
                    id="email"
                    autocomplete="email"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="phone-number"
                  class="block text-sm font-semibold leading-6 text-gray-900"
                  >Phone number</label
                >
                <div class="mt-2.5">
                  <input
                    type="tel"
                    formControlName="phoneNumber"
                    name="phone-number"
                    id="phone-number"
                    autocomplete="tel"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="message"
                  class="block text-sm font-semibold leading-6 text-gray-900"
                  >Message</label
                >
                <div class="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    formControlName="message"
                    rows="4"
                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="mt-8 flex justify-end">
              <button
                type="submit"
                [disabled]="!contactForm.valid"
                class="rounded-md bg-teal-600 px-3.5 disabled:opacity-25 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <app-notification
      *ngIf="messageSentToggle"
      [messageHeader]="'Your Message has been added!'"
      [messageBody]="'Thank you for contacting us!'"
      (onbuttonClick)="dismiss()"
    ></app-notification>
  `,
  styles: [],
})
export class ContactComponent {
  messageSentToggle = false;

  constructor(
    private fb: FormBuilder,
    private emailService: EmailMessagingService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  contactForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
    message: ['', [Validators.required]],
  });

  onSubmit() {
    // console.log(this.contactForm.value);

    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        if (!token) return;

        const msg: contactMessage = {
          firstName: this.contactForm.get('firstName')?.value as string,
          lastName: this.contactForm.get('lastName')?.value as string,
          email: this.contactForm.get('email')?.value as string,
          phoneNumber: this.contactForm.get('phoneNumber')?.value as string,
          message: this.contactForm.get('message')?.value as string,
        };

        this.emailService.saveContactMessage(msg).then(() => {
          this.dismiss();
          this.contactForm.reset();
        });
      });
  }

  dismiss() {
    this.messageSentToggle = !this.messageSentToggle;
  }
}
