import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-featurelist',
  template: `
 <div class="bg-white">
  <div class="mx-auto grid max-w-2xl grid-cols-1 items-center py-10 gap-x-8 gap-y-16 px-4  sm:px-6  lg:max-w-7xl lg:grid-cols-2 lg:px-8">
    <div>
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Minimalistic Dashboard</h2>
      <p class="mt-4 text-gray-500 text-justify">Simplify Your Journey: Our Minimalistic Customer Dashboard Puts You in Control. Track Weight, Daily Macros, and Custom Parameters Effortlessly, and Watch Your Progress Come to Life with Interactive Graphs.</p>

      <dl class="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Simply Weightracking</dt>
          <dd class="mt-2 text-sm text-gray-500">Effortless Weight Tracking: Simplify Your Progress Monitoring with Our User-Friendly Weightracking Feature.</dd>
        </div>
        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Custom Tracking Parameters</dt>
          <dd class="mt-2 text-sm text-gray-500">Tailored Progress Monitoring: Customize Your Tracking with Our Dynamic Custom Parameters Feature.</dd>
        </div>
        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Checkin & Macros</dt>
          <dd class="mt-2 text-sm text-gray-500">Empowering Your Journey: Never forget your Checkin and Macros for Enhanced Coaching and Progress Monitoring.</dd>
        </div>
        <div class="border-t border-gray-200 pt-4">
          <dt class="font-medium text-gray-900">Visualize Progress</dt>
          <dd class="mt-2 text-sm text-gray-500">See Your Success Unfold: Visualize Progress and Achievements Like Never Before with Our Powerful Feature.</dd>
        </div>
      </dl>
    </div>
    <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
      <img src="./assets/images/ClientView/feature-01.png"  class="rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
      <img src="./assets/images/ClientView/feature-02.png"  class="rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
      <img src="./assets/images/ClientView/feature-03.png"  class="rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
      <img src="./assets/images/ClientView/feature-04.png"  class="rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
    </div>
  </div>
</div>

    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>
<div class="bg-white">
  <div class="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
    <!-- Details section -->
    <section aria-labelledby="details-heading">
      <div class="flex flex-col items-center text-center">
        <h2 id="details-heading" class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Communication</h2>
        <p class="mt-3 max-w-3xl text-lg text-gray-600">Effortless Communication and Collaboration: Enhance Your Coaching Experience with Check-Ins and Shared Calendar Features, Connecting Clients and Coaches Seamlessly.</p>
      </div>

      <div class="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-8">
        <div>
          <div class="aspect-h-2 aspect-w-3 w-full overflow-hidden rounded-lg ring-2 shadow-lg shadow-gray-400 ring-gray-400">
            <img src="./assets/images/ClientView/checkinFeature-01.png" class="h-full w-full object-cover object-center ">
          </div>
          <p class="mt-8 text-base text-gray-500">Client Check-In Made Easy: Conveniently Share Progress via Text or Voice, and Upload Form Pictures for Enhanced Coaching Engagement.</p>
        </div>
        <div>
          <div class="aspect-h-2 aspect-w-3 w-full overflow-hidden rounded-lg ring-2  shadow-lg shadow-gray-400 ring-gray-400">
            <img src="./assets/images/ClientView/checkinFeature-02.png" class="h-full w-full object-cover object-center ">
          </div>
          <p class="mt-8 text-base text-gray-500">Stay Aligned and Connected: Collaborate and Coordinate Seamlessly with the Shared Calendar, Ensuring Your Coaching Relationship Thrives.</p>
        </div>
        
      </div>
    </section>
  </div>
</div>

    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>

    <div class="bg-white pb-6">
  <section aria-labelledby="features-heading" class="relative">
    <div class="flex justify-center h-full  sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16 pt-10">
      <img src="./assets/images/ClientView/trainingplanView.png"  class="h-full w-5/6 md:w-full ring-2 rounded-lg shadow-lg shadow-gray-400 ring-gray-400  object-center lg:h-full lg:w-fit">
    </div>

    <div class="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
      <div class="lg:col-start-2">
        <h2 id="features-heading" class="font-medium text-gray-500">Track your Training like a pro.</h2>
        <p class="mt-4 text-4xl font-bold tracking-tight text-gray-900">Trainingplan Tracker</p>
        <p class="mt-4 text-gray-500">Your Personal Training Companion: Effortlessly Track Your Current Workout, Customize Exercise Settings, Monitor Ratings and Training Times, and Easily Refer to Previous Session Data.</p>

        <dl class="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 text-sm sm:grid-cols-2">
          <div>
            <dt class="font-medium text-gray-900">Last Session Load</dt>
            <dd class="mt-2 text-gray-500">Revolutionize your workout routine by effortlessly importing the exact weight and reps from your last session, ensuring unparalleled progress and precision.</dd>
          </div>
          <div>
            <dt class="font-medium text-gray-900">Easy Interface</dt>
            <dd class="mt-2 text-gray-500">Simplify your fitness journey with our user-friendly interface, making training log entries a breeze.</dd>
          </div>
          <div>
            <dt class="font-medium text-gray-900">Session / Schedule Overview</dt>
            <dd class="mt-2 text-gray-500">Empower yourself with real-time insights into your current session and overall training plan for an optimized fitness journey.</dd>
          </div>
          <div>
            <dt class="font-medium text-gray-900">Last Sessions</dt>
            <dd class="mt-2 text-gray-500">Access your recent and mesocycle-based past sessions, fueling your motivation and tracking your progress with precision.</dd>
          </div>
        </dl>
      </div>
    </div>
  </section>
</div>


    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>

<div class="bg-white">
  <div class="mx-auto max-w-2xl px-4 sm:px-6 py-10 lg:max-w-7xl lg:px-8">
    <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
      <div>
        <div class="border-b border-gray-200 pb-10">
          <h2 class="font-medium text-gray-500">Never miss a Message.</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Notifications</p>
        </div>

        <dl class="mt-10 space-y-10">
          <div>
            <dt class="text-sm font-medium text-gray-900">Apple Watch</dt>
            <dd class="mt-3 text-sm text-gray-500">Effortlessly manage your notifications with a visual touch – view and respond to alerts directly from your Apple Watch.</dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-900">In App</dt>
            <dd class="mt-3 text-sm text-gray-500">Experience uninterrupted communication and updates through our intuitive in-app notification banner.</dd>
          </div>

        </dl>
      </div>

      <div>
        <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
          <img src="./assets/images/applewatch.png"  class="h-full w-full object-cover object-center ">
        </div>
        <div class="mt-4  lg:mt-8 ">
          <div class="overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
            <img src="./assets/images/notification.png"  class="h-full w-full  object-center">
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>

  `,
  styles: [
  ]
})
export class CustomerFeaturelistComponent {

}
