import { Component, Input } from '@angular/core';
import { ChartType, Chart } from 'chart.js';
import { WeightEntryData, AppUser } from 'src/app/core/thecoach';

@Component({
  selector: 'app-bar-macro-charts',
  template: `
    <div class="flex flex-row w-full justify-evenly mb-2">
      <div class="h-20 text-center">
        <span class="text-xs">Protein</span>
        <canvas id="pChart">{{ proteinChart }}</canvas>
      </div>

      <div class="h-20 text-center">
        <span class="text-xs">Carbs</span>
        <canvas id="cChart">{{ carbChart }}</canvas>
      </div>
      <div class="h-20 text-center">
        <span class="text-xs">Fat</span>
        <canvas id="fChart">{{ fatChart }}</canvas>
      </div>
    </div>
  `,
  styles: [],
})
export class BarMacroChartsComponent {
  ChartType: ChartType = 'bar';
  proteinChart: any;
  carbChart: any;
  fatChart: any;
  @Input() weightEntry?: WeightEntryData;

  ngOnInit(): void {
    this.loadCharts();
  }

  loadCharts() {
    if (
      !this.weightEntry ||
      !this.weightEntry.achivedMacros ||
      !this.weightEntry.setMacros
    ) {
    } else if (this.weightEntry.refeedMacros) {
      this.createChart(
        this.weightEntry.achivedMacros.protein as number,
        this.weightEntry.refeedMacros.protein as number,
        this.proteinChart,
        'pChart',
        'Protein',
        { red: 249, green: 115, blue: 22 } //orange 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.carbs as number,
        this.weightEntry.refeedMacros.carbs as number,
        this.proteinChart,
        'cChart',
        'Carbs',
        { red: 34, green: 197, blue: 74 } //green 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.fat as number,
        this.weightEntry.refeedMacros.fat as number,
        this.proteinChart,
        'fChart',
        'Fats',
        { red: 59, green: 130, blue: 246 } //blue 500
      );
    } else {
      this.createChart(
        this.weightEntry.achivedMacros.protein as number,
        this.weightEntry.setMacros.protein as number,
        this.proteinChart,
        'pChart',
        'Protein',
        { red: 249, green: 115, blue: 22 } //orange 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.carbs as number,
        this.weightEntry.setMacros.carbs as number,
        this.proteinChart,
        'cChart',
        'Carbs',
        { red: 34, green: 197, blue: 74 } //green 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.fat as number,
        this.weightEntry.setMacros.fat as number,
        this.proteinChart,
        'fChart',
        'Fats',
        { red: 59, green: 130, blue: 246 } //blue 500
      );
    }
  }

  createChart(
    macroValue: number,
    setValue: number,
    chartObj: any,
    idName: string,
    labelName: string,
    colorCode: { red: number; green: number; blue: number }
  ) {
    if (Chart.getChart(idName) != undefined) {
      Chart.getChart(idName)?.destroy();
    }

    const tintFactor = 0.75;
    const shadeFactor = 0.5;

    const color =
      'rgb(' +
      colorCode.red +
      ',' +
      colorCode.green +
      ',' +
      colorCode.blue +
      ')';
    let backcolor: string;

    if (setValue - macroValue >= 0) {
      backcolor =
        'rgb(' +
        Math.round(colorCode.red + (255 - colorCode.red) * tintFactor) +
        ',' +
        Math.round(colorCode.green + (255 - colorCode.green) * tintFactor) +
        ',' +
        Math.round(colorCode.blue + (255 - colorCode.blue) * tintFactor) +
        ')';
    } else {
      backcolor =
        'rgb(' +
        Math.round(colorCode.red * (1 - shadeFactor)) +
        ',' +
        Math.round(colorCode.green * (1 - shadeFactor)) +
        ',' +
        Math.round(colorCode.blue * (1 - shadeFactor)) +
        ')';
    }

    const chartdata = {
      labels: [labelName],
      datasets: [
        {
          label: labelName,
          data: [macroValue],
          backgroundColor: [backcolor],
        },
        {
          label: labelName,
          data: [setValue],
          backgroundColor: [color, backcolor],
        },
      ],
    };

    const config = {
      type: this.ChartType,
      data: chartdata,
      options: {
        indexAxis: 'y' as const, // <-- here
        responsive: true,

        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    };

    chartObj = new Chart(idName, config);
  }
}
