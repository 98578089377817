import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { cl } from '@fullcalendar/core/internal-common';
import { property } from 'lodash';
import { Subscription } from 'rxjs';
import {
  AppUser,
  ClientProperty,
  ClientPropertyName,
} from 'src/app/core/thecoach';
import { UserService } from 'src/app/services/user.service';
import { Client } from 'typesense';

@Component({
  selector: 'app-client-panel',
  template: `
    <div
      *ngIf="client"
      class="w-fit px-4 py-4 shadow border-gray-200/40 border rounded-md"
      [ngClass]="{
        'bg-gray-200 ring-2 ring-gray-700': !client.enableWeightLog,
      }"
    >
      <div class="relative">
        <div class="flex flex-row">
          <img
            class="h-20 w-20 flex-shrink-0 rounded-full bg-gray-300"
            src="{{
              client.imgUrl || '.././assets/images/default-person-image.webp'
            }}"
          />
          <div class="flex flex-col ml-4">
            <h3>
              {{ client.displayName }}
            </h3>
            <div
              class="flex flex-col w-fit text-gray-400 text-xs italic whitespace-nowrap"
            >
              <p>ID: {{ client.id }}</p>
              <p>E-Mail: {{ client.email }}</p>
              <p *ngIf="client.birthdate">
                Birthday:
                {{ client.birthdate | date: 'dd-MM-yyyy' }}
              </p>
              <p *ngIf="client.startingWeight">
                Starting Weight: {{ client.startingWeight }} kg
              </p>
              <p *ngIf="client.height">Height: {{ client.height }} cm</p>
            </div>
          </div>
        </div>
        <div
          (click)="onDeleteClientFromCoach(client)"
          class="absolute -top-4 sm:-right-4 -right-4 text-red-400 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>

      <div class="flex w-full flex-row justify-end">
        <div
          class="flex w-6/12 justify-end"
          *ngIf="checkIfAnyPropertyIsDisabled(clientProperties); else disable"
        >
          <button
            (click)="enableAllProperties(clientProperties)"
            type="button"
            class="rounded-md bg-teal-600 disabled:opacity-25 disabled:cursor-not-allowed px-0.5 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Enable features
          </button>
        </div>

        <ng-template #disable>
          <div class="flex w-6/12 justify-end">
            <button
              (click)="disableAllProperties(clientProperties)"
              type="button"
              class="rounded-md bg-teal-600 px-0.5 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Disable features
            </button>
          </div>
        </ng-template>
      </div>
      <div class="relative pb-4 pt-0">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
      </div>

      <div class="flex w-full flex-col">
        <div class="flex w-full">
          <ul class="w-full grid grid-cols-2">
            <li *ngFor="let property of clientProperties" class="p-2 ">
              <app-toggle-switch
                [toggleProperty]="property.value"
                (onToggleProperty)="onToggle(property)"
                [description]="property.description"
                [disableOnlyMode]="disableOnlyMode"
                [isWeightLog]="isWeightLog(property)"
              ></app-toggle-switch>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <app-simple-modal
      *ngIf="toggleDelete && selectedClient"
      (close)="onCloseDeleteModal($event)"
      [title]="deleteTitle"
      [message]="deleteMsg"
      [negativeButtonText]="'No'"
      [positiveButtonText]="'Yes'"
    >
    </app-simple-modal>
  `,
  styles: [],
})
export class ClientPanelComponent implements OnInit, OnDestroy {
  @Input({ required: true }) user!: AppUser;
  @Input({ required: true }) clientId!: string;
  @Input({ required: true }) isCoach!: boolean;
  @Input() disableOnlyMode?: boolean = false;

  @Output() clients = new EventEmitter<AppUser>();

  client: AppUser | undefined = undefined;
  clientProperties: ClientProperty[] = [];
  clientSubscription$: Subscription | undefined = undefined;

  toggleDelete = false;
  deleteTitle = '';
  deleteMsg = '';
  selectedClient: AppUser | undefined = undefined;

  constructor(private userService: UserService) {}
  ngOnInit(): void {
    if (this.isCoach && this.user.coachSpotId) {
      this.clientId = this.user.coachSpotId;
    }

    if (this.isClientOfCoach(this.user, this.clientId, this.isCoach)) {
      this.userService
        .getUserFromDatabase(this.clientId)
        .subscribe((client) => {
          if (!client) {
            this.clients.emit(undefined);
            return undefined;
          }
          this.client = client;
          this.clientProperties = this.addClientPropertiesToArray(client);

          this.clients.emit(client);
        });
    }
  }

  ngOnDestroy(): void {
    this.clientSubscription$?.unsubscribe();
  }

  isWeightLog(property: ClientProperty) {
    return property.name.includes(ClientPropertyName.EnableWeightLog);
  }
  isClientOfCoach(user: AppUser, clientId: string, isCoach: boolean) {
    if (isCoach && user.coachSpotId === clientId) return true;
    if (!user.clientIds) return false;

    return user.clientIds.includes(clientId);
  }

  addClientPropertiesToArray(client: AppUser) {
    let arr: ClientProperty[] = [];

    this.fixLegacyProperties(client);

    if (client.enableWeightLog !== undefined)
      arr.push({
        name: ClientPropertyName.EnableWeightLog,
        description: 'Weightlog',
        value: client.enableWeightLog,
      });
    if (client.enableTraining !== undefined)
      arr.push({
        name: ClientPropertyName.EnableTraining,
        description: 'Training',
        value: client.enableTraining,
      });
    if (client.enableCTMs !== undefined)
      arr.push({
        name: ClientPropertyName.EnableCTMs,
        description: "CTM's",
        value: client.enableCTMs,
      });
    if (client.enableMealPlans !== undefined)
      arr.push({
        name: ClientPropertyName.EnableMealPlans,
        description: 'Mealplans',
        value: client.enableMealPlans,
      });
    if (client.enableMacroTracking !== undefined)
      arr.push({
        name: ClientPropertyName.EnableMacroTracking,
        description: 'Macro Tracking',
        value: client.enableMacroTracking,
      });
    if (client.enableCMFs !== undefined)
      arr.push({
        name: ClientPropertyName.EnableCMFs,
        description: "CMF's",
        value: client.enableCMFs,
      });

    return arr;
  }

  async fixLegacyProperties(client: AppUser) {
    let change = false;
    if (client.enableCTMs === undefined) {
      client.enableCTMs = false;
      change = true;
    }
    if (client.enableMealPlans === undefined) {
      client.enableMealPlans = false;
      change = true;
    }

    if (client.enableMacroTracking === undefined) {
      client.enableMacroTracking = false;
      change = true;
    }
    if (client.enableCMFs === undefined) {
      client.enableCMFs = false;
      change = true;
    }
    if (change) {
      await this.userService.saveUserData(client);
    }
  }

  onToggle(property: { name: string; value: boolean }) {
    if (this.client) {
      property.value = !property.value;
      this.client[property.name] = property.value;

      this.userService.saveUserData(this.client);
    }
  }

  disableAllProperties(clientProperties: ClientProperty[]) {
    if (this.client) {
      clientProperties.forEach((property) => {
        if (!this.isWeightLog(property)) {
          property.value = false;
          this.client![property.name] = property.value;
        }
      });
      this.userService.saveUserData(this.client);
    }
  }
  enableAllProperties(clientProperties: ClientProperty[]) {
    if (this.client) {
      clientProperties.forEach((property) => {
        if (!this.isWeightLog(property)) {
          property.value = true;
          this.client![property.name] = property.value;
        }
      });
      this.userService.saveUserData(this.client);
    }
  }

  checkIfAnyPropertyIsDisabled(clientProperties: ClientProperty[]) {
    if (this.client) {
      return clientProperties
        .filter((property) => !this.isWeightLog(property))
        .some((property) => property.value === false);
    }

    return false;
  }

  onDeleteClientFromCoach(client: AppUser) {
    this.selectedClient = client;
    this.deleteTitle = 'Deleting Client!';
    this.deleteMsg = 'Do you want to delete ' + client.displayName + ' ?';
    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.toggleDelete = !this.toggleDelete;
  }

  deleteClientFromCoach(coach: AppUser, client: AppUser) {
    if (!this.isCoach) {
      if (coach && coach.clientIds && client) {
        if (coach.clientIds.some((clientId) => clientId === client.id)) {
          coach.clientIds = coach.clientIds.filter(
            (clientId) => clientId !== client.id,
          );
          client.coachId = 'NONE';
          this.userService.saveUserData(coach);
          this.userService.saveUserData(client);
        }
      }
    } else {
      client.coachId = coach.id;
      client.pending = true;
      coach.coachSpotId = '';

      this.userService.saveUserData(coach);
      this.userService.saveUserData(client);
    }
  }

  onCloseDeleteModal(event: boolean) {
    if (event && this.selectedClient) {
      this.deleteClientFromCoach(this.user, this.selectedClient);
    }

    this.selectedClient = undefined;
    this.toggleDeleteModal();
  }
}
