import { contactMessage } from './../core/thecoach';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root',
})
export class EmailMessagingService {
  constructor(private db: AngularFirestore) {}

  saveContactMessage(cM: contactMessage) {
    const id = this.db.createId();
    cM.id = id;

    return this.db
      .collection('/contactMessages')
      .doc(id)
      .set(Object.assign({}, cM));
  }
}
