import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Chart, ChartItem, ChartType } from 'chart.js';
import { format } from 'date-fns';
import { Macros, WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-mini-macro-radar',
  template: `
    <div>
      <canvas id="myRadarChart-{{ position }}" class="relative w-fit h-fit">{{
        weightLogChart
      }}</canvas>
    </div>
  `,
  styles: [],
})
export class MiniMacroRadarComponent implements AfterViewInit {
  @Input() logs: WeightEntryData[] = [];
  @Input() position?: number;

  weightLogChart: ChartItem | undefined;
  lineChartType: ChartType = 'radar';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    this.createChart();

    this.changeDetectorRef.detectChanges();
  }

  createChart() {
    const dates = this.logs.map((log) => format(log.date!, 'dd-MM')).reverse();
    const labels = ['Protein', 'Carbs', 'Fats'];
    const proteins = this.logs
      .map((log) => log.achivedMacros?.protein)
      .reverse();
    const carbs = this.logs.map((log) => log.achivedMacros?.carbs).reverse();
    const fats = this.logs.map((log) => log.achivedMacros?.fat).reverse();

    const getAverage = (array: number[]) => {
      const sum = array.reduce((acc, val) => acc + val, 0);
      return sum / array.length;
    };

    const averageData = [
      getAverage(proteins as number[]),
      getAverage(carbs as number[]),
      getAverage(fats as number[]),
    ];

    const setMacroData = [
      this.logs[0].setMacros?.protein,
      this.logs[0].setMacros?.carbs,
      this.logs[0].setMacros?.fat,
    ];

    let num = 0;
    if (this.position) {
      num = this.position;
    }

    if (Chart.getChart('myRadarChart-' + num.toString()) != undefined) {
      Chart.getChart('myRadarChart-' + num.toString())!.destroy();
    }

    this.weightLogChart = new Chart('myRadarChart-' + num.toString(), {
      type: 'radar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Data',
            data: averageData,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.2,
          },
          {
            label: 'Data',
            data: setMacroData,
            borderColor: 'rgb(192, 75, 192)',
            backgroundColor: 'rgba(192, 75, 192, 0.2)',
            tension: 0.2,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
      },
    });
  }
}
