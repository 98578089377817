import { Injectable } from '@angular/core';
import * as firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpUserService {
  private currentUserSubject: BehaviorSubject<firebase.default.User | null> = new BehaviorSubject<firebase.default.User | null>(null);
  currentUser$: Observable<firebase.default.User | null> = this.currentUserSubject.asObservable();


  constructor() {
    if (!(this.currentUserSubject.value !== null)) {
      const user = firebase.default.auth().currentUser;
      this.currentUserSubject.next(user);
    }

  }
}
