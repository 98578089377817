import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CheckinService } from './../../services/checkin.service';
import { UserService } from './../../services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import {
  AppUser,
  CheckinStatus,
  ClientCheckin,
  Message,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-coach-check-ins',
  template: `
    <ul role="list" class="space-y-3" *ngIf="openCheckIns.length > 0">
      <div *ngFor="let checkIn of openCheckIns; let i = index">
        <li
          class="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
        >
          <div class="relative">
            <div>
              <h3 class="font-bold py-2">
                {{ checkIn.clientName }} - {{ checkIn.title }}
              </h3>
              <div
                *ngFor="let message of checkIn.messages"
                class="text-sm  pb-2 flex"
                [ngClass]="{
                  'justify-start': checkOriginalAuthor(
                    checkIn,
                    message.author!
                  ),
                  'justify-end': !checkOriginalAuthor(checkIn, message.author!)
                }"
              >
                <div
                  *ngIf="
                    checkOriginalAuthor(checkIn, message.author!);
                    else notOriginalAuthor
                  "
                  class="break-all rounded-md bg-green-50 px-6 py-4 shadow max-w-full"
                >
                  <p>{{ message.message }}</p>
                  <div class="text-right pt-1">
                    <p class="italic text-xs font-light">
                      {{ message.author }} -
                      {{ message.date | date: 'dd-MM-yyyy' }}
                    </p>
                  </div>
                </div>

                <ng-template #notOriginalAuthor class="flex">
                  <div class="flex justify-right">
                    <div
                      class="break-all rounded-md bg-blue-50 px-6 py-4 shadow max-w-full"
                    >
                      <p>{{ message.message }}</p>
                      <div class="text-left pt-1">
                        <p class="italic text-xs font-light">
                          {{ message.author }} -
                          {{ message.date | date: 'dd-MM-yyyy' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div class="w-full flex justify-end pt-2">
                <button
                  (click)="onAnswerCheckIn(i)"
                  *ngIf="!answerToggler[i]"
                  type="button"
                  class="rounded-md bg-teal-50 py-1.5 px-2.5 text-sm font-semibold text-teal-600 shadow-sm hover:bg-teal-100"
                >
                  Send Message
                </button>
                <div *ngIf="answerToggler[i]" class="w-full">
                  <form [formGroup]="checkInForm" (ngSubmit)="onSubmit(i)">
                    <div formArrayName="checkIns">
                      <div [formGroupName]="i">
                        <textarea
                          rows="3"
                          formControlName="message"
                          name="message"
                          id="message"
                          class="block w-full py-2 my-4 border-1 border-gray-200 rounded-lg py-0 pb-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:ring-teal-400 focus:border-teal-400 sm:text-sm sm:leading-6"
                          placeholder="Write your Answer..."
                        ></textarea>
                        <div class="w-full flex justify-end">
                          <button
                            (click)="onCancelAnswer(i)"
                            type="button"
                            class="rounded-md bg-white mr-8 py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="absolute -top-2 -right-2">
              <span
                *ngIf="checkIn.status === 'sent'"
                class="inline-flex items-center rounded bg-yellow-100 px-2
                py-0.5 text-xs font-medium text-yellow-800"
                >Open</span
              >
              <span
                *ngIf="checkIn.status === 'answered'"
                class="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
                >Answered</span
              >
            </div>
            <div class="flex flex-row">
              <div class=" grid grid-cols-4 items-end mt-5 gap-px">
                <img
                  src="{{ img }}"
                  *ngFor="let img of checkIn.imgUrls"
                  class="w-54"
                />
              </div>
            </div>
          </div>
          <!-- Your content -->
        </li>
      </div>
      <!-- More items... -->
    </ul>
  `,
  styles: [],
})
export class CoachCheckInsComponent implements OnInit, OnDestroy {
  coach: AppUser = {};
  userSubscription: Subscription | undefined;
  checkInSubscription: Subscription | undefined;
  openCheckIns: ClientCheckin[] = [];
  answerToggler: boolean[] = [];

  checkInForm = this.fb.group({
    checkIns: this.fb.array([]),
  });

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private checkInService: CheckinService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.userSubscription = this.auth.appUser$.pipe().subscribe((appUser) => {
      this.coach = appUser as AppUser;
    });

    if (this.coach && this.coach.clientIds) {
      this.coach.clientIds.forEach((clientId) => {
        this.checkInSubscription = this.checkInService
          .getClientCheckins(clientId)
          .subscribe((checkIn) => {
            checkIn.forEach((checkIn, index) => {
              if (
                checkIn.status === CheckinStatus.Sent ||
                CheckinStatus.Answered
              ) {
                checkIn.date = (checkIn.date as unknown as Timestamp).toDate();

                checkIn.messages?.forEach((msg) => {
                  msg.date = (msg.date as unknown as Timestamp).toDate();
                });

                this.openCheckIns.push(checkIn);
                this.answerToggler.push(false);
                this.createForms(index);
              }
            });
            console.log(this.checkIns, this.openCheckIns);
          });
      });
    }
  }

  get checkIns() {
    return this.checkInForm.get('checkIns') as FormArray;
  }

  createInputElement(): FormGroup {
    return new FormGroup({ message: new FormControl('') });
  }

  createForms(index: number) {
    this.checkIns.push(this.createInputElement());
  }

  onAnswerCheckIn(index: number) {
    this.answerToggler[index] = !this.answerToggler[index];
  }

  onCancelAnswer(index: number) {
    this.checkIns.at(index).reset();
    this.answerToggler[index] = !this.answerToggler[index];
  }

  onSubmit(index: number) {
    if (this.checkIns.at(index).value.message.length > 1) {
      const answerMessage: Message = {
        author: this.coach.displayName,
        message: this.checkIns.at(index).value.message,
        date: new Date(),
      };

      this.checkInService.sendAnswerToCheckin(
        this.openCheckIns[index],
        answerMessage,
        CheckinStatus.Answered,
      );
      this.answerToggler[index] = !this.answerToggler[index];
    }
  }

  checkOriginalAuthor(checkin: ClientCheckin, author: string): boolean {
    if (!checkin.messages) return false;

    if (checkin.messages[0].author === author) return true;

    return false;
  }

  ngOnDestroy() {
    this.checkInSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }
}
