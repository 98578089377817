import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { format } from 'date-fns';
import { Observable, first, tap, finalize, from, concatMap, observable, mergeMap, switchMap } from 'rxjs';
import { AppUser, downloadObject } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-upload-files-task',
  template: `
 <div class="w-full justify-center">
      <div class="shadown w-full bg-grey-light">
    

        <div class="flex flex-col w-full">
          <div class="flex flex-col w-full">
        <div *ngFor="let fileName of displayFileNames; let i = index" class="flex">
        <div class="flex flex-row w-full items-center text-xs italic text-gray-700">
      {{fileName}}
      <div *ngIf="currentIndex === i" class="ml-2">

            <div
              class="border-t-transparent border-solid animate-spin  rounded-full border-teal-400 border-4 h-4 w-4"
            ></div>
        
    
    
    </div></div>
      </div>
      </div>
        <div
          *ngIf="snapshot | async as snap"
          class="w-full flex flex-row  justify-around text-sm italic text-gray-500 group-hover:text-gray-600 items-center"
        >
        <div class="w-full flex-row flex items-center" *ngIf="isActive(snap)">
         {{ snap.bytesTransferred / 1024 | number : '1.0-0' }} of {{ snap.totalBytes / 1024 | number : '1.0-0' }}   KB
      

         <div class="flex items-center ml-4">
        <button
        *ngIf="snap.state === 'running'"
          (click)="pauseUpload(snap.task)"
          [disabled]="!snap.task || !isActive(snap)"
          type="button"
          class="inline-flex items-center rounded-full bg-teal-600 p-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>

        <button
          *ngIf="snap.state === 'paused'"
          (click)="resumeUpload(snap.task)"
          [disabled]="!snap.task || !(snap?.state === 'paused') || !isActive(snap)"
          type="button"
          class="inline-flex items-center rounded-full bg-teal-600 p-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
          </svg>
        </button>
        </div>
      </div>
        </div>
      </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class UploadFilesTaskComponent implements OnInit, OnChanges {
  @Input() files: File[] | undefined;
  @Input() checkinId!: string;
  task: AngularFireUploadTask | undefined;
  percentage: Observable<number> | undefined;
  snapshot: Observable<any> | undefined;
  downloadUrl: string = '';
  user: AppUser | undefined;

  dlObjArr: downloadObject[] = []

  run = true

  displayFileNames: string[] = [];
  currentIndex: number | undefined

  @Output() imgUrl = new EventEmitter<downloadObject[]>();
  @Output() uploading = new EventEmitter<boolean>();

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private auth: AuthService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] && !changes['files'].firstChange) {
      // Files have changed, restart the upload process
      if (this.files) {
        this.getFileNames(this.files);
        this.uploadFilesSequentially()
      }
    }
  }


  ngOnInit(): void {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;
      //  console.log('checkinID', this.checkinId);
      //this.startUpload();
      if (this.files) {
        this.getFileNames(this.files);
        this.uploadFilesSequentially()
      }

    });
  }

  getFileNames(files: File[]) {
    files.forEach(file => this.displayFileNames.push(file.name))
  }

  log(val: any) {
    if (this.run) {
      this.run = false;
    }

  }

  uploadFilesSequentially() {
    if (this.user && this.user.id && this.files) {

      const id = this.user?.id
      const filesObservable = from(this.files);

      this.uploading.emit(true)
      filesObservable.pipe(
        concatMap((file) => this.uploadFile(id, file))
      ).subscribe(() => {
        this.imgUrl.emit(this.dlObjArr)
        this.uploading.emit(false)
      }, (error) => {
        console.error('Error uploading File', error)
      })

    }


  }
  uploadFile(id: string, file: File) {
    return new Observable<void>((observer) => {

      this.currentIndex = this.displayFileNames.findIndex(n => n === file.name)

      let safeName = file.name.replace(/([^a-zA-Z0-9]+)/gi, '');
      const timestamp = Date.now();
      const dateFormat = format(timestamp, 'yyyy-MM-dd');
      const uniqueSafeName = timestamp + '_' + safeName;
      const fileId = this.db.createId();
      const path = 'checkinFiles/' + id + '/files/' + uniqueSafeName;
      const ref = this.storage.ref(path);

      const metaData = {

        userId: id,
        dateFormat: dateFormat,
        fileId: fileId,
        timestamp: timestamp.toString(),
        checkinId: this.checkinId,
      };

      this.task = this.storage.upload(path, file, {
        customMetadata: metaData,
      });

      this.percentage = this.task.percentageChanges() as Observable<number>;

      this.snapshot = this.task.snapshotChanges().pipe(
        tap(),
        finalize(async () => {
          this.downloadUrl = await ref.getDownloadURL().toPromise();

          let downloadObj: downloadObject = {
            checkinId: this.checkinId,
            id: fileId,
            downloadURL: this.downloadUrl,
            storagePath: path,
            formatedDate: dateFormat,
            fileId: fileId,
            originalName: file.name,
            timestamp: timestamp.toString(),
          };



          this.db
            .collection('files')
            .doc(id)
            .collection(dateFormat)
            .doc(fileId)
            .set(downloadObj, { merge: true })
            .then(() => {
              this.dlObjArr.push(downloadObj);
              this.currentIndex = undefined
              observer.next(); // Notify that the upload is complete
              observer.complete();
            })
            .catch((error) => {
              console.error('error writing document', error);
              observer.error(error);
            });
        })
      );
    });
  }

  isActive(snapshot: UploadTaskSnapshot) {

    return (
      snapshot.state === 'paused' || snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }


  pauseUpload(task: AngularFireUploadTask | undefined) {
    if (task) {
      task.pause();
    }
  }

  // Function to cancel the upload


  // Function to resume the upload
  resumeUpload(task: AngularFireUploadTask | undefined) {
    if (task) {
      task.resume();
    }
  }


  /*
    startUpload() {
      const id = this.user!.id;
  
      if (id && this.files) {
        this.uploading.emit(true);
  
        for (let file of this.files) {
          let safeName = file.name.replace(/([^a-zA-Z0-9]+)/gi, '');
  
          const timestamp = Date.now();
          const dateFormat = format(timestamp, 'yyyy-MM-dd');
          const uniquqeSafeName = timestamp + '_' + safeName;
          const fileId = this.db.createId();
  
          const path = 'checkinFiles/' + id + '/files/' + uniquqeSafeName;
          const ref = this.storage.ref(path);
          const metaData = {
            userId: id,
            dateFormat: dateFormat,
            fileId: fileId,
            timestamp: timestamp.toString(),
            checkinId: this.checkinId,
          };
          this.task = this.storage.upload(path, file, {
            customMetadata: metaData,
          });
          this.percentage = this.task.percentageChanges() as Observable<number>;
  
          this.snapshot = this.task.snapshotChanges().pipe(
            tap(),
            finalize(async () => {
              this.downloadUrl = await ref.getDownloadURL().toPromise();
  
              let downloadObj: downloadObject = {
                checkinId: this.checkinId,
                id: fileId,
                downloadURL: this.downloadUrl,
                storagePath: path,
                formatedDate: dateFormat,
                fileId: fileId,
                originalName: file.name as string,
                timestamp: timestamp.toString(),
              };
              this.imgUrl.emit(downloadObj);
  
              this.db
                .collection('files')
                .doc(id)
                .collection(dateFormat)
                .doc(fileId)
                .set(downloadObj, { merge: true })
                .then(() => {
                  this.uploading.emit(false);
                  //  console.log('document written!');
                })
                .catch((error) => {
                  console.error('error writing document', error);
                });
            })
          );
        }
      }
    }
  */

}
