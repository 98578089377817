import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { add, sub } from 'date-fns';
import { Observable } from 'rxjs';
import { AppUser, WeightEntryData, Note } from 'src/app/core/thecoach';
import { NoteService } from 'src/app/services/note.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-coach-dashboard-data-table-panel',
  template: `
    <div
      class="rounded-lg  bg-white p-2 shadow-md w-full overflow-auto md:overflow-hidden md:w-fit h-fit xl:mr-4 mt-4 mx-[1px] ring-1 ring-gray-300 "
    >
      <app-date-range-picker
        (emitDateRange)="onUpdatedRange($event)"
        [startDate]="startDate"
        [endDate]="endDate"
      ></app-date-range-picker>
    </div>
    <ng-container *ngIf="clientWeightLog$ | async as logs; else loading">
      <ng-container *ngIf="logs.length > 1">
        <div class="flex flex-col xl:flex-row w-full">
          <div
            class="rounded-lg  bg-white shadow-md w-full overflow-auto md:overflow-hidden md:w-fit h-fit xl:mr-4 mt-4 mx-[1px] ring-1 ring-gray-300 "
          >
            <div class="px-4 py-5  w-full">
              <app-weightlog-line-chart-dashboard
                [logs]="logs"
              ></app-weightlog-line-chart-dashboard>
            </div>

            <div class="px-4 py-5 sm:p-6 w-full">
              <app-weightlog-table-dashboard-v2
                [client]="selectedClient"
                [weightLogData]="logs"
              >
              </app-weightlog-table-dashboard-v2>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #loading>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>
  `,
  styles: [],
})
export class CoachDashboardDataTablePanelComponent
  implements OnInit, OnChanges
{
  @Input() selectedClient: AppUser | undefined = undefined;

  clientWeightLog$: Observable<WeightEntryData[]> | undefined;

  latestNote$: Observable<Note | undefined> | undefined;
  showNewNoteModal = false;
  showEditNoteModal = false;
  editNote: Note | undefined = undefined;

  run = true;
  startDate = new Date();
  endDate = sub(this.startDate, { days: 29 });
  showCalendar = false;
  constructor(
    private weightLogService: WeightlogService,
    private notesService: NoteService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  log(value: any) {
    if (this.run) {
      //  console.log('logs', value);
      this.run = false;
    }
  }
  ngOnInit(): void {
    // console.log('selclient', this.selectedClient)
    if (this.selectedClient) {
      this.startDate = new Date();
      this.endDate = sub(this.startDate, { days: 29 });

      this.clientWeightLog$ =
        this.weightLogService.getWeightLogForUserBetweenDates(
          this.selectedClient?.id!,
          this.endDate as Date,
          this.startDate as Date,
        );

      this.latestNote$ = this.notesService.getLatestNote(this.selectedClient);
    }
  }

  checkLogsForAchievedMacros(logs: WeightEntryData[]): boolean {
    let temp = logs.filter((log) => log.achivedMacros);

    if (temp.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onUpdatedRange(val: { start: Date; end: Date }) {
    if (val) {
      this.startDate = val.start;
      this.endDate = val.end;
      this.clientWeightLog$ =
        this.weightLogService.getWeightLogForUserBetweenDates(
          this.selectedClient?.id!,
          this.endDate as Date,
          this.startDate as Date,
        );
    }
  }
}
