import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { first, map } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { downloadObject } from '../core/thecoach';
import { stringify } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private storage: AngularFireStorage
  ) { }

  async deleteIMG(downloadObj: downloadObject) {
    try {
      const appUser = await this.auth.appUser$.pipe(first()).toPromise();

      if (!appUser) return;

      // Fetch the current storage path
      const docSnapshot = await this.db
        .collection('files')
        .doc(appUser.id)
        .collection(downloadObj.formatedDate)
        .doc(downloadObj.fileId)
        .get()
        .toPromise();

      if (docSnapshot) {
        const fileData = docSnapshot.data();

        // Check if the document has a 'storagePath' field
        if (fileData && fileData['storagePath']) {
          const currentStoragePath = fileData['storagePath'];
          // Delete the file with the updated storage path
          await this.storage.ref(currentStoragePath).delete();

          this.db
            .collection('files')
            .doc(appUser.id)
            .collection(downloadObj.formatedDate)
            .doc(downloadObj.fileId)
            .delete();
        }
      } else {
        console.log('Document does not exist');
      }
    } catch (error) {
      console.error('Error deleting/updating file:', error);
      throw error; // You can handle the error as needed
    }
  }

  getIMGObject(userId: string, downloadObj: downloadObject) {
    return this.db
      .collection('files')
      .doc(userId)
      .collection<downloadObject>(downloadObj.formatedDate)
      .doc(downloadObj.fileId)
      .valueChanges();
  }

  async getNewFileDownloadURL(fileName: string, userId: string) {
    const newPath = userId + '/files/' + fileName + '_798x1064'
    const ref = this.storage.ref(newPath)
    try {
      const url = await ref.getDownloadURL().toPromise()
      return url

    } catch (err) {
      console.error(err)
      throw err;

    }

  }

}
