import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppUser, WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-modal-ctm',
  template: `
       <div
                  class="flex w-full"
                  [ngClass]="{
                    'justify-between':
                      selectedWeightEntry && selectedWeightEntry.customTrackingLog,
                    'justify-end':
                      selectedWeightEntry && !selectedWeightEntry.customTrackingLog
                  }"
                >
                  <div
                    class="mt-2 flex w-full"
                    *ngIf="
                      selectedWeightEntry && selectedWeightEntry.customTrackingLog
                    "
                  >
                    <div
                      class="mt-2 flex w-full justify-between items-center space-x-3 text-xs"
                      *ngIf="selectedWeightEntry.customTrackingLog"
                    >
                      <div class="flex space-x-3 flex-row w-4/5">
                        <dt class="mt-0.5 ">
                          <div
                            class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                              />
                            </svg>
                          </div>
                        </dt>
                        <dd class="flex text-justify">
                          <ul class="">
                            <li
                              *ngFor="
                                let cctv of selectedWeightEntry.customTrackingLog
                                  .ccts
                              "
                            >
                              {{ cctv.name }} - {{ cctv.value }}
                            </li>
                          </ul>
                        </dd>
                      </div>

 
                      <button
                        type="button"
                       (click)="
                          onDeleteCTMs(); $event.stopPropagation()
                        "
                        class="w-fit mr-4 h-fit rounded-md bg-red-600 py-0.5 px-0.5 text-sm font-semibold text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
  



                      <button
                        *ngIf="
                          user.ctmId &&
                          selectedWeightEntry &&
                          selectedWeightEntry.weight &&
                          selectedWeightEntry.customTrackingLog
                        "
                        type="button"
                        (click)="
                          toggleCustomsEditMenu(); $event.stopPropagation()
                        "
                        class="w-fit rounded-md h-fit bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <img
                          class="w-6 h-6 invert"
                          src="../../assets/icons/3d-modeling-icon-checked.png"
                        />
                      </button>
                    </div>
                  </div>
                  <button
                    *ngIf="
                      user.ctmId &&
                      selectedWeightEntry &&
                      selectedWeightEntry.weight &&
                      !selectedWeightEntry.customTrackingLog
                    "
                    type="button"
                    (click)="toggleCustomsEditMenu(); $event.stopPropagation()"
                    class="mt-2 w-fit rounded-md h-fit  bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-6 h-6 invert"
                      src="../../assets/icons/3d-modeling-icon.png"
                    />
                  </button>
                </div>
  `,
  styles: [
  ]
})
export class ModalCtmComponent {
  @Input() user!: AppUser
  @Input() selectedWeightEntry!: WeightEntryData;
  @Output() showCTMModal: EventEmitter<boolean> = new EventEmitter();
  @Output() deleteCTMModal: EventEmitter<boolean> = new EventEmitter();

  toggleCustomsEditMenu() {
    this.showCTMModal.emit(true);
  }
  onDeleteCTMs() {
    this.deleteCTMModal.emit(true);
  }
}
