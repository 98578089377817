import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MealPlanWithDays } from '../../../../../backend/core/thecoach';

@Injectable({
  providedIn: 'root'
})
export class MealplanService {
  private mealPlansSubject = new BehaviorSubject<MealPlanWithDays[]>([]);
  mealPlans$ = this.mealPlansSubject.asObservable();

  constructor() { }

  setMealPlans(mealPlans: MealPlanWithDays[]): void {
    this.mealPlansSubject.next(mealPlans);
  }
}
