import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearchModels',
})
export class FilterSearchModelsPipe implements PipeTransform {
  transform(models: any[], searchTerm: string): any[] {
    if (!models || !searchTerm) {
      return models;
    }

    searchTerm = searchTerm.toLowerCase();

    return models.filter((model) =>
      model.name.toLowerCase().includes(searchTerm)
    );
  }
}
