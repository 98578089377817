import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { sub } from 'date-fns';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import {
  TemplateTrainingDay,
  TemplateTrainingPlan,
  TemplateTrainingSlot,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-csvimport',
  template: `
    <label
      type="button"
      for="file-upload"
      class="rounded-md cursor-pointer bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
    >
      Import CSV
      <input
        id="file-upload"
        type="file"
        accept=".csv"
        style="display:none"
        (change)="handle($event)"
      />
    </label>
  `,
  styles: [],
})
export class CSVImportComponent {
  csvRecords: string[][] = [];
  header: boolean = false;
  @Output() trainingPlan = new EventEmitter<TemplateTrainingPlan>();

  constructor(private ngxCsvParser: NgxCsvParser) {}

  fileChangeListener($event: any): void {
    const files = $event;
    this.header =
      (this.header as unknown as string) === 'true' || this.header === true;

    this.ngxCsvParser
      .parse(files[0], {
        header: this.header,
        delimiter: ',',
        encoding: 'utf8',
      })
      .pipe()
      .subscribe({
        next: (result): void => {
          this.csvRecords = result as string[][];
          this.clearCSVInput(this.csvRecords);
        },
        error: (error: NgxCSVParserError): void => {
          console.log('Error', error);
        },
      });
  }

  handle(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      this.fileChangeListener(target.files);
    }
  }

  clearCSVInput(arr: string[][]) {
    let filtered: string[][] = [];
    arr.forEach((sub) => {
      filtered.push(sub.filter((elm) => elm));
    });
    this.splitCsvForTrainingDays(filtered);
  }

  splitCsvForTrainingDays(arr: string[][]) {
    let td: string[][][] = [[]];
    let j = 0;

    arr.forEach((subArr) => {
      if (subArr.length === 0) {
        td.push([]);
        j++;
      } else {
        td[j].push(subArr);
      }
    });

    this.trainingPlan.emit(this.createTrainingPlan(td));
  }

  createTrainingPlan(csvPlan: string[][][]) {
    const trainingPlan: TemplateTrainingPlan = new TemplateTrainingPlan();
    trainingPlan.trainingDays = [];
    trainingPlan.trainingPlanName = 'Imported Plan';
    trainingPlan.trainingPlanDescription = 'Imported Plan';

    for (const trainingDayData of csvPlan) {
      const trainingDay: TemplateTrainingDay = new TemplateTrainingDay();
      trainingDay.trainingSlots = [];

      // Extract training day name from the first element of the trainingDayData
      trainingDay.trainingDayName = trainingDayData[0][0];

      for (let i = 1; i < trainingDayData.length; i++) {
        const trainingSlotData = trainingDayData[i];

        const trainingSlot: TemplateTrainingSlot = new TemplateTrainingSlot();
        trainingSlot.exerciseName = trainingSlotData[0];
        trainingSlot.exerciseDescription = trainingSlotData[1];
        trainingSlot.sets = trainingSlotData[2];
        trainingSlot.reps = trainingSlotData[3];
        trainingSlot.rir = trainingSlotData[4];
        trainingSlot.slot = i;

        // You can add more properties to the trainingSlot here

        trainingDay.trainingSlots.push(trainingSlot);
      }

      trainingPlan.trainingDays.push(trainingDay);
    }

    // Add any additional properties to the trainingPlan here
    // For example, trainingPlan.trainingPlanName = 'My Training Plan';

    return trainingPlan;
  }
}
