import { CheckinService } from 'src/app/services/checkin.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckinStatus, ClientCheckin } from 'src/app/core/thecoach';

@Component({
  selector: 'app-coach-dashboard-check-in-history',
  template: `
    <ng-container *ngIf="clientCheckins; else loading">
      <ul
        role="list"
        class="space-y-6"
        *ngIf="clientCheckins.length > 0; else noCheckins"
      >
        <div class="flex flex-row justify-between">
          <h1 class="text-xl font-semibold">Latest 5 Check Ins</h1>
          <button
            *ngIf="!onLoadAllCheckInsToggle"
            (click)="onClickLoadHistory(true)"
            type="button"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Show all
          </button>
          <button
            *ngIf="onLoadAllCheckInsToggle"
            (click)="onClickLoadHistory(false)"
            type="button"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Close History
          </button>
        </div>

        <li
          *ngFor="let checkin of clientCheckins; let last = last; let i = index"
          class="flex w-full flex-row"
        >
          <div
            class="relative w-full flex gap-x-2  cursor-pointer hover:border-1 hover:bg-gray-300 hover:border-gray-500 shadow-sm rounded-md bg-gray-100"
            (click)="onOutputCheckin(checkin)"
            [ngClass]="{ 'bg-gray-300': checkin === sCheckin }"
          >
            <div
              class="absolute left-0 top-0 flex w-6 justify-center -bottom-6"
              [ngClass]="{ '-bottom-6': !last }"
            >
              <div class="w-px bg-gray-200"></div>
            </div>
            <div
              class="relative flex h-6 w-6 flex-none items-center justify-center bg-white"
              [ngSwitch]="checkin?.status"
            >
              <span
                *ngSwitchCase="checkinStatus.Sent"
                class="h-2.5 w-2.5 rounded-full bg-yellow-100 ring-1 ring-yellow-300"
              ></span>

              <span
                *ngSwitchCase="checkinStatus.Answered"
                class="h-2.5 w-2.5 rounded-full bg-green-100 ring-1 ring-green-300"
              ></span>

              <span
                *ngSwitchCase="checkinStatus.Closed"
                class="h-2.5 w-2.5 rounded-full bg-green-100 ring-1 ring-green-300"
              ></span>

              <span
                *ngSwitchDefault
                class="h-2.5 w-2.5 rounded-full bg-red-100 ring-1 ring-red-300"
              ></span>
            </div>
            <div
              class="flex items-center flex-auto py-0.5 text-xs leading-5 text-gray-500 flex-row"
            >
              <span class="font-medium text-gray-900 pr-2">{{
                checkin.title ||
                  'Check In - ' + (checkin.date | date : 'dd-MM-yyyy')
              }}</span>
            </div>
            <p class="flex-none py-0.5 pr-4 text-xs leading-5 text-gray-500">
              {{ checkin.date | date : 'HH:mm | dd-MM-yyyy' }}
            </p>
          </div>
          <div class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 stroke-gray-500 cursor-pointer"
              (click)="toggleCheckinSubmenu(i)"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div class="relative" *ngIf="subMenuToggler && selectedIndex === i">
              <app-checkin-submenu
                [checkin]="checkin"
                (delete)="onDeleteCheckin($event)"
                (closeCheckin)="onCloseCheckin($event)"
                [isSubMenu]="subMenuToggler"
              ></app-checkin-submenu>
            </div>
          </div>
        </li>
      </ul>
      <ng-template #noCheckins>
        <h1 class="text-xl font-semibold">No Check Ins available</h1>
      </ng-template>
    </ng-container>

    <ng-template #loading>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>

    <div *ngIf="toggleDelete">
      <app-simple-modal
        [title]="'Delete Checkin?'"
        [message]="'Do you want to delete this checkin?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onDeleteCheckinModal($event)"
      >
      </app-simple-modal>
    </div>

    <div *ngIf="toggleCloseCheckin">
      <app-simple-modal
        [title]="'Close Checkin?'"
        [message]="'Do you want to close this checkin?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onCloseCheckinModal($event)"
      >
      </app-simple-modal>
    </div>
  `,
  styles: [],
})
export class CoachDashboardCheckInHistoryComponent {
  @Input() clientCheckins: ClientCheckin[] | null = null;
  @Output() selectedCheckin = new EventEmitter<ClientCheckin>();
  @Output() loadAllCheckIns = new EventEmitter<boolean>();

  constructor(private checkinServce: CheckinService) {}

  onLoadAllCheckInsToggle = false;
  subMenuToggler = false;
  selectedIndex: number | undefined;

  sCheckin: ClientCheckin | undefined;

  checkinStatus = CheckinStatus;
  toggleDelete = false;
  toggleCloseCheckin = false;

  onOutputCheckin(checkin: ClientCheckin) {
    this.sCheckin = checkin;
    this.selectedCheckin.emit(checkin);
  }

  onClickLoadHistory(value: boolean) {
    this.onLoadAllCheckInsToggle = value;
    this.loadAllCheckIns.emit(value);
  }

  toggleCheckinSubmenu(index: number) {
    if (index === this.selectedIndex) this.selectedIndex = undefined;
    this.selectedIndex = index;

    this.subMenuToggler = !this.subMenuToggler;
  }

  onDeleteCheckin(event: boolean) {
    if (event && (this.selectedIndex || this.selectedIndex === 0)) {
      this.toggleDelete = !this.toggleDelete;
    }
  }

  onCloseCheckin(event: boolean) {
    if (event && (this.selectedIndex || this.selectedIndex === 0)) {
      this.toggleCloseCheckin = !this.toggleCloseCheckin;
    }
  }

  onDeleteCheckinModal(event: boolean) {
    if (event && (this.selectedIndex || this.selectedIndex === 0)) {
      const deleteCheckin = this.clientCheckins?.at(this.selectedIndex);

      if (
        deleteCheckin &&
        this.sCheckin &&
        this.sCheckin.id === deleteCheckin.id
      ) {
        this.sCheckin = undefined;
        this.selectedCheckin.emit(undefined);
      }

      this.checkinServce
        .deleteClientCheckin(
          deleteCheckin?.clientId as string,
          deleteCheckin as ClientCheckin
        )
        .then(() => {
          console.log('deleted');
        })
        .catch((e) => {
          console.error('error', e);
        });
    }
    this.selectedIndex = undefined;

    this.toggleDelete = !this.toggleDelete;
  }

  onCloseCheckinModal(event: boolean) {
    if (event && (this.selectedIndex || this.selectedIndex === 0)) {
      const modifyCheckin = this.clientCheckins?.at(this.selectedIndex);

      if (modifyCheckin) {
        modifyCheckin.status = CheckinStatus.Closed;
        this.checkinServce.closeCheckin(modifyCheckin);
        this.selectedIndex = undefined;
      }

      this.toggleCloseCheckin = !this.toggleCloseCheckin;
    }
  }
}
