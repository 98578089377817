import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trainingsheet',
  template: `

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="px-8 sm:px-6 lg:px-8">

      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3 pl-1 pr-1 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6">Nr</th>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">Übung</th>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">Sets</th>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">Reps</th>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">RIR</th>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium tracking-wide text-gray-500">i</th>

                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td class="whitespace-nowrap py-4 pl-2 pr-1 text-sm font-medium text-gray-900 sm:pl-6">1</td>
                  <td class=" px-1 py-4 text-sm text-gray-500 break-words">DB One Arm Overhead Extension</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">12-11</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">2-3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">i</td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap py-4 pl-2 pr-1 text-sm font-medium text-gray-900 sm:pl-6">2</td>
                  <td class=" px-1 py-4 text-sm text-gray-500 break-words">DB Rear Delt Row</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">12-11</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">2-3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">i</td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap py-4 pl-2 pr-1 text-sm font-medium text-gray-900 sm:pl-6">3</td>
                  <td class=" px-2 py-4 text-sm text-gray-500 break-words">Inverted Row breit (Ringe oder BB)</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">12-11</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">2-3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">i</td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap py-4 pl-2 pr-1 text-sm font-medium text-gray-900 sm:pl-6">4</td>
                  <td class=" px-1 py-4 text-sm text-gray-500 break-words">Leg Curl Unilateral</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">12-11</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">2-3</td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">i</td>
                </tr>

                <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Trainingslog</h1>
          <p class="mt-2 text-sm text-gray-700">Hier Trainingstag eintragen.</p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">Neuer Trainingstag</button>
        </div>
      </div>
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full">
                <thead class="bg-white">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr class="border-t border-gray-200">
                  <th colspan="5" scope="colgroup" class="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">DATUM</th>
                </tr>

                <tr class="border-t border-gray-300">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Lindsay Walton</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Front-end Developer</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">lindsay.walton@example.com</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Member</td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
                  </td>
                </tr>

                <tr class="border-t border-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Courtney Henry</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Designer</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">courtney.henry@example.com</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Admin</td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Courtney Henry</span></a>
                  </td>
                </tr>

                <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class TrainingsheetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
