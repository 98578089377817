import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Exercise } from 'src/app/core/thecoach';

@Component({
  selector: 'app-template-training-mesocycle',
  template: `
    <div
      class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 rounded-lg shadow-lg pt-4 flex flex-col min-h-[5rem]"
    >
      <div class="flex w-full justify-end">
        <button
          (click)="onAddTrainingDay()"
          type="button"
          class="rounded-md ml-4 h-fit bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Add Trainingday
        </button>
        <button
          type="button"
          [routerLink]="'/tp-list/'"
          class="rounded-md ml-4 bg-yellow-600 h-fit px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        >
          Cancel
        </button>
      </div>

      <div
        class="flex w-full"
        *ngFor="let trainingday of trainingDays.controls"
      >
        <app-template-training-day
          class="flex w-full"
          [exerciseDB]="exerciseDB"
          [trainingday]="convertToFormGroup(trainingday)"
        ></app-template-training-day>
      </div>
    </div>
  `,
  styles: [],
})
export class TemplateTrainingMesocycleComponent {
  @Input({ required: true }) mesoForm!: FormGroup;
  @Input({ required: true }) exerciseDB!: Exercise[];
  constructor(private fb: FormBuilder) {}
  /* Example of the Formpassed Into the Child

      const meso = this.fb.group({
      id: [''],
      name: [mesoName, Validators.required],
      trainingDays: this.fb.array([]),
    });
*/

  get trainingDays() {
    return this.mesoForm.get('trainingDays') as FormArray;
  }

  convertToFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }
  onAddTrainingDay() {
    const trainingDay = this.fb.group({
      id: [''],
      name: ['', Validators.required],

      trainingSlots: this.fb.array([]),
    });

    this.trainingDays.push(trainingDay);
  }
}
