import { Component, OnInit } from '@angular/core';
import { Subscription, first } from 'rxjs';
import { AppUser, TrainingLog, TrainingPlan } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';

@Component({
  selector: 'app-tracking-training',
  template: `
    <div>
      <div class="border-b border-gray-200 pb-2" *ngIf="!selectedLog; else logSelected">
  <div class="-ml-2 -mt-2 flex flex-wrap items-baseline" >
    <h3 class="ml-2 mt-2 text-base font-semibold leading-6 text-gray-900">Traininglogs</h3>
    <p class="ml-2 mt-1 truncate text-sm text-gray-500">select to load...</p>
  </div>
</div>
   <ng-template #logSelected>
    <div class="border-b border-gray-200 pb-2 flex justify-between flex-row">
  <div class="-ml-2  flex flex-wrap items-center" >
    <h3 class="ml-2 text-base font-semibold leading-6 text-gray-900">Traininglog - {{getTrainingPlan(selectedLog?.trainingPlanId)?.trainingPlanName}}</h3>

  </div>
<button type="button" (click)="onClearLoadedLog()" class="rounded-md bg-white px-2.5 py-0.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Clear</button>
</div>
</ng-template>
  <ul role="list" class="space-y-3 pb-4 pt-2" *ngIf="!selectedLog">
        <li (click)="onSelectLog(log)" *ngFor="let log of loadedTrainingLogs" class="flex justify-between text-sm overflow-hidden bg-white px-4 py-3 shadow rounded-lg shadow-gray-400 sm:rounded-md sm:px-6">
            <p class="text-gray-900 font-semibold">{{getTrainingPlan(log.trainingPlanId)?.trainingPlanName }}</p>

            <p class="text-gray-400 italic">Last Edit: {{(getTrainingPlan(log.trainingPlanId)?.lastEdit | date: 'dd-MM-yyyy')}}</p>
  <!-- Your content -->
  </li>

  <!-- More items... -->
</ul>
  </div>
 <div *ngIf="selectedLog && client && selectedTrainingplan" class="pt-2">
    <app-training-table-dashboard [loadedTrainingPlan]="selectedTrainingplan" [selectedLog]="selectedLog" [client]="client"></app-training-table-dashboard>
</div> 
  `,
  styles: [
  ]
})
export class TrackingTrainingComponent implements OnInit {

  trainingLogSubscription: Subscription | undefined;

  client: AppUser | undefined = undefined;
  trainingPlanSubscription: Subscription | undefined;

  loadedTrainingLogs: TrainingLog[] | null = null;
  loadedTrainingPlans: TrainingPlan[] | null = null;

  selectedLog: TrainingLog | undefined = undefined;
  selectedTrainingplan: TrainingPlan | undefined = undefined;
  constructor(private trainingLogService: LogService,
    private trainingPlanService: TrainingplanService, private auth: AuthService) { }
  ngOnInit(): void {
    this.auth.appUser$.pipe(first()).subscribe(user => {
      if (user) {
        this.client = user;

        this.getTrainingLog(this.client);
        this.getTrainingPlans();

      }

    })

  }
  onSelectLog(log: TrainingLog) {
    this.selectedLog = log;
    this.selectedTrainingplan = this.getTrainingPlan(log.trainingPlanId)
  }
  getTrainingPlan(planId: string | undefined) {

    if (planId === undefined) return;

    return this.loadedTrainingPlans?.find(plan => plan.id === planId)
  }



  getTrainingLog(client: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getTrainingLogsForUser(client.id as string)
      .subscribe((val) => {
        if (val) {
          this.loadedTrainingLogs = val;
        }
      });
  }


  getTrainingPlans() {
    this.trainingPlanSubscription = this.trainingPlanService
      .getAllTrainingplansForUser()
      .subscribe((plans) => {
        this.loadedTrainingPlans = plans;
      })
  }

  onClearLoadedLog() {
    this.selectedLog = undefined;
    this.selectedTrainingplan = undefined;
  }

}









