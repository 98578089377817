import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppUser, SlotModel } from 'src/app/core/thecoach';
import { AuthService } from '../auth.service';
import { Observable, ReplaySubject, first, map, switchMap, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ModelService {
  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
  ) {
    this.currentModels$ = this.getCurrentUserModels();
  }

  currentModels$: Observable<SlotModel[]> | undefined;

  saveModel(model: SlotModel) {
    return new Promise<void>((resolve, reject) => {
      this.auth.appUser$.pipe(first()).subscribe((appUser) => {
        if (!appUser) {
          reject('No User auth!');
          return;
        }

        if (!model.id) {
          model.id = this.db.createId();
        }

        if (!model.ownerId) {
          model.ownerId = appUser.id as string;
        }

        return this.db
          .collection('slotModels')
          .doc(appUser.id)
          .collection('slotModels')
          .doc(model.id)
          .set(model)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  getModelForUserById(id: string) {
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        if (!appUser) return of(null);

        return this.db
          .collection('slotModels')
          .doc(appUser.id)
          .collection('slotModels')
          .doc<SlotModel>(id)
          .valueChanges();
      }),
      map((model: SlotModel | undefined | null) => {
        if (!model) {
          throw new Error('Model not found!');
        }

        return model;
      }),
    );
  }

  getCurrentUserModels() {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection('slotModels')
          .doc(appUser.id)
          .collection<SlotModel>('slotModels', (ref) =>
            ref.where('ownerId', '==', appUser.id),
          )
          .valueChanges();
      }),
    );
  }

  deleteModel(model: SlotModel) {
    return this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) return;

      return this.db
        .collection('slotModels')
        .doc(appUser.id)
        .collection('slotModels')
        .doc(model.id)
        .delete();
    });
  }
}
