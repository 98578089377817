import { UserService } from './../../services/user.service';
import { Component, OnDestroy, OnInit, Directive } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';
import { AppUser, convertDateObject } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription, first, throttleTime } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { deleteApp } from 'firebase/app';
import { format, parse, parseISO } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-coach-settings',
  template: `
    <div>
      <form
        class="space-y-8 divide-y divide-gray-200"
        [formGroup]="userForm"
        (ngSubmit)="onSave()"
      >
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div class="space-y-6 sm:space-y-5">
            <div>
              <h3 class="text-base font-semibold leading-6 text-gray-900">
                Your Profile
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Welcome to your personal profile, change settings here.
              </p>
            </div>

          <div class="flex flex-row w-full justify-between items-center">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="photo"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Photo</label
              >
              <div
                class="mt-2 ml-4 sm:col-span-2 sm:mt-0 flex flex-row justify-between"
              >
                <div class="flex items-center">
                  <span
                    class="h-12 w-12 overflow-hidden rounded-full  bg-gray-100"
                  >
                    <div *ngIf="!onCheckUserImgUrl()">
                      <svg
                        class="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </div>
                    <div *ngIf="onCheckUserImgUrl()">
                      <img src="{{ user.imgUrl }}" />
                    </div>
                  </span>
                  <label
                    for="file-upload"
                    class="ml-5 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </label>

                  <input
                    type="file"
                    id="file-upload"
                    class="hidden"
                    (change)="onChangeProfilePicture($event)"
                  />
                </div>
                <div *ngIf="this.file">
                  <app-upload-profile-picture
                    [file]="this.file"
                    class="hidden"
                    (imgUrl)="onChangeUserImgUrl($event)"
                  ></app-upload-profile-picture>
                </div>
              </div>
            </div>
           <div
              class="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
            <div class="flex flex-col text-center justify-center">
              <label
                for="photo"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Brand Logo</label
              >

              <span class="italic text-xs text-gray-400">1400x300px</span></div>
              <div
                class="mt-2 ml-4 sm:col-span-2 sm:mt-0 flex flex-row justify-between"
              >
                <div class="flex items-center">
                  <span
                    class="w-20 h-4  overflow-hidden  bg-gray-100"
                  >
                    <div *ngIf="!onCheckUserBrandUrl()">
                      <svg
                        class="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </div>
                    <div *ngIf="onCheckUserBrandUrl()">
                      <img src="{{ user.brandImgUrl || '.././assets/images/default-person-image.webp'}}"/>
                    </div>
                  </span>
                  <label
                    for="brand-file-upload"
                    class="ml-5 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">

                    Change
                  </label>

                  <input
                    type="file"
                    id="brand-file-upload"
                    class="hidden"
                    (change)="onChangeBrandPicture($event)"
                  />
                </div>
                <div *ngIf="this.brandFile">
                  <app-upload-brand-picture
                    [file]="this.brandFile"
                    class="hidden"
                    (imgUrl)="onChangeBrandImgUrl($event)"
                  ></app-upload-brand-picture>
                </div>
              </div>
            </div>

        </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Email</label
              >
              <div class="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  formControlName="email"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                  aria-describedby="email-description"
                />
              </div>
            </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Displayname</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  name="displayName"
                  id="displayName"
                  formControlName="displayName"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="John Wayne"
                />
              </div>
            </div>

            <div class="flex flex-row justify-between">
              <div>
                <label
                  for="id"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Your Coach ID</label
                >
                <div class="mt-2">
                  <button
                    type="button"
                    (click)="onCopyToClipboard(user.id!)"
                    class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {{ user.id }}
                  </button>
                </div>
                <p class="mt-2 text-sm text-gray-500" id="id">
                  Prvoide this ID to your clients for Synchronisation.
                </p>
              </div>

              <div *ngIf="showRegLink">
                <label
                  for="regLink"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Registration Link</label
                >

                <div class="mt-2">
                  <button
                    *ngIf="!user.registrationLinkId"
                    type="button"
                    (click)="onCopyToClipboard(createRegLink(user))"
                    class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Create
                  </button>
                  <div
                    *ngIf="user.registrationLinkId"
                    class="flex flex-row justify-between"
                  >
                    <button
                      type="button"
                      (click)="onCopyToClipboard(getRegLink(user))"
                      class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Copy to Clipboard
                    </button>
                    <button
                      type="button"
                      (click)="createNewRegLink(user)"
                      class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Create New
                    </button>
                  </div>
                </div>
                <p class="mt-2 text-sm text-gray-500" id="regLink">
                  Prvoide this Link to your Client for Registration.
                </p>
              </div>
            </div>

            <div class="mt-2 flex flex-row items-center">
              <div *ngIf="tokenCheck; else noPermission">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 stroke-green-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>

              <ng-template #noPermission>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 stroke-red-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </ng-template>
              <button
                *ngIf="tokenCheck"
                (click)="getPermToken()"
                type="button"
                class="ml-2 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Refresh Notification Permission
              </button>
              <button
                *ngIf="!tokenCheck"
                (click)="getPermToken()"
                type="button"
                class="ml-2 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Get Notification Permission
              </button>
            </div>

            <div>
              <label
                for="birthdate"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Birthdate</label
              >
              <div class="mt-2">
                <input
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  formControlName="birthdate"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end gap-x-3">
            <button
              type="button"
              class="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="inline-flex justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <app-notification
        *ngIf="toggleSave"
        [messageHeader]="'Settings saved'"
        [messageBody]="'Settings has been saved.'"
        (onbuttonClick)="changeToggle()"
      >
      </app-notification>

      <app-notification
        *ngIf="toggleNotificationCopyToClipboard"
        [messageHeader]="'Copy to clipboard'"
        [messageBody]="'Link has been copied to clipboard'"
        (onbuttonClick)="changeCopyToClipBoardToggle()"
      >
      </app-notification>
    </div>

    <app-simple-modal
      *ngIf="toggleCreateNewRegId"
      (close)="onCloseModal($event)"
      [title]="'Create new registration link?'"
      [message]="
        'Old registration links will be abandoned and pending clients cannot be retrived!'
      "
      [negativeButtonText]="'Cancel'"
      [positiveButtonText]="'Save'"
    >
    </app-simple-modal>
  `,
  styles: [],
})
export class CoachSettingsComponent implements OnInit, OnDestroy{
  user: AppUser = {};
  selelctedUser: AppUser = {};
  appUser$ = this.auth.appUser$;
  toggleSave = false;
  toggleCreateNewRegId = false;
  toggleNotificationCopyToClipboard = false;
  tokenCheck = false;

  userSubscription: Subscription | undefined = undefined;
  file: File | undefined;
  brandFile: File | undefined;

  showRegLink = false;
  constructor(
    protected auth: AuthService,
    private fb: FormBuilder,
    private clipboardService: ClipboardService,
    private userService: UserService,
    private db: AngularFirestore,
    private notification: NotificationService
  ) { }
    ngOnDestroy(): void {
      this.userSubscription?.unsubscribe();
  }

  userForm = this.fb.group({
    id: [''],
    email: new FormControl('', [Validators.required]),
    displayName: new FormControl(''),
    coachId: new FormControl(''),
    birthdate: new FormControl('', [Validators.required]),
    registrationLinkId: [''],
  });

  getPermToken() {
    this.notification.requestPermission();
    this.checkIfTokenExists();
  }

  onSave() {

    this.user = this.userForm.value as AppUser;



    const birthdateValue = this.userForm?.get('birthdate')?.value as string;

if (birthdateValue && birthdateValue.trim() !== '') {
    const birthdate = convertDateObject(birthdateValue);
    if (birthdate instanceof Date && !isNaN(birthdate.getTime())) {
        // Valid date, assign it to user
        this.user.birthdate = birthdate;
    } else {
        // Invalid date format, handle error
        console.error('Invalid date format for birthdate');
        // Optionally, display an error message to the user
    }
} else {
    // Birthdate is empty or null, handle this case if needed
    console.error('Birthdate is empty or null');
    // Optionally, display an error message or take some other action
}

    this.userService.saveUserData(this.user).then(() => {
      this.ngOnInit();
      this.toggleSave = !this.toggleSave;

    });


    // this.authService.SetUserData(this.userForm.value);
  }

  changeToggle() {
    this.toggleSave = !this.toggleSave;

  }

  changeCopyToClipBoardToggle() {
    this.toggleNotificationCopyToClipboard =
      !this.toggleNotificationCopyToClipboard;
  }

  onCloseModal(event: boolean) {
    if (event) {
      this.onCopyToClipboard(this.createRegLink(this.selelctedUser));
    }

    this.toggleCreateNewRegId = !this.toggleCreateNewRegId;
  }

  createNewRegLink(user: AppUser) {
    this.selelctedUser = user;
    this.toggleCreateNewRegId = !this.toggleCreateNewRegId;
  }

  ngOnInit(): void {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (appUser) {
        this.user = appUser as AppUser;
        this.patchOnLoad(this.user);
      }
    });
    this.checkIfTokenExists();
  }

  checkIfTokenExists() {
    this.notification
      .checkToken()
      .pipe(first())
      .subscribe((token: string | null) => {
        if (token === null || token === undefined) {
          this.tokenCheck = false;
        } else if (typeof token === 'string' && token.length > 0) {
          this.tokenCheck = true;
        }
      });
  }

  onChangeUserImgUrl(downloadUrl: string) {
    if (downloadUrl) {
      this.user.imgUrl = downloadUrl;
    }


  }

  onChangeBrandImgUrl(downloadUrl: string) {
    if (downloadUrl) {
      this.user.brandImgUrl = downloadUrl;
    }

  }

  onChangeProfilePicture(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      this.file = target.files[0];
    }
  }

  onChangeBrandPicture(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      this.brandFile = target.files[0];
    }

  }

  onCheckUserImgUrl(): boolean {
    if (this.user) {
      if (!this.user.imgUrl || this.user.imgUrl === '') {
        return false;
      }


      return true;
    }

    return false;
  }

  onCheckUserBrandUrl(): boolean {
    if (this.user) {
      if (!this.user.brandImgUrl || this.user.brandImgUrl === '') {
        return false;
      }


      return true;
    }

    return false;
  }

  onCopyToClipboard(value: string) {
    this.toggleNotificationCopyToClipboard =
      !this.toggleNotificationCopyToClipboard;
    this.clipboardService.copyFromContent(value);
  }

  createRegLink(user: AppUser): string {
    user.registrationLinkId = this.db.createId();
    this.userService.saveUserData(this.user);

    return (
      window.location.protocol +
      '//' +
      window.location.host +
      '/register/' +
      user.registrationLinkId
    );
  }

  getRegLink(user: AppUser): string {
    return (
      window.location.protocol +
      '//' +
      window.location.host +
      '/register/' +
      user.registrationLinkId
    );
  }

  patchOnLoad(user: AppUser) {

    if(user.birthdate){
  const birthdate =  convertDateObject(user.birthdate as Date);
    if(birthdate === undefined)
  {
      delete user.birthdate;
    }
    }

    if (user.email) {
      this.userForm.patchValue({
        email: user.email,
      });
    }

    if (user.birthdate) {
      this.userForm.patchValue({
        birthdate: format((user.birthdate as unknown as Timestamp).toDate(), 'yyyy-MM-dd'),
      });
    }

    if (user.displayName) {
      this.userForm.patchValue({
        displayName: user.displayName,
      });
    }

    if (user.coachId) {
      this.userForm.patchValue({
        coachId: user.coachId,
      });
    }


    if (user.id) {
      this.userForm.patchValue({
        id: user.id,
      });
    }

    if (user.registrationLinkId) {
      this.userForm.patchValue({ registrationLinkId: user.registrationLinkId });
    }

  }
}
