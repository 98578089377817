import { Meal } from '../../../../../backend/node_modules/@prisma/client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DisplayMacrosModuleComponent } from './../../common/display-macros-module/display-macros-module.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as firebase from 'firebase/compat/app';
import { Subject, debounceTime, first } from 'rxjs';
import {
  MealWithIngredients,
  manipulateNumbersDown,
} from '../../../../../backend/core/thecoach';
import { environment } from 'src/environments/environment';
import {
  calculateKcalFromMacros,
  patchIngredient,
} from 'src/app/core/thecoach';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';

@Component({
  selector: 'app-mealday-creator',
  template: `
    <div
      class="mx-auto max-w-7xl sm:px-6 lg:px-8  py-2 bg-gray-100 shadow sm:rounded-lg"
    >
      <!-- Content goes here -->
      <form [formGroup]="dayForm">
        <div class="flex flex-row w-full items-center justify-between">
          <div class="flex flex-row w-full items-center">
            <input
              type="text"
              name="name"
              id="name"
              formControlName="name"
              class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
              placeholder="Enter Day Name"
            />
            <button
              type="button"
              *ngIf="!newMealToggler"
              (click)="addNewMeal()"
              class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Add Meal
            </button>
          </div>
          <button
            type="button"
            (click)="onDeleteDay()"
            class="rounded-md flex flex-row whitespace-nowrap	 ml-4 bg-red-600  disabled:opacity-25 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Delete Day
          </button>
        </div>
      </form>
      <div *ngIf="newMealToggler" class="flex flex-row pt-2 pl-2 items-center">
        <input
          type="search"
          (input)="onSearchMeal(searchMealValue)"
          [(ngModel)]="searchMealValue"
          autocomplete="off"
          class="block w-5/12 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
          placeholder="Enter Search Meal..."
        />

        <button
          (click)="onSearchMealFromDB(searchMealValue)"
          type="button"
          class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <div
            *ngIf="searchMealToggler; else textView"
            class="flex flex-row items-center"
          >
            <object
              type="image/svg+xml"
              data="../../assets/icons/ring-resize.svg"
              class="w-5 h-5"
            ></object>
          </div>
          <ng-template #textView>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </ng-template>
        </button>
        <div class="absolute z-10 w-3/5 md:w-2/5 lg:w-[25rem]">
          <ul
            *ngIf="mealList && mealList.length > 0"
            class="absolute top-[1.25rem] z-20 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <li
              *ngFor="let meal of mealList; let j = index"
              (click)="onAddMealToDay(meal)"
              class="hover:text-white flex flex-row items-center hover:cursor-pointer hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
            >
              {{ meal.name }}
              <p class="pl-2 text-xs italic text-gray-400">
                {{ getMealInfo(meal) }}
              </p>
            </li>
          </ul>
        </div>
        <!--
    <button type="button" [routerLink]="'/mealplaner/daycreator/mealcreator/new'" class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Create new Meal</button>
-->
        <button
          type="button"
          (click)="onAddMeal()"
          class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Create new Meal
        </button>

        <button
          type="button"
          [routerLink]="'/ingredient-lib'"
          class="ml-4 rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Ingredient Library
        </button>
      </div>

      <form [formGroup]="dayForm">
        <div *ngFor="let meal of meals.controls; let i = index" class="pt-4">
          <app-meal-creator
            class="mb-2"
            [mealForm]="convertToFromGroup(meal)"
            (delete)="onDeleteMeal(i)"
          ></app-meal-creator>
        </div>

        <app-mealday-stats
          *ngIf="hasIngredientsWithAmount()"
          [dayForm]="dayForm"
        ></app-mealday-stats>
      </form>
    </div>
  `,
  styles: [],
})
export class MealdayCreatorComponent implements OnInit {
  @Input() dayForm!: FormGroup;
  @Input() index!: number;
  @Output() delete = new EventEmitter<number>();

  newMealToggler = false;
  searchMealValue: string = '';
  searchMealToggler = false;
  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)
  mealList: MealWithIngredients[] | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private httpUser: HttpUserService,
  ) {}
  ngOnInit(): void {
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchObj) => {
        this.perfromSearch(searchObj);
      });
  }

  get meals() {
    return this.dayForm.get('meals') as FormArray;
  }

  addNewMeal() {
    this.newMealToggler = !this.newMealToggler;
  }

  onDeleteDay() {
    this.delete.emit(this.index);
  }

  onAddMeal() {
    let mealForm = this.fb.group({
      name: ['', Validators.required],
      saveToDB: false,
      mealDescription: [''],
      addedIngredients: this.fb.array([]),
    });

    this.meals.push(mealForm);
  }

  onAddMealToDay(meal: MealWithIngredients) {
    let mealForm = this.fb.group({
      id: [''],
      name: [meal.name, Validators.required],
      saveToDB: false,
      mealDescription: [meal.mealDescription],
      addedIngredients: this.fb.array([]),
    });

    meal.addedIngredients.forEach((addedIng) => {
      (mealForm.get('addedIngredients') as FormArray).push(
        patchIngredient(addedIng, false),
      );
    });

    this.meals.push(mealForm);

    this.searchMealValue = '';
    this.mealList = undefined;
  }

  getMealInfo(meal: MealWithIngredients) {
    let totalProteins = 0;
    let totalCarbs = 0;
    let totalFats = 0;
    let totalKcal = 0;

    meal.addedIngredients.forEach((addedIng) => {
      const grams = addedIng.grams;
      totalProteins += (addedIng.ingredient.protein * grams) / 100 || 0;
      totalCarbs += (addedIng.ingredient.carb * grams) / 100 || 0;
      totalFats += (addedIng.ingredient.fat * grams) / 100 || 0;

      totalKcal +=
        Number(
          calculateKcalFromMacros({
            protein: totalProteins,
            carbs: totalCarbs,
            fat: totalFats,
          }),
        ) *
          (grams / 100) || 0;
    });

    return (
      'P: ' +
      totalProteins.toFixed(0) +
      'g C: ' +
      totalCarbs.toFixed(0) +
      'g F: ' +
      totalFats.toFixed(0) +
      'g Kcal: ' +
      totalKcal.toFixed(0)
    );
  }

  convertToFromGroup(control: AbstractControl) {
    return control as FormGroup;
  }

  onDeleteMeal(index: number): void {
    this.meals.removeAt(index);
  }

  onSaveDay() {
    console.log(this.dayForm);
  }

  toggleMealSearchIcon() {
    this.searchMealToggler = !this.searchMealToggler;
  }

  onSearchMeal(searchString: string) {
    this.searchSubject.next(searchString);
  }

  perfromSearch(searchString: string) {
    if (searchString) {
      this.toggleMealSearchIcon();
      this.searchMealFromDB(searchString);
    } else {
      this.toggleMealSearchIcon();
    }
  }

  onSearchMealFromDB(searchString: string) {
    this.toggleMealSearchIcon();
    this.searchMealFromDB(searchString);
  }

  searchMealFromDB(searchString: string) {
    this.mealList = [];
    const searchTerm = searchString;
    console.log('Searching for: ', searchString);
    if (searchTerm) {
      return this.httpUser.currentUser$.pipe(first()).subscribe((user) => {
        if (user) {
          return user
            .getIdToken(true)
            .then((idToken) => {
              const headers = new HttpHeaders({
                Authorization: 'Bearer ' + idToken,
              });
              this.http
                .get<
                  MealWithIngredients[]
                >(environment.apiUrl + '/meal-template/filtered-meals/' + searchTerm, { headers })
                .subscribe(
                  (data) => {
                    if (data) {
                      data.forEach((meal) => {
                        meal.addedIngredients.forEach((addedIng) => {
                          addedIng.ingredient = manipulateNumbersDown(
                            addedIng.ingredient,
                          );
                        });
                      });
                      this.mealList = data;
                      //console.log(this.mealList)
                    } else {
                      this.mealList = [];
                    }
                    this.toggleMealSearchIcon();
                  },
                  (error) => {
                    this.toggleMealSearchIcon();
                    console.error('Error', error);
                  },
                );
            })
            .catch((error) => {
              this.toggleMealSearchIcon();
              console.error('Error', error);
            });
        } else {
          this.toggleMealSearchIcon();
          console.error('No User signed in.');
          return null;
        }
      });
    } else {
      this.toggleMealSearchIcon();
      return null;
    }
  }

  // Function to check if any meal has an ingredient with amount set
  hasIngredientsWithAmount(): boolean {
    for (const meal of this.meals.controls) {
      const addedIngredients = (meal as FormGroup).get(
        'addedIngredients',
      ) as FormArray;

      for (const ingredient of addedIngredients.controls) {
        const amount = (ingredient as FormGroup).get('grams')?.value;
        if (
          amount !== null &&
          amount !== undefined &&
          amount !== '' &&
          amount !== 0
        ) {
          return true;
        }
      }
    }

    return false;
  }

  dayFormHasName() {
    const name = this.dayForm.get('name')?.value;
    if (name) {
      return true;
    } else {
      return false;
    }
  }

  sendMealToDB(meal: AbstractControl) {
    const mealData = meal.value;
    //console.log(mealData);

    this.httpUser.currentUser$.pipe(first()).subscribe((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          const headers = new HttpHeaders({
            Authorization: 'Bearer ' + idToken,
          });

          this.http
            .post(
              environment.apiUrl + '/meals',
              { meal: mealData },
              { headers: headers },
            )
            .subscribe((res) => {
              console.log('Server responded', res);
            }),
            (error: Error) => {
              console.error('Error:', error);
            };
        });
      }
    });
  }
}
