import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private refreshComponentSource = new Subject<void>();

  // Observable stream to subscribe for refresh events
  refreshComponent$ = this.refreshComponentSource.asObservable();

  // Method to trigger a refresh event
  refreshComponent() {
    this.refreshComponentSource.next();
  }
}
