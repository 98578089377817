import { Component, Input } from '@angular/core';
import { CustomCheckinFormTypeString, CustomCheckinFormValue } from 'src/app/core/thecoach';

@Component({
  selector: 'app-display-dashboard-checkin-text',
  template: `
 <div >
   <div class="flex flex-row justify-between items-baseline">
        <h2 class="text-lg font-medium w-fit">{{ccf.name}}</h2>
        <p class="pl-4 text-sm text-gray-500 flex-1 text-end italic">{{ccf.description}}</p>
     </div>
     <div class="border rounded-lg p-2 flex items-center gap-2">
          <span class="text-sm font-normal">{{ccf.value}}</span>
        </div>
      </div>

  `,
  styles: [
  ]
})
export class DisplayDashboardCheckinTextComponent {
@Input() ccf!:CustomCheckinFormTypeString;

}
