import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkin-generated-number-input',
  template: `

    <div *ngIf="ccf" [formGroup]="ccf">
      <div class="flex flex-row justify-between">
        <p class="font-semibold text-sm">{{ ccf.get('name')!.value }}</p>
        <div class="group flex relative items-center pl-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 "
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <span
            class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-[97%] translate-y-[1.1rem] opacity-0 m-4 mx-auto"
            >{{ ccf.get('description')!.value }}</span
          >
        </div>
      </div>
      <input
        type="text"
        formControlName="value"
        inputmode="decimal"
        step="any"
        class="block  w-full  rounded-md text-center border-1 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
        placeholder="Enter Number here..."
      />
    </div>
  `,
  styles: [
  ]
})
export class CheckinGeneratedNumberInputComponent {
  @Input() ccf!: FormGroup;
}
