import { Component } from '@angular/core';

@Component({
  selector: 'app-impressum',
  template: `
    <div class="max-w-3xl mx-auto p-4">
      <h4 class="text-lg font-bold">Impressum</h4>
      <p>
        <b
          >Informationen und Offenlegung gemäß &sect;5 (1) ECG, &sect; 25
          MedienG, &sect; 63 GewO und &sect; 14 UGB</b
        >
      </p>
      <p><b>Webseitenbetreiber:</b> Maciej Lech MSc., BSc.</p>
      <p><b>Anschrift:</b> Tendlergasse 13/13, 1090 Wien</p>
      <p>
        <b>UID-Nr:</b> ATU78488236 <br />
        <b>Gewerbeaufsichtbehörde:</b> Handelsgericht <br />
        <b>Mitgliedschaften:</b> Registergericht:Wien
      </p>
      <p>
        <b>Kontaktdaten:</b> <br />
        Telefon: [0043]-664-126-9010 <br />
        Email: office[at]thecoach.cloud <br />
        Fax:
      </p>
      <p>
        <b>Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at <br />
        <b>Berufsbezeichnung:</b>
      </p>
      <p>
        <b>Online Streitbeilegung:</b> Verbraucher, welche in Österreich oder in
        einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind, haben die
        Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder
        Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS, AStG)
        zu lösen. Die Europäische Kommission stellt eine Plattform hierfür
        bereit:
        <a href="https://ec.europa.eu/consumers/odr"
          >https://ec.europa.eu/consumers/odr</a
        >
      </p>
      <p>
        <b>Urheberrecht:</b> Die Inhalte dieser Webseite unterliegen, soweit
        dies rechtlich möglich ist, diversen Schutzrechten (z.B dem
        Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem
        Material, welche urheberrechtlich untersagt ist, bedarf schriftlicher
        Zustimmung des Webseitenbetreibers.
      </p>
      <p>
        <b>Haftungsausschluss:</b> Trotz sorgfältiger inhaltlicher Kontrolle
        übernimmt der Webseitenbetreiber dieser Webseite keine Haftung für die
        Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
        ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch auf
        ausgehende Links aufmerksam werden, welche auf eine Webseite mit
        rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um
        dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu
        entfernen.<br />Die Urheberrechte Dritter werden vom Betreiber dieser
        Webseite mit größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen
        werden wir den betroffenen Inhalt umgehend entfernen.
      </p>
      <p>
        Quelle:
        <b
          ><a
            href="https://www.rechtstexte-generator.at/dsgvo-generator-oesterreich/"
            >Datenschutz DSGVO Generator</a
          ></b
        >
        in Kooperation mit <br /><b
          ><a href="http://ortner-rechtsanwalt.at/"
            >Rechtsanwalt Wien Ortner</a
          ></b
        >
      </p>
    </div>
  `,
  styles: [],
})
export class ImpressumComponent {}
