import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Chart, ChartItem, ChartType } from 'chart.js/auto';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  fromUnixTime,
  getMonth,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import * as _ from 'lodash';
import { Subscription, last, of, pipe } from 'rxjs';
import {
  AppUser,
  Macros,
  WeightEntryData,
  calculateKcalFromMacros,
  convertFormToModel,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

interface ScheduleItem {
  id: number;
  name: string;
  location: string;
  from: Time;
  imgSrc: string;
  showMenu: boolean;
}

interface Time {
  datetime: string;
  text: string;
}

@Component({
  selector: 'app-calendar',
  template: `
    <div *ngIf="auth.appUser$ | async as appUser">
      <div class="flex flex-col items-center">
        <div class="mt-5 font-semibold text-xl">
          <h1>Weightlog</h1>
        </div>
        <div class="">
          <div
            class="mt-5 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9"
          >
            <div class="flex items-center text-gray-900">
              <button
                type="button"
                (click)="onChangeMonth(-1)"
                class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">Previous month</span>
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div class="flex-auto text-sm font-semibold">
                {{ currentMonth }}
              </div>
              <button
                type="button"
                (click)="onChangeMonth(1)"
                class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">Next month</span>
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div
              class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
            >
              <!--
          Always include: "py-1.5 hover:bg-gray-100 focus:z-10"
          Is current month, include: "bg-white" X
          Is not current month, include: "bg-gray-50" X
          Is selected or is today, include: "font-semibold"
          Is selected, include: "text-white"
          Is not selected, is not today, and is current month, include: "text-gray-900"
          Is not selected, is not today, and is not current month, include: "text-gray-400"
          Is today and is not selected, include: "text-teal-600"

          Top left day, include: "rounded-tl-lg"
          Top right day, include: "rounded-tr-lg"
          Bottom left day, include: "rounded-bl-lg"
          Bottom right day, include: "rounded-br-lg"
        -->

              <!--
            Always include: "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
            Is selected and is today, include: "bg-teal-600"
            Is selected and is not today, include: "bg-gray-900"
          -->

              <button
                *ngFor="let day of generatedDays"
                type="button"
                (click)="onSelectDay(day)"
                class="bg-white py-1.5 px-2 text-gray-900 hover:bg-gray-400 hover:text-slate-50 focus:z-10"
                [ngClass]="{
                  'font-semibold': isToday(day),
                  'text-teal-600': isToday(day) && !isEqual(day),
                  'bg-teal-600 text-slate-50': isToday(day) && isEqual(day),
                  'bg-white': isCurrentMonth(day) && !isEqual(day),
                  'bg-gray-50': !isCurrentMonth(day) && !isEqual(day),
                  'text-gray-900':
                    isCurrentMonth(day) && !isToday(day) && isEqual(day),
                  'text-gray-400':
                    !isCurrentMonth(day) && !isToday(day) && !isEqual(day),
                  'bg-gray-600 text-white': isEqual(day) && !isToday(day)
                }"
              >
                <time
                  datetime="2022-01-21"
                  class="mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                  >{{ day | date : 'dd' }}</time
                >
                <div class="flex flex-row">
                  <div class="w-2 h-2 mx-auto mt-1">
                    <div
                      class="w-2 h-2 rounded-full bg-amber-500 border border-0.5 border-gray-700"
                      *ngIf="onWeightEntryOnDay(day)"
                    ></div>
                  </div>
                  <div
                    class="w-2 h-2 mx-auto mt-1"
                    *ngIf="onCustomModelEntryOnDay(day)"
                  >
                    <div
                      class="w-2 h-2 rounded-full bg-purple-500 border border-0.5 border-gray-700"
                    ></div>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div
                    class="w-2 h-2 mx-auto mt-1"
                    *ngIf="onMacrosAchievedOnDay(day)"
                  >
                    <div
                      class="w-2 h-2 rounded-full bg-red-500 border border-0.5 border-gray-700"
                    ></div>
                  </div>
                  <div
                    class="w-2 h-2 mx-auto mt-1"
                    *ngIf="onSetDiffrentMacros(day, appUser)"
                  >
                    <div
                      class="w-2 h-2 rounded-full bg-blue-500 border border-0.5 border-gray-700"
                    ></div>
                  </div>
                </div>
              </button>
            </div>
            <div
              class="flex flex-row w-full mt-4"
              [ngClass]="{
                'justify-between': onWeightEntryOnDay(this.selectedDay),
                'justify-center': !onWeightEntryOnDay(this.selectedDay)
              }"
            >
              <button
                type="button"
                *ngIf="onWeightEntryOnDay(this.selectedDay)"
                (click)="onDeleteEntry()"
                class="w-fit h-fit flex rounded-md bg-red-600 text-sm items-center p-1 font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>

              <button
                *ngIf="
                  onWeightEntryOnDay(this.selectedDay) &&
                  !onCheckForAchievedMacros(this.selectedDay)
                "
                (click)="onAddMacros()"
                type="button"
                class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <img
                  class="w-6 h-6 invert"
                  src="../../assets/icons/macros-icon-V4.png"
                />
              </button>

              <button
                *ngIf="
                  onWeightEntryOnDay(this.selectedDay) &&
                  onCheckForAchievedMacros(this.selectedDay)
                "
                (click)="onAddMacros()"
                type="button"
                class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <img
                  class="w-6 h-6 invert"
                  src="../../assets/icons/macros-icon-V4-checked.png"
                />
              </button>

              <button
                *ngIf="
                  appUser.ctmId &&
                  this.selectedDayWeightEntry.at(0) &&
                  this.selectedDayWeightEntry.at(0)!.weight &&
                  !this.selectedDayWeightEntry.at(0)!.customTrackingLog
                "
                type="button"
                (click)="toggleCustomsEditMenu()"
                class="w-fit rounded-md h-fit  bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <img
                  class="w-6 h-6 invert"
                  src="../../assets/icons/3d-modeling-icon.png"
                />
              </button>

              <button
                *ngIf="
                  appUser.ctmId &&
                  this.selectedDayWeightEntry.at(0) &&
                  this.selectedDayWeightEntry.at(0)!.weight &&
                  this.selectedDayWeightEntry.at(0)!.customTrackingLog
                "
                type="button"
                (click)="toggleCustomsEditMenu()"
                class="w-fit rounded-md h-fit bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <img
                  class="w-6 h-6 invert"
                  src="../../assets/icons/3d-modeling-icon-checked.png"
                />
              </button>

              <button
                *ngIf="!onWeightEntryOnDay(this.selectedDay); else editday"
                type="button"
                (click)="toggleWeightEntryMenu()"
                class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <img
                  class="w-6 h-6 invert"
                  src="../../assets/icons/body-weight-scales-icon.png"
                />
              </button>
              <ng-template #editday>
                <button
                  type="button"
                  (click)="toggleWeightEntryMenu()"
                  class="w-fit  h-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  <img
                    class="w-6 h-6 invert"
                    src="../../assets/icons/maintain-weight-icon.png"
                  />
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="w-5/6 lg:w-2/6">
          <ol
            class="mt-4 divide-y divide-gray-400 text-sm leading-6 text-center items-center "
          >
            <li
              class="relative flex space-x-6 py-2 "
              *ngFor="let weightEntry of selectedDayWeightEntry; let j = index"
            >
              <div class="flex-auto" *ngIf="weightEntry.weight">
                <dl class="mt-2 flex flex-col text-gray-500 ">
                  <div class="flex items-start space-x-3">
                    <dt class="mt-0.5">
                      <svg
                        class="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </dt>
                    <dd>
                      <time datetime="2022-01-10T17:00">{{
                        weightEntry.date | date : 'MMMM d y - HH:mm'
                      }}</time>
                    </dd>
                  </div>
                  <div class="mt-2 flex items-start space-x-3 ">
                    <dt class="mt-0.5">
                      <div
                        class="mx-auto flex h-5 w-5 items-center justify-center rounded-full"
                      >
                        <img
                          src="../../assets/icons/maintain-weight-icon.png"
                        />
                      </div>
                    </dt>
                    <dd>{{ weightEntry.weight | number : '1.1-2' }} kg</dd>
                  </div>
                  <div
                    class="mt-2 flex items-start space-x-3 "
                    *ngIf="weightEntry.comment !== ''"
                  >
                    <dt class="mt-0.5">
                      <div
                        class="mx-auto flex h-5 w-5 items-center justify-center rounded-full"
                      >
                        <img src="../../assets/icons/comment-bubble-icon.png" />
                      </div>
                    </dt>
                    <dd class="flex text-justify">
                      <div class="">
                        {{ weightEntry.comment }}
                      </div>
                    </dd>
                  </div>
                  <div
                    class="mt-2 flex items-start space-x-3 "
                    *ngIf="weightEntry.customTrackingLog"
                  >
                    <dt class="mt-0.5">
                      <div
                        class="mx-auto flex h-5 w-5 items-center justify-center rounded-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                          />
                        </svg>
                      </div>
                    </dt>
                    <dd class="flex text-justify">
                      <ul class="">
                        <li
                          *ngFor="
                            let cctv of weightEntry.customTrackingLog.ccts
                          "
                        >
                          {{ cctv.name }} - {{ cctv.value }}
                        </li>
                      </ul>
                    </dd>
                  </div>
                  <div
                    class="mt-2 flex items-center w-full space-x-3 "
                    *ngIf="checkForMacros(weightEntry, appUser)"
                  >
                    <dd class="flex w-full ">
                      <div
                        *ngIf="showCharts"
                        class="flex w-full flex-col shadow rounded-lg"
                      >
                        <h3>ACHIEVED MACROS</h3>
                        <app-macrographs
                          [weightEntry]="this.selectedDayWeightEntry[0]"
                          [user]="this.user"
                        ></app-macrographs>
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
              <div
                class="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center"
              ></div>
            </li>

            <!-- Modal -->
          </ol>
        </div>
        <div class="w-full my-2">
          <div class="w-full border-t border-gray-200"></div>
        </div>
        <div *ngIf="true">
          <div>
            <div class="mt-4">
              <div
                class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                aria-label="Tabs"
              >
                <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                <button
                  (click)="onToggleWeightLog(appUser)"
                  class="rounded-l-lg group relative min-w-0 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                  [ngClass]="{
                    'text-gray-900': weightLogtoggle,
                    'text-gray-500': !weightLogtoggle
                  }"
                >
                  <span>Weightlog</span>
                  <span
                    class=" absolute inset-x-0 bottom-0 h-0.5"
                    [ngClass]="{
                      'bg-teal-500 text-gray-900 ': weightLogtoggle,
                      'bg-transparent text-gray-500': !weightLogtoggle
                    }"
                  ></span>
                </button>

                <button
                  *ngIf="appUser.ctmId"
                  (click)="onToggleCustomLog()"
                  class="group relative min-w-0 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                  [ngClass]="{
                    'text-gray-900': customLogtoggle,
                    'text-gray-500': !customLogtoggle
                  }"
                >
                  <span>Custom Log</span>
                  <span
                    class="absolute inset-x-0 bottom-0 h-0.5"
                    [ngClass]="{
                      'bg-teal-500 text-gray-900 ': customLogtoggle,
                      'bg-transparent': !customLogtoggle,
             
                    }"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-full flex mt-4 justify-center items-center"
          *ngIf="weightLogtoggle"
        >
          <table class="divide-y divide-gray-300">
            <thead>
              <tr class="divide-x divide-gray-200">
                <th
                  scope="col"
                  class=" text-center text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="text-center text-sm font-semibold text-gray-900"
                >
                  Weight
                </th>
                <th
                  scope="col"
                  class=" text-center text-sm font-semibold text-gray-900"
                >
                  Protein
                </th>
                <th
                  scope="col"
                  class="text-center text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Carbs
                </th>
                <th
                  scope="col"
                  class=" text-center text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Fat
                </th>
                <th
                  scope="col"
                  class=" text-center text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Kcal
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                class="divide-x divide-gray-200"
                *ngFor="let log of weighEntryDatabase"
              >
                <td
                  class="whitespace-nowrap text-xs font-medium px-1 text-gray-900"
                >
                  {{ log.date | date : 'dd-MM-yyyy' }}
                </td>
                <td
                  class="whitespace-nowrap px-1 text-xs text-gray-500 text-center"
                  *ngIf="log.weight; else nokg"
                >
                  {{ log.weight }}kg
                </td>
                <ng-template #nokg>
                  <td
                    class="whitespace-nowrap px-1 text-xs text-gray-500 text-center"
                  >
                    -
                  </td>
                </ng-template>
                <td
                  class="whitespace-nowrap px-1 text-xs text-gray-500 text-center"
                >
                  {{ log.setMacros?.protein }}/{{ log.achivedMacros?.protein }}
                </td>
                <td
                  class="whitespace-nowrap px-1 text-xs text-gray-500 text-center"
                >
                  {{ log.setMacros?.carbs }}/{{ log.achivedMacros?.carbs }}
                </td>
                <td
                  class="whitespace-nowrap px-1 text-xs text-gray-500 text-center"
                >
                  {{ log.setMacros?.fat }}/{{ log.achivedMacros?.fat }}
                </td>
                <td
                  class="whitespace-nowrap  px-1 text-xs text-gray-500 text-center"
                >
                  {{ calculateKcal(log.setMacros) }}/{{
                    calculateKcal(log.achivedMacros)
                  }}
                </td>
              </tr>

              <!-- More people... -->
            </tbody>
          </table>
        </div>

        <div *ngIf="appUser.ctmId && customLogtoggle">
          <div
            *ngIf="
              this.ctmService.getModelByID(appUser)
                | async as customTrackingModel
            "
          >
            {{ log(customTrackingModel, customLogtoggle) }}
            <div class="w-full flex mt-4" *ngIf="dataLoaded">
              <table class="w-full divide-y divide-gray-300">
                <thead>
                  <tr class="divide-x divide-gray-200">
                    <th
                      scope="col"
                      class=" text-center text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Date
                    </th>
                    <th
                      *ngFor="let ctv of customTrackingModel.ccts"
                      scope="col"
                      class="text-center text-sm font-semibold text-gray-900  max-w-xs px-1"
                    >
                      <div *ngIf="ctv.name.length <= 8">
                        {{ ctv.name }}
                      </div>

                      <div
                        class=" group flex relative items-center justify-center"
                        *ngIf="ctv.name.length > 8"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </svg>
                        <span
                          class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
                -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                        >
                          {{ ctv.name }}</span
                        >
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    class="divide-x divide-gray-200"
                    *ngFor="let log of logEntryDatabase"
                  >
                    <ng-container
                      *ngIf="
                        log.customTrackingLog && log.customTrackingLog.ccts
                      "
                    >
                      <td
                        class="whitespace-nowrap text-xs font-medium px-1 text-gray-900"
                      >
                        {{ log.date | date : 'dd-MM-yyyy' }}
                      </td>
                      <td
                        class="whitespace-wrap px-1 text-xs text-gray-500 text-center"
                        *ngFor="let cct of log.customTrackingLog.ccts"
                      >
                        <ng-container *ngIf="cct.type !== 'boolean'">
                          {{ cct.value }}
                        </ng-container>

                        <ng-container *ngIf="cct.type === 'boolean'">
                          <ng-container *ngIf="cct.value === true">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4 stroke-green-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </ng-container>
                          <ng-container
                            *ngIf="cct.value === false || !cct.value"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4 stroke-red-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </ng-container>
                        </ng-container>
                      </td>
                    </ng-container>
                  </tr>

                  <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          *ngIf="showWeightEntryMenu"
          id="modal-background"
          @AnimationTrigger0
          class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
        >
          <app-weight-entry
            (onClick)="onClickEvent($event)"
            (onSaveData)="onSaveDataToServer($event)"
            @AnimationTrigger1
            *ngIf="showWeightEntryMenu"
            class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
            [day]="this.selectedDay"
            [weightEntry]="this.selectedDayWeightEntry[0]"
          ></app-weight-entry>
        </div>
      </div>

      <div
        *ngIf="macrosModalToggle"
        id="modal-background"
        @AnimationTrigger0
        class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
      >
        <app-macro-entry-modal
          class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
          (onSaveData)="onSaveMacrosToServer($event)"
          (onClick)="onCancelEvent($event)"
          [day]="this.selectedDay"
          [weightEntry]="this.selectedDayWeightEntry[0]"
        >
        </app-macro-entry-modal>
      </div>

      <div
        *ngIf="
          appUser.ctmId &&
            this.selectedDayWeightEntry.length > 0 &&
            !this.selectedDayWeightEntry.at(0)!.customTrackingLog &&
            (this.ctmService.getModelByID(appUser)
              | async) as customTrackingModel;
          else editModel
        "
      >
        <app-custom-model-modal
          *ngIf="customModalToggle"
          [ctm]="customTrackingModel"
          (close)="onCloseTrackingModal($event.event, $event.form, appUser)"
        >
        </app-custom-model-modal>
      </div>

      <ng-template #editModel>
        <app-custom-model-modal
          *ngIf="customModalToggle"
          [ctm]="this.selectedDayWeightEntry.at(0)!.customTrackingLog"
          (close)="onCloseTrackingModal($event.event, $event.form, appUser)"
        >
        </app-custom-model-modal>
      </ng-template>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate(
          '75ms ease-in',
          style({ opacity: 0, transform: 'scale(0.95)' })
        ),
      ]),
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class CalendarComponent implements OnInit, OnDestroy {
  currentAppointment: ScheduleItem | undefined;
  today = startOfToday();
  selectedDay = this.today;
  currentMonth = format(this.today, 'MMMM - yyyy');
  firstDayOfCurrentMonth = parse(this.currentMonth, 'MMMM - yyyy', new Date());
  generatedDays: Date[] = [];
  selectedDayAppointments: ScheduleItem[] = [];
  selectedDayWeightEntry: WeightEntryData[] = [];
  showWeightEntryMenu = false;
  weightLogSubscription: Subscription | undefined;
  weighEntryDatabase: WeightEntryData[] = [];
  logEntryDatabase: WeightEntryData[] = [];
  user: AppUser = {};

  showCharts = false;
  dataLoaded = false;

  customModalToggle = false;
  weightLogtoggle = true;
  customLogtoggle = false;
  macrosModalToggle = false;
  run = true;

  constructor(
    private weightLogService: WeightlogService,
    protected auth: AuthService,
    protected ctmService: CustomTrackingModelService
  ) {}

  log(val: any, other: any) {
    if (this.run) {
      // console.log(val);
      // console.log(other);
      this.run = false;
    }
  }

  ngOnInit(): void {
    this.createDates();

    this.loadWeightLogData();

    //this.onSelectDay(this.today);
  }

  onToggleWeightLog(appuser: AppUser) {
    //  console.log(appuser);
    //  console.log(this.weighEntryDatabase);
    this.weightLogtoggle = !this.weightLogtoggle;
    if (this.customLogtoggle) this.customLogtoggle = !this.customLogtoggle;
  }

  onToggleCustomLog() {
    this.customLogtoggle = !this.customLogtoggle;
    if (this.weightLogtoggle) this.weightLogtoggle = !this.weightLogtoggle;
  }

  loadWeightLogData(): void {
    this.weightLogSubscription = this.weightLogService
      .getWeightLogForClientOfXDaysFuture(35)
      .subscribe((data) => {
        data.forEach((item) => {
          if (item.date) {
            item.date = (item.date as unknown as Timestamp).toDate();
          }
        });

        this.weighEntryDatabase = data;
        this.logEntryDatabase = this.weighEntryDatabase.filter((entry) => {
          if (entry.customTrackingLog) return true;

          return false;
        });
        this.onSelectDay(this.selectedDay);
        this.dataLoaded = true;
      });
  }

  isToday(date: Date): boolean {
    return isToday(date);
  }

  createDates() {
    this.generatedDays = [];
    this.generatedDays = eachDayOfInterval({
      start: startOfWeek(this.firstDayOfCurrentMonth, { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(this.firstDayOfCurrentMonth), {
        weekStartsOn: 1,
      }),
    });
  }

  isCurrentMonth(date: Date): boolean {
    return isSameMonth(date, this.firstDayOfCurrentMonth);
  }

  isEqual(date: Date) {
    return isEqual(date, this.selectedDay);
  }

  onSelectDay(date: Date): void {
    this.selectedDay = date;
    // this.filterAppointmentsforDate(date);
    this.filterWeightEntryDataForDate(date);
  }

  onDeleteEntry() {
    if (this.selectedDayWeightEntry[0].refeedMacros) {
      let emptyEntry = new WeightEntryData();
      emptyEntry.date = this.selectedDay;
      emptyEntry.refeedMacros = _.cloneDeep(
        this.selectedDayWeightEntry[0].refeedMacros
      );
      this.weightLogService.overWriteLogAsCustomer(emptyEntry);
    } else {
      this.weightLogService.deleteDataPointAsCustomer(this.selectedDay);
    }
  }

  filterWeightEntryDataForDate(date: Date) {
    this.selectedDayWeightEntry = this.weighEntryDatabase.filter((entry) => {
      if (!entry.date) return null;

      if (isSameDay(date, entry.date)) {
        return entry;
      }

      return null;
    });
  }

  onClickEvent(event: boolean) {
    this.toggleWeightEntryMenu();
  }

  onSaveDataToServer(data: WeightEntryData) {
    this.weightLogService.addDataToLog(this.selectedDay, data);

    this.onSelectDay(this.selectedDay);
    this.toggleWeightEntryMenu();
  }

  onSaveMacrosToServer(data: WeightEntryData) {
    this.weightLogService.addDataToLog(this.selectedDay, data);

    this.macrosModalToggle = !this.macrosModalToggle;
  }

  onWeightEntryOnDay(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.weight;
    });
  }

  onCheckForAchievedMacros(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.achivedMacros;
    });
  }

  onCustomModelEntryOnDay(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.customTrackingLog;
    });
  }

  onAddMacros() {
    this.macrosModalToggle = !this.macrosModalToggle;
  }

  onMacrosAchievedOnDay(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.achivedMacros;
    });

    /*
    let check = false;
    this.weighEntryDatabase.forEach((entry) => {
      if (isSameDay(day, entry.date as Date)) {
        if (entry.achivedMacros) {
          check = true;
        }
      }
    });

    return check;
    */
  }

  onSetDiffrentMacros(day: Date, user: AppUser) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;

      if (!user.setMacros) return null;

      return isSameDay(day, entry.date) && entry.refeedMacros;
    });
  }

  checkMacros(newMacros: Macros, oldMacros: Macros) {
    if (
      newMacros &&
      oldMacros &&
      newMacros.carbs &&
      oldMacros.carbs &&
      newMacros.protein &&
      oldMacros.protein &&
      newMacros.fat &&
      oldMacros.fat &&
      newMacros.carbs === oldMacros.carbs &&
      newMacros.protein === oldMacros.protein &&
      newMacros.fat === oldMacros.fat
    )
      return false;

    return true;
  }

  onChangeMonth(amount: number) {
    this.firstDayOfCurrentMonth = parse(
      this.currentMonth,
      'MMMM - yyyy',
      new Date()
    );

    this.firstDayOfCurrentMonth = add(this.firstDayOfCurrentMonth, {
      months: amount,
    });
    this.currentMonth = format(this.firstDayOfCurrentMonth, 'MMMM - yyyy');
    this.createDates();
  }

  /*
  onToggleWeightEntryDropdown(id: string | undefined) {
    const indexOfDropdownEntry = this.gewichtsDaten.findIndex(
      (entry) => entry.id === id
    );
    this.gewichtsDaten[indexOfDropdownEntry].showMenu =
      !this.gewichtsDaten[indexOfDropdownEntry].showMenu;
  }
*/

  onEntryEdit(id: string | undefined) {
    if (!id) return null;

    return id;
  }

  onCancelEvent(event: boolean) {
    this.macrosModalToggle = !this.macrosModalToggle;
  }

  toggleWeightEntryMenu() {
    this.showWeightEntryMenu = !this.showWeightEntryMenu;
  }

  ngOnDestroy(): void {
    this.weightLogSubscription?.unsubscribe();
  }

  checkForMacros(data: WeightEntryData, user: AppUser) {
    if (!data || !data.achivedMacros) {
      return false;
    }

    this.user = user;
    this.showCharts = true;
    return true;
  }

  calculateKcal(macros: Macros | undefined) {
    if (!macros) return null;
    return calculateKcalFromMacros(macros);
  }

  toggleCustomsEditMenu() {
    this.customModalToggle = !this.customModalToggle;
  }

  onCloseTrackingModal(
    event: boolean,
    form: FormGroup | undefined,
    user: AppUser
  ) {
    this.customModalToggle = !this.customModalToggle;
    if (event) {
      const ctModel = convertFormToModel(form as FormGroup);
      if (this.selectedDayWeightEntry) {
        this.selectedDayWeightEntry[0].customTrackingLog = ctModel;

        /*  console.log(
          this.selectedDayWeightEntry[0],
          this.selectedDayWeightEntry
        );*/
        this.weightLogService.addSpecificParametersToLog(
          user.id as string,
          this.selectedDayWeightEntry[0]
        );
      }
    }
  }
}
