import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { AppUser, convertDateObject } from '../core/thecoach';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  Observable,
  ReplaySubject,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { isValid } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private db: AngularFirestore) {}
  /*
  saveUserData(user: AppUser) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.id}`
    );

    return userRef.set(user, { merge: true });
  }
*/
  saveUserData(user: AppUser) {
    if (
      user.birthdate &&
      user.birthdate !== undefined &&
      user.birthdate !== null &&
      isValid(user.birthdate)
    ) {
      user.birthdate = convertDateObject(user.birthdate as Date);
    } else {
      delete user.birthdate;
    }
    return this.db
      .collection('users')
      .doc(user.id)
      .set(user, { merge: true })
      .then(() => {
        //console.log(`User ${user.id} updated successfully.`, user);
      })
      .catch((error) => {
        console.error(`Error updating user ${user.id}:`, error);
      });
  }

  overWriteUser(user: AppUser) {
    if (
      user.birthdate &&
      user.birthdate !== undefined &&
      user.birthdate !== null &&
      isValid(user.birthdate)
    ) {
      user.birthdate = convertDateObject(user.birthdate as Date);
    } else {
      delete user.birthdate;
    }
    return this.db.collection('users').doc(user.id).set(user, { merge: false });
  }

  /*
  getCoachFromDatabase() {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection<AppUser>('users')
          .doc(appUser.id)
          .valueChanges();
      })
    );
  }
*/
  getUserFromDatabase(userId: string) {
    return this.db
      .collection<AppUser>('/users')
      .doc(userId)
      .valueChanges()
      .pipe(
        map((user) => {
          if (user && user.birthdate)
            user.birthdate = convertDateObject(user.birthdate as Date);

          return user;
        }),
      );
  }

  getUsersFromDatabaseOnDay(userId: string, checkInDayIndex: number) {
    return this.db
      .collection<AppUser>('/users', (ref) => {
        return ref.where('checkInWeekdayIndex', '==', checkInDayIndex);
      })
      .doc(userId)
      .valueChanges();
  }

  getUserCollectionFromDatabase(userId: string) {
    return this.db.collection<AppUser>('/users').doc(userId);
  }

  getClientsFromDatabase(user: AppUser) {
    if (user && user.registrationLinkId) {
      return this.db
        .collection('/users', (ref) => {
          return ref
            .where('registrationLinkId', '==', user.registrationLinkId)
            .where('role', '==', 'client');
        })
        .valueChanges();
    } else {
      return of([]);
    }
  }

  getPendingClientsFromDatabase(user: AppUser) {
    if (user) {
      //console.log('passeduser', user);
      return this.db
        .collection('users', (ref) => {
          return ref
            .where('coachId', '==', user.id)
            .where('pending', '==', true)
            .where('role', '==', 'client');
        })
        .valueChanges();
    } else {
      return of([]);
    }
  }

  deletePendingClientFormDatabase(clientId: string) {
    return this.db
      .collection<AppUser>('users')
      .doc(clientId)
      .delete()
      .then(() => {
        //console.log(`User with ID ${clientId} deleted successfully.`);
      })
      .catch((error) => {
        console.error(`Error deleting user with ID ${clientId}:`, error);
      });
  }

  getAllClientsFromDatabase() {
    return this.db
      .collection<AppUser>('/users', (ref) => {
        return ref.where('role', '==', 'client');
      })
      .valueChanges()
      .pipe(
        tap((clients) => {
          //  console.log('Clients:', clients);
        }),
      );
  }
}
