import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import {
  ClientCheckin,
  Message,
  formatUrlsWithLinks,
} from 'src/app/core/thecoach'; import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-checkin-message',
  template: `
    <div
      *ngFor="let message of checkIn.messages"
      class="text-sm pb-2 flex w-full"
      [ngClass]="{
        'justify-start': checkOriginalAuthor(checkIn, message),
        'justify-end': !checkOriginalAuthor(checkIn, message)
      }"
    >
      <div
        *ngIf="!checkOriginalAuthor(checkIn, message); else notOriginalAuthor"
        longPress
        (holdTime)="onLongPress($event, checkIn, message)"
        class="whitespace-pre-wrap break-all rounded-md bg-green-50 px-6 py-4 shadow max-w-full"
      >
        <div class="relative w-full">
          <div class="absolute invisible md:visible -top-2 -right-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 stroke-gray-500"
              (click)="toggleFlyoutMenu(message)"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div
              class="relative w-fit -top-4 -left-44"
              *ngIf="isFlyoutMenu && selectedMessage === message"
            >
              <app-flyout-msg-dashboard-menu
                (delete)="
                  onDeleteMsg(
                    $event,

                    checkIn,
                    selectedMessage
                  )
                "
                (copyToClipboard)="
                  onCopyToClipboardMsg($event, selectedMessage)
                "
                [isFlyoutMenu]="isFlyoutMenu"
                [message]="selectedMessage"
              ></app-flyout-msg-dashboard-menu>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!message.deletedFlag">
          <ng-container *ngIf="!message.voiceUrl">
            <div
              class="break-normal pt-4"
              [innerHTML]="frmtUrlsWithLinks(message.message)"
            ></div>
          </ng-container>
          <ng-container *ngIf="message.voiceUrl">
            <app-voice-player
              [audioBlobUrl]="message.voiceUrl"
            ></app-voice-player>
          </ng-container>
          <div class="text-right pt-1">
            <p class="italic text-xs font-light">
              {{ message.author }} -
              {{ message.date | date : 'dd-MM-yyyy' }}
            </p>
          </div>
          <div
            class="relative w-fit visible md:invisible"
            *ngIf="isFlyoutMenu && selectedMessage === message"
          >
            <app-flyout-msg-dashboard-menu
              (delete)="
                onDeleteMsg(
                  $event,

                  checkIn,
                  selectedMessage
                )
              "
              (copyToClipboard)="onCopyToClipboardMsg($event, selectedMessage)"
              [isFlyoutMenu]="isFlyoutMenu"
              [message]="selectedMessage"
            ></app-flyout-msg-dashboard-menu>
          </div>
          <div
            *ngIf="isFlyoutMenu && selectedMessage === message"
            (click)="toggleFlyoutMenu()"
            class="fixed inset-0"
          ></div>
        </ng-container>
        <ng-container *ngIf="message.deletedFlag">
          <p class="italic text-xs font-light">Has been deleted.</p>
        </ng-container>
      </div>

      <ng-template #notOriginalAuthor>
        <div class="flex justify-right">
          <div
            class="whitespace-pre-wrap break-all rounded-md bg-blue-50 px-6 py-4 shadow max-w-full "
          >
            <ng-container *ngIf="!message.deletedFlag">
              <ng-container *ngIf="!message.voiceUrl">
                <div
                  class="break-normal"
                  [innerHTML]="frmtUrlsWithLinks(message.message)"
                ></div>
              </ng-container>
              <ng-container *ngIf="message.voiceUrl">
                <app-voice-player
                  [audioBlobUrl]="message.voiceUrl"
                ></app-voice-player>
              </ng-container>
              <div class="text-left pt-1">
                <p class="italic text-xs font-light">
                  {{ message.author }} -
                  {{ message.date | date : 'dd-MM-yyyy' }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="message.deletedFlag">
              <p class="italic text-xs font-light">Has been deleted.</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: [],
})
export class CheckinMessageComponent {
  @Input() checkIn!: ClientCheckin;
  // false == client, true === coach
  @Input() customer!: boolean;
  @Output() ondelete = new EventEmitter<{
    checkin: ClientCheckin;
    msg: Message;
  }>();

  isFlyoutMenu = false;
  selectedMessage: Message | undefined;

  constructor(
    private clipBoardService: ClipboardService,
    private sanitizer: DomSanitizer
  ) { }

  checkOriginalAuthor(checkin: ClientCheckin, message: Message): boolean {
    if (this.customer) {
      return !this.checkAuthor(checkin, message);
    } else {
      return this.checkAuthor(checkin, message);
    }
  }

  checkAuthor(checkin: ClientCheckin, message: Message): boolean {
    if (!checkin.messages) return false;

    if (!message.authorId) {
      if (checkin.messages[0].author === message.author) return true;
    } else {
      if (checkin.messages[0].authorId === message.authorId) return true;
    }

    return false;
  }

  onLongPress(event: number, checkin: ClientCheckin, message: Message) {
    if (window.innerWidth < 600 && event > 500) {
      this.selectedMessage = message;
      this.isFlyoutMenu = true;
    }
  }

  toggleFlyoutMenu(message?: Message) {
    if (message) this.selectedMessage = message;
    this.isFlyoutMenu = !this.isFlyoutMenu;
  }

  onCopyToClipboardMsg(event: boolean, msg: Message) {
    if (event && msg.message) {
      this.clipBoardService.copyFromContent(msg.message);
    }
    this.toggleFlyoutMenu();
  }

  onDeleteMsg(event: boolean, checkin: ClientCheckin, msg: Message) {
    if (event) {
      this.ondelete.emit({ checkin: checkin, msg: msg });
    }
  }

  frmtUrlsWithLinks(text: string | undefined): string {
    return formatUrlsWithLinks(text);
  }
}
