import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, interval, takeUntil } from 'rxjs';

@Component({
  selector: 'app-timer',
  template: `

<div class="flex flex-row justify-center items-baseline py-4">
<div class="flex flex-row items-center pr-4">
    <p class="font-semibold italic text-gray-500">Timer:</p>
  <span class="pl-4">{{formatTime()}}</span>
</div>

<div class="flex gap-4 items-baseline">
<button type="button" *ngIf="!running" (click)="onStart()" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
</svg>

  </button>

<button type="button" *ngIf="running" (click)="onPause()" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">


<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>



  </button>



<button type="button" (click)="onReset()" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

  </button>

</div>
<div>

`,
  styles: [
  ]
})
export class TimerComponent  implements OnInit, OnDestroy{

  running = false;
  stopWatch$:Observable<number> | undefined = undefined;
  private onDestroy$: Subject<void> = new Subject<void>();
  time:number = 0;


  ngOnInit(): void {

    this.stopWatch$ = interval(1000).pipe(
      takeUntil(this.onDestroy$)
    )
  }


  ngOnDestroy(): void {
  this.onDestroy$.next();
    this.onDestroy$.complete();  }

onStart(){
    if(!this.running && this.stopWatch$){

      this.stopWatch$.subscribe(()=>{
        this.time++;
      })
      this.running = true;
    }
  }

onPause(){
      this.onDestroy$.next();
    this.running = false;
  }


onReset(){
this.time = 0
  }


  formatTime(){

      return new Date(this.time *1000).toISOString().substring(11,19)

    }





}
