import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { first } from 'rxjs';
import { CustomCheckinFormType, CustomCheckinFormTypeNumber, CustomCheckinFormTypeRange, CustomCheckinFormTypeString, CustomCheckinFormValue, CustomCheckinModel, CustomInputType, customCheckinInputToggler } from 'src/app/core/thecoach';
import { CustomCheckinModelService } from 'src/app/services/custom-checkin-model.service';

@Component({
  selector: 'app-custom-checkin-forms-creator',
  template: `
    <form
    [formGroup]="customCheckinFormModel"
    class="flex flex-row max-w-md mx-auto">
    <div class="flex flex-col w-full">
  <div class="mb-4">
    <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
    <input type="text" id="name" formControlName="name" class="mt-1 focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
  </div>
  <div class="mb-4">
    <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
    <textarea id="description" formControlName="description" class="mt-1 focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
  </div>
</div>
    <div class="flex flex-col w-fit justify-around">

              <button type="button"  (click)='saveCustomCheckinModel()' [disabled]="!customCheckinFormModel.valid" class="disabled:opacity-25 rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Save</button>

              <button type="button"  [routerLink]="'/coach-custom-checkin-form'" class="rounded-md ml-4 bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">Cancel</button>
    </div>
</form>

<app-custom-checkin-inputs-creator (addToArray)="onAddToArray($event)" [currentLength]="CCFS.length"></app-custom-checkin-inputs-creator>
<app-custom-checkin-inputs-view [inputArray]="CCFS"></app-custom-checkin-inputs-view>

  `,
  styles: [
  ]
})
export class CustomCheckinFormsCreatorComponent {

  customCheckinFormModel = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    description: [''],
    ccfs: this.fb.array([])
  }, { validators: this.atLeastOneInput })

  constructor(private fb: FormBuilder,
    private router: Router,
    private checkinModelService: CustomCheckinModelService) {

    this.checkinModelService.selectedCheckinModel$.pipe(first()).subscribe(checkinModel => {
      if (checkinModel) {
//        console.log(checkinModel)
        this.patchCheckinModel(checkinModel)
      }
      else { console.log("NoModelFound") }
    })
  }

  patchCheckinModel(checkinModel: CustomCheckinModel) {
    this.customCheckinFormModel.patchValue(checkinModel)
    checkinModel.ccfs.forEach(CCF => {
      switch (CCF.type) {
        case CustomCheckinFormType.Text:
          this.CCFS.push(this.patchTextInput(CCF))
          break;
        case CustomCheckinFormType.Number:
          this.CCFS.push(this.patchNumberInput(CCF))
          break;

        case CustomCheckinFormType.Range:
          this.CCFS.push(this.patchRangeInput(CCF))

          break;

        default:
          console.error("type not found")
          break;
      }
    })
  }

  patchTextInput(input: CustomCheckinFormTypeString): FormGroup {
    return this.fb.group({
      slot: [input.slot],
      type: [input.type],
      name: [input.name],
      description: [input.description]
    })
  }

  patchNumberInput(input: CustomCheckinFormTypeNumber): FormGroup {
    return this.fb.group({
      slot: [input.slot],
      type: [input.type],
      name: [input.name],
      defaultValue: [input.defaultValue],
      description: [input.description]
    })
  }

  patchRangeInput(input: CustomCheckinFormTypeRange): FormGroup {
    return this.fb.group({
      slot: [input.slot],
      type: [input.type],
      name: [input.name],
      min: [input.min],
      max: [input.max],
      value: [input.value],
      step: [input.step],

      description: [input.description]
    })

  }


  get CCFS() {
    return this.customCheckinFormModel.get('ccfs') as FormArray;
  }
  saveCustomCheckinModel() {
    const model: CustomCheckinModel = this.customCheckinFormModel.value as CustomCheckinModel;
 //   console.log(model)

    this.checkinModelService.addCheckinModelToDB(model).then(() =>
      this.router.navigate(['coach-custom-checkin-form']))


  }

  atLeastOneInput(control: AbstractControl) {
    const daysArray = control.get('ccfs') as FormArray;

    if (daysArray && daysArray.length > 0) {
      return null;

    } else {
      return { 'atLeastOneInput': true }
    }

  }

  onAddToArray(inputGroup: FormGroup) {
    this.CCFS.push(inputGroup);
  //  console.log(this.customCheckinFormModel)
  }


}
