import { Firestore } from '@angular/fire/firestore';
import { Timestamp } from 'firebase/firestore';
import {
  AppUser,
  CheckinStatus,
  UserRole,
  convertDateObject,
} from './../core/thecoach';
import { Injectable, OnDestroy } from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  Query,
  QueryFn,
} from '@angular/fire/compat/firestore';
import 'firebase/compat/firestore';
import { AuthService } from './auth.service';
import { ClientCheckin, Message } from '../core/thecoach';
import { format, isSameDay } from 'date-fns';
import {
  Observable,
  Subject,
  Subscription,
  finalize,
  first,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CheckinService implements OnDestroy {
  user: AppUser | undefined;

  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private storage: AngularFireStorage
  ) { }

  sendCheckIn(checkin: ClientCheckin) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;
      let dateFormat: string;

      if (checkin.id) {
      } else {
        checkin.id = this.db.createId();
      }

      checkin.clientEmail = this.user.email;
      checkin.clientId = this.user.id;
      checkin.clientName = this.user.displayName;

      if (this.user.role === UserRole.Client) {
        checkin.coachId = this.user.coachId;
      }

      checkin.messages?.forEach((msg) => {
        if (!msg.id) msg.id = this.db.createId();
        if (!msg.deletedFlag) msg.deletedFlag = false;
      });

      if (checkin.date) {
        dateFormat = format(checkin.date, 'yyyy-MM-dd');
      } else {
        dateFormat = format(new Date(), 'yyyy-MM-dd');
      }

      // console.log(checkin)

      return this.db
        .collection('checkins')
        .doc(checkin.clientId)
        .collection('checkins')
        .doc(checkin.id)
        .set(Object.assign({}, checkin, { merge: true }));
    });
  }

  sendCheckInWithVoice(
    checkin: ClientCheckin,
    answer: Message,
    audioBlob: Blob,
    audioName: string
  ) {
    let dateFormat: string;
    if (checkin.id) {
    } else {
      checkin.id = this.db.createId();
    }

    checkin.messages?.forEach((msg) => {
      if (!msg.id) msg.id = this.db.createId();
      if (!msg.deletedFlag) msg.deletedFlag = false;
    });

    if (checkin.date) {
      dateFormat = format(checkin.date, 'yyyy-MM-dd');
    } else {
      dateFormat = format(new Date(), 'yyyy-MM-dd');
    }

    let timestamp = Date.now();
    const uniquqeSafeName = timestamp + '_' + audioName;

    const path =
      '/voiceRecordings/' +
      checkin.clientId +
      '/' +
      checkin.id +
      '/' +
      uniquqeSafeName;
    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, audioBlob);
    this.percentage = this.task.percentageChanges() as Observable<number>;

    this.task
      .snapshotChanges()
      .pipe(
        tap(),
        finalize(async () => {
          answer.voiceUrl = await ref.getDownloadURL().toPromise();

          checkin.messages?.push(answer);
          checkin.messages?.forEach((msg) => {
            if (!msg.id) msg.id = this.db.createId();
            if (!msg.deletedFlag) msg.deletedFlag = false;
          });

          checkin.status = CheckinStatus.Sent;
          const dateFormat = format(checkin.date!, 'yyyy-MM-dd');
          checkin.lastEdit = new Date();

          this.db
            .collection('checkins')
            .doc(checkin.clientId)
            .collection('checkins')
            .doc(checkin.id)
            .set(Object.assign({}, checkin, { merge: true }))
            .then(() => { })
            .catch((error) => {
              console.error('error writing document', error);
            });
          this.voiceUploadCompletedSubject.next();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getAllCheckins(): Observable<ClientCheckin[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection('checkins')
          .doc(appUser.id)
          .collection('checkins')
          .valueChanges();
      })
    );
  }

  getClientCheckins(clientId: string): Observable<ClientCheckin[]> {
    return this.db
      .collection('checkins')
      .doc(clientId)
      .collection('checkins', (ref) => ref.orderBy('date', 'desc').limit(5))
      .valueChanges()
      .pipe(
        map((checkIns: ClientCheckin[]) => {
          return checkIns.map((checkIn: ClientCheckin) => {
            checkIn.date = convertDateObject(checkIn.date as Date);
            checkIn.lastEdit = convertDateObject(checkIn.lastEdit as Date);
            if (checkIn.messages)
              checkIn.messages = checkIn.messages.map((message) => {
                message.date = convertDateObject(message.date as Date);
                return message;
              });

            return checkIn;
          });
        })
      );
  }



  getAllClientCheckins(clientId: string): Observable<ClientCheckin[]> {
    return this.db
      .collection('checkins')
      .doc(clientId)
      .collection('checkins', (ref) => ref.orderBy('date', 'desc'))
      .valueChanges()
      .pipe(
        map((checkIns: ClientCheckin[]) => {
          return checkIns.map((checkIn: ClientCheckin) => {
            checkIn.date = convertDateObject(checkIn.date as Date);
            checkIn.lastEdit = convertDateObject(checkIn.lastEdit as Date);
            if (checkIn.messages)
              checkIn.messages = checkIn.messages.map((message) => {
                message.date = convertDateObject(message.date as Date);
                return message;
              });

            return checkIn;
          });
        })
      );
  }

  getClientCheckinById(clientId: string, checkinId: string) {
    return this.db
      .collection('checkins')
      .doc(clientId)
      .collection('checkins')
      .doc<ClientCheckin>(checkinId)
      .valueChanges();
  }

  deleteClientCheckin(clientId: string, checkin: ClientCheckin): Promise<void> {
    if (checkin.imgUrls)
      checkin.imgUrls?.forEach((img) => {
        this.storage.refFromURL(img.downloadURL).delete();
      });

    return this.db
      .collection('checkins')
      .doc(clientId)
      .collection('checkins')
      .doc(checkin.id)
      .delete();
  }

  getClientCheckInOnDate(client: AppUser, selectedDate: Date) {
    return this.db
      .collection('checkins')
      .doc(client.id)
      .collection('checkins', (ref) => ref.orderBy('date', 'desc'))
      .valueChanges()
      .pipe(
        map((checkIns: ClientCheckin[]) => {
          return checkIns
            .map((checkIn: ClientCheckin) => {
              checkIn.date = convertDateObject(checkIn.date as Date);
              checkIn.lastEdit = convertDateObject(checkIn.lastEdit as Date);
              if (checkIn.messages)
                checkIn.messages = checkIn.messages.map((message) => {
                  message.date = convertDateObject(message.date as Date);
                  return message;
                });

              return checkIn;
            })
            .find((checkIn) => isSameDay(checkIn.date!, selectedDate));
        })
      );
  }

  getClientLatestCheckin(
    clientId: string
  ): Observable<ClientCheckin | undefined> {
    return this.db
      .collection('checkins')
      .doc(clientId)
      .collection('checkins', (ref) => ref.orderBy('date', 'desc').limit(1))
      .valueChanges()
      .pipe(
        map((checkIns: ClientCheckin[]) => {
          if (checkIns.length === 0) {
            return undefined; // Return undefined if there are no check-ins
          }

          const latestCheckIn = checkIns[0];

          latestCheckIn.date = convertDateObject(latestCheckIn.date as Date);
          latestCheckIn.lastEdit = convertDateObject(
            latestCheckIn.lastEdit as Date
          );

          if (latestCheckIn.messages) {
            latestCheckIn.messages = latestCheckIn.messages.map((message) => {
              message.date = convertDateObject(message.date as Date);
              return message;
            });
          }

          return latestCheckIn;
        })
      );
  }

  getLatestClientCheckIn(client: AppUser) {
    return this.db
      .collection('checkins')
      .doc(client.id)
      .collection('checkins', (ref) => ref.orderBy('date', 'desc').limit(1))
      .valueChanges()
      .pipe(
        map((checkIns: ClientCheckin[]) => {
          if (checkIns.length === 0) {
            return null; // Return null if there are no check-ins
          }

          const latestCheckIn = checkIns[0];

          latestCheckIn.date = convertDateObject(latestCheckIn.date as Date);
          latestCheckIn.lastEdit = convertDateObject(
            latestCheckIn.lastEdit as Date
          );

          if (latestCheckIn.messages) {
            latestCheckIn.messages = latestCheckIn.messages.map((message) => {
              message.date = convertDateObject(message.date as Date);
              return message;
            });
          }

          return latestCheckIn;
        })
      );
  }

  sendAnswerToCheckin(
    checkin: ClientCheckin,
    answer: Message,
    status: CheckinStatus
  ) {
    checkin.messages?.push(answer);
    checkin.messages?.forEach((msg) => {
      if (!msg.id) msg.id = this.db.createId();
      if (!msg.deletedFlag) msg.deletedFlag = false;
    });

    checkin.status = status;
    const dateFormat = format(checkin.date!, 'yyyy-MM-dd');
    checkin.lastEdit = new Date();

    return this.db
      .collection('checkins')
      .doc(checkin.clientId)
      .collection('checkins')
      .doc(checkin.id)
      .set(Object.assign({}, checkin, { merge: true }));
  }

  closeCheckin(checkin: ClientCheckin) {
    const dateFormat = format(checkin.date!, 'yyyy-MM-dd');
    checkin.lastEdit = new Date();
    return this.db
      .collection('checkins')
      .doc(checkin.clientId)
      .collection('checkins')
      .doc(checkin.id)
      .set(Object.assign({}, checkin, { merge: true }));
  }

  task: AngularFireUploadTask | undefined;
  percentage: Observable<number> | undefined;
  snapshot: Observable<any> | undefined;
  private snapshotSubscription: Subscription | undefined;
  private destroy$ = new Subject<void>();
  private voiceUploadCompletedSubject = new Subject<void>();

  sendVoiceToCheckin(
    checkin: ClientCheckin,
    answer: Message,
    status: CheckinStatus,
    audioBlob: Blob,
    audioName: string
  ) {
    let timestamp = Date.now();
    const uniquqeSafeName = timestamp + '_' + audioName;

    const path =
      '/voiceRecordings/' +
      checkin.clientId +
      '/' +
      checkin.id +
      '/' +
      uniquqeSafeName;
    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, audioBlob);
    this.percentage = this.task.percentageChanges() as Observable<number>;

    this.task
      .snapshotChanges()
      .pipe(
        tap(),
        finalize(async () => {
          answer.voiceUrl = await ref.getDownloadURL().toPromise();

          checkin.messages?.push(answer);
          checkin.messages?.forEach((msg) => {
            if (!msg.id) msg.id = this.db.createId();
            if (!msg.deletedFlag) msg.deletedFlag = false;
          });

          checkin.status = status;
          const dateFormat = format(checkin.date!, 'yyyy-MM-dd');
          checkin.lastEdit = new Date();

          this.db
            .collection('checkins')
            .doc(checkin.clientId)
            .collection('checkins')
            .doc(checkin.id)
            .set(Object.assign({}, checkin, { merge: true }))
            .then(() => { })
            .catch((error) => {
              console.error('error writing document', error);
            });
          this.voiceUploadCompletedSubject.next();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getVoiceUploadCompletedObservable() {
    return this.voiceUploadCompletedSubject.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.snapshotSubscription?.unsubscribe();
  }

  overwriteMessage(checkin: ClientCheckin) {
    const dateFormat = format(checkin.date!, 'yyyy-MM-dd');
    checkin.lastEdit = new Date();

    return this.db
      .collection('checkins')
      .doc(checkin.clientId)
      .collection('checkins')
      .doc(checkin.id)
      .set(Object.assign({}, checkin, { merge: true }));
  }

  updateLegacyImgUrls(checkin: ClientCheckin) {

    const dateFormat = format(checkin.date!, 'yyyy-MM-dd');

    return this.db
      .collection('checkins')
      .doc(checkin.clientId)
      .collection('checkins')
      .doc(dateFormat)
      .set(Object.assign({}, checkin, { merge: true })).then(() => { return checkin }
      );
  }
}
