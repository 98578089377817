import { forEach, join } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Ingredient, Unit } from '../../../../../backend/node_modules/@prisma/client'
import * as firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { manipulateNumbersDown, manipulateNumbersUp } from '../../../../../backend/core/thecoach';
import { environment } from 'src/environments/environment';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';
import { Subject, first, debounceTime } from 'rxjs';

@Component({
  selector: 'app-ingredient-lib',
  template: `
  <div class="px-4 sm:px-6 lg:px-8" *ngIf="!loadingToggler; else dataloaded">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold leading-6 text-gray-900">Ingredients</h1>
      <p class="mt-2 text-sm text-gray-700">A list of all the Ingredients you created.</p>
    </div>

<input type="search" [(ngModel)]='searchTerm' (input)="onSearch(searchTerm)" (search)="onResetSearch()" class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6" placeholder="Enter Ingredient Name">

        <button type="button" (click)='onCreateNewIngredient()'  class="ml-4 rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">New Ingredient</button>

  <div class="mt-4">  <!--
  <label for="fileInput" class="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-full">
    <span>Import JSON</span>
    <input id="fileInput" type="file" class="hidden" (change)="handleFileInput($event)" accept=".json" />
  </label>

        <button type="button" (click)='onBulkUpload(this.ingArray)' class="ml-4 rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Upload</button>
        <button type="button" (click)='onBulkDelete()' class="ml-4 rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">DELETE</button>
-->
      </div>
      </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
      <th scope="col" class="whitespace-nowrap py-3 pl-4 pr-3 text-left text-sm font-medium text-gray-500 tracking-wider">Name</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Merchant</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">P. Size</th>
           <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Unit</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">P. Cost</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Protein</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Fat</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Sat. Fat</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Carb</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Sugar</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Fibre</th>
            <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Salt</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Public</th>
      <!--<th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Created At</th>
      <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">Updated At</th>
-->
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr *ngFor="let ing of ingList">
 <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ ing.name }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.merchant }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.packageSize }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.packageUnitSize }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.packageCost | number: '1.2-2'}}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.protein }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.fat }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.saturatedFat }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.carb }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.sugar }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.fibre }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.salt }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.public }}</td>
   <!--     <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.createdAt | date: 'dd-MM-yyyy'}}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ing.updatedAt| date: 'dd-MM-yyyy' }}</td>
-->             <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <div *ngIf="userId === ing.createdBy" (click)="onEdit(ing)" class="text-teal-600 hover:text-teal-900 cursor-pointer">Edit</div>
              </td>
                                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <div *ngIf="userId === ing.createdBy" (click)="onDelete(ing)" class="text-red-600 hover:text-red-900 cursor-pointer">Delete</div>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<nav *ngIf="searchTerm === ''" class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
  <div class="hidden sm:block">
    <p class="text-sm text-gray-700">
      Showing
      <span class="font-medium">{{(currentPage-1) *10 + 1}}</span>
      to
      <span class="font-medium">{{(currentPage-1)*10 + ingList.length}}</span>
      of
      <span class="font-medium">{{totalIngCounter}}</span>
      results
    </p>
  </div>
  <div class="flex flex-1 justify-between sm:justify-end">
    <div (click)="this.toggleLoader(); movePage(-1)" class="cursor-pointer relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Previous</div>

    <div (click)="this.toggleLoader();movePage(+1)" class="cursor-pointer relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Next</div>
  </div>
</nav>
<ng-template #dataloaded>
  <ngx-skeleton-loader count="5" animation="progress" [theme]="{height: '50px'}"></ngx-skeleton-loader>
</ng-template>
  <div *ngIf="editIngredientToggler">
    <app-new-ingredient-modal [editIng]="editIng" (editChanges)="onIngredientChanges($event)" (cancel)="onCancel()" ></app-new-ingredient-modal>
  </div>
  `,
  styles: [
  ]
})
export class IngredientLibComponent implements OnInit {
  ingArray: Ingredient[] = [];
  orignalIngArray: Ingredient[] = [];
  ingList: Ingredient[] = [];
  editIng: Ingredient | undefined;
  editIngredientToggler = false;
  loadingToggler = false;
  userId: string | undefined;

  currentPage = 1;
  totalIngCounter: number | undefined = undefined;

  searchTerm: string = ''
  private searchSubject = new Subject<string>();
  private readonly debouceTimeMS = 500;

  constructor(private http: HttpClient, private httpUser: HttpUserService) { }

  ngOnInit(): void {
    this.toggleLoader()
    this.initIngList();

    this.searchSubject.pipe(debounceTime(this.debouceTimeMS)).subscribe((searchObj) => { this.performSearch(searchObj) })
  }

  onResetSearch() {
    this.toggleLoader()
    this.searchTerm = '';
    this.initIngList();
  }

  onSearch(searchTerm: string) {
    this.searchSubject.next(searchTerm)
  }

  performSearch(searchString: string) {
    if (searchString) {
      this.toggleLoader()
      this.searchIngFromDB(searchString)
    } else { }
  }

  searchIngFromDB(searchString: string) {
    this.ingList = [];
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {

      if (user) {
        this.userId = user.uid
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          this.http.get<Ingredient[]>(`${environment.apiUrl}/ingredients/filtered-ingredients/${searchString}`, { headers }).subscribe(ingredients => {
            ingredients.map(data => {
              return manipulateNumbersDown(data)
            })

            this.ingList = ingredients;
            this.toggleLoader()
            // console.log(this.ingList)
          })


        }).catch((error) => {
          this.toggleLoader()
          // Handle error
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        // Handle the case when no user is signed in
        this.toggleLoader()
        console.error('No user signed in.');
        return null;
      }
    })
  }








  toggleLoader() {
    this.loadingToggler = !this.loadingToggler;
  }

  initIngList() {
    this.ingList = [];
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {

      if (user) {

        this.userId = user.uid
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          this.http.get<{ ingredientCount: number, ingredients: Ingredient[] }>(`${environment.apiUrl}/ingredients?skip=${(this.currentPage - 1) * 10}`, { headers }).subscribe(transactionObject => {
            transactionObject.ingredients.map(data => {
              return manipulateNumbersDown(data)
            })

            this.totalIngCounter = transactionObject.ingredientCount
            this.ingList = transactionObject.ingredients;
            this.toggleLoader();
            // console.log(this.ingList)
          })


        }).catch((error) => {
          this.toggleLoader()
          // Handle error
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        // Handle the case when no user is signed in
        this.toggleLoader()
        console.error('No user signed in.');
        return null;
      }
    })
  }

  movePage(increment: number) {
    this.currentPage += increment;


    if (this.currentPage <= 0) {
      this.currentPage = 1;
      this.toggleLoader()
    } else if (this.totalIngCounter && this.currentPage > Math.ceil(this.totalIngCounter / 10)) {
      this.currentPage = Math.ceil(this.totalIngCounter / 10)
      this.toggleLoader()
    } else {



      this.ingList = [];
      this.httpUser.currentUser$.pipe(first()).subscribe(user => {

        if (user) {
          return user.getIdToken(true).then((idToken) => {
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

            this.http.get<{ ingredientCount: number, ingredients: Ingredient[] }>(`${environment.apiUrl}/ingredients?skip=${(this.currentPage - 1) * 10}`, { headers }).subscribe(transactionObject => {
              transactionObject.ingredients.map(data => {
                return manipulateNumbersDown(data)
              })

              this.totalIngCounter = transactionObject.ingredientCount
              this.ingList = transactionObject.ingredients;
              this.toggleLoader();
              // console.log(this.ingList)
            })


          }).catch((error) => {
            this.toggleLoader()
            // Handle error
            console.error('Error getting ID token:', error);
            throw error;
          });
        } else {
          // Handle the case when no user is signed in
          this.toggleLoader()
          console.error('No user signed in.');
          return null;
        }
      })
    }
  }


  onEdit(ing: Ingredient) {
    this.editIng = ing;
    this.editIngredientToggler = !this.editIngredientToggler;
  }

  onCreateNewIngredient() {
    this.editIngredientToggler = !this.editIngredientToggler;
  }

  onDelete(ing: Ingredient) {
    this.toggleLoader()
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {

      if (user) {

        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          return this.http.delete<Ingredient>(environment.apiUrl + '/ingredients/' + ing.id, { headers }).subscribe(data => {
            this.editIngredientToggler = !this.editIngredientToggler;
            this.toggleLoader();
            this.initIngList();
          })

        }).catch((error) => {
          // Handle error
          this.toggleLoader()
          this.editIngredientToggler = !this.editIngredientToggler;
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        // Handle the case when no user is signed in
        this.toggleLoader()
        this.editIngredientToggler = !this.editIngredientToggler;
        console.error('No user signed in.');
        return null;
      }
    })

  }

  onCancel() {
    this.editIng = undefined;
    this.editIngredientToggler = !this.editIngredientToggler;
  }

  onIngredientChanges(val: boolean) {
    this.editIng = undefined
    if (val) {
      this.toggleLoader();
      this.initIngList();
    } else {
      console.error('Server Error')
    }
    this.editIngredientToggler = !this.editIngredientToggler;
  }


  handleFileInput(event: any): void {
    const file = event.target.files[0];

   if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        try {
          const jsonArray = JSON.parse(e.target.result);
          // Now you can work with the array (jsonArray) as needed
          console.log('JSON Array:', jsonArray);
          const uName = new Set<string>();
          jsonArray.forEach((jsonElement: Ingredient) => {


            if ((jsonElement.protein || jsonElement.protein === 0) && (jsonElement.fat || jsonElement.fat === 0)
              && (jsonElement.carb || jsonElement.carb === 0) && (jsonElement.packageUnitSize in Unit)) {
              const newIng: Ingredient = {
                id: jsonElement.id,
                name: jsonElement.name,
                merchant: jsonElement.merchant || '',
                packageSize: jsonElement.packageSize || 0,
                packageUnitSize: jsonElement.packageUnitSize,
                packageCost: jsonElement.packageCost || 0,
                protein: jsonElement.protein || 0,
                fat: jsonElement.fat || 0,
                saturatedFat: jsonElement.saturatedFat || 0,
                carb: jsonElement.carb || 0,
                sugar: jsonElement.sugar || 0,
                fibre: jsonElement.fibre || 0,
                salt: jsonElement.salt || 0,
                public: true,
                createdAt: new Date(),
                createdBy: 'DpkYqPHM3PblZe6sFPPp00UJJsv1',
                updatedAt: new Date(),


              }
              if (!uName.has(newIng.name)) {
                uName.add(newIng.name);
                this.ingArray.push(newIng)
              }
              if (newIng.packageCost && newIng.packageCost > 1000) {
                console.log(newIng)
              }


            }
          })

          console.log('Set Length', uName.size)
        } catch (error) {
        }
      };

      reader.readAsText(file);
      console.log("Array,", this.ingArray, this.ingArray.length)
      console.log("OriginalArray", this.orignalIngArray)
    }


    console.log("Array,", this.ingArray, this.ingArray.length)
  }


  onBulkUpload(ingredients: Ingredient[]) {
    const dataIngArr: Ingredient[] = []
    ingredients.forEach((ingredient) => {
      dataIngArr.push((ingredient))
    })

    console.log(dataIngArr)

    this.httpUser.currentUser$.pipe(first()).subscribe(user => {
      if (user) {
        user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })
          this.http.post(environment.apiUrl + '/ingredients/bulk-upload/', {

            ingredients: dataIngArr

          }, { headers: headers }).subscribe(res => {
            console.log('Server responded', res)


          }), (error: Error) => {
            console.error('Error', error)

          }
        })
      }



    })



  }
  onBulkDelete() {
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {

      if (user) {
        const deleteTerm = 'stk'
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          return this.http.delete<Ingredient>(environment.apiUrl + '/ingredients/bulk-delete/' + deleteTerm, { headers }).subscribe(data => {
            console.log(data)
          })

        }).catch((error) => {
          // Handle error
          this.editIngredientToggler = !this.editIngredientToggler;
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        // Handle the case when no user is signed in
        this.editIngredientToggler = !this.editIngredientToggler;
        console.error('No user signed in.');
        return null;
      }
    })

  }
}
