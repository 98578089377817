import { Component, Input, OnInit } from '@angular/core';
import {
  CustomInputType,
  WeightEntryData,
  AppUser,
  Macros,
  calculateKcalFromMacros,
  calcuclateDailydifferenceMaster,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateSevenDayAverageMaster,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
} from 'src/app/core/thecoach';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';

@Component({
  selector: 'app-weightlog-table-dashboard',
  template: `
    <div *ngIf="client && client.ctmId && client.coachId">
      <div class="relative">
        <button
          (click)="displayCTV = !displayCTV"
          type="button"
          class="absolute -top-4 -right-4 rounded-full bg-teal-600 p-1.5 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </button>
      </div>
      <div>
        <table class="divide-y divide-gray-300 ">
          <thead class="">
            <tr class="divide-x divide-gray-200">
              <th
                scope="col"
                class="pt-3.5 pl-4 pr-1 text-center text-sm font-semibold text-gray-900 sm:pl-0 "
              >
                Date
              </th>
              <th
                scope="col"
                class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
              >
                Weight
              </th>
              <th
                scope="col"
                class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
              >
                7 Day
              </th>
              <th
                scope="col"
                class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
              >
                ROG/L
              </th>

              <th
                scope="col"
                class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
              >
                ROG/L
              </th>
              <th
                scope="col"
                colspan="4"
                class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
              >
                Set Macros
              </th>

              <th
                scope="col"
                colspan="4"
                class=" pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
              >
                Achieved Macros
              </th>
              <ng-container *ngIf="displayCTV">
                <ng-container
                  *ngIf="
                    this.ctmService.getModelByIdByCoach(
                      client.ctmId,
                      client.coachId
                    ) | async as customTrackingModel
                  "
                >
                  <th
                    *ngFor="let ctv of customTrackingModel.ccts"
                    scope="col"
                    class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                  >
                    <div *ngIf="ctv.name.length <= 8">
                      {{ ctv.name }}
                    </div>

                    <div
                      class=" group flex relative items-center justify-center"
                      *ngIf="ctv.name.length > 8"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                      <span
                        class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                      >
                        {{ ctv.name }}</span
                      >
                    </div>
                  </th>
                </ng-container>
              </ng-container>
            </tr>
            <tr class="divide-x divide-gray-200">
              <th scope="col"></th>
              <th scope="col"></th>
              <th
                scope="col"
                class=" px-1 text-center text-sm font-semibold text-gray-900"
              >
                Avg.
              </th>
              <th
                scope="col"
                class="px-1 text-center text-sm font-semibold text-gray-900"
              >
                (daily)
              </th>

              <th
                scope="col"
                class="whitespace-nowrap px-1 text-center text-sm font-semibold text-gray-900"
              >
                (7-Day)
              </th>
              <th
                scope="col"
                class=" px-1 text-center text-sm font-semibold text-gray-900"
              >
                P
              </th>
              <th
                scope="col"
                class=" px-1 text-center text-sm font-semibold text-gray-900"
              >
                C
              </th>
              <th
                scope="col"
                class=" px-1 text-center text-sm font-semibold text-gray-900"
              >
                F
              </th>
              <th
                scope="col"
                class=" px-1 text-center text-sm font-semibold text-gray-900"
              >
                KCAL
              </th>
              <th
                scope="col"
                class="px-1 text-center text-sm font-semibold text-gray-900"
              >
                P
              </th>
              <th
                scope="col"
                class="px-1 text-center text-sm font-semibold text-gray-900"
              >
                C
              </th>
              <th
                scope="col"
                class=" px-1 text-center text-sm font-semibold text-gray-900"
              >
                F
              </th>
              <th
                scope="col"
                class="px-1 text-center text-sm font-semibold text-gray-900"
              >
                KCAL
              </th>
              <ng-container *ngIf="displayCTV">
                <ng-container
                  *ngIf="
                    this.ctmService.getModelByIdByCoach(
                      client.ctmId,
                      client.coachId
                    ) | async as customTrackingModel
                  "
                >
                  <th
                    *ngFor="let ctv of customTrackingModel.ccts"
                    scope="col"
                    class="px-1 text-center text-sm font-semibold text-gray-900"
                  >
                    <div>
                      <ng-container
                        *ngIf="ctv.type === CustomInputType.Number"
                        class="flex flex-row"
                      >
                        <p class="">
                          {{ ctv.defaultValue }}
                        </p>
                      </ng-container>

                      <ng-container
                        *ngIf="ctv.type === CustomInputType.Range"
                        class="flex flex-row"
                      >
                        <p class="">{{ ctv.min }}-{{ ctv.max }}</p>
                      </ng-container>
                    </div>
                  </th>
                </ng-container>
              </ng-container>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr
              class="divide-x divide-gray-200"
              *ngFor="let weighEntry of this.weightLogData; let j = index"
              [ngClass]="{
                'bg-gray-200': j % 7 === 0
              }"
            >
              <td
                class="whitespace-nowrap  text-center py-0.5 pl-4 pr-1 text-sm font-medium text-gray-900 sm:pl-0"
              >
                {{ weighEntry.date | date: 'dd-MM-yyyy' }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500 flex flex-row justify-center"
              >
                {{
                  weighEntry.weight !== undefined
                    ? (weighEntry.weight | number: '1.1-1')
                    : '-'
                }}

                <div
                  class="group flex relative items-center pl-0.5"
                  *ngIf="weighEntry.comment"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 stroke-yellow-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                  <span
                    class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-[20%] translate-y-2 opacity-0 m-4 mx-auto"
                    >{{ weighEntry.comment }}</span
                  >
                </div>
              </td>

              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ calculateSevenDayAverage(j, client) }}
              </td>
              <td
                class="whitespace-nowrap  text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ calculateRoglDaily(weighEntry.weight!, j, client) }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ calculateRoglWeekly(j, client) }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ weighEntry.setMacros?.protein }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ weighEntry.setMacros?.carbs }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ weighEntry.setMacros?.fat }}
              </td>

              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ calculateKcal(weighEntry.setMacros!) }}
              </td>

              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ weighEntry.achivedMacros?.protein || '-' }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ weighEntry.achivedMacros?.carbs || '-' }}
              </td>
              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ weighEntry.achivedMacros?.fat || '-' }}
              </td>

              <td
                class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
              >
                {{ calculateKcal(weighEntry.achivedMacros!) }}
              </td>
              <ng-container
                *ngIf="
                  displayCTV &&
                  weighEntry.customTrackingLog &&
                  weighEntry.customTrackingLog.ccts
                "
              >
                <td
                  class="whitespace-wrap px-1 text-xs text-gray-500 text-center"
                  *ngFor="let cct of weighEntry.customTrackingLog.ccts"
                >
                  <ng-container *ngIf="cct.type !== 'boolean'">
                    {{ cct.value }}
                  </ng-container>

                  <ng-container *ngIf="cct.type === 'boolean'">
                    <ng-container *ngIf="cct.value === true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 stroke-green-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </ng-container>
                    <ng-container *ngIf="cct.value === false || !cct.value">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 stroke-red-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  `,
  styles: [],
})
export class WeightlogTableDashboardComponent implements OnInit {
  CustomInputType = CustomInputType;
  displayCTV = false;

  constructor(protected ctmService: CustomTrackingModelService) {}
  ngOnInit(): void {
    console.log(this.weightLogData);
  }

  @Input() weightLogData!: WeightEntryData[] | null;
  @Input() client!: AppUser | undefined;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  calculateDailydifference(
    weight: number,
    index: number,
    client: AppUser,
  ): string {
    return calcuclateDailydifferenceMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateDifferenceFromStart(index: number, client: AppUser): string {
    return calculateDifferenceFromStartMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateFiveDayAverage(index: number, client: AppUser): string {
    return calculateFiveDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateSevenDayAverage(index: number, client: AppUser): string {
    return calculateSevenDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglDaily(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglWeekly(index: number, client: AppUser): string {
    return calculateRoglWeeklyMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
}
