import { MiniMacroPieComponent } from './../../CoachView/mini-macro-pie/mini-macro-pie.component';
import { Options } from 'ngx-slider-v2';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  add,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  intervalToDuration,
  parseISO,
} from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { TemplateTrainingDay } from 'src/app/core/thecoach';

export interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-session-rating-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div
                class="flex flex-col w-full justify-center text-center items-center"
              >
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <svg
                    class="h-6 w-6 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <div
                  class="mt-3 text-center flex flex-col w-full justify-center items-center"
                >
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Session finished!
                  </h3>
                  <div
                    class="mt-2 flex flex-col w-full justify-center items-center"
                  >
                    <p class="text-sm text-gray-500">
                      Please rate your Session (bad - good)
                    </p>
                    <div
                      class="custom-slider w-5/6 flex justify-center items-center text-center py-4"
                    >
                      <ngx-slider
                        [(value)]="value"
                        [options]="options"
                      ></ngx-slider>
                    </div>

                    <div class="flex w-full">
                      <div class="flex flex-col space-y-4 w-full">
                        <form [formGroup]="sessionDatesForm">
                          <label for="start-date" class="font-bold"
                            >Start Date and Time:</label
                          >
                          <div
                            class="flex flex-row justify-center space-x-4 w-full px-2"
                          >
                            <input
                              type="date"
                              id="start-date"
                              formControlName="startDate"
                              class="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                            />

                            <input
                              type="time"
                              id="start-time"
                              formControlName="startTime"
                              class="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                            />
                          </div>

                          <label for="end-date" class="font-bold"
                            >End Date and Time:</label
                          >
                          <div
                            class="flex flex-row justify-center  space-x-4 w-full px-2"
                          >
                            <input
                              type="date"
                              id="end-date"
                              formControlName="endDate"
                              class="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                            />

                            <input
                              type="time"
                              id="end-time"
                              formControlName="endTime"
                              class="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                            />
                          </div>
                        </form>

                        <div *ngIf="sessionDatesForm.valid">
                          <p class="text-sm text-gray-700">Duration:</p>
                          {{ durationFromForm() }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 px-4 py-3 flex w-full flex-row justify-between"
              >
                <button
                  type="button"
                  [disabled]="!sessionDatesForm.valid"
                  (click)="onSendValue(true)"
                  class="inline-flex w-fit justify-center disabled:bg-gray-400 rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto"
                >
                  Save
                </button>

                <button
                  type="button"
                  (click)="onSendValue(false)"
                  class="inline-flex w-fit justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .custom-slider::ng-deep .ngx-slider .ngx-slider-selection {
        background: #5eead5;
      }
      .custom-slider::ng-deep .ngx-slider .ngx-slider-tick.ngx-slider-selected {
        background: #5eead4;
      }

      .custom-slider::ng-deep .ngx-slider .ngx-slider-bar {
        background: #5eead4;
        height: 2px;
      }
      .custom-slider::ng-deep .ngx-slider .ngx-slider-pointer:after {
        display: none;
      }

      .custom-slider::ng-deep .ngx-slider .ngx-slider-pointer {
        width: 16px;
        height: 26px;
        top: auto; /* to remove the default positioning */
        bottom: -10px;
        background-color: #0d9488;
      }
    `,
  ],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class SessionRatingModalComponent implements OnInit {
  @Input() session?: TemplateTrainingDay;
  @Output() close = new EventEmitter<boolean>();

  toggleDurationEdit = false;

  value: number = 5;
  options: Options = {
    step: 1,
    floor: 0,
    ceil: 10,
  };

  sessionDatesForm = this.fb.group({
    startDate: ['', Validators.required],
    startTime: ['', Validators.required],
    endDate: ['', Validators.required],
    endTime: ['', Validators.required],
  });

  timeBetween: Duration | undefined = undefined;

  durationBetweenForm = this.fb.group({
    hours: [''],
    minutes: [''],
    seconds: [''],
  });
  isModal = true;
  run = true;

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    if (this.session && this.session.startDate) {
      if (typeof this.session.startDate === 'string') {
        this.session.startDate = parseISO(this.session.startDate);
      }
    }

    if (
      (this.session && this.session?.endDate === undefined) ||
      this.session?.endDate === null
    ) {
      this.session.endDate = new Date();
    }

    if (this.session) {
      this.sessionDatesForm
        .get('startDate')
        ?.patchValue(format(this.session.startDate as Date, 'yyyy-MM-dd'));
      this.sessionDatesForm
        .get('endDate')
        ?.patchValue(format(this.session.endDate as Date, 'yyyy-MM-dd'));
      this.sessionDatesForm
        .get('startTime')
        ?.patchValue(format(this.session.startDate as Date, 'HH:mm:ss'));
      this.sessionDatesForm
        .get('endTime')
        ?.patchValue(format(this.session.endDate as Date, 'HH:mm:ss'));
    }
  }

  onSendValue(value: boolean) {
    if (value) {
      if (this.session) this.session.sessionRating = this.value;

      this.saveSessionTimes();

      this.close.emit(value);
    } else {
      this.close.emit(value);
    }
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }

  duration() {
    if (
      (this.session && this.session?.endDate === undefined) ||
      this.session?.endDate === null
    ) {
      this.session.endDate = new Date();
    }

    if (this.session && this.session.endDate && this.session.startDate) {
      const duration = intervalToDuration({
        start: this.session.startDate,
        end: this.session.endDate,
      });

      this.timeBetween = {
        days: duration.days as number,
        hours: duration.hours as number,
        minutes: duration.minutes as number,
        seconds: duration.seconds as number,
      };

      if (this.timeBetween.days > 0) {
        return (
          this.timeBetween.days.toString() +
          'd ' +
          this.timeBetween.hours.toString() +
          'h ' +
          this.timeBetween.minutes.toString() +
          'min ' +
          this.timeBetween.seconds.toString() +
          's'
        );
      } else {
        return (
          this.timeBetween.hours.toString() +
          'h ' +
          this.timeBetween.minutes.toString() +
          'min ' +
          this.timeBetween.seconds.toString() +
          's'
        );
      }

      //   return this.session!.endDate! - this.session!.startDate!;
    }

    return null;
  }

  durationFromForm() {
    const startDate = new Date(
      ((this.sessionDatesForm.get('startDate')!.value as string) +
        'T' +
        this.sessionDatesForm.get('startTime')!.value) as string
    );
    const endDate = new Date(
      ((this.sessionDatesForm.get('endDate')!.value as string) +
        'T' +
        this.sessionDatesForm.get('endTime')!.value) as string
    );

    const duration = intervalToDuration({ start: startDate, end: endDate });

    this.timeBetween = {
      days: duration.days as number,
      hours: duration.hours as number,
      minutes: duration.minutes as number,
      seconds: duration.seconds as number,
    };

    if (this.timeBetween.days > 0) {
      return (
        this.timeBetween.days.toString() +
        'd ' +
        this.timeBetween.hours.toString() +
        'h ' +
        this.timeBetween.minutes.toString() +
        'min ' +
        this.timeBetween.seconds.toString() +
        's'
      );
    } else {
      return (
        this.timeBetween.hours.toString() +
        'h ' +
        this.timeBetween.minutes.toString() +
        'min ' +
        this.timeBetween.seconds.toString() +
        's'
      );
    }
  }

  saveSessionTimes() {
    const startDate = new Date(
      ((this.sessionDatesForm.get('startDate')!.value as string) +
        'T' +
        this.sessionDatesForm.get('startTime')!.value) as string
    );
    const endDate = new Date(
      ((this.sessionDatesForm.get('endDate')!.value as string) +
        'T' +
        this.sessionDatesForm.get('endTime')!.value) as string
    );

    if (this.session) {
      this.session.startDate = startDate;
      this.session.endDate = endDate;
    }
  }

  enableDurationEdit() {
    this.toggleDurationEdit = !this.toggleDurationEdit;
    this.durationBetweenForm
      .get('hours')
      ?.patchValue(this.timeBetween!.hours.toString());
    this.durationBetweenForm
      .get('minutes')
      ?.patchValue(this.timeBetween!.minutes.toString());
    this.durationBetweenForm
      .get('seconds')
      ?.patchValue(this.timeBetween!.seconds.toString());
  }

  saveNewDuration() {
    this.toggleDurationEdit = !this.toggleDurationEdit;
    this.session!.endDate = add(this.session!.startDate!, {
      hours: Number(this.durationBetweenForm.get('hours')!.value),
      minutes: Number(this.durationBetweenForm.get('minutes')!.value),
      seconds: Number(this.durationBetweenForm.get('seconds')!.value),
    });
  }
}
