import { EmailMessagingService } from 'src/app/services/email-messaging.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { Meta } from '@angular/platform-browser';
import { format } from 'date-fns';
import { Observable, Subscription, finalize, first, tap } from 'rxjs';
import { AppUser, downloadObject } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-upload-task',
  template: `
    <div class="w-full justify-center" *ngIf="percentage | async as pct">
      <div class="shadown w-full bg-grey-light">
        <div
          class="bg-teal text-xs leading-none py-1 text-center text-white hidden"
          style="width:{pct| number}"
        >
          {{ pct | number }}%
        </div>
        <div
          *ngIf="snapshot | async as snap"
          class="w-fit text-sm italic text-gray-500 group-hover:text-gray-600"
        >
          {{
            (file?.name)!.length > 12
              ? (file?.name | slice : 0 : 12) + '...'
              : file?.name
          }}
          - {{ snap.bytesTransferred / 1024 | number : '1.0-1' }} of
          {{ snap.totalBytes / 1024 | number : '1.0-1' }} KB
          <div class="hidden">
            <button
              (click)="task?.pause()"
              [disabled]="isActive(snap)"
              [hidden]="!isActive(snap)"
              type="button"
              class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Pause
            </button>
            <button
              [disabled]="isActive(snap)"
              [hidden]="!isActive(snap)"
              (click)="task?.cancel()"
              type="button"
              class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Cancel
            </button>
            <button
              [disabled]="!(snap?.state === 'paused')"
              [hidden]="!isActive(snap)"
              (click)="task?.resume()"
              type="button"
              class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Resume
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File | undefined;
  @Input() checkinId!: string;
  task: AngularFireUploadTask | undefined;
  percentage: Observable<number> | undefined;
  snapshot: Observable<any> | undefined;
  downloadUrl: string = '';
  user: AppUser | undefined;

  @Output() imgUrl = new EventEmitter<downloadObject>();
  @Output() uploading = new EventEmitter<boolean>();

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;
      //  console.log('checkinID', this.checkinId);
      this.startUpload();
    });
  }

  startUpload() {
    const id = this.user!.id;

    if (id && this.file) {
      this.uploading.emit(true);
      let safeName = this.file.name.replace(/([^a-zA-Z0-9]+)/gi, '');

      const timestamp = Date.now();
      const dateFormat = format(timestamp, 'yyyy-MM-dd');
      const uniquqeSafeName = timestamp + '_' + safeName;
      const fileId = this.db.createId();

      const path = 'checkinFiles/' + id + '/files/' + uniquqeSafeName;
      const ref = this.storage.ref(path);
      const metaData = {
        userId: id,
        dateFormat: dateFormat,
        fileId: fileId,
        timestamp: timestamp.toString(),
        checkinId: this.checkinId,
      };
      this.task = this.storage.upload(path, this.file, {
        customMetadata: metaData,
      });
      this.percentage = this.task.percentageChanges() as Observable<number>;

      this.snapshot = this.task.snapshotChanges().pipe(
        tap(),
        finalize(async () => {
          this.downloadUrl = await ref.getDownloadURL().toPromise();

          let downloadObj: downloadObject = {
            checkinId: this.checkinId,
            id: fileId,
            downloadURL: this.downloadUrl,
            storagePath: path,
            formatedDate: dateFormat,
            fileId: fileId,
            originalName: this.file?.name as string,
            timestamp: timestamp.toString(),
          };
          this.imgUrl.emit(downloadObj);

          this.db
            .collection('files')
            .doc(id)
            .collection(dateFormat)
            .doc(fileId)
            .set(downloadObj, { merge: true })
            .then(() => {
              this.uploading.emit(false);
              //  console.log('document written!');
            })
            .catch((error) => {
              console.error('error writing document', error);
            });
        })
      );
    }
  }

  isActive(snapshot: any) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
