import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-img-fullscreen-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div (click)="toggleModal()" class="fixed inset-0 z-20 ">
        <div
          class="flex mx-auto min-h-full  justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="z-20 relative transform rounded-lg  h-[fit] w-auto bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8  sm:p-6"
          >
            <div class='flex h-fit justify-between items-center flex-col'>
            <div class=" h-[60vh] w-auto items-center mx-auto">
              <img src="{{ imgSrc }}" class="h-[60vh] rounded-lg " />
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                type="button"
                (click)="toggleModal()"
                class="inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Go back to dashboard
              </button>
            </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class ImgFullscreenModalComponent implements OnInit {
  @Input() imgSrc!: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    //console.log(this.imgSrc);
  }
  ngOnInit(): void {
    // console.log(this.imgSrc);
  }
  isModal = true;

  toggleModal() {
    this.isModal = !this.isModal;
    this.closeModal.emit(true);
  }
}
