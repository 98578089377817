import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  AppUser,
  CheckinStatus,
  ClientCheckin,
  Message,
  UserRole,
} from 'src/app/core/thecoach';
import { CheckinService } from 'src/app/services/checkin.service';

@Component({
  selector: 'app-checkin-form-answer',
  template: `
    <form [formGroup]="checkInForm">
      <div>
        <div>
          <div
            class="flex w-full justify-between items-center mb-4"
            [ngClass]="{
              'justify-end': voicetoggler,
              'justify-between': !voicetoggler
            }"
          >
            <textarea
              *ngIf="!voicetoggler"
              rows="3"
              formControlName="message"
              name="message"
              id="message"
              class="block w-full py-2  mr-4 border-1 border-gray-200 rounded-lg  pb-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:ring-teal-400 focus:border-teal-400 sm:text-sm sm:leading-6"
              placeholder="Write your Answer..."
            >

                ></textarea
            >
            <app-voice-recorder
              *ngIf="!checkTextInput()"
              (blob)="onSendBlob($event)"
              (clearRecord)="onClearRecord($event)"
              (recordStart)="voicetoggler = true"
              [uploadAnimation]="this.uploadAnimation"
              [recorderAsAttachement]="true"
              [isCoach]="true"
            ></app-voice-recorder>
            <button
              *ngIf="checkTextInput()"
              type="button"
              (click)="onSubmit()"
              class="-rotate-90 rounded-full bg-[#F1F3F4] p-2 hover:bg-[#E5E7E8] "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 stroke-teal-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </button>
          </div>
          <div class="w-full flex justify-end">
            <button
              (click)="onCancelAnswer()"
              type="button"
              class="rounded-md bg-white  py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class CheckinFormAnswerComponent implements OnInit {
  voicetoggler = false;
  private voiceUploadSubscription: Subscription | undefined;

  @Input() selectedCheckin!: ClientCheckin;
  @Input() user!: AppUser;
  @Output() toggleAnswer = new EventEmitter<boolean>();

  audioBlob: Blob | undefined;
  audioName: string | undefined;

  uploadAnimation = false;

  checkInForm = this.fb.group({
    message: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private checkInService: CheckinService
  ) { }

  ngOnInit(): void {
    this.voiceUploadSubscription = this.checkInService
      .getVoiceUploadCompletedObservable()
      .subscribe(() => {
        this.uploadAnimation = false;
        this.onCancelAnswer();
      });
  }

  ngOnDestroy(): void {
    this.voiceUploadSubscription?.unsubscribe();
  }

  get message() {
    return this.checkInForm.get('message') as FormControl;
  }

  onSendBlob(event: { blob: Blob; name: string }) {
    if (event.blob && event.name) {
      this.audioBlob = event.blob;
      this.audioName = event.name;

      this.onSubmit();
    }
  }

  checkTextInput() {
    if (!this.message.value) return false;

    if (this.message.value && this.message.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onClearRecord(event: boolean) {
    if (event) {
      this.voicetoggler = false;
    }
  }

  onCancelAnswer() {
    this.toggleAnswer.emit(true);
    this.checkInForm.reset();
  }

  onSubmit() {
    if (this.selectedCheckin) {
      const answerMessage: Message = {
        author: this.user.displayName,
        authorId: this.user.id,
        message: this.message.value,
        date: new Date(),
      };

      if (this.audioBlob && this.audioName) {
        this.uploadAnimation = true;
        if (this.user.role === UserRole.Client) {
          this.checkInService.sendVoiceToCheckin(
            this.selectedCheckin,
            answerMessage,
            CheckinStatus.Sent,
            this.audioBlob as Blob,
            this.audioName as string
          );
        } else {
          this.checkInService.sendVoiceToCheckin(
            this.selectedCheckin,
            answerMessage,
            CheckinStatus.Answered,
            this.audioBlob as Blob,
            this.audioName as string
          );
        }
      } else {
        if (this.user.role === UserRole.Client) {
          this.checkInService.sendAnswerToCheckin(
            this.selectedCheckin,
            answerMessage,
            CheckinStatus.Sent
          );
        } else {
          this.checkInService.sendAnswerToCheckin(
            this.selectedCheckin,
            answerMessage,
            CheckinStatus.Answered
          );
        }

        this.onCancelAnswer();
      }
    }
  }
}
