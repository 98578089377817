import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-profile',
  template: `
    <p>
      customer-profile works!
    </p>
  `,
  styles: [
  ]
})
export class CustomerProfileComponent {

}
