import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription, first, forkJoin, mergeMap, of } from 'rxjs';
import {
  AppUser,
  CheckinStatus,
  ClientCheckin,
  Message,
  deleteCheckinMessage,
  downloadObject,
} from 'src/app/core/thecoach';
import { CheckinService } from 'src/app/services/checkin.service';
import { FileService } from 'src/app/services/file.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-coach-dashboard-check-in-selected',
  template: `
    <div class="bg-white py-4" *ngIf="selectedCheckin">


      <p class="text-lg font-semibold">
        {{ selectedCheckin.title || 'Check In - '}}
        {{ selectedCheckin.date | date : 'dd-MM-yyyy' }}
      </p>
      <div class="mb-4 col-span-2">
        <dd>
          <div class="grid grid-cols-4 gap-4">
            <ng-container *ngFor="let imgUrl of selectedCheckin.imgUrls">

              <img
                hoverOverImg
                (holdTime)="onLongPressIMG($event)"
                [src]="imgUrl.downloadURL || imgUrl"
                class="w-full h-auto "
                [ngClass]="{
                  'hover:scale-[300%] hover:transform-gpu hover:ring-1 hover:ring-gray-400 hover:rounded-md hover:z-20':
                    imgHover
                }"
                (click)="openImgFullscreen(imgUrl)"
                alt="Image"
              />
            </ng-container>
          </div>
        </dd>
      </div>

      <div class="mb-4 col-span-2">
        <dt class="text-lg font-semibold">Messages:</dt>
        <dd>
          <app-checkin-message
            [checkIn]="selectedCheckin"
            [customer]="false"
            (ondelete)="onDeleteMsg($event)"
          ></app-checkin-message>
        </dd>
      </div>
      <div class="w-full flex justify-end pt-2">
        <button
          (click)="onAnswerCheckIn()"
          *ngIf="!answerToggler"
          type="button"
          class="rounded-md bg-teal-50 py-1.5 px-2.5 text-sm font-semibold text-teal-600 shadow-sm hover:bg-teal-100"
        >
          Send Message
        </button>
        <div *ngIf="answerToggler" class="w-full">
          <app-checkin-form-answer
            (toggleAnswer)="onAnswerCheckIn()"
            [selectedCheckin]="selectedCheckin"
            [user]="this.coach"
          >
          </app-checkin-form-answer>
        </div>
      </div>
    </div>

    <div *ngIf="imgFullscreenToggle" class="z-20">
      <app-img-fullscreen-modal
        [imgSrc]="imgSrc"
        (closeModal)="imgFullscreenToggle = false"
      ></app-img-fullscreen-modal>
    </div>
  `,
  styles: [],
})
export class CoachDashboardCheckInSelectedComponent
  implements OnInit, OnDestroy {
  @Input() selectedCheckin!: ClientCheckin | undefined;
  @Input() coach!: AppUser;

  answerToggler = false;
  isFlyoutMenu = false;
  selectedMessage: Message | undefined;
  imgHover = false;
  imgFullscreenToggle = false;
  imgSrc: string = '';

  constructor(
    private fb: FormBuilder,
    private checkInService: CheckinService,
    private fileService: FileService
  ) { }
  ngOnDestroy(): void { }
  ngOnInit(): void {
    if (this.selectedCheckin) this.updateIMGs(this.selectedCheckin);
  }



  updateIMGs(checkin: ClientCheckin) {

    checkin.imgUrls?.forEach((imgUrlsObject, index) => {
      // console.log(imgUrlsObject);
      /* const fileName = (imgUrlsObject as unknown as string)
         .substring((imgUrlsObject as unknown as string)
           .lastIndexOf("%") + 3, (imgUrlsObject as unknown as string)
             .lastIndexOf("?"))
 */
      // this.getNewFileUrl(fileName, checkin.clientId as string)
      if (!this.conversionCheck(imgUrlsObject.storagePath)) {
        this.fileService
          .getIMGObject(checkin.clientId as string, imgUrlsObject)
          .pipe(first())
          .subscribe((res) => {
            if (checkin.imgUrls && res?.fileId === imgUrlsObject.fileId) {
              checkin.imgUrls[index] = _.cloneDeep(res);
            }
          });
      }
    });

  }


  conversionCheck(path: string) {
    if (path.substring(path.length - 9, path.length).includes('_798x1064')) {
      return true
    } else { return false; }
  }


  checkOriginalAuthor(checkin: ClientCheckin, message: Message): boolean {
    if (!checkin.messages) return false;

    if (!message.authorId) {
      if (checkin.messages[0].author === message.author) return true;
    } else {
      if (checkin.messages[0].authorId === message.authorId) return true;
    }

    return false;
  }

  onAnswerCheckIn() {
    this.answerToggler = !this.answerToggler;
  }

  onLongPressIMG(event: number) {
    if (event > 500) {
      this.imgHover = true;
    } else {
      this.imgHover = false;
    }
  }

  onDeleteMsg(event: { checkin: ClientCheckin; msg: Message }) {
    deleteCheckinMessage(event, this.checkInService);
  }

  openImgFullscreen(src: downloadObject) {
    if (src.downloadURL) {
      this.imgSrc = src.downloadURL;
    } else {
      this.imgSrc = src as unknown as string;
    }

    this.imgFullscreenToggle = !this.imgFullscreenToggle;
  }
}
