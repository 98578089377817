import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { add, eachDayOfInterval, endOfMonth, endOfWeek, format, isBefore, isEqual, isSameDay, isSameMonth, isToday, parse, startOfWeek } from 'date-fns';

@Component({
  selector: 'app-date-picker',
  template: `   
   <div *ngIf="preSelectedDay" class="flex flex-col text-center">
      <div class="flex items-center text-gray-900">
        <button
          type="button"
          (click)="onChangeMonth(-1); $event.stopPropagation()"
          class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Previous month</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="flex-auto text-sm font-semibold">
          {{ currentMonth }}
        </div>
        <button
          type="button"
          (click)="onChangeMonth(1); $event.stopPropagation()"
          class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Next month</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div
        class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
      >
        <!--
          Always include: "py-1.5 hover:bg-gray-100 focus:z-10"
          Is current month, include: "bg-white" X
          Is not current month, include: "bg-gray-50" X
          Is selected or is today, include: "font-semibold"
          Is selected, include: "text-white"
          Is not selected, is not today, and is current month, include: "text-gray-900"
          Is not selected, is not today, and is not current month, include: "text-gray-400"
          Is today and is not selected, include: "text-indigo-600"

          Top left day, include: "rounded-tl-lg"
          Top right day, include: "rounded-tr-lg"
          Bottom left day, include: "rounded-bl-lg"
          Bottom right day, include: "rounded-br-lg"
        -->

        <!--
            Always include: "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
            Is selected and is today, include: "bg-indigo-600"
            Is selected and is not today, include: "bg-gray-900"
          -->

        <button
          *ngFor="let day of generatedDays"
          type="button"
          (click)="onSelectDay(day); $event.stopPropagation()"
          class="bg-white py-1.5 px-1 text-gray-900  hover:bg-teal-400 hover:text-slate-50 focus:z-10"
          [ngClass]="{
            



            'bg-white': isCurrentMonth(day) && !isEqual(day) && !isToday(day),
            'bg-gray-300': !isCurrentMonth(day) && !isEqual(day),
        
           'font-semibold text-teal-600 bg-teal-100':  isCurrentMonth(day) && isEqual(day) ,
      
           'bg-teal-100': isCurrentMonth(day) && !isEqual(day) && isToday(day),
     


          }"
        >
          <time
            datetime="2022-01-21"
            class="mx-auto flex h-4 w-4 items-center justify-center rounded-full"
            >{{ day | date : 'dd' }}</time
          >
        </button>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate(
          '75ms ease-in',
          style({ opacity: 0, transform: 'scale(0.95)' })
        ),
      ]),
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],


})
export class DatePickerComponent implements OnInit {
  @Input() preSelectedDay!: Date;
  @Output() onSelectedDay = new EventEmitter<Date>();
  currentMonth: string | undefined;
  firstDayOfCurrentMonth: Date | undefined;
  generatedDays: Date[] = [];


  constructor() { }


  ngOnInit(): void {

    //this.onSelectDay(this.selectedDay);

    if (this.preSelectedDay) {

      this.currentMonth = format(this.preSelectedDay, 'MMMM - yyyy');
      this.firstDayOfCurrentMonth = parse(this.currentMonth, 'MMMM - yyyy', new Date());
      this.createDates();

    }
  }

  isSelected(day: Date): boolean {
    return (this.isToday(day) && this.isEqual(day))
  }

  isToday(date: Date): boolean {
    return isToday(date);
  }

  createDates() {
    this.generatedDays = [];
    this.generatedDays = eachDayOfInterval({
      start: startOfWeek(this.firstDayOfCurrentMonth as Date, { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(this.firstDayOfCurrentMonth as Date), {
        weekStartsOn: 1,
      }),
    });
  }

  isCurrentMonth(date: Date): boolean {
    return isSameMonth(date, this.firstDayOfCurrentMonth as Date);
  }

  isEqual(date: Date) {
    return isSameDay(date, this.preSelectedDay);
  }

  onSelectDay(date: Date): void {
    this.preSelectedDay = date;
    this.onSelectedDay.emit(date);

  }

  onChangeMonth(amount: number) {
    this.firstDayOfCurrentMonth = parse(
      this.currentMonth as string,
      'MMMM - yyyy',
      new Date()
    );

    this.firstDayOfCurrentMonth = add(this.firstDayOfCurrentMonth, {
      months: amount,
    });
    this.currentMonth = format(this.firstDayOfCurrentMonth, 'MMMM - yyyy');
    this.createDates();
  }

  /*
  onToggleWeightEntryDropdown(id: string | undefined) {
    const indexOfDropdownEntry = this.gewichtsDaten.findIndex(
      (entry) => entry.id === id
    );
    this.gewichtsDaten[indexOfDropdownEntry].showMenu =
      !this.gewichtsDaten[indexOfDropdownEntry].showMenu;
  }
*/

  onEntryEdit(id: string | undefined) {
    if (!id) return null;

    return id;
  }

}
