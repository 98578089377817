import { CheckinStatus } from './../../core/thecoach';
import { Component, OnDestroy } from '@angular/core';
import {
  Observable,
  Subscription,
  combineLatest,
  first,
  map,
  switchMap,
} from 'rxjs';
import {
  AppUser,
  ClientCheckin,
  convertDateObject,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { CheckinService } from 'src/app/services/checkin.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-coach-check-in',
  template: `
    <div class="py-4">
      <div>
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Client Name..."
          />
        </div>
      </div>
    </div>

    <ul role="list" class="space-y-3">
      <li
        *ngFor="let client of displayClients; let i = index"
        class="overflow-hidden bg-white px-4 py-4 shadow rounded-md px-6"
      >
        <div class="flex w-full justify-between">
          <div class="flex w-2/6 justify-start items-center">
            <img
              class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
              src="{{
                client.imgUrl || '.././assets/images/default-person-image.webp'
              }}"
            />
            <h3 class="pl-2">{{ client.displayName }}</h3>
            <span class="italic text-sm text-gray-400 pl-2 align-bottom">{{
              client.email
            }}</span>
          </div>

          <div
            class="flex text-gray-500 items-center

          "
          >
            <div
              class="w-fit h-fit p-2 border-2 rounded-lg border-gray-500 hover:bg-gray-200 cursor-pointer"
              [ngClass]="{
                'bg-gray-200': selectedClientIndex === i,
                'bg-white': selectedClientIndex !== i
              }"
              (click)="onOpenClient(client, i)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div *ngIf="clientToggler[i]">
          <ng-container *ngIf="checkIns$ | async as checkIns">
            <ul
              role="list"
              class="space-y-3"
              *ngIf="checkIns.length > 0; else noCheckins"
            >
              <li
                *ngFor="let checkIn of checkIns; let i = index"
                class=" bg-white px-4 py-4 shadow rounded-md px-6"
              >
                <div class="flex w-full justify-between">
                  <div>
                    {{ checkIn.title }} -
                    {{ checkIn.date | date: 'dd-MM-yyyy - hh:mm' }}
                    - Status: {{ checkIn.status }}
                  </div>
                  <ng-container
                    *ngIf="checkIn.status === checkinStatus.Answered"
                  >
                    <span
                      class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                      >Answered</span
                    >
                  </ng-container>

                  <ng-container *ngIf="checkIn.status === checkinStatus.Sent">
                    <span
                      class="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700"
                      >Message from Client</span
                    >
                  </ng-container>

                  <div class="flex flex-row items-center">
                    <div class="italic text-gray-400 text-xs pr-4">
                      Last Edit:{{
                        checkIn.lastEdit | date: 'dd-MM-yyyy - HH:mm'
                      }}
                    </div>
                    <div
                      class="w-fit h-fit p-2 border-2 rounded-lg border-gray-500 hover:bg-gray-200 cursor-pointer"
                      [ngClass]="{
                        'bg-gray-200': selectedCheckinIndex === i,
                        'bg-white': selectedCheckinIndex !== i
                      }"
                      (click)="
                        openCheckIn(checkIn, i); $event.stopPropagation()
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div *ngIf="checkInToggler[i] && selectedCheckIn">
                  <app-check-in-detail-view
                    [coach]="this.user"
                    [checkIn]="selectedCheckIn"
                  ></app-check-in-detail-view>
                  <app-display-checkin-form-dashboard
                    [selectedCheckin]="selectedCheckIn"
                    [coach]="user"
                  ></app-display-checkin-form-dashboard>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-template #noCheckins> NO DATA </ng-template>
        </div>
      </li>

      <!-- More items... -->
    </ul>
  `,
  styles: [],
})
export class CoachCheckInComponent implements OnDestroy {
  user: AppUser = {};
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];
  selectedClient: AppUser = {};

  clientSubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;
  checkinSubscription: Subscription | undefined;

  clientToggler: boolean[] = [];
  selectedClientIndex: number | undefined = undefined;

  searchNameValue = '';

  checkinStatus = CheckinStatus;

  checkIns$: Observable<ClientCheckin[]> | undefined;
  checkInToggler: boolean[] = [];
  selectedCheckIn: ClientCheckin | undefined;
  selectedCheckinIndex: number | undefined = undefined;

  constructor(
    protected auth: AuthService,
    protected userService: UserService,
    private checkinService: CheckinService,
  ) {}
  ngOnDestroy(): void {
    this.checkinSubscription?.unsubscribe();
  }

  getClientCheckins(clientId: string) {
    this.checkIns$ = this.checkinService.getClientCheckins(clientId);
    this.checkinSubscription = this.checkIns$.subscribe((checkIns) => {
      this.checkInToggler = new Array<boolean>(checkIns.length).fill(false);
    });
  }

  openCheckIn(checkIn: ClientCheckin, index: number) {
    if (this.selectedCheckIn === checkIn) {
      this.selectedCheckIn = undefined;
      this.selectedCheckinIndex = undefined;
      this.checkInToggler[index] = !this.checkInToggler[index];
    } else {
      this.selectedCheckIn = checkIn;
      this.selectedCheckinIndex = index;
      this.checkInToggler.fill(false);
      this.checkInToggler[index] = !this.checkInToggler[index];
    }
  }

  onOpenClient(client: AppUser, index: number) {
    this.selectedClient = client;
    this.selectedClientIndex = index;
    if (this.clientToggler[index]) {
      this.clientToggler[index] = !this.clientToggler[index];
      this.selectedClientIndex = undefined;
    } else {
      this.clientToggler.fill(false);
      this.clientToggler[index] = !this.clientToggler[index];
    }

    this.getClientCheckins(client.id as string);
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$
      .pipe(
        switchMap((user: AppUser | null) => {
          const observables: Observable<AppUser>[] = [];
          if (user) {
            this.user = user;
            if (this.user.clientIds && this.user.clientIds.length > 0) {
              for (const clientid of user.clientIds!) {
                this.clientIdDatabase = user.clientIds as string[];

                observables.push(
                  this.userService.getUserFromDatabase(
                    clientid,
                  ) as Observable<AppUser>,
                );
              }
            }
          }
          return combineLatest(observables);
        }),
      )
      .pipe(
        map((clients: AppUser[]) => {
          return clients
            .filter((client) => client && client.coachId === this.user.id)
            .map((client) => {
              let cl = client as AppUser;
              // console.log(cl.birthdate);

              //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();
              if (cl.birthdate) cl.birthdate = convertDateObject(cl.birthdate);

              return cl;
            });
        }),
      )
      .subscribe((clients) => {
        for (const client of clients) {
          //this.getWeightLogData(client, 30);

          if (this.clientDatabase.some((e) => e.id === client.id)) {
            let i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
            this.clientToggler.push(false);
          }
        }
        this.displayClients = this.clientDatabase;
      });

    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }
      });
  }
}
