import { Component, Input } from '@angular/core';
import { Macros, calculateKcalFromMacros } from 'src/app/core/thecoach';

@Component({
  selector: 'app-display-macros-module',
  template: `
<dl class="mt-1 grid grid-cols-4  gap-2 text-center">
      <div class="col-span-4">
        <h3 class="font-semibold text-gray-900">{{title}}</h3>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">Protein</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ macros.protein }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">Carbs</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ macros.carbs }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">Fat</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ macros.fat }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">KCAL</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ calculateKcal(macros) }}
        </dd>
      </div>
    </dl>
  `,
  styles: [
  ]
})
export class DisplayMacrosModuleComponent {
  @Input() macros!: Macros;
  @Input() title!: string;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }
}
