import { Component, Input } from '@angular/core';
import { AppUser, CustomInputType, CustomTrackingModel } from 'src/app/core/thecoach';

@Component({
  selector: '[app-statistics-ctm-tableheader]',
  template: `
  <ng-container *ngIf="ctm">
    <tr class="divide-x divide-gray-200">
        <th
          scope="col"
          class=" pr-1 text-center text-sm font-semibold   sm:pl-0 "
        >
          Date
        </th>
  <th
              *ngFor="let ctv of ctm.ccts"
              scope="col"
              class=" px-1 text-center text-sm font-semibold  "
            >
              <div *ngIf="ctv.name.length <= 8">
                {{ ctv.name }}
              </div>

              <div
                class=" group flex relative items-center justify-center"
                *ngIf="ctv.name.length > 8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
                <span
                  class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                >
                  {{ ctv.name }}</span
                >
              </div>
            </th>
    </tr>
              <tr class="divide-x divide-gray-200">
                       <th scope="col"></th>
             <th
              *ngFor="let ctv of ctm.ccts"
              scope="col"
              class="px-1 text-center text-sm font-semibold  "
            >
              <div>
                <ng-container
                  *ngIf="ctv.type === CustomInputType.Number"
                  class="flex flex-row"
                >
                  <p class="">
                    {{ ctv.defaultValue }}
                  </p>
                </ng-container>

                <ng-container
                  *ngIf="ctv.type === CustomInputType.Range"
                  class="flex flex-row"
                >
                  <p class="">{{ ctv.min }}-{{ ctv.max }}</p>
                </ng-container>
              </div>
            </th>
    </tr>
  </ng-container>
  `,
  styles: [
  ]
})
export class StatisticsCtmTableheaderComponent {
  @Input() client!: AppUser;
  @Input() ctm!: CustomTrackingModel | null | undefined;
  CustomInputType = CustomInputType;

}
