import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-video-link-edit-modal',
  template: `
    <div
      @opacity
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <div
            @opacityTranslateY
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div class="bg-white shadow sm:rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Enter Videolink
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Change the Video link of {{ exerciseName }}</p>
                </div>
                <form class="mt-5 sm:flex sm:items-center">
                  <div class="w-full sm:max-w-xs">
                    <label for="email" class="sr-only">Videolink</label>
                    <input
                      type="url"
                      name="videolink"
                      id="videolink"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                      [(ngModel)]="videoLink"
                    />
                  </div>
                  <button
                    (click)="onClose(true)"
                    type="button"
                    class="mt-3 inline-flex w-full items-center justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 sm:ml-3 sm:mt-0 sm:w-auto"
                  >
                    Save
                  </button>
                  <button
                    (click)="onClose(false)"
                    type="button"
                    class="mt-3 inline-flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 sm:ml-3 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class VideoLinkEditModalComponent implements OnInit {
  @Input() slotForm?: AbstractControl;
  @Output() onSave = new EventEmitter<boolean>();

  exerciseName: string | undefined;
  videoLink: string | undefined;

  constructor() {}

  ngOnInit(): void {
    if (this.slotForm) {
      this.exerciseName = this.slotForm.get('exerciseName')!.value;
      this.videoLink = this.slotForm.get('exerciseVideoLink')!.value;
    }
  }

  onClose(val: boolean) {
    if (val) {
      this.slotForm?.get('exerciseVideoLink')?.patchValue(this.videoLink);
    }
    this.onSave.emit(val);
  }
}
