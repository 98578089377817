import { Component, Input } from '@angular/core';
import { AppUser } from 'src/app/core/thecoach';

@Component({
  selector: '[app-statistics-macro-tracking-tableheader]',
  template: `
<ng-container  class="text-gray-900 ">
      <tr class="divide-x divide-gray-200">
        <th
          scope="col"
          class="pt-3.5   pr-1 text-center text-sm font-semibold   sm:pl-0 "
        >
          Date
        </th>
       
        <th
          scope="col"
          colspan="4"
          class="pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Daily Macros
        </th>
        <th
          *ngIf="client.enableOffDayMacros"
          scope="col"
          colspan="4"
          class="pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Off Day Macros
        </th>

        <th
          scope="col"
          colspan="4"
          class=" pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Achieved Macros
        </th>
      </tr>
       <tr class="divide-x divide-gray-200">
        <th scope="col"></th>
        
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">P</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">C</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">F</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
          KCAL
        </th>
        <ng-container *ngIf="client.enableOffDayMacros">
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            P
          </th>
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            C
          </th>
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            F
          </th>
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            KCAL
          </th>
        </ng-container>
        <th scope="col" class="px-1 text-center text-sm font-semibold  ">P</th>
        <th scope="col" class="px-1 text-center text-sm font-semibold  ">C</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">F</th>
        <th scope="col" class="px-1 text-center text-sm font-semibold  ">
          KCAL
        </th>
       </tr>

  `,
  styles: [
  ]
})
export class StatisticsMacroTrackingTableheaderComponent {
  @Input() client!: AppUser;
}
