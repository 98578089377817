import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Note } from 'src/app/core/thecoach';
import { NoteService } from 'src/app/services/note.service';

@Component({
  selector: 'app-coach-customer-notes-creator',
  template: `
    <div class="items-center">
      <div class="mt-4">
        <h2 class="text-lg font-semibold self-center text-center">
          Create New Note
        </h2>
        <form [formGroup]="noteForm" (ngSubmit)="createNote()" class="mt-4">
          <label for="title" class="block font-semibold mb-1">Title:</label>
          <input
            type="text"
            id="title"
            formControlName="title"
            class="border rounded py-2 px-3 w-full"
            required
          />

          <label for="body" class="block font-semibold mt-4 mb-1"
            >Message:</label
          >
          <textarea
            id="body"
            formControlName="body"
            class="border rounded py-2 px-3 w-full"
            required
          ></textarea>
          <div class="flex w-full items-center justify-between">
            <button
              type="submit"
              class="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Save
            </button>
            <button
              type="button"
              class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
              (click)="cancel()"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [],
})
export class CoachCustomerNotesCreatorComponent implements OnInit, OnDestroy {
  @Output() saveNote = new EventEmitter<Note>();
  @Output() canceled = new EventEmitter<boolean>();
  @Input() editNote: Note | undefined;

  noteForm = this.fb.group({
    title: ['', Validators.required],
    body: ['', Validators.required],
    id: [''],
    lastedit: [''],
  });

  constructor(private fb: FormBuilder) {}
  ngOnDestroy(): void {}
  ngOnInit(): void {
    if (this.editNote) {
      this.noteForm.get('title')?.patchValue(this.editNote.title);
      this.noteForm.get('body')?.patchValue(this.editNote.message);
      this.noteForm.get('id')?.patchValue(this.editNote.id as string);
    }
  }

  createNote() {
    if (this.noteForm.valid) {
      const note: Note = {
        title: this.noteForm.value.title as string,
        message: this.noteForm.value.body as string,
        id: this.noteForm.value.id as string,
        lastEdit: new Date(),
      };

      // Here, you can implement the logic to save the note, e.g., send an API request, etc.

      // Reset the form and hide it
      this.noteForm.reset();
      this.saveNote.emit(note);
    }
  }

  cancel() {
    // Reset the form and hide it
    this.noteForm.reset();
    this.canceled.emit(false);
  }
}
