import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ro } from 'date-fns/locale';
import { AppUser, Message } from './core/thecoach';
import { NotificationService } from './services/notification.service';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { MessagePayload } from 'firebase/messaging';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  message: Observable<MessagePayload | null> | undefined;

  constructor(
    protected auth: AuthService,
    private notification: NotificationService,
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar

  ) {
    //

    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (appUser) {
        // User is logged in and appUser is available, load notifications adding Test
        this.loadNotifications();
        //this.checkSoftwareUpdates();
      }
    });

    this.initCheckForUpdates();
  }
  ngOnInit(): void { }

  loadNotifications() {
    this.notification.requestPermission();
    this.notification.receiveMessage();
    this.message = this.notification.currentMessage;
  }

  removeNotification(val: MessagePayload) {
    this.notification.currentMessage.next(null);
    this.notification.receiveMessage();
  }

  /*
  isUpdate = false;

  close(event: boolean) {
    this.isUpdate = false;
    window.location.reload();
    console.log('closed');
  }
*/

  initCheckForUpdates() {
    if (!this.swUpdate.isEnabled) return;

    setInterval(() => {
      //  console.log('Checking for updates!!!');
      this.swUpdate.checkForUpdate().then((e: any) => {
        //console.log('Checking for updates', e)
        if (e) {
          this.swUpdate.activateUpdate().then((e: any) => {
            // console.log('Updates are available', e);
          })
        }
      });
    }, 2 * 60 * 1000)


    this.swUpdate.versionUpdates.subscribe((event: any) => {
      // console.log('SWEVENT!:', event)
      if (event.type === "VERSION_READY") {
        //console.log('AN UPDATE IS AVAILAVBLE!')
        const snackBarRef = this.snackBar.open('NEW APP VERSION!', 'UPDATE');
        snackBarRef.onAction().subscribe(() => {
          window.location.reload();
        });
      }


    })







  }

}
