import { Component, Input } from '@angular/core';
import { WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: '[app-statistics-ctm-tablebody]',
  template: `
    <tr
      class="divide-x divide-gray-200"
      *ngFor="let weighEntry of weightLogData; let j = index"
      [ngClass]="{
        'bg-gray-200': j % 7 === 0
      }"
    >
    <ng-container
        *ngIf="
          weighEntry.customTrackingLog &&
          weighEntry.customTrackingLog.ccts
        "
      >
         <td
        class="whitespace-nowrap  text-center py-0.5  pr-1 text-sm font-medium text-gray-900 sm:pl-0"
      >
        {{ weighEntry.date | date : 'dd.MM' }}
      </td>
   <td
          class="whitespace-wrap px-1 text-xs text-gray-500 text-center"
          *ngFor="let cct of weighEntry.customTrackingLog.ccts"
        >
          <div *ngIf="cct.type !== 'boolean'">
            <div
              *ngIf="
                cct.type === 'textShort' || cct.type === 'textLong';
                else number
              "
            >
              <div
                class="group flex relative items-center pl-0.5"
                *ngIf="cct.value"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>

                <span
                  class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute
    -translate-x-[100%] translate-y-4 opacity-0 m-4 mx-auto"
                  >{{ cct.value }}</span
                >
              </div>
            </div>
            <ng-template #number>
              {{ cct.value }}
            </ng-template>
          </div>

          <div *ngIf="cct.type === 'boolean'" class="items-center flex justify-center">
            <div *ngIf="cct.value === true" >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-green-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div *ngIf="cct.value === false || !cct.value">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-red-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div>
        </td>
    </ng-container>
    </tr>
  `,
  styles: [
  ]
})
export class StatisticsCtmTablebodyComponent {
  @Input() weightLogData: WeightEntryData[] | null = [];

}
