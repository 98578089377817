import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  template: `
    <div class="flex flex-row items-center">
      <button
        *ngIf="toggleProperty !== undefined"
        type="button"
        (click)="isWeightLog ? onToggleWeightLog() : onToggle()"
        [ngClass]="{
          'bg-teal-600': toggleProperty,
          'bg-gray-600': !toggleProperty,
          'cursor-not-allowed opacity-50':
            isWeightLog && disableOnlyMode && !toggleProperty,
        }"
        class="bg-gray-200 relative inline-flex h-4 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
        role="switch"
        aria-labelledby="property-toggler"
      >
        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
        <span
          aria-hidden="true"
          [ngClass]="{
            'translate-x-5': toggleProperty,
            'translate-x-0': !toggleProperty,
          }"
          class="pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        ></span>
      </button>
      <div *ngIf="description">
        <div
          class="ml-1 text-sm flex flex-row items-center "
          id="description-switch"
        >
          <span class="font-medium text-gray-900">{{ description }}</span>
          <div
            *ngIf="isWeightLog"
            class="ml-1.5 w-5 h-5 p-0.5 rounded-full"
            [ngClass]="{
              'text-red-400 bg-gray-700': !toggleProperty,
              'text-green-400 bg-gray-100': toggleProperty,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              class=" size-4"
            >
              <path
                fill-rule="evenodd"
                d="M8 1a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0v-6.5A.75.75 0 0 1 8 1ZM4.11 3.05a.75.75 0 0 1 0 1.06 5.5 5.5 0 1 0 7.78 0 .75.75 0 0 1 1.06-1.06 7 7 0 1 1-9.9 0 .75.75 0 0 1 1.06 0Z"
                clip-rule="evenodd"
                stroke="currentColor"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class ToggleSwitchComponent {
  @Input({ required: true }) toggleProperty!: boolean | undefined;
  @Input() description?: string;
  @Input() disableOnlyMode?: boolean = false;
  @Input({ required: true }) isWeightLog!: boolean;
  @Output() onToggleProperty = new EventEmitter();

  onToggleWeightLog() {
    //  console.log(this.disableOnlyMode, this.toggleProperty);
    if (this.toggleProperty || !this.disableOnlyMode) {
      this.onToggleProperty.emit();
    }
  }
  onToggle() {
    this.onToggleProperty.emit();
  }
}
