import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-duration-dropdown',
  template: `
    <div class="dropdown">
      <div class="relative inline-block text-left">
        <div>
          <button
            type="button"
            (click)="toggleMenu(); $event.stopPropagation()"
            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <ng-container *ngIf="defaultDuration !== 0; else allData"
              >{{ defaultDuration }} Days</ng-container
            >
            <ng-template #allData>All Data</ng-template>
            <svg
              class="-mr-1 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          @opacityScale
          *ngIf="isMenu"
          class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <button
              (click)="
                onSetSelectedDuration(duration);
                toggleMenu();
                $event.stopPropagation()
              "
              *ngFor="let duration of durationOptions"
              class="w-full text-start text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              <ng-container *ngIf="duration !== 0; else allData"
                >{{ duration }} Days</ng-container
              >
              <ng-template #allData>All Data</ng-template>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="isMenu" (click)="toggleMenu()" class="fixed inset-0"></div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class DurationDropdownComponent {
  isMenu = false;
  durationOptions = [7, 14, 30, 60, 90, 120, 180, 240, 300, 365, 0];

  @Input() defaultDuration!: number;
  @Output() selectedDuration = new EventEmitter<number>();

  onSetSelectedDuration(duration: number) {
    this.defaultDuration = duration;
    this.selectedDuration.emit(duration);
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }
}
