import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ms } from 'date-fns/locale';
import { ClipboardService } from 'ngx-clipboard';
import {
  AppUser,
  CheckinStatus,
  ClientCheckin,
  Message,
  deleteCheckinMessage,
} from 'src/app/core/thecoach';
import { CheckinService } from 'src/app/services/checkin.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-check-in-detail-view',
  template: `<div class="bg-white py-4">
    <div class="mb-4 col-span-2">
      <dd>
        <div class="grid grid-cols-4 gap-4">
          <ng-container *ngFor="let imgUrl of checkIn.imgUrls">
            <img
              [src]="imgUrl.downloadURL || imgUrl"
              class="w-full h-auto"
              alt="Image"
            />
          </ng-container>
        </div>
      </dd>
    </div>

    <div class="mb-4 col-span-2">
      <dt class="text-lg font-semibold">Messages:</dt>
      <dd>
        <app-checkin-message
          [checkIn]="checkIn"
          [customer]="false"
          (ondelete)="onDeleteMsg($event)"
        ></app-checkin-message>
      </dd>
    </div>
    <div class="w-full flex justify-end pt-2">
      <button
        (click)="onAnswerCheckIn()"
        *ngIf="!answerToggler"
        type="button"
        class="rounded-md bg-teal-50 py-1.5 px-2.5 text-sm font-semibold text-teal-600 shadow-sm hover:bg-teal-100"
      >
        Send Message
      </button>
      <div *ngIf="answerToggler" class="w-full">
        <app-checkin-form-answer
          (toggleAnswer)="onAnswerCheckIn()"
          [selectedCheckin]="checkIn"
          [user]="this.coach"
        >
        </app-checkin-form-answer>
      </div>
    </div>
  </div> `,
  styles: [],
})
export class CheckInDetailViewComponent implements OnInit {
  @Input() checkIn!: ClientCheckin;
  @Input() coach!: AppUser;
  answerToggler = false;

  constructor(
    private fb: FormBuilder,
    private checkInService: CheckinService,
    private clipBoardService: ClipboardService,
    private fileService: FileService
  ) {}
  ngOnInit(): void {
    // console.log(this.checkIn);
  }

  checkOriginalAuthor(checkin: ClientCheckin, message: Message): boolean {
    if (!checkin.messages) return false;

    if (!message.authorId) {
      if (checkin.messages[0].author === message.author) return true;
    } else {
      if (checkin.messages[0].authorId === message.authorId) return true;
    }

    return false;
  }

  onAnswerCheckIn() {
    this.answerToggler = !this.answerToggler;
  }

  onDeleteMsg(event: { checkin: ClientCheckin; msg: Message }) {
    deleteCheckinMessage(event, this.checkInService);
  }
}
