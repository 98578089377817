import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, Form, FormArray, FormGroup } from '@angular/forms';
import {
  SlotModel,
  CustomSlotInputNumber,
  CustomSlotModelInputType,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-template-slot-generation',
  template: `
    <div *ngIf="loadForm">
      <form [formGroup]="slotModelForm">
        <div class="flex flex-row w-full">
          <div *ngFor="let customModelInput of csmiv.controls; let i = index">
            <ng-container [ngSwitch]="getInputTypeFromForm(customModelInput)">
              <ng-container *ngSwitchCase="customSlotModelInputType.Number"
                ><app-number-slot
                  *ngIf="
                    getInputTypeFromForm(customModelInput) ===
                    customSlotModelInputType.Number
                  "
                  [customModelInput]="
                    convertControlToFormGroup(customModelInput)
                  "
                ></app-number-slot
              ></ng-container>
              <ng-container *ngSwitchCase="customSlotModelInputType.Time"
                ><app-time-slot
                  *ngIf="
                    getInputTypeFromForm(customModelInput) ===
                    customSlotModelInputType.Time
                  "
                  [customModelInput]="
                    convertControlToFormGroup(customModelInput)
                  "
                ></app-time-slot
              ></ng-container>
              <ng-container *ngSwitchCase="customSlotModelInputType.Target"
                ><app-target-slot
                  *ngIf="
                    getInputTypeFromForm(customModelInput) ===
                    customSlotModelInputType.Target
                  "
                  [customModelInput]="
                    convertControlToFormGroup(customModelInput)
                  "
                ></app-target-slot
              ></ng-container>
              <ng-container *ngSwitchDefault>Error</ng-container>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class TemplateSlotGenerationComponent implements OnInit, OnChanges {
  @Input({ required: true }) slotModelForm!: FormGroup;
  customSlotModelInputType = CustomSlotModelInputType;
  loadForm = false;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['slotModelForm'] && changes['slotModelForm'].currentValue) {
      if (this.slotModelForm) {
        this.loadForm = true;
      }
    }
  }
  ngOnInit(): void {
    if (this.customInput) {
      this.loadForm = true;
    }
  }

  get customInput() {
    return this.slotModelForm.get('customInput') as FormGroup;
  }

  get csmiv() {
    return this.customInput.get('csmiv') as FormArray;
  }

  convertControlToFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }

  getInputTypeFromForm(control: AbstractControl) {
    const type = (control as FormGroup).get('type')?.value;

    return type;
  }
  /*
   *
   *
   *
   * export interface SlotModel extends BaseModel {
  name: string; //ModelName
  ownerId?: string;
  customInput: CustomSlotModelInput;
}

export interface CustomSlotModelInput extends BaseModel {
  value?: number; //Sets, Runs, Trys
  csmiv: CustomSlotModelInputValue[];
  progressDirection: boolean; //true if lower values are better, false if higher values are better
}

export type CustomSlotModelInputValue =
  | CustomSlotInputNumber
  //| CustomSlotInputText
  | CustomSlotInputTime
  | CustomSlotInputTarget;

export enum CustomSlotModelInputType {
  //Text = 'text',
  Number = 'number',
  Time = 'time',
  Target = 'target',
}



*/
}
