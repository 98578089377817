import { Component, Input } from '@angular/core';
import { WeightEntryData, AppUser, Macros, calculateKcalFromMacros } from 'src/app/core/thecoach';

@Component({
  selector: '[app-statistics-macro-tracking-tablebody]',
  template: `
 <tr
      class="divide-x divide-gray-200"
      *ngFor="let weighEntry of weightLogData; let j = index"
      [ngClass]="{
        'bg-gray-200': j % 7 === 0
      }"
    >
      <td
        class="whitespace-nowrap  text-center py-0.5  pr-1 text-sm font-medium text-gray-900 sm:pl-0"
      >
        {{ weighEntry.date | date : 'dd.MM' }}
      </td>

       <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weighEntry.refeedMacros?.protein || weighEntry.setMacros?.protein }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weighEntry.refeedMacros?.carbs || weighEntry.setMacros?.carbs }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weighEntry.refeedMacros?.fat || weighEntry.setMacros?.fat }}
      </td>

      <td
        class="whitespace-nowrap  text-center py-0.5 px-1 text-sm text-gray-500"
        *ngIf="weighEntry.refeedMacros; else setMacros"
      >
        {{ calculateKcal(weighEntry.refeedMacros!) }}
      </td>
      <ng-template #setMacros>
        <td
          class="whitespace-nowrap  text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ calculateKcal(weighEntry.setMacros!) }}
        </td>
      </ng-template>

      <ng-container *ngIf="client.enableOffDayMacros">
        <td
          class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ weighEntry.offDayMacros?.protein || '-' }}
        </td>
        <td
          class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ weighEntry.offDayMacros?.carbs || '-' }}
        </td>
        <td
          class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ weighEntry.offDayMacros?.fat || '-' }}
        </td>

        <td
          class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ calculateKcal(weighEntry.offDayMacros!) }}
        </td>
      </ng-container>

      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weighEntry.achivedMacros?.protein || '-' }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weighEntry.achivedMacros?.carbs || '-' }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weighEntry.achivedMacros?.fat || '-' }}
      </td>

      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateKcal(weighEntry.achivedMacros!) }}
      </td>

 </tr>
  `,
  styles: [
  ]
})
export class StatisticsMacroTrackingTablebodyComponent {
  @Input() weightLogData: WeightEntryData[] | null = [];
  @Input() client!: AppUser;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }
}
