import { isSameDay } from 'date-fns';
import { Component, Input } from '@angular/core';
import { Macros, Specification, calculateKcalFromMacros, convertDateObject } from 'src/app/core/thecoach';

@Component({
  selector: 'app-customer-spec-view',
  template: `
<div class=" rounded-lg bg-red-50 px-2 py-2 shadow space-y-2">

<div class="w-full flex flex-row justify-between items-center">
     <div class="font-bold">
     {{ specification.title || 'N/A' }}
    </div>


    <div *ngIf="isSameDayCheck(specification.start, specification.end); else diffDates">
        <div class="text-xs italic">
  {{ specification.start | date:'dd-MM-yyy' || 'N/A' }}
  </div>
    </div>

<ng-template #diffDates>
    <div class="text-xs italic">
       {{ specification.start | date:'dd-MM-yyy' || 'N/A' }} - {{specification.end  | date:'dd-MM-yyy'}}
    </div>
</ng-template>


</div>


    <div *ngIf="specification?.setMessage" class="mt-2 flex flex-col">
    <div class="font-bold">Message from Coach</div>
     <div class="text-xs font-normal whitespace-pre-wrap">{{ specification.message || 'N/A' }}</div>
    </div>

      <div *ngIf="specification?.setTrainingDay" class="mt-2 flex flex-row justify-center w-full">
      <span class="font-bold">Training Day: </span>
      <div class="ml-2 italic"> {{ specification.trainingDay || 'N/A' }} </div>
      </div>

   <div *ngIf="specification?.setMacros && specification.specMacros" class="mt-2">
      <!-- Assuming specMacros is an object with properties like 'protein', 'carbs', 'fat' -->
       <app-display-macros-module [title]="'Specific Macros'" [macros]="specification.specMacros"></app-display-macros-module> 
    </div>
</div>
  `,
  styles: [
  ]
})
export class CustomerSpecViewComponent {
  @Input() specification!: Specification;


  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  isSameDayCheck(start: Date | undefined, end: Date | undefined): boolean {
    return isSameDay(start as Date, end as Date);
  }


}
