import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckinStatus, ClientCheckin, Message } from 'src/app/core/thecoach';

@Component({
  selector: 'app-checkin-submenu',
  template: `
    <div
      @opacityTranslateXY
      *ngIf="isSubMenu"
      class="absolute z-10 flex w-[14rem] max-w-max -translate-x-3/4 px-4"
    >
      <div
        class="w-screen max-w-md flex-auto  rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
      >
        <div class="p-4">
          <div
            class="group relative flex justify-center rounded-lg p-2 hover:bg-gray-200 ring-2 ring-gray-600 cursor-pointer "
            (click)="
              onDelete(); $event.preventDefault(); $event.stopPropagation()
            "
          >
            <div
              class="flex pr-1 flex-none items-center justify-center rounded-lg hover:bg-gray-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-red-400 hover:bg-gray-200"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
            <div>
              <p class="font-semibold text-gray-900 break-normal">
                Delete Checkin
                <span class="absolute inset-0"></span>
              </p>
            </div>
          </div>

          <div
            *ngIf="checkin.status === checkinStatus.Sent"
            class="group relative mt-2 flex justify-center text-center rounded-lg p-2 hover:bg-gray-200 ring-2 ring-gray-600 cursor-pointer"
            (click)="onCloseCheckin(); $event.stopPropagation()"
          >
            <div>
              <p class="font-semibold text-gray-900">
                Set Answered
                <span class="absolute inset-0"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityTranslateXY', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CheckinSubmenuComponent {
  @Input() isSubMenu!: boolean;
  @Input() checkin!: ClientCheckin;
  @Output() delete = new EventEmitter<boolean>();
  @Output() closeCheckin = new EventEmitter<boolean>();

  checkinStatus = CheckinStatus;

  onDelete() {
    this.delete.emit(true);
  }

  onCloseCheckin() {
    this.closeCheckin.emit(true);
  }
}
