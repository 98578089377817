import { forEach, isDate } from 'lodash';
import { CalendarComponent } from './../calendar/calendar.component';
import { he } from 'date-fns/locale';
import { Subscription, first } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Calendar,
  CalendarOptions,
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventInput,
  EventSourceInput,
} from '@fullcalendar/core';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { WeightlogService } from 'src/app/services/weightlog.service';
import {
  AppUser,
  CalendarEventData,
  Specification,
  TemplateTrainingDay,
  TrainingLog,
  WeightEntryData,
  convertDateObject,
} from 'src/app/core/thecoach';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isWithinInterval,
  parse,
  startOfToday,
  startOfWeek,
  sub,
} from 'date-fns';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { LogService } from 'src/app/services/log.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { en } from '@fullcalendar/core/internal-common';
import { AuthService } from 'src/app/services/auth.service';
import { SpecificationsService } from 'src/app/services/specifications.service';

@Component({
  selector: 'app-calendar-full',

  template: ` <div *ngIf="user">
    <full-calendar
      #calendar
      [options]="calendarOptions"
      [eventSources]="eventSources"
      [deepChangeDetection]="false"
      *ngIf="true"
      class="h-full"
    >
    </full-calendar>

    <app-calendar-full-event-modal
      [user]="user"
      [coach]="coach"
      (onClose)="onCloseModal($event)"
      [isDateClick]="isDateClick"
      [selectedSpecEntries]="selectedSpecEntries"
      [selectedWeightEntry]="selectedWeightEntry"
      [selectedTrainingDay]="selectedTrainingDay"
      [selectedCalendarEventEntries]="selectedEventEntries"
      *ngIf="selectedWeightEntry !== undefined"
    >
    </app-calendar-full-event-modal>

    <app-calendar-multievent-modal
      *ngIf="multiEventSelection"
      [inputStart]="multiSelectionStartDate!"
      [inputEnd]="multiSelectionEndDate"
      (triggerNewCalEvent)="onNewMultiDayCalEvent($event)"
      (triggerNewSpecEvent)="onNewMultiDaySpecEvent($event)"
      (onClose)="onCloseModal($event)"
    >
    </app-calendar-multievent-modal>

    <app-calendar-new-entry-modal
      *ngIf="newMultiDayCalEventTrigger"
      [inputDate]="multiSelectionStartDate!"
      [inputEnd]="multiSelectionEndDate"
      (close)="newCalendarEventToggle($event)"
      (save)="onSaveCalendarEvent($event)"
    ></app-calendar-new-entry-modal>

    <app-coach-specsentry-modal
      *ngIf="newMultiDaySpecEventTrigger"
      [client]="user"
      [inputEnd]="multiSelectionEndDate"
      [inputStart]="multiSelectionStartDate"
      [selectedSpecEntry]="{}"
      (cancel)="onCancel($event)"
      (onSaveData)="onSaveSpec($event)"
    >
    </app-coach-specsentry-modal>
  </div>`,
  styles: [``],
})
export class CalendarFullComponent implements OnInit, OnDestroy {
  @Input() client?: AppUser;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    firstDay: 1,
    plugins: [dayGridPlugin, interactionPlugin],
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },

    height: '78vh',
    dayMaxEventRows: true,

    editable: true,
    selectable: true,
    selectMirror: true,
    fixedWeekCount: false,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    dateClick: this.handleDateClick.bind(this),

    eventDataTransform: function (event) {
      if (event.allDay) {
        event.end = add(event.end as Date, { days: 1 });
      }
      return event;
    },
    datesSet: this.updateCalendarDates.bind(this),
    eventContent: function (eventInfo) {
      return { html: eventInfo.event.extendedProps['customHtml'] };
    },

    eventTimeFormat: {
      // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: true,
      hour12: false,
    },
  };

  weightLogSubscription: Subscription | undefined;
  weightEntryDatabase: WeightEntryData[] = [];
  trainingLogSubscription: Subscription | undefined;
  loadedTrainingLogs: TemplateTrainingDay[] = [];
  calendarEventsSubscription: Subscription | undefined;
  calendarEventsData: CalendarEventData[] = [];

  specLogSubscription: Subscription | undefined;
  specEntryDatabase: Specification[] = [];

  weightLogEvents: CalendarEventData[] = [];
  achievedMacroEvents: CalendarEventData[] = [];
  ctmEvents: CalendarEventData[] = [];
  trainingLogEvents: CalendarEventData[] = [];
  calendarEvents: CalendarEventData[] = [];
  specEvents: CalendarEventData[] = [];
  eventSources: {}[] = [];

  selectedWeightEntry: WeightEntryData | undefined;
  selectedTrainingDay: TemplateTrainingDay | undefined;
  selectedEventEntries: CalendarEventData[] = [];
  selectedSpecEntries: Specification[] = [];
  loadedDatabaseSpec: Specification[] = [];
  isDateClick = false;

  user: AppUser | undefined;
  coach: AppUser | undefined;

  calendarStartDate: Date | undefined = undefined;
  calendarEndDate: Date | undefined = undefined;

  multiEventSelection = false;
  multiSelectionStartDate: Date | undefined = undefined;
  multiSelectionEndDate: Date | undefined = undefined;

  newMultiDaySpecEventTrigger = false;
  newMultiDayCalEventTrigger = false;

  constructor(
    private weightLogService: WeightlogService,
    private changeDetector: ChangeDetectorRef,
    private trainingLogService: LogService,
    private calendarEventService: CalendarService,
    private specService: SpecificationsService,
    private authService: AuthService,
  ) {}
  ngOnDestroy(): void {
    this.weightLogSubscription?.unsubscribe();
    this.trainingLogSubscription?.unsubscribe();
    this.calendarEventsSubscription?.unsubscribe();
    this.specLogSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.client) {
      this.authService.appUser$.pipe(first()).subscribe((appUser) => {
        if (appUser) {
          this.user = appUser;
          this.client = appUser;
          this.loadSpecEntries();
        }
      });
    } else {
      this.user = this.client;
      this.authService.appUser$.pipe(first()).subscribe((appUser) => {
        if (appUser) {
          this.coach = appUser;
          this.loadSpecEntries();
        }
      });
    }
  }

  updateCalendarDates(dateInfo: { start: Date; end: Date }): void {
    if (dateInfo.end) {
      this.calendarEndDate = dateInfo.end;
    }
    if (dateInfo.start) {
      this.calendarStartDate = dateInfo.start;
    }

    this.loadSpecEntries();
    this.loadWeightLogData();
    this.loadCalendarEntries();
    this.loadTraininglogData();
  }

  updateEventSources() {
    this.eventSources = [
      this.specEvents,
      this.weightLogEvents,
      this.achievedMacroEvents,
      this.ctmEvents,
      this.trainingLogEvents,
      this.calendarEvents,
      // Add more event sources as needed
    ].filter((source) => source && source.length > 0);

    this.changeDetector.detectChanges();
    // console.log('eventsources', this.eventSources)
  }

  loadWeightLogData(): void {
    if (this.user && this.calendarStartDate && this.calendarEndDate) {
      if (this.weightLogSubscription) {
        this.weightLogSubscription.unsubscribe();
      }

      this.weightLogSubscription = this.weightLogService
        .getWeightLogForUserBetweenDates(
          this.user.id as string,
          this.calendarStartDate as Date,
          this.calendarEndDate as Date,
        )
        .subscribe((data) => {
          this.weightLogEvents = [];
          this.achievedMacroEvents = [];
          this.ctmEvents = [];
          data.forEach((item) => {
            if (item.date) {
              item.date = convertDateObject(item.date);
            }
            if (item.weight) {
              let eventEntry = {
                title: item.weight.toString() + ' kg',
                customHtml: item.weight.toString() + ' kg',
                start: item.date as Date,
                allDay: true,
                className:
                  'bg-teal-500 hover:bg-teal-100 opacity-90 text-center font-normal border-0',
              };
              this.weightLogEvents.push(eventEntry);
            }

            if (item.achivedMacros) {
              let macroEntry = {
                title: 'Macros',
                customHtml:
                  '<img class= "w-5 h-5" src="../../assets/icons/macros-icon-V4-checked.png"/>',
                start: item.date as Date,
                allDay: true,
                className:
                  'bg-cyan-300 hover:bg-cyan-100 flex justify-center opacity-90 text-center  items-center font-normal border-0 ',
                textColor: '#0e7490',
              };
              this.achievedMacroEvents.push(macroEntry);
            }

            if (item.customTrackingLog) {
              let ctmEntry = {
                title: 'ctm',
                customHtml:
                  ' <img class= "w-5 h-5 " src="../../assets/icons/3d-modeling-icon-checked.png" />',
                start: item.date as Date,
                allDay: true,
                className:
                  'bg-violet-300 hover:bg-violet-100 flex justify-center opacity-90 text-center  items-center font-normal border-0 ',
                textColor: '#6d28d9',
              };
              this.ctmEvents.push(ctmEntry);
            }
          });

          this.weightEntryDatabase = data;
          this.updateEventSources();
        });
    }
  }

  loadTraininglogData() {
    if (this.user && this.calendarStartDate && this.calendarEndDate) {
      if (this.trainingLogSubscription) {
        this.trainingLogSubscription.unsubscribe();
      }

      this.trainingLogSubscription = this.trainingLogService
        .getAllLogsForUserBetweenDates(
          this.user,
          this.calendarStartDate,
          this.calendarEndDate,
        )
        .subscribe((trainingLogs) => {
          if (trainingLogs) {
            this.trainingLogEvents = [];
            this.loadedTrainingLogs = trainingLogs;
            trainingLogs.forEach((td) => {
              let trainingLogEntry = {
                title: td.trainingDayName as string,
                customHtml:
                  (('<p class="truncate">' + td.trainingDayName) as string) +
                  '</p>',
                allday: true,
                start: td.startDate as Date,
                end: td.endDate as Date,
                className:
                  'bg-lime-300 hover:bg-lime-100 flex justify-center opacity-90 text-center text-xs items-center font-semibold border-0 ',
                textColor: '#6d28d9',
              };
              this.trainingLogEvents.push(trainingLogEntry);
            });

            this.updateEventSources();
          }
        });
    }
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    // console.log('select', selectInfo)
    this.isDateClick = false;
    this.multiSelectionStartDate = selectInfo.start as Date;
    this.multiSelectionEndDate = sub(selectInfo.end as Date, { days: 1 });

    if (isSameDay(this.multiSelectionStartDate, this.multiSelectionEndDate)) {
      this.checkForDateSelection(this.multiSelectionStartDate as Date);
    } else {
      this.multiEventSelection = true;
    }
  }

  handleDateClick(clickInfo: DateClickArg) {
    // console.log("handleDateClick", clickInfo);
    this.isDateClick = true;
    this.checkForDateSelection(clickInfo.date as Date);
  }

  handleEventClick(clickInfo: EventClickArg) {
    //   console.log("handleEventClick", clickInfo);
    //  console.log("eventinfo", clickInfo.event.title, clickInfo.event.start, clickInfo.event)

    this.isDateClick = false;
    this.checkForDateSelection(clickInfo.event.start as Date);
  }

  checkForDateSelection(inputDate: Date) {
    const entry = this.weightEntryDatabase.find((entry) =>
      isSameDay(entry.date as Date, inputDate as Date),
    );

    let foundTrainingDay: TemplateTrainingDay | undefined = undefined;
    foundTrainingDay = this.loadedTrainingLogs.find((td) =>
      isSameDay(td.startDate as Date, inputDate as Date),
    );

    let foundEvents: CalendarEventData[] = [];

    if (this.calendarEvents) {
      //  console.log(inputDate, this.calendarEvents)
      this.calendarEvents.forEach((event) => {
        if (
          !isSameDay(event.start as Date, event.end as Date) &&
          !this.isDateClick
        ) {
          event.end = sub(event.end as Date, { days: 1 });
        }
        //  console.log('event', event, inputDate as Date)
        // console.log(isWithinInterval(inputDate as Date, { start: event.start as Date, end: event.end as Date }))
        if (
          event.start &&
          event.end &&
          event.start !== event.end &&
          isWithinInterval(inputDate as Date, {
            start: event.start as Date,
            end: event.end as Date,
          })
        ) {
          foundEvents.push(event);
        }
      });
    }

    let foundSpecEvents: CalendarEventData[] = [];
    if (this.specEvents) {
      // console.log('before Error', this.specEvents)
      this.specEvents.forEach((event) => {
        if (!isSameDay(event.start as Date, event.end as Date)) {
          event.end = sub(event.end as Date, { days: 1 });
        }
        if (
          event.start &&
          event.end &&
          event.start !== event.end &&
          isWithinInterval(inputDate as Date, {
            start: event.start as Date,
            end: event.end as Date,
          })
        ) {
          foundSpecEvents.push(event);
        }
      });
    }

    if (entry) {
      this.selectedWeightEntry = entry;
      //   console.log('WESelect: ', this.selectedWeightEntry)
    } else {
      this.selectedWeightEntry = { date: inputDate as Date };
    }

    if (foundTrainingDay) {
      this.selectedTrainingDay = foundTrainingDay;
      //    console.log('TDSelect: ', this.selectedTrainingDay)
    }

    if (foundEvents.length > 0) {
      this.selectedEventEntries = foundEvents;
      //console.log('CalEvSelect: ', this.selectedEventEntries)
    }

    if (this.specEvents.length > 0) {
      this.selectedSpecEntries = this.loadedDatabaseSpec.filter(
        (spec) =>
          isSameDay(spec.start as Date, inputDate as Date) ||
          isWithinInterval(inputDate, {
            start: spec.start as Date,
            end: spec.end as Date,
          }),
      );
    }
  }

  handleEvents(events: EventApi[]) {
    // this.currentEvents = events;
    this.changeDetector.detectChanges();
  }

  loadSpecEntries() {
    if (this.client && this.calendarStartDate && this.calendarEndDate) {
      if (this.specLogSubscription) {
        this.specLogSubscription.unsubscribe();
      }

      this.specLogSubscription = this.specService
        .getSpecsForUserBetweenDates(
          this.client.coachId as string,
          this.client.id as string,
          this.calendarStartDate,
          this.calendarEndDate,
        )
        .subscribe((data) => {
          this.specEvents = [];
          data.forEach((item) => {
            if (item.start) {
              let specEntry = {
                id: item.id,
                title: item.title,
                customHtml: item.title as string,
                start: item.start as Date,
                end: item.end as Date,
                allDay: true,
                className:
                  'bg-red-500 hover:bg-red-100 opacity-90 text-center font-normal border-0',
              };
              this.specEvents.push(specEntry);
            }
          });

          this.loadedDatabaseSpec = data;
          // this.selectedSpecEntries = data;
          this.updateEventSources();
        });
    }
  }

  loadCalendarEntries() {
    if (this.user && this.calendarStartDate && this.calendarEndDate) {
      if (this.calendarEventsSubscription) {
        this.calendarEventsSubscription.unsubscribe();
      }

      this.calendarEventsSubscription = this.calendarEventService
        .getCalendarForUserBetweenDates(
          this.user.id as string,
          this.calendarStartDate,
          this.calendarEndDate,
        )
        .subscribe((events) => {
          if (events) {
            this.calendarEvents = [];
            events.forEach((event) => {
              let eventEntry = {
                id: event.id,
                customHtml: event.title as string,
                title: event.title as string,
                start: event.start as Date,
                end: event.end as Date,
                description: event.description as string,
                allDay: true,
                className:
                  'bg-amber-300 hover:bg-amber-100 opacity-90 text-center font-normal border-0 italic',
                textColor: '#b45309',
              };
              this.calendarEvents.push(eventEntry);
            });
          }

          this.updateEventSources();
        });
    }
  }

  onCloseModal(event: boolean) {
    if (event) {
      //console.log('modal closed', event)
      this.selectedWeightEntry = undefined;
      this.selectedSpecEntries = [];
      this.selectedEventEntries = [];
      this.isDateClick = false;
      this.multiEventSelection = false;
      this.newMultiDayCalEventTrigger = false;
      this.newMultiDaySpecEventTrigger = false;
    }

    this.loadSpecEntries();
    this.loadCalendarEntries();
    this.changeDetector.detectChanges();
  }

  newCalendarEventToggle(event: boolean) {
    this.multiEventSelection = !this.multiEventSelection;
    this.newMultiDayCalEventTrigger = !this.newMultiDayCalEventTrigger;
  }

  onSaveCalendarEvent(event: CalendarEventData) {
    if (event) {
      if (!event.id) {
        this.calendarEventService.saveNewCalendarEntryForUser(
          event,
          this.user as AppUser,
        );
      } else {
        this.calendarEventService.updateCalendarEntryForUser(
          event,
          this.user as AppUser,
        );
      }
      //this.inputEventData = event;
    }

    this.multiEventSelection = false;
    this.newMultiDayCalEventTrigger = !this.newMultiDayCalEventTrigger;
  }

  onNewMultiDayCalEvent(val: boolean) {
    if (val) {
      this.newMultiDayCalEventTrigger = !this.newMultiDayCalEventTrigger;
    }
    this.multiEventSelection = !this.multiEventSelection;
  }

  onNewMultiDaySpecEvent(val: boolean) {
    if (val) {
      this.newMultiDaySpecEventTrigger = !this.newMultiDaySpecEventTrigger;
    }

    this.multiEventSelection = !this.multiEventSelection;
  }

  onCancel(val: boolean) {
    if (!val) {
      this.newMultiDaySpecEventTrigger = !this.newMultiDaySpecEventTrigger;
    }
    this.multiEventSelection = !this.multiEventSelection;
    // console.log(val, this.newMultiDaySpecEventTrigger)
  }

  onSaveSpec(event: Specification) {
    if (event && this.coach && this.user) {
      if (!event.id) {
        this.specService.addSpecification(
          this.coach.id as string,
          this.user.id as string,
          event,
        );
        this.selectedSpecEntries?.push(event);
      } else {
        this.specService.updateSpecification(
          this.coach.id as string,
          this.user.id as string,
          event,
        );
      }
      //this.inputEventData = event;
    }

    //this.toggleSpecEntryMenu();
    this.multiEventSelection = false;
    this.newMultiDaySpecEventTrigger = !this.newMultiDaySpecEventTrigger;
  }
}
