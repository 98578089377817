import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppUser } from 'src/app/core/thecoach';
import { AuthService } from '../auth.service';
import { first, from, of, catchError, switchMap, timeout } from 'rxjs';
import { Invite } from '../../../../../backend/core/thecoach';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { RequestUnauthorized } from 'typesense/lib/Typesense/Errors';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  user: AppUser | undefined;

  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private functions: AngularFireFunctions,
  ) {}

  saveInvite(invite: Invite) {
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        this.user = appUser as AppUser;
        if (!appUser) return of(null);
        const createInvite = this.functions.httpsCallable('createInviteToken');
        return createInvite(invite).pipe(
          timeout(5000),
          first(),
          catchError((error) => {
            console.error('Error calling function:', error);
            return of(null);
          }),
        );
      }),
    );
  }

  deleteInvite(id: string) {
    return this.auth.appUser$.pipe(first()).subscribe((appuser) => {
      if (!appuser) return of(null);

      return this.db
        .collection('coaches')
        .doc(appuser.id)
        .collection('invites')
        .doc(id)
        .delete()
        .then((result) => {
          console.log('delete result', result);
        });
    });
  }
}
