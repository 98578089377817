import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  first,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { AppUser, Note, convertDateObject } from '../core/thecoach';

@Injectable({
  providedIn: 'root',
})
export class NoteService implements OnDestroy {
  user: AppUser | undefined;
  private notesSubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(
    []
  );
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private userService: UserService
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private retrieveNotes(customerId: string) {
    this.auth.appUser$
      .pipe(
        first(),
        switchMap((appUser) => {
          this.user = appUser as AppUser;
          return this.db
            .collection('notes')
            .doc(this.user.id)
            .collection<Note>(customerId)
            .valueChanges();
        }),
        takeUntil(this.unsubscribe$),
        map((notes: Note[]) => {
          return notes
            .map((note: Note) => {
              return {
                ...note,
                lastEdit: convertDateObject(note.lastEdit) as Date,
              };
            })
            .sort((a, b) => b.lastEdit.getTime() - a.lastEdit.getTime());
        })
      )

      .subscribe((notes) => {
        this.notesSubject.next(notes);
      });
  }

  getAllNotes(customer: AppUser): Observable<Note[]> {
    if (customer.id) {
      this.retrieveNotes(customer.id);
      return this.notesSubject.asObservable();
    } else {
      return of([]);
    }
  }

  getLatestNote(customer: AppUser): Observable<Note | undefined> {
    return this.getAllNotes(customer).pipe(
      map((notes) => {
        if (notes.length > 0) {
          return notes[0];
        } else {
          return undefined;
        }
      })
    );
  }

  saveNoteFromCoachToUser(customer: AppUser, note: Note) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      if (!note.id) {
        note.id = this.db.createId();
      }

      return this.db
        .collection('notes')
        .doc(this.user.id)
        .collection(customer.id!)
        .doc(note.id)
        .set(Object.assign({}, note));
    });
  }

  deleteNote(customer: AppUser, note: Note) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      if (!note.id) note.id = this.db.createId();

      return this.db
        .collection('notes')
        .doc(this.user.id)
        .collection(customer.id!)
        .doc(note.id)
        .delete();
    });
  }
}
