import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-custom-checkin-inputs-view',
  template: `
<hr class="my-4">

 <ul  *ngIf="inputArray.length>0" role="list" class="divide-y divide-gray-100">
 <li *ngFor="let input of inputArray.controls; let i = index" class="flex justify-between gap-x-6 py-5">
    <div class="flex w-full gap-x-4">

            <div class="flex w-full flex-row justify-between">
              <!-- Move Slot -->
              <div class="w-fit flex items-center text-center justify-start">
                <div class="flex mr-2">
                  {{ getParameterFromControl(input, 'slot') }}
                </div>
                <div class="flex flex-col items-center pr-2">
                  <svg (click)="moveInputSlot(true, i)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 border-2 rounded cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>

                  <svg
                    (click)="moveInputSlot(false, i)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 border-2 rounded cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>

            <!-- all other types than range -->
              <div
                *ngIf="input.get('type')!.value !== 'range'"
                class="flex items-center justify-start flex-col w-full"
              >
                <div class="w-full flex justify-between flex-row">
                  <div>
                    <span class="font-bold"
                      >Name: {{ getParameterFromControl(input, 'name') }}</span
                    >
                  </div>

                  <div>
                    <span class="font-bold">Description</span>
                  </div>
                </div>
                <div class="w-full flex justify-between flex-row text-gray-500">
                  <div>
                    <span class="text-gray-500"
                      >Type: {{ getParameterFromControl(input, 'type') }}</span>
                  </div>
                  <div class="text-gray-500">
                    {{ getParameterFromControl(input, 'description') }}
                  </div>
                </div>
              </div>
             <!-- range display -->
              <div
                *ngIf="input.get('type')!.value === 'range'"
                class="flex items-center justify-start flex-col w-full"
              >
                <div class="w-full flex justify-between flex-row">
                  <div class="">
                    <span class="font-bold"
                      >Name: {{ getParameterFromControl(input, 'name') }}</span
                    >
                  </div>
                  <div class="text-gray-500">
                    Min: {{ getParameterFromControl(input, 'min') }}, Max:
                    {{ getParameterFromControl(input, 'max') }}, Default:
                    {{ getParameterFromControl(input, 'value') }}, Tick:
                    {{ getParameterFromControl(input, 'step') }}
                  </div>
                </div>
                <div class="w-full flex justify-between flex-row text-gray-500">
                  <div>
                    <span class="font-bold">Type: </span>
                    {{ getParameterFromControl(input, 'type') }}
                  </div>
                  <div>
                    <span class="font-bold text-black">Description</span>
                  </div>
                </div>
                <div
                  class="w-full flex justify-end flex-row text-gray-500 italic"
                >
                  <div>
                    {{ getParameterFromControl(input, 'description') }}
                  </div>
                </div>
              </div>


              <!-- delete and edit buttons -->

              <div
                class="w-fit flex items-center text-center text-red-500 ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 cursor-pointer"
                  (click)="onDeleteInput(i)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </div>
              <div
                class="w-fit flex items-center text-center text-teal-500 ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 cursor-pointer"
                  (click)="onEditInput(i)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </div>
  </div>
</div>
  </li>

</ul>

<app-custom-checkin-inputs-edit
[editForm]="editFormItem"
(onSave)="saveEdit()"
(onCancel)="onCancelEdit()"
*ngIf="editToggle && editFormItem !== undefined" ><app-custom-checkin-inputs-edit>

  `,
  styles: [
  ]
})
export class CustomCheckinInputsViewComponent {
  @Input() inputArray!: FormArray;


  editToggle = false;
  editFormItem: FormGroup | undefined;
  getParameterFromControl(val: AbstractControl, parameter: string): string {
    const parameterControl = val.get(parameter);

    if (
      parameterControl !== null &&
      parameterControl.value !== undefined &&
      parameterControl.value !== null
    ) {
      return parameterControl.value.toString();
    }

    return '';
  }


  toggleEdit(){
    this.editToggle = !this.editToggle;
  }

  onCancelEdit(){

    this.editFormItem = undefined;
    this.toggleEdit();

  }

  saveEdit(){
    if(this.editFormItem){
      this.inputArray.at(this.editFormItem.get('slot')?.value).patchValue(this.editFormItem.value)
    }
    this.editFormItem = undefined;
    this.toggleEdit()
  }

  moveInputSlot(direction: boolean, index: number) {
    if (direction && index > 0) {
      this.swapElemets(this.inputArray.controls, index, index - 1);
    } else if (!direction && index < this.inputArray.controls.length - 1) {
      this.swapElemets(this.inputArray.controls, index, index + 1);
    }
    this.renumerateSlots();
  }
  renumerateSlots() {
    this.inputArray.controls.forEach((control, index) => {
      control.get('slot')?.patchValue(index);
    });
  }

  swapElemets(arr: Array<any>, position1: number, position2: number) {
    const tmp = arr[position1];
    arr[position1] = arr[position2];
    arr[position2] = tmp;
    return arr;
  }

  onDeleteInput(index: number) {
    this.inputArray.removeAt(index)
  }

  onEditInput(index: number) {
    this.editFormItem = _.cloneDeep(this.inputArray.at(index) as FormGroup);

    this.toggleEdit()
  }
}
