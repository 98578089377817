import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppUser, CustomTrackingModel } from '../core/thecoach';
import { AuthService } from './auth.service';
import { Observable, first, of, share, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomTrackingModelService {
  user: AppUser | undefined;

  constructor(private db: AngularFirestore, private auth: AuthService) {}

  addModelToDatabase(model: CustomTrackingModel) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      if (!model.id) model.id = this.db.createId();

      if (!model.ownerId) model.ownerId = this.user.id as string;

      return this.db
        .collection('customTrackingModels')
        .doc(this.user.id)
        .collection('customTrackingModels')
        .doc(model.id)
        .set(Object.assign({}, model), { merge: true });
    });
  }

  private cachedModels$: Observable<CustomTrackingModel[]> | undefined;

  getModelsForCreator() {
    if (!this.cachedModels$) {
      this.cachedModels$ = this.auth.appUser$.pipe(
        switchMap((appUser) => {
          if (!appUser) return of([]);

          return this.db
            .collection('customTrackingModels')
            .doc(appUser.id)
            .collection<CustomTrackingModel>('customTrackingModels')
            .valueChanges();
        }),
        shareReplay(1)
      );
    }
    return this.cachedModels$;
  }

  deleteModel(id: string) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      return this.db
        .collection('customTrackingModels')
        .doc(this.user.id)
        .collection('customTrackingModels')
        .doc(id)
        .delete();
    });
  }

  private cachedModel$: Observable<CustomTrackingModel> | undefined;
  private cacheModelId: string | undefined;

  getModelByID(appUser: AppUser) {
    if (!this.cachedModel$ || this.cacheModelId !== appUser.ctmId) {
      this.cacheModelId = appUser.ctmId;
      this.cachedModel$ = this.db
        .collection('customTrackingModels')
        .doc(appUser.coachId)
        .collection<CustomTrackingModel>('customTrackingModels')
        .doc(appUser.ctmId)
        .valueChanges() as Observable<CustomTrackingModel>;
    }

    return this.cachedModel$;
  }

  getModelByIdByCoach(id: string, coachId: string) {
    if (!this.cachedModel$ || this.cacheModelId !== id) {
      this.cacheModelId = id;
      this.cachedModel$ = this.db
        .collection('customTrackingModels')
        .doc(coachId)
        .collection<CustomTrackingModel>('customTrackingModels')
        .doc(id)
        .valueChanges() as Observable<CustomTrackingModel>;

      shareReplay(1);
    }

    return this.cachedModel$;
  }
}
