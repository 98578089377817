import {
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Output,
} from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

@Directive({
  selector: '[longPress]',
})
export class LongPressDirective {
  @Output() holdTime: EventEmitter<number> = new EventEmitter();

  state: Subject<string> = new Subject();

  cancel: Observable<string>;
  constructor() {
    this.cancel = this.state.pipe(
      filter((v) => v === 'cancel'),
      tap((v) => {
        //   console.log('stopped hold');
        this.holdTime.emit(0);
      })
    );
  }

  @HostListener('touchcancel', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onExit() {
    this.state.next('cancel');
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onHold() {
    //  console.log('started Hold');
    this.state.next('start');
    const n = 100;

    interval(n)
      .pipe(
        takeUntil(this.cancel),
        tap((v) => {
          this.holdTime.emit(v * n);
          //    console.log('hold time:', v * n);
        })
      )
      .subscribe();
  }
}
