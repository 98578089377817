import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from 'src/app/core/thecoach';

@Component({
  selector: 'app-flyout-msg-dashboard-menu',
  template: `
    <div
      @opacityTranslateXY
      *ngIf="isFlyoutMenu"
      class="absolute left-[6.5rem] z-10 mt-5 flex w-[14rem] max-w-max -translate-x-1/2 px-4"
    >
      <div
        class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
      >
        <div class="p-4">
          <div
            class="group relative flex justify-center rounded-lg p-2 hover:bg-gray-50 ring-2 ring-gray-600 "
            (click)="
              onDelete(); $event.preventDefault(); $event.stopPropagation()
            "
          >
            <div
              class="flex pr-1 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-red-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
            <div>
              <p class="font-semibold text-gray-900 break-normal">
                Delete Message
                <span class="absolute inset-0"></span>
              </p>
            </div>
          </div>

          <div
            *ngIf="message.message"
            class="group relative mt-2 flex justify-end rounded-lg p-2 hover:bg-gray-50 ring-2 ring-gray-600 "
            (click)="onCopy(); $event.stopPropagation()"
          >
            <div
              class="flex pr-1 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                />
              </svg>
            </div>
            <div>
              <p class="font-semibold text-gray-900">
                Copy Message
                <span class="absolute inset-0"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityTranslateXY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translate(-50%,0.25rem)' }),
        animate(
          '200ms ease-out',
          style({ opacity: 1, transform: 'translate(-50%,0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translate(-50%,0)' }),
        animate(
          '150ms ease-in',
          style({ opacity: 0, transform: 'translate(-50%,0.25rem)' })
        ),
      ]),
    ]),
  ],
})
export class FlyoutMsgDashboardMenuComponent {
  @Input() isFlyoutMenu!: boolean;
  @Input() message!: Message;
  @Output() delete = new EventEmitter<boolean>();
  @Output() copyToClipboard = new EventEmitter<boolean>();

  onDelete() {
    this.delete.emit(true);
  }

  onCopy() {
    this.copyToClipboard.emit(true);
  }
}
