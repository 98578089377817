import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first, from, of, timeout } from 'rxjs';

@Component({
  selector: 'app-tester',
  template: `{{ teststring }}`,
  styles: [``],
})
export class TesterComponent implements OnInit {
  id: string | undefined;
  teststring = 'loading';

  constructor(
    private route: ActivatedRoute,
    private functions: AngularFireFunctions,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;

    console.log(this.id);

    if (this.id && this.isValidBase64Url(this.id)) {
      const checkInvite = this.functions.httpsCallable('checkInvite');
      console.log(checkInvite);
      from(checkInvite(this.id))
        .pipe(
          timeout(5000),
          first(),
          catchError((error) => {
            console.error('Error calling function: ', error);
            this.teststring = 'FAIL';
            this.router.navigate(['/register/page-not-found']);
            return of(null);
          }),
        )
        .subscribe((result) => {
          console.log(result);

          if (result && result.validLink) {
            this.teststring = 'SUCCESS';
          } else {
            this.router.navigate(['/register/page-not-found']);

            this.teststring = 'FAIL';
          }
        });
    } else {
      console.error('NOT VALID');
      this.router.navigate(['/register/page-not-found']);
      this.teststring = 'FAIL';
    }
  }

  isValidBase64Url(str: string): boolean {
    const base64UrlPattern = /^[A-Za-z0-9-_]+$/;
    const minLength = 70; //
    const maxLength = 80;
    return (
      base64UrlPattern.test(str) &&
      str.length >= minLength &&
      str.length <= maxLength
    );
  }
}
