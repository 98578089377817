import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { ModelInputCreatorComponent } from './model-input-creator.component';
import {
  CustomSlotInputNumber,
  CustomSlotInputTarget,
  CustomSlotInputTime,
  CustomSlotModelInputType,
  CustomSlotModelInputValue,
  SlotModel,
  isModelInputType,
} from 'src/app/core/thecoach';
import { ModelService } from 'src/app/services/trainingplans/model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first, pipe } from 'rxjs';
import { DisplayDashboardCheckinNumberComponent } from '../../checkins/display-dashboard/display-dashboard-checkin-number.component';

@Component({
  selector: 'app-model-generator',
  template: `
    <div
      class="flex flex-col shadow-sm ring-1 px-5 pt-4 ring-gray-900/5 sm:rounded-xl "
    >
      <div class=" sm:px-0 flex flex-row pb-4">
        <div class="flex w-full flex-col">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Model Generator
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600 ">
            Define your own Trainingmodel.
          </p>
        </div>
        <div class="flex w-full justify-between items-center">
          <form [formGroup]="modelForm" class="w-full">
            <input
              id="name"
              type="text"
              formControlName="name"
              placeholder="Name"
              class="border border-gray-300 h-fit w-full mx-2 focus:ring-teal-500 focus:border-teal-500  rounded-md w-5/12 "
            />

            <div class="flex w-full flex-row justify-between items-center pt-2">
              <div class="flex flex-col">
                <p
                  class="block text-sm font-semibold leading-6 text-gray-900 pr-2"
                >
                  Progression Direction
                </p>

                <p class="italic font-light text-xs">
                  Progression: Up (Property Multiplication Value increases)
                  <br />
                  Example: Sets x Reps
                </p>

                <p class="italic font-light text-xs pt-2">
                  Progression: Down (Property Multiplication Value decreases)<br />
                  Example: Time x Distance
                </p>
              </div>
              <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
              <button
                id="progressionToggler"
                type="button"
                (click)="toggle()"
                [ngClass]="{
                  'bg-teal-600': isEnabled,
                  'bg-gray-200': !isEnabled
                }"
                class="bg-gray-200 relative inline-flex h-6 w-11 mr-4 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                role="switch"
                aria-checked="false"
              >
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span
                  [ngClass]="{
                    'translate-x-5': isEnabled,
                    'translate-x-0': !isEnabled
                  }"
                  class="translate-x-0 pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                >
                  <!-- Enabled: "opacity-0 duration-100 ease-out", Not Enabled: "opacity-100 duration-200 ease-in" -->
                  <span
                    [@opacity]="isEnabled ? 'active' : 'inactive'"
                    class="opacity-100 duration-200 ease-in absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-3 h-3"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <!-- Enabled: "opacity-100 duration-200 ease-in", Not Enabled: "opacity-0 duration-100 ease-out" -->
                  <span
                    [@opacity]="isEnabled ? 'inactive' : 'active'"
                    class="opacity-0 duration-100 ease-out absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-3 h-3 text-teal-500 "
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a.75.75 0 0 1 .75.75v10.638l3.96-4.158a.75.75 0 1 1 1.08 1.04l-5.25 5.5a.75.75 0 0 1-1.08 0l-5.25-5.5a.75.75 0 1 1 1.08-1.04l3.96 4.158V3.75A.75.75 0 0 1 10 3Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <app-model-input-creator
        #inputCreatorRef
        (customInput)="onAddInput($event)"
        (updateInput)="onUpdateInput($event)"
      ></app-model-input-creator>

      <div *ngIf="customInput.length > 0" class="py-4">
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          Added Properties
        </h2>
        <ul role="list" class="space-y-3">
          <li
            *ngFor="let inputType of customInput.controls; let i = index"
            class="flex flex-row  justify-between items-center overflow-hidden rounded-md bg-gray-100 px-6 py-4 shadow"
          >
            <div class="flex flex-row justify-start items-center">
              <div class="flex flex-col w-fit items-center pr-2">
                <svg
                  (click)="moveInput(i, true)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 border-2 rounded bg-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>

                <svg
                  (click)="moveInput(i, false)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 border-2 rounded bg-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
              <div class="flex flex-col">
                <p class="text-gray-800 font-semibold text-md">
                  {{ inputType.get('name')?.value }}
                </p>

                <p class="text-gray-600 font-regular text-xs">
                  {{ inputType.get('description')?.value }}
                </p>
              </div>
            </div>

            <div
              class="flex flex-row justify-center items-center space-x-2 text-center"
            >
              <div>
                <p class="text-gray-800 font-semibold text-md">
                  Type: {{ inputType.get('type')?.value }}
                </p>
                <div
                  *ngIf="isInputType(inputType.get('type')?.value)"
                  class="text-xs"
                >
                  <div class="flex flex-row">
                    <p class="italic text-gray-600">Included Calculation:</p>
                    <p
                      *ngIf="
                        inputType.get('includeCalc')?.value === true;
                        else notIncluded
                      "
                    >
                      ✅
                    </p>
                    <ng-template #notIncluded>
                      <p>❌</p>
                    </ng-template>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="rounded-md bg-teal-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                (click)="onEditInput(inputType)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </button>
              <div>
                <button
                  type="button"
                  class="rounded-md bg-red-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  (click)="onDeleteInput(i)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="flex items-center justify-end gap-x-6 mt-4 border-t border-gray-900/10 px-4 py-4 sm:px-8"
    >
      <button
        (click)="onCancelEditModel()"
        type="button"
        class="text-sm font-semibold leading-6 text-gray-900"
      >
        Cancel
      </button>
      <button
        type="button"
        [disabled]="!modelForm.valid"
        (click)="onSaveModel()"
        class="rounded-md bg-teal-600 disabled:opacity-25 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
      >
        Save
      </button>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      state('active', style({ opacity: 0 })),
      state('inactive', style({ opacity: 1 })),
      transition('* => active', [animate('100ms ease-out')]),
      transition('* => inactive', [animate('200ms ease-in')]),
    ]),
  ],
})
export class ModelGeneratorComponent {
  isEnabled = false;

  run = true;
  constructor(
    private fb: FormBuilder,
    private modelService: ModelService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const id = this.route.snapshot.params['id'];

    if (id) {
      this.modelService
        .getModelForUserById(id)
        .pipe(first())
        .subscribe((model: SlotModel) => {
          this.patchFormWithModel(model);
        });
    }
  }

  @ViewChild('inputCreatorRef')
  inputCreatorRef!: ModelInputCreatorComponent;

  modelForm = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    customInput: this.fb.array(
      [],
      [Validators.minLength(1), Validators.required],
    ),
    progressDirection: [false, Validators.required],
  });

  patchFormWithModel(model: SlotModel) {
    this.modelForm.patchValue({
      id: model.id,
      name: model.name,
      progressDirection: model.customInput.progressDirection,
    });

    if (model.customInput.progressDirection) {
      this.isEnabled = true;
    }

    // Clear any existing customInput form array
    const customInputArray = this.modelForm.get('customInput') as FormArray;
    customInputArray.clear();

    // Loop through the customInput in the model and push new form groups to the customInput form array
    model.customInput.csmiv.forEach((inputValue) => {
      customInputArray.push(this.convertModelToFormGroup(inputValue));
    });
  }

  convertModelToFormGroup(model: CustomSlotModelInputValue) {
    if (
      model.type === CustomSlotModelInputType.Number ||
      model.type === CustomSlotModelInputType.Time
    ) {
      return this.fb.group({
        name: model.name,
        description: model.description,
        type: model.type,
        value: model.value,
        slot: model.slot,
        includeCalc: model.includeCalc,
        // Add other form controls if needed based on your CustomSlotModelInputValue interface
      });
    }

    return this.fb.group({
      name: model.name,
      description: model.description,
      type: model.type,
      value: model.value,
      slot: model.slot,

      // Add other form controls if needed based on your CustomSlotModelInputValue interface
    });
  }

  get customInput() {
    return this.modelForm.get('customInput') as FormArray;
  }

  log(val: any) {
    if (this.run) {
      console.log(val);
      this.run = false;
    }
  }

  toggle() {
    this.isEnabled = !this.isEnabled;
    this.modelForm.get('progressDirection')?.patchValue(this.isEnabled);
  }

  onCancelEditModel() {
    this.router.navigate(['/model-dashboard']);
  }

  onSaveModel() {
    this.modelService
      .saveModel(this.convertFormToModel(this.modelForm))
      .then(() => {
        this.router.navigate(['/model-dashboard']);
      })
      .catch((error) => {
        console.error('Error Saving Model ', error);
      });
  }

  convertFormToModel(form: FormGroup) {
    const id = form.get('id')?.value;
    const name = form.get('name')?.value;
    const progressDirection = form.get('progressDirection')?.value;

    const customInput = this.formArrayToCustomModelInput(
      this.customInput,
      progressDirection,
    );

    const slotModel: SlotModel = {
      id: id,
      name: name,

      customInput: customInput,
    };
    return slotModel;
  }

  formArrayToCustomModelInput(
    formArray: FormArray,
    progressDirection: boolean,
  ) {
    const customInput: CustomSlotModelInputValue[] = formArray.controls.map(
      (control, index) =>
        this.inputFormGroupToSlotModelInput(control as FormGroup, index),
    );

    return {
      csmiv: customInput,
      progressDirection: progressDirection,
    };
  }

  inputFormGroupToSlotModelInput(form: FormGroup, index: number) {
    const type = form.get('type')?.value;

    switch (type) {
      case CustomSlotModelInputType.Number:
        return this.customConvertNumber(form, index);
      case CustomSlotModelInputType.Time:
        return this.customConvertTime(form, index);
      case CustomSlotModelInputType.Target:
        return this.customConvertTarget(form, index);
      //     case CustomSlotModelInputType.Text:
      //     return this.customConvertText(form, index);

      default:
        throw new Error(`Invalid Custom Slot Input ${type}`);
    }
  }

  customConvertNumber(form: FormGroup, index: number): CustomSlotInputNumber {
    return {
      name: form.get('name')?.value,
      description: form.get('description')?.value,
      includeCalc: form.get('includeCalc')?.value,
      slot: index,
      type: CustomSlotModelInputType.Number,
    };
  }

  customConvertTime(form: FormGroup, index: number): CustomSlotInputTime {
    return {
      name: form.get('name')?.value,

      slot: index,
      description: form.get('description')?.value,
      includeCalc: form.get('includeCalc')?.value,
      type: CustomSlotModelInputType.Time,
    };
  }

  customConvertTarget(form: FormGroup, index: number): CustomSlotInputTarget {
    return {
      name: form.get('name')?.value,

      slot: index,
      description: form.get('description')?.value,
      type: CustomSlotModelInputType.Target,
    };
  }
  /*
  customConvertText(form: FormGroup, index: number): CustomSlotInputText {
    return {
      name: form.get('name')?.value,
      slot: index,
      description: form.get('description')?.value,
      type: CustomSlotModelInputType.Text,
    };
  }*/
  onAddInput(addCustomInput: FormGroup) {
    this.customInput.push(_.cloneDeep(addCustomInput));

    this.customInput.controls.forEach((control, index) => {
      control.get('slot')?.patchValue(index);
    });
  }

  onUpdateInput(updatedCustomInput: FormGroup) {
    const slotVal = updatedCustomInput.get('slot')?.value;
    if (!Number.isNaN(slotVal)) {
      this.customInput.removeAt(slotVal);
      this.customInput.insert(slotVal, updatedCustomInput);
    }
  }

  moveInput(i: number, direction: boolean) {
    if (this.customInput.length > 1) {
      if (direction) {
        if (i !== 0) {
          const tmpElement = this.customInput.at(i - 1) as FormGroup;
          //Patching Slots
          this.customInput
            .at(i)
            .get('slot')
            ?.patchValue(i - 1);
          tmpElement.get('slot')?.patchValue(i);
          //Moving Elements
          this.customInput.removeAt(i - 1);
          this.customInput.insert(i, tmpElement);
        }
      } else {
        if (i !== this.customInput.length - 1) {
          const tmpElement = this.customInput.at(i + 1) as FormGroup;
          //Patching Slots
          this.customInput
            .at(i)
            .get('slot')
            ?.patchValue(i + 1);
          tmpElement.get('slot')?.patchValue(i);
          //Moving Elements
          this.customInput.removeAt(i + 1);
          this.customInput.insert(i, tmpElement);
        }
      }
    }
  }

  onDeleteInput(i: number) {
    this.customInput.removeAt(i);
  }

  onEditInput(input: AbstractControl) {
    if (input) {
      this.inputCreatorRef!.patchForm(input as FormGroup);
    }
  }

  isInputType(inputType: CustomSlotModelInputType) {
    return isModelInputType(inputType);
  }
}
