import * as _ from 'lodash';
import { TemplateTrainingDay } from './../../core/thecoach';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppUser, Note, WeightEntryData } from 'src/app/core/thecoach';
import { NoteService } from 'src/app/services/note.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-coach-dashboard-check-in-wlog-panel',
  template: `
    <ng-container *ngIf="clientWeightLog$ | async as logs; else loading">
      <ng-container *ngIf="logs.length > 2">
        <div class="flex flex-col xl:flex-row">
          <div
            class="rounded-lg  bg-white shadow-md w-full overflow-auto md:overflow-hidden  xl:mr-4 mt-4 mx-[1px] ring-1 ring-gray-300 "
          >
            <div class="m-6">
              <app-calendar-full [client]="selectedClient"></app-calendar-full>
            </div>
          </div>
          <div
            class="flex flex-col justify-center shrink overflow-none items-center rounded-lg bg-white shadow-md mt-4 mx-[1px]  w-full xl:w-fit   ring-1 ring-gray-300 h-full "
          >
            <div
              *ngIf="this.selectedClient"
              class="flex justify-center items-center w-fit flex-col pb-6"
            >
              <div class="italic text-gray-500 pt-6 font-semidbold text-xl">
                Daily Macros
              </div>
              <app-set-macros-module
                class="xl:mx-1"
                [client]="this.selectedClient"
              ></app-set-macros-module>

              <div class="italic text-gray-500 pt-4 font-semidbold text-xl">
                Off-Day Macros
              </div>
              <app-set-off-day-macros-module-coach
                [client]="this.selectedClient"
              >
              </app-set-off-day-macros-module-coach>
            </div>

            <div class="relative  w-5/6">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center">
                <span class="bg-white px-2 text-sm text-gray-500"
                  >Achieved Macros</span
                >
              </div>
            </div>
            <div
              class="px-4 py-5 w-full"
              *ngIf="checkLogsForAchievedMacros(logs)"
            >
              <app-weightlog-macro-pies-dashboard
                [logs]="logs"
              ></app-weightlog-macro-pies-dashboard>
            </div>
            <div class="relative  w-5/6">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center">
                <span class="bg-white px-2 text-sm text-gray-500">Notes</span>
              </div>
            </div>
            <div
              class="px-4 py-5 w-full max-w-md "
              *ngIf="this.latestNote$ | async as note; else noNotes"
            >
              <div
                class="rounded-lg bg-white text-center shadow-md ring-2 ring-gray-200"
              >
                <app-notes-module
                  [client]="selectedClient"
                  [note]="note"
                  (editNote)="onEditNote($event)"
                ></app-notes-module>
              </div>
            </div>
            <ng-template #noNotes>
              <div class="flex justify-center pb-8 pt-4">
                <button
                  (click)="onNewNotes()"
                  *ngIf="!showNewNoteModal"
                  class="bg-teal-500 h-fit w-fit whitespace-nowrap hover:bg-teal-700 text-white font-bold py-2 px-4 rounded"
                >
                  Create New Note
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #loading>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>

    <app-notes-modal
      *ngIf="showEditNoteModal"
      [editNote]="editNote"
      (note)="onSaveNote($event)"
      (close)="onCancel($event)"
    >
    </app-notes-modal>

    <app-notes-modal
      *ngIf="showNewNoteModal"
      (close)="onCancel($event)"
      (note)="onSaveNote($event)"
    >
    </app-notes-modal>
  `,
  styles: [],
})
export class CoachDashboardCheckInWlogPanelComponent
  implements OnInit, OnChanges
{
  @Input() selectedClient: AppUser | undefined = undefined;

  clientWeightLog$: Observable<WeightEntryData[]> | undefined;

  latestNote$: Observable<Note | undefined> | undefined;
  showNewNoteModal = false;
  showEditNoteModal = false;
  editNote: Note | undefined = undefined;

  run = true;

  constructor(
    private weightLogService: WeightlogService,
    private notesService: NoteService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  log(value: any) {
    if (this.run) {
      // console.log(value);
      this.run = false;
    }
  }
  ngOnInit(): void {
    if (this.selectedClient) {
      this.clientWeightLog$ =
        this.weightLogService.getWeightLogDataOfXDaysUpdateDate(
          this.selectedClient?.id!,
          29,
        );

      this.latestNote$ = this.notesService.getLatestNote(this.selectedClient);
    }
  }

  checkLogsForAchievedMacros(logs: WeightEntryData[]): boolean {
    let temp = logs.filter((log) => log.achivedMacros);

    if (temp.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onEditNote(note: Note) {
    //this.showNewNoteModal = !this.showNewNoteModal;
    this.editNote = note;
    this.showEditNoteModal = !this.showEditNoteModal;
  }

  onNewNotes() {
    this.showNewNoteModal = !this.showNewNoteModal;
  }

  onSaveNote(note: Note) {
    if (this.selectedClient && note) {
      this.notesService.saveNoteFromCoachToUser(this.selectedClient, note);
    }

    this.editNote = undefined;
    if (this.showEditNoteModal)
      this.showEditNoteModal = !this.showEditNoteModal;
    if (this.showNewNoteModal) this.showNewNoteModal = !this.showNewNoteModal;
  }

  onCancel(event: boolean) {
    if (!event) {
      this.editNote = undefined;
      if (this.showEditNoteModal)
        this.showEditNoteModal = !this.showEditNoteModal;
      if (this.showNewNoteModal) this.showNewNoteModal = !this.showNewNoteModal;
    }
  }
}
