import { Component, Input } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { CustomSlotInputNumber } from 'src/app/core/thecoach';

@Component({
  selector: 'app-number-slot',
  template: `
<form [formGroup]="customModelInput">
    <div class="flex flex-col justify-center w-full text-center px-2">
      <p>{{ customModelInput.get('name')?.value }}</p>
      <input
        type="text"
        name="coachValue"
        id="coachValue"
        formControlName="coachValue"
        class="block w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
        [placeholder]="customModelInput.get('name')?.value + ' Value'"
      />
      <p class="text-xs italic text-gray-400">Number Input</p>
    </div>
</form>
`,
  styles: [],
})
export class NumberSlotComponent {
  @Input({ required: true }) customModelInput!: FormGroup;
  /*
   * export interface CustomSlotInputNumber extends BaseModel {
  value?: number;
  name: string;
  description?: string;
  includeCalc: boolean;
  type: CustomSlotModelInputType.Number;
  slot: number;
}
*/
}
