import { trigger, transition, style, animate } from '@angular/animations';
import {
  TemplateTrainingSlot,
  TrainingSlotEntry,
  TemplateTrainingDay,
  convertDateObject,
  durationFromSessionMaster,
  getSessionsForTrainingDayMaster,
  getUniqueTrainingSlotsMaster,
} from './../../core/thecoach';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { intervalToDuration, isSameDay, parse } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import {
  Observable,
  Subscription,
  combineLatest,
  delay,
  filter,
  first,
  forkJoin,
  map,
  of,
  retry,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  AppUser,
  Exercise,
  MesoCycle,
  TrainingLog,
  TrainingPlan,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { LogService } from 'src/app/services/log.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-trainingplan-logview',
  template: ` <div class="py-4">
      <div>
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Client Name..."
          />
        </div>
      </div>
    </div>

    <ul role="list" class="space-y-3 ">
      <li
        *ngFor="let client of displayClients; let i = index"
        class="w-fit min-w-full bg-white px-4 py-4 shadow rounded-md px-6"
      >
        <div class="flex w-full">
          <div
            class="flex w-full justify-around"
            (click)="onOpenClient(i, client)"
          >
            <div class="flex w-full justify-start items-center">
              <div
                *ngIf="!userContentLoaded"
                class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
              >
                <ngx-skeleton-loader
                  appearance="circle"
                  [theme]="{ width: '32px', height: '32px', margin: 0 }"
                  class="absolute"
                ></ngx-skeleton-loader>
              </div>
              <img
                *ngIf="userContentLoaded"
                class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
                src="{{
                  client.imgUrl ||
                    '.././assets/images/default-person-image.webp'
                }}"
              />
              <h3 class="pl-2">{{ client.displayName }}</h3>
              <span class="italic text-sm text-gray-400 pl-2 align-bottom">{{
                client.email
              }}</span>
            </div>

            <div class="flex text-gray-500 items-center px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div *ngIf="clientToggler[i]">
          <div *ngIf="!logConentLoaded; else showData">
            <div *ngIf="!noLogsFound; else noLogsFoundMessage" class="p-4">
              <ngx-skeleton-loader
                count="3"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
            <ng-template #noLogsFoundMessage>
              <div
                class="p-4 w-full flex justify-center text-center items-center"
              >
                <p class="text-xl text-red-700 font-semibold">NO LOGS FOUND</p>
              </div>
            </ng-template>
          </div>

          <ng-template #showData>
            <div class="px-8">
              <div class="mt-8 flow-root">
                <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                  <div class="flex w-full align-middle flex-col">
                    <ul
                      role="list"
                      class="my-3 grid grid-row-1 grid-flow-col gap-5 w-fit"
                    >
                      <li
                        class="overflow-hidden rounded-md  px-2 py-2 shadow bg-gray-200 cursor-pointer"
                        *ngFor="let traininglog of userTrainingLogs"
                        (click)="
                          onLoadTrainingLog(traininglog);
                          ($event.stopPropagation)
                        "
                      >
                        <div>
                          <h1>
                            {{
                              getTrainingPlanName(traininglog.trainingPlanId!)
                            }}
                          </h1>
                        </div>
                      </li>
                    </ul>

                    <div
                      *ngIf="this.selectedTrainingLog"
                      class="w-full flex justify-between"
                    >
                      <div class="flex w-fit justify-start">
                        <div
                          *ngFor="
                            let meso of this.selectedTrainingPlan?.mesoCycles;
                            let x = index
                          "
                          class="mr-2 "
                        >
                          <div class="hidden sm:block">
                            <nav
                              class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                              aria-label="Tabs"
                            >
                              <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                              <div
                                (click)="onLoadMeso(meso)"
                                class="text-gray-900 rounded-l-lg group relative min-w-0 shadow-lg flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                              >
                                <span>{{ meso.mesoname }}</span>
                                <span
                                  class="bg-teal-500 absolute inset-x-0 bottom-0 h-0.5"
                                  [ngClass]="{
                                    'bg-teal-500':
                                      this.selectedMesoCycle?.mesoname ===
                                      meso.mesoname,
                                    'bg-transparent':
                                      this.selectedMesoCycle?.mesoname !==
                                      meso.mesoname
                                  }"
                                ></span>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="relative">
                          <button
                            class="text-gray-400 border-2 border-gray-400 bg-white hover:bg-gray-200 text-white font-bold py-2 px-1 rounded flex items-center"
                            (click)="enableViewDropdown()"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6 stroke-gray-400"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                              />
                            </svg>
                          </button>

                          <ul
                            *ngIf="viewSettings"
                            class="absolute right-0 z-10 mt-1 max-h-60 w-fit  rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            id="options"
                            role="listbox"
                          >
                            <ng-container
                              *ngIf="
                                this.selectedTrainingLog?.advancedView !==
                                  undefined;
                                else noViewSelected
                              "
                            >
                              <li
                                class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-gray-900  hover:bg-gray-200 "
                                id="option-0"
                                role="option"
                                (click)="
                                  setAdvancedView(
                                    this.selectedTrainingLog,
                                    true
                                  )
                                "
                              >
                                <!-- Selected: "font-semibold" -->
                                <span class="block truncate"
                                  >Advanced View</span
                                >

                                <span
                                  class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-teal-600"
                                  *ngIf="
                                    this.selectedTrainingLog.advancedView ===
                                    true
                                  "
                                >
                                  <!-- Heroicon name: mini/check -->
                                  <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </span>
                              </li>
                              <li
                                class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-gray-900  hover:bg-gray-200 "
                                id="option-1"
                                role="option"
                                (click)="
                                  setAdvancedView(
                                    this.selectedTrainingLog,
                                    false
                                  )
                                "
                              >
                                <!-- Selected: "font-semibold" -->
                                <span class="block truncate">Simple View</span>
                                <span
                                  class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-teal-600"
                                  *ngIf="
                                    this.selectedTrainingLog.advancedView ===
                                    false
                                  "
                                >
                                  <!-- Heroicon name: mini/check -->
                                  <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </span>
                              </li>
                            </ng-container>
                            <ng-template #noViewSelected>
                              <li
                                class="relative  select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-gray-200 cursor-pointer"
                                id="option-0"
                                role="option"
                                (click)="
                                  setAdvancedView(
                                    this.selectedTrainingLog,
                                    true
                                  )
                                "
                              >
                                <!-- Selected: "font-semibold" -->
                                <span class="block truncate"
                                  >Advanced View</span
                                >
                              </li>
                              <li
                                class="relative select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-gray-200 cursor-pointer"
                                id="option-1"
                                role="option"
                                (click)="
                                  setAdvancedView(
                                    this.selectedTrainingLog,
                                    false
                                  )
                                "
                              >
                                <!-- Selected: "font-semibold" -->
                                <span class="block truncate">Simple View</span>
                              </li>
                            </ng-template>

                            <!-- More items... -->
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="viewSettings"
                      (click)="enableViewDropdown()"
                      class="fixed inset-0"
                    ></div>

                    <div *ngIf="this.selectedMesoCycle" class="pt-4"></div>
                    <div
                      *ngFor="
                        let trainingday of this.selectedMesoCycle?.trainingDays
                      "
                      class=""
                    >
                      <h3
                        class="italic font-semibold text-gray-900 text-sm mt-2"
                      >
                        Trainingday: {{ trainingday.trainingDayName }}
                      </h3>
                      <table
                        class="divide-y divide-gray-300 w-fit table-fixed border-2  "
                      >
                        <thead>
                          <tr class="divide-x divide-gray-200">
                            <th
                              scope="col"
                              class="py-2 px-2 text-left text-sm font-semibold text-gray-900 w-[2.5rem]"
                            >
                              Slot
                            </th>
                            <th
                              scope="col"
                              class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[13rem]"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[7.25rem]"
                            >
                              Desc.
                            </th>
                            <th
                              scope="col"
                              class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                            >
                              Sets
                            </th>
                            <th
                              scope="col"
                              class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[3.5rem]"
                            >
                              Reps
                            </th>
                            <th
                              scope="col"
                              class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                            >
                              RIR
                            </th>
                            <ng-container
                              *ngIf="
                                getSessionsForTrainingDay(trainingday).length >
                                0
                              "
                            >
                              <th
                                class="py-2 px-0.5 text-center text-sm font-semibold text-gray-900 whitespace-nowrap	w-[12rem] "
                                *ngFor="
                                  let session of getSessionsForTrainingDay(
                                    trainingday
                                  )
                                "
                              >
                                <p
                                  *ngIf="
                                    session.startDate && session.endDate;
                                    else startdate
                                  "
                                >
                                  {{ session.startDate | date: 'dd-MM' }} -
                                  {{ durationFromSession(session) }}
                                </p>

                                <ng-template #startdate>
                                  {{ session.startDate | date: 'dd-MM HH:mm' }}
                                </ng-template>

                                <p
                                  *ngIf="session.sessionRating"
                                  class="italic text-xs font-normal text-gray-600"
                                >
                                  Rating: {{ session.sessionRating }}/10
                                </p>
                              </th>
                            </ng-container>
                          </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                          <tr
                            class="divide-x divide-gray-200"
                            *ngFor="
                              let trainingSlot of getUniqueTrainingSlots(
                                trainingday
                              );
                              let j = index
                            "
                          >
                            <td
                              class="whitespace-normal py-2 px-2 text-sm font-medium text-gray-900 text-center truncate"
                            >
                              {{ trainingSlot.slot }}
                            </td>
                            <td
                              class="whitespace-nowrap p-2 text-sm text-gray-500 truncate ..."
                            >
                              {{ trainingSlot.exerciseName }}
                            </td>
                            <td
                              class="p-2 text-sm text-gray-500 flex flex-row justify-between"
                            >
                              {{
                                trainingSlot.exerciseDescription!.length > 10
                                  ? (trainingSlot.exerciseDescription
                                      | slice: 0 : 8) + '...'
                                  : trainingSlot.exerciseDescription
                              }}
                              <div class=" group flex relative items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-4 h-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                                <span
                                  class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
                                  -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                                  >{{ trainingSlot.exerciseDescription }}</span
                                >
                              </div>
                            </td>
                            <td
                              class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                            >
                              {{ trainingSlot.sets }}
                            </td>
                            <td
                              class="whitespace-nowrap py-2 text-sm text-gray-500 text-center"
                            >
                              {{ trainingSlot.reps }}
                            </td>
                            <td
                              class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                            >
                              {{ trainingSlot.rir }}
                            </td>
                            <ng-container>
                              <td
                                *ngFor="
                                  let slot of getTrainingSlotSessionData(
                                    trainingday,
                                    trainingSlot
                                  );
                                  let i = index
                                "
                                class="whitespace-nowrap py-2 items-center px-1 text-sm text-gray-500 text-center"
                              >
                                <ng-container
                                  *ngIf="
                                    this.selectedTrainingLog?.advancedView &&
                                      this.selectedTrainingLog?.advancedView ===
                                        true;
                                    else simpleView
                                  "
                                >
                                  <app-training-log-slot-detail-view
                                    [slot]="slot"
                                    [trainingday]="trainingday"
                                    [i]="i"
                                    [selectedMesoCycle]="this.selectedMesoCycle"
                                    [selectedTrainingLog]="
                                      this.selectedTrainingLog
                                    "
                                  ></app-training-log-slot-detail-view>
                                </ng-container>
                                <ng-template #simpleView>
                                  <app-training-log-slot-detail-simple-view
                                    [slot]="slot"
                                    [trainingday]="trainingday"
                                    [i]="i"
                                    [selectedMesoCycle]="this.selectedMesoCycle"
                                    [selectedTrainingLog]="
                                      this.selectedTrainingLog
                                    "
                                  >
                                  </app-training-log-slot-detail-simple-view>
                                </ng-template>
                              </td>
                            </ng-container>
                          </tr>

                          <!-- More people... -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </li>

      <!-- More items... -->
    </ul>`,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class TrainingplanLogviewComponent implements OnInit, OnDestroy {
  user: AppUser = {};
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];
  clientToggler: boolean[] = [];

  clientSubscription: Subscription | undefined;
  userTrainingLogSubscription: Subscription | undefined;
  userTrainingPlanSubscription: Subscription | undefined;
  exerciseSubscription: Subscription | undefined;
  trainingLogSubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;

  userTrainingLogs: TrainingLog[] = [];
  userTrainingPlans: TrainingPlan[] = [];

  selectedTrainingLog: TrainingLog | undefined;
  selectedTrainingPlan: TrainingPlan | undefined;
  selectedMesoCycle: MesoCycle | undefined;

  exercises: Exercise[] = [];

  test: number[] = [1, 2, 3, 4, 5, 6];
  run = true;

  userContentLoaded = false;
  logConentLoaded = false;
  noLogsFound = false;

  searchNameValue = '';

  viewSettings = false;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private trainingLogService: LogService,
    private trainingPlanService: TrainingplanService,
    private exerciseService: ExerciseService,
  ) {}

  enableViewDropdown() {
    this.viewSettings = !this.viewSettings;
  }

  setAdvancedView(log: TrainingLog, value: boolean) {
    if (log.advancedView && log.advancedView === value) {
    } else {
      log.advancedView = value;
      this.trainingLogService.updateLog(log);
    }
    this.enableViewDropdown();
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }
  resetEverything() {
    this.userTrainingLogs = [];
    this.userTrainingPlans = [];
    this.selectedTrainingLog = undefined;
    this.selectedTrainingPlan = undefined;
    this.selectedMesoCycle = undefined;
    this.logConentLoaded = false;
    this.noLogsFound = false;
  }
  ngOnDestroy(): void {
    this.userTrainingLogSubscription?.unsubscribe();
    this.userTrainingPlanSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
    this.exerciseSubscription?.unsubscribe();
    this.trainingLogSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  log(val: any) {
    console.log(val);
  }

  ngOnInit(): void {
    {
      this.userSubscription = this.auth.appUser$
        .pipe(
          switchMap((user: AppUser | null) => {
            const observables: Observable<AppUser>[] = [];
            if (user) {
              this.user = user;
              this.getExercises();
              if (this.user.clientIds && this.user.clientIds.length > 0) {
                for (const clientid of user.clientIds!) {
                  this.clientIdDatabase = user.clientIds as string[];
                  //this.getClientData(this.clientIdDatabase);

                  this.userContentLoaded = true;

                  observables.push(
                    this.userService.getUserFromDatabase(
                      clientid,
                    ) as Observable<AppUser>,
                  );
                }
              }
            }
            return combineLatest(observables);
          }),
        )
        .pipe(
          map((clients: AppUser[]) => {
            return clients
              .filter((client) => client && client.coachId === this.user.id)
              .map((client) => {
                let cl = client as AppUser;
                // console.log(cl.birthdate);

                //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();
                if (cl.birthdate)
                  cl.birthdate = convertDateObject(cl.birthdate);

                return cl;
              });
          }),
        )
        .subscribe((clients) => {
          for (const client of clients) {
            if (this.clientDatabase.some((e) => e.id === client.id)) {
              let i = this.clientDatabase.indexOf(client);
              this.clientDatabase[i] = client;
            } else {
              this.clientDatabase.push(client);
              this.clientToggler.push(false);
            }
          }

          this.displayClients = this.clientDatabase;
        });
    }
    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }
      });
  }
  getUniqueTrainingSlots(td: TemplateTrainingDay): TemplateTrainingSlot[] {
    return getUniqueTrainingSlotsMaster(
      td,
      this.selectedMesoCycle!,
      this.selectedTrainingLog!,
    );
  }

  getTrainingLogData(client: AppUser) {
    if (client && client.id)
      this.userTrainingLogSubscription = this.trainingLogService
        .getTrainingLogsForUser(client.id)

        .subscribe((data) => {
          if (data.length === 0) {
            this.noLogsFound = true;
            return;
          }

          data.forEach((trainingLog) => {
            trainingLog.mesoCycle?.forEach((meso) => {
              meso.trainingDays?.forEach((td) => {
                td.startDate = convertDateObject(td.startDate as Date);
                td.endDate = convertDateObject(td.endDate as Date);
              });
            });
          });

          this.userTrainingLogs = data as TrainingLog[];
          this.userTrainingLogs.reverse();
          this.logConentLoaded = true;
        });
  }

  getTrainingPlansForUser(client: AppUser) {
    if (client && client.id)
      this.userTrainingPlanSubscription = this.trainingPlanService
        .getAllTrainingplansForClient(client)
        .subscribe((data) => {
          this.userTrainingPlans = data as TrainingPlan[];
        });
  }

  getExercises() {
    this.exerciseSubscription = this.exerciseService
      .getAllExercisesForUser()
      .subscribe((data) => {
        this.exercises = data;
      });
  }

  onOpenClient(index: number, client: AppUser) {
    //this.clientToggler[index] = !this.clientToggler[index];
    this.resetEverything();
    this.turnOffArray(this.clientToggler, index);
    this.getTrainingLogData(client);
    this.getTrainingPlansForUser(client);
  }

  turnOffArray(varArray: boolean[], index: number) {
    varArray.forEach((v, i, a) => {
      if (i === index) a[i] = !a[i];
      else a[i] = false;
    });
  }

  getTrainingPlanName(tpid: string) {
    let temptp = this.userTrainingPlans.find((plan) => plan.id === tpid);

    if (temptp?.trainingPlanName) return temptp.trainingPlanName;

    return 'Name not found!';
  }

  onLoadTrainingLog(trainingLog: TrainingLog) {
    if (this.selectedTrainingLog === trainingLog) {
      this.selectedTrainingLog = undefined;
      this.selectedTrainingPlan = undefined;
      this.selectedMesoCycle = undefined;
    } else {
      this.selectedTrainingLog = trainingLog;
      if (trainingLog && trainingLog.trainingPlanId)
        this.selectedTrainingPlan = this.getTrainingPlan(
          trainingLog.trainingPlanId,
        );
    }
  }

  getExerciseDescription(id: string) {
    const ex = this.exercises.find((e) => e.id === id);
    return ex?.exerciseDescription;
  }

  onLoadMeso(meso: MesoCycle) {
    this.selectedMesoCycle = meso;
  }

  getTrainingPlan(id: string): TrainingPlan | undefined {
    return this.userTrainingPlans.find((plan) => plan.id === id);
  }

  getSessionsForTrainingDay(td: TemplateTrainingDay) {
    return getSessionsForTrainingDayMaster(
      td,
      this.selectedMesoCycle!,
      this.selectedTrainingLog!,
    );
  }

  getTrainingSlotSessionData(
    td: TemplateTrainingDay,
    slot: TemplateTrainingSlot,
  ) {
    let index = undefined;
    let sessiondates: TemplateTrainingDay[] = [];
    let sessionslots: TemplateTrainingSlot[] = [];

    if (this.selectedMesoCycle && this.selectedMesoCycle.mesoname) {
      index = this.selectedTrainingLog?.mesoCycle?.findIndex(
        (mesoCycle) => mesoCycle.mesoname === this.selectedMesoCycle?.mesoname,
      );
    }

    if (index === -1) {
      return [];
    }

    if (index || index === 0) {
      this.selectedTrainingLog?.mesoCycle
        ?.at(index)
        ?.trainingDays?.forEach((trainingDay) => {
          if (trainingDay.trainingDayName === td.trainingDayName)
            sessiondates.push(trainingDay);
        });

      sessiondates = sessiondates.sort(
        (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
      );

      sessiondates.forEach((session) => {
        session.trainingSlots?.forEach((sessionslot) => {
          if (
            sessionslot.exerciseId === slot.exerciseId &&
            sessionslot.slot == slot.slot
          )
            sessionslots.push(sessionslot);
        });
      });
    }

    return sessionslots;
  }

  checkSlotTotalVolume(
    slot: TemplateTrainingSlot,
    td: TemplateTrainingDay,
    pos: number,
  ): number {
    let index = undefined;
    let sessionTrainingDays: TemplateTrainingDay[] = [];
    let sessionslots: TemplateTrainingSlot[] = [];

    if (this.selectedMesoCycle && this.selectedMesoCycle.mesoname) {
      index = this.selectedTrainingLog?.mesoCycle?.findIndex(
        (mesoCycle) => mesoCycle.mesoname === this.selectedMesoCycle?.mesoname,
      );
    }

    if (index === -1) return -1;
    if (index || index === 0) {
      this.selectedTrainingLog?.mesoCycle
        ?.at(index)
        ?.trainingDays?.forEach((trainingDay) => {
          if (trainingDay.trainingDayName === td.trainingDayName)
            sessionTrainingDays.push(trainingDay);
        });

      sessionTrainingDays = sessionTrainingDays.sort(
        (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
      );

      sessionTrainingDays.forEach((session) => {
        session.trainingSlots?.forEach((sessionslot) => {
          if (
            sessionslot.exerciseId === slot.exerciseId &&
            sessionslot.slot == slot.slot
          )
            // sessionslot.date = convertDateObject(sessionslot.date as Date);
            sessionslots.push(sessionslot);
        });
      });
    }

    if (pos === sessionslots.length - 2) {
      return this.compareTrainingSlotsVolume(slot, sessionslots[pos + 1]);
    } else {
      if (sessionslots[pos + 1] !== undefined)
        return this.compareTrainingSlotsVolume(slot, sessionslots[pos + 1]);

      return 2;
    }
  }

  compareTrainingSlotsVolume(
    slotA: TemplateTrainingSlot,
    slotB: TemplateTrainingSlot,
  ) {
    let volumeA: number = 0;
    let volumeB: number = 0;

    if (slotA.inputSets)
      slotA.inputSets?.forEach((inputSet) => {
        if (inputSet.reps && inputSet.weight)
          volumeA += inputSet.reps * inputSet.weight;
      });
    if (slotB.inputSets)
      slotB.inputSets?.forEach((inputSet) => {
        if (inputSet.reps && inputSet.weight)
          volumeB += inputSet.reps * inputSet.weight;
      });

    if (volumeA > volumeB) {
      return 1;
    } else if (volumeA === volumeB) {
      return 0;
    } else {
      return -1;
    }
  }

  displaySlotDetail(slot: TemplateTrainingSlot): string {
    let returnString = '';
    let weightSame = slot.inputSets?.every(
      (iS) => iS.weight || iS.weight === 0,
    );

    if (weightSame) {
      returnString = slot.inputSets![0].weight?.toString() + 'kg x ';
      let repString = '';
      slot.inputSets?.forEach((inputSet) => {
        if (!inputSet.reps) {
          repString = '-  ';
        } else {
          repString = repString + inputSet.reps + ', ';
        }
      });

      return (returnString + repString).slice(0, -2);
    }

    let tempString = '';
    slot.inputSets?.forEach((inputSet) => {
      if (
        inputSet.weight?.toString() !== undefined &&
        inputSet.weight !== undefined &&
        inputSet.weight?.toString() !== ''
      ) {
        tempString = inputSet.weight?.toString() as string;

        if (inputSet.reps !== undefined && inputSet.reps?.toString() !== '') {
          returnString =
            returnString + tempString + 'kg x ' + inputSet.reps + ', ';
        }
      }
    });

    if (returnString === '') {
      return '-';
    }

    return returnString.slice(0, -2);
  }

  displaySlotDetailNew(slot: TemplateTrainingSlot): string {
    let returnString = '';
    let weightSame = slot.inputSets?.every((iS) => iS.weight);

    if (weightSame) {
      returnString = slot.inputSets![0].weight?.toString() + 'kg x ';
      let repString = '';
      slot.inputSets?.forEach((inputSet) => {
        repString = repString + inputSet.reps + ', ';
      });

      return (returnString + repString).slice(0, -2);
    }

    slot.inputSets?.forEach((inputSet) => {
      returnString =
        returnString +
        inputSet.weight?.toString() +
        'kg x ' +
        inputSet.reps +
        ', ';
    });

    return returnString.slice(0, -2);
  }

  durationFromSession(td: TemplateTrainingDay) {
    return durationFromSessionMaster(td);
  }
}
