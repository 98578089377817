import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as firebase from 'firebase/compat/app';
import {
  Subscription,
  switchMap,
  Observable,
  combineLatest,
  map,
  Subject,
  debounceTime,
  forkJoin,
  from,
  first,
  throwError,
  filter,
  tap,
  of,
} from 'rxjs';
import {
  AppUser,
  calculateKcalFromMacros,
  convertDateObject,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { MealplanService } from 'src/app/services/mealplaner/mealplan.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import {
  DayWithMeals,
  MealPlanWithDays,
  dayMacroInfo,
  manipulateNumbersDown,
  mealPlanMacroInfo,
} from '../../../../../backend/core/thecoach';
import { catchError } from 'rxjs';
import { User } from 'firebase/auth';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';

@Component({
  selector: 'app-client-mealplans',
  template: `
    <div *ngIf="!loadingToggler; else dataloaded">
      <div class="py-4">
        <div>
          <label
            for="search"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Search by Name</label
          >
          <div class="mt-2">
            <input
              type="search"
              name="search"
              id="search"
              [(ngModel)]="searchNameValue"
              (keyup)="onKeyDownSearch($event)"
              (input)="onClearSearch()"
              class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
              placeholder="Enter Client Name..."
            />
          </div>
        </div>
      </div>

      <ul class="bg-gray-100 p-4 rounded-md">
        <li *ngFor="let client of displayClients; let i = index" class="mb-4">
          <div
            class="p-4 bg-white rounded-md shadow-md flex flex-row justify-between"
          >
            <div>
              <h2 class="text-xl font-bold mb-2">{{ client.displayName }}</h2>
              <p class="text-gray-600 mb-2">{{ client.email }}</p>
            </div>
            <!-- Display any other properties of the client as needed -->
            <div class="flex flex-row w-3/5 items-center justify-between">
              <div class="flex flex-row items-center justify-between w-full">
                <div
                  class="mb-2"
                  *ngIf="
                    selectedClientIndex !== i ||
                      selectedClientIndex === undefined;
                    else selectMealplan
                  "
                >
                  <div class="flex flex-row">
                    <p class="font-bold">
                      {{ findClientMealPlan(client.mealPlanId)?.name || '-' }}
                    </p>
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ getMealPlanInfo(findClientMealPlan(client.mealPlanId)) }}
                  </div>
                  <div
                    *ngFor="
                      let day of findClientMealPlan(client.mealPlanId)?.days
                    "
                    class="text-gray-500 italic text-xs flex flex-row"
                  >
                    <p class="font-bold pr-4">
                      {{ day.name }} - {{ getDayInfo(day) }}
                    </p>
                    <p>Meals: {{ day.meals.length }}</p>
                  </div>
                </div>
                <ng-template #selectMealplan>
                  <div class="flex rounded-md shadow-sm w-4/6">
                    <input
                      type="search"
                      (input)="onInputSearch(searchMealPlan)"
                      [(ngModel)]="searchMealPlan"
                      autocomplete="off"
                      name="name"
                      id="name"
                      class="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                      placeholder="Enter Mealplan Name"
                    />
                    <button
                      (click)="onSearchMealPlanFromDB(searchMealPlan)"
                      type="button"
                      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <div
                        *ngIf="searchMealPlanToggler; else textView"
                        class="flex flex-row items-center"
                      >
                        <object
                          type="image/svg+xml"
                          data="../../assets/icons/ring-resize.svg"
                          class="w-5 h-5"
                        ></object>
                      </div>
                      <ng-template #textView>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                          />
                        </svg>
                      </ng-template>
                    </button>
                    <div class="absolute z-10 w-4/5 md:w-2/5 lg:w-[25rem]">
                      <ul
                        *ngIf="
                          mealPlans.length > 0 && selectedClientIndex === i
                        "
                        class="absolute top-[2.5rem] text-start z-20 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <li
                          *ngFor="let mealplan of mealPlans; let j = index"
                          (click)="setMealPlan(client, mealplan)"
                          class="hover:text-white hover:cursor-pointer hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                        >
                          <div class="flex flex-row">
                            <p class="font-bold pr-2">{{ mealplan.name }}</p>
                            <p>
                              | Days: {{ mealplan.days.length }} - Meals:
                              {{ getAmountofMeals(mealplan) }}
                            </p>
                          </div>
                          <p class="pl-4 text-xs italic text-gray-500">
                            Total: {{ getMealPlanInfo(mealplan) }}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
                <div class="flex flex-row justify-end">
                  <div
                    *ngIf="
                      selectedClientIndex !== i || undefined;
                      else cancelEdit
                    "
                  >
                    <button
                      class="rounded-md bg-teal-600 text-white px-4 py-2 font-semibold"
                      (click)="selectedClientIndex = i"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                  </div>
                  <ng-template #cancelEdit>
                    <button
                      class="rounded-md bg-yellow-600 text-white px-4 py-2 font-semibold"
                      (click)="selectedClientIndex = undefined"
                    >
                      Cancel
                    </button>
                  </ng-template>
                  <button
                    *ngIf="client.mealPlanId"
                    class="rounded-md bg-red-600 text-white px-4 py-2 font-semibold ml-4"
                    (click)="onDeleteMealplanFromUser(client)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <ng-template #dataloaded>
      <ngx-skeleton-loader
        count="5"
        animation="progress"
        [theme]="{ height: '50px' }"
      ></ngx-skeleton-loader>
    </ng-template>
  `,
  styles: [],
})
export class ClientMealplansComponent implements OnInit, OnDestroy {
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];
  searchNameValue = '';
  userSubscription: Subscription | undefined;
  searchMealPlanToggler = false;
  user: AppUser | undefined;
  selectedClientIndex: number | undefined;
  loadingToggler = false;
  mealPlans: MealPlanWithDays[] = [];
  clientMealPlans: MealPlanWithDays[] = [];
  searchMealPlan: string | undefined = undefined;
  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)
  constructor(
    private userService: UserService,
    private auth: AuthService,
    private http: HttpClient,
    private httpUser: HttpUserService,
  ) {
    this.toggleLoader();
    this.initMealPlans();

    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchObj) => {
        this.performSearch(searchObj);
      });
  }
  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  onInputSearch(searchTerm: string | undefined): void {
    if (searchTerm) {
      this.searchSubject.next(searchTerm);
    }
  }

  performSearch(searchString: string): void {
    if (searchString) {
      this.toggleMealPlanSearchIcon();
      this.searchMealPlanFromDB(searchString);
    } else {
      this.toggleMealPlanSearchIcon();
    }
  }

  getAmountofMeals(mealplan: MealPlanWithDays) {
    let meals = 0;
    mealplan.days.forEach((day) => {
      meals += day.meals.length;
    });

    return meals;
  }

  onSearchMealPlanFromDB(searchTerm: string | undefined) {
    if (searchTerm) {
      this.toggleMealPlanSearchIcon();
      this.searchMealPlanFromDB(searchTerm);
    }
  }
  toggleMealPlanSearchIcon() {
    this.searchMealPlanToggler = !this.searchMealPlanToggler;
  }
  searchMealPlanFromDB(searchString: string) {
    this.mealPlans = [];
    const searchTerm = searchString;
    console.log('Searching for: ', searchString);
    if (searchTerm) {
      this.httpUser.currentUser$.pipe(first()).subscribe((user) => {
        if (user) {
          return user
            .getIdToken(true)
            .then((idToken) => {
              const headers = new HttpHeaders({
                Authorization: 'Bearer ' + idToken,
              });
              this.http
                .get<
                  MealPlanWithDays[]
                >(environment.apiUrl + '/mealplans/filtered-mealplans/' + searchTerm, { headers })
                .subscribe(
                  (mealplans) => {
                    if (mealplans) {
                      mealplans.map((mealplan) => {
                        mealplan.days.map((day) => {
                          day.meals.map((meal) => {
                            meal.addedIngredients.map((addedIng) => {
                              addedIng.ingredient = manipulateNumbersDown(
                                addedIng.ingredient,
                              );
                            });
                          });
                        });
                      });

                      this.mealPlans = mealplans;
                    } else {
                      this.mealPlans = [];
                    }
                    this.toggleMealPlanSearchIcon();
                  },
                  (error) => {
                    this.toggleMealPlanSearchIcon();
                    console.error('Error', error);
                  },
                );
            })
            .catch((error) => {
              this.toggleMealPlanSearchIcon();
              console.error('Error', error);
            });
        } else {
          this.toggleMealPlanSearchIcon();
          console.error('No User signed in.');
          return null;
        }
      });
    } else {
      this.toggleMealPlanSearchIcon();
      return null;
    }
    return null;
  }

  toggleLoader() {
    this.loadingToggler = !this.loadingToggler;
  }

  findClientMealPlan(mealPlanId: string | undefined) {
    if (!mealPlanId) return null;

    if (this.clientMealPlans) {
      const clientMealPlan = this.clientMealPlans.find(
        (mealPlan) => mealPlan.id === mealPlanId,
      );
      if (clientMealPlan) {
        return clientMealPlan;
      } else {
        {
          return null;
        }
      }
    }
    return null;
  }

  findMealPlanById(
    mealPlanId: string | undefined,
  ): Observable<MealPlanWithDays | null> {
    if (mealPlanId) {
      return this.httpUser.currentUser$.pipe(
        first(),
        switchMap((user) => {
          if (!user) {
            return throwError('User is not authenticated');
          }

          return from(user.getIdToken(true)).pipe(
            switchMap((idToken) => {
              const headers = new HttpHeaders({
                Authorization: 'Bearer ' + idToken,
              });
              return this.http.get<MealPlanWithDays>(
                `${environment.apiUrl}/mealplans/${mealPlanId}`,
                { headers },
              );
            }),
            map((mealplan) => {
              if (mealplan) {
                mealplan.days.forEach((day) => {
                  day.meals.forEach((meal) => {
                    meal.addedIngredients.forEach((addedIng) => {
                      addedIng.ingredient = manipulateNumbersDown(
                        addedIng.ingredient,
                      );
                    });
                  });
                });
                return mealplan;
              } else {
                return null;
              }
            }),
            catchError((error) => {
              console.error('Error', error);
              return throwError('Failed to fetch meal plan');
            }),
          );
        }),
      );
    }
    // Return an observable representing the case where mealPlanId is undefined
    return throwError('Meal plan ID is undefined');
  }

  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$
      .pipe(
        switchMap((user: AppUser | null) => {
          const observables: Observable<AppUser>[] = [];
          if (user) {
            this.user = user;
            if (this.user.clientIds && this.user.clientIds.length > 0) {
              for (const clientid of user.clientIds!) {
                this.clientIdDatabase = user.clientIds as string[];

                observables.push(
                  this.userService.getUserFromDatabase(
                    clientid,
                  ) as Observable<AppUser>,
                );
              }
            }
          }
          return combineLatest(observables);
        }),
      )
      .subscribe((clients) => {
        const mealPlanObservables: Observable<MealPlanWithDays | null>[] = [];

        for (const client of clients) {
          //this.getWeightLogData(client, 30);

          if (this.clientDatabase.some((e) => e.id === client.id)) {
            let i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }

          if (client.mealPlanId) {
            mealPlanObservables.push(this.findMealPlanById(client.mealPlanId));
          }
        }
        this.displayClients = this.clientDatabase;

        forkJoin(mealPlanObservables).subscribe(
          (mealPlansFork) => {
            this.clientMealPlans = mealPlansFork.filter(
              (mp) => mp !== null,
            ) as MealPlanWithDays[];
          },
          (error) => {
            console.error('Error in Frokjoin', error);
          },
        );
      });

    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }
  /*
  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
          if (client.mealPlanId) {
            this.findMealPlanById(client.mealPlanId)
              .pipe(first())
              .subscribe((mp) => {
                if (mp) {
                  this.clientMealPlans.push(mp);
                }
              });
          }
        }
      });
  }
*/

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(
        first(),
        filter((client): client is AppUser => !!client),
        tap((client: AppUser) => {
          const existingIndex = this.clientDatabase.findIndex(
            (e) => e.id === client.id,
          );
          if (existingIndex !== -1) {
            this.clientDatabase[existingIndex] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }),
        switchMap((client) => {
          if (client.mealPlanId) {
            return this.findMealPlanById(client.mealPlanId).pipe(
              first(),
              filter((mp): mp is MealPlanWithDays => !!mp),
              tap((mp) => this.clientMealPlans.push(mp as MealPlanWithDays)),
            );
          } else {
            return of(null); // return an observable to continue the chain
          }
        }),
      )
      .subscribe();
  }
  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  initMealPlans() {
    this.mealPlans = [];
    this.httpUser.currentUser$.pipe(first()).subscribe((user) => {
      if (user) {
        return user
          .getIdToken(true)
          .then((idToken) => {
            const headers = new HttpHeaders({
              Authorization: 'Bearer ' + idToken,
            });

            this.http
              .get<{
                mealPlanCount: number;
                mealPlans: MealPlanWithDays[];
              }>(`${environment.apiUrl}/mealplans?skip=${10}`, { headers })
              .subscribe((transactionalObject) => {
                transactionalObject.mealPlans.map((mealplan) => {
                  mealplan.days.map((day) => {
                    day.meals.map((meal) => {
                      meal.addedIngredients.map((addedIng) => {
                        addedIng.ingredient = manipulateNumbersDown(
                          addedIng.ingredient,
                        );
                      });
                    });
                  });
                });

                this.mealPlans = transactionalObject.mealPlans;
                this.toggleLoader();
              });
          })
          .catch((error) => {
            // Handle error
            console.error('Error getting ID token:', error);
            this.toggleLoader();
            throw error;
          });
      } else {
        // Handle the case when no user is signed in
        console.error('No user signed in.');
        this.toggleLoader();
        return null;
      }
    });
  }

  setMealPlan(user: AppUser, mealplan: MealPlanWithDays) {
    user.mealPlanId = mealplan.id;
    this.clientMealPlans.push(mealplan);

    this.userService.saveUserData(user).then(() => {
      this.selectedClientIndex = undefined;
      this.searchMealPlan = undefined;
    });
  }

  getDayInfo(day: DayWithMeals) {
    return dayMacroInfo(day);
  }

  getMealPlanInfo(mealplan: MealPlanWithDays | null) {
    if (mealplan) {
      return mealPlanMacroInfo(mealplan);
    } else {
      return '';
    }
  }

  onDeleteMealplanFromUser(user: AppUser) {
    if (user.mealPlanId) {
      delete user.mealPlanId;
      this.userService.overWriteUser(user).then(() => {
        this.selectedClientIndex = undefined;
        this.searchMealPlan = undefined;
      });
    }
  }
}
