import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUser } from 'src/app/core/thecoach';
import { InviteService } from 'src/app/services/inviteSystem/invite.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';
import { UserConfig } from '../../../../../../backend/core/thecoach';

@Component({
  selector: 'app-client-pending-panel',
  template: `
    <div class="flex justify-center items-center flex-col w-full">
      <h1 class="text-xl font-bold uppercase">Pending Clients</h1>
      <hr class="pb-2 w-full" />
      <ul
        class="flex flex-wrap w-full gap-y-2 gap-x-2"
        *ngIf="this.pendingClients && this.pendingClients.size > 0"
      >
        <div
          *ngFor="let pendingClient of pendingClients | keyvalue; let j = index"
          class="flex-1"
        >
          <li
            class=" w-fit  h-fit bg-white shadow rounded-md p-2 border border-black"
          >
            <div class="relative">
              <div class="flex flex-col ">
                <h3>
                  {{ pendingClient.value.displayName }}
                </h3>
                <div class="text-gray-400 text-xs italic whitespace-nowrap">
                  <p>E-Mail: {{ pendingClient.value.id }}</p>
                  <p>E-Mail: {{ pendingClient.value.email }}</p>
                </div>
                <div
                  class="flex w-full"
                  [ngClass]="{
                    'justify-between': !this.user.coachSpotId,
                    'justify-center': this.user.coachSpotId,
                  }"
                >
                  <button
                    type="button"
                    (click)="addClientToCoach(pendingClient.value)"
                    [disabled]="checkSpotQuantity()"
                    class="mt-2 inline-flex disabled:opacity-25 items-center w-fit rounded-md bg-teal-600 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <svg
                      class="-ll-0.5 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                      />
                    </svg>
                    Client
                  </button>
                  <button
                    *ngIf="!this.user.coachSpotId"
                    (click)="addClientToCoachSpot(pendingClient.value)"
                    type="button"
                    class="mt-2 inline-flex disabled:opacity-25 items-center w-fit rounded-md bg-teal-600 px-1.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <svg
                      class="-ll-0.5 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                      />
                    </svg>
                    Coach Spot
                  </button>
                </div>
                <div *ngIf="checkSpotQuantity()">
                  <div class="flex justify-center flex-col w-full text-center">
                    <p class="italic text-red-400 font-semibold text-xs">
                      Not enough Spots
                    </p>
                    <p class="italic text-red-400 text-xs">
                      Please buy spots or disable client
                    </p>
                  </div>
                </div>
              </div>
              <div
                (click)="onDeleteModal(pendingClient.value)"
                class="absolute -top-2 -right-2 text-red-400 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
    <app-simple-modal
      *ngIf="toggleDeletePending && selectedClient"
      (close)="onCloseDeletePendingModal($event)"
      [title]="deleteTitle"
      [message]="'Pending Client will be removed.'"
      [negativeButtonText]="'No'"
      [positiveButtonText]="'Yes'"
    >
    </app-simple-modal>
  `,
  styles: [],
})
export class ClientPendingPanelComponent implements OnInit, OnDestroy {
  @Input({ required: true }) user!: AppUser;
  @Input({ required: true }) userConfig!: UserConfig | undefined;
  @Input({ required: true }) activeUsers!: Map<string, AppUser> | undefined;
  //needs rework - coach spot enable / disable with quantity check
  constructor(private userService: UserService) {}
  pendingClients = new Map();
  pendingClientSubscription$: Subscription | undefined = undefined;
  toggleDeletePending = false;
  selectedClient: AppUser | undefined = undefined;
  deleteTitle = '';
  ngOnInit(): void {
    this.getPendingClientsData();
  }
  getPendingClientsData() {
    if (this.user) {
      this.pendingClientSubscription$ = this.userService
        .getPendingClientsFromDatabase(this.user)
        .subscribe((clients) => {
          if (!clients) return undefined;
          this.pendingClients.clear();
          //console.log(clients, this.pendingClients);
          return (clients as AppUser[]).forEach((client: AppUser) => {
            this.pendingClients.set(client.id, client);
            // console.log(this.pendingClients);
          });
        });
    }
  }

  addClientToCoach(client: AppUser) {
    client.pending = false;
    if (!this.user.clientIds) {
      this.user.clientIds = [];
    }

    this.user.clientIds.push(client.id as string);
    this.updateBothUsers(client, this.user);
  }

  addClientToCoachSpot(client: AppUser) {
    client.pending = false;
    this.user.coachSpotId = client.id;

    this.updateBothUsers(client, this.user);
  }

  async updateBothUsers(client: AppUser, coach: AppUser) {
    try {
      await this.userService.saveUserData(client);
      await this.userService.saveUserData(coach);
      //console.log('Both users updates succesfully!');
    } catch (error) {
      console.error('Error updateing... ', error);
    }
  }
  onDeleteModal(client: AppUser) {
    this.selectedClient = client;
    this.updateDeleteTitle(client);
    this.toggleDeletePending = !this.toggleDeletePending;
  }

  onDeletePendingClient(pendingClient: AppUser) {
    //console.log('pendingclient', pendingClient);
    if (pendingClient && pendingClient.id) {
      pendingClient.coachId = 'NONE';
      pendingClient.pending = false;
      //console.log('running delete...', pendingClient);
      this.userService.saveUserData(pendingClient).then(() => {
        this.getPendingClientsData();
      });
    }
  }

  onCloseDeletePendingModal(event: boolean) {
    if (event && this.selectedClient) {
      //console.log('deleting ', this.selectedClient);
      this.onDeletePendingClient(this.selectedClient);
    }
    this.toggleDeletePending = !this.toggleDeletePending;
    this.selectedClient = undefined;
  }

  updateDeleteTitle(client: AppUser) {
    if (client) {
      this.deleteTitle = `Delete ${client.displayName}?`;
    } else {
      this.deleteTitle = '';
    }
  }

  checkSpotQuantity() {
    if (this.activeUsers && this.userConfig) {
      return this.userConfig.quantity <= this.activeUsers.size;
    }
    return false;
  }
  ngOnDestroy(): void {
    this.pendingClientSubscription$?.unsubscribe();
  }
}
