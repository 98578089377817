import { Component, Input } from '@angular/core';
import {
  CustomInputType,
  CustomTrackingModel,
  AppUser,
} from 'src/app/core/thecoach';

@Component({
  selector: '[app-statistics-wlog-tableheader]',
  template: `
    <ng-container class="text-gray-900 ">
      <tr class="divide-x divide-gray-200">
        <th
          scope="col"
          class=" pr-1 text-center text-xs font-semibold   sm:pl-0 "
        >
          Date
        </th>
        <th scope="col" class="px-1 text-center text-xs font-semibold">
          <div class="h-full flex items-center justify-center">
            <img
              class="w-6 h-6"
              src="../../assets/icons/body-weight-scales-icon.png"
            />
          </div>
        </th>
        <th
          scope="col"
          class="pt-3.5 px-1 text-center text-xs font-semibold  whitespace-nowrap"
        >
          Ø7-Day
        </th>
        <th
          scope="col"
          colspan="2"
          class="pt-3.5  text-center text-xs font-semibold  "
        >
          ROG/L (daily)
        </th>

        <th
          scope="col"
          colspan="2"
          class="pt-3.5 text-center text-xs font-semibold  "
        >
          ROG/L (7-Day)
        </th>
        <th
          *ngIf="client.enableTraining"
          scope="col"
          class="pt-3.5 text-center text-xs font-semibold  px-0.5"
        >
          Session
        </th>
      </tr>
    </ng-container>
  `,
  styles: [],
})
export class StatisticsWlogTableheaderComponent {
  @Input() client!: AppUser;
}
