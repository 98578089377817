import { Component, Input } from '@angular/core';
import { first } from 'rxjs';
import { AppUser } from 'src/app/core/thecoach';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-coaching-spot-panel',
  template: `
    <div class="flex justif-center flex-col items-center">
      <h1 class="text-xl font-bold uppercase">Your Choaching Spot</h1>
      <hr class="pb-2 w-full" />
      <div *ngIf="user.coachSpotId; else addCoachingSpotClient">
        <app-client-panel
          [user]="user"
          [clientId]="user.coachSpotId"
          [isCoach]="true"
        />
      </div>
      <ng-template #addCoachingSpotClient
        ><div class="flex flex-col justify-center w-full text-center">
          <p class="italic text-xs text-gray-600">
            You can add yourself as a Client.
          </p>

          <p class="italic text-xs text-gray-600">
            Please use a diffrent E-Mail than {{ user.email }}
          </p>
        </div>
      </ng-template>
    </div>
  `,
  styles: [],
})
export class CoachingSpotPanelComponent {
  @Input({ required: true }) user!: AppUser;

  constructor(private userService: UserService) {}
}
