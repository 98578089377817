import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { WeightlogService } from 'src/app/services/weightlog.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AppUser,
  CustomInputType,
  CustomTrackingModel,
  CustomTrackingValue,
  Macros,
  Specification,
  WeightEntryData,
  calculateKcalFromMacros,
  convertFormToModel,
} from 'src/app/core/thecoach';
import { Observable, Subscription, first, of, switchMap } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import {
  format,
  isSameDay,
  isThisWeek,
  isToday,
  isWithinInterval,
  startOfToday,
} from 'date-fns';
import { ChartItem, ChartType } from 'chart.js';
import { Chart } from 'chart.js/auto';
import { animate, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { he, te } from 'date-fns/locale';
import { FormArray, FormGroup } from '@angular/forms';
import { SpecificationsService } from 'src/app/services/specifications.service';

@Component({
  selector: 'app-customer-dashboard',
  template: `
    <div *ngIf="auth.appUser$ | async as user">
      <div *ngIf="user.enableWeightLog; else noWeightLogActive">
        <div *ngIf="specsForUser$ | async as specs">
          <div *ngIf="show">
            <div
              *ngIf="user.checkInWeekdayIndex || user.checkInWeekdayIndex === 0"
              class="flex justify-center w-full text-md text-gray-400  rounded-lg bg-white px-2 py-2 shadow"
            >
              <p>{{ calculcateDaystoCheckin(user.checkInWeekdayIndex) }}</p>
            </div>
            <div>
              <ul class="space-y-2 pt-2">
                <li *ngFor="let spec of specs">
                  <app-customer-spec-view
                    [specification]="spec"
                  ></app-customer-spec-view>
                </li>
              </ul>
            </div>

            <div
              *ngIf="
                user.enableMacroTracking &&
                  user.setMacros &&
                  (checkForSpecMacros(specs) || user.mealPlanId);
                else normalDisplay
              "
              class="my-2"
              [ngClass]="{
                'opacity-30 bg-gray-300 rounded-md p-2':
                  checkForSpecMacros(specs) || user.mealPlanId
              }"
            >
              <div
                class="cursor-pointer flex w-full justify-between flex-row items-center"
                (click)="toggleCollapseDaily()"
              >
                <h3 class="font-semibold text-gray-900">Daily Macros</h3>
                <div *ngIf="!isCollapsedDaily; else expanded">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                </div>
                <ng-template #expanded>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  </div>
                </ng-template>
              </div>
              <div
                @AnimationTrigger0
                *ngIf="isCollapsedDaily && user.enableMacroTracking"
              >
                <app-display-macros-module
                  [title]="''"
                  [macros]="user.setMacros"
                ></app-display-macros-module>
              </div>
            </div>
          </div>
          <ng-template #normalDisplay>
            <app-display-macros-module
              *ngIf="user.enableMacroTracking && user.setMacros"
              [title]="'Daily Macros'"
              [macros]="user.setMacros"
            ></app-display-macros-module>
          </ng-template>

          <div *ngIf="user.enableMealPlans && user.mealPlanId">
            <app-mealplan-dashboard-view
              [mealplanId]="user.mealPlanId"
            ></app-mealplan-dashboard-view>
          </div>

          <div
            *ngIf="
              user.enableMacroTracking &&
                user.enableOffDayMacros &&
                user.offDayMacros &&
                checkForSpecMacros(specs);
              else normalOffDay
            "
            class="mb-2"
            [ngClass]="{
              'opacity-30 bg-gray-300 rounded-md p-2': checkForSpecMacros(specs)
            }"
          >
            <div
              class="cursor-pointer flex w-full justify-between flex-row items-center"
              (click)="toggleCollapseOffDay()"
            >
              <h3 class="font-semibold text-gray-900">Off-Day Macros</h3>
              <div *ngIf="!isCollapsedOffDay; else expanded">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>
              <ng-template #expanded>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </div>
              </ng-template>
            </div>
            <div
              @AnimationTrigger0
              *ngIf="isCollapsedOffDay && user.enableMacroTracking"
            >
              <app-display-macros-module
                [title]="''"
                [macros]="user.offDayMacros"
              ></app-display-macros-module>
            </div>
          </div>
          <ng-template #normalOffDay>
            <app-display-macros-module
              *ngIf="
                user.enableMacroTracking &&
                user.enableOffDayMacros &&
                user.offDayMacros
              "
              [title]="'Off-Day Macros'"
              [macros]="user.offDayMacros"
            ></app-display-macros-module>
          </ng-template>
          <div *ngFor="let wlog of selectedDayWeightEntry">
            <div *ngIf="checkForMacros(wlog, user)">
              <div *ngIf="achievedmacrosToggler">
                <h3
                  class="text-base font-semibold leading-6 text-gray-900 text-center pt-2"
                >
                  Achieved Macros
                </h3>
                <app-macrographs
                  [specs]="specs"
                  [user]="user"
                  [weightEntry]="wlog"
                ></app-macrographs>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-2" *ngIf="show" />

        <div
          class="mt-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
        >
          <div class="flex justify-center w-full px-2 py-2 ">
            <!-- Content goes here -->
            <!-- We use less vertical padding on card headers on desktop than on body sections -->
            <div class="flex w-full justify-evenly items-center text-center">
              <h3
                class="text-base font-semibold leading-6 text-gray-900 text-center"
              >
                Weightlog Summary
              </h3>
            </div>
          </div>
          <div class="px-1 py-1 ">
            <!-- Content goes here -->
            <div>
              <canvas id="wlChart">{{ weightLogChart }}</canvas>
            </div>
          </div>
          <div class="flex justify-end px-2 py-2">
            <p *ngIf="averageWeight > 0" class="text-sm italic text-gray-400">
              Average weight last 14 Days: {{ averageWeight }}
            </p>

            <!-- Content goes here -->
            <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
          </div>
        </div>
        <hr class="my-2" />
        <div
          class="flex flex-row w-full items-center text-center justify-around"
        >
          <button
            type="button"
            class="w-fit  rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            *ngIf="!checkDates()"
            (click)="onQuickAddWeight()"
          >
            <img
              class="w-6 h-6 invert"
              src="../../assets/icons/body-weight-scales-icon.png"
            />
          </button>

          <button
            *ngIf="checkDates()"
            (click)="onQuickEditWeight()"
            type="button"
            class="w-fit  rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            <img
              class="w-6 h-6 invert"
              src="../../assets/icons/maintain-weight-icon.png"
            />
          </button>

          <button
            *ngIf="
              user.enableMacroTracking &&
              checkDates() &&
              !checkForAchievedMacros()
            "
            (click)="onAddMacros()"
            type="button"
            class="w-fit  rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            <img
              class="w-6 h-6 invert"
              src="../../assets/icons/macros-icon-V4.png"
            />
          </button>

          <button
            *ngIf="
              user.enableMacroTracking &&
              checkDates() &&
              checkForAchievedMacros()
            "
            (click)="onAddMacros()"
            type="button"
            class="w-fit  rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            <img
              class="w-6 h-6 invert"
              src="../../assets/icons/macros-icon-V4-checked.png"
            />
          </button>

          <button
            *ngIf="
              user.enableCTMs &&
              user.ctmId &&
              (this.todayWeightEntry | json) != '{}' &&
              this.todayWeightEntry != undefined &&
              this.todayWeightEntry &&
              !this.todayWeightEntry.customTrackingLog
            "
            (click)="onToggleCustomAddModal()"
            type="button"
            class="w-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            <img
              class="w-6 h-6 invert"
              src="../../assets/icons/3d-modeling-icon.png"
            />
          </button>
          <button
            *ngIf="
              user.enableCTMs &&
              user.ctmId &&
              (this.todayWeightEntry | json) != '{}' &&
              this.todayWeightEntry?.customTrackingLog
            "
            (click)="onToggleCustomAddModal()"
            type="button"
            class="w-fit rounded-md bg-teal-600 py-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            <img
              class="w-6 h-6 invert"
              src="../../assets/icons/3d-modeling-icon-checked.png"
            />
          </button>
        </div>

        <div
          *ngIf="quickWeightEntryToggler"
          id="modal-background"
          @AnimationTrigger0
          class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
        >
          <app-weight-entry
            (onSaveData)="onSaveDataToServer($event)"
            (onClick)="onClickEvent($event)"
            @AnimationTrigger1
            *ngIf="quickWeightEntryToggler"
            class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg -top-36"
            [day]="this.today"
            [weightEntry]="this.todayWeightEntry"
            [lastWeightEntry]="lastWeightEntry"
          ></app-weight-entry>
        </div>

        <div
          *ngIf="macrosModalToggle"
          id="modal-background"
          @AnimationTrigger0
          class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
        >
          <app-macro-entry-modal
            class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
            (onSaveData)="onSaveMacrosToServer($event)"
            (onClick)="onCancelEvent($event)"
            [day]="this.today"
            [weightEntry]="this.todayWeightEntry"
            [lastweightEntry]="this.lastWeightEntry"
            [todaysSpec]="this.todaysSpec"
          >
          </app-macro-entry-modal>
        </div>

        <div
          *ngIf="
            user.enableCTMs &&
              user.ctmId &&
              this.todayWeightEntry &&
              !this.todayWeightEntry.customTrackingLog &&
              (this.ctmService.getModelByID(user)
                | async) as customTrackingModel;
            else editModel
          "
        >
          <app-custom-model-modal
            *ngIf="customModalToggle"
            [ctm]="customTrackingModel"
            (close)="onCloseTrackingModal($event.event, $event.form, user)"
          >
          </app-custom-model-modal>
        </div>

        <ng-template #editModel>
          <app-custom-model-modal
            *ngIf="customModalToggle"
            [ctm]="this.todayWeightEntry?.customTrackingLog"
            (close)="onCloseTrackingModal($event.event, $event.form, user)"
          >
          </app-custom-model-modal>
        </ng-template>
      </div>
      <ng-template #noWeightLogActive>
        <div class="w-full text-center justify-center">
          <p class="italic text-md font-semibold">
            Please wait for Coach to activate your features!
          </p>
        </div>
      </ng-template>
    </div>
  `,
  styles: [],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
  ],
})
export class CustomerDashboardComponent implements OnInit, OnDestroy {
  today = startOfToday();
  weightLogChart: ChartItem | undefined;
  lineChartType: ChartType = 'line';
  weightLogSubscription: Subscription | undefined;
  weightLogDatabase: WeightEntryData[] = [];
  averageWeight: number = 0;
  quickWeightEntryToggler: boolean = false;
  todayWeightEntry: WeightEntryData | undefined = {};
  lastWeightEntry: WeightEntryData | undefined = undefined;
  selectedDayWeightEntry: WeightEntryData[] = [];
  achievedmacrosToggler: boolean = false;
  loadWlGraph: boolean = false;
  todayDiffMacroLog: WeightEntryData | undefined = {};
  show = false;
  customModalToggle = false;
  macrosModalToggle = false;

  weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  run = true;
  isCollapsedDaily = false;
  isCollapsedOffDay = false;
  todaysSpec: Specification | undefined;

  //customTrackingModel$: Observable<CustomTrackingModel | undefined>;

  specsForUser$: Observable<Specification[]> = this.auth.appUser$
    .pipe(first())
    .pipe(
      switchMap((user) => {
        if (user) {
          const specs = this.specService.getSpecsForUserForDate(
            user.coachId as string,
            user.id as string,
            this.today,
          );

          specs.pipe(first()).subscribe((val) => {
            if (val && val.length > 0) {
              this.todaysSpec = val.shift();
            }
          });

          return specs;
        } else {
          return of([]);
        }
      }),
    );

  constructor(
    protected auth: AuthService,
    private weightLogService: WeightlogService,
    protected ctmService: CustomTrackingModelService,
    private specService: SpecificationsService,
  ) {}
  ngOnDestroy(): void {
    this.weightLogSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.loadWeightLogDataforXDays();
  }

  toggleCollapseDaily() {
    this.isCollapsedDaily = !this.isCollapsedDaily;
  }

  toggleCollapseOffDay() {
    this.isCollapsedOffDay = !this.isCollapsedOffDay;
  }

  onAddMacros() {
    this.macrosModalToggle = !this.macrosModalToggle;
  }

  calculcateDaystoCheckin(userCheckinWeekdayIndex: number): string {
    const indexForTodayString = this.weekdays.findIndex(
      (day) => day === format(this.today, 'EEE'),
    );

    let daysToCheckin = userCheckinWeekdayIndex - indexForTodayString;

    if (daysToCheckin === 0) {
      return 'Today is your Checkin!';
    } else if (daysToCheckin === 1) {
      return 'Tomorrow is your Checkin!';
    } else if (daysToCheckin < 0) {
      daysToCheckin = daysToCheckin + 7;
    }

    return daysToCheckin.toString() + ' Days to Checkin!';
  }

  loadWeightLogDataforXDays() {
    this.weightLogSubscription = this.weightLogService
      .getWeightLogForClientOfXDaysNoFuture(14)
      .subscribe((data: WeightEntryData[]) => {
        data.forEach((item) => {
          if (item.date) {
            item.date = (item.date as unknown as Timestamp).toDate();
          }
        });

        this.weightLogDatabase = data.reverse();
        if (data.length > 0) {
          this.show = true;

          this.checkAchievedMacros();

          this.checkIfDiffrentMacrosToday();
        }
        this.lastWeightEntry = data[data.length - 1];
        this.createWeightLogGraph();
      });
  }

  getDiffrentMacrosFromLog() {
    return this.weightLogDatabase.find((item) => {
      if (isSameDay(item.date!, new Date()) && item.refeedMacros) {
        return item;
      }

      return undefined;
    });
  }

  checkIfDiffrentMacrosToday() {
    if (this.weightLogDatabase) {
      let tempitem = this.weightLogDatabase.find(
        (item) => isSameDay(item.date!, new Date()) && item.refeedMacros,
      );

      this.todayDiffMacroLog = tempitem;
      //console.log(this.todayDiffMacroLog);
      // this.todayWeightEntry = { ...tempitem };
    }
  }

  getDaysAgoData(data: WeightEntryData[], daysAgo: number) {
    let today = startOfToday();

    let dateDaysAgo = new Date(
      Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - daysAgo,
      ),
    );
    dateDaysAgo.setHours(0, 0, 0, 0);
    today.setHours(23, 59, 59, 59);

    return data.filter((item) => {
      let itemDate = new Date(item.date!);

      return itemDate >= dateDaysAgo && itemDate <= today;
    });
  }

  onCloseTrackingModal(
    event: boolean,
    form: FormGroup | undefined,
    user: AppUser,
  ) {
    this.customModalToggle = !this.customModalToggle;
    if (event) {
      const ctModel = convertFormToModel(form as FormGroup);
      if (this.todayWeightEntry) {
        this.todayWeightEntry.customTrackingLog = ctModel;
        this.weightLogService.addSpecificParametersToLog(
          user.id as string,
          this.todayWeightEntry,
        );
      }
    }
  }

  createWeightLogLables(data: WeightEntryData[]) {
    let log: string[] = [];

    data.forEach((item) => {
      log.push(format(item.date!, 'dd-MM'));
    });
    return log;
  }

  createWeightLogData(data: WeightEntryData[]) {
    let weightLogs: number[] = [];

    data.forEach((item) => {
      weightLogs.push(item.weight!);
    });

    return weightLogs;
  }

  createWeightLogGraph() {
    if (Chart.getChart('wlChart') != undefined) {
      Chart.getChart('wlChart')?.destroy();
    }

    let filteredDatabase = this.weightLogDatabase.filter(
      (item) => item.weight !== undefined && item.weight !== null,
    );

    this.averageWeight = this.calculateAverageWeight(
      filteredDatabase,
    ) as number;

    const data = {
      labels: this.createWeightLogLables(filteredDatabase),
      datasets: [
        {
          label: 'Weight Logs',
          data: this.createWeightLogData(filteredDatabase),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.2,
          trendlineLinear: {
            colorMin: 'red',
            colorMax: 'red',
            lineStyle: 'solid',
            width: 2,
            projection: true,
          },
        },
      ],
    };

    let minDataValue =
      Math.min(...this.createWeightLogData(filteredDatabase)) - 0.5;
    let maxDataValue =
      Math.max(...this.createWeightLogData(filteredDatabase)) + 0.5;

    const noData = {
      id: 'noData',
      afterDatasetsDraw: (chart: any, args: any, plugins: any) => {
        const {
          ctx,
          data,
          chartArea: { top, bottom, left, right, width, height },
        } = chart;
        ctx.save();

        if (data.datasets[0].data.length === 0) {
          ctx.fillStyle = 'rgba(201,201,201,0.5)';
          ctx.fillRect(left, top, width, height);
          ctx.font = 'bold 20px sans-serif';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';

          ctx.fillText('No Data Available', left + width / 2, top + height / 2);
        }
      },
    };

    const config = {
      type: this.lineChartType,
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {},
            suggestedMin: minDataValue,
            suggestedMax: maxDataValue,
          },
        },
      },
      plugins: [noData],
    };

    this.weightLogChart = new Chart('wlChart', config);
  }

  calculateAverageWeight(data: WeightEntryData[]) {
    const sum = data.reduce((a, b) => a + b.weight!, 0);

    const avg = (sum / data.length).toFixed(2) || 0;

    return avg;
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  onQuickAddWeight() {
    this.todayWeightEntry = undefined;
    this.quickWeightEntryToggler = !this.quickWeightEntryToggler;
  }

  onQuickEditWeight() {
    this.quickWeightEntryToggler = !this.quickWeightEntryToggler;
  }

  onSaveDataToServer(data: WeightEntryData) {
    if (this.checkForSameDay()) {
      data.setMacros = this.todayDiffMacroLog?.setMacros;
    }

    this.weightLogService.addDataToLog(this.today, data);

    this.quickWeightEntryToggler = !this.quickWeightEntryToggler;
  }

  onSaveMacrosToServer(data: WeightEntryData) {
    this.weightLogService.addDataToLog(this.today, data);

    this.macrosModalToggle = !this.macrosModalToggle;
  }

  onCancelEvent(event: boolean) {
    this.macrosModalToggle = !this.macrosModalToggle;
  }

  checkForSameDay() {
    return this.weightLogDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(this.today, entry.date);
    });
  }

  onClickEvent(event: boolean) {
    this.quickWeightEntryToggler = !this.quickWeightEntryToggler;
    if ((!event && !this.todayWeightEntry) || !this.todayWeightEntry!.weight) {
      this.todayWeightEntry = undefined;
    }
  }

  checkDates() {
    let check = false;

    this.weightLogDatabase.forEach((weightLog) => {
      if (isSameDay(weightLog.date as Date, this.today) && weightLog.weight) {
        check = true;
        this.todayWeightEntry = { ...weightLog };
      }

      if (check) return;

      return null;
    });

    return check;
  }

  checkForAchievedMacros() {
    let check = false;

    this.weightLogDatabase.forEach((weightLog) => {
      if (
        isSameDay(weightLog.date as Date, this.today) &&
        weightLog.achivedMacros
      ) {
        check = true;
        this.todayWeightEntry = { ...weightLog };
      }

      if (check) return;

      return null;
    });

    return check;
  }

  checkAchievedMacros() {
    this.selectedDayWeightEntry = this.weightLogDatabase.filter((entry) => {
      if (!entry.date) return null;

      if (isSameDay(this.today, entry.date)) {
        return entry;
      }

      return null;
    });

    if (!this.todayWeightEntry || !this.todayWeightEntry.achivedMacros) {
      this.achievedmacrosToggler = false;
    } else {
      this.achievedmacrosToggler = true;
    }
  }

  checkForMacros(data: WeightEntryData, user: AppUser) {
    if (!data || !data.achivedMacros) {
      return false;
    }

    // this.user = user;
    this.achievedmacrosToggler = true;
    return true;
  }

  onToggleCustomAddModal() {
    this.customModalToggle = !this.customModalToggle;
  }

  checkForSpecMacros(specs: Specification[]): boolean {
    return specs.some((spec) => spec.setMacros);
  }

  testFunction(testInput: WeightEntryData): boolean {
    let valueOne = 'test';
    const testValue = 1234;

    for (let i = 0; i < testValue; i++) {
      console.log(valueOne + i + testInput.comment);
    }

    return true;
  }
}
