import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Exercise } from '../../core/thecoach';
import { ExerciseService } from '../../services/exercise.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { take } from 'rxjs';

@Component({
  selector: 'app-exercise-creator',
  template: `
    <div class="flex justify-center flex-col items-center w-full">
      <form
        [formGroup]="exerciseForm"
        (ngSubmit)="onSubmit()"
        class="flex flex-row justify-evenly w-10/12 items-center"
      >
        <div class="flex flex-col justify-center  self-start w-3/12">
          <div class="flex flex-col w-full">
            <label
              for="city"
              class="block text-sm font-medium text-gray-700 sm:mt-px  pr-2 text-left items-center"
              >Exercise Name</label
            >
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                formControlName="exerciseName"
                id="exerciseName"
                class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
              />
            </div>
          </div>

          <div class="flex flex-col pt-2 w-full">
            <label
              for="city"
              class="block text-sm font-medium text-gray-700 sm:mt-px pr-2 text-left items-center"
              >Muscle Group</label
            >
            <div class="mt-1 sm:col-span-2 sm:mt-0 w-full">
              <input
                type="text"
                formControlName="muscleGroup"
                id="exerciseName"
                class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col justify-center  self-start w-4/12">
          <label
            for="region"
            class="block text-sm font-medium text-gray-700 sm:mt-px text-center items-center pb-1"
            >Exercise Description</label
          >

          <div class="mt-1 sm:col-span-2 sm:mt-0 w-full">
            <textarea
              rows="4"
              formControlName="exerciseDescription"
              id="exerciseDescription"
              class="flex w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
            ></textarea>
          </div>
        </div>

        <div class="flex flex-col justify-center   self-start w-3/12">
          <label
            for="postal-code"
            class="block text-sm font-medium text-center text-gray-700"
            >Exercise Video URL</label
          >
          <div class="mt-1 sm:col-span-2 sm:mt-0 pb-2 w-full">
            <input
              type="text"
              formControlName="exerciseVideoUrl"
              id="exerciseVideoUrl"
              class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
            />
          </div>
          <label
            for="postal-code"
            class="block text-sm font-medium text-center text-gray-700"
            >Exercise Id</label
          >
          <div class="mt-1 sm:col-span-2 sm:mt-0 pb-2 w-full">
            <input
              type="text"
              formControlName="id"
              id="id"
              class="block w-full max-w-lg rounded-md disabled:opacity-50 bg-gray-500 border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
            />
          </div>
          <div class="flex justify-around w-full">
            <button
              *ngIf="id"
              type="button"
              (click)="onDeleteExercise(id)"
              class="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
            >
              Delete
            </button>

            <button
              type="submit"
              [disabled]="exerciseForm.invalid"
              class="inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm disabled:opacity-50 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class ExerciseCreatorComponent implements OnInit {
  exercise: Exercise = {};

  @Input() id?: string | null;
  @Output() formSaved = new EventEmitter();

  exerciseForm = this.fb.group({
    id: [''],
    exerciseName: ['', [Validators.required]],
    exerciseVideoUrl: [''],
    exerciseDescription: ['', [Validators.required]],
    muscleGroup: [''],
    ownerId: [''],
  });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private exerciseService: ExerciseService,
  ) {
    // this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    if (this.id) {
      this.exerciseService
        .get(this.id)
        .pipe(take(1))
        .subscribe((data) => {
          if (!data) {
            return;
          }

          this.exercise = data as Exercise;
          this.exerciseForm.patchValue(this.exercise);
          this.exerciseForm.controls['id'].disable();
        });
    }
  }

  onSubmit() {
    this.exercise = Object.assign(this.exercise, this.exerciseForm.value);

    if (this.id) {
      this.exerciseService.update(this.id, this.exercise);
    } else {
      this.exerciseService.createNew(this.exercise);
    }

    this.formSaved.emit(true);
    // this.router.navigate(['/exercise-library']);
  }

  onDeleteExercise(id: string) {
    this.exerciseService.delete(id);
    this.formSaved.emit(true);
  }
}
