import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  AppUser,
  CustomInputType,
  CustomTrackingModel,
} from 'src/app/core/thecoach';

@Component({
  selector: '[app-weightlog-customheader-dashboard]',
  template: `
    <ng-container *ngIf="index === 0 || displayCTV" class="text-gray-900 ">
      <tr class="divide-x divide-gray-200">
        <th
          scope="col"
          class="pt-3.5 min-w-[4rem] pl-4 pr-1 text-center text-sm font-semibold   sm:pl-0 "
        >
          Date
        </th>
        <th scope="col" class="pt-3.5 px-1 text-center text-sm font-semibold  ">
          Weight
        </th>
        <th scope="col" class="pt-3.5 px-1 text-center text-sm font-semibold  ">
          7 Day
        </th>
        <th scope="col" colspan="2" class="pt-3.5 px-1 text-center text-sm font-semibold  ">
          ROG/L
        </th>

        <th scope="col"  colspan="2" class="pt-3.5 px-1 text-center text-sm font-semibold  ">
          ROG/L
        </th>
        <th
          scope="col"
          colspan="4"
          class="pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Daily Macros
        </th>
        <th
          *ngIf="client.enableOffDayMacros"
          scope="col"
          colspan="4"
          class="pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Off Day Macros
        </th>

        <th
          scope="col"
          colspan="4"
          class=" pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Achieved Macros
        </th>
        <th
          *ngIf="client.enableTraining"
          scope="col"
          colspan="1"
          class=" pt-3.5 px-1 text-center text-sm font-semibold  "
        >
          Sessions
        </th>
        <ng-container *ngIf="displayCTV">
          <ng-container *ngIf="ctm">
            <th
              *ngFor="let ctv of ctm.ccts"
              scope="col"
              class="pt-3.5 px-1 text-center text-sm font-semibold  "
            >
              <div *ngIf="ctv.name.length <= 8">
                {{ ctv.name }}
              </div>

              <div
                class=" group flex relative items-center justify-center"
                *ngIf="ctv.name.length > 8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
                <span
                  class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                >
                  {{ ctv.name }}</span
                >
              </div>
            </th>
          </ng-container>
        </ng-container>
      </tr>
      <tr class="divide-x divide-gray-200">
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
          Avg.
        </th>
        <th scope="col" colspan="2" class="px-1 text-center text-sm font-semibold  ">
          (daily)
        </th>

        <th
          colspan="2"
          scope="col"
          class="whitespace-nowrap px-1 text-center text-sm font-semibold  "
        >
          (7-Day)

        </th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">P</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">C</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">F</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
          KCAL
        </th>
        <ng-container *ngIf="client.enableOffDayMacros">
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            P
          </th>
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            C
          </th>
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            F
          </th>
          <th scope="col" class=" px-1 text-center text-sm font-semibold  ">
            KCAL
          </th>
        </ng-container>
        <th scope="col" class="px-1 text-center text-sm font-semibold  ">P</th>
        <th scope="col" class="px-1 text-center text-sm font-semibold  ">C</th>
        <th scope="col" class=" px-1 text-center text-sm font-semibold  ">F</th>
        <th scope="col" class="px-1 text-center text-sm font-semibold  ">
          KCAL
        </th>
            <th scope="col" class="px-1 text-center text-sm font-semibold  ">
        </th>
        <ng-container *ngIf="displayCTV">
          <ng-container *ngIf="ctm">
            <th
              *ngFor="let ctv of ctm.ccts"
              scope="col"
              class="px-1 text-center text-sm font-semibold  "
            >
              <div>
                <ng-container
                  *ngIf="ctv.type === CustomInputType.Number"
                  class="flex flex-row"
                >
                  <p class="">
                    {{ ctv.defaultValue }}
                  </p>
                </ng-container>

                <ng-container
                  *ngIf="ctv.type === CustomInputType.Range"
                  class="flex flex-row"
                >
                  <p class="">{{ ctv.min }}-{{ ctv.max }}</p>
                </ng-container>
              </div>
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-container>
  `,
  styles: [],
})
export class WeightlogCustomheaderDashboardComponent {
  CustomInputType = CustomInputType;

  constructor() { }

  @Input() ctm!: CustomTrackingModel | null | undefined;
  @Input() displayCTV!: boolean;
  @Input() index!: number;
  @Input() client!: AppUser;
}
