import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { AppUser, CustomInputType, WeightEntryData, convertDateObject } from '../core/thecoach';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { AuthService } from './auth.service';
import {
  Observable,
  Subscription,
  filter,
  first,
  map,
  of,
  pipe,
  shareReplay,
  switchMap,
} from 'rxjs';
import { CollectionReference, FieldValue } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class WeightlogService {
  user: AppUser | undefined;
  constructor(private db: AngularFirestore, private auth: AuthService) { }

  addDataToLog(date: Date, weightEntry: WeightEntryData) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      const dateFormat = format(date, 'yyyy-MM-dd');

      if (weightEntry.id === undefined || !weightEntry.id) {
        weightEntry.id = this.db.createId();
      }

      //if (this.user.setMacros) weightEntry.setMacros = this.user.setMacros;
      if (!weightEntry.setMacros && this.user.setMacros) {
        weightEntry.setMacros = this.user.setMacros;
      }

      return this.db
        .collection('weightlogs')
        .doc(this.user.id)
        .collection('logs')
        .doc(dateFormat)
        .set(Object.assign({}, weightEntry), { merge: true });
    });
  }

  addDataToLogForUser(
    date: Date,
    client: AppUser,
    weightEntry: WeightEntryData
  ) {
    const dateFormat = format(date, 'yyyy-MM-dd');

    if (weightEntry.id === undefined || !weightEntry.id) {
      weightEntry.id = this.db.createId();
    }

    //if (this.user.setMacros) weightEntry.setMacros = this.user.setMacros;
    if (!weightEntry.setMacros && client.setMacros) {
      weightEntry.setMacros = client.setMacros;
    }

    return this.db
      .collection('weightlogs')
      .doc(client.id)
      .collection('logs')
      .doc(dateFormat)
      .set(Object.assign({}, weightEntry), { merge: true });
  }

  addSpecificParametersToLog(clientId: string, weightEntry: WeightEntryData) {
    const dateFormat = format(weightEntry.date as Date, 'yyyy-MM-dd');

    if (weightEntry.id === undefined || !weightEntry.id) {
      weightEntry.id = this.db.createId();
    }

    if (weightEntry.customTrackingLog && !weightEntry.customTrackingLog.logId) {
      weightEntry.customTrackingLog.logId = this.db.createId();
    }

    return this.db
      .collection('weightlogs')
      .doc(clientId)
      .collection('logs')
      .doc(dateFormat)
      .set(Object.assign({}, weightEntry), { merge: true });
  }

  deleteSetMacrosFromLog(client: AppUser, weightEntry: WeightEntryData) {
    const dateFormat = format(weightEntry.date as Date, 'yyyy-MM-dd');

    if (weightEntry.id === undefined || !weightEntry.id) {
      weightEntry.id = this.db.createId();
    }

    if (client.setMacros) weightEntry.setMacros = client.setMacros;

    if (weightEntry.achivedMacros || weightEntry.weight) {
      return this.db
        .collection('weightlogs')
        .doc(client.id)
        .collection('logs')
        .doc(dateFormat)
        .set(Object.assign({}, weightEntry), { merge: false });
    } else {
      return this.db
        .collection('weightlogs')
        .doc(client.id)
        .collection('logs')
        .doc(dateFormat)
        .delete();
    }
  }

  getAllWeightLogData(): Observable<WeightEntryData[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection<WeightEntryData>('weightlogs')
          .doc(appUser?.id)
          .collection('logs')
          .valueChanges();
      })
    );
  }

  overWriteLogAsCustomer(weightEntry: WeightEntryData): void {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      const dateFormat = format(weightEntry.date!, 'yyyy-MM-dd');

      return this.db
        .collection('weightlogs')
        .doc(this.user.id)
        .collection('logs')
        .doc(dateFormat)
        .set(Object.assign({}, weightEntry), { merge: false });
    });
  }

  overWriteLogForUser(weightEntry: WeightEntryData, user: AppUser): void {


    const dateFormat = format(weightEntry.date!, 'yyyy-MM-dd');

    this.db
      .collection('weightlogs')
      .doc(user.id)
      .collection('logs')
      .doc(dateFormat)
      .set(Object.assign({}, weightEntry), { merge: false });

  }

  deleteDataPointAsCustomer(date: Date) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      const dateFormat = format(date, 'yyyy-MM-dd');

      return this.db
        .collection('weightlogs')
        .doc(this.user.id)
        .collection('logs')
        .doc(dateFormat)
        .delete();
    });
  }

  deleteDataPointForUser(date: Date, user: AppUser) {

    const dateFormat = format(date, 'yyyy-MM-dd');

    return this.db
      .collection('weightlogs')
      .doc(user.id)
      .collection('logs')
      .doc(dateFormat)
      .delete();
  }


  private weightLogCacheXDaysFuture$: Observable<WeightEntryData[]> | undefined;

  getWeightLogForClientOfXDaysFuture(
    days: number
  ): Observable<WeightEntryData[]> {
    if (!this.weightLogCacheXDaysFuture$) {
      this.weightLogCacheXDaysFuture$ = this.auth.appUser$.pipe(
        switchMap((appUser) => {
          if (!appUser) {
            return of([]);
          }
          // console.log('APPUSER' + appUser);

          const today = new Date();
          const startOfToday = startOfDay(today);
          const startOfPastDays = subDays(startOfToday, days);

          return this.db
            .collection<WeightEntryData>('weightlogs')
            .doc(appUser.id)
            .collection('logs', (ref) =>
              ref.orderBy('date', 'desc').limit(days)
            )
            .valueChanges();
        }),
        shareReplay({ bufferSize: 35, refCount: true })
      );
    }

    return this.weightLogCacheXDaysFuture$;
  }

  private weightLogCacheXDaysNoFuture$:
    | Observable<WeightEntryData[]>
    | undefined;

  getWeightLogForClientOfXDaysNoFuture(
    days: number
  ): Observable<WeightEntryData[]> {
    if (!this.weightLogCacheXDaysNoFuture$) {
      this.weightLogCacheXDaysNoFuture$ = this.auth.appUser$.pipe(
        switchMap((appUser) => {
          if (!appUser) {
            return of([]);
          }

          const today = new Date();
          const startOfToday = startOfDay(today);
          const startOfPastDays = subDays(startOfToday, days);

          return this.db
            .collection<WeightEntryData>('weightlogs')
            .doc(appUser.id)
            .collection('logs', (ref) =>
              ref
                .orderBy('date', 'desc')
                .where('date', '>=', startOfPastDays)
                .where('date', '<=', endOfDay(today))
                .limit(days)
            )
            .valueChanges();
        }),
        shareReplay({ bufferSize: 35, refCount: true })
      );
    }

    return this.weightLogCacheXDaysNoFuture$;
  }

  getWeightLogForUserBetweenDates(userId: string, startDate: Date, endDate: Date): Observable<WeightEntryData[]> {
   // console.log('startDate', startDate)
   // console.log('endDate', endDate)
    // console.log('userId', userId)
    return this.db
      .collection<WeightEntryData>('weightlogs')
      .doc(userId)
      .collection<WeightEntryData>('logs', (ref) =>
        ref.orderBy('date', 'desc')
          .where('date', '>=', startDate)
          .where('date', '<=', endDate))
      .valueChanges()
      .pipe(
        map((entries: WeightEntryData[]) => {
          return entries.map(entry => {

            if (entry.date) entry.date = convertDateObject(entry.date);
            if(entry.customTrackingLog){
              entry.customTrackingLog.ccts.forEach(cct =>{
                if(cct && cct.value && cct.type === CustomInputType.Number){
                  const valueStr = cct.value.toString();
                  if(valueStr.includes(",")){
                    cct.value = parseFloat(valueStr.replace(",","."))

                  }else{
                    cct.value = parseFloat(valueStr)
                  }

                }
              })
            }
            return entry;
          })
        })
      )
  }








  getAllWeightLogDataOfUser(clientid: string): Observable<WeightEntryData[]> {
    return this.db
      .collection<WeightEntryData>('weightlogs')
      .doc(clientid)
      .collection('logs', (ref) => ref.orderBy('date', 'desc'))
      .valueChanges();
  }

  private weightLogCacheData$: Observable<WeightEntryData[]> | undefined;

  getWeightLogDataOfXDaysNew(clientid: string, days: number) {
    const today = new Date();
    const startOfToday = startOfDay(today);
    const startOfPastDays = subDays(startOfToday, days);

    return this.db
      .collection<WeightEntryData>('weightlogs')
      .doc(clientid)
      .collection('logs', (ref) =>
        ref
          .orderBy('date', 'desc')

          .where('date', '>=', startOfPastDays)
          .where('date', '<=', endOfDay(today))
          .limit(days)
      );
  }

  getWeightLogDataOfXDays(
    clientid: string,
    days: number
  ): Observable<WeightEntryData[]> {
    const today = new Date();
    const startOfToday = startOfDay(today);
    const startOfPastDays = subDays(startOfToday, days);

    return this.db
      .collection<WeightEntryData>('weightlogs')
      .doc(clientid)
      .collection('logs', (ref) => ref.orderBy('date', 'desc').limit(days))
      .valueChanges();
  }

  getWeightLogDataOfXDaysUpdateDate(
    clientid: string,
    days: number
  ): Observable<WeightEntryData[]> {

    return this.db
      .collection<WeightEntryData>('weightlogs')
      .doc(clientid)
      .collection('logs', (ref) => ref.orderBy('date', 'desc').limit(days))
      .valueChanges()
      .pipe(
        map((entries: DocumentData[]) =>
          entries.map((entry) => {
            const weightEntry = entry as WeightEntryData;
            weightEntry.date = convertDateObject(weightEntry.date!);
            return weightEntry;
          })
        )
      );
  }

  getWeightLogOfSingleUserSingleDate(client: AppUser, selectedDay: Date) {
    const dateFormat = format(selectedDay as Date, 'yyyy-MM-dd');

    return this.db
      .collection<WeightEntryData>('weightlogs')
      .doc(client.id)
      .collection('logs')
      .doc(dateFormat)
      .valueChanges()
      .pipe(
        map((entry: DocumentData | undefined) => {
          if (!entry) {
            return undefined; // Return undefined if the document doesn't exist
          }

          const weightEntry = entry as WeightEntryData;
          weightEntry.date = convertDateObject(weightEntry.date!);
          return weightEntry;
        })
      );
  }
}
