import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { Observable, map, of, switchMap } from 'rxjs';
import { AppUser, convertDateObject } from '../core/thecoach';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private db: AngularFirestore, private auth: AuthService) { }

  getAllUsers(): Observable<AppUser[] | undefined> {
    return this.auth.appUser$.pipe(switchMap((appUser) => {
      if (!appUser || appUser.role !== 'admin') return of(undefined)

      return this.db.collection<AppUser>('users').valueChanges();

    }), map((appUsers) => {
      if (!appUsers) return appUsers;
      return appUsers.map(user => {
        if (user.birthdate) {

          user.birthdate = convertDateObject(user.birthdate as Date)
        }
        if (user.registrationDate) {

          user.registrationDate = convertDateObject(user.registrationDate as Date)
        }

        return user;
      })
    }))
  }




}
