import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { isToday } from 'date-fns';
import { first } from 'rxjs';
import {
  Macros,
  calculateKcalFromMacros,
  AppUser,
  WeightEntryData,
  convertDateObject,
} from 'src/app/core/thecoach';
import { UserService } from 'src/app/services/user.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-set-macros-module',
  template: `
    <div class="flex flex-col xl:flex-row items-center">
      <dl class="mt-1 grid grid-cols-4  gap-2 text-center">
        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">Protein</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ client.setMacros?.protein }}
          </dd>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">Carbs</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ client.setMacros?.carbs }}
          </dd>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">Fat</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ client.setMacros?.fat }}
          </dd>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">KCAL</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ calculateKcal(client.setMacros!) }}
          </dd>
        </div>
      </dl>

      <div class="flex mt-2 xl:mt-0 items-center">
        <button
          type="button"
          (click)="onSetMacrosModal(client); $event.stopPropagation()"
          class="rounded-md bg-teal-600 py-1.5 px-2.5 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Set Macros
        </button>
        <button
          [disabled]="!client.setMacros"
          type="button"
          (click)="onTriggerDeleteMacrosModal(); $event.stopPropagation()"
          class="rounded-md bg-red-600 py-1.5 px-2.5 ml-2 text-sm disabled:opacity-25 font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>
        </button>
      </div>
    </div>

    <div
      *ngIf="macroModaltrigger"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-set-macros-modal
        @AnimationTrigger1
        [client]="client"
        (onClick)="setMacrosOfClient($event)"
        *ngIf="macroModaltrigger"
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
      ></app-set-macros-modal>
    </div>

<div *ngIf="deleteToggler">
<app-simple-modal
[title]="'Delete Macros?'"
[message]="'Do you want to delete User Macros?'"
        [positiveButtonText] = "'Yes'"
        [negativeButtonText] = "'No'"
(close)="onDeleteMacros($event)"



      ></app-simple-modal>
</div>

  `,
  styles: [],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class SetMacrosModuleComponent {
  constructor(
    private userService: UserService,
    private weightLogService: WeightlogService
  ) { }
  @Input() client!: AppUser;

  macroModaltrigger = false;
  deleteToggler = false;

onTriggerDeleteMacrosModal(){
    this.deleteToggler = !this.deleteToggler
  }




  onDeleteMacros(value:boolean){
    if(value && this.client){
    delete this.client.setMacros;
    this.userService.overWriteUser((this.client))
    }


    this.onTriggerDeleteMacrosModal();

  }
  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  onSetMacrosModal(client: AppUser) {
    this.macroModaltrigger = !this.macroModaltrigger;
  }

  setMacrosOfClient(event: boolean) {
    if (event) {
      this.userService.overWriteUser(this.client);

      this.weightLogService
        .getWeightLogDataOfXDaysUpdateDate(this.client.id!, 1)
        .pipe(first())
        .subscribe((data) => {
          let log: WeightEntryData | undefined = undefined;
          if (data.length > 0) {

            log = data[0];
            if (log && log.date && isToday(log.date)) {
              log.date = convertDateObject(log.date as Date);
              log.setMacros = this.client.setMacros;
            }
          } else {
            log = {
              setMacros: this.client.setMacros,
              date: new Date(),
            }

          }
          if (log) {

            this.weightLogService.addDataToLogForUser(log.date as Date, this.client, log);
          }

        });
    }
    this.macroModaltrigger = !this.macroModaltrigger;
  }
}
