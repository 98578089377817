import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(private db: AngularFirestore) {}

  saveEmail(email: string) {
    const id = this.db.createId();

    return this.db.collection('/newsletter').doc(id).set({ email: email });
  }
}
