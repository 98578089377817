import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CheckinService } from '../services/checkin.service';
import { SharedService } from '../services/shared.service';
import { UserService } from '../services/user.service';
import { Observable, Subscription } from 'rxjs';
import { AppUser, ClientCheckin } from '../core/thecoach';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-admin-dashboard',
  template: `
<div *ngIf="users$ | async as users">
          <ul role="list" class="mx-auto mt-4 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-2 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-5">
      <li *ngFor="let user of users" (click)="onSelectUser(user)" class="cursor-pointer">
      <img class="mx-auto h-12 w-12 rounded-full" src="{{user.imgUrl  || '.././assets/images/default-person-image.webp'}}" alt="">
      <h4 class="text-lg font-bold">{{user.displayName}}</h4>
              <p class="text-xs leading-6 text-gray-600">{{user.id}}</p>
       <p class="text-xs leading-6 text-gray-600">{{user.email}}</p>
      </li>
          </ul>













      <app-training-log-panel-dashboard
        *ngIf="selectedClient &&
          selectedClient.enableTraining &&
          selectedClient.traininglogIds
        "
        class="flex w-full pt-4"
        [selectedClient]="selectedClient"
      ></app-training-log-panel-dashboard>


      <app-coach-dashboard-data-table-panel   *ngIf="
selectedClient &&
    selectedClient.enableWeightLog"

        [selectedClient]="this.selectedClient">
      </app-coach-dashboard-data-table-panel>



</div>
  `,
  styles: [
  ]
})
export class AdminDashboardComponent {

  todaysCheckIns: ClientCheckin[] = [];

  selectedClient: AppUser | undefined;
  selectedCheckin: ClientCheckin | undefined;

  sharedSubscription: Subscription | undefined;
  users$: Observable<AppUser[] | undefined> | undefined;
  constructor(
    protected auth: AuthService,
    protected userService: UserService,
    private checkInService: CheckinService,
    private sharedService: SharedService,
    private adminService: AdminService
  ) {
    this.users$ = this.adminService.getAllUsers();

  }

  getClientCheckin(client: AppUser) {
    return this.todaysCheckIns.find(
      (checkin) => checkin.clientId === client.id
    );
  }

  onSelectUser(user: AppUser) {
    this.selectedClient = user;
  }



}
