/* eslint-disable prettier/prettier */
import { Ingredient, Meal, MealTemplate, User } from '@prisma/client';

/* eslint-disable */
export interface FCMTokens {
  token: string;
  date: Date;
}

export interface PrevAttribues {
  quantity: number;
}

export interface Invite {
  id: string;
  creatorId: string;

  createdAt: Date;
  expiresAt: Date;
  isCoach?: boolean;
}

export interface UserConfig {
  id: string;
  created: Date;
  subId: string;
  latestInvoice: string;
  itemId: string;
  planId: string;
  productId: string;
  quantity: number;
  currentPeriodEnd: number;
}

export interface MealPlanWithDays {
  id: string;
  name: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;

  days: DayWithMeals[];

  coach: User;
  coachId: string;

  client: User;
  clientId: string;
}

export interface DayWithMeals {
  id: string;
  name: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  meals: MealWithIngredients[];

  mealPlanId: string;
}

export interface MealWithIngredients {
  id: string;
  name: string;
  addedIngredients: AddedIngredientWithIngredient[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  saveToDB: boolean;

  mealImgUrl: string;
  mealDescription: string;
  DayId: string;
}

export interface AddedIngredientWithIngredient {
  id: string;
  grams: number;
  ingredientId: string;
  ingredient: Ingredient;
  mealId: string;
  meal: Meal | undefined;
  mealTemplateId?: string;
  mealTempalte?: MealTemplate;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export function manipulateNumbersDown(ing: Ingredient) {
  const propertiesToManipulate: (keyof Ingredient)[] = [
    'packageCost',
    'packageSize',
    'protein',
    'fat',
    'saturatedFat',
    'carb',
    'sugar',
    'fibre',
  ];

  propertiesToManipulate.forEach((property) => {
    if (ing[property] != null) {
      (ing[property] as number) = (ing[property] as number) / 100;
    }
  });

  return ing;
}

export function manipulateNumbersUp(ing: Ingredient) {
  const propertiesToManipulate: (keyof Ingredient)[] = [
    'packageCost',
    'packageSize',
    'protein',
    'fat',
    'saturatedFat',
    'carb',
    'sugar',
    'fibre',
  ];

  propertiesToManipulate.forEach((property) => {
    if (ing[property] != null) {
      (ing[property] as number) = Math.round((ing[property] as number) * 100);
    }
  });

  return ing;
}

function calculateMacros({
  protein,
  carbs,
  fat,
}: {
  protein: number;
  carbs: number;
  fat: number;
}) {
  return ((protein + carbs) * 4.1 + fat * 9.3).toFixed().toString();
}

export function dayMacroInfo(day: DayWithMeals) {
  let totalProteins = 0;
  let totalCarbs = 0;
  let totalFats = 0;
  let totalKcal = 0;

  day.meals.forEach((meal) => {
    // Reset for each meal
    let mealProteins = 0;
    let mealCarbs = 0;
    let mealFats = 0;

    meal.addedIngredients.forEach((addedIng) => {
      const grams = addedIng.grams;
      mealProteins += (addedIng.ingredient.protein * grams) / 100 || 0;
      mealCarbs += (addedIng.ingredient.carb * grams) / 100 || 0;
      mealFats += (addedIng.ingredient.fat * grams) / 100 || 0;
    });

    // Accumulate meal values to total
    totalProteins += mealProteins;
    totalCarbs += mealCarbs;
    totalFats += mealFats;

    // Calculate kcal for each meal
    const mealKcal = calculateMacros({
      protein: mealProteins,
      carbs: mealCarbs,
      fat: mealFats,
    });
    totalKcal += Number(mealKcal) || 0;
  });

  return (
    'P: ' +
    totalProteins.toFixed(0) +
    'g C: ' +
    totalCarbs.toFixed(0) +
    'g F: ' +
    totalFats.toFixed(0) +
    'g Kcal: ' +
    totalKcal.toFixed(0)
  );
}

export function mealMacroInfo(meal: MealWithIngredients) {
  let totalProteins = 0;
  let totalCarbs = 0;
  let totalFats = 0;
  let totalKcal = 0;

  meal.addedIngredients.forEach((addedIng) => {
    const grams = addedIng.grams;
    totalProteins += (addedIng.ingredient.protein * grams) / 100 || 0;
    totalCarbs += (addedIng.ingredient.carb * grams) / 100 || 0;
    totalFats += (addedIng.ingredient.fat * grams) / 100 || 0;
  });

  // Accumulate meal values to total

  // Calculate kcal for each meal
  const mealKcal = calculateMacros({
    protein: totalProteins,
    carbs: totalCarbs,
    fat: totalFats,
  });
  totalKcal += Number(mealKcal) || 0;

  return (
    'P: ' +
    totalProteins.toFixed(0) +
    'g C: ' +
    totalCarbs.toFixed(0) +
    'g F: ' +
    totalFats.toFixed(0) +
    'g Kcal: ' +
    totalKcal.toFixed(0)
  );
}

export function mealPlanMacroInfo(mealplan: MealPlanWithDays) {
  let totalProteins = 0;
  let totalCarbs = 0;
  let totalFats = 0;
  let totalKcal = 0;

  mealplan.days.forEach((day) => {
    day.meals.forEach((meal) => {
      let mealProteins = 0;
      let mealCarbs = 0;
      let mealFats = 0;
      let mealKcal = 0;

      meal.addedIngredients.forEach((addedIng) => {
        const grams = addedIng.grams;
        mealProteins += (addedIng.ingredient.protein * grams) / 100 || 0;
        mealCarbs += (addedIng.ingredient.carb * grams) / 100 || 0;
        mealFats += (addedIng.ingredient.fat * grams) / 100 || 0;
      });
      mealKcal +=
        Number(
          calculateMacros({
            protein: mealProteins,
            carbs: mealCarbs,
            fat: mealFats,
          }),
        ) || 0;

      // Accumulate meal values to total
      totalProteins += mealProteins;
      totalCarbs += mealCarbs;
      totalFats += mealFats;
      totalKcal += mealKcal;
    });
  });

  return (
    'P: ' +
    totalProteins.toFixed(0) +
    'g C: ' +
    totalCarbs.toFixed(0) +
    'g F: ' +
    totalFats.toFixed(0) +
    'g Kcal: ' +
    totalKcal.toFixed(0)
  );
}
