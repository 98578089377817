import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ex } from '@fullcalendar/core/internal-common';
import { first, takeUntil } from 'rxjs';
import {
  CustomSlotModelInputType,
  CustomSlotModelInputValue,
  Exercise,
  SlotModel,
} from 'src/app/core/thecoach';
import { ExerciseService } from 'src/app/services/exercise.service';
import { ModelService } from 'src/app/services/trainingplans/model.service';

@Component({
  selector: 'app-template-training-day',
  template: `
    <div class="flex w-full pb-4">
      <form [formGroup]="trainingday" class="flex w-full pt-4">
        <div class="flex flex-col w-full">
          <div class="items-center w-full flex flex-row justify-between">
            <div class="flex flex-row w-full justify-around items-center">
              <div class="flex flex-row w-full ">
                <div class="flex w-5/12">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    formControlName="name"
                    class="block w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
                    placeholder="Enter Trainingday Name"
                  />
                </div>
              </div>
              <div class="flex w-fit items-center">
                <div class="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      (click)="toggleMenu()"
                      class="inline-flex whitespace-nowrap w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      id="menu-button"
                      aria-expanded="true"
                      aria-haspopup="true"
                    >
                      {{
                        selectedTrainingModel?.name || 'Default Training Model'
                      }}
                      <svg
                        class="-mr-1 h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  <div
                    @opacityScale
                    *ngIf="isMenu"
                    class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <div
                        (click)="setTrainingModel(model)"
                        *ngFor="let model of currentModels"
                        class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        {{ model.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="isMenu"
                  (click)="toggleMenu()"
                  class="fixed inset-0"
                ></div>
              </div>
              <div>
                <button
                  (click)="onAddExercise()"
                  type="button"
                  class="rounded-md ml-4 whitespace-nowrap bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  Add Exercise
                </button>
              </div>
            </div>
          </div>

          <div *ngFor="let trainingSlot of trainingSlots.controls" class="pt-4">
            <app-template-training-slot
              [exerciseDB]="exerciseDB"
              [trainingSlot]="convertToFormGroup(trainingSlot)"
              [userTrainingModels]="currentModels"
            ></app-template-training-slot>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class TemplateTrainingDayComponent {
  @Input({ required: true }) trainingday!: FormGroup;
  @Input({ required: true }) exerciseDB!: Exercise[];
  isMenu = false;

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  /* Example Trainingday thats Passed as an Input
   *     const trainingDay = this.fb.group({
      id: [''],
      name: ['', Validators.required],

      trainingSlots: this.fb.array([]),
    });

*/

  currentModels: SlotModel[] = [];
  selectedTrainingModel: SlotModel | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    protected modelService: ModelService,
  ) {
    this.modelService.currentModels$?.pipe(first()).subscribe((slotmodels) => {
      if (slotmodels) {
        this.currentModels = slotmodels;
        this.selectedTrainingModel = slotmodels[0];
      }
    });
  }

  get trainingSlots() {
    return this.trainingday.get('trainingSlots') as FormArray;
  }

  convertToFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }

  onAddExercise() {
    //We need to create the slotmodel as a Formgroup!
    const trainingSlot = this.fb.group({
      id: [''],
      date: [],
      slotNr: [],
      slotModel: this.createSlotModelForm(this.selectedTrainingModel),
      exerciseSetup: [''],
      exercise: [, Validators.required],
    });

    this.trainingSlots.push(trainingSlot);
  }

  createSlotModelForm(slotModel: SlotModel | undefined) {
    if (!slotModel) {
      return undefined;
    }
    const slotModelForm = this.fb.group({
      id: [slotModel.id],
      ownerId: [slotModel.ownerId],
      name: [slotModel.name],
      customInput: this.fb.group({
        progressDirection: [slotModel.customInput.progressDirection],
        csmiv: this.fb.array([]),
      }),
    });

    slotModel.customInput.csmiv.forEach((cmiv) => {
      (
        (slotModelForm.get('customInput') as FormGroup).get(
          'csmiv',
        ) as FormArray
      ).push(this.generateCsmivForms(cmiv));
    });

    return slotModelForm;
  }

  generateCsmivForms(cmiv: CustomSlotModelInputValue) {
    switch (cmiv.type) {
      case CustomSlotModelInputType.Number:
      case CustomSlotModelInputType.Time:
        return this.fb.group({
          value: [cmiv.value],
          coachValue: [cmiv.coachValue],
          name: [cmiv.name],
          description: [cmiv.description],
          includedCalc: [cmiv.includeCalc],
          type: [cmiv.type],
          slot: [cmiv.slot],
        });
      case CustomSlotModelInputType.Target:
        return this.fb.group({
          value: [cmiv.value],
          name: [cmiv.name],
          description: [cmiv.description],
          type: [cmiv.type],
          slot: [cmiv.slot],
        });
      default:
        throw new Error(`Unhandeld Input Type! $(cmiv.type)`);
    }
  }

  setTrainingModel(model: SlotModel) {
    this.selectedTrainingModel = model;
    this.toggleMenu();
  }
}
