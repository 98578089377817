import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  defaultIfEmpty,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { format, getDay, getWeek, isSameDay, isToday, parse } from 'date-fns';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import {
  AppUser,
  ClientCheckin,
  convertDateObject,
  getWeekdayIndex,
} from 'src/app/core/thecoach';
import { CheckinService } from 'src/app/services/checkin.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-coach-dashboard-v2',
  template: `
    <div class="flex justify-between -mr-6">
      <div>
        <div class="">
          <input
            type="search"
            name="search"
            id="search"
            autocomplete="off"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-[15rem] rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="{{
              selectedClient?.displayName
                ? selectedClient?.displayName + ' - ' + selectedClient?.email
                : 'Enter Client Name...'
            }}"
          />
          <div class="absolute z-10 w-3/5 md:w-2/5 lg:w-[25rem]">
            <ul
              *ngIf="searchNameValue !== ''"
              class="absolute z-20 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              id="options"
              role="listbox"
            >
              <li
                *ngFor="let client of filteredSearchClients"
                class="hover:text-white hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                id="option-0"
                role="option"
                tabindex="-1"
                (click)="onSelectedClient(client); searchNameValue = ''"
              >
                <div class="flex flex-row items-center">
                  <!-- Selected: "font-semibold" -->
                  <span class="flex flex-row whitespace-nowrap pr-1">{{
                    client.displayName
                  }}</span>
                  -
                  <span class="block truncate italic text-xs pl-1">
                    {{ client.email }}</span
                  >
                </div>
                <!--
          Checkmark, only display for selected option.

          Active: "text-white", Not Active: "text-teal-600"
        -->
              </li>

              <!-- More items... -->
            </ul>
          </div>
        </div>
      </div>

      <div>
        <span
          class="isolate inline-flex rounded-md shadow-sm cursor-pointer group hover:bg-gray-50"
          (click)="toggleSlideOver()"
        >
          <div
            class="relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 group-hover:bg-gray-50 focus:z-10"
          >
            <svg
              class=" h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
              />
            </svg>
            {{ selectedDay | date: 'EEE' }}
          </div>
          <div
            class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 group-hover:bg-gray-50 focus:z-10"
          >
            {{ checkInAmount }}
          </div>
        </span>
      </div>
    </div>

    <app-coach-dashboard-side-menu
      @translateX
      *ngIf="isSlideOver"
      [isSlideOver]="isSlideOver"
      (changeSlideover)="isSlideOver = $event"
      (selectedDay)="onSelectedDay($event)"
    ></app-coach-dashboard-side-menu>

    <div *ngIf="this.coach" class="flex flex-row pl-6 pt-4">
      <div class="flex w-full justify-between">
        <div class="flex">
          <ng-container
            *ngFor="let client of fliteredClientesForSelectedDay; let i = index"
          >
            <app-coach-dashboard-client-avatar
              (click)="onSelectedClient(client)"
              [client]="client"
              [toggleCheckinStatus]="true"
              [clientCheckin]="avatarCheckins$[i] | async"
            ></app-coach-dashboard-client-avatar>
          </ng-container>
        </div>

        <div class="flex flex-col md:flex-row">
          <div class="flex -space-x-4 hidden">
            <ng-container *ngFor="let client of remaingingClients">
              <app-coach-dashboard-client-avatar
                [toggleCheckinStatus]="false"
                (click)="onSelectedClient(client)"
                [client]="client"
              ></app-coach-dashboard-client-avatar>
            </ng-container>
          </div>
          <div class="flex" *ngIf="selectedClient">
            <div class="absolute right-2">
              <button
                (click)="resetVariables()"
                type="button"
                class="rounded-md bg-teal-50 px-2.5 py-1.5 text-sm font-semibold text-teal-600 shadow-sm hover:bg-teal-100"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedClient && this.coach">
      <div class="flex flex-col 2xl:flex-row justify-between">
        <div
          class="rounded-lg bg-white shadow w-full mt-4 2xl:mt-0 2xl:mr-4 2xl:min-w-[40%] ring-1 ring-gray-300"
          *ngIf="selectedCheckin"
        >
          <div class="px-4  sm:px-6 ">
            <app-coach-dashboard-check-in-selected
              [selectedCheckin]="selectedCheckin"
              [coach]="this.coach"
            ></app-coach-dashboard-check-in-selected>
          </div>
        </div>

        <div
          class="rounded-lg bg-white shadow w-full 2xl:flex-1 2xl:mr-4 mt-4 2xl:mt-0 ring-1 ring-gray-300"
          *ngIf="
            selectedCheckin &&
            selectedCheckin.CustomCheckinModel &&
            selectedCheckin.CustomCheckinModel.ccfs &&
            selectedCheckin.CustomCheckinModel.ccfs.length > 0 &&
            selectedClient.enableCMFs
          "
        >
          <div class="px-4  sm:px-6 ">
            <app-display-checkin-form-dashboard
              [selectedCheckin]="selectedCheckin"
              [coach]="this.coach"
            ></app-display-checkin-form-dashboard>
          </div>
        </div>
        <div
          class="rounded-lg bg-white shadow w-full h-full 2xl:w-fit 2xl:h-fit 2xl:min-w-[30%] mt-4 2xl:mt-0 ring-1 ring-gray-300"
        >
          <div class="px-4 py-5 sm:p-6">
            <app-coach-dashboard-check-in-history
              (loadAllCheckIns)="onLoadHistory($event)"
              (selectedCheckin)="selectedCheckin = $event"
              [clientCheckins]="clientCheckins$ | async"
            ></app-coach-dashboard-check-in-history>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row justify-between">
        <app-coach-dashboard-data-table-panel
          *ngIf="this.selectedClient.enableWeightLog"
          [selectedClient]="this.selectedClient"
        >
        </app-coach-dashboard-data-table-panel>
      </div>
      <app-coach-dashboard-check-in-wlog-panel
        *ngIf="this.selectedClient.enableWeightLog"
        [selectedClient]="this.selectedClient"
      ></app-coach-dashboard-check-in-wlog-panel>

      <app-training-log-panel-dashboard
        *ngIf="
          this.selectedClient.enableTraining &&
          this.selectedClient.traininglogIds
        "
        class="flex w-full pt-4"
        [selectedClient]="this.selectedClient"
      ></app-training-log-panel-dashboard>
    </div>
  `,
  styles: [],
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate(
          '300ms ease-in-out',
          style({ opacity: 0, transform: 'translateX(100%)' }),
        ),
      ]),
    ]),
  ],
})
export class CoachDashboardV2Component implements OnInit, OnDestroy {
  userSubscription: Subscription | undefined;
  clientIdDatabase: string[] = [];
  coach: AppUser | undefined;
  selectedDay: Date | undefined;
  checkInAmount = 0;

  clientDatabase: AppUser[] = [];
  fliteredClientesForSelectedDay: AppUser[] = [];
  clientToggler: boolean[] = [];
  displayClients: AppUser[] = [];
  remaingingClients: AppUser[] = [];

  todaysCheckIns: ClientCheckin[] = [];

  selectedClient: AppUser | undefined;
  selectedCheckin: ClientCheckin | undefined;

  private unsubscribe$: Subject<void> = new Subject<void>();
  clientCheckins$: Observable<ClientCheckin[]> | undefined;

  sharedSubscription: Subscription | undefined;
  avatarCheckins$: Observable<ClientCheckin | undefined>[] = [];

  searchNameValue = '';
  filteredSearchClients: AppUser[] = [];

  getClientsFromCoach(coach: AppUser) {
    if (coach && coach.clientIds) {
      const observables: Observable<AppUser>[] = [];
      coach.clientIds.forEach((clientId) => {
        observables.push(
          this.userService.getUserFromDatabase(clientId) as Observable<AppUser>,
        );
      });
      return combineLatest(observables);
    } else {
      return of(null);
    }
  }

  isSlideOver = false;

  run = true;
  n = 0;

  constructor(
    protected auth: AuthService,
    protected userService: UserService,
    private checkInService: CheckinService,
    private sharedService: SharedService,
  ) {
    this.sharedSubscription = this.sharedService.refreshComponent$.subscribe(
      () => {
        if (this.selectedClient) this.getClientCheckin(this.selectedClient);
      },
    );
  }
  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.sharedSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.selectedDay = new Date();
    this.userSubscription = this.auth.appUser$
      .pipe(
        switchMap((user: AppUser | null) => {
          const observables: Observable<AppUser>[] = [];
          if (user) {
            this.coach = user;
            if (user.clientIds && user.clientIds.length > 0) {
              for (const clientid of user.clientIds!) {
                this.clientIdDatabase = user.clientIds as string[];

                observables.push(
                  this.userService.getUserFromDatabase(
                    clientid,
                  ) as Observable<AppUser>,
                );
              }
            }
          }
          return combineLatest(observables);
        }),
      )
      .pipe(
        map((clients: AppUser[]) => {
          return clients
            .filter((client) => client && client.coachId === this.coach!.id)
            .map((client) => {
              let cl = client as AppUser;
              // console.log(cl.birthdate);

              //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();
              if (cl.birthdate) cl.birthdate = convertDateObject(cl.birthdate);

              return cl;
            });
        }),
      )
      .subscribe((clients) => {
        for (const client of clients) {
          //this.getWeightLogData(client, 30);

          if (this.clientDatabase.some((e) => e.id === client.id)) {
            let i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
            this.clientToggler.push(false);
          }
        }
        this.displayClients = this.clientDatabase;
        this.getCheckinAmountForTheDay();
      });
  }

  resetVariables() {
    this.selectedClient = undefined;
    this.clientCheckins$ = undefined;
    this.selectedCheckin = undefined;
  }

  onSelectedClient(client: AppUser) {
    this.resetVariables();

    if (this.selectedClient === client) {
      this.selectedClient = undefined;
    } else {
      this.selectedClient = client;
      this.clientCheckins$ = this.checkInService.getClientCheckins(
        this.selectedClient.id!,
      );
    }
  }

  onLoadHistory(value: boolean) {
    if (value) {
      if (this.selectedClient)
        this.clientCheckins$ = this.checkInService.getAllClientCheckins(
          this.selectedClient.id!,
        );
    } else {
      if (this.selectedClient)
        this.clientCheckins$ = this.checkInService.getClientCheckins(
          this.selectedClient.id!,
        );
    }
  }

  getClientCheckin(client: AppUser) {
    return this.todaysCheckIns.find(
      (checkin) => checkin.clientId === client.id,
    );
  }

  getLatestClientCheckin(client: AppUser) {
    return this.todaysCheckIns.find(
      (checkin) => checkin.clientId === client.id,
    );
  }
  getCheckinAmountForTheDay() {
    this.checkInAmount = 0;
    if (this.selectedDay) {
      const weekdayIndex = getWeekdayIndex(format(this.selectedDay, 'EEEE'));
      this.clientDatabase.forEach((client) => {
        if (client.checkInWeekdayIndex === weekdayIndex) {
          this.checkInAmount++;
        }
      });

      this.filterClientsOnWeekdayIndex(weekdayIndex);
    }
  }

  groupRemainingClients() {
    let remainingGroupedClients: AppUser[][] = [];
    for (let i = 0; i < this.remaingingClients.length; i += 10) {
      remainingGroupedClients.push(this.remaingingClients.slice(i, i + 10));
    }
    return remainingGroupedClients;
  }

  filterClientsOnWeekdayIndex(weekdayIndex: number) {
    this.fliteredClientesForSelectedDay = this.clientDatabase.filter(
      (client) => client.checkInWeekdayIndex === weekdayIndex,
    );
    this.remaingingClients = this.clientDatabase.filter(
      (client) => client.checkInWeekdayIndex !== weekdayIndex,
    );

    /*
    const makeRepeated = (arr: any, repeats: any) =>
      Array.from({ length: repeats }, () => arr).flat();

    this.remaingingClients = makeRepeated(this.remaingingClients, 4);
*/

    this.checkIfClientHasCheckinOnWeekdayIndex(
      this.fliteredClientesForSelectedDay,
      this.selectedDay!,
    );

    this.fliteredClientesForSelectedDay.forEach((client) => {
      const obs: Observable<ClientCheckin | undefined> =
        this.checkInService.getClientLatestCheckin(client.id!);
      this.avatarCheckins$.push(obs);
    });
  }

  checkIfClientHasCheckinOnWeekdayIndex(clients: AppUser[], date: Date) {
    this.fliteredClientesForSelectedDay.forEach((client) => {
      this.checkInService
        .getLatestClientCheckIn(client)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((checkin) => {
          if (checkin && checkin !== null) this.todaysCheckIns.push(checkin);
        });
    });
  }

  onSelectedDay(day: Date) {
    this.resetVariables();
    this.selectedDay = day;
    this.fliteredClientesForSelectedDay = [];
    this.avatarCheckins$ = [];
    this.getCheckinAmountForTheDay();
  }

  toggleSlideOver() {
    this.isSlideOver = !this.isSlideOver;
  }

  log(value: any) {
    if (this.run) {
      console.log(value);
      this.run = false;
    }
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.filteredSearchClients = this.clientDatabase;
    } else {
      this.filteredSearchClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.filteredSearchClients = this.clientDatabase;
    }
  }
}
