import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { format, isSameDay, parse, parseISO, startOfToday } from 'date-fns';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  AppUser,
  Macros,
  Note,
  WeightEntryData,
  calculateKcalFromMacros,
  convertDateObject,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WeightlogService } from 'src/app/services/weightlog.service';
import { Timestamp } from 'firebase/firestore';
import { animate, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { UntypedFormArray } from '@angular/forms';
import { SetMacrosDatepickerModalComponent } from 'src/app/common/set-macros-datepicker-modal/set-macros-datepicker-modal.component';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { NoteService } from 'src/app/services/note.service';
import { U } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-coach-weightlogs',
  template: `
    <div class="py-4">
      <div>
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Client Name..."
          />
        </div>
      </div>
    </div>

    <ul role="list" class="space-y-3">
      <li
        *ngFor="let client of displayClients; let i = index"
        class="overflow-hidden bg-white  py-4 shadow rounded-md px-6"
      >
        <div class="flex w-full justify-between">
          <div class="flex w-2/6 justify-start items-center">
            <img
              class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
              src="{{
                client.imgUrl || '.././assets/images/default-person-image.webp'
              }}"
            />
            <h3 class="pl-2">{{ client.displayName }}</h3>
            <span class="italic text-sm text-gray-400 pl-2 align-bottom">{{
              client.email
            }}</span>
          </div>
          <div class="flex flex-col">
            <app-set-macros-module [client]="client"></app-set-macros-module>
            <app-set-off-day-macros-module-coach
              [client]="client"
            ></app-set-off-day-macros-module-coach>
          </div>
          <div
            class="flex text-gray-500 items-center

          "
          >
            <div
              (click)="onOpenClient(client, i)"
              class="w-fit h-fit p-2 border-2 rounded-lg border-gray-500 hover:bg-gray-200 cursor-pointer"
              [ngClass]="{
                'bg-gray-200': selectedClientIndex === i,
                'bg-white': selectedClientIndex !== i
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div *ngIf="this.selectedClient === client">
          <app-coach-dashboard-data-table-panel
            *ngIf="this.selectedClient && this.selectedClient.enableWeightLog"
            [selectedClient]="this.selectedClient"
          >
          </app-coach-dashboard-data-table-panel>
        </div>
      </li>

      <!-- More items... -->
    </ul>

    <div
      *ngIf="macroModaltrigger"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-set-macros-modal
        @AnimationTrigger1
        [client]="selectedClient"
        (onClick)="onSetUserMacros($event)"
        *ngIf="macroModaltrigger"
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
      ></app-set-macros-modal>
    </div>
  `,
  styles: [],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class CoachWeightlogsComponent implements OnInit, OnDestroy {
  user: AppUser = {};
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];

  clientToggler: boolean[] = [];
  selectedClient: AppUser | undefined;
  macroModaltrigger: boolean = false;
  weightLogSubscription: Subscription | undefined;
  selectedDate = new Date();

  userSubscription: Subscription | undefined;

  macroDateModaltrigger: boolean = false;
  run = true;

  selectedClientIndex: number | undefined = undefined;

  searchNameValue = '';

  constructor(
    protected auth: AuthService,
    private weightLogService: WeightlogService,
    protected userService: UserService,
    private cdr: ChangeDetectorRef,
  ) {}

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$
      .pipe(
        switchMap((user: AppUser | null) => {
          const observables: Observable<AppUser>[] = [];
          if (user) {
            this.user = user;
            if (this.user.clientIds && this.user.clientIds.length > 0) {
              for (const clientid of user.clientIds!) {
                this.clientIdDatabase = user.clientIds as string[];

                observables.push(
                  this.userService.getUserFromDatabase(
                    clientid,
                  ) as Observable<AppUser>,
                );
              }
            }
          }

          return combineLatest(observables);
        }),
      )
      .pipe(
        map((clients: AppUser[]) => {
          return clients
            .filter((client) => client && client.coachId === this.user.id)
            .map((client) => {
              let cl = client as AppUser;
              // console.log(cl.birthdate);

              //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();
              if (cl.birthdate) cl.birthdate = convertDateObject(cl.birthdate);

              return cl;
            });
        }),
      )
      .subscribe((clients) => {
        for (const client of clients) {
          //this.getWeightLogData(client, 30);

          if (this.clientDatabase.some((e) => e.id === client.id)) {
            let i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
            this.clientToggler.push(false);
          }
        }

        this.displayClients = this.clientDatabase;
      });

    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }

  checkForMacros(logs: WeightEntryData[]) {
    if (logs.some((e) => e.achivedMacros)) {
      return true;
    } else {
      return false;
    }
  }

  onSetMacrosForDate(event: Date) {
    this.selectedDate = event;
    this.macroDateModaltrigger = !this.macroDateModaltrigger;
  }

  checkForSetMacrosInLog(log: WeightEntryData[]) {
    return !!log?.find(
      (data) =>
        isSameDay(data.date as Date, this.selectedDate as Date) &&
        data.setMacros,
    );
  }

  onDeleteSetMacros(event: boolean) {
    this.macroDateModaltrigger = !this.macroDateModaltrigger;
  }

  onOpenClient(client: AppUser, index: number) {
    if (client === this.selectedClient) {
      this.selectedClient = undefined;
      this.selectedClientIndex = undefined;
    } else {
      this.selectedClient = client;
      this.selectedClientIndex = index;
    }
  }

  onSetMacrosModal(index: number, client: AppUser) {
    this.selectedClient = client;
    this.macroModaltrigger = !this.macroModaltrigger;
  }

  onSetUserMacros(event: boolean) {
    if (!event) return;
    if (this.selectedClient) this.userService.saveUserData(this.selectedClient);
  }

  onSetRefeedMacros(event: WeightEntryData | boolean) {
    if (typeof event !== 'boolean') {
      if (this.selectedClient)
        this.weightLogService.addSpecificParametersToLog(
          this.selectedClient.id as string,
          event as WeightEntryData,
        );
    }

    // console.log(event);
    this.cdr.detectChanges();
    this.macroDateModaltrigger = !this.macroDateModaltrigger;
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }
}
