import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-modal-weight-entry',
  template: `
               <div
                  class="flex w-full"
                  [ngClass]="{
                    'justify-between': selectedWeightEntry.weight,
                    'justify-end': !selectedWeightEntry.weight
                  }"
                >
                  <div
                    class="flex w-full justify-between items-center"
                    *ngIf="selectedWeightEntry.weight"
                  >
                    <div class="flex w-4/5 flex-col">
                      <div class="flex items-center space-x-3 text-xs">
                        <dt class="">
                          <div
                            class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                          >
                            <img
                              src="../../assets/icons/maintain-weight-icon.png"
                            />
                          </div>
                        </dt>
                        <dd>
                          {{ selectedWeightEntry.weight | number : '1.1-2' }} kg
                        </dd>
                      </div>
                      <div
                        class="mt-2 flex items-center space-x-3 pr-4"
                        *ngIf="selectedWeightEntry.comment !== ''"
                      >
                        <dt class="">
                          <div
                            class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                          >
                            <img
                              src="../../assets/icons/comment-bubble-icon.png"
                            />
                          </div>
                        </dt>
                        <dd class="flex text-justify text-xs">
                          <div class="">
                            {{ selectedWeightEntry.comment }}
                          </div>
                        </dd>
                      </div>
                    </div>

                      <button
                        type="button"
                       (click)="
                          onDeleteWeightEntry(); $event.stopPropagation()
                        "
                        class="w-fit mr-4 h-fit rounded-md bg-red-600 py-0.5 px-0.5 text-sm font-semibold text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                   <button
                      type="button"
                      (click)="
                        toggleWeightEntryMenu(); $event.stopPropagation()
                      "
                      class="w-8 h-8 rounded-md bg-teal-600 py-1 px-1 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"

                    >
                      <img
                        class="w-full h-auto invert"
                        src="../../assets/icons/maintain-weight-icon.png"
                      />
                    </button>
                  </div>
                   <button
                    *ngIf="!selectedWeightEntry.weight"
                    type="button"
                    (click)="toggleWeightEntryMenu(); $event.stopPropagation()"
                    class="w-8 h-8 rounded-md bg-teal-600 py-1 px-1 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-full h-auto invert"
                      src="../../assets/icons/body-weight-scales-icon.png"
                    />
                  </button>
              </div>
                
`,
  styles: [
  ]
})
export class ModalWeightEntryComponent {
  @Input() selectedWeightEntry!: WeightEntryData;
  @Output() showWeightEntryModal: EventEmitter<boolean> = new EventEmitter();
  @Output() deleteWeightEntry: EventEmitter<boolean> = new EventEmitter();


  toggleWeightEntryMenu() {
    this.showWeightEntryModal.emit(true)
  }

  onDeleteWeightEntry() {
    this.deleteWeightEntry.emit(true)
  }

}
