import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Macros, Specification, WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-macro-entry-modal',
  template: `
    <div class="flex justify-center flex-col pt-6 items-center">
      <form
        [formGroup]="achievedMacrosForm"
        (ngSubmit)="onSubmit()"
        class="flex flex-col w-full h-full justify-center items-center"
      >
        <div class="w-5/6 flex justify-center flex-col items-center">
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
          >
            <!-- Heroicon name: outline/check -->
            <img src="../../assets/icons/macros-icon-V4-checked.png" />
          </div>
          <h3
            class="text-base my-2 font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Achieved Macros for {{ day | date: 'dd.MM.yyyy' }}
          </h3>

          <div class="mt-4 flex flex-col text-center w-full">
            <div class="flex flex-row justify-evenly w-full">
              <div class="flex flex-col justify-center items-center">
                <span>Protein</span>
                <div class="relative rounded-md shadow-sm w-16">
                  <input
                    type="text"
                    formControlName="protein"
                    inputmode="decimal"
                    id="protein"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                    [placeholder]="selectMacros('protein') || '50'"
                    aria-describedby="protein-input"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <span class="text-gray-500 " id="unit-suffix">g</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-center items-center">
                <span>Carbs</span>
                <div class="relative  rounded-md shadow-sm w-16">
                  <input
                    type="text"
                    inputmode="decimal"
                    formControlName="carbs"
                    id="carbs"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                    [placeholder]="selectMacros('carbs') || '50'"
                    aria-describedby="carbs-input"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <span class="text-gray-500 " id="unit-suffix">g</span>
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-center items-center">
                <span>Fat</span>
                <div class="relative rounded-md shadow-sm w-16">
                  <input
                    type="text"
                    inputmode="decimal"
                    formControlName="fat"
                    id="fat"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                    [placeholder]="selectMacros('fat') || '50'"
                    aria-describedby="fat-input"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <span class="text-gray-500 " id="unit-suffix">g</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-around w-5/6 mt-2 pb-4">
          <button
            [disabled]="!this.achievedMacrosForm.valid"
            id="btn-save"
            type="submit"
            class="mt-3 inline-flex w-fit disabled:opacity-25 justify-center rounded-md border border-gray-300 bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-400 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Save
          </button>

          <button
            (click)="onClickEvent(false)"
            type="button"
            id="btn-cancel"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class MacroEntryModalComponent implements OnInit {
  @Input() day?: Date;
  @Input() weightEntry!: WeightEntryData | undefined;
  @Input() lastweightEntry?: WeightEntryData | undefined;
  @Input() todaysSpec?: Specification | undefined;
  @Output() onClick = new EventEmitter<boolean>();
  @Output() onSaveData = new EventEmitter<WeightEntryData>();

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    //console.log(this.day, this.weightEntry);
    if (this.weightEntry) this.patchForm(this.weightEntry);
  }

  achievedMacrosForm = this.fb.group({
    protein: ['', [Validators.required]],
    carbs: ['', [Validators.required]],
    fat: ['', [Validators.required]],
  });

  onClickEvent(value: boolean) {
    this.onClick.emit(value);
  }

  selectMacros(macroName: string) {
    if (
      this.todaysSpec &&
      this.todaysSpec.setMacros &&
      this.todaysSpec.specMacros
    ) {
      return this.macroSelection(this.todaysSpec.specMacros, macroName);
    } else if (this.weightEntry?.offDayMacros) {
      return this.macroSelection(this.weightEntry.offDayMacros, macroName);
    } else if (this.lastweightEntry?.achivedMacros) {
      return this.macroSelection(this.lastweightEntry.achivedMacros, macroName);
    } else if (this.weightEntry?.setMacros) {
      return this.macroSelection(this.weightEntry.setMacros, macroName);
    } else {
      return undefined;
    }
  }

  macroSelection(macros: Macros | undefined, macroName: string | undefined) {
    if (!macros || !macroName) return undefined;

    if (macroName.includes('protein')) {
      return macros.protein;
    } else if (macroName.includes('carbs')) {
      return macros.carbs;
    } else if (macroName.includes('fat')) {
      return macros.fat;
    } else {
      return undefined;
    }
  }

  patchForm(data: WeightEntryData) {
    if (
      data &&
      data.achivedMacros &&
      data.achivedMacros?.protein &&
      data.achivedMacros.carbs &&
      data.achivedMacros.fat
    ) {
      this.achievedMacrosForm
        .get('protein')
        ?.patchValue(data.achivedMacros.protein.toString());
      this.achievedMacrosForm
        .get('carbs')
        ?.patchValue(data.achivedMacros.carbs.toString());
      this.achievedMacrosForm
        .get('fat')
        ?.patchValue(data.achivedMacros.fat.toString());
    }
  }

  onSubmit() {
    let tempProt = 0;
    let tempCarb = 0;
    let tempFat = 0;

    if (this.achievedMacrosForm.get('protein')!.value!.includes(',')) {
      tempProt = Number(
        this.achievedMacrosForm.get('protein')!.value!.replace(',', '.'),
      );
    } else {
      tempProt = Number(this.achievedMacrosForm.get('protein')!.value!);
    }

    if (this.achievedMacrosForm.get('carbs')!.value!.includes(',')) {
      tempCarb = Number(
        this.achievedMacrosForm.get('carbs')!.value!.replace(',', '.'),
      );
    } else {
      tempCarb = Number(this.achievedMacrosForm.get('carbs')!.value!);
    }

    if (this.achievedMacrosForm.get('fat')!.value!.includes(',')) {
      tempFat = Number(
        this.achievedMacrosForm.get('fat')!.value!.replace(',', '.'),
      );
    } else {
      tempFat = Number(this.achievedMacrosForm.get('fat')!.value!);
    }

    if (this.weightEntry)
      this.weightEntry.achivedMacros! = {
        protein: tempProt,
        carbs: tempCarb,
        fat: tempFat,
      };

    this.onSaveData.emit(this.weightEntry);
  }
}
