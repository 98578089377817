import { Duration } from './../session-rating-modal/session-rating-modal.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  parse,
  startOfToday,
  format,
  add,
  isToday,
  isEqual,
  isSameMonth,
  isSameDay,
  intervalToDuration,
  addDays,
} from 'date-fns';
import { ca } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import { forEach } from 'lodash';
import {
  Observable,
  Subscription,
  combineLatest,
  forkJoin,
  mergeMap,
} from 'rxjs';
import {
  CalendarEventData,
  MesoCycle,
  TemplateTrainingDay,
  TrainingLog,
  WeightEntryData,
  convertDateObject,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { LogService } from 'src/app/services/log.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-calendar-v2',
  template: `
    <div *ngIf="auth.appUser$ | async as appUser" class="relative w-full">
      <div class="flex flex-col items-center fixed h-[80%] w-[92.5%] md:w-4/6 md:left-[25%]">
        <div class="w-full  h-full">
          <div
            class="h-full mt-2 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9"
          >
            <div class="flex items-center text-gray-900">
              <button
                type="button"
                (click)="onChangeMonth(-1)"
                class="ml-4 -m-1.5 ring-2 ring-gray-500 rounded-md flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">Previous month</span>
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div class="flex-auto text-sm font-semibold">
                {{ currentMonth }}
              </div>
              <button
                type="button"
                (click)="onChangeMonth(1)"
                class="mr-4 -m-1.5 ring-2 ring-gray-500 rounded-md  flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">Next month</span>
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div class="mt-4 grid grid-cols-7 text-xs leading-6 text-gray-600">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div
              class="isolate mt-2 flex flex-wrap grid h-[100%] grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
            >
              <!--
          Always include: "py-1.5 hover:bg-gray-100 focus:z-10"
          Is current month, include: "bg-white" X
          Is not current month, include: "bg-gray-50" X
          Is selected or is today, include: "font-semibold"
          Is selected, include: "text-white"
          Is not selected, is not today, and is current month, include: "text-gray-900"
          Is not selected, is not today, and is not current month, include: "text-gray-400"
          Is today and is not selected, include: "text-teal-600"

          Top left day, include: "rounded-tl-lg"
          Top right day, include: "rounded-tr-lg"
          Bottom left day, include: "rounded-bl-lg"
          Bottom right day, include: "rounded-br-lg"
        -->

              <!--
            Always include: "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
            Is selected and is today, include: "bg-teal-600"
            Is selected and is not today, include: "bg-gray-900"
          -->

              <button
                *ngFor="let day of generatedDays; let i = index"
                type="button"
                (click)="onSelectDay(day)"
                class="flex flex-col  py-0.5 px-0.5  text-gray-900 lg:hover:bg-teal-100 lg:hover:opacity-30  focus:z-10"
                [ngClass]="{
                  'text-gray-900':
                    isCurrentMonth(day) && !isToday(day) && isEqual(day),
                  'text-gray-400':
                    !isCurrentMonth(day) && !isToday(day) && !isEqual(day),
               
                  'bg-gray-100': !isCurrentMonth(day) && !isEqual(day),
                  'bg-white': isCurrentMonth(day) && !isEqual(day) && !isSameDay(day, selectedDay),
                  'bg-teal-50 ':
                    isCurrentMonth(day) && isSameDay(day, selectedDay),
                }"
              >
                <div
                  class="rounded-full w-full flex justify-center items-center text-center"
                  [ngClass]="{
                    'bg-gray-100': !isCurrentMonth(day) && !isEqual(day),

                    'font-semibold bg-teal-500 text-white ring-2 ring-teal-100':
                      isCurrentMonth(day) && isSameDay(day, selectedDay),
                      '  font-bold text-slate-50 bg-teal-600':
                      isCurrentMonth(day) && isToday(day),

                  }"
                >
                  <time
                    datetime="2022-01-21"
                    class="mx-auto flex items-center h-5 w-5 items-top justify-center rounded-full"
                    >{{ day | date : 'dd' }}</time
                  >
                </div>

                <div
                  class="flex flex-col gap-y-0.5 w-full h-full pt-2 justify-start"
                >
                  <div
                    *ngIf="onWeightEntryOnDay(day)"
                    class="flex flex-row w-full justify-center text-xs bg-amber-200 rounded-md"
                  >
                    {{ getWeighEntryOnDay(day) }}kg
                  </div>

                  <div class="flex w-full">
                    <div
                      *ngIf="onMacrosEntryOnDay(day)"
                      class="flex flex-row w-1/2 justify-center text-xs bg-green-200 rounded-md"
                    >
                      <img
                        class="w-4 h-4 "
                        src="../../assets/icons/macros-icon-V4-checked.png"
                      />
                    </div>
                    <div
                      *ngIf="onCustomModelEntryOnDay(day)"
                      class="flex flex-row w-1/2 justify-center text-xs bg-blue-200 rounded-md"
                    >
                      <img
                        class="w-4 h-4 "
                        src="../../assets/icons/3d-modeling-icon-checked.png"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="onTrainingLogOnDay(day)"
                    class="flex flex-row w-full justify-center text-xs bg-cyan-200 rounded-md"
                  >
                    {{ getTrainingDayOnDay(day)?.trainingDayName }}
                  </div>
                </div>

                <!-- Display events for the current day -->
                <div *ngIf="calendarEntriesDatabase" class="w-full ">
                  <div
                    *ngFor="let event of calendarEntriesDatabase"
                    class="w-full py-0.5"
                  >
                    <div
                      *ngIf="
                        isSameDay(event.start!, day) ||
                        checkInBetweenDates(event.start!, event.end!, day)
                      "
                      class="w-full "
                    >
                      <!-- Render event details here -->
                      <div
                        class="flex flex-row w-full justify-center text-xs  bg-purple-200 whitespace-nowrap rounded-md "
                        [ngClass]="{
                    
                    'border-2 border-purple-400 ':  isSameDay(event.start!, day),
           
                 
                  }"
                      >
                        {{ event.title }}
                      </div>
                      <!-- You can add more event details as needed -->
                    </div>
                  </div>
                </div>

                <!--
                <div
                  *ngIf="onEventEntryOnDay(day)"
                  class="flex flex-row w-full "
                >
                  <div
                    class="flex flex-row w-full justify-center text-xs  bg-purple-200 whitespace-nowrap "
                    [ngClass]="{
                    '  w-[130%] -ml-4': getDuration(day) === 2 && endDateIsSameDay(day),

                    'relative w-[135%] -ml-2':
                      checkDayInBetween(day) && edgeCasesForCalendar(i),
                    'rounded-l-md ': startDateIsSameDay(day),
                    'rounded-r-md': endDateIsSameDay(day),
                    'z-20 pl-4 ': setTitleToFront(day),
                 
                  }"
                    [innerHTML]="displayTitle(day, i)"
                  ></div> 
                </div>
                -->
              </button>
            </div>
            <div class="flex flex-row w-full mt-4"></div>
          </div>
        </div>
        <button
          type="button"
          class="absolute z-20 -bottom-[4.25rem] -right-2 rounded-full bg-teal-600 p-2 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          (click)="toggleNewCalendarEvent()"
        >
          <svg
            class="h-8 w-8"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
            />
          </svg>
        </button>
      </div>

      <div *ngIf="toggleOpenDay">
        <app-calendar-day-modal
          [user]="appUser"
          [inputDate]="selectedDay"
          [inputWeightEntry]="selectedWeightEntry"
          [inputTrainingDay]="selectedTrainingDay"
          [inputEventsData]="selectedEvents"
          (close)="onModalClose($event)"
        ></app-calendar-day-modal>
      </div>

      <div *ngIf="newCalendarEventToggle">
        <app-calendar-new-entry-modal
          [inputDate]="selectedDay"
          (close)="toggleNewCalendarEvent()"
          (save)="onSaveCalendarEvent($event)"
        ></app-calendar-new-entry-modal>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate(
          '75ms ease-in',
          style({ opacity: 0, transform: 'scale(0.95)' })
        ),
      ]),
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class CalendarV2Component implements OnInit, OnDestroy {
  generatedDays: Date[] = [];
  today = startOfToday();
  selectedDay = this.today;
  currentMonth = format(this.today, 'MMMM - yyyy');
  firstDayOfCurrentMonth = parse(this.currentMonth, 'MMMM - yyyy', new Date());

  toggleOpenDay = false;

  weightLogSubscription: Subscription | undefined;
  weighEntryDatabase: WeightEntryData[] = [];
  selectedWeightEntry: WeightEntryData | undefined;

  trainingLogSubscription: Subscription | undefined;
  loadedTrainingLog: TrainingLog | undefined;
  selectedTrainingDay: TemplateTrainingDay | undefined;

  newCalendarEventToggle = false;
  calendarEntriesDatabase: CalendarEventData[] = [];
  calendarEntrySubscription: Subscription | undefined;
  selectedEvents: CalendarEventData[] | undefined = [];

  constructor(
    protected auth: AuthService,
    private weightLogService: WeightlogService,
    private trainingLogService: LogService,
    private calendarService: CalendarService
  ) { }
  ngOnDestroy(): void {
    this.weightLogSubscription?.unsubscribe();
    this.trainingLogSubscription?.unsubscribe();
    this.calendarEntrySubscription?.unsubscribe();
    this.calendarEntrySubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  ngOnInit(): void {
    this.createDates();
    this.loadWeightLogData();
    this.loadTraininglogData();
  }

  createDates() {
    this.generatedDays = [];
    this.generatedDays = eachDayOfInterval({
      start: startOfWeek(this.firstDayOfCurrentMonth, { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(this.firstDayOfCurrentMonth), {
        weekStartsOn: 1,
      }),
    });
    this.loadCalendarEntriesNew();
  }

  onChangeMonth(amount: number) {
    this.firstDayOfCurrentMonth = parse(
      this.currentMonth,
      'MMMM - yyyy',
      new Date()
    );

    this.firstDayOfCurrentMonth = add(this.firstDayOfCurrentMonth, {
      months: amount,
    });
    this.currentMonth = format(this.firstDayOfCurrentMonth, 'MMMM - yyyy');
    this.createDates();
  }

  isToday(date: Date): boolean {
    return isToday(date);
  }

  isSameDay(startDate: Date, endDate: Date): boolean {
    return isSameDay(startDate, endDate);
  }

  isCurrentMonth(date: Date): boolean {
    return isSameMonth(date, this.firstDayOfCurrentMonth);
  }

  isEqual(date: Date) {
    return isEqual(date, this.selectedDay);
  }

  onSelectDay(date: Date): void {
    this.selectedDay = date;
    this.selectedWeightEntry = this.findWeighEntrybasedOnWeightLog(date);
    this.selectedTrainingDay = this.getTrainingDayOnDay(date);
    this.selectedEvents = this.findAllEntriesForDate(date);
    this.toggleOpenDay = !this.toggleOpenDay;
  }

  onModalClose(event: boolean) {
    if (event) this.loadCalendarEntriesNew();

    this.toggleOpenDay = !this.toggleOpenDay;
  }

  loadWeightLogData(): void {
    this.weightLogSubscription = this.weightLogService
      .getWeightLogForClientOfXDaysFuture(35)
      .subscribe((data) => {
        data.forEach((item) => {
          if (item.date) {
            item.date = convertDateObject(item.date);
          }
        });

        this.weighEntryDatabase = data;
      });
  }

  findWeighEntrybasedOnWeightLog(date: Date) {
    return this.weighEntryDatabase.find((weightEntry) =>
      isSameDay(weightEntry.date as Date, date)
    );
  }

  onWeightEntryOnDay(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.weight;
    });
  }

  getWeighEntryOnDay(day: Date) {
    const weightEntry = this.weighEntryDatabase.find((weightEntry) =>
      isSameDay(weightEntry.date as Date, day)
    );

    if (weightEntry) {
      return weightEntry.weight?.toString();
    } else {
      return null;
    }
  }

  loadTraininglogData() {
    this.trainingLogSubscription = this.trainingLogService
      .getLatestLogForCurrentUser()
      .subscribe((trainingLog) => {
        if (trainingLog) {
          this.loadedTrainingLog = trainingLog;
        }
      });
  }

  onTrainingLogOnDay(date: Date) {
    if (!this.loadedTrainingLog || !this.loadedTrainingLog.mesoCycle) {
      return false;
    }

    return this.loadedTrainingLog.mesoCycle.some((meso) =>
      meso.trainingDays?.some((td) => isSameDay(td.startDate as Date, date))
    );
  }

  getTrainingDayOnDay(date: Date) {
    if (!this.loadedTrainingLog || !this.loadedTrainingLog.mesoCycle) {
      return undefined;
    }

    for (const meso of this.loadedTrainingLog.mesoCycle) {
      const trainingDay = meso.trainingDays?.find((td) =>
        isSameDay(td.startDate as Date, date)
      );

      if (trainingDay) {
        return trainingDay;
      }
    }

    return undefined;
  }

  toggleNewCalendarEvent() {
    this.newCalendarEventToggle = !this.newCalendarEventToggle;
  }

  onSaveCalendarEvent(event: CalendarEventData) {
    if (event) {
      if (!event.id) {
        this.calendarService.saveNewCalendarEntry(event);
      } else {
        this.calendarService.updateCalendarEntry(event);
      }
    }
  }

  loadCalendarEntries() {
    this.generatedDays.forEach((day) => {
      this.calendarEntrySubscription = this.calendarService
        .getCalendarEntriesPerDay(day)
        .subscribe((data) => {
          console.log(data);
          if (data) {
            data.forEach((entry) => {
              entry.start = convertDateObject(entry.start as Date);
              if (entry.end)
                entry.end = convertDateObject(entry.end as Date);
            });
            this.calendarEntriesDatabase = data;
          }
        });
    });
  }

  private calendarEntrySubscriptions: Subscription[] = [];

  loadCalendarEntriesNew() {
    this.calendarEntrySubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.calendarEntriesDatabase = [];
    this.calendarEntrySubscriptions = [];

    const observables: Observable<CalendarEventData[]>[] = [];

    this.generatedDays.forEach((day) => {
      const observable = this.calendarService.getCalendarEntriesPerDay(day);
      observables.push(observable);
    });

    combineLatest(observables).subscribe(
      (dataArrays) => {
        //console.log(dataArrays); // Debug: Check if combineLatest receives data
        if (dataArrays && dataArrays.length > 0) {
          dataArrays.forEach((innerDataArray) => {
            innerDataArray.forEach((entry) => {
              entry.start = convertDateObject(entry.start as Date);
              if (entry.end) {
                entry.end = convertDateObject(entry.end as Date);
              }
              this.calendarEntriesDatabase.push(entry);

              const mergedObjectsMap = new Map<string, CalendarEventData>();
              for (const obj of this.calendarEntriesDatabase) {
                const existingObj = mergedObjectsMap.get(obj.id as string);

                if (existingObj) {
                  // Merge properties of existing object with current object
                  Object.assign(existingObj, obj);
                } else {
                  // If no object with the same id exists, add the current object
                  mergedObjectsMap.set(obj.id as string, obj);
                }
              }

              this.calendarEntriesDatabase = Array.from(
                mergedObjectsMap.values()
              );

              this.sortByDuration(this.calendarEntriesDatabase);
            });
          });
        }
      },
      (error) => {
        console.error('CombineLatest Error:', error); // Debug: Check for combineLatest errors
      }
    );
  }

  sortByDuration(array: CalendarEventData[]) {
    function compareByDuration(a: CalendarEventData, b: CalendarEventData) {
      if (a.start && a.end && b.start && b.end) {
        const durationA = a.end.getTime() - a.start.getTime();
        const durationB = b.end.getTime() - b.start.getTime();

        return durationA - durationB;
      }

      return 0;
    }

    array.sort(compareByDuration);
  }

  onEventEntryOnDay(day: Date) {
    return this.calendarEntriesDatabase.some((entry) => {
      if (!entry.start) return null;
      if (entry.end && entry.start) {
        if (
          (isSameDay(entry.end, entry.start) && entry.title) ||
          (this.checkInBetweenDates(entry.start, entry.end, day) &&
            entry.title)
        )
          return true;
      }

      return isSameDay(day, entry.start) && entry.title;
    });
  }

  checkEventWithDate(entry: CalendarEventData, day: Date) {
    if (!entry.start) return null;
    if (entry.end && entry.start) {
      if (
        (isSameDay(entry.end, entry.start) && entry.title) ||
        (this.checkInBetweenDates(entry.start, entry.end, day) &&
          entry.title)
      ) {
        return true;
      } else return false;
    }

    if (isSameDay(day, entry.start) && entry.title) {
      return true;
    } else {
      return false;
    }
  }

  checkDayInBetween(day: Date) {
    return this.calendarEntriesDatabase.some((entry) => {
      if (entry.end && entry.start) {
        if (isSameDay(entry.start, day) && entry.title) return null;
        if (isSameDay(entry.end, day) && entry.title) return null;

        return (
          this.checkInBetweenDates(entry.start, entry.end, day) &&
          entry.title
        );
      }
      return null;
    });
  }

  checkInBetweenDates(startDate: Date, endDate: Date, day: Date) {
    const start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    const target = new Date(day.getFullYear(), day.getMonth(), day.getDate());

    return target >= start && target <= end;
  }

  findCalendarEntry(day: Date): CalendarEventData | undefined {
    const calendarEntry = this.calendarEntriesDatabase.find((entry) => {
      return (
        this.checkInBetweenDates(entry.start!, entry.end!, day) &&
        entry.title
      );
    });

    return calendarEntry;
  }

  findAllEntriesForDate(day: Date): CalendarEventData[] | undefined {
    let calendarEntries: CalendarEventData[] = [];
    this.calendarEntriesDatabase.forEach((entry) => {
      if (this.isSameDay(entry.start!, day) && entry.title) {
        calendarEntries.push(entry);
      }
    });

    return calendarEntries;
  }

  getDuration(day: Date) {
    const calendarEntry = this.findCalendarEntry(day);

    if (calendarEntry && calendarEntry.start && calendarEntry.end) {
      const duration = intervalToDuration({
        start: calendarEntry?.start,
        end: addDays(calendarEntry?.end, 1),
      });
      return duration.days;
    }

    return null;
  }

  displayTitle(day: Date, index: number) {
    const calendarEntry = this.findCalendarEntry(day);

    if (calendarEntry && calendarEntry.start && calendarEntry.end) {
      const duration = intervalToDuration({
        start: calendarEntry?.start,
        end: addDays(calendarEntry?.end, 1),
      });

      const currentStep = intervalToDuration({
        start: addDays(day, 1),
        end: calendarEntry.start,
      });

      if (duration && duration.days && currentStep.days === 1) {
        return calendarEntry.title;
      } else if (currentStep.days === 2) {
        return '&nbsp;';
      } else {
        return currentStep.days?.toString() + '/' + duration.days?.toString();
      }
    }

    return ' ';
  }

  setTitleToFront(day: Date) {
    const calendarEntry = this.findCalendarEntry(day);

    if (calendarEntry && calendarEntry.start && calendarEntry.end) {
      const duration = intervalToDuration({
        start: calendarEntry?.start,
        end: addDays(calendarEntry?.end, 1),
      });

      const currentStep = intervalToDuration({
        start: addDays(day, 1),
        end: calendarEntry.start,
      });

      if (
        duration &&
        duration.days &&
        duration.days > 1 &&
        currentStep.days === 1
      ) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getCalendarEntryOnDay(day: Date) {
    const calendarEntry = this.calendarEntriesDatabase.find((entry) => {
      if (entry.end && entry.start) {
        if (
          (isSameDay(entry.end, entry.start) && entry.title) ||
          (this.checkInBetweenDates(entry.start, entry.end, day) &&
            entry.title)
        )
          return true;
      }

      return isSameDay(day, entry.start!) && entry.title;
    });

    if (calendarEntry) {
      return calendarEntry.title;
    } else {
      return null;
    }
  }

  edgeCasesForCalendar(index: number) {
    return index > 0 && index % 7 !== 0 && index % 7 !== 6;
  }

  startDateIsSameDay(day: Date) {
    const calendarEntry = this.calendarEntriesDatabase.find((entry) => {
      if (entry && entry.start) {
        if (isSameDay(entry.start, day)) return true;
      }
      return null;
    });

    if (calendarEntry && calendarEntry.start) {
      if (isSameDay(calendarEntry.start, day)) return true;

      return false;
    } else {
      return false;
    }
  }

  endDateIsSameDay(day: Date) {
    const calendarEntry = this.calendarEntriesDatabase.find((entry) => {
      if (entry && entry.end) {
        if (isSameDay(entry.end, day)) return true;
      }
      return null;
    });

    if (calendarEntry && calendarEntry.end) {
      if (isSameDay(calendarEntry.end, day)) return true;

      return false;
    } else {
      return false;
    }
  }

  onMacrosEntryOnDay(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.achivedMacros;
    });
  }

  onCustomModelEntryOnDay(day: Date) {
    return this.weighEntryDatabase.some((entry) => {
      if (!entry.date) return null;
      return isSameDay(day, entry.date) && entry.customTrackingLog;
    });
  }
}
