import { MesoCycle } from './../../core/thecoach';
import { TrainingplanService } from './../../services/trainingplan.service';
import { TemplateTrainingplanService } from '../../services/templatetrainingplan.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { parse } from 'date-fns';
import { Subscription, take } from 'rxjs';
import {
  AppUser,
  Exercise,
  TemplateTrainingPlan,
  TrainingPlan,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-trainingplan-assign',
  template: `
    <ul role="list" class="space-y-3">
      <li
        *ngFor="let client of clientDatabase; let i = index"
        class="overflow-hidden bg-white px-4 py-4 shadow rounded-md px-6"
        (click)="onOpenClient(i)"
      >
        <div class="flex w-full justify-around">
          <div class="flex w-fit justify-start items-center">
            <img
              class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
              src="{{ client.imgUrl }}"
            />
            <h3 class="pl-2">{{ client.displayName }}</h3>
            <span class="italic text-sm text-gray-400 pl-2 align-bottom">{{
              client.email
            }}</span>
          </div>

          <div class="flex text-gray-500 items-center">
            <button
              type="button"
              (click)="
                selectTemplateTrainingplan(client); $event.stopPropagation()
              "
              class="rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Generate New Trainningplan
            </button>
          </div>
        </div>
        <div *ngIf="newPlanToggler">
          <div class="px-8">
            <div class="mt-8 flow-root">
              <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="flex w-full align-middle">
                  <div class="relative inline-block text-left">
                    <div>
                      <button
                        type="button"
                        (click)="toggleMenu()"
                        class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                      >
                        {{ defaultValueDropdownTemplates }}
                        <svg
                          class="-mr-1 h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>

                    <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
                    <div
                      @opacityScale
                      *ngIf="isMenu"
                      class="fixed z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabindex="-1"
                    >
                      <div class="py-1" role="none">
                        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                        <a
                          (click)="onGenerateTrainingPlan(tp, client)"
                          *ngFor="let tp of templateTrainingPlans"
                          class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm hover:cursor-pointer"
                          role="menuitem"
                          tabindex="-1"
                          id="menu-item-0"
                          >{{ tp.trainingPlanName }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isMenu"
                    (click)="toggleMenu()"
                    class="fixed inset-0"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4" *ngIf="(this.activePlan | json) != '{}'">
          <div>
            <div class="flex">
              <nav
                class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                aria-label="Tabs"
              >
                <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                <a
                  *ngFor="let meso of this.activePlan.mesoCycles; let i = index"
                  (click)="onSelectMeso(meso, i)"
                  class="text-gray-900 rounded-l-lg group hover:cursor-pointer relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                  aria-current="page"
                >
                  <span>{{ meso.mesoname }}</span>
                  <span
                    aria-hidden="true"
                    class="absolute inset-x-0 bottom-0 h-0.5"
                    [ngClass]="{
                      'bg-teal-500': this.activeMesoIndex === i,
                      'bg-gray-700': this.activeMesoIndex !== i
                    }"
                  ></span>
                </a>
              </nav>
            </div>
          </div>
          <div class="overflow-hidden rounded-lg bg-white shadow">
            <div class="px-4 py-5 sm:p-6">
              <!-- Content goes here -->

              <div
                *ngFor="let trainingDay of this.activeMesoCycle.trainingDays"
              >
                <div class="px-4 sm:px-6 lg:px-8">
                  <div class="mt-8 flow-root">
                    <div
                      class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 w-full"
                    >
                      <div
                        class="flex flex-col py-2 align-middle w-fit sm:px-6 lg:px-8 border rounded-md pb-4"
                      >
                        <h1 class="text-md font-semibold italic text-gray-600">
                          Trainingday: {{ trainingDay.trainingDayName }}
                        </h1>

                        <table
                          class="divide-y divide-gray-300 w-fit table-auto"
                        >
                          <thead>
                            <tr class="divide-x divide-gray-200">
                              <th
                                scope="col"
                                class="py-2 px-2 text-left text-sm font-semibold text-gray-900 w-fit"
                              >
                                Slot
                              </th>
                              <th
                                scope="col"
                                class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-auto"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[7.25rem]"
                              >
                                Desc.
                              </th>
                              <th
                                scope="col"
                                class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                              >
                                Sets
                              </th>
                              <th
                                scope="col"
                                class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[3.5rem]"
                              >
                                Reps
                              </th>
                              <th
                                scope="col"
                                class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                              >
                                RIR
                              </th>
                            </tr>
                          </thead>
                          <tbody class="divide-y divide-gray-200 bg-white">
                            <tr
                              class="divide-x divide-gray-200"
                              *ngFor="
                                let trainingSlot of trainingDay.trainingSlots
                              "
                            >
                              <td
                                class="whitespace-normal py-2 px-2 text-sm font-medium text-gray-900 text-center truncate"
                              >
                                {{ trainingSlot.slot }}
                              </td>
                              <td
                                class="whitespace-nowrap px-2 text-sm text-gray-500 w-auto"
                              >
                                <span
                                  class="input w-full h-full"
                                  role="input"
                                  contenteditable="true"
                                  >{{ trainingSlot.exerciseName }}
                                </span>
                              </td>
                              <td
                                class="whitespace-normal p-2 text-sm text-gray-500 flex flex-row justify-between"
                              >
                                {{
                                  getExerciseDescription(
                                    trainingSlot.exerciseId!
                                  )!.length > 10
                                    ? (getExerciseDescription(
                                        trainingSlot.exerciseId!
                                      ) | slice : 0 : 10) + '...'
                                    : getExerciseDescription(
                                        trainingSlot.exerciseId!
                                      )
                                }}
                                <div class="group flex relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                  </svg>
                                  <span
                                    class="z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-2 opacity-0 m-4 mx-auto"
                                    >{{
                                      getExerciseDescription(
                                        trainingSlot.exerciseId!
                                      )
                                    }}</span
                                  >
                                </div>
                              </td>
                              <td
                                class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                              >
                                {{ trainingSlot.sets }}
                              </td>
                              <td
                                class="whitespace-nowrap py-2 text-sm text-gray-500 text-center"
                              >
                                {{ trainingSlot.reps }}
                              </td>
                              <td
                                class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                              >
                                {{ trainingSlot.rir }}
                              </td>
                            </tr>

                            <!-- More people... -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-4">
            <button
              type="button"
              (click)="assignTrainingPlan(client); $event.stopPropagation()"
              class="rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Assign TP to Client
            </button>

            <button
              type="button"
              (click)="onAddMeso(this.templatePlan); $event.stopPropagation()"
              class="ml-4 rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              +Meso
            </button>
          </div>
        </div>
      </li>

      <!-- More items... -->
    </ul>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class TrainingplanAssignComponent implements OnInit, OnDestroy {
  user: AppUser = {};
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  clientSubscription: Subscription | undefined;
  templateTrainingPlanSubscription: Subscription | undefined;
  exerciseSubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;
  clientToggler: boolean[] = [];
  selectedClient: AppUser = {};
  newPlanToggler = false;
  isMenu = false;
  defaultValueDropdownTemplates = 'Trainingplan Templates';
  activePlan: TrainingPlan = {};
  activeMesoCycle: MesoCycle = {};

  templateTrainingPlans: TemplateTrainingPlan[] = [];
  templatePlan: TemplateTrainingPlan = {};
  exercises: Exercise[] = [];
  activeIndex: number | undefined;
  activeMesoIndex: number | undefined;

  constructor(
    protected auth: AuthService,
    protected userService: UserService,
    private templatetpServcie: TemplateTrainingplanService,
    private exerciseService: ExerciseService,
    private tpService: TrainingplanService
  ) {}

  ngOnDestroy(): void {
    this.clientSubscription?.unsubscribe;
    this.templateTrainingPlanSubscription?.unsubscribe();
    this.exerciseSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    console.log('test?');
    this.userSubscription = this.auth.appUser$
      .pipe()
      .subscribe((appUser) => (this.user = appUser as AppUser));
    if (this.user && this.user.clientIds) {
      this.clientIdDatabase = this.user.clientIds;
      this.getClientData(this.clientIdDatabase);
      this.getTemplateTrainingplans();
      this.getExercises();
    }
  }

  getTemplateTrainingplans() {
    this.templateTrainingPlanSubscription = this.templatetpServcie
      .getAllTrainingplansForUser()
      .subscribe((plans) => {
        this.templateTrainingPlans = plans;
      });
  }

  getClientData(clientIds: string[]) {
    clientIds.forEach((id) => {
      this.clientSubscription = this.userService
        .getUserFromDatabase(id)
        .subscribe((client) => {
          if (client?.coachId === this.user.id) {
            let cl = client as AppUser;
            //console.log(cl.birthdate);

            //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();

            cl.birthdate = parse(
              cl.birthdate as unknown as string,
              'yyyy-MM-dd',
              new Date()
            );

            if (this.clientDatabase.some((e) => e.id === cl.id)) {
              let i = this.clientDatabase.indexOf(cl);
              this.clientDatabase[i] = cl;
            } else {
              this.clientDatabase.push(cl);
              this.clientToggler.push(false);
            }
          }
        });
    });
  }

  getExercises() {
    this.exerciseSubscription = this.exerciseService
      .getAllExercisesForUser()
      .subscribe((data) => {
        this.exercises = data;
      });
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  selectTemplateTrainingplan(client: AppUser) {
    this.newPlanToggler = !this.newPlanToggler;
  }

  onSelectMeso(meso: MesoCycle, index: number) {
    this.activeMesoCycle = meso;

    this.activeMesoIndex = index;
  }

  getExerciseDescription(id: string) {
    const ex = this.exercises.find((e) => e.id === id);
    return ex?.exerciseDescription;
  }

  onGenerateTrainingPlan(tp: TemplateTrainingPlan, client: AppUser) {
    this.toggleMenu();
    this.templatePlan = tp;
    this.activePlan.clientId = client.id;
    this.activePlan.ownerId = tp.ownerId;
    this.activePlan.trainingPlanDescription = tp.trainingPlanDescription;
    this.activePlan.trainingPlanName = tp.trainingPlanName;
    this.activePlan.mesoCycles = [];

    let mesoCycle: MesoCycle = {};

    mesoCycle.mesoname = 'Meso 1';
    mesoCycle.trainingDays = tp.trainingDays;

    this.activePlan.mesoCycles?.push(mesoCycle);

    this.activeMesoCycle = mesoCycle;
    this.defaultValueDropdownTemplates = this.activePlan
      .trainingPlanName as string;

    this.activeMesoIndex = 0;
    //this.activeMesoCycle = this.activePlan.mesoCycles?.at(0)!;
  }

  assignTrainingPlan(client: AppUser) {
    this.tpService.save(this.activePlan, client.id!);
  }

  getClientTrainingPlans(trainingPlans: string[]) {}

  onOpenClient(index: number) {
    this.clientToggler[index] = !this.clientToggler[index];

    if (this.activeIndex === index) {
      this.activeIndex = undefined;
    } else {
      this.activeIndex = index;
    }
  }

  onAddMeso(tp: TemplateTrainingPlan) {
    let mesoCycle: MesoCycle = {};
    mesoCycle.mesoname =
      'Meso ' + (this.activePlan.mesoCycles!.length + 1).toString();
    mesoCycle.trainingDays = tp.trainingDays;

    this.activePlan.mesoCycles?.push(mesoCycle);
  }
}
