import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Observable, Subject, filter, interval, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[hoverOverImg]',
})
export class HoverOverImgDirective {
  @Output() holdTime: EventEmitter<number> = new EventEmitter();

  state: Subject<string> = new Subject();
  cancel: Observable<string>;

  constructor() {
    this.cancel = this.state.pipe(
      filter((v) => v === 'cancel'),
      tap((v) => {
        //   console.log('stopped hold');
        this.holdTime.emit(0);
      })
    );
  }

  @HostListener('mouseleave', ['$event'])
  onExit() {
    this.state.next('cancel');
  }

  @HostListener('mouseover', ['$event'])
  onHold() {
    //  console.log('started Hold');
    this.state.next('start');
    const n = 100;

    interval(n)
      .pipe(
        takeUntil(this.cancel),
        tap((v) => {
          this.holdTime.emit(v * n);
          //    console.log('hold time:', v * n);
        })
      )
      .subscribe();
  }
}
