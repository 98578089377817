import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { add, format, isBefore, isSameDay } from 'date-fns';
import {
  WeightEntryData,
  TemplateTrainingDay,
  durationFromSessionMaster,
  CalendarEventData,
  convertDateObject,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-calendar-new-entry-modal',
  template: ` <div
    @opacity
    *ngIf="isModal"
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
      >
        <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
        <div
          @opacityTranslateY
          *ngIf="isModal"
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
        >
          <div>
            <div class="flex flex-row justify-center items-center">
              <div
                class="flex h-8 w-8 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 stroke-teal-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                  />
                </svg>
              </div>
              <h3
                class="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                New Event
              </h3>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <form
                [formGroup]="newCalEventForm"
                class="flex w-full flex-col gap-y-4"
              >
                <div class="flex w-full">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    formControlName="title"
                    placeholder="Add title"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div class="flex  w-full ">
                  <input
                  
                    type="date"
                    name="start"
                    id="start"
                    formControlName="start"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div class="flex w-full">
                  <input
                    type="date"
                    name="end"
                    id="end"
                    formControlName="end"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div class="flex  w-full ">
                  <textarea
                    name="description"
                    id="description"
                    formControlName="description"
                    placeholder="Description..."
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="flex flex-row justify-between mt-5 sm:mt-6">
            <button
              type="button"
              (click)="toggleModal()"
              class="inline-flex w-fit justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              Cancel
            </button>

            <button
              type="button"
              (click)="saveModal()"
              [disabled]="!newCalEventForm.valid || checkForWrongDateSetup()"
              class="disabled:opacity-25 inline-flex w-fit justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>`,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class CalendarNewEntryModalComponent implements OnInit {
  @Input() inputEnd?: Date;
  @Input() eventEntry?: CalendarEventData;
  @Input() inputDate!: Date;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<CalendarEventData>();

  isModal = true;

  newCalEventForm = this.fb.group({
    id: [''],
    title: ['', [Validators.required]],
    start: ['', [Validators.required]],
    end: ['', Validators.required],
    description: [''],
  });

  constructor(private fb: FormBuilder) { }
  ngOnInit(): void {

    this.newCalEventForm
      .get('start')
      ?.patchValue(format(this.inputDate, 'yyyy-MM-dd'));

    if (!this.inputEnd) {
      this.newCalEventForm
        .get('end')
        ?.patchValue(format(this.inputDate, 'yyyy-MM-dd'));
    } else {

      this.newCalEventForm
        .get('end')
        ?.patchValue(format(this.inputEnd, 'yyyy-MM-dd'));
    }


    if (this.eventEntry) {
      this.newCalEventForm.get('id')?.patchValue(this.eventEntry.id as string);

      this.newCalEventForm
        .get('title')
        ?.patchValue(this.eventEntry.title as string);

      this.newCalEventForm
        .get('description')
        ?.patchValue(this.eventEntry.description as string);

      this.newCalEventForm
        .get('end')
        ?.patchValue(format(this.eventEntry.end as Date, 'yyyy-MM-dd'));

      this.newCalEventForm
        .get('start')
        ?.patchValue(format(this.eventEntry.start as Date, 'yyyy-MM-dd'));

    }
  }

  toggleModal() {
    this.close.emit();
    this.isModal = !this.isModal;
  }

  saveModal() {
    const calEventData = this.convertFormToCalendarEventData(
      this.newCalEventForm
    );

    this.save.emit(calEventData);
    this.isModal = !this.isModal;
  }

  getDurationFromSession(td: TemplateTrainingDay) {
    return durationFromSessionMaster(td);
  }

  convertFormToCalendarEventData(form: FormGroup): CalendarEventData {

    if (!this.eventEntry) {
      const eventData = new CalendarEventData();

      eventData.start = convertDateObject(form.get('start')?.value);
      eventData.end = convertDateObject(form.get('end')?.value);
      eventData.title = form.get('title')?.value;
      eventData.description = form.get('description')?.value;

      if (!eventData.description) eventData.description = "";

      return eventData;
    } else {
      this.eventEntry.start = convertDateObject(form.get('start')?.value);
      this.eventEntry.end = convertDateObject(form.get('end')?.value);
      this.eventEntry.title = form.get('title')?.value;
      this.eventEntry.description = form.get('description')?.value;

      if (!this.eventEntry.description) this.eventEntry.description = ''

      return this.eventEntry;
    }

  }

  checkForWrongDateSetup() {
    const startDate = convertDateObject(this.newCalEventForm.get('start')?.value as string)
    const endDate = convertDateObject(this.newCalEventForm.get('end')?.value as string)

    if (isBefore(endDate as Date, startDate as Date)) {
      return true
    } else {
      return false
    }
  }
}
