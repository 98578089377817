import { Component, Input } from '@angular/core';
import { RequiredValidator } from '@angular/forms';
import {
  AppUser,
  MacroSelector,
  MacroType,
  Macros,
  WeightEntryData,
  calcuclateDailydifferenceMaster,
  calcuclateSevenDayMacro,
  calculateAvgNumeric,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateKcalFromMacros,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
  calculateSevenDayAverageMaster,
} from 'src/app/core/thecoach';

@Component({
  selector: '[app-weightlog-custom-average-dashboard]',
  template: `
    <tr
      class="divide-x divide-gray-300 border-dashed border-2 border-gray-600"
      *ngIf="weightEntry"
      [ngClass]="{
        'bg-gray-200': j % 7 === 0
      }"
    >
      <td
        colspan="2"
        class="whitespace-nowrap w-[5.25rem] text-center py-0.5 pl-4 pr-1 text-sm font-medium text-gray-900 sm:pl-0"
      >
        Average Values
      </td>

      <td
        class="whitespace-nowrap min-w-[2.75rem] text-center py-0.5 px-1 text-sm text-gray-500"
      ></td>
      <td
        colspan="2"
        class="whitespace-nowrap min-w-[4rem] text-center py-0.5 px-1 text-sm text-gray-500"
      ></td>
      <td
        colspan="2"
        class="whitespace-nowrap min-w-[4rem] text-center py-0.5 px-1 text-sm text-gray-500"
      ></td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{
          calculateAvgSevenDayMacro(macroType.SetMacros, macroSelector.Protein)
        }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateAvgSevenDayMacro(macroType.SetMacros, macroSelector.Carb) }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateAvgSevenDayMacro(macroType.SetMacros, macroSelector.Fat) }}
      </td>

      <td
        class="whitespace-nowrap w-[3rem] text-center py-0.5 px-1 text-sm text-gray-500"
        *ngIf="weightEntry.refeedMacros; else setMacros"
      >
        {{
          calculateAvgSevenDayMacro(macroType.RefeedMacros, macroSelector.Kcal)
        }}
      </td>
      <ng-template #setMacros>
        <td
          class="whitespace-nowrap min-w-[3rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{
            calculateAvgSevenDayMacro(macroType.SetMacros, macroSelector.Kcal)
          }}
        </td>
      </ng-template>

      <ng-container *ngIf="client.enableOffDayMacros">
        <td
          class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{
            calculateAvgSevenDayMacro(
              macroType.OffdayMacros,
              macroSelector.Protein
            )
          }}
        </td>
        <td
          class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{
            calculateAvgSevenDayMacro(
              macroType.OffdayMacros,
              macroSelector.Carb
            )
          }}
        </td>
        <td
          class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{
            calculateAvgSevenDayMacro(macroType.OffdayMacros, macroSelector.Fat)
          }}
        </td>

        <td
          class="whitespace-nowrap text-center min-w-[3rem] py-0.5 px-1 text-sm text-gray-500"
        >
          {{
            calculateAvgSevenDayMacro(
              macroType.OffdayMacros,
              macroSelector.Kcal
            )
          }}
        </td>
      </ng-container>

      <td
        class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{
          calculateAvgSevenDayMacro(
            macroType.AchievedMacros,
            macroSelector.Protein
          )
        }}
      </td>
      <td
        class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{
          calculateAvgSevenDayMacro(
            macroType.AchievedMacros,
            macroSelector.Carb
          )
        }}
      </td>
      <td
        class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{
          calculateAvgSevenDayMacro(macroType.AchievedMacros, macroSelector.Fat)
        }}
      </td>

      <td
        class="whitespace-nowrap text-center min-w-[3rem] py-0.5 px-1 text-sm text-gray-500"
      >
        {{
          calculateAvgSevenDayMacro(
            macroType.AchievedMacros,
            macroSelector.Kcal
          )
        }}
      </td>

      <td
        *ngIf="client.enableTraining"
        class="whitespace-nowrap text-center min-w-[5rem] py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.sessionName }}
      </td>
      <ng-container *ngIf="displayCTV && getCustomTrackingLog()">
        <td
          class="whitespace-wrap px-1 text-xs text-gray-500 text-center"
          *ngFor="let cct of getCustomTrackingLog(); let j = index"
        >
          <div *ngIf="cct.type !== 'boolean'">
            <div
              *ngIf="
                cct.type === 'textShort' || cct.type === 'textLong';
                else number
              "
            >
              <div
                class="group flex relative items-center pl-0.5"
                *ngIf="cct.value"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>

                <span
                  class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute
    -translate-x-[100%] translate-y-4 opacity-0 m-4 mx-auto"
                  >{{ cct.value }}</span
                >
              </div>
            </div>
            <ng-template #number>
              {{ calculateAvgSevenDayNumeric(j) }}
            </ng-template>
          </div>

          <div *ngIf="cct.type === 'boolean'">
            <div *ngIf="cct.value === true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-green-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div *ngIf="cct.value === false || !cct.value">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-red-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div>
        </td>
      </ng-container>
    </tr>
  `,
  styles: [],
})
export class WeightlogCustomAverageDashboardComponent {
  @Input({ required: true }) weightEntry!: WeightEntryData | null;
  @Input({ required: true }) weightLogData!: WeightEntryData[] | null;
  @Input() displayCTV!: boolean;
  @Input() client!: AppUser;
  @Input() j!: number;

  macroSelector = MacroSelector;
  macroType = MacroType;

  getCustomTrackingLog() {
    if (this.weightLogData) {
      for (const wlData of this.weightLogData) {
        if (wlData.customTrackingLog) {
          return wlData.customTrackingLog.ccts;
        }
      }
    }

    return null;
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  calculateDailydifference(
    weight: number,
    index: number,
    client: AppUser,
  ): string {
    return calcuclateDailydifferenceMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateDifferenceFromStart(index: number, client: AppUser): string {
    return calculateDifferenceFromStartMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateFiveDayAverage(index: number, client: AppUser): string {
    return calculateFiveDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateSevenDayAverage(index: number, client: AppUser): string {
    return calculateSevenDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateAvgSevenDayMacro(
    macroType: MacroType,
    macroSelector: MacroSelector,
  ): string {
    return calcuclateSevenDayMacro(
      this.j,
      this.weightLogData as WeightEntryData[],
      macroType,
      macroSelector,
    );
  }

  calculateAvgSevenDayNumeric(index: number) {
    return calculateAvgNumeric(
      this.j,
      this.weightLogData as WeightEntryData[],
      index,
    );
  }

  calculateRoglDaily(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglWeekly(index: number, client: AppUser): string {
    return calculateRoglWeeklyMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
}
