import { Component, Input } from '@angular/core';
import { CustomCheckinFormTypeNumber, CustomCheckinFormValue } from 'src/app/core/thecoach';

@Component({
  selector: 'app-display-dashboard-checkin-number',
  template: `
        <div class="flex w-full flex-row justify-between">
          <div class="flex flex-col flex-1">
        <h2 class="text-lg font-medium">{{ccf.name}}</h2>
        <p class="text-sm text-gray-500 ">{{ccf.description}}</p>
        </div>
            <div class="border rounded-lg p-2 flex items-center gap-2">
          <span class="text-sm font-semibold">{{ccf.value}}</span>
            </div>
        </div>
  `,
  styles: [
  ]
})
export class DisplayDashboardCheckinNumberComponent {
@Input() ccf!:CustomCheckinFormTypeNumber;
}
