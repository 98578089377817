import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Chart, ChartType } from 'chart.js';
import { CustomCheckinFormType, CustomCheckinFormTypeRange, CustomCheckinFormValue } from 'src/app/core/thecoach';

@Component({
  selector: 'app-display-dashboard-checkin-range',
  template: `

      <div>


   <div class="flex flex-row justify-between items-baseline">
        <h2 class="text-lg font-medium w-fit">{{ccf.name}}</h2>
        <p class="pl-4 text-sm text-gray-500 flex-1 text-end italic">{{ccf.description}}</p>
     </div>
        <div class="border rounded-lg p-4 flex items-center gap-4">
            <div (window:resize)="onResize($event)" class="w-full h-14">
            <canvas  responsive=true id="rChart-{{this.ccf.slot}}">{{ this.charts[this.ccf.slot]}}</canvas>
          </div>
        </div>
      </div>

  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayDashboardCheckinRangeComponent implements AfterViewInit{
    ngAfterViewInit(): void {

   this.createChart(this.ccf ,'rChart-'+this.ccf.slot)
  }
@Input() ccf!:CustomCheckinFormTypeRange;

  ChartType: ChartType = 'bar';
  charts:{[slot:number]:any}={}




  createChart(ccf:CustomCheckinFormTypeRange, idName:string)
    {
    const labelName = ccf.name;

    if (Chart.getChart(idName) != undefined) {
      Chart.getChart(idName)?.destroy();
    }


    const backcolor = '#ffffff'
    const color = '#0d9488'

    const chartdata = {
      labels: [labelName],
      datasets: [
        {
          data: [ccf.value],
          backgroundColor: [color,backcolor],
          categoryPercentage:0.1
        },
      ],
    };


    const config = {
      type: this.ChartType,
      data: chartdata,
      options: {
        maintainAspectRatio: false,
        indexAxis: 'y' as const, // <-- here
        responsive: true,
        scales:{
          y:{
            ticks:{display:false},
          },

          x:{
            min:ccf.min,
            max:ccf.max,

          }


        },

        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    };
 //   console.log('before creation', idName, config, this.charts)
      this.charts[this.ccf.slot] = new Chart(idName, config);
   // console.log('After Creation', this.charts)
  }



  onResize(event: any) {
    this.charts[this.ccf.slot].resize();
  }
}
