import { WeightEntryData, AppUser, Specification } from 'src/app/core/thecoach';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Chart, { ChartType } from 'chart.js/auto';
import * as _ from 'lodash';

@Component({
  selector: 'app-macrographs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row w-full justify-evenly mb-2">
      <div class="w-20 text-center">
        <span class="text-xs">Protein</span>
        <canvas id="pChart">{{ proteinChart }}</canvas>
      </div>

      <div class="w-20 text-center">
        <span class="text-xs">Carbs</span>
        <canvas id="cChart">{{ carbChart }}</canvas>
      </div>
      <div class="w-20 text-center">
        <span class="text-xs">Fat</span>
        <canvas id="fChart">{{ fatChart }}</canvas>
      </div>
    </div>
  `,
  styles: [],
})
export class MacrographsComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    this.loadCharts();
    /*
    if (!changes['weightEntry'] || !changes['weightEntry']['firstChange']) {
      const changeVal = changes['weightEntry'];
      const current = changeVal.currentValue as WeightEntryData;
      const previous = changeVal.previousValue as WeightEntryData;

      console.log(
        this.weightEntry?.achivedMacros?.protein,
        current.achivedMacros?.protein,
        previous.achivedMacros?.protein
      );

      if (!_.isEqual(this.weightEntry, current)) {
        console.log(
          'INSIDE',
          changes['weightEntry']['currentValue'],
          changes['weightEntry']['previousValue']
        );
        this.weightEntry = changes['weightEntry'][
          'currentValue'
        ] as WeightEntryData;
        this.loadCharts();
      }
    }*/
  }

  ngOnInit(): void {
    if (this.specs) {
      this.getFirstSpec()
    }
    this.loadCharts();
  }

  doughnutChartType: ChartType = 'doughnut';
  proteinChart: any;
  carbChart: any;
  fatChart: any;
  firstMacroSpec: Specification | undefined;
  @Input() weightEntry?: WeightEntryData;
  @Input() user?: AppUser;
  @Input() updater?: boolean;
  @Input() specs?: Specification[];

  getFirstSpec() {
    this.firstMacroSpec = this.specs?.find(spec => (spec.setMacros && spec.specMacros))
  }

  loadCharts() {
    if (
      !this.weightEntry ||
      !this.weightEntry.achivedMacros ||
      !this.user ||
      !this.user.setMacros
    ) {
    } else if (this.firstMacroSpec && this.firstMacroSpec.specMacros) {
      this.createChart(
        this.weightEntry.achivedMacros.protein as number,
        this.firstMacroSpec.specMacros.protein as number,
        this.proteinChart,
        'pChart',
        'Protein',
        { red: 249, green: 115, blue: 22 } //orange 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.carbs as number,
        this.firstMacroSpec.specMacros.carbs as number,
        this.carbChart,
        'cChart',
        'Carbs',
        { red: 34, green: 197, blue: 74 } //green 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.fat as number,
        this.firstMacroSpec.specMacros.fat as number,
        this.fatChart,
        'fChart',
        'Fats',
        { red: 59, green: 130, blue: 246 } //blue 500
      );
    }
    else {
      this.createChart(
        this.weightEntry.achivedMacros.protein as number,
        this.user.setMacros.protein as number,
        this.proteinChart,
        'pChart',
        'Protein',
        { red: 249, green: 115, blue: 22 } //orange 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.carbs as number,
        this.user.setMacros.carbs as number,
        this.carbChart,
        'cChart',
        'Carbs',
        { red: 34, green: 197, blue: 74 } //green 500
      );

      this.createChart(
        this.weightEntry.achivedMacros.fat as number,
        this.user.setMacros.fat as number,
        this.fatChart,
        'fChart',
        'Fats',
        { red: 59, green: 130, blue: 246 } //blue 500
      );
    }
  }

  createChart(
    macroValue: number,
    setValue: number,
    chartObj: any,
    idName: string,
    labelName: string,
    colorCode: { red: number; green: number; blue: number }
  ) {
    if (Chart.getChart(idName) != undefined) {
      Chart.getChart(idName)?.destroy();
    }

    const tintFactor = 0.75;
    const shadeFactor = 0.5;

    const color =
      'rgb(' +
      colorCode.red +
      ',' +
      colorCode.green +
      ',' +
      colorCode.blue +
      ')';
    let backcolor: string;

    if (setValue - macroValue >= 0) {
      backcolor =
        'rgb(' +
        Math.round(colorCode.red + (255 - colorCode.red) * tintFactor) +
        ',' +
        Math.round(colorCode.green + (255 - colorCode.green) * tintFactor) +
        ',' +
        Math.round(colorCode.blue + (255 - colorCode.blue) * tintFactor) +
        ')';
    } else {
      backcolor =
        'rgb(' +
        Math.round(colorCode.red * (1 - shadeFactor)) +
        ',' +
        Math.round(colorCode.green * (1 - shadeFactor)) +
        ',' +
        Math.round(colorCode.blue * (1 - shadeFactor)) +
        ')';
    }

    const chartdata = {
      labels: [labelName],
      datasets: [
        {
          label: labelName,
          data: [macroValue, setValue - macroValue],
          backgroundColor: [color, backcolor],
        },
      ],
    };

    const config = {
      type: this.doughnutChartType,
      data: chartdata,
      options: {
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },

        cutout: '70%',
        elements: {
          arc: {
            borderWidth: 1,
          },
        },
      },
      plugins: [
        {
          id: 'text',
          beforeDraw: function (chart: any, a: any, b: any) {
            let width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            let fontSize = (height / 114).toFixed(2);
            ctx.font = fontSize + 'em sans-serif';
            ctx.textBaseline = 'middle';

            let text = macroValue.toString() + ' / ' + setValue.toString(),
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;

            ctx.fillText(text, textX, textY);

            let val = (setValue - macroValue).toString();
            (textX = Math.round((width - ctx.measureText(val).width) / 2)),
              (textY = height / 2);

            ctx.font = 'bold ' + fontSize + 'em sans-serif';
            ctx.fillText(val, textX, textY + 10);

            ctx.save();
          },
        },
      ],
    };

    chartObj = new Chart(idName, config);
  }
}
