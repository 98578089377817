import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { Observable, ReplaySubject, first, of, switchMap } from 'rxjs';
import { AppUser } from '../core/thecoach';
import { UserConfig } from '../../../../backend/core/thecoach';
import { User } from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  userConfig$ = new ReplaySubject<UserConfig | null>(1);
  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
  ) {
    this.auth.appUser$
      .pipe(
        first(),
        switchMap((appUser) => {
          if (!appUser) return of(null);

          return this.db
            .collection<UserConfig>('userConfig')
            .doc(appUser.customerId)
            .valueChanges();
        }),
      )
      .subscribe((userConfig) => {
        this.userConfig$.next(userConfig || null);
      });
  }

  //LEGACY CODE
  getQuantityOfSubscriptions(): Observable<UserConfig | undefined> {
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        if (!appUser) return of(undefined);

        return this.db
          .collection<UserConfig>('userConfig')
          .doc(appUser.customerId)
          .valueChanges();
      }),
    );
  }
}
