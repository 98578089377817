import { Component, Input, OnInit } from '@angular/core';
import { WeightEntryData, AppUser } from 'src/app/core/thecoach';

@Component({
  selector: 'app-statistics-macro-tracking-table',
  template: `
       <div *ngIf="client">
      <div class="pt-2">

          <table
            class="divide-y divide-gray-300 overflow-x-auto whitespace-nowrap table-auto w-full "
            [ngClass]="{'block' : client.offDayMacros}"
          >
            <thead
              app-statistics-macro-tracking-tableheader
[client]="client"
            ></thead>

            <tbody
              app-statistics-macro-tracking-tablebody
              [client]="client"
              [weightLogData]="table"
              class="divide-y divide-gray-200"
            ></tbody>
          </table>

      </div>
    </div>
  `,
  styles: [
  ]
})
export class StatisticsMacroTrackingTableComponent implements OnInit {

  @Input() weightLogData!: WeightEntryData[] | null;
  @Input() client!: AppUser | undefined;

  table = this.weightLogData

  ngOnInit(): void {
    //this.splitArray = this.formatArrays(this.weightLogData!);
    if (this.weightLogData) this.table = this.weightLogData;


  }

}
