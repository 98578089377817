import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { isToday } from 'date-fns';
import { first } from 'rxjs';
import {
  AppUser,
  Macros,
  WeightEntryData,
  calculateKcalFromMacros,
  convertDateObject,
} from 'src/app/core/thecoach';
import { UserService } from 'src/app/services/user.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-set-off-day-macros-module-coach',
  template: `
    <div
      class="flex flex-col xl:flex-row items-center mt-2"
      *ngIf="client.enableOffDayMacros; else enableOffDayMacros"
    >
      <dl class="mt-1 grid grid-cols-4  gap-2 text-center">
        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">Protein</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ client.offDayMacros?.protein }}
          </dd>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">Carbs</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ client.offDayMacros?.carbs }}
          </dd>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">Fat</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ client.offDayMacros?.fat }}
          </dd>
        </div>

        <div class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow ">
          <dt class="truncate text-sm font-medium text-gray-500">KCAL</dt>
          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
            {{ calculateKcal(client.offDayMacros!) }}
          </dd>
        </div>
      </dl>

      <div class="mt-2 xl:mt-0 flex flex-row items-center justify-center">
        <button
          type="button"
          (click)="onSetMacrosModal(client); $event.stopPropagation()"
          class="rounded-md bg-teal-600 py-1.5 px-2.5 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Set Macros
        </button>
        <button
          type="button"
          (click)="deleteOffDayMacros(); $event.stopPropagation()"
          class="rounded-md bg-red-600 py-1.5  px-2.5 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </button>
      </div>
    </div>
    <ng-template #enableOffDayMacros>
      <div class="flex items-center justify-center pt-2">
        <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
        <button
          type="button"
          (click)="toggle()"
          [ngClass]="{
            'bg-teal-600': isEnabled,
            'bg-gray-200': !isEnabled
          }"
          class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
          role="switch"
          aria-checked="false"
          aria-labelledby="annual-billing-label"
        >
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span
            aria-hidden="true"
            [ngClass]="{
              'translate-x-5': isEnabled,
              'translate-x-0': !isEnabled
            }"
            class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          ></span>
        </button>
        <span class="ml-3 text-sm" id="annual-billing-label">
          <span class="font-medium text-gray-500">Enable Off Day Macros?</span>
        </span>
      </div>
    </ng-template>

    <div
      *ngIf="macroModaltrigger"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-set-off-day-macros-modal
        @AnimationTrigger1
        [client]="client!"
        (onClick)="setOffMacrosOfClient($event)"
        *ngIf="macroModaltrigger"
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
      ></app-set-off-day-macros-modal>
    </div>
  `,
  styles: [],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class SetOffDayMacrosModuleCoachComponent {
  constructor(
    private userService: UserService,
    private weightLogService: WeightlogService
  ) {}
  @Input() client!: AppUser;
  isEnabled = false;

  macroModaltrigger = false;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  onSetMacrosModal(client: AppUser) {
    this.macroModaltrigger = !this.macroModaltrigger;
  }

  setOffMacrosOfClient(event: boolean) {
    if (event) {
      this.userService.overWriteUser(this.client);

      this.weightLogService
        .getWeightLogDataOfXDaysUpdateDate(this.client.id!, 1)
        .pipe(first())
        .subscribe((data) => {
          let log = data[0];

          log.date = convertDateObject(log.date as Date);
          if (log.date && isToday(log.date)) {
            log.offDayMacros = this.client.offDayMacros;
          } else {
            log.offDayMacros = this.client.offDayMacros;
            log.date = new Date();
          }

          this.weightLogService.addDataToLogForUser(log.date, this.client, log);
        });
    }
    this.macroModaltrigger = !this.macroModaltrigger;
  }

  toggle() {
    this.client.enableOffDayMacros = !this.client.enableOffDayMacros;

    this.isEnabled = !this.isEnabled;
  }
  deleteOffDayMacros() {
    this.client.enableOffDayMacros = !this.client.enableOffDayMacros;
    if (this.client.offDayMacros) delete this.client.offDayMacros;
    this.isEnabled = false;
    this.userService.overWriteUser(this.client);
  }
}
