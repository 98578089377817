import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { parse } from 'date-fns';
import { forEach } from 'lodash';
import { Subscription, first, switchMap, take } from 'rxjs';
import {
  AppUser,
  TrainingPlan,
  MesoCycle,
  TemplateTrainingPlan,
  Exercise,
  TemplateTrainingSlot,
  TemplateTrainingDay,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { TemplateTrainingplanService } from 'src/app/services/templatetrainingplan.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ta, tr } from 'date-fns/locale';

@Component({
  selector: 'app-trainingplan-assign-dynamic',
  template: `
    <div class="py-2">
      <button
        (click)="onGoBack()"
        type="button"
        class="flex flex-row justify-center items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 pr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
          />
        </svg>

        Back
      </button>
    </div>

    <div *ngIf="newPlanToggler">
      <div class="px-8">
        <div class="mt-8 flow-root">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex w-full align-middle">
              <div class="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    (click)="toggleMenu()"
                    class="inline-flex w-48 justify-center gap-x-1.5 rounded-md bg-white px-1 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                  >
                    {{ defaultValueDropdownTemplates }}
                    <svg
                      class="-mr-1 h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <div
                  @opacityScale
                  *ngIf="isMenu"
                  class="fixed z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <a
                      (click)="
                        onGenerateFromTemplateTrainingPlan(tp, selectedClient)
                      "
                      *ngFor="let tp of templateTrainingPlans"
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm hover:cursor-pointer"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      >{{ tp.trainingPlanName }}</a
                    >
                  </div>
                </div>
              </div>
              <div
                *ngIf="isMenu"
                (click)="toggleMenu()"
                class="fixed inset-0"
              ></div>
              <input
                *ngIf="
                  this.activePlan.trainingPlanName ||
                  this.activePlan.trainingPlanName === ''
                "
                type="text"
                #trainingPlanName
                [(ngModel)]="this.activePlan.trainingPlanName"
                placeholder="Training Plan Name..."
                name="trainingPlanName"
                id="trainingPlanName"
                class="ml-4 block w-[16rem] h-fit rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
              />
              <textarea
                *ngIf="
                  this.activePlan.trainingPlanDescription ||
                  this.activePlan.trainingPlanDescription === ''
                "
                type="textarea"
                rows="4"
                #trainingPlanDescription
                [(ngModel)]="this.activePlan.trainingPlanDescription"
                placeholder="Description..."
                name="trainingPlanDescription"
                id="trainingPlanDescription"
                class="ml-4 block w-3/6 rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 pb-[20%]" *ngIf="mesoCycles">
      <form [formGroup]="trainingPlanForm!" (ngSubmit)="onSubmit()">
        <div>
          <div class="flex">
            <nav
              class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
              aria-label="Tabs"
              formArrayName="mesoCycles"
            >
              <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
              <a
                *ngFor="let meso of mesoCycles.controls; let i = index"
                (click)="onSelectMesoFromForm(i)"
                class="text-gray-900 rounded-l-lg group hover:cursor-pointer relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                aria-current="page"
                [formGroupName]="i"
              >
                <span>{{ getMesoName(meso) }}</span>
                <span
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-0.5"
                  [ngClass]="{
                    'bg-teal-500': this.activeMesoIndex === i,
                    'bg-gray-700': this.activeMesoIndex !== i
                  }"
                ></span>
              </a>
            </nav>
          </div>
        </div>
        <div
          class="overflow-hidden rounded-lg bg-white shadow"
          formArrayName="mesoCycles"
        >
          <div formGroupName="{{ this.activeMesoIndex }}">
            <div class="pl-2 py-2 " formArrayName="trainingDays">
              <!-- Content goes here -->

              <div
                *ngFor="
                  let trainingDay of getActiveMesoControls()!.controls;
                  let j = index
                "
                class="w-full"
              >
                <div formGroupName="{{ j }}">
                  <div class="px-4 sm:px-6 lg:px-8">
                    <div class="mt-8 flow-root">
                      <div class=" -ml-4 overflow-x-auto w-full">
                        <div
                          class="flex flex-col py-2 align-middle w-fit px-2 border rounded-md pb-4"
                        >
                          <div class="flex flex-row pb-2 justify-between">
                            <div class="flex flex-row items-center">
                              <div
                                class="flex flex-col w-fit items-center pr-2"
                              >
                                <svg
                                  (click)="
                                    moveTrainingDayUp(
                                      trainingDay,
                                      getActiveMesoControls()!.controls,
                                      j
                                    )
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-4 h-4 border-2 rounded"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                  />
                                </svg>

                                <svg
                                  (click)="
                                    moveTrainingDayDown(
                                      trainingDay,
                                      getActiveMesoControls()!.controls,
                                      j
                                    )
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-4 h-4 border-2 rounded"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </div>
                              <h1
                                class="text-md font-semibold italic text-gray-600"
                              >
                                Trainingday:

                                <input
                                  type="text"
                                  (keyup)="calculateTrainingDayNameWidth()"
                                  formControlName="trainingDayName"
                                  [style.width.px]="trainingDayNameWidth"
                                />
                              </h1>
                            </div>
                            <div
                              class="flex flex-row justify-between items-center"
                            >
                              <button
                                type="button"
                                (click)="
                                  onDeleteTrainingDay(j);
                                  $event.stopPropagation()
                                "
                                class="ml-4 w-fit h-fit rounded-md bg-red-600 py-0.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              >
                                Delete Trainingday
                              </button>
                            </div>

                            <button
                              type="button"
                              (click)="
                                onAddExercise(trainingDay);
                                $event.stopPropagation()
                              "
                              class="ml-4 w-fit h-fit rounded-md bg-teal-600 py-0.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                              +Exercise
                            </button>
                          </div>
                          <table
                            class="divide-y divide-gray-300 w-full table-auto"
                          >
                            <thead>
                              <tr class="divide-x divide-gray-200">
                                <th
                                  scope="col"
                                  class="py-2 px-2 text-left text-sm font-semibold text-gray-900 "
                                >
                                  Slot
                                </th>
                                <th
                                  scope="col"
                                  class="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  class="px-2 py-2 text-left text-sm font-semibold text-gray-900 "
                                >
                                  Description
                                </th>
                                <!--
                                    <th
                                      scope="col"
                                      class="px-2 py-2 text-left text-sm font-semibold text-gray-900 "
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                                        />
                                      </svg>
                                    </th>-->
                                <th
                                  scope="col"
                                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900 "
                                >
                                  Sets
                                </th>
                                <th
                                  scope="col"
                                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900 "
                                >
                                  Reps
                                </th>
                                <th
                                  scope="col"
                                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900"
                                >
                                  RIR
                                </th>
                                <th
                                  scope="col"
                                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900"
                                >
                                  Edit
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              class="divide-y divide-gray-200 bg-white"
                              formArrayName="trainingSlots"
                            >
                              <tr
                                class="divide-x divide-gray-200"
                                *ngFor="
                                  let trainingSlot of getTrainingDaySlots(
                                    trainingDay
                                  ).controls!;
                                  let k = index
                                "
                                formGroupName="{{ k }}"
                              >
                                <td
                                  class="whitespace-normal py-2  text-sm font-medium text-gray-900 text-center w-auto"
                                >
                                  <div class="flex flex-row items-center">
                                    <div
                                      class="flex flex-col items-center pr-2"
                                    >
                                      <svg
                                        (click)="
                                          moveSlotUp(trainingSlot, trainingDay)
                                        "
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-4 h-4 border-2 rounded"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                        />
                                      </svg>

                                      <svg
                                        (click)="
                                          moveSlotDown(
                                            trainingSlot,
                                            trainingDay
                                          )
                                        "
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-4 h-4 border-2 rounded"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                        />
                                      </svg>
                                    </div>
                                    {{
                                      getParameterFromTrainingSlot(
                                        trainingSlot,
                                        'slot'
                                      )
                                    }}
                                  </div>
                                </td>

                                <td
                                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                                >
                                  <div
                                    class="flex flex-row items-center justify-center text-center"
                                  >
                                    <span
                                      class="input w-full h-full"
                                      role="input"
                                      tabindex="-1"
                                      contenteditable="true"
                                    >
                                      <input
                                        class="rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                                        type="text"
                                        #exName
                                        autocomplete="off"
                                        [style.width.px]="exerciseNameWidth"
                                        formControlName="exerciseName"
                                        (click)="
                                          toggleActiveInput(exName, k, j)
                                        "
                                        (keyup)="
                                          filterExercisesByName(exName, k, j)
                                        "
                                        id="{{
                                          getParameterFromTrainingSlot(
                                            trainingSlot,
                                            'exerciseName'
                                          )
                                        }}"
                                      />
                                      <div class="absolute z-10 w-3/5">
                                        <ul
                                          *ngIf="
                                            activeInputIndex === k &&
                                            activeTrainingDayIndex === j &&
                                            filteredExercises.length > 0
                                          "
                                          class="absolute z-20 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                          id="options"
                                          role="listbox"
                                        >
                                          <li
                                            *ngFor="
                                              let exercise of filteredExercises
                                            "
                                            class="hover:text-white hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                                            id="option-0"
                                            role="option"
                                            tabindex="-1"
                                            (click)="
                                              patchExerciseOnSlot(
                                                exercise,
                                                trainingSlot
                                              )
                                            "
                                          >
                                            <div
                                              class="flex flex-row items-center"
                                            >
                                              <!-- Selected: "font-semibold" -->
                                              <span
                                                class="flex flex-row whitespace-nowrap pr-1"
                                                >{{
                                                  exercise.exerciseName
                                                }}</span
                                              >
                                              -
                                              <span
                                                class="block truncate italic text-xs pl-1"
                                              >
                                                {{
                                                  exercise.exerciseDescription
                                                }}</span
                                              >
                                            </div>
                                            <!--
          Checkmark, only display for selected option.

          Active: "text-white", Not Active: "text-teal-600"
        -->
                                          </li>

                                          <!-- More items... -->
                                        </ul>
                                      </div>
                                    </span>
                                    <div *ngIf="false">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5 cursor-pointer"
                                        (click)="
                                          onExerciseLibraryLoad(trainingSlot)
                                        "
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  class="whitespace-nowrap p-2 text-sm text-gray-500 w-auto"
                                >
                                  <span
                                    class="input w-full h-full"
                                    role="input"
                                    tabindex="-1"
                                    contenteditable="true"
                                  >
                                    <textarea
                                      class="w-96 min-h-[2.5rem] rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                                      rows="2"
                                      type="text"
                                      formControlName="exerciseDescription"
                                    ></textarea>
                                  </span>
                                </td>
                                <!--
                                    <td
                                      class="whitespace-nowrap items-center p-2 text-sm text-gray-500 w-auto"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6 cursor-pointer"
                                        (click)="
                                          onOpenEditVideoLink(
                                            trainingSlot,
                                            trainingDay
                                          )
                                        "
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                                        />
                                      </svg>
                                    </td>-->
                                <td
                                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                                >
                                  <input
                                    class="w-[2.5rem]  text-center rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                                    type="text"
                                    formControlName="sets"
                                    id="{{
                                      getParameterFromTrainingSlot(
                                        trainingSlot,
                                        'sets'
                                      )
                                    }}"
                                  />
                                </td>
                                <td
                                  class="whitespace-nowrap py-2 text-sm text-gray-500 text-center w-fit "
                                >
                                  <input
                                    class="w-[6.5rem]  text-center rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                                    type="text"
                                    formControlName="reps"
                                    id="{{
                                      getParameterFromTrainingSlot(
                                        trainingSlot,
                                        'reps'
                                      )
                                    }}"
                                  />
                                </td>
                                <td
                                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                                >
                                  <input
                                    class=" w-[3.5rem] text-center rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                                    type="text"
                                    formControlName="rir"
                                    id="{{
                                      getParameterFromTrainingSlot(
                                        trainingSlot,
                                        'rir'
                                      )
                                    }}"
                                  />
                                </td>
                                <td
                                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                                >
                                  <button
                                    (click)="
                                      onDeleteSlot(trainingSlot, trainingDay, k)
                                    "
                                    type="button"
                                    class="ml-4 w-fit rounded-md bg-red-600 py-0.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    (click)="
                                      onCopySlot(trainingSlot, trainingDay, k)
                                    "
                                    type="button"
                                    class="ml-4 w-fit rounded-md bg-teal-600 py-0.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                                      />
                                    </svg>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4">
                                  <div class="flex w-full justify-end">
                                    <span class="italic text-gray-500"
                                      >Total Sets:
                                      {{ totalSets(trainingDay) }}</span
                                    >

                                    <span
                                      class="pl-4 font-bold text-red-400"
                                      *ngIf="hasErrorInExercises(trainingDay)"
                                      >There is an Set Error! Please Fix!
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4 w-full flex-row flex justify-between">
          <div class="w-2/5 flex flex-row justify-around">
            <button
              type="button"
              (click)="onAddTrainingDay(); $event.stopPropagation()"
              class="ml-4 rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              +Trainingday
            </button>
            <div class="flex flex-row justify-end">
              <button
                type="button"
                (click)="onAddMeso(); $event.stopPropagation()"
                class="ml-4 rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                +Meso
              </button>
              <button
                *ngIf="mesoCycles.length > 1"
                type="button"
                (click)="onRemoveMeso(); $event.stopPropagation()"
                class="ml-4 rounded-md bg-red-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                -Meso
              </button>
            </div>
          </div>

          <button
            *ngIf="!this.id"
            type="button"
            (click)="
              assignTrainingPlan(selectedClient); $event.stopPropagation()
            "
            class="rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Assign TP to Client
          </button>
          <button
            *ngIf="this.id"
            [disabled]="hasErrorInForm()"
            type="button"
            (click)="
              updateTrainingPlan(selectedClient); $event.stopPropagation()
            "
            class="rounded-md disabled:opacity-25 bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Update TP to Client
          </button>
        </div>
        <div class="flex w-full justify-center text-center">
        <span
          class="font-bold text-red-400"
          *ngIf="hasErrorInForm()"
        >There is an Set Error! Please Fix!
        </span>
          </div>
      </form>
    </div>

    <app-video-link-edit-modal
      *ngIf="toggleVideoEditMenu"
      [slotForm]="selectForEditVideoLink"
      (onSave)="onClosingVideoLinkEdit($event)"
    >
    </app-video-link-edit-modal>

    <app-new-exercises-modal
      *ngIf="toggleModifiedExercises"
      (close)="onModifiedExercises($event)"
      [modifedExercises]="this.modifiedExercises"
    ></app-new-exercises-modal>

    <app-exercise-search-modal
      class="z-10"
      (close)="onSelectedExerciseFromDatabase($event)"
      [slotElement]="selectedTrainingSlotForLoad"
      [exercises]="this.exercises"
      *ngIf="toggleExerciseLibrarySearch"
    >
    </app-exercise-search-modal>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class TrainingplanAssignDynamicComponent implements OnInit, OnDestroy {
  user: AppUser = {};
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  backupClientDatabase: AppUser[] = [];
  clientSubscription: Subscription | undefined;
  templateTrainingPlanSubscription: Subscription | undefined;
  exerciseSubscription: Subscription | undefined;
  trainingPlanSubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;
  clientToggler: boolean[] = [];
  selectedClient: AppUser = {};
  newPlanToggler = false;
  isMenu = false;
  defaultValueDropdownTemplates = 'Trainingplan Templates';
  activePlan: TrainingPlan = {};
  backupPlan: TrainingPlan = {};

  activeMesoCycle: MesoCycle = {};

  templateTrainingPlans: TemplateTrainingPlan[] = [];
  templatePlan: TemplateTrainingPlan = {};
  exercises: Exercise[] = [];
  backupExercises: Exercise[] = [];
  filteredExercises: Exercise[] = [];
  activeIndex: number | undefined;
  activeMesoIndex: number | undefined;

  trainingPlanForm: FormGroup | undefined;

  activeFormMesoCycle: FormGroup | undefined;

  exerciseDescriptionWidth = 275;
  exerciseNameWidth = 125;
  trainingDayNameWidth = 125;
  id: string | undefined;
  userId: string | undefined;

  toggleVideoEditMenu = false;
  selectForEditVideoLink: AbstractControl | undefined;
  modifiedExercises: Exercise[] = [];
  toggleModifiedExercises = false;

  toggleExerciseLibrarySearch = false;
  selectedTrainingSlotForLoad: AbstractControl | undefined = undefined;

  showDropdown = false;
  activeInput: HTMLInputElement | null = null;
  activeInputIndex: number | null = null;
  activeTrainingDayIndex: number | null = null;

  constructor(
    private _eref: ElementRef,
    protected auth: AuthService,
    protected userService: UserService,
    private templatetpServcie: TemplateTrainingplanService,
    private exerciseService: ExerciseService,
    private tpService: TrainingplanService,
    private fb: FormBuilder,
    private db: AngularFirestore,
    private location: Location,
    private route: ActivatedRoute,
    private trainingPlanService: TrainingplanService,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  logPlan() {
    /*
    console.log(this.activePlan);
    this.activePlan.mesoCycles?.forEach((meso) => {
      console.log('mesos:', meso);
    });
    */
    console.log(this.trainingPlanForm);
    console.log(this.mesoCycles);
  }

  get mesoCycles() {
    if (!this.trainingPlanForm) return undefined;
    return this.trainingPlanForm.get('mesoCycles') as FormArray;
  }

  onExerciseLibraryLoad(trainingSlot: AbstractControl) {
    //this.getExercises();
    this.toggleExerciseLibrarySearch = !this.toggleExerciseLibrarySearch;
    this.selectedTrainingSlotForLoad = trainingSlot;
  }

  onSelectedExerciseFromDatabase(event: boolean) {
    this.calculateExNameWidth();
    this.calculateExDescriptionWidth();
    this.toggleExerciseLibrarySearch = !this.toggleExerciseLibrarySearch;
    this.cd.detectChanges();
  }

  onCancelGeneration(client: AppUser) {
    this.toggleMenu();
    this.selectTemplateTrainingplan(client);
    this.activePlan = {};
    this.isMenu = false;
    this.newPlanToggler = false;
    this.id = undefined;
    this.trainingPlanForm = undefined;
    this.activeFormMesoCycle = undefined;
    this.activeMesoIndex = undefined;
    this.defaultValueDropdownTemplates = 'Trainingplan Templates';
    this.clientDatabase = Object.assign([], this.backupClientDatabase);
  }

  generateTrainingPlanFromTemplateForm(
    tp: TemplateTrainingPlan,
    meso: FormArray,
    clientId: string,
  ) {
    return this.fb.group({
      id: [tp.id],
      trainingPlanName: [tp.trainingPlanName],
      trainingPlanDescription: [tp.trainingPlanDescription],
      ownerId: [tp.ownerId],
      clientId: [clientId],
      mesoCycles: meso,
    });
  }

  generateTrainingPlanForm(tp: TrainingPlan, meso: FormArray) {
    return this.fb.group({
      id: [tp.id],
      trainingPlanName: [tp.trainingPlanName],
      trainingPlanDescription: [tp.trainingPlanDescription],
      ownerId: [tp.ownerId],
      clientId: [tp.clientId],
      mesoCycles: meso,
    });
  }

  generateMesoCycle(meso: MesoCycle, formTrainingDays: FormArray) {
    return this.fb.group({
      id: [meso.id],
      mesoname: [meso.mesoname],
      trainingDays: formTrainingDays,
    });
  }

  generateTrainingDay(
    td: TemplateTrainingDay,
    formSlots: FormArray,
  ): FormGroup {
    return this.fb.group({
      id: [td.id],
      trainingDayId: [td.trainingDayId],
      trainingPlanId: [td.trainingPlanId],
      trainingDayName: [td.trainingDayName],
      trainingSlots: formSlots,
    });
  }

  generateTrainingSlot(slot: TemplateTrainingSlot): FormGroup {
    return this.fb.group({
      id: [slot.id],
      slot: [slot.slot],
      exerciseName: [slot.exerciseName],
      exerciseDescription: [slot.exerciseDescription],
      exerciseVideoLink: [slot.exerciseVideoLink],
      exerciseId: [slot.exerciseId],
      sets: [
        slot.sets,
        [Validators.required, Validators.pattern(/^-?\d+(\.\d+)?$/)],
      ],
      reps: [slot.reps],
      rir: [slot.rir],
    });
  }

  generateEmptyTrainingDay(): FormGroup {
    let formSlots: FormArray = this.fb.array([]);
    formSlots.push(this.generateEmptyTrainingSlot());
    formSlots.at(0).get('slot')?.patchValue(1);
    return this.fb.group({
      trainingDayName: [''],
      trainingSlots: formSlots,
    });
  }

  generateEmptyTrainingSlot(): FormGroup {
    return this.fb.group({
      slot: [''],
      exerciseName: [''],
      exerciseDescription: [''],
      exerciseId: [''],
      sets: [''],
      reps: [''],
      rir: [''],
    });
  }

  totalSets(trainingDay: AbstractControl) {
    let totalSets = 0;
    (trainingDay.get('trainingSlots') as FormArray).controls.forEach((slot) => {
      totalSets += Number((slot.get('sets') as AbstractControl).value);
      //console.log(num);
    });

    return totalSets;
  }

  hasErrorInExercises(trainingDay: AbstractControl) {
    let error = false;
    (trainingDay.get('trainingSlots') as FormArray).controls.forEach((slot) => {
      if ((slot.get('sets') as AbstractControl).hasError('pattern')) {
        error = true;
      }
      //console.log(num);
    });

    return error;
  }

  hasErrorInForm() {
    let error = false;
    this.mesoCycles?.controls.forEach(meso =>{
    (meso.get('trainingDays') as FormArray).controls.forEach(trainingDay =>{
    if(this.hasErrorInExercises(trainingDay)) error = true
      })})

    return error;
  }


  generateFromTrainingPlan(tp: TrainingPlan) {
    this.toggleMenu();
    this.defaultValueDropdownTemplates = this.activePlan
      .trainingPlanName as string;
    this.activeMesoIndex = this.activePlan.mesoCycles?.length! - 1;

    let tempMesoCycle: FormArray = this.fb.array([]);
    tp.mesoCycles?.forEach((meso) => {
      let tempMesoForm: FormArray = this.fb.array([]);
      meso.trainingDays?.forEach((td) => {
        let tempTDForm: FormArray = this.fb.array([]);

        td.trainingSlots?.forEach((slot) => {
          tempTDForm.push(this.generateTrainingSlot(slot));
        });

        tempMesoForm.push(this.generateTrainingDay(td, tempTDForm));
      });
      tempMesoCycle.push(this.generateMesoCycle(meso, tempMesoForm));
    });

    this.trainingPlanForm = this.generateTrainingPlanForm(tp, tempMesoCycle);

    this.activeFormMesoCycle = this.mesoCycles!.at(
      this.mesoCycles?.controls.length! - 1,
    ) as FormGroup;

    this.setInputWidths();
  }

  onGenerateFromTemplateTrainingPlan(
    tp: TemplateTrainingPlan,
    client: AppUser,
  ) {
    this.toggleMenu();
    this.templatePlan = tp;
    this.activePlan.clientId = client.id;
    this.activePlan.ownerId = tp.ownerId;
    this.activePlan.trainingPlanDescription = tp.trainingPlanDescription;
    this.activePlan.trainingPlanName = tp.trainingPlanName;
    this.activePlan.mesoCycles = [];

    /*
    tp.trainingDays?.forEach((td) => {
      td.trainingSlots?.forEach((sl) => {
        sl.exerciseDescription = this.getExerciseDescription(sl.exerciseId!);
        sl.exerciseVideoLink = this.getExerciseVideoLink(sl.exerciseId!);

        sl.muscleGroup = this.getExerciseMuscleGroup(sl.exerciseId!);
      });
    });
*/
    let mesoCycle: MesoCycle = {};

    mesoCycle.mesoname = 'Meso 1';
    mesoCycle.trainingDays = tp.trainingDays;

    this.activePlan.mesoCycles?.push(mesoCycle);

    this.activeMesoCycle = mesoCycle;
    this.defaultValueDropdownTemplates = this.activePlan
      .trainingPlanName as string;

    this.activeMesoIndex = 0;

    let tempMesoForm: FormArray = this.fb.array([]);

    tp.trainingDays?.forEach((td) => {
      let tempTDForm: FormArray = this.fb.array([]);

      td.trainingSlots?.forEach((slot) => {
        tempTDForm.push(this.generateTrainingSlot(slot));
      });

      tempMesoForm.push(this.generateTrainingDay(td, tempTDForm));
    });

    let formMesoCycle: FormArray = this.fb.array([]);

    formMesoCycle.push(this.generateMesoCycle(mesoCycle, tempMesoForm));

    this.trainingPlanForm = this.generateTrainingPlanFromTemplateForm(
      tp,
      formMesoCycle,
      client.id!,
    );

    this.activeFormMesoCycle = this.mesoCycles!.at(0) as FormGroup;

    this.setInputWidths();
    //this.activeMesoCycle = this.activePlan.mesoCycles?.at(0)!;
  }

  ngOnDestroy(): void {
    this.clientSubscription?.unsubscribe;
    this.exerciseSubscription?.unsubscribe;
    this.templateTrainingPlanSubscription?.unsubscribe;
    this.trainingPlanSubscription?.unsubscribe;
    this.userSubscription?.unsubscribe;
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.queryParamMap.get('id') as string;
    this.userId = this.route.snapshot.queryParamMap.get('userId') as string;

    this.userSubscription = this.auth.appUser$
      .pipe()
      .subscribe((appUser) => (this.user = appUser as AppUser));
    this.getTemplateTrainingplans();
    this.getExercises();

    // console.log('inhere', this.userId, this.user);

    if (this.id) {
      this.trainingPlanSubscription = this.trainingPlanService
        .getTrainingPlan(this.id)
        .pipe(take(1))
        .subscribe((data) => {
          this.activePlan = data as TrainingPlan;
          this.backupPlan = _.cloneDeep(data) as TrainingPlan;

          if (this.activePlan) {
            this.userId = this.activePlan.clientId;
            this.newPlanToggler = true;
            this.isMenu = true;
            this.generateFromTrainingPlan(this.activePlan);
          }
        });
    } else {
      if (this.user && this.user.clientIds) {
        this.clientIdDatabase = this.user.clientIds;
        this.getClientData(this.clientIdDatabase);
      }

      if (this.user && this.user.coachSpotId) {
        this.getCoachSpot(this.user.coachSpotId);
      }
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }

          if (this.userId === client.id) {
            this.selectedClient = client;
            this.newPlanToggler = true;
          }
          //    console.log(client, this.clientDatabase)
        }
      });
  }

  syncExerciseIds(tp: TrainingPlan) {
    tp.mesoCycles?.forEach((meso) => {
      meso.trainingDays?.forEach((td) => {
        td.trainingSlots?.forEach((slot) => {
          let foundex = this.exercises.find(
            (ex) =>
              ex.exerciseName === slot.exerciseName &&
              ex.exerciseDescription === slot.exerciseDescription,
          );

          if (foundex) {
            slot.exerciseId = foundex.id;
          }
        });
      });
    });
  }

  getTemplateTrainingplans() {
    this.templateTrainingPlanSubscription = this.templatetpServcie
      .getAllTrainingplansForUser()
      .subscribe((plans) => {
        this.templateTrainingPlans = plans;
        let emptyTrainingPlan: TemplateTrainingPlan =
          new TemplateTrainingPlan();
        emptyTrainingPlan.id = this.db.createId();
        emptyTrainingPlan.trainingPlanName = 'New Empty Plan';
        emptyTrainingPlan.trainingPlanDescription = 'Insert Description';
        emptyTrainingPlan.ownerId = this.user.id;

        this.templateTrainingPlans.push(emptyTrainingPlan);
        // console.log('Plans', plans)
        // console.log('Templateplans', this.templateTrainingPlans)
      });
  }

  getClientData(clientIds: string[]) {
    clientIds.forEach((id) => {
      this.clientSubscription = this.userService
        .getUserFromDatabase(id)
        .subscribe((client) => {
          if (client?.coachId === this.user.id) {
            let cl = client as AppUser;
            //console.log(cl.birthdate);

            //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();

            cl.birthdate = parse(
              cl.birthdate as unknown as string,
              'yyyy-MM-dd',
              new Date(),
            );

            if (this.clientDatabase.some((e) => e.id === cl.id)) {
              let i = this.clientDatabase.indexOf(cl);
              this.clientDatabase[i] = cl;
            } else {
              this.clientDatabase.push(cl);
              this.clientToggler.push(false);
            }

            if (this.userId) {
              this.selectedClient = this.clientDatabase.find(
                (client) => client.id === this.userId,
              ) as AppUser;

              this.newPlanToggler = true;
            } else {
              this.selectedClient = this.clientDatabase.find(
                (client) => client.id === this.activePlan.clientId,
              ) as AppUser;

              this.newPlanToggler = false;
            }
          }
        });
    });
  }

  getExercises() {
    this.exerciseSubscription = this.exerciseService
      .getAllExercisesForUser()
      .pipe(first())
      .subscribe((data) => {
        this.exercises = data;
        this.backupExercises = data;
        this.filteredExercises = data;
      });
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  selectTemplateTrainingplan(client: AppUser, index?: number) {
    this.newPlanToggler = !this.newPlanToggler;

    if (index || index === 0) {
      this.backupClientDatabase = Object.assign([], this.clientDatabase);
      const selectedUser = Object.assign({}, this.clientDatabase[index]);
      this.clientDatabase.splice(0);
      this.clientDatabase.push(selectedUser);
    }
  }

  onSelectMeso(meso: MesoCycle, index: number) {
    this.activeMesoCycle = meso;

    this.activeMesoIndex = index;
  }

  onSelectMesoFromForm(index: number) {
    this.activeMesoCycle = this.activePlan.mesoCycles?.at(index)!;
    this.activeMesoIndex = index;
    this.activeFormMesoCycle = this.mesoCycles!.at(index) as FormGroup;
  }

  getExerciseDescription(id: string) {
    const ex = this.exercises.find((e) => e.id === id);

    return ex?.exerciseDescription;
  }

  getExerciseVideoLink(id: string) {
    const ex = this.exercises.find((e) => e.id === id);
    return ex?.exerciseVideoURL;
  }

  getExerciseMuscleGroup(id: string) {
    const ex = this.exercises.find((e) => e.id === id);
    return ex?.muscleGroup;
  }

  getExercise(id: string) {
    const ex = this.exercises.find((e) => e.id === id);
    return ex;
  }

  assignTrainingPlan(client: AppUser) {
    let mdCycles: MesoCycle[] = [];
    this.mesoCycles?.controls.forEach((mesoForm) => {
      let md: MesoCycle = {};

      (mesoForm.get('trainingDays') as FormArray).controls.forEach((tdForm) => {
        let td: TemplateTrainingDay[] = [];

        (tdForm.get('trainingSlots') as FormArray).controls.forEach(
          (slotForm, index) => {
            const slotCheck = slotForm.get('slot')?.value;
            if (!slotCheck) {
              slotForm.get('slot')?.patchValue(index + 1);
            }
            let slot: TemplateTrainingDay = {};
            Object.assign(slot, slotForm.value);
            // td.push(slot);
          },
        );
        Object.assign(td, tdForm.value);
      });
      Object.assign(md, mesoForm.value);
      mdCycles.push(md);
    });

    this.activePlan.mesoCycles = mdCycles;

    if (this.userId) {
      this.activePlan.clientId = this.userId;
    }
    //  console.log(this.activePlan);
    this.tpService.save(this.activePlan, this.userId!);

    this.syncExerciseIds(this.activePlan);
    this.checkForNewExercises(this.activePlan);
  }

  updateTrainingPlan(client: AppUser) {
    let mdCycles: MesoCycle[] = [];
    this.mesoCycles?.controls.forEach((mesoForm) => {
      let md: MesoCycle = {};

      (mesoForm.get('trainingDays') as FormArray).controls.forEach((tdForm) => {
        let td: TemplateTrainingDay[] = [];

        (tdForm.get('trainingSlots') as FormArray).controls.forEach(
          (slotForm, index) => {
            const slotCheck = slotForm.get('slot')?.value;
            if (!slotCheck) {
              slotForm.get('slot')?.patchValue(index + 1);
            }
            let slot: TemplateTrainingDay = {};
            Object.assign(slot, slotForm.value);
            // td.push(slot);
          },
        );
        Object.assign(td, tdForm.value);
      });
      Object.assign(md, mesoForm.value);
      mdCycles.push(md);
    });

    this.activePlan.mesoCycles = mdCycles;

    if (this.userId) {
      this.activePlan.clientId = this.userId;
    }

    this.tpService.update(this.activePlan, this.userId!);

    this.syncExerciseIds(this.activePlan);
    this.checkForNewExercises(this.activePlan);
  }

  checkForNewExercises(tp: TrainingPlan) {
    let newExercises: Exercise[] = [];
    tp.mesoCycles?.forEach((meso) => {
      meso.trainingDays?.forEach((td) => {
        td.trainingSlots?.forEach((slot) => {
          if (
            !this.exercises.some((e) => e.id === slot.exerciseId) ||
            !this.exercises.some((e) => e.exerciseName === slot.exerciseName) ||
            !this.exercises.some(
              (e) => e.exerciseDescription === slot.exerciseDescription,
            )
          )
            newExercises.push(this.convertTemplateTrainingSlotToExercise(slot));
        });
      });
    });

    this.modifiedExercises = newExercises;
    if (this.modifiedExercises.length > 0) {
      this.toggleModifiedExercises = !this.toggleModifiedExercises;
    } else {
      this.router.navigate(['/coach-assigned-trainingplans']);
    }
  }

  onModifiedExercises(event: boolean) {
    if (event) {
      this.modifiedExercises.forEach((exercise) => {
        this.exerciseService.createNew(exercise);
      });
    }
    this.toggleModifiedExercises = !this.toggleModifiedExercises;
    this.router.navigate(['/coach-assigned-trainingplans']);
  }

  convertTemplateTrainingSlotToExercise(slot: TemplateTrainingSlot): Exercise {
    return {
      exerciseName: slot.exerciseName,
      id: slot.exerciseId,
      exerciseDescription: slot.exerciseDescription,
      muscleGroup: slot.muscleGroup,
      exerciseVideoURL: slot.exerciseVideoLink,
      ownerId: this.user.id,
    };
  }

  checkExerciseChanges(
    meso: MesoCycle,
    td: TemplateTrainingDay,
    slot: TemplateTrainingSlot,
  ) {
    this.mesoCycles?.controls.forEach((mesoForm) => {
      if (mesoForm.get('mesoname')?.value === meso.mesoname) {
        (mesoForm.get('trainingDays') as FormArray).controls.forEach(
          (tdForm) => {
            if (tdForm.get('trainingDayName')?.value === td.trainingDayName) {
              (tdForm.get('trainingSlots') as FormArray).controls.forEach(
                (slotForm) => {
                  if (slotForm.get('exerciseId')?.value === slot.exerciseId) {
                    if (
                      slotForm.get('exerciseName')?.value !== slot.exerciseName
                    ) {
                      slot.exerciseId = this.db.createId();
                      slot.exerciseName = slotForm.get('exerciseName')?.value;
                      slot.exerciseDescription = slotForm.get(
                        'exerciseDescription',
                      )?.value;
                    }

                    if (
                      slotForm.get('exerciseDescription')?.value !==
                      slot.exerciseDescription
                    ) {
                      slot.exerciseDescription = slotForm.get(
                        'exerciseDescription',
                      )?.value;
                    }

                    if (slotForm.get('sets')?.value !== slot.sets) {
                      slot.sets = slotForm.get('sets')?.value;
                    }

                    if (slotForm.get('reps')?.value !== slot.reps) {
                      slot.reps = slotForm.get('reps')?.value;
                    }

                    if (slotForm.get('rir')?.value !== slot.rir) {
                      slot.rir = slotForm.get('rir')?.value;
                    }
                  }
                },
              );
            }
          },
        );
      }
    });
  }

  onOpenClient(index: number) {
    this.clientToggler[index] = !this.clientToggler[index];

    if (this.activeIndex === index) {
      this.activeIndex = undefined;
    } else {
      this.activeIndex = index;
    }
  }

  onDeleteSlot(
    trainingSlot: AbstractControl,
    trainingDay: AbstractControl,
    position: number,
  ) {
    (trainingDay.get('trainingSlots') as FormArray).removeAt(position);
    this.renumberSlots(trainingDay);
    /*
    let checkIfDeleted = false;
    (
      this.mesoCycles
        ?.at(this.activeMesoIndex!)
        .get('trainingDays') as FormArray
    ).controls.forEach((td) => {
      const s1 = (td.get('trainingDayName')?.value as string).toLowerCase();
      const s2 = (
        trainingDay.get('trainingDayName')?.value as string
      ).toLowerCase();

      if (s1 === s2) {
        (td.get('trainingSlots') as FormArray).controls.forEach(
          (slotForm, index) => {
            if (
              slotForm.get('exerciseId')?.value ===
                trainingSlot.get('exerciseId')?.value &&
              slotForm.get('exerciseName')?.value ===
                trainingSlot.get('exerciseName')?.value
            )
              (td.get('trainingSlots') as FormArray).removeAt(index);
            this.renumberSlots(td);
          }
        );
      }
    });
  */
  }

  renumberSlots(td: AbstractControl) {
    (td.get('trainingSlots') as FormArray).controls.forEach(
      (slotForm, index) => {
        slotForm.get('slot')?.patchValue(index + 1);
      },
    );
  }

  moveSlotUp(trainingSlot: AbstractControl, trainingDay: AbstractControl) {
    if (trainingSlot.get('slot')!.value > 1) {
      this.arrayMove(
        (trainingDay.get('trainingSlots') as FormArray).controls,
        trainingSlot.get('slot')!.value - 1,
        trainingSlot.get('slot')!.value - 2,
      );
      this.renumberSlots(trainingDay);
    }
  }

  moveTrainingDayUp(
    trainingDay: AbstractControl,
    meso: AbstractControl[],
    index: number,
  ) {
    if (index > 0) {
      this.arrayMove(meso, index, index - 1);
      (
        (this.mesoCycles!.at(this.activeMesoIndex as number) as FormGroup).get(
          'trainingDays',
        ) as FormArray
      ).patchValue(meso);
    }
  }
  moveTrainingDayDown(
    trainingDay: AbstractControl,
    meso: AbstractControl[],
    index: number,
  ) {
    if (index < meso.length) {
      this.arrayMove(meso, index, index + 1);
      (
        (this.mesoCycles!.at(this.activeMesoIndex as number) as FormGroup).get(
          'trainingDays',
        ) as FormArray
      ).patchValue(meso);
    }
  }

  arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  moveSlotDown(trainingSlot: AbstractControl, trainingDay: AbstractControl) {
    if (
      trainingSlot.get('slot')!.value <
      (trainingDay.get('trainingSlots') as FormArray).controls.length
    ) {
      this.arrayMove(
        (trainingDay.get('trainingSlots') as FormArray).controls,
        trainingSlot.get('slot')!.value - 1,
        trainingSlot.get('slot')!.value,
      );
      this.renumberSlots(trainingDay);
    }
  }

  onCopySlot(
    trainingSlot: AbstractControl,
    trainingDay: AbstractControl,
    position: number,
  ) {
    let copiedSlot = this.generateEmptyTrainingSlot();

    this.patchSlot(copiedSlot, trainingSlot);

    (trainingDay.get('trainingSlots') as FormArray).insert(
      position + 1,
      copiedSlot,
    );

    this.renumberSlots(trainingDay);
  }

  patchSlot(targetSlot: AbstractControl, sourceSlot: AbstractControl) {
    const exName = sourceSlot.get('exerciseName')?.value;
    const exDesc = sourceSlot.get('exerciseDescription')?.value;
    const exID = sourceSlot.get('exerciseId')?.value;

    targetSlot.get('exerciseName')?.patchValue(exName);
    targetSlot.get('exerciseDescription')?.patchValue(exDesc);
    targetSlot.get('exerciseId')?.patchValue(exID);
  }

  onAddExercise(trainingDay: AbstractControl) {
    (trainingDay.get('trainingSlots') as FormArray).push(
      this.generateEmptyTrainingSlot(),
    );

    let index = (trainingDay.get('trainingSlots') as FormArray).length;
    (
      (trainingDay.get('trainingSlots') as FormArray)
        .at(index - 1)
        .get('slot') as FormControl
    ).patchValue(index);
  }

  onDeleteTrainingDay(j: number) {
    (
      this.mesoCycles
        ?.at(this.activeMesoIndex!)
        .get('trainingDays') as FormArray
    ).removeAt(j);
  }

  onAddTrainingDay() {
    (
      this.mesoCycles
        ?.at(this.activeMesoIndex!)
        .get('trainingDays') as FormArray
    ).push(this.generateEmptyTrainingDay());

    this.activeFormMesoCycle = this.mesoCycles!.at(
      this.activeMesoIndex!,
    ) as FormGroup;
  }

  onRemoveMeso() {
    this.mesoCycles?.controls.splice(this.mesoCycles.controls.length - 1, 1);
    this.activePlan.mesoCycles?.splice(
      this.activePlan.mesoCycles.length - 1,
      1,
    );

    this.activeMesoIndex = this.mesoCycles?.controls.length! - 1;
  }

  onAddMeso() {
    let mesoCycle: MesoCycle = {};
    let tempMesoForm: FormArray = this.fb.array([]);

    let tempForm: FormArray = this.fb.array([]);
    (
      this.mesoCycles?.controls
        .at(this.mesoCycles.controls.length - 1)
        ?.get('trainingDays') as FormArray
    ).controls.forEach((tdForm) => {
      let tdFor: FormArray = this.fb.array([]);
      let tdObj: TemplateTrainingDay = {};
      (tdForm.get('trainingSlots') as FormArray).controls.forEach(
        (slotForm) => {
          let slot: TemplateTrainingSlot = {};
          Object.assign(slot, slotForm.value);
          tdFor.push(this.generateTrainingSlot(slot));
        },
      );

      Object.assign(tdObj, tdForm.value);
      tempForm.push(this.generateTrainingDay(tdObj, tdFor));
    });

    mesoCycle.mesoname =
      'Meso ' + (this.activePlan.mesoCycles!.length + 1).toString();

    this.activePlan.mesoCycles?.push(mesoCycle);

    this.mesoCycles?.push(this.generateMesoCycle(mesoCycle, tempForm));

    this.activeMesoIndex = this.mesoCycles?.controls.length! - 1;

    this.onSelectMesoFromForm(this.activeMesoIndex);
  }

  getMesoName(meso: AbstractControl) {
    return meso.get('mesoname')?.value;
  }

  getTrainingDayName(trainingDay: AbstractControl) {
    return trainingDay.get('trainingDayName')?.value;
  }

  getTrainingDaySlots(trainingDay: AbstractControl) {
    return trainingDay.get('trainingSlots') as FormArray;
  }

  getUpdatedTrainingDaySlot(index: number) {
    return (this.activeFormMesoCycle?.get('trainingDays') as FormArray)
      .at(index)
      .get('trainingSlots') as FormArray;
  }

  getParameterFromTrainingSlot(trainingSlot: AbstractControl, param: string) {
    return trainingSlot.get(param)?.value;
  }

  getActiveMesoControls() {
    if (this.activeFormMesoCycle)
      return this.activeFormMesoCycle.get('trainingDays') as FormArray;

    return undefined;
  }

  log(val: any) {
    console.log(val);
  }

  log2(val: any, valt: any) {
    console.log(val, valt);
  }

  getControlsOfDay(trainingDay: AbstractControl) {
    return trainingDay.get('trainingDays') as FormArray;
  }

  findMaxExDescriptonLengthInTemplateTrainingPlan(tp: TemplateTrainingPlan) {
    let lengthValues: number[] = [];

    tp.trainingDays?.forEach((td) => {
      td.trainingSlots?.forEach((slot) => {
        lengthValues.push(slot.exerciseDescription?.length!);
      });
    });

    return Math.max(...lengthValues);
  }

  findMaxLengthOfInputInForm(parameter: string, val: number, offset: number) {
    let lenghtValues: number[] = [];
    (
      this.activeFormMesoCycle?.get('trainingDays') as FormArray
    ).controls.forEach((control) => {
      (control.get('trainingSlots') as FormArray).controls.forEach((slot) => {
        if (slot.get(parameter)!.value === null) {
          lenghtValues.push(15);
        } else {
          lenghtValues.push((slot.get(parameter)!.value as string).length);
        }
      });
    });

    return Math.max(...lenghtValues) * val + offset;
  }

  findMaxLengthOfTrainingDayName(val: number) {
    let lenghtValues: number[] = [];

    (
      this.activeFormMesoCycle?.get('trainingDays') as FormArray
    ).controls.forEach((control) => {
      lenghtValues.push(
        (control.get('trainingDayName')!.value as string).length,
      );
    });

    return Math.max(...lenghtValues) * val + 35;
  }

  onSubmit() {}

  calculateExDescriptionWidth() {
    let val = this.findMaxLengthOfInputInForm('exerciseDescription', 7.85, 0);
    if (val > this.exerciseDescriptionWidth) {
      this.exerciseDescriptionWidth = val;
    }
  }

  calculateExNameWidth() {
    let val = this.findMaxLengthOfInputInForm('exerciseName', 8.5, 30);
    if (val > this.exerciseNameWidth) {
      this.exerciseNameWidth = val;
    }
  }

  calculateTrainingDayNameWidth() {
    let val = this.findMaxLengthOfTrainingDayName(8.5);

    if (val > this.trainingDayNameWidth) {
      this.trainingDayNameWidth = val;
    }
  }

  setInputWidths() {
    let valDesc = this.findMaxLengthOfInputInForm(
      'exerciseDescription',
      7.85,
      0,
    );
    if (valDesc > this.exerciseDescriptionWidth) {
      this.exerciseDescriptionWidth = valDesc;
    }

    let valNam = this.findMaxLengthOfInputInForm('exerciseName', 8.5, 30);
    if (valNam > this.exerciseNameWidth) {
      this.exerciseNameWidth = valNam;
    }

    this.calculateTrainingDayNameWidth();
  }

  onGoBack() {
    // this.location.back();
    this.router.navigate(['/coach-assigned-trainingplans']);
  }

  onOpenEditVideoLink(
    trainingSlot: AbstractControl,
    trainingDay: AbstractControl,
  ) {
    (trainingDay.get('trainingSlots') as FormArray).controls.forEach(
      (slotForm) => {
        if (slotForm.get('slot')?.value === trainingSlot.get('slot')?.value) {
          this.openVideoEditModal(trainingSlot);
        }
      },
    );
  }

  openVideoEditModal(trainingSlot: AbstractControl) {
    this.toggleVideoEditMenu = !this.toggleVideoEditMenu;
    this.selectForEditVideoLink = trainingSlot;
  }

  onClosingVideoLinkEdit(val: boolean) {
    this.toggleVideoEditMenu = !this.toggleVideoEditMenu;
  }

  toggleActiveInput(input: HTMLInputElement, index: number, day: number): void {
    this.activeInput = this.activeInput === input ? null : input;
    if (index === 0 && day === 0) {
      this.activeInputIndex = 0;
      this.activeTrainingDayIndex = 0;
    } else {
      this.activeInputIndex = this.activeInputIndex === index ? null : index;
      this.activeTrainingDayIndex = this.activeInputIndex === day ? null : day;
    }
  }

  filterExercisesByName(value: HTMLInputElement, index: number, day: number) {
    this.calculateExNameWidth();
    this.activeInput = value;

    if (index === 0 && day === 0) {
      this.activeInputIndex = 0;
      this.activeTrainingDayIndex = 0;
    } else {
      this.activeInputIndex = index;
      this.activeTrainingDayIndex = day;
    }

    this.showDropdown = true;
    const val = value.value;

    this.filteredExercises = this.backupExercises.filter((element) => {
      return element.exerciseName!.toLowerCase().includes(val.toLowerCase());
    });
  }

  patchExerciseOnSlot(exercise: Exercise, trainingSlot: AbstractControl) {
    if (exercise) {
      trainingSlot?.get('exerciseName')?.patchValue(exercise.exerciseName, {
        onlySelf: false,
        emitEvent: true,
      });
      trainingSlot
        ?.get('exerciseDescription')
        ?.patchValue(exercise.exerciseDescription, {
          onlySelf: false,
          emitEvent: true,
        });
      trainingSlot
        ?.get('exerciseId')
        ?.patchValue(exercise.id, { onlySelf: false, emitEvent: true });
    }
  }

  onClick(event: any) {
    if (event.target !== this.activeInput) this.activeInput = null;

    if (!this._eref.nativeElement.contains(this.activeInput)) {
      this.activeInput = null;
      this.activeInputIndex = null;
      this.activeTrainingDayIndex = null;
    }
    // or some similar check
  }
}
