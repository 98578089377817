import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, first, map, of, switchMap, take } from 'rxjs';
import { AppUser, UserRole } from '../core/thecoach';

@Injectable({
  providedIn: 'root',
})
export class LoginActiveGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //   console.log('starting loginguard')
    return this.authService.appUser$.pipe(
      map((appUser) => {
        //     console.log('login-active guard',appUser);
        if (!appUser || appUser === null) {
          return true;
        }

        switch (appUser!.role) {
          case UserRole.Client:
            return this.router.parseUrl('/dashboard');
          case UserRole.Coach:
            return this.router.parseUrl('/coach-dashboard');
          case UserRole.Admin:
            return this.router.parseUrl('admin/dashboard');
          default:
            return this.router.parseUrl('/dashboard');
        }
      }),
    );

    /*
    return this.authService.appUser$.pipe(
      map((appUser) => {
        console.log(appUser);
        return false;
      })
    );


    let val: boolean | UrlTree = false;
    this.authService.appUser$.subscribe((res) => {
      console.log(res);
      if (res) {
        val = this.router.parseUrl('/dashboard');
      }

      val = false;
    });

    return val;
    console.log(this.authService.isLoggedIn);
    if (!this.authService.isLoggedIn) {
      if (state.url !== '/home') {
        return false;
      }
    } else {
      console.log('naving to dashboard...');
      // return this.router.navigateByUrl('/dashboard');
      console.log(this.router.parseUrl('/dashboard'));
      return false;
    }

    console.log('Exceptional Case');
    return true;

    /*
    return this.authService.appUser$.pipe(
      map((appUser) => {
        switch (appUser!.role) {
          case UserRole.Client:
   npo         return this.router.parseUrl('/dashboard');
          case UserRole.Coach:
            console.log('test');
            return this.router.parseUrl('/coach-dashboard');
          case UserRole.Admin:
            return this.router.parseUrl('/admin-dashboard');
          default:
            return this.router.parseUrl('/dashboard');
        }
      })
    );*/
  }
}
