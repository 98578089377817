import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  template: `
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      *ngIf="isNotification"
      aria-live="assertive"
      (click)="close()"
      class="fixed inset-0 flex z-10 items-end px-4 py-6 sm:items-start sm:p-6 "
      [ngClass]="{ 'bg-gray-900 bg-opacity-50 ': !disableBackground }"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!--
      Notification panel, dynamically insert this into the live region when it needs to be displayed

      Entering: "transform ease-out duration-300 transition"
        From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        To: "translate-y-0 opacity-100 sm:translate-x-0"
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div
          @opacityTranslateY
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ messageHeader }}
                </p>
                <p class="mt-1 text-sm text-gray-500">{{ messageBody }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  (click)="close()"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                >
                  <svg
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ transform: 'translateY(0.5rem)', opacity: 0 }),
        animate(
          '300ms ease-out',
          style({ transform: 'translateY(0)', opacity: 1 }),
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(
          '100ms ease-in',
          style({ transform: 'translateY(0.5rem)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class NotificationComponent {
  @Input() messageHeader?: string;
  @Input() messageBody?: string;
  @Input() disableBackground?: boolean;
  @Output() onbuttonClick = new EventEmitter();

  isNotification = true;

  close() {
    this.isNotification = false;
    this.onbuttonClick.emit(true);
  }
}
