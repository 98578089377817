import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Firestore } from '@angular/fire/firestore';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import {
  addHours,
  addMinutes,
  addSeconds,
  getHours,
  getMinutes,
  getSeconds,
  startOfToday,
} from 'date-fns';
import * as _ from 'lodash';
import { WeightEntryData, Macros } from 'src/app/core/thecoach';

@Component({
  selector: 'app-weight-entry',
  template: `
    <div class="flex justify-center flex-col pt-6 items-center">
      <form
        [formGroup]="newFormWeightEntry"
        (ngSubmit)="onSubmit()"
        class="flex flex-col w-full h-full justify-center items-center"
      >
        <div class="w-5/6 flex justify-center flex-col items-center">
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
          >
            <!-- Heroicon name: outline/check -->
            <img src="../../assets/icons/maintain-weight-icon.png" />
          </div>
          <h3
            class="text-base my-2 font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Weight entry for {{ day | date : 'dd.MM.yyyy' }}
          </h3>

          <div class="flex justify-evenly w-full">
            <div class="relative rounded-md  w-24">
              <input
                type="text"
                name="weight"
                inputmode="decimal"
                step="0.01"
                formControlName="weight"
                id="weight"
                class="block w-full rounded-md border-gray-300  focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                placeholder="{{lastWeightEntry?.weight || '80'}}"
                aria-describedby="weight-input"
                #weightInput
              />
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
              >
                <span class="text-gray-500 " id="unit-suffix">KG</span>
              </div>
            </div>
          </div>

          <div class="text-left w-full">
            <p class="mt-2 text-sm text-gray-500 sm:text-sm ">
              Commentary ({{
                newFormWeightEntry.get('comment')!.value!.length!
              }}/150)
            </p>
          </div>
          <textarea
            type="textarea"
            name="comment"
            formControlName="comment"
            rows="3"
            maxlength="150"
            class="border-2 border-gray-300 rounded-md text-sm w-full focus:border-teal-500 focus:ring-teal-500 resize-none"
          ></textarea>
        </div>
        <div class="flex flex-row justify-around w-5/6 mt-2 pb-4">
          <button
            [disabled]="!this.newFormWeightEntry.valid"
            id="btn-save"
            type="submit"
            class="mt-3 inline-flex w-fit disabled:opacity-25 justify-center rounded-md border border-gray-300 bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-400 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Save
          </button>

          <button
            (click)="onClickEvent(false)"
            type="button"
            id="btn-cancel"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class WeightEntryComponent implements OnInit, AfterViewInit {
  @ViewChild('weightInput') weightInput: ElementRef;

  @Input() day?: Date;
  @Input() weightEntry?: WeightEntryData;
  @Input() lastWeightEntry?: WeightEntryData;

  @Output() onClick = new EventEmitter<boolean>();
  @Output() onSaveData = new EventEmitter<WeightEntryData>();
  addAchievedMacrosToggler = false;

  achievedMacrosForm = this.fb.group({
    protein: [''],
    carbs: [''],
    fat: [''],
  });

  newFormWeightEntry = this.fb.group({
    id: [''],
    date: [''],
    weight: ['', [Validators.required]],
    comment: [''],
    achieviedMacros: this.achievedMacrosForm,
  });

  constructor(private fb: FormBuilder, private renderer: Renderer2) {
    this.weightInput = {} as ElementRef;

  }
  ngAfterViewInit(): void {

    this.renderer.selectRootElement(this.weightInput.nativeElement).focus();

  }

  ngOnInit(): void {
    const currentTime = new Date();

    if (this.day) {
      this.day = addHours(this.day, getHours(currentTime));
      this.day = addMinutes(this.day, getMinutes(currentTime));
      this.day = addSeconds(this.day, getSeconds(currentTime));
    }

    this.patchForm(this.weightEntry!);
  }

  onClickEvent(value: boolean) {
    this.onClick.emit(value);
  }
  toggleAddAchievedMacros() {
    this.addAchievedMacrosToggler = !this.addAchievedMacrosToggler;
  }

  patchForm(data: WeightEntryData) {
    if (data && data.id) {
      this.newFormWeightEntry.get('id')?.patchValue(data.id);
    }
    if (data && data.date) {
      this.newFormWeightEntry.get('date')?.patchValue(data.date.toISOString());
    } else if (this.day) {
      this.newFormWeightEntry.get('date')?.patchValue(this.day.toISOString());
    } else {
      this.newFormWeightEntry.get('date')?.patchValue(new Date().toISOString());
    }

    if (data && data.weight) {
      this.newFormWeightEntry.get('weight')?.patchValue(data.weight.toString());
    }

    if (data && data.comment) {
      this.newFormWeightEntry
        .get('comment')
        ?.patchValue(data.comment.toString());
    }

    if (
      data &&
      data.achivedMacros &&
      data.achivedMacros?.protein &&
      data.achivedMacros.carbs &&
      data.achivedMacros.fat
    ) {
      this.addAchievedMacrosToggler = !this.addAchievedMacrosToggler;

      this.achievedMacrosForm
        .get('protein')
        ?.patchValue(data.achivedMacros.protein.toString());
      this.achievedMacrosForm
        .get('carbs')
        ?.patchValue(data.achivedMacros.carbs.toString());
      this.achievedMacrosForm
        .get('fat')
        ?.patchValue(data.achivedMacros.fat.toString());
    }
  }

  onSubmit() {
    // const value: WeightEntryData = this.newFormWeightEntry.value;
    //console.log(this.newFormWeightEntry.value);
    // console.log(this.newFormWeightEntry);
    const weightEntry = new WeightEntryData();

    weightEntry.id = this.newFormWeightEntry.get('id')?.value as string;

    if (this.newFormWeightEntry.get('weight')!.value!.includes(',')) {
      weightEntry.weight = Number(
        this.newFormWeightEntry.get('weight')!.value!.replace(',', '.')
      );
    } else {
      weightEntry.weight = Number(
        this.newFormWeightEntry.get('weight')!.value!
      );
    }

    weightEntry.comment = this.newFormWeightEntry.get('comment')
      ?.value as string;
    weightEntry.date = new Date(
      this.newFormWeightEntry.get('date')?.value as string
    );

    if (this.addAchievedMacrosToggler) {
      let tempProt = 0;
      let tempCarb = 0;
      let tempFat = 0;

      if (this.achievedMacrosForm.get('protein')!.value!.includes(',')) {
        tempProt = Number(
          this.achievedMacrosForm.get('protein')!.value!.replace(',', '.')
        );
      } else {
        tempProt = Number(this.achievedMacrosForm.get('protein')!.value!);
      }

      if (this.achievedMacrosForm.get('carbs')!.value!.includes(',')) {
        tempCarb = Number(
          this.achievedMacrosForm.get('carbs')!.value!.replace(',', '.')
        );
      } else {
        tempCarb = Number(this.achievedMacrosForm.get('carbs')!.value!);
      }

      if (this.achievedMacrosForm.get('fat')!.value!.includes(',')) {
        tempFat = Number(
          this.achievedMacrosForm.get('fat')!.value!.replace(',', '.')
        );
      } else {
        tempFat = Number(this.achievedMacrosForm.get('fat')!.value!);
      }

      weightEntry.achivedMacros! = {
        protein: tempProt,
        carbs: tempCarb,
        fat: tempFat,
      };
    }
    if (this.weightEntry?.refeedMacros)
      weightEntry.refeedMacros = _.cloneDeep(this.weightEntry?.refeedMacros);

    this.onSaveData.emit(weightEntry);
  }
}
