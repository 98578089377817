import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarEventData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-modal-event',
  template: `
        <div
                  class="flex w-full flex-col"
                  [ngClass]="{
                    'justify-between items-center': selectedCalendarEventEntries!.length > 0,
                    'justify-end  items-end': selectedCalendarEventEntries!.length === 0
                  }"
                >
                  <div
                    *ngFor="let inputEventData of selectedCalendarEventEntries"
                    class="mt-2 flex w-full"
                  >
                    <div class="flex w-full items-center justify-between">
                      <div
                        class="mt-2 flex w-fit justify-between"
                        *ngIf="inputEventData"
                      >
                        <div
                          class="mt-2 flex items-start space-x-3 text-xs w-full"
                        >
                          <dt class="mt-0.5 ">
                            <div
                              class="mx-auto flex h-3 w-3 items-center justify-center rounded-full"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                                />
                              </svg>
                            </div>
                          </dt>
                          <dd class="flex text-justify text-xs flex-col">
                            <div class="font-bold">{{ inputEventData.title }} </div>
                           <div class="italic">From: {{inputEventData.start | date: "dd-MM-yyyy"}} To: {{inputEventData.end | date: "dd-MM-yyyy"}}</div>
                            <div class="break-normal whitespace-pre-wrap" [innerHTML]="inputEventData.description"></div>
                          </dd>
                        </div>
                      </div>

                      <div class="flex justify-end">
                      <button
                        type="button"
                       (click)="
                          deleteEvent(inputEventData); $event.stopPropagation()
                        "
                        class="w-fit mr-4 h-fit rounded-md bg-red-600 py-0.5 px-0.5 text-sm font-semibold text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>

                      <button
                        type="button"
                        (click)="
                          newCalendarEventToggle(inputEventData);
                          $event.stopPropagation()
                        "
                        class="w-8 h-8 rounded-md bg-teal-600 pt-1 pb-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <img
                          class="w-full h-auto invert"
                          src="../../assets/icons/event-checked.png"
                        />
                      </button>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full justify-end">
                  <button
                    type="button"
                    (click)="newCalendarEventToggle(); $event.stopPropagation()"
                    class=" w-8 h-8 rounded-md bg-teal-600 pt-1 pb-1.5 px-1.5 text-sm font-semibold text-white shadow hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    <img
                      class="w-full h-auto invert"
                      src="../../assets/icons/event-unchecked.png"
                    />
                  </button>
                  </div>
                </div>
  `,
  styles: [
  ]
})
export class ModalEventComponent {
  @Input() selectedCalendarEventEntries?: CalendarEventData[];
  @Input() selectedDate?: Date;
  @Output() selectedEvent: EventEmitter<{ mode: boolean, data: CalendarEventData | undefined }> = new EventEmitter();



  newCalendarEventToggle(event?: CalendarEventData) {
    if (event) { this.selectedEvent.emit({ mode: true, data: event }); } else if (this.selectedDate) {
      this.selectedEvent.emit({ mode: true, data: { start: this.selectedDate, end: this.selectedDate } });


    } else {

      this.selectedEvent.emit({ mode: true, data: undefined });

    }
  }

  deleteEvent(event: CalendarEventData) {
    if (event) this.selectedEvent.emit({ mode: false, data: event });
  }
}
