import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isBefore, isSameDay } from 'date-fns';

@Component({
  selector: 'app-date-range-picker',
  template: `
  <div class="flex w-fit flex-row justify-between items-center">
  <div class="realtive z-10">
     <span class="ml-4">From:</span>



    <button type="button" (click)="toggleEndDatePicker()"
    class="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-100 p-2 text-sm font-bold"
    >
  {{endDate | date: 'dd-MM-yyyy'}}
  </button>

    <div
    @AnimationTrigger0
    *ngIf="datePickerEndToggle"
    class="absolute z-10 mt-1 h-fit w-60 overflow-auto rounded-lg bg-white py-2 text-base shadow ring-1 ring-black ring-opacity-5">
        <div class="w-full flex justify-center items-center">

<app-date-picker [preSelectedDay]="endDate" (onSelectedDay)="onSetEndDate($event)"></app-date-picker>
        </div>
  </div>


  </div>
    

 <span class="">To:</span>
   <div class="realtive z-10">
<button type="button" (click)="toggleStartDatePicker()"
    class="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-100 p-2 text-sm font-bold"
    >
    {{startDate | date: 'dd-MM-yyyy'}}
    </button>

      <div
      @AnimationTrigger0
      *ngIf="datePickerStartToggle"
      class="absolute z-10 mt-1 h-fit w-60 overflow-auto rounded-lg bg-white py-2 text-base shadow ring-1 ring-black ring-opacity-5">
        <div class="w-full flex justify-center items-center">
          <app-date-picker [preSelectedDay]="startDate" (onSelectedDay)="onSetStartDate($event)"></app-date-picker>
        </div>
      </div>
  </div>

  <button
  (click)="onLoadButton()"
  [disabled]="checkDates()"
  type="button" class="rounded-md flex flex-row ml-4 items-center bg-teal-600 px-1.5 py-0.5
  disabled:opacity-25
  text-sm
   font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2
    focus-visible:outline-offset-2 focus-visible:outline-teal-600">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

    
    Refresh Data</button>


</div>
  `,
  styles: [
  ],
  animations: [

    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ])
  ],


})
export class DateRangePickerComponent {
  @Input() startDate!: Date
  @Input() endDate!: Date

  @Output() emitDateRange = new EventEmitter<{ start: Date, end: Date }>();

  datePickerStartToggle = false;
  datePickerEndToggle = false;

  checkDates() {
    if (isSameDay(this.endDate, this.startDate)) {
      return false;
    } else if (isBefore(this.endDate, this.startDate)) {
      return false;
    } else {
      return true;
    }
  }

  onLoadButton() {
    this.emitDateRange.emit({ start: this.startDate, end: this.endDate })
  }

  toggleStartDatePicker() {
    this.datePickerStartToggle = !this.datePickerStartToggle

    if (this.datePickerEndToggle) this.datePickerEndToggle = !this.datePickerEndToggle
  }

  toggleEndDatePicker() {
    this.datePickerEndToggle = !this.datePickerEndToggle;

    if (this.datePickerStartToggle) this.datePickerStartToggle = !this.datePickerStartToggle;
  }

  onSetStartDate(date: Date) {
    if (date) {
      this.startDate = date
      this.toggleStartDatePicker()
    }
  }

  onSetEndDate(date: Date) {
    if (date) {
      this.endDate = date
      this.toggleEndDatePicker()
    }
  }
}
