import { Injectable, NgZone } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  first,
  from,
  map,
  of,
  switchMap,
  take,
  timeout,
  catchError,
  timer,
  firstValueFrom,
} from 'rxjs';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import * as auth from 'firebase/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AppUser, UserRole } from '../core/thecoach';
import { UserService } from './user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { User } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  appUser$ = new ReplaySubject<AppUser | null>(1);
  private loggedIn$ = new BehaviorSubject<boolean>(false);
  private creatingUser$ = new BehaviorSubject<boolean>(false);

  userData: any; // Save logged in user data
  constructor(
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private router: Router,
    private db: AngularFirestore,
    private userService: UserService,
    private functions: AngularFireFunctions,
  ) {
    this.afAuth.authState
      .pipe(
        switchMap((user) => {
          // console.log(user);
          if (user) {
            //console.log('Checking this', user);

            if (!this.creatingUser$.value) {
              return this.db
                .collection<AppUser>('users')
                .doc(user.uid)
                .valueChanges();
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((appUser) => {
        //   console.log('constructor', appUser);

        this.appUser$.next(appUser || null);
        this.loggedIn$.next(!!appUser);
      });
  }

  createUserIfNotExists(user: firebase.default.User) {
    const userRef = this.db.collection<AppUser>('users').doc(user.uid);
    return userRef.get().pipe(
      switchMap((doc) => {
        if (!doc.exists) {
          return of(null);
        } else {
          return of(doc.data() as AppUser);
        }
      }),
    );
  }

  authStatusListener() {
    this.afAuth.onAuthStateChanged((credential) => {
      // console.log('testing');
      if (credential) {
        this.appUser$.next(credential as AppUser);
      } else {
        this.appUser$.next(null);
      }
    });
  }

  getUser() {
    return this.appUser$.asObservable();
  }

  get isLoggedInAppUser() {
    return this.loggedIn$.asObservable();
  }

  async SignUp(
    email: string,
    password: string,
    name: string,
    coachId: string,
    coachReg: boolean,
  ) {
    this.creatingUser$.next(true);
    let result = await this.afAuth.createUserWithEmailAndPassword(
      email,
      password,
    );
    //localStorage.setItem('user', JSON.stringify(result.user));

    return this.saveNewUser(result.user, name, coachId, coachReg);
  }

  saveNewUser(user: any, name: string, coachId: string, coachReg: boolean) {
    let userData: AppUser;
    if (!coachReg) {
      userData = {
        id: user.uid,
        email: user.email,
        displayName: name,
        coachId: coachId,
        pending: true,
        enableWeightLog: false,
        enableTraining: false,
        enableCMFs: false,
        enableCTMs: false,
        enableMealPlans: false,
        enableMacroTracking: false,

        registrationDate: new Date(),
        role: user.role ? user.role : UserRole.Client,
      };
    } else {
      userData = {
        id: user.uid,
        email: user.email,
        displayName: name,
        registrationDate: new Date(),
        role: user.role ? user.role : UserRole.Coach,
      };
    }

    const userRef: AngularFirestoreDocument<AppUser> = this.db.doc(
      `users/${user.uid}`,
    );

    //console.log('beforesubscribe');
    return userRef
      .set(userData)
      .then(() => {
        this.creatingUser$.next(false);
        this.appUser$.next(userData);
        //  console.log('USER WRITTEN');

        //  localStorage.removeItem('user');
      })
      .catch((error) => {
        //localStorage.removeItem('user');
        this.creatingUser$.next(false);
        this.appUser$.next(null);
        //console.log('ERROR WRITING', error);

        throw error;
      });
  }

  async SignUp_old2(
    email: string,
    password: string,
    name: string,
    coachId: string,
    coachReg: boolean,
    token: string,
  ) {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password,
      );
      if (!result.user) throw new Error('User creation failed');

      localStorage.setItem('user', JSON.stringify(result.user));

      await result.user.updateProfile({ displayName: name });

      this.appUser$.next(result.user as AppUser);

      await timer(5000).toPromise(); // Wait for 5 seconds to ensure authentication state propagation

      await this.SetUserDataAtSignUp(result.user, name, coachId, coachReg);
      localStorage.removeItem('user');
      await this.closeInviteOnReg(token);
      await this.SendVerificationMail();
      this.router.navigate(['verify-email']);
    } catch (error) {
      console.error('Error during SignUp:', error);
      throw error;
    }
  }
  // Sign up with email/password
  SignUp_old(
    email: string,
    password: string,
    name: string,
    coachId: string,
    coachReg: boolean,
    token: string,
  ) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user) {
          //console.log('Result ', result);
          return result.user.getIdTokenResult().then((idTokenResult) => {
            return result
              .user!.updateProfile({ displayName: name })
              .then(async () => {
                this.appUser$.next(result.user as AppUser);

                this.appUser$.pipe(first()).subscribe((user) => {
                  //      console.log('user logged', user);
                });

                //  console.log('idtoken ', idTokenResult);
                //console.log('user Set!');
                /*
                await this.SetUserDataAtSignUp(
                  result.user,
                  name,
                  coachId,
                  coachReg,
                );*/
              })
              .then(() => this.closeInviteOnReg(token))
              .then(() => this.SendVerificationMail())
              .then(() => this.newSetupData(name, coachId, coachReg))
              .then(() => {
                this.router.navigate(['verify-email']);
              });
          });
        } else {
          throw new Error('User creation failed');
        }
        /* Call the SendVerificaitonMail() function when new user sign
          up and returns promise
        if (result) {
          console.log(
            'gettVing result of create user with email and pw',
            result,
          );
          return this.afAuth.currentUser.then((result) => {
            if (!result) return console.error('NO USER');
            return this.SetUserDataAtSignUp(result, name, coachId, coachReg)
              .then(() => this.closeInviteOnReg(token))
              .then(() => result.sendEmailVerification());
            console.log('result', result);
          });
        } else {
          return console.error('NO USER');
        }*/
      })
      .catch((error) => {
        // window.alert(error.message);
        return Promise.reject(error);
      });
  }

  /*
  SetUserToDB(coachId: string, coachReg: boolean, name: string, token: string) {
    return this.appUser$.pipe(take(1)).subscribe((appuser) => {
      console.log('appuser on set', appuser);
      if (!appuser) return of(null);

      console.log('Appuser', appuser);
      return this.SetUserDataAtSignUp(appuser, name, coachId, coachReg)
        .then(() => this.closeInviteOnReg(token))
        .then(() => this.SendVerificationMail())
        .then(() => {
          this.router.navigate(['verify-email']);
        });
    });
  }
*/
  // Send email verification when a new user signs up
  SendVerificationMail() {
    //console.log('Starting to send verification email');
    return this.afAuth.currentUser
      .then((u: any) => {
        if (u) {
          //console.log('Sending email verification to:', u.email);
          return u.sendEmailVerification();
        } else {
          throw new Error('No user found for email verification');
        }
      })
      .catch((error) => {
        console.error('Error sending email verification:', error);
        throw error;
      });
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result && result.user) {
          this.userService
            .getUserFromDatabase(result.user?.uid!)
            .pipe(take(1))
            .subscribe((user) => {
              if (user) {
                this.appUser$.next(user as AppUser);

                switch (user.role) {
                  case UserRole.Client:
                    this.router.navigate(['/dashboard']);
                    break;
                  case UserRole.Coach:
                    this.router.navigate(['/coach-dashboard']);
                    break;
                  case UserRole.Admin:
                    this.router.navigate(['/coach-dashboard']);
                    break;
                  default:
                    this.router.navigate(['/dashboard']);
                }
              }
            });

          // this.SetUserData(result.user);
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  newSetupData(name: string, coachId: string, coachReg: boolean) {
    //console.log('Starting to set user');
    return this.afAuth.currentUser
      .then((user: any) => {
        if (user) {
          let userData: AppUser;

          if (!coachReg) {
            userData = {
              id: user.uid,
              email: user.email,
              displayName: name,
              coachId: coachId,
              pending: true,
              enableWeightLog: false,
              enableTraining: false,
              registrationDate: new Date(),
              role: user.role ? user.role : UserRole.Client,
            };
          } else {
            userData = {
              id: user.uid,
              email: user.email,
              displayName: name,
              registrationDate: new Date(),
              role: user.role ? user.role : UserRole.Coach,
            };
          }
          this.appUser$.next(userData);

          return this.db
            .collection<AppUser>('users')
            .doc(user.id)
            .set(userData);
        } else {
          throw new Error('No user found for setting in DB');
        }
      })
      .catch((error) => {
        console.error('Error setting User in DB', error);
        throw error;
      });
  }

  async SetUserDataAtSignUp(
    user: any,
    name: string,
    coachId: string,
    coachReg: boolean,
  ) {
    //console.log('Setting up user data User->', user);
    /*
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${user.uid}`,
    );*/
    //console.log('setting ref', userRef);
    let userData: AppUser;

    if (!coachReg) {
      userData = {
        id: user.uid,
        email: user.email,
        displayName: name,
        coachId: coachId,
        pending: true,
        enableWeightLog: false,
        enableTraining: false,
        registrationDate: new Date(),
        role: user.role ? user.role : UserRole.Client,
      };
    } else {
      userData = {
        id: user.uid,
        email: user.email,
        displayName: name,
        registrationDate: new Date(),
        role: user.role ? user.role : UserRole.Coach,
      };
    }

    //console.log('Prepared user data:', userData);
    this.appUser$.next(userData);
    //console.log('new User set');
    this.afAuth.user.pipe(first()).subscribe((user) => {
      // console.log('Currentuser ', user);
    });
    await new Promise((resolve) => setTimeout(resolve, 5000));
    return this.appUser$.pipe(first()).subscribe((user) => {
      //console.log('Checking USer befor write', user);
      if (!user) return of(null);

      //console.log('async appuser before set', user);

      return this.db.collection<AppUser>('users').doc(user.id).set(userData);
    });
  }

  async closeInviteOnReg(id: string) {
    //console.log('starting to close invite...');
    const closeInvite = this.functions.httpsCallable('closeInvite');
    from(closeInvite(id))
      .pipe(
        timeout(5000),
        first(),
        catchError((error) => {
          console.error('Error calling function: ', error);
          return of(null);
        }),
      )
      .subscribe((result) => {
        //  console.log(result);
        if (result) {
          return Promise.resolve();
        } else {
          return console.error('Problem closing inv');
        }
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      if (res) {
        this.router.navigate(['home']);
      }
    });
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['home']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${user.uid}`,
    );

    const userData: AppUser = {
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      role: user.role ? user.role : UserRole.Client,
    };

    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.appUser$.next(null);
      this.loggedIn$.next(false);
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }
}
