import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ChartType, Chart, ChartItem } from 'chart.js';
import { WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-mini-macro-pie',
  template: `
    <div class="flex flex-row w-full justify-evenly mb-2">
      <div class="w-20 text-center">
        <span class="text-xs">Protein</span>
        <canvas id="pChart-{{ position }}">{{ proteinChart }}</canvas>
      </div>

      <div class="w-20 text-center">
        <span class="text-xs">Carbs</span>
        <canvas id="cChart-{{ position }}">{{ carbChart }}</canvas>
      </div>
      <div class="w-20 text-center">
        <span class="text-xs">Fat</span>
        <canvas id="fChart-{{ position }}">{{ fatChart }}</canvas>
      </div>
    </div>
  `,
  styles: [],
})
export class MiniMacroPieComponent implements AfterViewInit {
  @Input() logs: WeightEntryData[] = [];
  @Input() position?: number;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  doughnutChartType: ChartType = 'doughnut';
  proteinChart: ChartItem | undefined;
  carbChart: ChartItem | undefined;
  fatChart: ChartItem | undefined;

  ngAfterViewInit(): void {
    this.loadCharts();
    this.changeDetectorRef.detectChanges();
  }

  loadCharts() {
    let tempLog = this.logs.filter((log) => log.achivedMacros);
    const proteins = tempLog
      .map((log) => log.achivedMacros?.protein)
      .filter((val) => typeof val === 'number' && !isNaN(val))
      .reverse();
    const carbs = tempLog
      .map((log) => log.achivedMacros?.carbs)
      .filter((val) => typeof val === 'number' && !isNaN(val))
      .reverse();
    const fats = tempLog
      .map((log) => log.achivedMacros?.fat)
      .filter((val) => typeof val === 'number' && !isNaN(val))
      .reverse();

    const getAverage = (array: number[]) => {
      const sum = array.reduce((acc, val) => acc + val, 0);
      return Math.round(sum / array.length);
    };

    this.createChart(
      getAverage(proteins as number[]),
      this.logs[this.logs.length - 1].setMacros?.protein as number,
      this.proteinChart!,
      'pChart-' + this.position,
      'Protein',
      { red: 249, green: 115, blue: 22 } //orange 500
    );

    this.createChart(
      getAverage(carbs as number[]),
      this.logs[this.logs.length - 1].setMacros?.carbs as number,
      this.carbChart!,
      'cChart-' + this.position,
      'Carbs',
      { red: 34, green: 197, blue: 74 } //green 500
    );

    this.createChart(
      getAverage(fats as number[]),
      this.logs[this.logs.length - 1].setMacros?.fat as number,
      this.fatChart!,
      'fChart-' + this.position,
      'Fats',
      { red: 59, green: 130, blue: 246 } //blue 500
    );
  }

  createChart(
    macroValue: number,
    setValue: number,
    chartObj: ChartItem,
    idName: string,
    labelName: string,
    colorCode: { red: number; green: number; blue: number }
  ) {
    if (Chart.getChart(idName) != undefined) {
      Chart.getChart(idName)?.destroy();
    }

    const tintFactor = 0.75;
    const shadeFactor = 0.5;

    const color =
      'rgb(' +
      colorCode.red +
      ',' +
      colorCode.green +
      ',' +
      colorCode.blue +
      ')';
    let backcolor: string;

    if (setValue - macroValue >= 0) {
      backcolor =
        'rgb(' +
        Math.round(colorCode.red + (255 - colorCode.red) * tintFactor) +
        ',' +
        Math.round(colorCode.green + (255 - colorCode.green) * tintFactor) +
        ',' +
        Math.round(colorCode.blue + (255 - colorCode.blue) * tintFactor) +
        ')';
    } else {
      backcolor =
        'rgb(' +
        Math.round(colorCode.red * (1 - shadeFactor)) +
        ',' +
        Math.round(colorCode.green * (1 - shadeFactor)) +
        ',' +
        Math.round(colorCode.blue * (1 - shadeFactor)) +
        ')';
    }

    const chartdata = {
      labels: [labelName],
      datasets: [
        {
          label: labelName,
          data: [macroValue, setValue - macroValue],
          backgroundColor: [color, backcolor],
        },
      ],
    };

    const config = {
      type: this.doughnutChartType,
      data: chartdata,
      options: {
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },

        cutout: '70%',
        elements: {
          arc: {
            borderWidth: 1,
          },
        },
      },
      plugins: [
        {
          id: 'text',
          beforeDraw: function (chart: any, a: any, b: any) {
            let width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            let fontSize = (height / 114).toFixed(2);
            ctx.font = fontSize + 'em sans-serif';
            ctx.textBaseline = 'middle';

            let text = macroValue.toString() + ' / ' + setValue.toString(),
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;

            ctx.fillText(text, textX, textY);

            let val = (setValue - macroValue).toString();
            (textX = Math.round((width - ctx.measureText(val).width) / 2)),
              (textY = height / 2);

            ctx.font = 'bold ' + fontSize + 'em sans-serif';
            ctx.fillText(val, textX, textY + 10);

            ctx.save();
          },
        },
      ],
    };

    chartObj = new Chart(idName, config);
  }
}
