import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppUser, calculateAgeMaster } from 'src/app/core/thecoach';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-panel',
  template: `
    <div
      class="overflow-hidden w-fit px-4 py-4 shadow sm:rounded-md sm:px-6"
      [ngClass]="{
        'bg-gray-200 ring-2 ring-gray-700':
          !client.enableWeightLog && !client.enableTraining,
      }"
    >
      <div class="relative">
        <div
          class="flex flex-row"
          [ngClass]="{
            'opacity-25 ': !checkSpotAvailability(quantity),
          }"
        >
          <img
            class="h-20 w-20 flex-shrink-0 rounded-full bg-gray-300"
            src="{{
              client.imgUrl || '.././assets/images/default-person-image.webp'
            }}"
          />
          <div class="flex flex-col ml-4">
            <h3>
              {{ client.displayName }}
            </h3>
            <div class="text-gray-400 text-xs italic">
              <p>ID: {{ client.id }}</p>
              <p>E-Mail: {{ client.email }}</p>
              <p *ngIf="client.birthdate">
                Birthday:
                {{ client.birthdate | date: 'dd-MM-yyyy' }} ({{
                  calculateAge(client.birthdate!)
                }})
              </p>
              <p *ngIf="client.startingWeight">
                Starting Weight: {{ client.startingWeight }} kg
              </p>
              <p *ngIf="client.height">Height: {{ client.height }} cm</p>
            </div>
          </div>
        </div>
        <div
          (click)="onDeleteClient(client)"
          class="absolute -top-4 -right-6 text-red-400 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>

      <div class="flex w-full flex-row justify-end">
        <div
          class="flex w-6/12 justify-end"
          *ngIf="
            !client.enableMacroTracking ||
              !client.enableMealPlans ||
              !client.enableCMFs ||
              !client.enableCTMs ||
              !client.enableTraining ||
              !client.enableWeightLog;
            else disable
          "
        >
          <button
            (click)="enableAllToggles(client)"
            type="button"
            class="rounded-md bg-teal-600 px-0.5 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Enable All
          </button>
        </div>

        <ng-template #disable>
          <div class="flex w-6/12 justify-end">
            <button
              (click)="disableAllToggles(client)"
              type="button"
              class="rounded-md bg-teal-600 px-0.5 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Disable All
            </button>
          </div>
        </ng-template>
      </div>
      <div class="relative pb-4 pt-0">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
      </div>

      <div
        class="flex w-full flex-col"
        [ngClass]="{
          'opacity-25 pointer-events-none': !checkSpotAvailability(quantity),
        }"
      >
        <div class="flex w-full flex-wrap flex-row">
          <div class="flex flex-1 items-center">
            <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->

            <button
              type="button"
              (click)="toggleWeightLog(client)"
              [ngClass]="{
                'bg-teal-600': client.enableWeightLog,
                'bg-gray-600': !client.enableWeightLog,
              }"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-5': client.enableWeightLog,
                  'translate-x-0': !client.enableWeightLog,
                }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
            <span class="ml-3 text-sm" id="annual-billing-label">
              <span class="font-medium text-gray-900">Weightlog</span>
            </span>
          </div>
          <div class="flex flex-1 items-center">
            <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
            <button
              type="button"
              (click)="toggleTraining(client)"
              [ngClass]="{
                'bg-teal-600': client.enableTraining,
                'bg-gray-600': !client.enableTraining,
              }"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-5': client.enableTraining,
                  'translate-x-0': !client.enableTraining,
                }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
            <span class="ml-3 text-sm" id="annual-billing-label">
              <span class="font-medium text-gray-900">Training</span>
            </span>
          </div>
        </div>
        <div class="flex flex-row w-full justify-between mt-2">
          <div class="flex flex-1 items-center">
            <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
            <button
              type="button"
              (click)="toggleCTMs(client)"
              [ngClass]="{
                'bg-teal-600': client.enableCTMs,
                'bg-gray-600': !client.enableCTMs,
              }"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-5': client.enableCTMs,
                  'translate-x-0': !client.enableCTMs,
                }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
            <span class="ml-3 text-sm" id="annual-billing-label">
              <span class="font-medium text-gray-900">CTM's</span>
            </span>
          </div>
          <div class="flex flex-1 items-center">
            <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
            <button
              type="button"
              (click)="toggleMealPlans(client)"
              [ngClass]="{
                'bg-teal-600': client.enableMealPlans,
                'bg-gray-600': !client.enableMealPlans,
              }"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-5': client.enableMealPlans,
                  'translate-x-0': !client.enableMealPlans,
                }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
            <span class="ml-3 text-sm" id="annual-billing-label">
              <span class="font-medium text-gray-900">Mealplans</span>
            </span>
          </div>
        </div>

        <div class="flex flex-row w-full justify-between mt-2">
          <div class="flex flex-1 items-center">
            <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
            <button
              type="button"
              (click)="toggleMacroTracking(client)"
              [ngClass]="{
                'bg-teal-600': client.enableMacroTracking,
                'bg-gray-600': !client.enableMacroTracking,
              }"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-5': client.enableMacroTracking,
                  'translate-x-0': !client.enableMacroTracking,
                }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
            <span class="ml-3 text-sm" id="annual-billing-label">
              <span class="font-medium text-gray-900">Macros Tracking</span>
            </span>
          </div>

          <div class="flex flex-1 items-center">
            <!-- Enabled: "bg-teal-600", Not Enabled: "bg-gray-200" -->
            <button
              type="button"
              (click)="toggleCheckinForms(client)"
              [ngClass]="{
                'bg-teal-600': client.enableCMFs,
                'bg-gray-600': !client.enableCMFs,
              }"
              class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
            >
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-5': client.enableCMFs,
                  'translate-x-0': !client.enableCMFs,
                }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
            <span class="ml-3 text-sm" id="annual-billing-label">
              <span class="font-medium text-gray-900">Checkin Forms</span>
            </span>
          </div>
        </div>
      </div>
      <!-- Your content -->
    </div>
  `,
  styles: [],
})

//Legacy use Client-Panel
export class CustomerPanelComponent {
  @Input() client!: AppUser;
  @Input() user!: AppUser;
  @Input() quantity!: number;
  @Output() delete = new EventEmitter<AppUser>();

  constructor(private userService: UserService) {}

  checkSpotAvailability(quantity: number) {
    if (quantity && !this.user.clientIds) {
      return true;
    }

    if (this.user.clientIds && quantity >= this.user.clientIds?.length) {
      return true;
    } else if (
      this.user.coachSpotId &&
      this.user.coachSpotId === this.client.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  calculateAge(birthday: Date) {
    return calculateAgeMaster(birthday);
  }

  disableAllToggles(client: AppUser) {
    client.enableCMFs = false;
    client.enableCTMs = false;
    client.enableTraining = false;
    client.enableMealPlans = false;
    client.enableWeightLog = false;
    client.enableMacroTracking = false;

    this.userService.saveUserData(client);
  }
  enableAllToggles(client: AppUser) {
    client.enableCMFs = true;
    client.enableCTMs = true;
    client.enableTraining = true;
    client.enableMealPlans = true;
    client.enableWeightLog = true;
    client.enableMacroTracking = true;

    this.userService.saveUserData(client);
  }
  toggleWeightLog(client: AppUser) {
    if (
      client.enableWeightLog === undefined ||
      client.enableWeightLog === null
    ) {
      client.enableWeightLog = true;
    } else {
      client.enableWeightLog = !client.enableWeightLog;
    }

    this.userService.saveUserData(client);
  }

  toggleTraining(client: AppUser) {
    if (client.enableTraining === undefined || client.enableTraining === null) {
      client.enableTraining = true;
    } else {
      client.enableTraining = !client.enableTraining;
    }

    this.userService.saveUserData(client);
  }

  toggleMacroTracking(client: AppUser) {
    if (
      client.enableMacroTracking === undefined ||
      client.enableMacroTracking === null
    ) {
      client.enableMacroTracking = true;
    } else {
      client.enableMacroTracking = !client.enableMacroTracking;
    }

    this.userService.saveUserData(client);
  }

  toggleMealPlans(client: AppUser) {
    if (
      client.enableMealPlans === undefined ||
      client.enableMealPlans === null
    ) {
      client.enableMealPlans = true;
    } else {
      client.enableMealPlans = !client.enableMealPlans;
    }

    this.userService.saveUserData(client);
  }

  toggleCTMs(client: AppUser) {
    if (client.enableCTMs === undefined || client.enableCTMs === null) {
      client.enableCTMs = true;
    } else {
      client.enableCTMs = !client.enableCTMs;
    }

    this.userService.saveUserData(client);
  }

  toggleCheckinForms(client: AppUser) {
    if (client.enableCMFs === undefined || client.enableCMFs === null) {
      client.enableCMFs = true;
    } else {
      client.enableCMFs = !client.enableCMFs;
    }

    this.userService.saveUserData(client);
  }

  onDeleteClient(client: AppUser) {
    this.delete.emit(client);
  }

  addCoachSpot(client: AppUser) {
    this.user.coachSpotId = client.id;
    this.userService.saveUserData(this.user);
  }
}
