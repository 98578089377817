import { Component } from '@angular/core';

@Component({
  selector: 'app-agb',
  template: `
    <div class="p-8">
      <h1 class="text-3xl font-bold mb-4">
        Allgemeine Geschäftsbedingungen (AGB) für die Nutzung der
        Coachingplattform "thecoach.cloud"
      </h1>

      <h2 class="text-xl font-semibold mb-2">Stand: 14.08.2023</h2>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">1. Geltungsbereich</h3>
        <p>
          1.1 Diese Allgemeinen Geschäftsbedingungen (im Folgenden "AGB") gelten
          für die Nutzung der Coachingplattform "thecoach.cloud" (im Folgenden
          "Plattform") durch Trainer (im Folgenden "Coach" oder "Sie").
        </p>
        <p>
          1.2 Mit der Registrierung als Coach auf der Plattform akzeptieren Sie
          diese AGB und erklären sich damit einverstanden, an diese gebunden zu
          sein.
        </p>
      </div>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">2. Leistungen der Plattform</h3>
        <p>
          2.1 Die Plattform ermöglicht es Coaches, Slots für ihre Kunden zu
          erwerben. Ein Slot entspricht einer Monatsmitgliedschaft für den
          Zugang zu den Dienstleistungen des Coaches.
        </p>
        <p>
          2.2 Die Details zu den Leistungen, Preisen und Verfügbarkeit der Slots
          werden auf der Plattform festgelegt.
        </p>
      </div>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">3. Registrierung und Nutzung</h3>
        <p>
          3.1 Die Registrierung als Coach auf der Plattform ist erforderlich.
        </p>
        <p>
          3.2 Sie verpflichten sich, bei der Registrierung korrekte und aktuelle
          Informationen anzugeben und diese Informationen bei Änderungen zu
          aktualisieren.
        </p>
      </div>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">4. Bezahlung und Slot-Kauf</h3>
        <p>
          4.1 Die Bezahlung für Slots erfolgt im Voraus auf monatlicher Basis.
        </p>
        <p>
          4.2 Ein Slot berechtigt den Coach zur Erbringung seiner
          Dienstleistungen an einen einzelnen Kunden für einen Monat.
        </p>
        <p>
          4.3 Es ist keine Stornierung oder Rückerstattung für erworbene Slots
          möglich, da es sich um ein digitales Produkt handelt.
        </p>
      </div>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">5. Haftungsausschluss</h3>
        <p>
          5.1 Die Plattform "thecoach.cloud" stellt lediglich die technische
          Infrastruktur für die Bereitstellung von Coaching-Dienstleistungen zur
          Verfügung. Wir übernehmen keine Verantwortung für etwaige
          gesundheitliche, mentale oder sachliche Schäden, die im Zusammenhang
          mit der Nutzung der Plattform oder den erbrachten Coachings entstehen.
        </p>
        <p>
          5.2 Die Coaches sind verantwortlich für die Inhalte, Informationen und
          Ratschläge, die sie ihren Kunden während der Nutzung der Plattform
          geben.
        </p>
      </div>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">6. Änderungen der AGB</h3>
        <p>
          6.1 Die Plattform behält sich das Recht vor, diese AGB jederzeit zu
          ändern. Aktualisierte AGB werden auf der Plattform veröffentlicht.
        </p>
        <p>
          6.2 Die fortgesetzte Nutzung der Plattform nach einer Änderung der AGB
          gilt als Akzeptanz der geänderten Bedingungen.
        </p>
      </div>

      <div class="mb-6">
        <h3 class="text-lg font-semibold mb-2">7. Schlussbestimmungen</h3>
        <p>7.1 Diese AGB unterliegen dem Österreichischem Recht.</p>
        <p>
          7.2 Gerichtsstand für Streitigkeiten im Zusammenhang mit diesen AGB
          ist Wien.
        </p>
        <p>
          7.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder
          werden, berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.
        </p>
        <p>
          7.4 Es gilt die zum Zeitpunkt Ihrer Registrierung als Coach auf der
          Plattform aktuelle Version dieser AGB.
        </p>
      </div>
    </div>
  `,
  styles: [],
})
export class AgbComponent {}
