import { HttpClient } from '@angular/common/http';
import { RoleSelector } from './../../core/thecoach';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RouterEvent } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthService } from 'src/app/services/auth.service';
import { NewsletterService } from 'src/app/services/newsletter.service';

@Component({
  selector: 'app-home',
  template: `
    <!--
  This example requires some changes to your config:


  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }

-->
    <div class="bg-white">
      <!-- Header -->
      <header
        class="flex justify-center  items-center object-center inset-x-0 top-0 pt-4 mx-auto z-50"
      >
        <!-- Mobile menu, show/hide based on menu open state. -->
        <div class="w-5/6 flex justify-between items-center">
          <img src="./assets/icons/TCCLogo_text.png" class="md:h-32 h-16" />
          <button
            type="button"
            [routerLink]="['/login']"
            class="rounded-md ring-2 h-fit ring-teal-400 bg-teal-50 px-3 py-2 text-sm font-semibold text-teal-600 shadow-sm hover:bg-teal-100"
          >
            Login
          </button>
        </div>
      </header>

      <main>
        <!-- Hero section -->
        <div class="relative isolate">
          <svg
            class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width="200"
                height="200"
                x="50%"
                y="-1"
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                stroke-width="0"
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              stroke-width="0"
              fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
            />
          </svg>
          <div
            class="mx-auto max-w-7xl px-6 py-8  lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40"
          >
            <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <div class="w-full flex justify-end lg:justify-start"></div>
              <h1
                class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
              >
                Revolutionize <br />Your Coaching
              </h1>
              <p class="mt-6 text-lg leading-8 text-gray-600 text-justify">
                Effortlessly manage your coaching business - Streamline client
                progress tracking and engagement with our app - Say goodbye to
                Excel & Sheets and hello to a simplified coaching experience.
              </p>
            </div>
            <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <svg
                viewBox="0 0 366 729"
                role="img"
                class="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width="316" height="684" rx="36" />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#5E6268"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width="316"
                  height="684"
                  transform="translate(24 24)"
                  clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img
                    src="./../assets/images/ClientView/newMobileView2.webp"
                    alt=""
                  />
                </foreignObject>
              </svg>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-center">
          <div class="flex flex-col justify-center items-center ">
            <h2
              class="text-3xl font-bold italic tracking-tight pb-2 text-gray-700 sm:text-4xl"
            >
              Choose your role...
            </h2>

            <nav
              class="isolate flex w-fit divide-x divide-gray-200 rounded-lg shadow"
              aria-label="Tabs"
            >
              <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
              <a
                (click)="onRoleSelect(roleSelector.Coach)"
                class="text-gray-900  w-fit  cursor-pointer rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-xl font-medium hover:bg-gray-50 focus:z-10"
                aria-current="page"
              >
                <span>Coach</span>
                <span
                  aria-hidden="true"
                  class="bg-transparent absolute inset-x-0 bottom-0 h-0.5"
                  [ngClass]="{
                    'bg-teal-400': activeRole === roleSelector.Coach,
                    'bg-transparent': activeRole !== roleSelector.Coach
                  }"
                ></span>
              </a>
              <a
                (click)="onRoleSelect(roleSelector.Client)"
                class="text-gray-500 cursor-pointer w-fit hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-xl font-medium hover:bg-gray-50 focus:z-10"
              >
                <span>Client</span>
                <span
                  aria-hidden="true"
                  class=" absolute inset-x-0 bottom-0 h-0.5"
                  [ngClass]="{
                    'bg-teal-400': activeRole === roleSelector.Client,
                    'bg-transparent': activeRole !== roleSelector.Client
                  }"
                ></span>
              </a>
            </nav>
            <p class="pt-2 text-sm italic text-gray-400">
              Tap to load your role...
            </p>
          </div>
        </div>

        <app-customer-featurelist
          *ngIf="activeRole === roleSelector.Client"
        ></app-customer-featurelist>
        <app-coaches-featurelist
          *ngIf="activeRole === roleSelector.Coach"
        ></app-coaches-featurelist>

        <hr class=" my-10 mx-8" />

        <!-- Newsletter section -->
        <div class="hidden mx-auto mt-2 max-w-7xl sm:px-6 lg:px-8 pb-8">
          <div
            class="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32"
          >
            <h2
              class="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl"
            >
              Stay Updated: Your Latest Coaching Insights Await!
            </h2>
            <p
              class="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300"
            >
              Sign up for our newsletter to be the first to know when coaching
              updates, resoruces or exert tips arrive.
            </p>
            <form
              class="mx-auto mt-10 flex max-w-md gap-x-4"
              [formGroup]="newsletterForm"
              (ngSubmit)="onSubmit()"
            >
              <label for="email-address" class="sr-only">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                formControlName="email"
                required
                class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                class="flex-none rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm hover:cursor-pointer hover:bg-teal-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Notify me
              </button>
            </form>
            <svg
              viewBox="0 0 1024 1024"
              class="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle
                cx="512"
                cy="512"
                r="512"
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fill-opacity="0.7"
              />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stop-color="#7775D6" />
                  <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </main>
    </div>

    <footer class="bg-white">
      <div class="mx-auto max-w-7xl overflow-hidden px-6 py-4 sm:py-2 lg:px-8">
        <nav
          class="-mb-6 columns-1 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          <div class="pb-6">
            <a
              href="#"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >ABOUT</a
            >
          </div>

          <div class="pb-6">
            <a
              href="/agb"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >T&C</a
            >
          </div>

          <div class="pb-6">
            <a
              href="/gdpr"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >GDPR</a
            >
          </div>

          <div class="pb-6">
            <a
              href="/legal-notice"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >IMPRINT</a
            >
          </div>

          <div class="pb-6">
            <a
              href="/contact"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >CONTACT</a
            >
          </div>
        </nav>
        <div *ngIf="show" class="mt-10 flex justify-center space-x-10">
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Instagram</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
              />
            </svg>
          </a>

          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">GitHub</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a href="#" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">YouTube</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>
        <p class="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2023 TheCoach.cloud, All rights reserved.
        </p>
      </div>
    </footer>

    <app-notification
      *ngIf="emailSentToggle"
      [messageHeader]="'Your Email has been added!'"
      [messageBody]="'Thank you for subscribing to our Newsletter!'"
      (onbuttonClick)="dismiss()"
    ></app-notification>
  `,
  styles: [],
})
export class HomeComponent implements OnInit {
  emailSentToggle = false;
  show = false;
  roleSelector = RoleSelector;
  activeRole = RoleSelector.Coach;

  constructor(
    private fb: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private newsletterService: NewsletterService,
    private http: HttpClient,
  ) {}

  newsletterForm = this.fb.group({
    email: ['', Validators.required],
  });

  ngOnInit(): void {
    //console.log(this.authService.appUser$);
  }

  onRoleSelect(val: RoleSelector) {
    if (this.activeRole === val) {
      this.activeRole = RoleSelector.None;
    } else {
      this.activeRole = val;
    }
  }

  onSubmit() {
    if (this.newsletterForm.invalid) return;

    const email = this.newsletterForm.get('email')?.value as string;
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        if (!token) return;

        this.newsletterService.saveEmail(email).then(() => {
          this.emailSentToggle = !this.emailSentToggle;
        });
      });
  }

  dismiss() {
    this.emailSentToggle = !this.emailSentToggle;
  }
}
