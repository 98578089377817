import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FirebaseError } from 'firebase/app';
import { first, from, of, timeout, catchError, window } from 'rxjs';
import { AuthErrorConstants } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidationService } from 'src/app/services/custom-validation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  template: `
    <div
      *ngIf="validLink; else verifyingLink"
      class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto h-12 w-auto"
          src=".././assets/icons/TCCLogo-min.webp"
          alt="The Coach Cloud"
        />
        <h2
          *ngIf="!coachReg"
          class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
        >
          Create an Account
        </h2>
        <h2
          *ngIf="coachReg"
          class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
        >
          Create an Coach Account
        </h2>
      </div>

      <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            class="space-y-2"
            [formGroup]="registerForm"
            (ngSubmit)="onSubmit()"
          >
            <div>
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >Name</label
                >
                <div class="relative ">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    formControlName="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                  <div
                    *ngIf="
                      (registerFormControls['name'].touched || submitted) &&
                      registerFormControls['name'].errors?.['required']
                    "
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900  mt-2"
                  >Email address</label
                >
                <div class="relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    formControlName="email"
                    autocomplete="email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                  <div
                    *ngIf="
                      (registerFormControls['email'].touched || submitted) &&
                      registerFormControls['email'].errors?.['required']
                    "
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  class="mt-2 text-sm text-red-600"
                  id="email-error"
                  *ngIf="
                    registerFormControls['email'].touched &&
                    registerFormControls['email'].errors?.['email']
                  "
                >
                  Not a valid email address.
                </p>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >Password</label
                >
                <div class="relative ">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    formControlName="password"
                    autocomplete="current-password"
                    min="6"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                  <div
                    *ngIf="
                      (registerFormControls['password'].touched || submitted) &&
                      registerFormControls['password'].errors?.['required']
                    "
                    class="pointer-events-none absolute inset-y-0 right-0 flex
                    items-center pr-3"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  class="mt-2 text-sm text-red-600"
                  id="password-error"
                  *ngIf="
                    registerFormControls['password'].touched &&
                    registerFormControls['password'].errors?.['invalidPassword']
                  "
                >
                  Password should have minimum 8 characters, at least 1
                  uppercase letter, 1 lowercase letter and 1 number
                </p>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >Confirm Password</label
                >
                <div class="relative ">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    formControlName="confirmPassword"
                    autocomplete="current-password"
                    min="6"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                  <div
                    *ngIf="
                      (registerFormControls['confirmPassword'].touched ||
                        submitted) &&
                      registerFormControls['confirmPassword'].errors?.[
                        'required'
                      ]
                    "
                    class="pointer-events-none absolute inset-y-0 right-0 flex
                    items-center pr-3"
                  >
                    <svg
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  class="mt-2 text-sm text-red-600"
                  id="password-error"
                  *ngIf="
                    registerFormControls['confirmPassword'].touched &&
                    registerFormControls['confirmPassword'].errors?.[
                      'passwordMismatch'
                    ]
                  "
                >
                  Passwords does not match
                </p>
              </div>
            </div>

            <div class="relative flex items-start">
              <div class="flex h-6 items-center">
                <input
                  id="acceptTerms"
                  aria-describedby="acceptTerms-description"
                  formControlName="acceptTerms"
                  name="acceptTerms"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                />
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="acceptTerms" class="font-medium text-gray-900"
                  >Accept
                  <a class="bold text-teal-800" [routerLink]="['/agb']"
                    >Terms & Conditions</a
                  ></label
                >
                <p
                  id="acceptTerms-description"
                  class="text-red-500"
                  *ngIf="
                    submitted && registerFormControls['acceptTerms'].errors
                  "
                >
                  Accept Ts & Cs is required!
                </p>
              </div>
            </div>

            <div class="relative flex items-start">
              <div class="flex h-6 items-center">
                <input
                  id="acceptGDPR"
                  aria-describedby="acceptGDPR-description"
                  formControlName="acceptGDPR"
                  name="acceptGDPR"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                />
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="acceptGDPR" class="font-medium text-gray-900"
                  >Accept
                  <a class="bold text-teal-800" [routerLink]="['/gdpr']"
                    >GDPR</a
                  ></label
                >
                <p
                  id="acceptGDPR-description"
                  class="text-red-500"
                  *ngIf="submitted && registerFormControls['acceptGDPR'].errors"
                >
                  Accept GDPR is required!
                </p>
              </div>
            </div>

            <div>
              <button
                type="submit"
                [disabled]="!registerForm.valid"
                class="flex w-full justify-center disabled:opacity-25 rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Sign up
              </button>
            </div>

            <div *ngIf="errorMessage">
              <p class="font-bold text-red-500 italic">
                {{ errorMessage }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ng-template #verifyingLink>
      <div
        class="flex m-auto w-full h-full justify-center items-center max-w-md p-10"
      >
        <ngx-skeleton-loader
          class="w-full "
          count="5"
          [theme]="{
            width: '100%',
            'border-radius': '5px',
            height: '100px',
            border: '1px solid white',
          }"
        ></ngx-skeleton-loader>
      </div>
    </ng-template>
  `,
  styles: [],
})
export class RegisterComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup = this.fb.group([]);
  id: string | undefined;
  coachId: string | undefined;
  coachReg: boolean = false;
  errorMessage: string | undefined;
  validLink = false;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private customValidator: CustomValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private functions: AngularFireFunctions,
  ) {}
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    // console.log(this.id);
    this.getDataForForm();

    if (this.id && this.id.includes('C3o1a5ch')) {
      this.coachReg = true;
    }
    this.registerForm = this.fb.group(
      {
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          Validators.compose([
            Validators.required,
            this.customValidator.patternValidator(),
          ]),
        ],
        confirmPassword: ['', [Validators.required]],
        acceptGDPR: [false, Validators.requiredTrue],
        acceptTerms: [false, Validators.requiredTrue],
      },
      {
        validator: this.customValidator.MatchPassword(
          'password',
          'confirmPassword',
        ),
      },
    );
  }

  get registerFormControls() {
    return this.registerForm.controls;
  }

  getDataForForm() {
    if (this.id && this.isValidBase64Url(this.id)) {
      const checkInvite = this.functions.httpsCallable('checkInvite');
      from(checkInvite(this.id))
        .pipe(
          timeout(5000),
          first(),
          catchError((error) => {
            console.error('Error calling function: ', error);
            this.router.navigate(['/register/page-not-found']);
            return of(null);
          }),
        )
        .subscribe((result) => {
          console.log(result);
          if (result && result.validLink) {
            if (result.isCoach) {
              this.coachReg = true;
            }

            this.coachId = result.creatorId;
            this.validLink = true;
          } else {
            this.router.navigate(['/register/page-not-found']);
          }
        });
    } else {
      console.error('NOT VALID');
      this.router.navigate(['/register/page-not-found']);
    }
  }

  async onSubmit() {
    this.submitted = true;

    const value = this.registerForm.value;

    try {
      await this.authService
        .SignUp(
          value.email,
          value.password,
          value.name,
          this.coachId!,
          this.coachReg,
        )
        .catch((error) => {
          const code = error.code.slice(5, error.code.length);
          if (
            error instanceof FirebaseError &&
            code in AuthErrorConstants.authErrors
          ) {
            this.errorMessage = AuthErrorConstants.authErrors[code];
          }
        });

      await this.authService.closeInviteOnReg(this.id as string);
      await this.authService.SendVerificationMail();
      console.log('Naving to verify-email');
      // Use Router.navigate with extras
      this.router.navigate(['/verify-email']).then(() => {
        //location.reload();
      });
    } catch (error) {
      console.error('Error', error);
    }
  }

  isValidBase64Url(str: string): boolean {
    const base64UrlPattern = /^[A-Za-z0-9-_]+$/;
    const minLength = 70; //
    const maxLength = 80;
    return (
      base64UrlPattern.test(str) &&
      str.length >= minLength &&
      str.length <= maxLength
    );
  }
}
