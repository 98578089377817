import { Options } from 'ngx-slider-v2';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Form, FormGroup } from '@angular/forms';
import {
  CustomInputType,
  CustomTrackingModel,
  CustomTrackingTypeRange,
  CustomTrackingValue,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-generated-range-slider',
  template: `
    <div *ngIf="cctv" [formGroup]="cctv">
      <div class="flex flex-row justify-between">
        <p class="font-semibold text-sm">{{ cctv.get('name')!.value }}</p>
        <div class="group flex relative items-center pl-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 "
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <span
            class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-[97%] translate-y-[1.1rem] opacity-0 m-4 mx-auto"
            >{{ cctv.get('description')!.value }}</span
          >
        </div>
      </div>
      <div class="custom-slider">
        <ngx-slider
          [(value)]="value"
          [options]="options"
          formControlName="value"
        ></ngx-slider>
      </div>
    </div>
  `,
  styles: [
    `
      .custom-slider::ng-deep .ngx-slider .ngx-slider-selection {
        background: #5eead5;
      }

      .custom-slider::ng-deep .ngx-slider .ngx-slider-tick.ngx-slider-selected {
        background: #5eead4;
      }

      .custom-slider::ng-deep .ngx-slider .ngx-slider-bar {
        background: #5eead4;
        height: 2px;
      }
      .custom-slider::ng-deep .ngx-slider .ngx-slider-pointer:after {
        display: none;
      }

      .custom-slider::ng-deep .ngx-slider .ngx-slider-pointer {
        width: 16px;
        height: 26px;
        top: auto; /* to remove the default positioning */
        bottom: -10px;
        background-color: #0d9488;
      }
    `,
  ],
})
export class GeneratedRangeSliderComponent implements OnInit {
  @Input() cctv!: FormGroup;

  value: number = 5;
  options: Options = {
    step: 1,
    floor: 0,
    ceil: 10,
  };

  customInputType = CustomInputType;

  constructor() {}
  ngOnInit(): void {
    if (this.cctv && this.cctv.get('type')!.value === CustomInputType.Range) {
      this.value = Number(this.cctv.get('value')!.value);
      this.options = {
        step: Number(this.cctv.get('step')!.value),
        floor: Number(this.cctv.get('min')!.value),
        ceil: Number(this.cctv.get('max')!.value),
      };
    }
  }
}
