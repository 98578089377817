import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Macros, calculateKcalFromMacros } from 'src/app/core/thecoach';
import { Unit } from '../../../../../backend/node_modules/@prisma/client'
@Component({
  selector: 'app-mealday-stats',
  template: `
 <div class="bg-white rounded-md shadow p-4 mt-12">
  <dl class="grid grid-cols-1 gap-5 sm:grid-cols-7">
    <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Meals</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{meals.controls.length}}</dd>
    </div>
        <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Amount</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{calculateTotalGramsPerDay()}}</dd>
    </div>
    <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Proteins</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{calcuclateTotalParametersPerDay('protein')}}</dd>
    </div>
    <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Carbs</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{calcuclateTotalParametersPerDay('carb')}}</dd>
    </div>
        <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Fats</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{calcuclateTotalParametersPerDay('fat')}}</dd>
    </div>
        <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Kcal</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{calcuclateTotalKcalPerDay()}}</dd>
    </div>
            <div class="overflow-hidden rounded-lg bg-white px-2 py-2 shadow sm:p-2">
      <dt class="truncate text-sm font-medium text-gray-500">Price</dt>
      <dd class="mt-1 text-md font-semibold tracking-tight text-gray-900">{{calculateTotalPricePerDay()}}</dd>
    </div>
  </dl>
</div>

  `,
  styles: [
  ]
})
export class MealdayStatsComponent {
  @Input() dayForm!: FormGroup;

  get meals() {
    return this.dayForm.get('meals') as FormArray;
  }


  calculateTotalGramsPerDay() {
    let totalGrams = 0
    for (let i = 0; i < this.meals.controls.length; i++) {
      totalGrams += this.calculateTotalGramsPerMeal(this.meals.at(i).get('addedIngredients') as FormArray)
    }
    return totalGrams
  }


  calculateTotalGramsPerMeal(addedIngredients: FormArray) {
    let totalGrams = 0
    addedIngredients.controls.forEach((control: AbstractControl) => {
      const gramsControl = control.get('grams')
      if (gramsControl) {
        totalGrams += gramsControl.value || 0
      }
    })

    return totalGrams
  }

  calcuclateTotalParametersPerDay(parameter: string) {
    let total = 0;
    for (let i = 0; i < this.meals.controls.length; i++) {
      total += this.calculcateTotalParametersPerMeal(this.meals.at(i).get('addedIngredients') as FormArray, parameter)
    }

    return total.toFixed(2);

  }


  calculcateTotalParametersPerMeal(addedIngredients: FormArray, parameter: string) {
    let total = 0;
    addedIngredients.controls.forEach((control: AbstractControl) => {
      const ingControl = control.get('ingredient') as FormGroup


      if (ingControl) {
        const grams = control.get('grams')?.value
        const parameterControl = ingControl.get(parameter)

        if (parameterControl) {
          total += (parameterControl.value * (grams / 100)) || 0
        }
      }
    })

    return total;
  }


  calcuclateTotalKcalPerDay() {
    let total = 0;
    for (let i = 0; i < this.meals.controls.length; i++) {
      total += this.calculateTotalKcalPerMeal(this.meals.at(i).get('addedIngredients') as FormArray)
    }

    return total.toFixed(0);

  }

  calculateTotalKcalPerMeal(addedIngredients: FormArray) {
    let totalKcal = 0
    addedIngredients.controls.forEach((control: AbstractControl) => {
      const ingControl = control.get('ingredient') as FormGroup
      const grams = control.get('grams')?.value

      if (ingControl) {
        const macros: Macros = {
          protein: ingControl.get('protein')?.value,
          carbs: ingControl.get('carb')?.value,
          fat: ingControl.get('fat')?.value


        }

        if (macros) {
          totalKcal += Number(calculateKcalFromMacros(macros)) * (grams / 100) || 0
        }
      }
    })

    return totalKcal
  }



  calculatePrice(addedIngredient: AbstractControl) {
    const packageCost: number = addedIngredient.get('ingredient.packageCost')?.value
    const packageSize: number = addedIngredient.get('ingredient.packageSize')?.value
    const grams: number = addedIngredient.get('grams')?.value

    const packageUnitSize: Unit = addedIngredient.get('ingredient.packageUnitSize')?.value

    let val = 0;

    if (packageUnitSize === Unit.kg || packageUnitSize === Unit.l) {
      val = ((packageCost / (packageSize * 1000)) * grams);
    } else {
      val = ((packageCost / packageSize) * grams);
    }
    return val.toFixed(2)




  }


  calculateTotalPricePerMeal(addedIngredients: FormArray) {
    let totalPrice = 0

    addedIngredients.controls.forEach((control: AbstractControl) => {


      totalPrice += Number(this.calculatePrice(control)) || 0
    })

    return totalPrice
  }


  calculateTotalPricePerDay() {
    let total = 0;
    for (let i = 0; i < this.meals.controls.length; i++) {
      total += this.calculateTotalPricePerMeal(this.meals.at(i).get('addedIngredients') as FormArray)
    }

    return total.toFixed(2);

  }

}
