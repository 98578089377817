// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//import 'zone.js/plugins/zone-error';

export const environment = {
  recaptcha: {
    sitekey: '6LfYieAlAAAAADYT8STNGgbxkLm-aJ-g5iLzciAf',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCFEnkfMpEwk9zqQkyNrS-JKtui7-Y15Bs',
    authDomain: 'thecoach-dev.firebaseapp.com',
    projectId: 'thecoach-dev',
    storageBucket: 'thecoach-dev.appspot.com',
    messagingSenderId: '493054957889',
    appId: '1:493054957889:web:e579e5a0add8d4d9d7e9c0',
    vapidKey:
      'BEP1e0DgU5BqXa6LJNrOPkuuWPtYER0o0MVNaD4ROlO_jBIdq-LBEXo64bTO8DgRx1vAfN770-XYT20LOdjrSDU',
  },
  stirpe: {
    key: 'sk_test_51NcSKbLK2Txw2dbCuCq2cAVuBLXISiOrBALWEBpJbW6RdLhDuBEVOk4OydyEBEbTlWDoFzLxWpID7Dyl0Kz7ItbJ00le91TY9c',
  },
  useEmulators: true,
  production: false,

  apiUrl: 'https://api-d42ucbrxea-ew.a.run.app',
  version: 'dev-1.3.80',
  //apiUrl: 'http://localhost:3000',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.


firebase: {
  projectId: 'thecoach-39ebf',
    appId: '1:447213051808:web:98bfb5855f2ab8a0188ec5',
    storageBucket: 'thecoach-39ebf.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDgzpC73_rQJnAwBgH55sZWEDl6dZfq9fI',
    authDomain: 'thecoach-39ebf.firebaseapp.com',
    messagingSenderId: '447213051808',
},
 */

//  // Included with Angular CLI.
