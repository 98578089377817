import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { Exercise } from 'src/app/core/thecoach';

@Component({
  selector: 'app-exercise-search-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
  Background backdrop, show/hide based on modal state.

  Entering: "ease-out duration-300"
    From: "opacity-0"
    To: "opacity-100"
  Leaving: "ease-in duration-200"
    From: "opacity-100"
    To: "opacity-0"
-->
      <div
        class="fixed z-30 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="relative z-40 transform  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full"
                >
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Select Exercise form your Database
                  </h3>
                  <div class="mt-2  w-full">
                    <div class="w-full">
                      <div class="relative mt-1 w-full">
                        <input
                          (keyup)="filterValues(inputExerciseName)"
                          #inputExerciseName
                          id="combobox"
                          type="text"
                          [value]="getNameAndDescriptionFromSelected()"
                          class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          role="combobox"
                          aria-controls="options"
                          aria-expanded="false"
                        />
                        <button
                          type="button"
                          (click)="toggle()"
                          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                        >
                          <svg
                            class="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>

                        <ul
                          *ngIf="showDropdown"
                          class="absolute z-50 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          id="options"
                          role="listbox"
                        >
                          <!--
        Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Active: "text-white bg-teal-600", Not Active: "text-gray-900"
      -->
                          <li
                            *ngFor="let exercise of filteredExercises"
                            (click)="select(exercise)"
                            (mouseenter)="active = exercise"
                            (mouseleave)="active = null"
                            class="hover:text-white hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                            id="option-0"
                            role="option"
                            tabindex="-1"
                          >
                            <div class="flex flex-row items-center">
                              <!-- Selected: "font-semibold" -->
                              <span
                                class="flex flex-row whitespace-nowrap pr-1"
                                >{{ exercise.exerciseName }}</span
                              >
                              -
                              <span class="block truncate italic text-xs pl-1">
                                {{ exercise.exerciseDescription }}</span
                              >
                            </div>
                            <!--
          Checkmark, only display for selected option.

          Active: "text-white", Not Active: "text-teal-600"
        -->
                            <span
                              *ngIf="selected && selected.id == exercise.id"
                              [ngClass]="{
                                'text-teal-600': active?.id !== exercise.id,
                                'text-white': active?.id == exercise.id
                              }"
                              class="absolute inset-y-0 right-0 flex items-center pr-4"
                            >
                              <svg
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </li>

                          <!-- More items... -->
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 flex flex-row justify-between">
              <button
                type="button"
                (click)="onSendValue(true)"
                class="inline-flex w-fit justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto"
              >
                SAVE
              </button>
              <button
                type="button"
                (click)="onSendValue(false)"
                class="inline-flex w-fit  justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-200 sm:mt-0 sm:w-auto"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class ExerciseSearchModalComponent implements OnInit {
  @Input() exercises: Exercise[] = [];
  @Input() slotElement: AbstractControl | undefined;
  @Output() close = new EventEmitter<boolean>();

  filteredExercises: Exercise[] = [];
  showDropdown = false;
  selected: Exercise | undefined = undefined;
  active: Exercise | null = null;
  isModal = true;

  constructor() {}

  ngOnInit(): void {
    if (this.exercises) {
      this.filteredExercises = _.cloneDeep(this.exercises);
    }
  }

  onSendValue(value: boolean) {
    if (value) {
      this.slotElement
        ?.get('exerciseName')
        ?.patchValue(this.selected?.exerciseName, {
          onlySelf: false,
          emitEvent: true,
        });
      this.slotElement
        ?.get('exerciseDescription')
        ?.patchValue(this.selected?.exerciseDescription, {
          onlySelf: false,
          emitEvent: true,
        });
      this.slotElement
        ?.get('exerciseId')
        ?.patchValue(this.selected?.id, { onlySelf: false, emitEvent: true });
    }

    this.close.emit(value);
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }

  toggle() {
    this.showDropdown = !this.showDropdown;
  }

  select(exercise: Exercise) {
    this.selected = exercise;
    this.toggle();
  }

  getNameAndDescriptionFromSelected() {
    if (this.selected !== undefined) {
      return (
        this.selected.exerciseName + ' - ' + this.selected.exerciseDescription
      );
    } else {
      return '';
    }
  }

  filterValues(value: HTMLInputElement) {
    this.showDropdown = true;
    let val = value.value;

    this.filteredExercises = this.exercises.filter((element) =>
      element.exerciseName?.toLowerCase().includes(val.toLowerCase())
    );

    console.log(this.filteredExercises);
  }
}
