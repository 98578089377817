import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { eachDayOfInterval, format } from 'date-fns';
import { sl } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import { Subscription, first } from 'rxjs';
import { AppUser, convertDateObject } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-management',
  template: `
    <div *ngIf="checkForUnscheduledClients()">
      <h1 class="text-base font-semibold leading-6 text-gray-900">
        Unscheduled Clients
      </h1>
      <ul
        role="list"
        class=" w-full grid  grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-3"
      >
        <div *ngFor="let client of filteredDataBase()" class="">
          <li
            class="cursor-pointer hover:bg-gray-200 overflow-hidden w-fit  px-4 py-4 shadow sm:rounded-md sm:px-6 w-7/12"
            *ngIf="
              client.checkInWeekdayIndex === undefined ||
              client.checkInSlotIndex === undefined
            "
            (click)="onSelectClient(client)"
            [ngClass]="{
              'ring-2 ring-teal-600 bg-gray-200': client === selectedClient
            }"
          >
            <div class="flex flex-row justify-start items-center w-full">
              <img
                class="inline-block h-10 w-10 rounded-md"
                src="{{
                  client.imgUrl ||
                    '.././assets/images/default-person-image.webp'
                }}"
                alt=""
              />
              <h3 class="pl-2 italic text-sm text-gray-600 ">
                {{ client.email }}
              </h3>
            </div>
          </li>
        </div>
      </ul>
    </div>
    <div class="flex h-fit flex-col">
      <header
        class="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6"
      >
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          Schedule your clients
        </h1>
        <button
          type="button"
          class="rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          (click)="onSave()"
        >
          Save
        </button>
      </header>
      <div class="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div
          style="width: 165%"
          class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div
            class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            <div
              class="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden"
            >
              <button
                *ngFor="let day of weekdays"
                type="button"
                class="flex flex-col items-center pt-2 pb-3"
              >
                {{ day }}
              </button>
            </div>

            <div
              class="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid"
            >
              <div class="col-end-1 w-14"></div>
              <div
                class="flex items-center justify-center py-3"
                *ngFor="let day of weekdays"
              >
                <span>{{ day }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-auto h-fit">
            <div
              class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100"
            ></div>
            <div class="grid flex-auto grid-cols-1 grid-rows-1 h-fit">
              <!-- Horizontal lines -->
              <div
                class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style="grid-template-rows: repeat(20, 4rem)"
              >
                <div *ngFor="let slot of checkinSlots">
                  <div
                    class="sticky left-0 z-20 mt-5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                  >
                    {{ slot }}
                  </div>
                </div>
              </div>

              <!-- Vertical lines -->
              <div
                class="h-fit col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7"
              >
                <div class="col-start-1 row-span-full"></div>
                <div class="col-start-2 row-span-full"></div>
                <div class="col-start-3 row-span-full"></div>
                <div class="col-start-4 row-span-full"></div>
                <div class="col-start-5 row-span-full"></div>
                <div class="col-start-6 row-span-full"></div>
                <div class="col-start-7 row-span-full"></div>
                <div class="col-start-8 row-span-full w-8"></div>
              </div>

              <!-- Events -->
              <ol
                class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style="grid-template-rows: 0.5rem repeat(160, 0.5rem) auto"
              >
                <ng-container *ngFor="let day of weekdays; let i = index">
                  <li
                    *ngFor="let slot of checkinSlots; let j = index"
                    class="relative mt-px flex "
                    style="grid-row: {{
                      getCorrectStyleValue(j)
                    }} / span 8; grid-column-start:{{ i + 1 }}"
                    (click)="assignClientToSchedule(i, j)"
                  >
                    <div
                      class=" cursor-pointer group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
                      [ngClass]="{
                        'ring-2 ring-blue-600 bg-blue-200':
                          checkIfSelectedClient(i, j)
                      }"
                    >
                      <div
                        class="w-full text-gray-600 h-full flex justify-center items-center flex-col"
                      >
                        <svg
                          *ngIf="!clientsHaveIndexes(i, j)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-8 h-8"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <div
                          class="relative w-full hover:text-red-500"
                          *ngIf="checkIfSelectedClient(i, j)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="absolute w-6 h-6 right-0"
                            (click)="
                              onUnassignClient(); $event.stopPropagation()
                            "
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>

                        <div
                          *ngIf="clientsHaveIndexes(i, j)"
                          class="overflow-hidden truncate"
                        >
                          <div
                            *ngIf="getClientOnIndex(i, j) as client"
                            class="w-full justfiy-center text-center flex flex-col"
                          >
                            <p class="font-semibold text-sm">
                              {{ client.displayName }}
                            </p>
                            <p class="text-[0.6rem] ">
                              {{ client.email }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <app-simple-modal
        *ngIf="saveModalToggle"
        (close)="saveCurrentSchedule($event)"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'Cancel'"
        [title]="'Save current Schedule'"
        [message]="'Do you want to save current Schedule?'"
      >
        ></app-simple-modal
      >
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate(
          '75ms ease-in',
          style({ opacity: 0, transform: 'scale(0.95)' }),
        ),
      ]),
    ]),
  ],
})
export class CustomerManagementComponent implements OnInit, OnDestroy {
  weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  checkinSlots = Array.from(Array(21).keys()).slice(1);

  showViewOptions = false;

  user: AppUser | undefined;
  userSubscription: Subscription | undefined;

  clientSubscription: Subscription | undefined;
  clientDatabase: AppUser[] = [];

  selectedClient: AppUser | undefined;
  tempClient: AppUser | undefined;

  saveModalToggle = false;

  constructor(
    private userService: UserService,
    private auth: AuthService,
  ) {}
  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$
      .pipe(first())
      .subscribe((appUser) => (this.user = appUser as AppUser));

    if (this.user && this.user.clientIds) {
      this.getClientData(this.user.clientIds);
    }
  }

  onSave() {
    this.saveModalToggle = !this.saveModalToggle;
  }

  filteredDataBase() {
    return this.clientDatabase.filter(
      (client) =>
        client.checkInSlotIndex === undefined ||
        client.checkInWeekdayIndex === undefined,
    );
  }

  saveCurrentSchedule(event: boolean) {
    if (event) {
      this.clientDatabase.forEach((client) => {
        if (
          client.checkInSlotIndex === undefined &&
          client.checkInWeekdayIndex === undefined
        ) {
          delete client.checkInSlotIndex;
          delete client.checkInWeekdayIndex;
          this.userService.overWriteUser(client);
        } else {
          this.userService.saveUserData(client);
        }
      });
    }

    this.saveModalToggle = !this.saveModalToggle;
  }

  getClientData(clientIds: string[]) {
    clientIds.forEach((id) => {
      this.clientSubscription = this.userService
        .getUserFromDatabase(id)
        .subscribe((client) => {
          if (client?.coachId === this.user!.id) {
            let cl = client as AppUser;

            if (cl.birthdate) {
              cl.birthdate = convertDateObject(cl.birthdate);
            }

            if (this.clientDatabase.some((e) => e.id === cl.id)) {
              let i = this.clientDatabase.indexOf(cl);
              this.clientDatabase[i] = cl;
            } else {
              this.clientDatabase.push(cl);
            }
          }
        });
    });
  }

  toggleViewOptions() {
    this.showViewOptions = !this.showViewOptions;
  }

  getCorrectStyleValue(index: number) {
    if (index === 0) {
      return 1;
    }

    return index * 8 + 1;
  }

  checkForUnscheduledClients(): boolean {
    if (this.clientDatabase.some((e) => e.checkInWeekdayIndex === undefined))
      return true;

    return false;
  }

  onSelectClient(client: AppUser) {
    this.selectedClient = client;
  }

  assignClientToSchedule(weekday: number, slotIndex: number) {
    if (this.selectedClient && !this.clientsHaveIndexes(weekday, slotIndex)) {
      this.selectedClient.checkInSlotIndex = slotIndex;
      this.selectedClient.checkInWeekdayIndex = weekday;
    } else if (
      this.selectedClient &&
      this.clientsHaveIndexes(weekday, slotIndex)
    ) {
      let tmpClient = this.getClientOnIndex(weekday, slotIndex);
      if (tmpClient) {
        tmpClient.checkInSlotIndex = undefined;
        tmpClient.checkInWeekdayIndex = undefined;
      }
      this.selectedClient.checkInSlotIndex = slotIndex;
      this.selectedClient.checkInWeekdayIndex = weekday;
    } else if (
      !this.selectedClient &&
      this.clientsHaveIndexes(weekday, slotIndex) &&
      !this.tempClient
    ) {
      this.tempClient = this.getClientOnIndex(weekday, slotIndex);
    } else if (
      !this.selectedClient &&
      this.clientsHaveIndexes(weekday, slotIndex) &&
      this.tempClient
    ) {
      let localclient = this.getClientOnIndex(weekday, slotIndex);

      if (localclient) {
        localclient.checkInSlotIndex = this.tempClient.checkInSlotIndex;
        localclient.checkInWeekdayIndex = this.tempClient.checkInWeekdayIndex;
      }

      this.tempClient.checkInSlotIndex = slotIndex;
      this.tempClient.checkInWeekdayIndex = weekday;
      this.tempClient = undefined;
    } else if (!this.selectedClient && !this.tempClient) {
    } else {
      this.tempClient!.checkInSlotIndex = slotIndex;
      this.tempClient!.checkInWeekdayIndex = weekday;
      this.tempClient = undefined;
    }

    this.selectedClient = undefined;
  }

  clientsHaveIndexes(weekday: number, slotIndex: number): boolean {
    if (
      this.clientDatabase.some(
        (e) =>
          e.checkInWeekdayIndex === weekday && e.checkInSlotIndex === slotIndex,
      )
    )
      return true;

    return false;
  }

  getClientOnIndex(weekday: number, slotIndex: number): AppUser | undefined {
    return this.clientDatabase.find(
      (e) =>
        e.checkInWeekdayIndex === weekday && e.checkInSlotIndex === slotIndex,
    );
  }

  checkIfSelectedClient(weekday: number, slotIndex: number) {
    let temp = this.getClientOnIndex(weekday, slotIndex);

    if (this.tempClient && temp) {
      if (this.tempClient === temp) return true;
    }

    return false;
  }

  onUnassignClient() {
    if (this.tempClient) {
      this.tempClient.checkInSlotIndex = undefined;
      this.tempClient.checkInWeekdayIndex = undefined;
    }
  }
}
