import { Component } from '@angular/core';

@Component({
  selector: 'app-coaches-featurelist',
  template: `
    <div class="bg-white">
      <div aria-hidden="true" class="relative">
        <img
          src="./assets/images/CoachView/mainViewBanner.png"
          alt=""
          class=" w-full object-cover object-center"
        />
        <div class="absolute inset-0 bg-gradient-to-t from-white"></div>
      </div>

      <div
        class="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8"
      >
        <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2
            class="text-3xl font-bold tracking-tight text-teal-900 sm:text-4xl"
          >
            The Dashboard - One to rule them all.
          </h2>
          <p class="mt-4 text-gray-500">
            Streamline Your Coaching Experience: Introducing the 'The
            Dashboard'<br />
            Your Central Hub for Effortless Check-In Management!
          </p>
        </div>

        <dl
          class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8"
        >
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
                src="./assets/images/CoachView/featureList/checkin-inbox.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Clients Checkins</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Effortless Weekday Management: Stay Updated on Your Clients'
                Check-Ins for Today
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
                src="./assets/images/CoachView/featureList/checkinStatus.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Checkin Status</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Real-Time Check-In Monitoring: Track Check-In Status from Send
                to Response
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
                src="./assets/images/CoachView/featureList/weekday-checkin.png"
                class="rounded-md"
              />
            </div>
           <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Weekday Checkins</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Efficient Weekday Client Management: Seamlessly Navigate and
                Monitor Check-Ins for Every Day
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
                src="./assets/images/CoachView/featureList/checkin-history.png"
                class="rounded-md w-full"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Checkin History</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Your Check-In Archives: Easily Access Current and Past Check-In
                Data at Your Fingertips
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
                src="./assets/images/CoachView/featureList/selected-checkin.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Selected Checkin</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Focused Check-In Management: Dive into Selected Check-Ins,
                Communicate via Text or Voice, and View Client Form
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
               src="./assets/images/CoachView/featureList/tableView.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Weightlog Tableview</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Comprehensive Weight Log Table: Access Client Data and
                Calculations with Ease
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
               src="./assets/images/CoachView/featureList/tablegraphic.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Weightlog Graphicview</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Visualize Progress: Explore Weight Log Data Through Informative
                Graphical Views
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
                src="./assets/images/CoachView/featureList/quickedit.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Weightlog Quickedit</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Macro Management Made Easy: Quick and Simple Client Adjustments
              </dd>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-4 flex flex-row">
            <div
              class="w-1/6 h-full object-center flex justify-center items-center pr-2"
            >
              <img
             src="./assets/images/CoachView/featureList/trainingLog.png"
                class="rounded-md"
              />
            </div>
            <div class="w-5/6 flex flex-col">
              <dt class="font-medium text-teal-900">Traininglog</dt>
              <dd class="mt-2 text-sm text-gray-500">
                Track Progress, Enhance Performance: Dive into Customer Training
                Sessions with Our Training Log
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>

    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>

    <div class="bg-white">
      <div class="mx-auto max-w-2xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="mx-auto max-w-3xl text-center">
          <h2
            class="text-3xl font-bold tracking-tight text-teal-900 sm:text-4xl"
          >
            Client Checkins
          </h2>
          <p class="mt-4 text-gray-500 text-center">
            Unlocking the Power of Client Check-Ins:<br> Seamlessly Navigate
            History,
            Engage with <br>Selected Clients, and Utilize Text and Voice Features
          </p>
        </div>

        <div class="space-y-10">
          <div
            class="flex mt-6 flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6  lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-1"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Effortless Communication Tracking
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                Instantly Know When Your Clients Reach Out and When Their
                Check-Ins Are Attended To
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-6 xl:col-start-5"
            >
              <div
                class="h-fit overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400"
              >
                <img src="./assets/images/CoachView/checkin-0.png" />
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-8 xl:col-start-9"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Engage, Interact, and Empower Your Clients Like Never Before
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                In the Spotlight: Dive into Selected Check-Ins, View Client
                Updates, and Connect via Voice or Text for Effective Coaching
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-1"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100  "
              >
                <img
                  src="./assets/images/CoachView/CheckinDetail.png"
                  alt="Detail of zipper pull with tan leather and silver rivet."
                  class="object-cover object-center "
                />
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-1"
            >
              <h3 class="text-lg font-medium text-gray-900">Checkin History</h3>
              <p class="mt-2 text-sm text-gray-500">
                Delve into Check-In History and Select the Check-In of Your
                Choice
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-6 xl:col-start-5"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-white"
              >
                <img
                  src="./assets/images/CoachView/checkinHistory.png"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>

    <div class="bg-white">
      <div class="mx-auto max-w-2xl px-4 py-10 sm:px-6  lg:max-w-7xl lg:px-8">
        <div class="mx-auto max-w-3xl text-center">
          <h2
            class="text-3xl font-bold tracking-tight text-teal-900 sm:text-4xl"
          >
            Weightlog
          </h2>
          <p class="mt-4 text-gray-500">
            Comprehensive Weightlog Management: Effortlessly Monitor Progress
            with Table and Graph Views,<br />
            Customize Macros, and Keep Detailed Notes for Client Success
          </p>
        </div>

        <div class="mt-12 space-y-16">
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-1"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Classic Table View
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                Data at a Glance: Your Information, Your Way, All in One Table
                View
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-6 xl:col-start-5"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-white"
              >
                <img
                  src="./assets/images/CoachView/dataview.png"
                  class="object-cover"
                />
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-8 xl:col-start-9"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Macro Precision and Client Progress in Clear Focus
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                Visualize Success, Precision, and Progress: The Weightlog
                Graphical View - Tracking Macros with Accuracy and Results with
                Clarity
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-1"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400"
              >
                <img
                  src="./assets/images/CoachView/dataviewGraph.png"
                  alt="Detail of zipper pull with tan leather and silver rivet."
                  class="object-center"
                />
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-1"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Tailored Nutrition and Client Insights
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                Fine-Tune Nutrition and Keep Records with Ease in One Central
                Hub
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-6 xl:col-start-5"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-white ring-2  shadow-lg shadow-gray-400 ring-gray-400"
              >
                <img
                  src="./assets/images/CoachView/dataviewSetValues.png"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>


    <div class="bg-white">
      <div class="mx-auto max-w-2xl px-4 py-10 sm:px-6  lg:max-w-7xl lg:px-8">
        <div class="mx-auto max-w-3xl text-center">
          <h2
            class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
          >
            Traininglog
          </h2>
          <p class="mt-4 text-gray-500">
            Streamlined Progress Tracking: The Traininglog Table - Your
            All-in-One Solution<br />
            for Monitoring Client Achievements and Advancements
          </p>
        </div>

        <div class="mt-12 space-y-16">
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-1"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Track, Improve, Excel
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                A Glimpse into Your Clients Training Day and Progress Over Time
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-6 xl:col-start-5"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-white ring-2  shadow-lg shadow-gray-400 ring-gray-400"
              >
                <img
                  src="./assets/images/CoachView/traininglogView.png"
                  class="object-cover"
                />
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
          >
            <div
              class="mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4 lg:col-start-8 xl:col-start-9"
            >
              <h3 class="text-lg font-medium text-gray-900">
                Visualizing Progression
              </h3>
              <p class="mt-2 text-sm text-gray-500">
                A Dynamic Progression Tracker that Tells Your Story in Green,
                Yellow, and Red
              </p>
            </div>
            <div
              class="flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8 lg:col-start-1"
            >
              <div
                class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400"
              >
                <img
                  src="./assets/images/CoachView/traininglogDetailView.png"
                  alt="Detail of zipper pull with tan leather and silver rivet."
                  class="object-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>

    <div class="bg-white">
  <div class="mx-auto max-w-2xl px-4 py-10 sm:px-6  lg:max-w-7xl lg:px-8">
    <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
      <div>
        <div class="border-b border-gray-200 pb-10">
          <h2 class="font-medium text-gray-500">Never miss a Message.</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Notifications</p>
        </div>

        <dl class="mt-10 space-y-10">
          <div>
            <dt class="text-sm font-medium text-gray-900">Apple Watch</dt>
            <dd class="mt-3 text-sm text-gray-500">Effortlessly manage your notifications with a visual touch – view and respond to alerts directly from your Apple Watch.</dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-900">In App</dt>
            <dd class="mt-3 text-sm text-gray-500">Experience uninterrupted communication and updates through our intuitive in-app notification banner.</dd>
          </div>

        </dl>
      </div>

      <div>
        <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
          <img src="./assets/images/applewatch.png"  class="h-full w-full object-cover object-center ">
        </div>
        <div class="mt-4  lg:mt-8 ">
          <div class="overflow-hidden rounded-lg bg-gray-100 ring-2  shadow-lg shadow-gray-400 ring-gray-400">
            <img src="./assets/images/notification.png"  class="h-full w-full  object-center">
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        aria-hidden="true"
      >
        <div class="w-5/6 border-t border-gray-300"></div>
      </div>
    </div>

    <!-- Feature section -->
    <div class="mx-auto  pt-10 max-w-7xl  sm:px-6 lg:px-8">
      <div
        class="relative isolate overflow-hidden bg-gray-900 py-20 px-6 sm:rounded-3xl sm:py-24 sm:px-10 lg:py-24 xl:px-24"
      >
        <div
          class="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0"
        >
          <div class="lg:row-start-2 lg:max-w-md">
            <h2
              class="text-3xl font-bold tracking-tight text-white sm:text-4xl"
            >
              Empower Your Training Arsenal and Supercharge Client Progress
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">
              Create, customize, and assign training templates, import workout
              plans via CSV, and witness your exercise database grow with each
              client. This streamlined approach not only simplifies your
              planning process but also nurtures a dynamic, collaborative
              environment, where tailored workouts enhance client engagement,
              adherence, and overall success, all while expanding your resources
              for future coaching excellence.
            </p>
          </div>
          <img
            src="./../assets/images/CoachView/trainingplanView.png"
            alt="Product screenshot"
            class="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
            width="2432"
            height="1442"
          />
          <div
            class="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10"
          >
            <dl
              class="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none"
            >
              <div class="relative">
                <dt class="ml-9 inline-block font-semibold text-white">
                  <svg
                    class="absolute top-1 left-1 h-5 w-5 text-teal-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  CSV Import
                </dt>
                <dd class="inline">
                  Effortlessly import your training plans with CSV. Streamline
                  your coaching processes and save time by seamlessly
                  transferring workout routines. Get started today for enhanced
                  efficiency.
                </dd>
              </div>

              <div class="relative">
                <dt class="ml-9 inline-block font-semibold text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="absolute top-1 left-1 h-5 w-5 text-teal-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                    />
                  </svg>

                  Mobile friendly
                </dt>
                <dd class="inline">
                  Empower your clients to train on the go with ease. Our
                  mobile-friendly training plan view ensures that a good
                  Gymsession is always within reach, offering convenience and
                  flexibility for their workouts
                </dd>
              </div>

              <div class="relative">
                <dt class="ml-9 inline-block font-semibold text-white">
                  <svg
                    class="absolute top-1 left-1 h-5 w-5 text-teal-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Exercise Database
                </dt>
                <dd class="inline">
                  Watch Your Exercise Database Expand With Every Training Plan
                  Created.
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <svg
          class="pointer-events-none absolute top-1/2 left-12 -z-10 h-[42.375rem] -translate-y-1/2 transform-gpu blur-3xl lg:top-auto lg:bottom-[-12rem] lg:translate-y-0"
          viewBox="0 0 1155 678"
          fill="none"
        >
          <path
            fill="url(#c0458c57-1330-459f-9d5c-f0d75c210466)"
            fill-opacity=".25"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="c0458c57-1330-459f-9d5c-f0d75c210466"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#9089FC" />
              <stop offset="1" stop-color="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>

    <div class="bg-white pt-10 ">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">One Rate, All Access</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">Simplify your subscription with straightforward, all-inclusive pricing. Access all features for one price per seat. Your Growth, Our Commitment – At a Steady Rate.</p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
      <div class="p-8 sm:p-10 lg:flex-auto">
        <h3 class="text-2xl font-bold tracking-tight text-gray-900">Per Seat Subscription: Individualized Excellence,<br> No Matter Your Size</h3>
        <p class="mt-6 text-base leading-7 text-gray-600">Our app welcomes coaches of all sizes, placing no significance on your client count. With a personalized seat subscription, unlock access to our suite of tools and elevate your coaching journey on your terms.</p>
        <div class="mt-10 flex items-center gap-x-4">
          <h4 class="flex-none text-sm font-semibold leading-6 text-teal-600">What’s included</h4>
          <div class="h-px flex-auto bg-gray-100"></div>
        </div>
        <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-3 sm:gap-6">
                                           <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
           All Feature Access
          </li>
          <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Client Management
          </li>
          <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Workout Planing 
          </li>
          <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Performance Tracking
          </li>
          <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            In-App Communication
          </li>
                  <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Easy Interface
          </li>
                       <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Voice and Text Messaging
          </li>
                         <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
           Macro Guidance
          </li>
                             <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
Data Visualization          </li>
                   <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
           Exercise Library
          </li>

                        <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
           One Price, No Tricks
          </li>
                        <li class="flex gap-x-1">
            <svg class="h-6 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
           Created by Machete
          </li>
        </ul>
      </div>
      <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div class="mx-auto max-w-xs px-8">
            <p class="text-base font-semibold text-gray-600">Per Seat Subscription</p>
            <p class="mt-6 flex items-baseline justify-center gap-x-2">
              <span class="text-5xl font-bold tracking-tight italic text-gray-400">24,90</span>
              <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">EUR</span>
            </p>
            <p class="mt-4 text-xs leading-5 text-gray-600">Price incl. VAT</p>
            <a href="https://tally.so/r/nWRKpN" class="mt-10 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Get in touch</a>
            <p class="mt-2 text-xs leading-5 text-gray-600">Invoices and receipts available for easy company reimbursement</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    
  `,
  styles: [],
})
export class CoachesFeaturelistComponent { }
