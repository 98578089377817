import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { isSameDay } from 'date-fns';
import { Observable, map, tap } from 'rxjs';
import { WeightEntryData, Macros, AppUser } from 'src/app/core/thecoach';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-set-macros-datepicker-modal',
  template: `
    <ng-container *ngIf="clientWeightLog$ | async as logs"> </ng-container>
    <div class="flex justify-center flex-col pt-6 items-center">
      <form
        [formGroup]="macroForm"
        (ngSubmit)="onSubmit()"
        class="flex flex-col w-full h-full justify-center items-center"
      >
        <div class="w-5/6 flex justify-center flex-col items-center">
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
          >
            <!-- Heroicon name: outline/check -->
            <img src="../../assets/icons/maintain-weight-icon.png" />
          </div>
          <h3
            class="text-base my-2 font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Set new Macros
          </h3>

          <div class="flex flex-row justify-evenly w-6/12">
            <div class="flex flex-col justify-center items-center">
              <span>Protein</span>
              <div class="relative mt-1 rounded-md shadow-sm w-24">
                <input
                  type="text"
                  formControlName="protein"
                  name="protein"
                  inputmode="decimal"
                  step="0.01"
                  id="protein"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="protein-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-center items-center">
              <span>Carbs</span>
              <div class="relative mt-1 rounded-md shadow-sm w-24">
                <input
                  type="text"
                  formControlName="carbs"
                  name="carbs"
                  inputmode="decimal"
                  step="0.01"
                  id="carbs"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="carbs-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-center items-center">
              <span>Fat</span>
              <div class="relative mt-1 rounded-md shadow-sm w-24">
                <input
                  type="text"
                  formControlName="fat"
                  name="fat"
                  inputmode="decimal"
                  step="0.01"
                  id="fat"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="fat-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-around w-5/6 mt-2 pb-4">
          <button
            id="btn-save"
            type="submit"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Save
          </button>

          <button
            *ngIf="loadedLog"
            (click)="onDelete()"
            type="button"
            id="btn-delete"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-red px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Delete
          </button>

          <button
            (click)="onClickEvent(false)"
            type="button"
            id="btn-cancel"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class SetMacrosDatepickerModalComponent {
  clientWeightLog$: Observable<WeightEntryData | undefined> | undefined;
  run = true;
  loadedLog: WeightEntryData | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private weightLogService: WeightlogService
  ) {}

  @Input() selectedDate?: Date;
  @Input() duration!: number;
  @Input() client!: AppUser;
  @Output() onClick = new EventEmitter<WeightEntryData | boolean>();
  @Output() delete = new EventEmitter<boolean>();

  show = true;
  macroForm = this.fb.group({
    protein: [''],
    carbs: [''],
    fat: [''],
  });

  private fetchWeightLogData() {
    if (this.client) {
      this.clientWeightLog$ = this.weightLogService
        .getWeightLogOfSingleUserSingleDate(
          this.client,
          this.selectedDate as Date
        )
        .pipe(
          map((entry) => {
            if (entry) {
              this.initForm(entry);
            }
            return entry;
          })
        );
    }
  }
  /*
  checkForSetMacrosInLog(logs: WeightEntryData[]) {
    return !!logs.find(
      (data) =>
        isSameDay(data.date as Date, this.selectedDate as Date) &&
        data.refeedMacros
    );
  }

  initFormModule(logs: WeightEntryData[]) {
    if (this.checkForSetMacrosInLog(logs) && logs) {
      const logEntry = logs.find((data) =>
        isSameDay(data.date as Date, this.selectedDate as Date)
      );

      if (logEntry && logEntry.refeedMacros) {
        this.macroForm
          .get('protein')
          ?.patchValue(logEntry.refeedMacros.protein?.toString() as string);
        this.macroForm
          .get('carbs')
          ?.patchValue(logEntry.refeedMacros.carbs?.toString() as string);
        this.macroForm
          .get('fat')
          ?.patchValue(logEntry.refeedMacros.fat?.toString() as string);
      }
    }
  }
*/
  initForm(log: WeightEntryData) {
    if (log && log.refeedMacros) {
      this.loadedLog = log;
      this.macroForm
        .get('protein')
        ?.patchValue(log.refeedMacros.protein?.toString() as string);
      this.macroForm
        .get('carbs')
        ?.patchValue(log.refeedMacros.carbs?.toString() as string);
      this.macroForm
        .get('fat')
        ?.patchValue(log.refeedMacros.fat?.toString() as string);
    }
  }

  ngOnInit(): void {
    this.fetchWeightLogData();
  }

  onClickEvent(value: boolean) {
    this.onClick.emit(value);
  }

  onDelete() {
    delete this.loadedLog!['refeedMacros'];

    this.weightLogService
      .deleteSetMacrosFromLog(this.client, this.loadedLog!)
      .then(() => {
        this.delete.emit(true);
      });
  }

  onSubmit() {
    let logEntry: WeightEntryData | undefined = undefined;

    logEntry = new WeightEntryData();
    logEntry.date = this.selectedDate;

    if (logEntry) {
      logEntry.refeedMacros = {
        protein: Number(this.macroForm.get('protein')!.value),
        carbs: Number(this.macroForm.get('carbs')!.value),
        fat: Number(this.macroForm.get('fat')!.value),
      };
    }

    this.onClick.emit(logEntry);
  }
}
