import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import { Subscription } from 'rxjs';
import {
  TemplateTrainingDay,
  TrainingPlan,
  convertDateObject,
} from 'src/app/core/thecoach';
import { TrainingplanService } from 'src/app/services/trainingplan.service';

@Component({
  selector: 'app-customer-trainingplan-library',
  template: `
    <ul role="list" class="space-y-3">
      <a
        [routerLink]="['/trainingplans', tp.id]"
        [state]="{ id: tp.id }"
        *ngFor="let tp of trainingplans"
      >
        <li
          class="overflow-hidden rounded-md bg-white px-6 py-4 shadow flex flex-row justify-between"
        >
          <!-- Your content -->
          <div>
            <h1 class="font-bold">{{ tp.trainingPlanName }}</h1>
            <p class="text-sm italic line-clamp-3">
              {{ tp.trainingPlanDescription }}
            </p>
          </div>
          <div>
            <h1 class="font-bold">Last Edit</h1>
            <p class="text-sm italic line-clamp-3">
              {{ tp.lastEdit | date: 'dd-MM-yyyy' }}
            </p>
          </div>
        </li>
      </a>
      <!-- More items... -->
    </ul>
  `,
  styles: [],
})
export class CustomerTrainingplanLibraryComponent implements OnInit, OnDestroy {
  trainingPlanSubscription: Subscription | undefined;
  trainingplans: TrainingPlan[] = [];

  constructor(
    private tpService: TrainingplanService,
    private router: Router,
  ) {}
  ngOnDestroy(): void {
    this.trainingPlanSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    this.trainingPlanSubscription = this.tpService
      .getAllTrainingplansForUser()
      .subscribe((data) => {
        this.trainingplans = data;

        this.trainingplans.forEach((tp) => {
          tp.lastEdit = convertDateObject(tp.lastEdit as Date);
        });

        if (localStorage.getItem('session') !== null) {
          const selectedTrainingDay = JSON.parse(
            localStorage.getItem('session') as string,
          ) as TemplateTrainingDay;
          this.trainingplans.forEach((tp) =>
            tp.mesoCycles?.forEach((meso) =>
              meso.trainingDays?.forEach((day) => {
                if (tp.id === selectedTrainingDay.trainingPlanId) {
                  this.router.navigate(['/trainingplans', tp.id]);
                }
              }),
            ),
          );
        }
      });
  }

  onOpenTrainingPlan(tp: TrainingPlan): void {
    this.router.navigate(['/trainingplans', tp.id]);
  }
}
