import { UntypedFormArray } from '@angular/forms';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Auth } from 'firebase/auth';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  first,
  map,
  of,
  switchMap,
} from 'rxjs';
import {
  AppUser,
  StatsMenu,
  WeightEntryData,
  convertDateObject,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-statistics',
  template: `
    <div class="flex w-full justify-center py-4">
      <div class="flex flex-col justify-center items-center ">
        <nav
          class="isolate flex w-fit divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
          <a
            (click)="onMenuSelect(statsMenu.Weightstats)"
            [ngClass]="{
              ' text-gray-900': activeMenu === statsMenu.Weightstats,
              'text-gray-500': activeMenu !== statsMenu.Weightstats,
            }"
            class="flex justify-center items-center   w-fit  cursor-pointer rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-1 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            aria-current="page"
          >
            <span>Weightlog<br />Statistics</span>
            <span
              aria-hidden="true"
              class="bg-transparent absolute inset-x-0 bottom-0 h-0.5"
              [ngClass]="{
                'bg-teal-400 text-gray-900':
                  activeMenu === statsMenu.Weightstats,
                'bg-transparent text-gray-500':
                  activeMenu !== statsMenu.Weightstats,
              }"
            ></span>
          </a>
          <a
            (click)="onMenuSelect(statsMenu.TrackingAccuracy)"
            [ngClass]="{
              ' text-gray-900': activeMenu === statsMenu.TrackingAccuracy,
              'text-gray-500': activeMenu !== statsMenu.TrackingAccuracy,
            }"
            class="flex justify-center items-center  cursor-pointer w-fit hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-1 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
          >
            <span>Macro<br />Tracking</span>
            <span
              aria-hidden="true"
              class=" absolute inset-x-0 bottom-0 h-0.5"
              [ngClass]="{
                'bg-teal-400 text-gray-900':
                  activeMenu === statsMenu.TrackingAccuracy,
                'bg-transparent text-gray-500':
                  activeMenu !== statsMenu.TrackingAccuracy,
              }"
            ></span>
          </a>
          <a
            *ngIf="client?.ctmId"
            (click)="onMenuSelect(statsMenu.CustomTrackingParameters)"
            [ngClass]="{
              ' text-gray-900':
                activeMenu === statsMenu.CustomTrackingParameters,
              'text-gray-500':
                activeMenu !== statsMenu.CustomTrackingParameters,
            }"
            class="flex justify-center items-center   cursor-pointer w-fit hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-1 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
          >
            <span>Custom<br />Tracking</span>
            <span
              aria-hidden="true"
              class=" absolute inset-x-0 bottom-0 h-0.5"
              [ngClass]="{
                'bg-teal-400 text-gray-900':
                  activeMenu === statsMenu.CustomTrackingParameters,
                'bg-transparent text-gray-500':
                  activeMenu !== statsMenu.CustomTrackingParameters,
              }"
            ></span>
          </a>
        </nav>
      </div>
    </div>
    <ng-container *ngIf="clientWeightLog$ | async as logs; else loading">
      <app-duration-dropdown
        [defaultDuration]="dataDurationSubject.value"
        (selectedDuration)="onSelectedDuration($event)"
      ></app-duration-dropdown>
      <app-statistics-wlog-table
        *ngIf="activeMenu === statsMenu.Weightstats"
        [client]="client"
        [weightLogData]="sortToLatest(logs)"
      >
      </app-statistics-wlog-table>

      <app-statistics-macro-tracking-table
        *ngIf="activeMenu === statsMenu.TrackingAccuracy"
        [client]="client"
        [weightLogData]="sortToLatest(logs)"
      >
      </app-statistics-macro-tracking-table>

      <app-statistics-ctm-table
        *ngIf="activeMenu === statsMenu.CustomTrackingParameters"
        [client]="client"
        [weightLogData]="sortToLatest(logs)"
      >
      </app-statistics-ctm-table>
    </ng-container>

    <ng-template #loading>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>
  `,
  styles: [],
})
export class StatisticsComponent implements OnInit {
  clientWeightLog$: Observable<WeightEntryData[]> | undefined;
  client: AppUser | undefined;
  userSubscription: Subscription | undefined;

  statsMenu = StatsMenu;
  activeMenu = StatsMenu.Weightstats;
  dataDurationSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    14,
  );

  constructor(
    private weightLogService: WeightlogService,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    this.clientWeightLog$ = combineLatest([
      this.auth.appUser$.pipe(first()),
      this.dataDurationSubject,
    ]).pipe(
      switchMap(([user, duration]) => {
        if (user) {
          this.client = user;
          this.cdr.detectChanges();
          return this.fetchWeightLogData(user.id as string, duration);
        } else {
          return of([]); // Return an empty observable if the user is not available
        }
      }),
    );
  }

  ngOnInit(): void {}

  onSelectedDuration(val: number) {
    this.dataDurationSubject.next(val);
    this.refreshData();
  }

  onMenuSelect(val: StatsMenu) {
    this.activeMenu = val;
    this.refreshData();
  }

  // This method fetches the weight log data based on user ID and duration
  private fetchWeightLogData(
    userId: string,
    duration: number,
  ): Observable<WeightEntryData[]> {
    if (duration === 0) {
      return this.weightLogService.getAllWeightLogDataOfUser(userId).pipe(
        map((log: WeightEntryData[]) => {
          log.forEach((entry) => {
            entry.date = convertDateObject(entry.date as Date);
          });
          return log;
        }),
      );
    } else {
      return this.weightLogService.getWeightLogDataOfXDaysUpdateDate(
        userId,
        duration,
      );
    }
  }

  private refreshData() {
    if (this.client) {
      this.clientWeightLog$ = this.fetchWeightLogData(
        this.client.id as string,
        this.dataDurationSubject.value,
      );
    }
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  sortToLatest(logs: WeightEntryData[]) {
    return logs.sort((a, b) => {
      return this.getTime(b.date) - this.getTime(a.date);
    });
  }
}
