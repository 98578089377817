import { Component, Input } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { AppUser } from 'src/app/core/thecoach';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UserConfig } from '../../../../../../backend/core/thecoach';

@Component({
  selector: 'app-coach-payment-panel',
  template: `
    <div *ngIf="userConfig; else noSpots">
      <h1 class="text-xl font-bold uppercase">PAYMENT MANAGEMENT</h1>
      <hr class="pb-2" />
      <!-- Use subscriptionData in your template -->
      <div *ngIf="activeUsers; else noIds" class="flex flex-col">
        <div class="flex w-full justify-between">
          <p class="font-medium italic">Spots</p>
          <div class="flex flex-row w-fit items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 text-gray-600 mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
              />
            </svg>
            <p class="font-bold">
              {{ userConfig.quantity }}/{{ activeUsers.size }}
            </p>
          </div>
        </div>
        <div class="flex w-full justify-end">
          <p class="text-xs italic text-gray-600">
            Available Spots/Active Clients
          </p>
        </div>
      </div>
      <ng-template #noIds
        ><p>Spots: {{ userConfig.quantity }}</p></ng-template
      >
      <div class="py-4">
        <div class="flex w-full justify-between">
          <p class="font-medium italic">Next Payment</p>

          <div class="flex flex-row w-fit items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4  h-4 text-gray-600 mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
            <p class="font-bold">
              {{ userConfig.currentPeriodEnd * 1000 | date: 'dd.MM.yyyy' }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-center">
        <button
          type="button"
          (click)="goToStripeCustomerPortal()"
          class="flex w-fit h-fit items-center whitespace-nowrap rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Manage Coaching Spots
        </button>
      </div>
    </div>

    <ng-template #noSpots>
      <button
        type="button"
        (click)="createStripePaymentLink()"
        class="flex w-fit h-fit items-center whitespace-nowrap rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
      >
        Buy Coaching Spots
      </button>
    </ng-template>
  `,
  styles: [],
})
export class CoachPaymentPanelComponent {
  @Input({ required: true }) user!: AppUser;
  @Input({ required: true }) userConfig!: UserConfig | undefined;
  @Input({ required: true }) activeUsers!: Map<string, AppUser> | undefined;
  constructor(
    protected coachSubscription: SubscriptionService,
    private fireFunctions: AngularFireFunctions,
  ) {}

  goToStripeCustomerPortal() {
    const portal = this.fireFunctions.httpsCallable(
      'stripeCreateCustomerPortal',
    );

    //console.log(portal);
    portal({ customerId: this.user.customerId }).subscribe({
      next: (result) => {
        // Handle the result of your Cloud Function here
        // Access the session URL from the result
        const sessionUrl = result as string;
        // Perform any action with sessionUrl, like redirection
        window.location.href = sessionUrl + '#';
      },
      error: (error) => {
        console.error('Cloud Function error:', error);
      },
    });
  }

  createStripePaymentLink() {
    const callable = this.fireFunctions.httpsCallable(
      'stripeCreatePaymentLink',
    );

    // console.log(callable);
    callable({}).subscribe({
      next: (result) => {
        // Handle the result of your Cloud Function here
        // Access the session URL from the result
        const sessionUrl = result as string;
        // Perform any action with sessionUrl, like redirection
        window.location.href =
          sessionUrl +
          '?prefilled_email=' +
          this.user.email +
          '&client_reference_id=' +
          this.user.id;
      },
      error: (error) => {
        // Handle errors if the Cloud Function call fails
        console.error('Cloud Function error:', error);
      },
    });
  }
}
