import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription, first } from 'rxjs';
import { AppUser, CustomCheckinFormType, CustomCheckinFormTypeNumber, CustomCheckinFormTypeRange, CustomCheckinFormTypeString, CustomCheckinModel } from 'src/app/core/thecoach';
import { CustomCheckinModelService } from 'src/app/services/custom-checkin-model.service';

@Component({
  selector: 'app-checkin-custom-models-form',
  template: `
  <div *ngIf="customCheckinForm.valid" >
<form [formGroup]="customCheckinForm"

  class="flex flex-col gap-y-4"
>
  <div formArrayName="ccfs" *ngFor="let ccf of CCFS.controls; let i = index"
  >
    <ng-container [ngSwitch]="ccf.get('type')!.value" >
     <div *ngSwitchCase="customCheckinFormType.Text">
        <app-checkin-generated-text-input
        [formGroupName]="i"
        [ccf]="ccfFormGroup(i)"
        ></app-checkin-generated-text-input>

      </div>
      <div *ngSwitchCase="customCheckinFormType.Number">
        <app-checkin-generated-number-input
        [formGroupName]="i"
        [ccf]="ccfFormGroup(i)"
        ></app-checkin-generated-number-input>

      </div>

      <div *ngSwitchCase="customCheckinFormType.Range">
        <app-checkin-generated-range-input
        [formGroupName]="i"
        [ccf]="ccfFormGroup(i)"
        ></app-checkin-generated-range-input>

      </div>
      <div *ngSwitchDefault>No Type found</div>
    </ng-container>
    </div>
</form>
  </div>
<!--
      <button
                type="button"
                (click)="log();
            $event.stopPropagation();
            $event.preventDefault()"
                class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Log
              </button>
  -->
  `,
  styles: [
  ],

})
export class CheckinCustomModelsFormComponent implements OnInit {
  @Input() user!: AppUser;
  @Input() customCheckinForm!: FormGroup;
  @Input() customCheckinModel?: CustomCheckinModel


  customCheckinSubscription: Subscription | undefined;
  customCheckinFormType = CustomCheckinFormType;


  constructor(private fb: FormBuilder, private customCheckinModelService: CustomCheckinModelService) {



  }
  ngOnInit(): void {

    if (this.customCheckinModel) {
      this.patchCheckinModel(this.customCheckinModel)
    } else {
      if (this.user) {
        this.customCheckinSubscription = this.customCheckinModelService.getModelByID(this.user)
          .pipe(first())
          .subscribe(newCustomCheckinModel => {
            if (newCustomCheckinModel) {
 //             console.log('Formloading:', this.customCheckinForm)

              this.patchCheckinModel(newCustomCheckinModel)
            }
          })

      }
    }
  }

  log() {
//    console.log(this.customCheckinForm)
  }

  ccfFormGroup(i: number) {
    return this.CCFS.at(i) as FormGroup;
  }



  patchCheckinModel(checkinModel: CustomCheckinModel) {
    this.customCheckinForm.patchValue(checkinModel)
    checkinModel.ccfs.forEach(CCF => {
      switch (CCF.type) {
        case CustomCheckinFormType.Text:
          this.CCFS.push(this.patchTextInput(CCF))
          break;
        case CustomCheckinFormType.Number:
          this.CCFS.push(this.patchNumberInput(CCF))
          break;

        case CustomCheckinFormType.Range:
          this.CCFS.push(this.patchRangeInput(CCF))

          break;

        default:
          console.error("type not found")
          break;
      }
    })
  }

  patchTextInput(input: CustomCheckinFormTypeString): FormGroup {
    return this.fb.group({
      slot: [input.slot],
      type: [input.type],
      name: [input.name],
      value: [input.value],
      description: [input.description]
    })
  }

  patchNumberInput(input: CustomCheckinFormTypeNumber): FormGroup {
    return this.fb.group({
      slot: [input.slot],
      type: [input.type],
      name: [input.name],
      value: [input.value],
      defaultValue: [input.defaultValue],
      description: [input.description]
    })
  }

  patchRangeInput(input: CustomCheckinFormTypeRange): FormGroup {
    return this.fb.group({
      slot: [input.slot],
      type: [input.type],
      name: [input.name],
      min: [input.min],
      max: [input.max],
      value: [input.value],
      step: [input.step],

      description: [input.description]
    })

  }


  get CCFS() {
    return this.customCheckinForm.get('ccfs') as FormArray;
  }

}
