import { Component, Input } from '@angular/core';
import {
  WeightEntryData,
  AppUser,
  formatArraysForTables,
  CustomTrackingModel,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-statistics-ctm-table',
  template: `
    <div *ngIf="client">
      <div class="pt-2">
        <ng-container
          *ngFor="let table of splitArray; let i = index"
          class="space-y-4"
        >
          <table
            class="divide-y divide-gray-300  overflow-x-auto whitespace-nowrap table-auto w-full mb-4"
          >
            <thead
              app-statistics-ctm-tableheader
              [client]="client"
              [ctm]="findCTMForTable(table)"
            ></thead>

            <tbody
              app-statistics-ctm-tablebody
              [weightLogData]="table"
              class="divide-y divide-gray-200"
            ></tbody>
          </table>
        </ng-container>
      </div>
    </div>
  `,
  styles: [],
})
export class StatisticsCtmTableComponent {
  @Input() weightLogData!: WeightEntryData[] | null;
  @Input() client!: AppUser | undefined;
  splitArray: WeightEntryData[][] = [[]];

  ngOnInit(): void {
    this.splitArray = this.formatArrays(this.weightLogData!);
  }

  findCTMForTable(tableData: WeightEntryData[]) {
    let ctm: CustomTrackingModel | undefined;

    ctm = tableData.find((data) => data.customTrackingLog)?.customTrackingLog;

    return ctm;
  }

  formatArrays(data: WeightEntryData[]) {
    return formatArraysForTables(data.reverse());
  }
}
