import { AppUser, UserRole } from './../../core/thecoach';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, catchError, first, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-verify-email',
  template: `
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto h-12 w-auto"
          src="../../../assets/icons/TCCLogo.webp"
          alt="The Coach.cloud Logo"
        />
        <h2
          class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
        >
          TheCoach
        </h2>
      </div>

      <div
        class="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
        *ngIf="auth.appUser$ | async as appUser"
      >
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="flex justify-center flex-col text-center">
            <h3>Thank You for Registering</h3>
            <div class="flex flex-col">
              <p class="text-center py-2">
                We have sent a confirmation email to
                <strong>{{ appUser.email }}</strong>
              </p>
              <p class="text-center py-2">
                Please check your email and click on the link to verfiy your
                email address.
              </p>
            </div>

            <!-- Calling SendVerificationMail() method using authService Api -->

            <div class="flex justify-center py-2 w-full ">
              <!--
              <button
                type="button"
                class="flex w-fit justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                (click)="authService.SendVerificationMail()"
              >
                Resend Verification Email
              </button>
              <button
                type="button"
                (click)="onBackToLogin()"
                class="flex w-fit justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Back to Login
              </button>
              -->

              <div class="flex " *ngIf="appUser">
                <button
                  *ngIf="appUser.role === 'client'"
                  type="button"
                  [routerLink]="'/dashboard'"
                  class="flex w-fit justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  Go to Dashboard
                </button>

                <button
                  *ngIf="appUser.role === 'coach'"
                  type="button"
                  [routerLink]="'/coach-dashboard'"
                  class="flex w-fit justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  Go to Coach Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    protected auth: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    console.log('construcoor of verify page');
  }

  ngOnInit(): void {
    console.log('verify page loaded');
    this.cdr.detectChanges();
  }

  onBackToLogin() {
    this.router.navigateByUrl('/login');
  }
}
