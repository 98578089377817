import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Specification, convertDateObject } from '../core/thecoach';
import { format, isSameDay, isWithinInterval } from 'date-fns';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecificationsService {

  constructor(private db: AngularFirestore, private auth: AuthService) { }



  addSpecification(coachId: string, clientId: string, spec: Specification) {
    if (!spec || !spec.id || spec.id === undefined) {
      spec.id = this.db.createId();
    }


    return this.db
      .collection('coaches')
      .doc(coachId)
      .collection('clients')
      .doc(clientId)
      .collection('specs')
      .doc(spec.id)
      .set(Object.assign({}, spec), { merge: true })
  }

  getSpecsForUserForDate(coachId: string, clientId: string, date: Date) {

    return this.db
      .collection('coaches')
      .doc(coachId)
      .collection('clients')
      .doc(clientId)
      .collection<Specification>('specs', ref =>
        ref.orderBy('start', 'desc')
      )
      .valueChanges()
      .pipe(
        map(specs => {
          return specs.map(spec => {

            if (spec.start) spec.start = convertDateObject(spec.start)
            if (spec.end) spec.end = convertDateObject(spec.end)


            if (isWithinInterval(date, { start: spec.start as Date, end: spec.end as Date }) || isSameDay(date, spec.start as Date)) {
              return spec
            } else {
              return null;
            }
          }).filter(Boolean) as Specification[]
        })
      )
  }


  getSpecsForUserBetweenDates(coachId: string, clientId: string, startDate: Date, endDate: Date) {
    //  console.log('Getting Calendar')
    // Create two separate observables, one for end >= startDate and one for start <= endDate
    // Create two separate observables, one for end >= startDate and one for start <= endDate

    const query1 = this.db
      .collection('coaches')
      .doc(coachId)
      .collection('clients')
      .doc(clientId)
      .collection<Specification>('specs', ref =>
        ref.orderBy('end', 'desc')
          .where('end', '>=', startDate)
      )
      .valueChanges()
    // Limit to one emission to avoid duplicates

    const query2 = this.db
      .collection('coaches')
      .doc(coachId)
      .collection('clients')
      .doc(clientId)
      .collection<Specification>('specs', ref =>
        ref.orderBy('start', 'desc')
          .where('start', '>=', startDate)
          .where('start', '<=', endDate)
      )
      .valueChanges()
    // Limit to one emission to avoid duplicates

    // Combine the results of the two queries and remove duplicates
    return combineLatest([query1, query2]).pipe(
      map(([result1, result2]) => {
        const mergedResults = result1.concat(result2);

        // Use a Set to track unique event IDs
        const uniqueEventIds = new Set();
        const filteredEntries = mergedResults.filter(entry => {
          if (uniqueEventIds.has(entry.id)) {
            // If the entry ID is already in the Set, it's a duplicate; exclude it
            return false;
          }
          // Otherwise, add the entry ID to the Set and include the entry
          uniqueEventIds.add(entry.id);
          if (entry.start) entry.start = convertDateObject(entry.start);
          if (entry.end) entry.end = convertDateObject(entry.end);
          return true;
        });

        return filteredEntries;
      })
    );
  }


  // Update a Specification
  updateSpecification(coachId: string, clientId: string, spec: Specification) {
    return this.db
      .collection('coaches')
      .doc(coachId)
      .collection('clients')
      .doc(clientId)
      .collection('specs')
      .doc(spec.id)
      .set(Object.assign({}, spec), { merge: false });
  }

  // Delete a Specification by Date
  deleteSpecification(coachId: string, clientId: string, spec: Specification) {
    console.log('Delete Specification ', spec);
    return this.db
      .collection('coaches')
      .doc(coachId)
      .collection('clients')
      .doc(clientId)
      .collection('specs')
      .doc(spec.id)
      .delete();
  }
}
