import { User } from 'firebase/auth';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import {
  AppUser,
  TrainingLog,
  TrainingPlan,
  UserRole,
  convertDateObject,
} from '../core/thecoach';
import { Observable, first, map, of, shareReplay, switchMap } from 'rxjs';
import { UserService } from './user.service';
import { ca } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class TrainingplanService {
  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private userService: UserService,
  ) {}

  save(tp: TrainingPlan, clientId: string) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }

      if (!tp.id) {
        const id = this.db.createId();
        tp.id = id;
      }

      tp.mesoCycles?.forEach((meso) => {
        if (!meso.id) {
          meso.id = this.db.createId();
        }
        meso.trainingDays?.forEach((trainingDay) => {
          if (!trainingDay.id) trainingDay.id = this.db.createId();
          trainingDay.trainingPlanId = tp.id;

          if (!trainingDay.trainingDayId) {
            trainingDay.trainingDayId = this.db.createId();
          }
        });
      });

      tp.clientId = clientId;
      tp.lastEdit = new Date();
      this.saveTrainingPlanIdtoUser(tp, clientId).subscribe();

      return this.db
        .collection('/trainingplans')
        .doc(appUser.id)
        .collection<TrainingPlan>('/trainingplans')
        .doc(tp.id)
        .set(Object.assign({}, tp));
    });
  }

  update(newtp: TrainingPlan, clientId: string) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }

      newtp.mesoCycles?.forEach((meso) => {
        if (!meso.id) {
          meso.id = this.db.createId();
        }
        meso.trainingDays?.forEach((trainingDay) => {
          if (!trainingDay.id) trainingDay.id = this.db.createId();
          trainingDay.trainingPlanId = newtp.id;

          if (!trainingDay.trainingDayId) {
            trainingDay.trainingDayId = this.db.createId();
          }
        });
      });

      this.saveTrainingPlanIdtoUser(newtp, clientId).subscribe();
      return this.db
        .collection('/trainingplans')
        .doc(appUser.id)
        .collection<TrainingPlan>('/trainingplans')
        .doc(newtp.id)
        .set(Object.assign({}, newtp), { merge: true });
    });
  }

  delete(tp: TrainingPlan) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }

      return this.db
        .collection('/trainingplans')
        .doc(appUser.id)
        .collection<TrainingPlan>('/trainingplans')
        .doc(tp.id)
        .delete();
    });
  }

  saveTrainingPlanIdtoUser(tp: TrainingPlan, clientId: string) {
    return this.userService.getUserFromDatabase(clientId).pipe(
      first(),
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        if (!appUser.trainingplanIds) appUser.trainingplanIds = [];

        if (!appUser.trainingplanIds?.includes(tp.id!))
          appUser.trainingplanIds?.push(tp.id!);

        return this.userService.saveUserData(appUser);
      }),
    );
  }

  getAllTrainingplansForUser(): Observable<TrainingPlan[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }
        let coachId = appUser.coachId;
        if (appUser.role === UserRole.Coach) coachId = appUser.id;

        return this.db
          .collection('/trainingplans')
          .doc(coachId)
          .collection<TrainingPlan[]>('/trainingplans', (ref) => {
            return ref.where('clientId', 'in', [appUser.id]);
          })
          .valueChanges({ idField: 'id' })
          .pipe(
            map((trainingPlans: TrainingPlan[]) => {
              trainingPlans.forEach((trainingPlan) => {
                trainingPlan.lastEdit = convertDateObject(
                  trainingPlan.lastEdit as Date,
                );
                trainingPlan.mesoCycles?.forEach((mesoCycle) => {
                  mesoCycle.trainingDays?.forEach((trainingDay) => {
                    trainingDay.endDate = convertDateObject(
                      trainingDay.endDate as Date,
                    );
                    trainingDay.startDate = convertDateObject(
                      trainingDay.startDate as Date,
                    );
                  });
                });
              });
              return trainingPlans;
            }),
          );
      }),
    );
  }

  private trainingPlanCache: {
    [id: string]: Observable<TrainingPlan | undefined>;
  } = {};

  getTrainingPlan(
    id: string,
    client?: AppUser,
  ): Observable<TrainingPlan | undefined> {
    const cachedTrainingPlan = this.trainingPlanCache[id];

    if (cachedTrainingPlan) {
      return cachedTrainingPlan;
    } else {
      const newTrainingPlan$ = this.auth.appUser$.pipe(
        switchMap((appUser) => {
          if (!appUser) return of(undefined);

          let coachId = appUser.coachId;
          if (
            appUser.role === UserRole.Coach ||
            appUser.role === UserRole.Admin
          )
            coachId = appUser.id;
          if (client && appUser.role === UserRole.Admin)
            coachId = client?.coachId;

          return this.db
            .collection('/trainingplans')
            .doc(coachId)
            .collection<TrainingPlan>('/trainingplans', (ref) => {
              return ref
                .where('clientId', 'in', [appUser.id])
                .where('id', 'in', [id]);
            })
            .doc(id)
            .valueChanges();
        }),
        shareReplay(1),
      );

      this.trainingPlanCache[id] = newTrainingPlan$;

      return newTrainingPlan$;
    }

    /*
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        return this.db
          .collection<TrainingPlan>('/trainingplans', (ref) => {
            return ref
              .where('clientId', 'in', [appUser.id])
              .where('id', 'in', [id]);
          })
          .doc(id)
          .valueChanges();
      })
    );*/
  }
  getAllTrainingplansForClient(client: AppUser): Observable<TrainingPlan[]> {
    return this.db
      .collection('/trainingplans')
      .doc(client.coachId)
      .collection<TrainingPlan>('/trainingplans', (ref) => {
        return ref.where('clientId', '==', client.id);
      })
      .valueChanges();
  }

  getAllTrainingplansForCoach(): Observable<TrainingPlan[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        let coachId = appUser.coachId;
        if (appUser.role === UserRole.Coach || appUser.role === UserRole.Admin)
          coachId = appUser.id;

        return this.db
          .collection('/trainingplans')
          .doc(coachId)
          .collection<TrainingPlan>('/trainingplans', (ref) => {
            return ref.where('ownerId', '==', appUser.id);
          })
          .valueChanges();
      }),
    );
  }

  getAllOLDTrainingplansForCoach(): Observable<TrainingPlan[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection<TrainingPlan>('/trainingplans', (ref) => {
            return ref.where('ownerId', '==', appUser.id);
          })
          .valueChanges();
      }),
    );
  }

  saveToNewDatabase(tpArr: TrainingPlan[]) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }
      for (const tp of tpArr) {
        this.db
          .collection('/trainingplans')
          .doc(appUser.id)
          .collection<TrainingPlan>('/trainingplans')
          .doc(tp.id)
          .set(Object.assign({}, tp));
      }
      console.log('savedToNewDB');
      return;
    });
  }

  deleteOLD(tpArr: TrainingPlan[]) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }
      for (const tp of tpArr) {
        this.db.collection<TrainingPlan>('/trainingplans').doc(tp.id).delete();
      }

      return;
    });
  }
}
