import { Component, OnDestroy, OnInit } from '@angular/core';
import { isDate, parse } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import {
  Observable,
  Subscription,
  combineLatest,
  first,
  map,
  switchMap,
} from 'rxjs';
import { AppUser, CustomTrackingModel } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-coach-assigned-tracking',
  template: `
    <div class="py-4">
      <div>
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Client Name..."
          />
        </div>
      </div>
    </div>

    <div *ngIf="customTrackingModels$ | async as customTrackingModels">
      <ul
        *ngIf="customTrackingModels.length > 0"
        class="bg-gray-100 p-4 rounded-md"
      >
        <li *ngFor="let client of displayClients" class="mb-4">
          <div
            class="p-4 bg-white rounded-md shadow-md flex flex-row justify-between"
          >
            <div>
              <h2 class="text-xl font-bold mb-2">{{ client.displayName }}</h2>
              <p class="text-gray-600 mb-2">{{ client.email }}</p>
            </div>
            <!-- Display any other properties of the client as needed -->
            <div class="flex flex-row w-3/5 items-center justify-between">
              <ng-container *ngIf="!client.isEditing">
                <p class="mb-2">
                  Custom Tracking Model:
                  {{ getCTMName(client, customTrackingModels) }}
                </p>
                <button
                  class="rounded-md bg-teal-600 text-white px-4 py-2 font-semibold"
                  (click)="client.isEditing = true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </button>
              </ng-container>
              <ng-container *ngIf="client.isEditing">
                <select
                  class="border border-gray-300 rounded-md p-2 mb-2 w-3/5"
                  [(ngModel)]="client.ctmId"
                >
                  <option [value]="">NO CTM</option>

                  <option
                    *ngFor="let model of customTrackingModels"
                    [value]="model.id"
                  >
                    {{ model.name }}
                  </option>
                </select>
                <button
                  class="rounded-md bg-teal-600 text-white px-4 py-2 font-semibold"
                  (click)="saveCustomTrackingModel(client)"
                >
                  Save
                </button>

                <button
                  class="rounded-md bg-yellow-600 text-white px-4 py-2 font-semibold"
                  (click)="onCancelEdit(client)"
                >
                  Cancel
                </button>
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  `,
  styles: [],
})
export class CoachAssignedTrackingComponent implements OnInit, OnDestroy {
  userSubscription: Subscription | undefined;
  user: AppUser | undefined;
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];
  searchNameValue = '';

  customTrackingModels$: Observable<CustomTrackingModel[]> | undefined;

  ngOnInit(): void {
    this.customTrackingModels$ = this.ctms.getModelsForCreator();

    this.userSubscription = this.auth.appUser$
      .pipe(
        switchMap((user: AppUser | null) => {
          const observables: Observable<AppUser>[] = [];
          if (user) {
            this.user = user;
            if (this.user.clientIds && this.user.clientIds.length > 0) {
              for (const clientid of user.clientIds!) {
                this.clientIdDatabase = user.clientIds as string[];

                observables.push(
                  this.userService.getUserFromDatabase(
                    clientid,
                  ) as Observable<AppUser>,
                );
              }
            }
          }
          return combineLatest(observables);
        }),
      )
      .pipe(
        map((clients: AppUser[]) => {
          return clients
            .filter((client) => client && client.coachId === this.user!.id)
            .map((client) => {
              let cl = client as AppUser;
              // console.log(cl.birthdate);
              cl.isEditing = false;

              //cl.birthdate = (cl.birthdate as unknown as Timestamp).toDate();

              cl.birthdate = this.convertDateObject(cl.birthdate!);

              if (cl.birthdate === undefined) delete cl.birthdate;
              return cl;
            });
        }),
      )
      .subscribe((clients) => {
        for (const client of clients) {
          //this.getWeightLogData(client, 30);

          if (this.clientDatabase.some((e) => e.id === client.id)) {
            let i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }
        this.displayClients = this.clientDatabase;
      });
    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }
      });
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  onCancelEdit(client: AppUser) {
    client.isEditing = !client.isEditing;
  }

  convertDateObject(date: Date | string): Date | undefined {
    if (date === '') {
      // console.log('found Undefined');
      return undefined;
    }

    if (isDate(date)) {
      //  console.log('leaving Date');
      return date as Date;
    }

    if (typeof date === 'string') {
      // console.log('convertingString');
      return parse(date, 'yyyy-MM-dd', new Date());
    }

    try {
      //console.log('No Case found', date);
      return (date as unknown as Timestamp).toDate();
    } catch (e) {
      return undefined;
    }
  }

  saveCustomTrackingModel(client: AppUser) {
    client.isEditing = !client.isEditing;
    this.userService.overWriteUser(client);
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  getCTMName(client: AppUser, database: CustomTrackingModel[]) {
    let ctm: CustomTrackingModel | undefined;
    if (client.ctmId) {
      ctm = database.find((ctm) => {
        if (ctm.id === client.ctmId) return ctm;

        return undefined;
      });
    } else {
      ctm = undefined;
    }

    if (ctm !== undefined) {
      return ctm.name;
    } else {
      return 'NO CTM Assigned';
    }
  }

  constructor(
    private userService: UserService,
    private auth: AuthService,
    private ctms: CustomTrackingModelService,
  ) {}
}
