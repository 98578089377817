import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppUser, Macros } from '../core/thecoach';
import * as _ from 'lodash';
import { formatISO, isValid, parse } from 'date-fns';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-admin-user-form',
  template: `
 <div class="mx-auto mt-4" *ngIf="userForm && user && userProperties">
<form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="flex flex-col">
  <!-- First Column -->
  <div class="flex flex-row">
  <div class="w-1/2 pr-4" >
    <ng-container *ngFor="let prop of userProperties.slice(0, math.ceil(userProperties.length / 2))">
      <div class="mb-4">
        <label for="{{prop.key}}" class="block text-gray-700 text-sm font-bold mb-2">{{prop.key | titlecase}}</label>
        <ng-container [ngSwitch]="prop.value.constructor.name">
          <input *ngSwitchCase="'String'" type="text" [id]="prop.key" [formControlName]="prop.key" class="w-full rounded border p-2">
          <input *ngSwitchCase="'Number'" type="number" [id]="prop.key" [formControlName]="prop.key" class="w-full rounded border p-2">
          <input *ngSwitchCase="'Date'" type="date" [id]="prop.key" [formControlName]="prop.key" class="w-full rounded border p-2">
          <input *ngSwitchCase="'Boolean'" type="checkbox" [id]="prop.key" [formControlName]="prop.key" class="rounded border p-2">
          <ng-container *ngSwitchCase="'Array'">
            <div *ngFor="let control of userFormControls(prop.key); let i = index" class="mb-2">
              <div [formGroupName]="prop.key">
                <input type="text" [formControlName]="i" class="w-full rounded border p-2">
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Object'">
            <div *ngIf="getMacrosObject(prop.value)">
              <div [formGroupName]="prop.key" class="mb-2">
                <label>Protein</label>
                <input type="number" formControlName="protein" class="w-full rounded border p-2">
                <label>Carbs</label>
                <input type="number" formControlName="carbs" class="w-full rounded border p-2">
                <label>Fat</label>
                <input type="number" formControlName="fat" class="w-full rounded border p-2">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <!-- Second Column -->
  <div class="w-1/2 pl-4">
    <ng-container *ngFor="let prop of userProperties.slice(math.ceil(userProperties.length / 2))">
      <div class="mb-4">
        <label for="{{prop.key}}" class="block text-gray-700 text-sm font-bold mb-2">{{prop.key | titlecase}}</label>
        <ng-container [ngSwitch]="prop.value.constructor.name">
          <input *ngSwitchCase="'String'" type="text" [id]="prop.key" [formControlName]="prop.key" class="w-full rounded border p-2">
          <input *ngSwitchCase="'Number'" type="number" [id]="prop.key" [formControlName]="prop.key" class="w-full rounded border p-2">
          <input *ngSwitchCase="'Date'" type="date" [id]="prop.key" [formControlName]="prop.key" class="w-full rounded border p-2">
          <input *ngSwitchCase="'Boolean'" type="checkbox" [id]="prop.key" [formControlName]="prop.key" class="rounded border p-2">
          <ng-container *ngSwitchCase="'Array'">
            <div *ngFor="let control of userFormControls(prop.key); let i = index" class="mb-2">
              <div [formGroupName]="prop.key">
                <input type="text" [formControlName]="i" class="w-full rounded border p-2">
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Object'">
            <div *ngIf="getMacrosObject(prop.value)">
              <div [formGroupName]="prop.key" class="mb-2">
                <label>Protein</label>
                <input type="number" formControlName="protein" class="w-full rounded border p-2">
                <label>Carbs</label>
                <input type="number" formControlName="carbs" class="w-full rounded border p-2">
                <label>Fat</label>
                <input type="number" formControlName="fat" class="w-full rounded border p-2">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  </div>
   <!-- Button to submit the form -->
        <button type="submit" class="w-fit bg-teal-500 hover-bg-teal-700 text-white font-bold py-2 px-4 rounded">Save</button>

</form>

</div>
  `,
  styles: [
  ]
})
export class AdminUserFormComponent implements OnInit {
  @Input() user!: AppUser;
  @Output() closeUser: EventEmitter<boolean> = new EventEmitter()
  run = true;
  i = 0;
  math = Math;
  userForm: FormGroup | undefined;
  userProperties: { key: string, value: any }[] | undefined; // Array to store key-value pairs



  constructor(private fb: FormBuilder, private userSerivce: UserService) {


  }

  getObjectKeyLength(obj: Object): number {
    return Object.keys(obj).length;
  }

  getMacrosObject(obj: Object): boolean {
    return ('protein' in obj && 'carbs' in obj && 'fat' in obj)
  }

  log(val: any) {
    if (this.run) {
      console.log(val)
      this.i++;
      if (this.i > 20) this.run = false;
      //   this.run = false

    }
  }


  userFormControls(key: string) {

    return (this.userForm?.get(key) as FormArray).controls
  }
  ngOnInit(): void {
    if (!this.user) {
      return;
    }
    //  console.log(this.user)
    this.userProperties = Object.entries(this.user)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => ({ key, value }));



    this.userForm = this.fb.group({});

    for (const key of Object.keys(this.user)) {
      // Check if the property exists in the user object and is not null
      if (this.user.hasOwnProperty(key) && this.user[key] !== null) {
        if (Array.isArray(this.user[key]) && this.user[key].every((item: string) => typeof item === 'string')) {
          const formArray = new FormArray(
            this.user[key].map((value: string) => new FormControl(value))
          );
          this.userForm.addControl(key, formArray);
        } else if (this.user[key] instanceof Object && 'protein' in this.user[key] && 'carbs' in this.user[key] && 'fat' in this.user[key]) {


          let macrosGroup = this.fb.group({});

          macrosGroup.addControl('protein', new FormControl(this.user[key].protein));
          macrosGroup.addControl('carbs', new FormControl(this.user[key].carbs));
          macrosGroup.addControl('fat', new FormControl(this.user[key].fat));

          this.userForm.addControl(key, macrosGroup);
        } else if (this.user[key] instanceof Date) {
          // Handle Date type by formatting the value
          this.userForm.addControl(key, new FormControl(formatISO(this.user[key]).split('T')[0]));
        } else {
          this.userForm.addControl(key, new FormControl(this.user[key]));
        }
      }
    }
    // console.log(this.userForm, this.userProperties)
  }





  onSubmit() {
    this.userSerivce.saveUserData(this.formToUser(this.userForm as FormGroup))
      .then(() => {

        this.closeUser.emit(true)
      })
      .catch((e: Error) =>
        console.error(e))

  }


  formToUser(userForm: FormGroup): any {
    const user: any = {};

    Object.keys(userForm.controls).forEach((key) => {
      const control = userForm.get(key);
      //console.log(control)
      if (control instanceof FormGroup) {
        // Handle nested objects
        if ('protein' in control.controls && 'carbs' in control.controls && 'fat' in control.controls) {
          user[key] = {
            protein: control.get('protein')?.value,
            carbs: control.get('carbs')?.value,
            fat: control.get('fat')?.value,
          };
        } else {
          // Handle other types of nested objects if needed
        }
      } else if (typeof control?.value === 'boolean') {
        // Handle Boolean type
        user[key] = control.value;
      } else if (typeof control?.value === 'number') {
        // Handle Number type
        user[key] = control.value;
      } else if (Array.isArray(control?.value)) {
        // Handle Arrays
        user[key] = control?.value;

      } else if (isValid(parse(control?.value, 'yyyy-MM-dd', new Date()))) {
        // Handle Date type



        user[key] = new Date(control?.value + " 00:00:00")
      }


      else {
        // Handle String type
        user[key] = control?.value;
      }
    });

    return user;
  }
}

