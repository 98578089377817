import { Component } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { AppUser, Note, convertDateObject } from 'src/app/core/thecoach';
import { NoteService } from 'src/app/services/note.service';

@Component({
  selector: 'app-coach-customer-notes',
  template: `
    <div class="flex flex-col w-full justify-between items-center">
      <app-coach-customer-menu
        class="w-full"
        (clientWasSelected)="onSelectedClient($event)"
      ></app-coach-customer-menu>
      <div
        *ngIf="selectedClient !== undefined"
        class="flex justify-center items-center w-10/12 "
      >
        <div class="w-fit pr-4">
          <div class="">
            <input
              type="search"
              name="search"
              id="search"
              [(ngModel)]="searchTermValue"
              class="block w-[24rem] rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
              placeholder="Searchterm ..."
            />
          </div>
        </div>
        <button
          (click)="showNewNoteModal = true"
          *ngIf="!showNewNoteModal"
          class="bg-teal-500 h-fit w-fit whitespace-nowrap hover:bg-teal-700 text-white font-bold py-2 px-4 rounded"
        >
          Create New Note
        </button>
      </div>
    </div>

    <ul
      *ngIf="selectedClient !== undefined"
      role="list"
      class="pt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <ng-container *ngIf="notes$ | async as notes">
        <li
          class="col-span-1 flex h-fit flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md ring-2 ring-gray-200"
          *ngFor="let note of notes | filterSearchTerm : searchTermValue"
        >
          <app-notes-module
            [note]="note"
            (editNote)="onEditNote($event)"
          ></app-notes-module>
        </li>
      </ng-container>
      <!-- More people... -->
    </ul>

    <app-notes-modal
      *ngIf="showNewNoteModal"
      (close)="onCancel($event)"
      (note)="onSaveNote($event)"
    >
    </app-notes-modal>

    <app-notes-modal
      *ngIf="editNote && showNewNoteModal"
      [editNote]="editNote"
      (note)="onSaveNote($event)"
      (close)="onCancel($event)"
    >
    </app-notes-modal>
  `,
  styles: [],
})
export class CoachCustomerNotesComponent {
  constructor(private notesService: NoteService) {}
  showNewNoteModal = false;
  searchTermValue = '';
  selectedClient: AppUser | undefined = undefined;
  notes$: Observable<Note[]> | undefined;
  editNote: Note | undefined = undefined;
  onSelectedClient(client: AppUser) {
    if (client === undefined) {
      this.selectedClient = undefined;
      this.notes$ = of([]);
      this.searchTermValue = '';
    } else {
      this.selectedClient = client;
      this.notes$ = this.notesService.getAllNotes(this.selectedClient);
    }
  }

  onEditNote(note: Note) {
    this.showNewNoteModal = !this.showNewNoteModal;
    this.editNote = note;
  }

  onSaveNote(note: Note) {
    if (this.selectedClient && note) {
      this.notesService.saveNoteFromCoachToUser(this.selectedClient, note);
    }

    this.editNote = undefined;
    this.showNewNoteModal = !this.showNewNoteModal;
  }

  onCancel(event: boolean) {
    if (!event) {
      this.editNote = undefined;
      this.showNewNoteModal = !this.showNewNoteModal;
    }
  }
}
