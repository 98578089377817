import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { isSameDay } from 'date-fns';
import { ta } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import {
  CustomInputType,
  WeightEntryData,
  AppUser,
  Macros,
  calculateKcalFromMacros,
  calcuclateDailydifferenceMaster,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateSevenDayAverageMaster,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
  CustomTrackingModel,
  TrainingLog,
  convertDateObject,
  formatArraysForTables,
} from 'src/app/core/thecoach';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-weightlog-table-dashboard-v2',
  template: `
    <div *ngIf="client && client.coachId">
      <div class="relative">
        <button
          *ngIf="false"
          (click)="toggleCTV()"
          type="button"
          class="absolute  -top-4 -left-[0.75rem] rounded-full bg-teal-600 p-1.5 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </button>
      </div>
      <div>
        <ng-container
          *ngFor="let table of splitArray; let i = index"
          class="space-y-4"
        >
          {{ log(table) }}
          <table
            app-weightlog-customtable-dashboard
            class="divide-y divide-gray-300  table-fixed "
          >
            <thead
              app-weightlog-customheader-dashboard
              [index]="i"
              [client]="client"
              [ctm]="findCTMForTable(table)"
              [displayCTV]="displayCTV"
              class=""
            ></thead>

            <ng-container *ngFor="let data of table; let i = index">
              <ng-container *ngIf="i % 7 === 0">
                <tbody
                  app-weightlog-custom-average-dashboard
                  [weightLogData]="table"
                  [client]="client"
                  [displayCTV]="displayCTV"
                  [j]="i"
                  [weightEntry]="data"
                  class="divide-y divide-gray-200"
                ></tbody>
              </ng-container>

              <tbody
                app-weightlog-custombody-dashboard
                [client]="client"
                [j]="i"
                [displayCTV]="displayCTV"
                [weightEntry]="data"
                [weightLogData]="table"
                class="divide-y divide-gray-200"
              ></tbody>
            </ng-container>
          </table>
        </ng-container>
      </div>
    </div>
  `,
  styles: [],
})
export class WeightlogTableDashboardV2Component implements OnChanges {
  @Input() weightLogData!: WeightEntryData[] | null;
  @Input() client!: AppUser | undefined;

  CustomInputType = CustomInputType;
  displayCTV = true;
  splitArray: WeightEntryData[][] = [[]];

  trainingLogSubscription: Subscription | undefined;
  loadedTrainingLog: TrainingLog | null = null;

  run = true;
  runs = 6;
  constructor(
    protected ctmService: CustomTrackingModelService,
    private cdr: ChangeDetectorRef,
    private trainingLogService: LogService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['weightLogData']) {
      this.weightLogData = changes['weightLogData'].currentValue;
      this.ngOnInit();
    }
  }

  log(val: any) {
    if (this.runs < 5) {
      console.log(val);

      this.runs++;
    }
  }

  ngOnInit(): void {
    if (this.weightLogData) {
      this.weightLogData = this.weightLogData?.reverse();
    }

    this.splitArray = this.formatArrays(this.weightLogData!);
    if (this.client) this.getTrainingLog(this.client);
  }

  toggleCTV() {
    this.displayCTV = !this.displayCTV;
    this.cdr.detectChanges();
    window.dispatchEvent(new Event('resize'));
  }

  getTrainingLog(client: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getLatestLogForUser(
        client.id!,
        client.traininglogIds?.at(client.traininglogIds.length - 1) as string,
      )
      .subscribe((val) => {
        if (val) {
          this.loadedTrainingLog = val;
          this.syncTrainingLogWithWeightEntry(
            this.loadedTrainingLog,
            this.splitArray,
          );
        }
      });
  }

  syncTrainingLogWithWeightEntry(
    trainingLog: TrainingLog,
    weightEntryData: WeightEntryData[][],
  ) {
    weightEntryData.forEach((table) => {
      table.forEach((day) => {
        trainingLog.mesoCycle?.forEach((meso) => {
          meso.trainingDays?.find((td) => {
            const logStartDate = convertDateObject(td.startDate as Date);
            const weightDate = convertDateObject(day.date as Date);
            if (
              logStartDate &&
              weightDate &&
              isSameDay(logStartDate, weightDate)
            ) {
              day.sessionName = td.trainingDayName;
            }
          });
        });
      });
    });
  }

  formatArrays(wldata: WeightEntryData[]) {
    return formatArraysForTables(wldata);
  }

  findCTMForTable(tableData: WeightEntryData[]) {
    let ctm: CustomTrackingModel | undefined;

    ctm = tableData.find((data) => data.customTrackingLog)?.customTrackingLog;

    return ctm;
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  calculateDailydifference(
    weight: number,
    index: number,
    client: AppUser,
  ): string {
    return calcuclateDailydifferenceMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateDifferenceFromStart(index: number, client: AppUser): string {
    return calculateDifferenceFromStartMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateFiveDayAverage(index: number, client: AppUser): string {
    return calculateFiveDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateSevenDayAverage(index: number, client: AppUser): string {
    return calculateSevenDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglDaily(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglWeekly(index: number, client: AppUser): string {
    return calculateRoglWeeklyMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
}
