import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { AppUser, CustomCheckinModel } from '../core/thecoach';
import { BehaviorSubject, first, Observable, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomCheckinModelService {

  constructor(private db: AngularFirestore, private auth: AuthService) { }

  addCheckinModelToDB(model: CustomCheckinModel) {
    return new Promise<void>((resolve, reject) => {
      this.auth.appUser$.pipe(first()).subscribe((appUser) => {
        if (appUser) {
          if (!model.id) model.id = this.db.createId();
          if (!model.ownerId) model.ownerId = appUser.id as string;

          this.db.collection('customCheckinModels')
            .doc(appUser.id)
            .collection('customCheckinModels')
            .doc(model.id)
            .set(Object.assign({}, model), { merge: true })
            .then(() => {
              resolve(); // Resolve the promise when the operation is complete
            })
            .catch((error) => {
              reject(error); // Reject the promise if there's an error
            });
        } else {
          reject("User not found"); // Reject the promise if appUser is null
        }
      });
    });
  }
  private selectedCheckinModelSubject = new BehaviorSubject<CustomCheckinModel | undefined>(undefined);
  selectedCheckinModel$ = this.selectedCheckinModelSubject.asObservable();


  setSelectedCheckiModel(model: CustomCheckinModel) {
    this.selectedCheckinModelSubject.next(model);
  }

  private cachedCheckinModels$: Observable<CustomCheckinModel[]> | undefined

  getModels() {
    if (!this.cachedCheckinModels$) {
      this.cachedCheckinModels$ = this.auth.appUser$.pipe(
        switchMap((appUser) => {
          if (!appUser) return of([]);
          return this.db.collection('customCheckinModels')
            .doc(appUser.id)
            .collection<CustomCheckinModel>('customCheckinModels')
            .valueChanges()
        }),
        shareReplay(1)
      )

    }
    return this.cachedCheckinModels$;
  }

  deleteCheckinModel(id: string) {
    return new Promise<void>((resolve, reject) => {

      this.auth.appUser$.pipe(first()).subscribe((appUser) => {
        if (!appUser) return null;

        return this.db
          .collection('customCheckinModels')
          .doc(appUser.id)
          .collection('customCheckinModels')
          .doc(id)
          .delete()
          .then(() => { resolve(); })
          .catch((error) => { reject(error); });


      })
    })
  }


  private cachedModel$: Observable<CustomCheckinModel> | undefined;
  private cacheModelId: string | undefined;

  getModelByID(appUser: AppUser) {
    if (!this.cachedModel$ || this.cacheModelId !== appUser.ccmId) {
      this.cacheModelId = appUser.ctmId;
      this.cachedModel$ = this.db
        .collection('customCheckinModels')
        .doc(appUser.coachId)
        .collection<CustomCheckinModel>('customCheckinModels')
        .doc(appUser.ccmId)
        .valueChanges() as Observable<CustomCheckinModel>;
    }

    return this.cachedModel$;
  }

  getModelByIdByCoach(id: string, coachId: string) {
    if (!this.cachedModel$ || this.cacheModelId !== id) {
      this.cacheModelId = id;
      this.cachedModel$ = this.db
        .collection('customTrackingModels')
        .doc(coachId)
        .collection<CustomCheckinModel>('customTrackingModels')
        .doc(id)
        .valueChanges() as Observable<CustomCheckinModel>;

      shareReplay(1);
    }

    return this.cachedModel$;
  }



}
