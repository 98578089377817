import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { applyActionCode } from 'firebase/auth';
import { first } from 'rxjs';
import { AppUser, Exercise, atLeastOneValidation } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { ExerciseService } from 'src/app/services/exercise.service';

import * as typesense from 'typesense';
@Component({
  selector: 'app-template-training-plan',
  template: `
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <!-- Content goes here -->
      <form [formGroup]="trainingPlanForm">
        <div class="flex flex-col w-full">
          <div class="items-center w-full flex flex-row justify-between">
            <div class="flex flex-row w-full justify-center items-center">
              <div class="flex flex-col w-5/12">
                <input
                  type="text"
                  name="name"
                  id="name"
                  formControlName="name"
                  class="block w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="Enter Trainingplan Name"
                />

                <div class="pt-2">
                  <textarea
                    rows="1"
                    formControlName="description"
                    name="description"
                    id="description"
                    placeholder="Enter your Trainingplan description..."
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </div>

              <button
                (click)="testFun()"
                type="button"
                class="rounded-md ml-4 bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                TestFunc
              </button>
              <button
                (click)="onAddMeso()"
                type="button"
                class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Add Meso
              </button>
              <button
                type="button"
                [disabled]="!trainingPlanForm.valid"
                class="disabled:opacity-25 rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Save Trainingplan
              </button>
            </div>
            <button
              type="button"
              [routerLink]="'/tp-list/'"
              class="rounded-md ml-4 bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              Cancel
            </button>
            <button
              (click)="migrate()"
              type="button"
              class="rounded-md hidden ml-4 bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              FetchData
            </button>
          </div>

          <ul
            class="flex divide-x divide-gray-200 rounded-lg shadow w-fit flex-row pt-4"
          >
            <li
              *ngFor="let meso of mesoCycles.controls; let i = index"
              class="relative text-gray-900 cursor-pointer rounded-l-lg min-w-0  flex flex-row  items-center bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              aria-current="page"
              (click)="onSelectMeso(meso)"
            >
              <span class="pr-2">{{ getMesoName(meso) }}</span>
              <span
                *ngIf="selectedMeso === meso"
                aria-hidden="true"
                class="bg-teal-500 absolute  inset-x-0 bottom-0 h-0.5"
              ></span>
            </li>
          </ul>
          <div *ngIf="selectedMeso">
            <app-template-training-mesocycle
              [mesoForm]="selectedMeso"
              [exerciseDB]="exerciseDB"
            >
            </app-template-training-mesocycle>
          </div>

          <!--
    <button type="button" [routerLink]="'/mealplaner/daycreator/new'" class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Create new Day</button>
-->
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class TemplateTrainingPlanComponent {
  trainingPlanForm = this.fb.group(
    {
      id: [''],
      name: ['', Validators.required],
      description: ['', Validators.required],
      clientId: [''],
      ownerId: [''],
      lastEdit: [''],

      mesoCycles: this.fb.array([]),
    },
    { validator: this.atLeastOneMesoCycle },
  );

  selectedMeso: FormGroup | undefined = undefined;
  run = false;
  user: AppUser | undefined = undefined;
  exerciseDB: Exercise[] = [];
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private exersciseService: ExerciseService,
  ) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) return;

      this.user = appUser;
    });

    this.exersciseService
      .getAllExercisesForUser()
      .pipe(first())
      .subscribe((exercises) => {
        console.log('loaded Exercisese', exercises);
        this.exerciseDB = exercises;
      });
    /*
    this.exersciseService
      .getAllExercisesForUser()
      .pipe(first())
      .subscribe((exercises) => {
        if (exercises) {
          const exercisesStorage = JSON.parse(
            localStorage.getItem('exercises') as string,
          );
          if (exercisesStorage && exercisesStorage < exercises) {
            localStorage.setItem('exercises', JSON.stringify(exercises));
            console.log('Updated Oldlength ', exercisesStorage.length);
            console.log('Updated Newlength ', exercises.length);
          } else {
            console.log('No updated needed');
          }
        }
      });*/
  }

  //tried to use typesense - decided against
  // testFun() {
  //   const test = this.fireFunctions.httpsCallable('testerfunction');
  //   test({ userId: this.user?.id })
  //     .pipe(first())
  //     .subscribe((result) => {
  //       console.log('Restult of Testfunction', result);
  //     });
  // }

  testFun() {}

  migrate() {
    // this.exersciseService.migrateToTypesense();
  }

  get mesoCycles() {
    return this.trainingPlanForm.get('mesoCycles') as FormArray;
  }

  atLeastOneMesoCycle(control: AbstractControl) {
    return atLeastOneValidation(control, 'mesoCycles');
  }

  convertToFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }

  getMesoName(control: AbstractControl) {
    return control.get('name')?.value;
  }

  onAddMeso() {
    const mesoName = 'Meso ' + (this.mesoCycles.length + 1);

    const meso = this.fb.group({
      id: [''],
      name: [mesoName, Validators.required],
      trainingDays: this.fb.array([]),
    });

    this.mesoCycles.push(meso);
  }

  onSelectMeso(control: AbstractControl) {
    if (this.selectedMeso && this.selectedMeso === (control as FormGroup)) {
      this.selectedMeso = undefined;
    } else {
      this.selectedMeso = control as FormGroup;
    }
  }
}
