import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseError } from 'firebase/app';
import { AuthErrorConstants } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  template: `
    <!--
  This example requires some changes to your config:
  
  
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  
-->
    <!--
  This example requires updating your template:

  
  <html class="h-full bg-gray-50">
  <body class="h-full">
  
-->
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          class="mx-auto h-20 w-auto"
          src=".././assets/icons/TCCLogo-min.webp"
          alt="The Coach Cloud Logo"
        />
        <h2
          class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
        >
          Sign in to your account
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            class="space-y-6"
            #loginForm="ngForm"
            (ngSubmit)="onLoginWithEmailAndPassword()"
          >
            <div *ngIf="!isMenuForgottenPassword">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Email address</label
                >
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    [(ngModel)]="loginData.email"
                    autocomplete="email"
                    required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Password</label
                >
                <div class="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    [(ngModel)]="loginData.password"
                    required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="isMenuForgottenPassword">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Email address</label
                >
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    [(ngModel)]="resetPasswordEmail"
                    autocomplete="email"
                    required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900
                shadow-sm ring-1 ring-inset ring-gray-300
                placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center"></div>

              <div class="text-sm">
                <a
                  *ngIf="!isMenuForgottenPassword"
                  (click)="onToggleForgotPassword()"
                  class="font-medium text-teal-600 hover:text-teal-500 cursor-pointer"
                  >Forgot your password?</a
                >

                <a
                  *ngIf="isMenuForgottenPassword"
                  (click)="onToggleForgotPassword()"
                  class="font-medium text-teal-600 hover:text-teal-500 cursor-pointer"
                  >Back to Login</a
                >
              </div>
            </div>

            <div>
              <button
                *ngIf="!isMenuForgottenPassword"
                type="submit"
                class="flex w-full justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Sign in
              </button>
              <button
                *ngIf="isMenuForgottenPassword"
                type="button"
                (click)="onResetPassword()"
                class="flex w-full justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Reset Password
              </button>
            </div>
            <div *ngIf="isError" class="flex w-full justify-center text-center">
              <p class="font-bold text-red-500 italic">{{ errorMsg }}</p>
            </div>
          </form>
          <!-- 
          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="bg-white px-2 text-gray-500"
                  >Or continue with</span
                >
              </div>
            </div>
            
            <div class="mt-6 flex justify-center ">
              <div>
                <a
                  (click)="onLoginWithGoogle()"
                  class="inline-flex w-full justify-center rounded-md bg-white py-2 px-4 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                >
                  <div
                    class="mx-auto flex h-10 w-10 items-center justify-center rounded-full"
                  >
                  -->
          <!-- Heroicon name: outline/check -->
          <!--
                    <img src="../../assets/icons/google-color-icon.png" />
                  </div>
                </a>
              </div>
          
            </div>
           
          </div>
               -->
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class LoginComponent {
  isMenuForgottenPassword = false;
  loginData: { email: string; password: string } = { email: '', password: '' };
  resetPasswordEmail: string = '';
  isError = false;
  errorMsg: string | undefined;

  constructor(private authService: AuthService, private router: Router) { }

  onLoginWithGoogle() {
    this.authService.GoogleAuth();
  }

  onLoginWithEmailAndPassword() {
    this.authService
      .SignIn(this.loginData.email, this.loginData.password)
      .catch((error) => {
        const code = error.code.slice(5, error.code.length);
        if (error instanceof FirebaseError && code in AuthErrorConstants.authErrors) {
          this.errorMsg = AuthErrorConstants.authErrors[code];

          //  console.log(this.errorMsg);
        } else {
          this.errorMsg = 'An unknown error occurred. Please try again later.';
        }

        this.isError = true;
      });
  }

  onToggleForgotPassword() {
    this.isMenuForgottenPassword = !this.isMenuForgottenPassword;
  }

  onResetPassword() {
    this.authService.ForgotPassword(this.resetPasswordEmail);
  }
}
