import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Note } from 'src/app/core/thecoach';

@Component({
  selector: 'app-notes-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <app-coach-customer-notes-creator
                [editNote]="editNote"
                (canceled)="onCancel($event)"
                (saveNote)="onSaveNote($event)"
              ></app-coach-customer-notes-creator>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class NotesModalComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  @Output() note = new EventEmitter<Note>();
  @Input() editNote: Note | undefined;
  isModal = true;

  constructor() {}
  ngOnInit(): void {}

  onCancel(event: boolean) {
    this.close.emit(event);
    this.toggleModal();
  }

  onSendValue(value: boolean) {
    this.close.emit(value);
  }

  onSaveNote(note: Note) {
    this.note.emit(note);
    this.toggleModal();
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }
}
