import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { DayWithMeals, MealPlanWithDays, MealWithIngredients, dayMacroInfo, manipulateNumbersDown, mealPlanMacroInfo } from '../../../../../backend/core/thecoach';
import * as firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { MealplanService } from 'src/app/services/mealplaner/mealplan.service';
import { calculateKcalFromMacros } from 'src/app/core/thecoach';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-mealplaner',
  template: `




<div class="px-4 sm:px-6 lg:px-8" *ngIf="!loadingToggler; else dataloaded">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold leading-6 text-gray-900">Mealplans</h1>
      <p class="mt-2 text-sm text-gray-700">A list of all the Mealplans in your account.</p>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
    <button type="button" [routerLink]="'/mealplaner/new'" class="rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">New Meal Plan</button>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Days</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
                       <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Copy</span>
              </th>
                                 <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr *ngFor="let mealplan of mealPlans">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <div>{{mealplan.name}}</div>


          </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div class="pb-2">Total: {{calcTotalMealPlanMacros(mealplan)}}</div>
                    <div *ngFor="let day of mealplan.days" class="text-gray-500 italic text-xs flex flex-row">
            <p class="font-bold pr-4">{{day.name}} - {{getDayInfo(day)}}</p>
            <p>Meals: {{day.meals.length}}</p>
          </div>
        </td>
              <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <a [routerLink]="'/mealplaner/'+mealplan.id" class="text-teal-600 hover:text-teal-900">Edit</a>
              </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <a [routerLink]="['/mealplaner', mealplan.id]" [queryParams]="{copy:true}" class="text-teal-600 hover:text-teal-900">Copy</a>
              </td>
                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <svg (click)="onDelete(mealplan)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 stroke-red-500 cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

                      </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>


</div>
<nav class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
  <div class="hidden sm:block">
    <p class="text-sm text-gray-700">
      Showing
      <span class="font-medium">{{(currentPage-1) *10 + 1}}</span>
      to
      <span class="font-medium">{{(currentPage-1)*10 + mealPlans.length}}</span>
      of
      <span class="font-medium">{{totalMealPlanCount}}</span>
      results
    </p>
  </div>
  <div class="flex flex-1 justify-between sm:justify-end">
    <div (click)="this.toggleLoader(); movePage(-1)" class="cursor-pointer relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Previous</div>

    <div (click)="this.toggleLoader();movePage(+1)" class="cursor-pointer relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Next</div> </div>
</nav>
<ng-template #dataloaded>
  <ngx-skeleton-loader count="5" animation="progress" [theme]="{height: '50px'}"></ngx-skeleton-loader>
</ng-template>
  `,
  styles: [
  ]
})
export class MealplanerComponent {
  constructor(private http: HttpClient, private mealPlanService: MealplanService, private httpUser: HttpUserService) {
    this.toggleLoader();
    this.initMealPlans()
  }
  loadingToggler = false;
  mealPlans: MealPlanWithDays[] = []
  i = 0;

  totalMealPlanCount: number | undefined = undefined
  currentPage = 1;



  toggleLoader() {
    this.loadingToggler = !this.loadingToggler;
  }

  onDelete(mealplan: MealPlanWithDays) {
    this.toggleLoader()




    this.httpUser.currentUser$.pipe(first()).subscribe(user => {
      if (user) {
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          return this.http.delete(environment.apiUrl + '/mealplans/' + mealplan.id, { headers }).subscribe(data => {
            this.initMealPlans();
          })


        }).catch((error) => {
          // Handle error
          this.toggleLoader()
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        // Handle the case when no user is signed in
        this.toggleLoader()
        console.error('No user signed in.');
        return null;
      }
    })

  }




  initMealPlans() {
    this.mealPlans = [];
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {
      if (user) {

        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          this.http.get<{ mealPlanCount: number, mealPlans: MealPlanWithDays[] }>(`${environment.apiUrl}/mealplans?skip=${((this.currentPage - 1) * 10)}`, { headers }).subscribe(transactionObject => {

            if (transactionObject && transactionObject.mealPlans) {
              transactionObject.mealPlans.map(mealplan => {
                mealplan.days.map(day => {
                  day.meals.map(meal => {
                    meal.addedIngredients.map(addedIng => {
                      addedIng.ingredient = manipulateNumbersDown(addedIng.ingredient)
                    })
                  })
                })

              }
              )
              this.totalMealPlanCount = transactionObject.mealPlanCount
              this.mealPlans = transactionObject.mealPlans
              this.mealPlanService.setMealPlans(this.mealPlans)
              this.toggleLoader()
            }
          })


        }).catch((error) => {
          // Handle error
          console.error('Error getting ID token:', error);
          this.toggleLoader()
          throw error;
        });
      } else {
        // Handle the case when no user is signed in
        console.error('No user signed in.');
        this.toggleLoader()
        return null;
      }


    })
  }



  movePage(increment: number) {

    this.currentPage += increment;


    if (this.currentPage <= 0) {
      this.currentPage = 1;
      this.toggleLoader()
    } else if (this.totalMealPlanCount && this.currentPage > Math.ceil(this.totalMealPlanCount / 10)) {
      this.currentPage = Math.ceil(this.totalMealPlanCount / 10)
      this.toggleLoader()
    } else {

      this.mealPlans = [];

      this.httpUser.currentUser$.pipe(first()).subscribe(user => {
        if (user) {

          return user.getIdToken(true).then((idToken) => {
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

            this.http.get<{ mealPlanCount: number, mealPlans: MealPlanWithDays[] }>(`${environment.apiUrl}/mealplans?skip=${((this.currentPage - 1) * 10)}`, { headers }).subscribe(transactionObject => {
              if (transactionObject && transactionObject.mealPlans) {
                transactionObject.mealPlans.map(mealplan => {
                  mealplan.days.map(day => {
                    day.meals.map(meal => {
                      meal.addedIngredients.map(addedIng => {
                        addedIng.ingredient = manipulateNumbersDown(addedIng.ingredient)
                      })
                    })
                  })

                }
                )
                this.totalMealPlanCount = transactionObject.mealPlanCount
                this.mealPlans = transactionObject.mealPlans
                this.mealPlanService.setMealPlans(this.mealPlans)
                this.toggleLoader()
              }
            })


          }).catch((error) => {
            // Handle error
            console.error('Error getting ID token:', error);
            this.toggleLoader()
            throw error;
          });
        } else {
          // Handle the case when no user is signed in
          console.error('No user signed in.');
          this.toggleLoader()
          return null;
        }


      })
    }
  }






  getDayInfo(day: DayWithMeals) {
    return dayMacroInfo(day)
  }

  calcTotalMealPlanMacros(mealplan: MealPlanWithDays) {
    return mealPlanMacroInfo(mealplan)
  }

}
