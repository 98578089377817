import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, first, switchMap, throwError, from, map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DayWithMeals, MealPlanWithDays, dayMacroInfo, manipulateNumbersDown, mealPlanMacroInfo } from '../../../../../backend/core/thecoach';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';
import { MealplanService } from 'src/app/services/mealplaner/mealplan.service';

@Component({
  selector: 'app-mealplan-dashboard-view',
  template: `
    <div *ngIf='$mealPlan | async as mealPlan'>
    <div class="overflow-hidden rounded-lg bg-white shadow">
  <div class="px-4 py-5 sm:p-6">
 <div class="flex flex-row justify-between items-center"><p class="font-bold">Mealplan: {{mealPlan.name || "-" }}</p>
<button type="button" [routerLink]="'/mealplan/' +mealPlan.id" class="inline-flex items-center gap-x-1.5 rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
</svg>

 Mealplan 
</button>
</div>
              <div class="text-sm text-gray-500">
                {{getMealPlanInfo(mealPlan)}}
              </div>
                                  <div *ngFor="let day of mealPlan.days" class="text-gray-500 italic text-xs flex flex-row">
            <p class="font-bold pr-4">{{day.name}} - {{getDayInfo(day)}}</p>
            <p>Meals: {{day.meals.length}}</p>
          </div>

    </div>
</div>
</div>


  `,
  styles: [
  ]
})
export class MealplanDashboardViewComponent implements OnInit {
  @Input() mealplanId!: string;

  $mealPlan: Observable<MealPlanWithDays | null> = of(null);

  ngOnInit(): void {
    this.findMealPlanById(this.mealplanId)
  }

  constructor(private http: HttpClient, private httpUser: HttpUserService, private mealPlanService: MealplanService) {


  }

  findMealPlanById(mealPlanId: string | undefined): Observable<MealPlanWithDays | null> {
    if (!mealPlanId) {
      return throwError('Meal plan ID is undefined');
    }

    this.$mealPlan = this.httpUser.currentUser$.pipe(
      first(),
      switchMap(user => {
        if (!user) {
          return throwError('User is not authenticated');
        }
        return from(user.getIdToken(true)).pipe(
          switchMap(idToken => {
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken });
            return this.http.get<MealPlanWithDays>(`${environment.apiUrl}/mealplans/${mealPlanId}`, { headers });
          }),
          map(mealplan => {
            if (mealplan) {
              mealplan.days.forEach(day => {
                day.meals.forEach(meal => {
                  meal.addedIngredients.forEach(addedIng => {
                    addedIng.ingredient = manipulateNumbersDown(addedIng.ingredient);
                  });
                });
              });
              const mealplansArr: MealPlanWithDays[] = [];
              mealplansArr.push(mealplan);
              this.mealPlanService.setMealPlans(mealplansArr);
              return mealplan;
            } else {
              return null;
            }
          }),
          catchError(error => {
            console.error('Error', error);
            return throwError('Failed to fetch meal plan');
          })
        );
      })
    );

    return this.$mealPlan;

  }

  getMealPlanInfo(mealplan: MealPlanWithDays | null) {
    if (mealplan) {
      return mealPlanMacroInfo(mealplan)
    } else {
      return ''
    }

  }

  getDayInfo(day: DayWithMeals) {
    return dayMacroInfo(day)
  }

}
