import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { StringNullableChain } from 'lodash';
import {
  CustomSlotModelInputType,
  isModelInputType,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-model-input-creator',
  template: `
    <div
      class="bg-white shadow-sm ring-1 p-4 mb-4 ring-gray-900/5 sm:rounded-xl md:col-span-2"
    >
      <div class="px-4 sm:px-0 flex flex-row">
        <div class="flex w-full flex-col">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Properties
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600 ">
            Add custom properties.<br />
            Will be displayed from left to right on client side
          </p>
        </div>
        <div class="flex w-full justify-between items-center">
          <button
            type="button"
            (click)="toggleProperty()"
            class="inline-flex h-fit w-fit items-center gap-x-1.5 rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                clip-rule="evenodd"
              />
            </svg>
            Add Property
          </button>
        </div>
      </div>

      <div class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-semibold leading-6 text-gray-900">
              Number Input
            </dt>
            <dd
              class="mt-1 text-sm italic leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              Defines a user number input. Example: Sets, Reps...
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-semibold leading-6 text-gray-900">
              Target Specification
            </dt>
            <dd
              class="mt-1 text-sm italic leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              Defines a specification provided by the Coach. Example: RiR/RPE
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-semibold leading-6 text-gray-900">
              Time Input
            </dt>
            <dd
              class="mt-1 text-sm italic leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              Defines a user time input. Example: mm:ss - Minutes:Seconds for a
              Run
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div *ngIf="propertyFormToggler" class="flex flex-row py-4">
      <form
        [formGroup]="propertyInputForm"
        (ngSubmit)="onAddProperty()"
        class="w-full flex flex-row justify-center"
      >
        <div class="w-full flex flex-col justify-center items-center">
          <div class="w-full flex flex-row justify-center mb-2 items-center">
            <input
              id="name"
              type="text"
              formControlName="name"
              placeholder="Name"
              class="border border-gray-300 h-fit mx-2 focus:ring-teal-500 focus:border-teal-500  rounded-md w-5/12 "
            />

            <div class="flex justify-center flex-row items-center">
              <label
                for="type"
                class="block text-sm font-semibold leading-6 text-gray-900 pr-2"
                >Type</label
              >
              <select
                formControlName="type"
                id="type"
                name="type"
                class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-teal-600 sm:text-sm sm:leading-6"
              >
                <option
                  [ngValue]="enum.value"
                  *ngFor="let enum of customSlotModelInputType | keyvalue"
                >
                  {{ enum.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex flex-row w-full items-center">
            <ng-container *ngIf="isInputType()">
              <div
                class="relative flex items-start border-[0.8px] border-gray-300 rounded-xl p-2 mr-2"
              >
                <div class="flex h-6 items-center">
                  <input
                    formControlName="includeCalc"
                    id="includeCalc"
                    name="includeCalc"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                  />
                </div>
                <div class="ml-3 text-sm leading-6">
                  <label
                    for="includeCalc"
                    class="font-semibold text-md text-gray-900"
                    >Include Calculation</label
                  >
                  <p id="includeCalc-description" class="text-gray-500 text-xs">
                    Should this Input be considered for progress Calculation?
                  </p>
                </div>
              </div>
            </ng-container>
            <textarea
              formControlName="description"
              rows="2"
              placeholder="Description..."
              class="border border-gray-300 flex-shrink focus:ring-teal-500 focus:border-teal-500 px-4 py-1 rounded-md w-full "
            ></textarea>
            <div class="flex flex-col ml-3 justify-center items-center">
              <button
                [disabled]="!propertyInputForm.valid"
                type="submit"
                class="bg-teal-500  flex flex-row h-fit w-fit disabled:opacity-50 pr-2 text-white py-1 rounded-md  items-center text-center"
              >
                <ng-container
                  *ngIf="
                    checkIfValExists(propertyInputForm.get('slot')?.value);
                    else newProperty
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 m-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <span>SAVE</span>
                </ng-container>
                <ng-template #newProperty>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 m-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <span>ADD</span>
                </ng-template>
              </button>
              <button
                (click)="onCancelEdit()"
                type="button"
                class="rounded-md mt-2 bg-yellow-600 w-fit px-1.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class ModelInputCreatorComponent implements OnInit {
  @Output() customInput = new EventEmitter<FormGroup>();
  @Output() updateInput = new EventEmitter<FormGroup>();

  propertyFormToggler = false;
  customSlotModelInputType = CustomSlotModelInputType;
  selectedSlotType: string = 'choose type...';
  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {}

  customSlotModelInputForm = this.fb.group({
    csmiv: this.fb.array([]),
  });

  propertyInputForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    type: ['', Validators.required],
    slot: [],
    includeCalc: [false],
  });

  get csmiv() {
    return this.customSlotModelInputForm.get('csmiv') as FormArray;
  }
  toggleProperty() {
    this.propertyFormToggler = !this.propertyFormToggler;
  }

  patchForm(form: FormGroup) {
    if (form.get('slot')?.value === this.propertyInputForm.get('slot')?.value) {
      this.toggleProperty();
    } else {
      this.propertyFormToggler = false;
      this.propertyInputForm.patchValue({
        name: form.get('name')?.value,
        description: form.get('description')?.value,
        type: form.get('type')?.value,
        slot: form.get('slot')?.value,
        includeCalc: form.get('includeCalc')?.value,
      });

      this.toggleProperty();
    }
  }

  onAddProperty() {
    if (this.checkIfValExists(this.propertyInputForm.get('slot')?.value)) {
      this.updateInput.emit(_.cloneDeep(this.propertyInputForm));
    } else {
      this.customInput.emit(this.propertyInputForm);
    }

    this.propertyInputForm.reset();
    this.propertyInputForm.patchValue({ includeCalc: false });
    this.toggleProperty();
  }

  checkIfValExists(val: string | number | null | undefined) {
    return typeof val === 'number' && !Number.isNaN(val);
  }

  isInputType() {
    return isModelInputType(
      this.propertyInputForm.get('type')?.value as CustomSlotModelInputType,
    );
  }

  onCancelEdit() {
    this.propertyInputForm.reset();
    this.propertyInputForm.patchValue({ includeCalc: false });
    this.toggleProperty();
  }
}
