import { Component, Input, OnInit } from '@angular/core';
import {
  WeightEntryData,
  AppUser,
  Macros,
  calculateKcalFromMacros,
  calcuclateDailydifferenceMaster,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateSevenDayAverageMaster,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
  calculateRoglDailyAbsoluteMaster,
  calculateRoglWeeklyPercentageMaster,
} from 'src/app/core/thecoach';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';

@Component({
  selector: '[app-statistics-wlog-tablebody]',
  template: `
    <tr
      class="divide-x divide-gray-200"
      *ngFor="let weighEntry of weightLogData; let j = index"
      [ngClass]="{
        'bg-gray-200': j % 7 === 0,
      }"
    >
      <td
        class="  text-center py-0.5  pr-1 text-xs font-medium text-gray-900 sm:pl-0"
      >
        {{ weighEntry.date | date: 'dd.MM' }}
      </td>
      <td
        class="  text-center py-0.5 px-1 text-xs text-gray-500 flex flex-row justify-center"
      >
        {{
          weighEntry.weight !== undefined
            ? (weighEntry.weight | number: '1.1-1')
            : '-'
        }}

        <div
          class="group flex relative items-center pl-0.5"
          *ngIf="weighEntry.comment"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 stroke-yellow-600"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <span
            class="pointer-events-none z-20  group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-xs text-gray-100 rounded-md absolute left-1/2
    -translate-x-[20%] translate-y-2 opacity-0 m-4 mx-auto"
            >{{ weighEntry.comment }}</span
          >
        </div>
      </td>

      <td class="  text-center py-0.5  text-xs text-gray-500">
        {{ calculateSevenDayAverage(j, client) }}
      </td>
      <td class=" text-center py-0.5  text-xs text-gray-500">
        {{ calculateRoglDailyAbsolute(weighEntry.weight!, j, client) }}
      </td>
      <td class=" text-center py-0.5  text-xs text-gray-500">
        {{ calculateRoglDaily(weighEntry.weight!, j, client) }}
      </td>
      <td class="  text-center py-0.5  text-xs text-gray-500">
        {{ calculateRoglWeekly(j, client) }}
      </td>
      <td class="  text-center py-0.5  text-xs text-gray-500">
        {{ calculateRoglWeeklyPercentage(j, client) }}
      </td>
      <td
        *ngIf="client.enableTraining"
        class=" text-center min-w-[3rem] py-0.5 px-0.5  text-xs text-gray-500"
      >
        {{ weighEntry.sessionName }}
      </td>
    </tr>
  `,
  styles: [],
})
export class StatisticsWlogTablebodyComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
  @Input() weightLogData: WeightEntryData[] | null = [];
  @Input() client!: AppUser;

  calculateSevenDayAverage(index: number, client: AppUser): string {
    return calculateSevenDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglDaily(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglWeekly(index: number, client: AppUser): string {
    return calculateRoglWeeklyMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
  calculateRoglDailyAbsolute(
    weight: number,
    index: number,
    client: AppUser,
  ): string {
    return calculateRoglDailyAbsoluteMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
  calculateRoglWeeklyPercentage(index: number, client: AppUser): string {
    return calculateRoglWeeklyPercentageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
}
