import { Component, OnInit } from '@angular/core';
import { Subscription, first } from 'rxjs';
import { MealplanService } from 'src/app/services/mealplaner/mealplan.service';
import { DayWithMeals, MealPlanWithDays, MealWithIngredients, dayMacroInfo, mealMacroInfo } from '../../../../../backend/core/thecoach';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { formatUrlsWithLinks } from 'src/app/core/thecoach';


@Component({
  selector: 'app-mealplan-detail-view',
  template: `
  <button type="button" (click)="onBackClick()" class="items-center flex flex-row rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-1  w-4 h-4 rotate-180">
  <path stroke-linecap="round" stroke-linejoin="round"  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
</svg>

  Back</button>

  <div *ngIf="mealPlan">
  <div class="overflow-hidden rounded-lg bg-white shadow">

  <div class="px-4 py-5 sm:p-6">
    <!-- Content goes here --><div class="flex flex-row justify-between items-center">
    <p class="text-md font-bold">Mealplan: {{mealPlan.name}}</p>

    <p class="text-xs italic font-semibold text-gray-500">Days: {{mealPlan.days.length}}</p></div>
<div class="overflow-hidden rounded-lg bg-white shadow" *ngFor="let day of mealPlan.days">
  <div class="px-4 py-5 sm:p-6">
    <div class="flex flex-row  justify-between items-center">
      <div class="flex flex-col justify-start">
      <p class="text-md font-bold">{{day.name}}</p>
      <p class="text-xs italic font-semibold text-gray-500">{{getDayInfo(day)}}</p>
</div>
      <p class="text-xs italic font-semibold text-gray-500">Meals: {{day.meals.length}}</p>
    </div>

<div class="px-2 pb-2 mt-4 bg-gray-100 rounded-md" *ngFor="let meal of day.meals">
<div>
<p class="text-md font-semibold">{{meal.name}}</p>
<p class="text-xs italic font-medium text-gray-500">{{getMealMacros(meal)}}</p>
    <div *ngIf="meal.mealDescription">
                  <hr class="pt-2">
 <div class="break-normal text-xs italic text-gray-700 whitespace-pre-wrap" [innerHTML]="frmtUrlsWithLinks(meal.mealDescription)"></div>
                <hr>
                </div>

              </div>
    <ul class="pt-4">

      <li *ngFor="let addedIngredient of meal.addedIngredients" class="text-xs flex flex-row justify-between">
      <p>{{addedIngredient.ingredient.name}}</p>
      <p class="font-semibold">{{addedIngredient.grams}}g</p>
      </li>
    </ul>


</div>
</div>
</div>

  </div>
</div>
  </div>
  `,
  styles: [
  ]
})
export class MealplanDetailViewComponent implements OnInit {

  mealPlanSubscription: Subscription | undefined = undefined;
  mealPlan: MealPlanWithDays | undefined = undefined;

  constructor(private mealplanService: MealplanService, private route: ActivatedRoute, private location: Location) { }

  onBackClick() {
    this.location.back();
  }

  ngOnInit(): void {

    const params = this.route.snapshot.params;

    const id = params['id'];

    if (id) {
      this.mealPlanSubscription = this.mealplanService.mealPlans$.pipe(first()).subscribe(mealPlans => {
        this.mealPlan = mealPlans.find(mp => mp.id === id);
      });
    }
  }

  getDayInfo(day: DayWithMeals) {
    return dayMacroInfo(day)
  }

  getMealMacros(meal: MealWithIngredients) {
    return mealMacroInfo(meal);
  }

  frmtUrlsWithLinks(text: string | undefined): string {
    return formatUrlsWithLinks(text);
  }
}

