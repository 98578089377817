import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { InviteService } from 'src/app/services/inviteSystem/invite.service';
import { Invite } from '../../../../../../backend/core/thecoach';
import { add } from 'date-fns';
import { AppUser, UserRole } from 'src/app/core/thecoach';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-client-registration-panel',
  template: `
    <div class="text-center">
      <h1 class="text-xl font-bold uppercase">REGISTRATION LINK</h1>
      <hr class="pb-2" />
      <div
        class="mt-2 flex items-center"
        [ngClass]="{
          'justify-between': user.role === userRole.Admin,
          'justify-center': user.role !== userRole.Admin,
        }"
      >
        <button
          type="button"
          (click)="createRegistrationLink()"
          class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Create Client Invite
        </button>
        <button
          *ngIf="user.role === userRole.Admin"
          type="button"
          (click)="createRegistrationLink(true)"
          class="cursor-pointer rounded-md bg-white ml-4 py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Create Coach Invite
        </button>
      </div>
      <p class="mt-2 text-sm text-gray-500" id="regLink">
        Prvoide this Link to your Client for Registration.
      </p>
      <div *ngIf="lastCreatedLink !== ''" class="pt-2">
        <a [href]="lastCreatedLink" target="_blank">
          <button
            type="button"
            class="rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Last created link
          </button></a
        >
      </div>
    </div>
    <app-notification
      *ngIf="toggleNotification"
      [messageHeader]="notificationHeaderStr"
      [messageBody]="notificationBodyStr"
      (onbuttonClick)="onToggleNotification()"
    >
    </app-notification>
    <app-simple-loading-modal
      *ngIf="toggleCreateNewInvite"
      [title]="'New Customer Invite'"
      [message]="'Creating new invite for customer...'"
    >
    </app-simple-loading-modal>
    <app-simple-loading-modal
      *ngIf="toggleCreateNewCoachInvite"
      [title]="'New Coach Invite'"
      [message]="'Creating new invite for Coach...'"
    >
    </app-simple-loading-modal>
  `,
  styles: [],
})
export class ClientRegistrationPanelComponent {
  @Input({ required: true }) user!: AppUser;

  toggleNotification: boolean = false;
  notificationHeaderStr: string = '';
  notificationBodyStr: string = '';

  lastCreatedLink: string = '';

  toggleCreateNewInvite: boolean = false;
  toggleCreateNewCoachInvite: boolean = false;
  userRole = UserRole;

  constructor(
    private db: AngularFirestore,
    private inviteService: InviteService,
    private clipboardService: ClipboardService,
  ) {}
  createRegistrationLink(isCoach?: boolean) {
    this.toggleCreateNewInviteLoadingModal();
    const invite: Invite = {
      id: this.db.createId(),
      creatorId: this.user.id as string,
      createdAt: new Date(),
      expiresAt: add(new Date(), { hours: 48 }),
      isCoach: isCoach,
    };

    //console.log(invite);

    this.inviteService.saveInvite(invite).subscribe({
      next: (result: { token: string }) => {
        if (result) {
          const regLink =
            window.location.protocol +
            '//' +
            window.location.host +
            '/register/' +
            result.token;
          this.toggleCreateNewInviteLoadingModal();
          this.onCopyToClipboard(regLink);
        } else {
          this.toggleCreateNewInviteLoadingModal();
          this.inviteErrorNotification();
          console.error('Error: No result received');
        }
      },
      error: (error: Error) => {
        this.toggleCreateNewInviteLoadingModal();
        this.inviteErrorNotification();
        console.error('Error in saving invite:', error);
      },
    });
  }

  toggleCreateNewInviteLoadingModal() {
    this.toggleCreateNewInvite = !this.toggleCreateNewInvite;
  }

  inviteErrorNotification() {
    this.toggleNotification = !this.toggleNotification;
    this.notificationHeaderStr = 'ERROR';
    this.notificationBodyStr = 'There has been an error creating the invite...';
  }
  onToggleNotification() {
    this.toggleNotification = !this.toggleNotification;
  }
  onCopyToClipboard(value: string) {
    this.toggleNotification = !this.toggleNotification;
    this.notificationHeaderStr = 'Clipboard';
    this.notificationBodyStr = 'Link has been copied to clipboard!';
    this.lastCreatedLink = value;
    this.clipboardService.copyFromContent(value);
  }
}
