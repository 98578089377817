import { NgForm } from '@angular/forms';
import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AppUser,
  Macros,
  calculateKcalFromMacros,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-set-macros-modal',
  template: `
    <div class="flex justify-center flex-col pt-6 items-center">
      <form
        #macrosForm="ngForm"
        (ngSubmit)="onSubmit(macrosForm)"
        class="flex flex-col w-full h-full justify-center items-center"
      >
        <div class="w-5/6 flex justify-center flex-col items-center">
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
          >
            <!-- Heroicon name: outline/check -->
            <img src="../../assets/icons/maintain-weight-icon.png" />
          </div>
          <h3
            class="text-base my-2 font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Set new Macros
          </h3>

          <div class="flex flex-row justify-evenly w-full mx-2">
            <div class="flex flex-col justify-center items-center">
              <span>Protein</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #protein
                  name="protein"
                  [(ngModel)]="macros.protein"
                  id="protein"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="protein-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-center items-center">
              <span>Carbs</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #carbs
                  name="carbs"
                  [(ngModel)]="macros.carbs"
                  id="carbs"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="carbs-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-center items-center">
              <span>Fat</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #fat
                  name="fat"
                  [(ngModel)]="macros.fat"
                  id="fat"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="fat-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-center items-center ml-2 ">
              <span>Kcal</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #fat
                  name="fat"
                  [value]="
                    calculateKcal({
                      protein: macros.protein,
                      fat: macros.fat,
                      carbs: macros.carbs
                    })
                  "
                  id="kcal"
                  disabled
                  class="block w-full  rounded-md border-gray-300 shadow-sm bg-gray-200 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="fat-input"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-around w-5/6 mt-2 pb-4">
          <button
            id="btn-save"
            type="submit"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Save
          </button>

          <button
            (click)="onClickEvent(false)"
            type="button"
            id="btn-cancel"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class SetMacrosModalComponent implements OnInit {
  @Input() client?: AppUser;
  @Output() onClick = new EventEmitter<boolean>();

  macros: Macros = {};

  ngOnInit(): void {
    if (this.client && this.client.setMacros) {
      this.macros = Object.assign({}, this.client.setMacros);
    }
  }

  onClickEvent(value: boolean) {
    this.onClick.emit(value);
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  onSubmit(macrosForm: NgForm) {
    if (this.client) {
      this.client.setMacros = macrosForm.value;
      this.onClick.emit(true);
    }
  }
}
