import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { intervalToDuration } from 'date-fns';
import { Subject } from 'rxjs';
import { AudioRecordingService } from 'src/app/services/audio-recording.service';

const MicRecorder = require('mic-recorder-to-mp3');

@Component({
  selector: 'app-voice-recorder',
  template: `
    <div
      class="flex flex-col w-full justify-end  p-2 rounded-lg"
      [ngClass]="{
        'bg-gray-200 ring-2 ring-gray-700':
          !isAudioRecording && audioBlobUrl && recorderAsAttachement
      }"
    >
      <div class="flex flex-row items-center">
        <app-voice-player
          *ngIf="!isAudioRecording && audioBlobUrl"
          [audioBlobUrl]="audioBlobUrl"
        ></app-voice-player>
        <button
          *ngIf="!isAudioRecording && audioBlobUrl && recorderAsAttachement && isCoach && checkFileSize(audioBlob)"
          (click)="sendAudioRecordData()"
          class="-rotate-90 rounded-full bg-white p-2 hover:bg-gray-500 ml-1 cursor-pointer hover:stroke-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 stroke-teal-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
          <div
            *ngIf="uploadAnimation"
            class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 "
          >
            <div
              class="border-t-transparent border-solid animate-spin  rounded-full border-teal-400 border-4 h-10 w-10"
            ></div>
          </div>
        </button>

        <div
          id="audioTimer"
          *ngIf="isAudioRecording && !audioBlobUrl"
          class="rounded-full bg-[#F1F3F4] p-2 pr-4 pl-4 hover:bg-[#E5E7E8]"
        >
          {{ audioRecordedTime }}
        </div>
        <button
          *ngIf="!isAudioRecording && !audioBlobUrl"
          (click)="startMp3Recorder()"
          class="rounded-full bg-[#F1F3F4] p-2 hover:bg-[#E5E7E8]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
        </button>
        <button
          *ngIf="isAudioRecording && !audioBlobUrl"
          (click)="stopMp3Recorder()"
          class="rounded-full bg-[#F1F3F4] p-2 hover:bg-[#E5E7E8] ml-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z"
            />
          </svg>
        </button>
        <!--
        <button
          class="rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          *ngIf="!isAudioRecording && audioBlobUrl"
          (click)="downloadAudioRecordedData()"
        >
          Download Audio Recording
        </button>-->
      </div>
      <div
        class="w-full justify-between flex mt-2 items-center"
        *ngIf="!isAudioRecording && audioBlobUrl && recorderAsAttachement"
      >
        <button
          class="rounded-full bg-[#F1F3F4] hover:bg-[#E5E7E8] mr-1"
          *ngIf="!isAudioRecording && audioBlobUrl"
          (click)="clearMp3data()"
          [ngClass]="{ 'p-2': !recorderAsAttachement }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 stroke-red-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </button>
        <p class="italic font-semibold text-xs text-gray-400">
          This is a draft!
        </p>
        <button
          *ngIf="!isAudioRecording && audioBlobUrl && recorderAsAttachement && !isCoach && checkFileSize(audioBlob)"
          (click)="sendAudioRecordData()"
          class="rounded-full bg-teal-600 text-white p-2 font-semibold hover:bg-teal-400 ml-1 w-fit"
        >
          Add to Checkin

          <div
            *ngIf="uploadAnimation"
            class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 "
          >
            <div
              class="border-t-transparent border-solid animate-spin  rounded-full border-teal-400 border-4 h-10 w-10"
            ></div>
          </div>
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class VoiceRecorderComponent {
  isPlaying = false;
  displayControls = true;
  isAudioRecording = false;
  isVideoRecording = false;
  audioRecordedTime: string = '00:00';
  audioBlobUrl: SafeUrl | null | undefined;
  audioBlob: Blob | undefined;
  audioName: string | undefined;

  @Output() blob = new EventEmitter<{
    blob: Blob;
    name: string;
    url?: SafeUrl;
  }>();
  @Output() clearRecord = new EventEmitter<boolean>();
  @Output() recordStart = new EventEmitter<boolean>();
  @Input() uploadAnimation!: boolean;
  @Input() recorderAsAttachement: boolean = false;

  @Input() isCoach!: boolean;
  recorder = new MicRecorder({
    bitRate: 36,
  });

  private interval: string | number | NodeJS.Timer | undefined;
  private _recordingTime = new Subject<string>();
  private startTime: Date | undefined;

  constructor(
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) { }


  checkFileSize(blob:Blob | undefined){
    if(blob && blob.size > 4000){
      return true
    }else{return false}
  }

  /*

  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }


  stopAudioRecording() {
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.isAudioRecording = false;
    }
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = null;
  }

  downloadAudioRecordedData() {
    // this._downloadFile(this.audioBlob, 'audio/ogg', this.audioName as string);
  }

  ngOnDestroy(): void {
    this.abortAudioRecording();
  }

  _downloadFile(data: any, type: string, filename: string): any {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    //this.video.srcObject = stream;
    //const url = data;
    console.log(url);
    //   const anchor = document.createElement('a');
    //   anchor.download = filename;
    //   anchor.href = url;
    //  document.body.appendChild(anchor);
    //  anchor.click();
    // document.body.removeChild(anchor);
  }



  async convertBlobToMP3(inputBlob: Blob): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const buffer = reader.result as ArrayBuffer;
        const mp3Blob = this.startEncoding(buffer);
        resolve(mp3Blob as unknown as Blob);
      };

      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(inputBlob);
    });
  }

  // Usage example
  async convert() {
    const convAudioBlob: Blob = this.audioBlob as Blob; // Your WAV audio blob
    try {
      const mp3Blob = await this.convertBlobToMP3(convAudioBlob).then(
        (blob: Blob) => {
          this.blob.emit({
            blob: this.audioBlob as Blob,
            name: this.audioName as string,
          });
          // console.log(mp3Blob);
          // this._downloadFile(mp3Blob, 'audio/mp3', this.audioName as string);
        }
      );

      // Now you can use `mp3Blob` as needed, such as downloading or playing
    } catch (error) {
      console.error('Conversion error:', error);
    }
  }

  startEncoding(audiodata: ArrayBuffer) {
    let wav = lamejs.WavHeader.readHeader(new DataView(audiodata));
    let samples = new Int16Array(audiodata, wav.dataOffset, wav.dataLen / 2);
    this.encodeMono(samples);
  }

  encodeMono(samples: any) {
    var buffer = [];
    var mp3enc = new lamejs.Mp3Encoder(1, 48000, 128);
    var remaining = samples.length;
    var maxSamples = 1152;
    for (var i = 0; remaining >= maxSamples; i += maxSamples) {
      var mono = samples.subarray(i, i + maxSamples);
      var mp3buf = mp3enc.encodeBuffer(mono);
      if (mp3buf.length > 0) {
        buffer.push(new Int8Array(mp3buf));
      }
      remaining -= maxSamples;
    }
    var d = mp3enc.flush();
    if (d.length > 0) {
      buffer.push(new Int8Array(d));
    }

    let convblob = new Blob(buffer, { type: 'audio/mp3' });
    this.audioBlob = convblob;

    //console.log('Blob created, URL:', bUrl);
    // window.myAudioPlayer = document.createElement('audio');
    // window.myAudioPlayer.src = bUrl;
    // window.myAudioPlayer.setAttribute('controls', '');
    // window.myAudioPlayer.play();
  }
  */

  sendAudioRecordData() {
    if (this.audioBlob && this.audioName)
      this.blob.emit({
        blob: this.audioBlob,
        name: this.audioName,

        url: this.audioBlobUrl as SafeUrl,
      });
    // this._downloadFile(this.audioBlob, 'audio/ogg', this.audioName as string);
  }

  clearMp3data() {
    this.audioBlobUrl = null;
    this.clearRecord.emit(true);
  }

  startMp3Recorder() {
    if (!this.isAudioRecording) {
      this.recordStart.emit(true);
      this.isAudioRecording = true;
      this.recorder
        .start()
        .then(() => {
          // something else
          this.startTime = new Date();
          this.interval = setInterval(() => {
            const currentTime = new Date();
            const diffTime = intervalToDuration({
              start: this.startTime as Date,
              end: currentTime,
            });
            const time =
              this.toString(diffTime.minutes as number) +
              ':' +
              this.toString(diffTime.seconds as number);
            this.audioRecordedTime = time;
          });
        })
        .catch((e: Error) => {
          console.error(e);
        });
    }
  }

  stopMp3Recorder() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]: [BlobPart[], Blob]) => {
          const file = new File(
            buffer,
            encodeURIComponent('audio_' + new Date().getTime() + '.mp3'),
            {
              type: blob.type,
              lastModified: Date.now(),
            }
          );
          this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(file)
          );
          this.audioBlob = blob;
          this.audioName = file.name;
//          console.log(blob)
          this.audioRecordedTime = '00:00';
          clearInterval(this.interval as number);
          this.ref.detectChanges();
        })
        .catch((e: Error) => {
          alert('We could not retrieve your message');
          console.log(e);
        });
    }
  }

  private toString(value: any) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }
}
