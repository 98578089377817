import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Ingredient, Unit } from '../../../../../backend/node_modules/@prisma/client'
import { Macros, calculateKcalFromMacros } from 'src/app/core/thecoach';
import * as firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { manipulateNumbersDown } from '../../../../../backend/core/thecoach';
import { Subject, debounceTime, first } from 'rxjs';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-meal-creator',
  template: `
   <div class="mx-auto max-w-7xl min-h-[39rem] sm:px-6 lg:px-8 pt-2 bg-white shadow sm:rounded-lg">
  <!-- Content goes here -->
  <form [formGroup]="mealForm">
<div class="flex w-full flex-row justify-between items-center">

         <div class="flex justify-around w-full">
<div class="flex w-4/12 h-fit flex-row justify-start">
  <label for="name" class="sr-only">Name</label>
  <input type="text" name="name" id="name" formControlName="name" class="block w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6" placeholder="Enter Meal Name">
      </div>
         <div class="w-7/12">
  <textarea rows="1" name="mealDescription" id="mealDescription"
               formControlName="mealDescription"
               class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                placeholder="Enter description..."></textarea>
</div>

<div class="relative inline-block text-left">
  <div>
    <button type="button" (click)="toggleSubMenu()" class="flex items-center rounded-md border-2 border-black hover:border-gray-600 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-gray-100" id="menu-button" aria-expanded="true" aria-haspopup="true">
      <span class="sr-only">Open options</span>
      <svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
      </svg>
    </button>
  </div>
</div>
  <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
  <div @opacityScale *ngIf='isSubMenu' class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="py-1" role="none">
      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
      <div (click)="onCreateNewIngredient()" class="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Create New Ingredient</div>
      <div *ngIf="!modal" (click)="onDelete()" class="cursor-pointer text-red-700 hover:bg-red-100 hover:text-red-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Delete</div>

    </div>
  </div>
</div>
<div *ngIf='isSubMenu' (click)="toggleSubMenu()" class="fixed inset-0"></div>

  </div>


<div class="mt-2 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

    <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="whitespace-nowrap  py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Ingredient Name</th>
              <th scope="col" class="whitespace-nowrap w-[2rem] px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Protein</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Carbs</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Fats</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Kcal</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Merchant</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Price</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left ">
                <svg (click)='onAddIngredient()' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 fill-teal-500 cursor-pointer">
  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clip-rule="evenodd" />
</svg>

              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white" formArrayName='addedIngredients'>
            <tr *ngFor='let addedingredient of addedIngredients.controls; let i = index' [formGroupName]="i">
              <td class="whitespace-nowrap pt-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0" formGroupName='ingredient'>
               <div class="flex flex-col">
                 <div class="flex rounded-md shadow-sm">
               <input type="search" (input)="onInputSearch(addedingredient,i)" autocomplete="off" name="name" id="name" formControlName="name" class="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" placeholder="Enter Ingredient Name">

           <button (click)="onSearchIngFromDB(addedingredient,i)" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">

       <div *ngIf="searchToggler && currenSearchIndex === i; else textView " class="flex flex-row items-center">
                <object type="image/svg+xml" data="../../assets/icons/ring-resize.svg" class="w-5 h-5"></object>
            </div>
            <ng-template #textView>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
</svg>
            </ng-template>


    </button>
  </div>
     <div class="flex flex-row" *ngIf="checkForIngredientId(addedingredient)">
        <p class="pl-4 text-xs italic text-gray-400">
           {{getDetailMacroInfo(addedingredient)}}
</p>
     </div>
    <div class="absolute z-10 w-3/5 md:w-2/5 lg:w-[25rem]">
      <ul *ngIf="ingList.length > 0 && currenSearchIndex === i" class="absolute top-[2.5rem] text-start z-20 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <li *ngFor="let ing of ingList;let j = index" (click)="setIngredient(ing, addedingredient)" class="hover:text-white hover:cursor-pointer hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
      {{ing.name}}
      </li>

      </ul>
    </div>




  </div>
              </td>
              <td class="whitespace-nowrap px-2 pb-2 text-sm font-medium text-gray-900">

                           <input type="number" name="grams" id="grams" formControlName="grams"  class="text-right block w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6 disabled:opacity-25 disabled:bg-gray-400 disabled:mt-2 " placeholder="0">
              </td>

              <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">{{calculateParameter('protein', addedingredient)}}</td>
              <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">{{calculateParameter('carb', addedingredient)}}</td>
              <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">{{calculateParameter('fat', addedingredient)}}</td>
              <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">{{calculateAmountKcal(addedingredient)}}</td>
              <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">{{addedingredient.get('ingredient.merchant')?.value}}</td>
                        <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">{{calculatePrice(addedingredient)}}</td>
                         <td class="whitespace-nowrap px-2 pb-2 text-sm text-gray-500">
  <svg xmlns="http://www.w3.org/2000/svg" (click)="onDeleteIngredient(i)" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 stroke-red-500 hover:cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>


                         </td>

            </tr>

            <!-- More transactions... -->
          </tbody>
          <tfoot *ngIf="hasAmount()">
            <tr>
              <th scope="col" class="whitespace-nowrap  py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0 text-right">Totals</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900">{{calculateTotalGrams()}}g</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{calculcateTotalParameters('protein')}}</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{calculcateTotalParameters('carb')}}</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{calculcateTotalParameters('fat')}}</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{calculateTotalKcal()}}</th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
              <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{calculateTotalPrice()}}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="flex w-full justify-start pb-2" *ngIf="hasAmount()">
      <div class="relative flex items-center ml-2">
      <div class="flex h-6 items-center">
        <input id="saveToDB" formControlName="saveToDB" aria-describedby="comments-description" name="saveToDB" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600">
      </div>
      <div class="ml-1 text-sm leading-6">
        <label for="comments" class="font-md text-gray-900">Save as Template</label>
      </div>
    </div>
    </div>
</form>
  </div>


  <div *ngIf="newIngredientToggler" class='z-50'>
    <app-new-ingredient-modal></app-new-ingredient-modal>
  </div>
  `,
  styles: [
  ],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class MealCreatorComponent implements OnInit {

  @Input() modal?:boolean;
  @Input() mealForm!: FormGroup;
  @Output() delete = new EventEmitter();

  isSubMenu = false;

  ingList: Ingredient[] = [];
  currenSearchIndex: number | undefined = undefined;
  newIngredientToggler = false;
  searchToggler = false;
  private searchSubject = new Subject<{ ing: AbstractControl, index: number }>();
  private readonly debounceTimeMs = 600; // Set the debounce time (in milliseconds)

  run = 0;



  get addedIngredients() {
    return this.mealForm.get('addedIngredients') as FormArray;
  }
  constructor(private fb: FormBuilder, private http: HttpClient, private httpUser: HttpUserService) { }
  ngOnInit(): void {
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchObj) => {
        this.performSearch(searchObj);
      });

    this.modal = this.modal || false;

  }

  toggleSubMenu() {
    this.isSubMenu = !this.isSubMenu;
  }

  setIngredient(ing: Ingredient, addedIngredient: AbstractControl) {
    (addedIngredient as FormGroup).get('ingredient')?.patchValue(ing);
    (addedIngredient as FormGroup).get('ingredientId')?.patchValue(ing.id)
    addedIngredient.get('grams')?.enable();
    this.ingList = [];

  }

  searchIngFromDB(addedIngredient: AbstractControl, index: number) {
    this.ingList = []
    const searchTerm = addedIngredient.get('ingredient.name')?.value as string;
    this.currenSearchIndex = index;
    if (searchTerm) {
      return this.httpUser.currentUser$.pipe(first()).subscribe(user => {

        if (user) {
          return user.getIdToken(true).then((idToken) => {
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })
            this.http.get<Ingredient[]>(environment.apiUrl + '/ingredients/filtered-ingredients/' + searchTerm, { headers }).subscribe(data => {
              if (data) {
                data.map(data => manipulateNumbersDown(data))
                this.ingList = data;
//                console.log(this.ingList);
              } else { this.ingList = []; }
              this.toggleSearch()
            }, error => {
              this.toggleSearch();
              console.log('Error: ', error);
            })
          }).catch((error) => {
            this.toggleSearch();
            console.error('Error', error)
          })
        } else {
          this.toggleSearch();
          console.error('No User signed in.')
          return null
        }
      })
    } else {
      this.toggleSearch();
      return null
    }


  }


  onAddIngredient() {
    this.addedIngredients.push(this.fb.group({

      id: [''],
      grams: [{ value: 0, disabled: true }, Validators.required],
      ingredientId: [''],
      ingredient: this.fb.group({
        id: [''],
        name: ['', [Validators.required]],
        merchant: [''],
        packageSize: [0],
        packageUnitSize: ['g'],
        packageCost: [0],
        protein: [0, Validators.required],
        fat: [0, Validators.required],
        saturatedFat: [0],
        carb: [0, Validators.required],
        sugar: [0],
        fibre: [0],


      })


    }))

  }


  calculateTotalGrams() {
    let totalGrams = 0
    this.addedIngredients.controls.forEach((control: AbstractControl) => {
      const gramsControl = control.get('grams')
      if (gramsControl) {
        totalGrams += gramsControl.value || 0
      }
    })

    return totalGrams
  }

  calculcateTotalParameters(parameter: string) {
    let total = 0;
    this.addedIngredients.controls.forEach((control: AbstractControl) => {
      const ingControl = control.get('ingredient') as FormGroup


      if (ingControl) {
        const grams = control.get('grams')?.value
        const parameterControl = ingControl.get(parameter)

        if (parameterControl) {
          total += (parameterControl.value * (grams / 100)) || 0
        }
      }
    })

    return total.toFixed(2);
  }


  calculateParameter(parameter: string, addedIngredient: AbstractControl) {
    let value = 0
    const grams = addedIngredient.get('grams')?.value
    const ingControl = addedIngredient.get('ingredient') as FormGroup

    if (grams && ingControl) {
      const parameterControl = ingControl.get(parameter)
      if (parameterControl) {
        value = (parameterControl.value * (grams / 100)) || 0
      }

    }


    return value.toFixed(2);
  }

  calculateTotalKcal() {
    let totalKcal = 0
    this.addedIngredients.controls.forEach((control: AbstractControl) => {
      const ingControl = control.get('ingredient') as FormGroup
      const grams = control.get('grams')?.value

      if (ingControl) {
        const macros: Macros = {
          protein: ingControl.get('protein')?.value,
          carbs: ingControl.get('carb')?.value,
          fat: ingControl.get('fat')?.value


        }

        if (macros) {
          totalKcal += Number(calculateKcalFromMacros(macros)) * (grams / 100) || 0
        }
      }
    })


    return totalKcal.toFixed(0)

  }

  calculateTotalPrice() {
    let totalPrice = 0

    this.addedIngredients.controls.forEach((control: AbstractControl) => {


      totalPrice += Number(this.calculatePrice(control)) || 0
    })

    return totalPrice.toFixed(2)
  }

  calculateKcal(addedIngredient: AbstractControl) {

    const macros: Macros = {
      protein: addedIngredient.get('ingredient.protein')?.value,
      carbs: addedIngredient.get('ingredient.carb')?.value,
      fat: addedIngredient.get('ingredient.fat')?.value
    }

    return calculateKcalFromMacros(macros)
  }

  calculateAmountKcal(addedIngredient: AbstractControl) {

    const grams = addedIngredient.get('grams')?.value;

    const macros: Macros = {
      protein: addedIngredient.get('ingredient.protein')?.value,
      carbs: addedIngredient.get('ingredient.carb')?.value,
      fat: addedIngredient.get('ingredient.fat')?.value
    }



    const val = (grams * (Number(calculateKcalFromMacros(macros)) / 100))


    return val.toFixed(0)
  }




  calculatePrice(addedIngredient: AbstractControl) {
    const packageCost: number = addedIngredient.get('ingredient.packageCost')?.value
    const packageSize: number = addedIngredient.get('ingredient.packageSize')?.value
    const grams: number = addedIngredient.get('grams')?.value
    const packageUnitSize: Unit = addedIngredient.get('ingredient.packageUnitSize')?.value

    if (isNaN(packageCost) || isNaN(packageSize) || isNaN(grams) || packageSize <= 0) {
      return '0.00';
    }

    let val = 0;

    if (packageUnitSize === Unit.kg || packageUnitSize === Unit.l) {
      val = ((packageCost / (packageSize * 1000)) * grams);
    } else {
      val = ((packageCost / packageSize) * grams);
    }
    return val.toFixed(2)

  }

  checkForIngredientId(addedIngredient: AbstractControl) {
    const ingredient = addedIngredient.get('ingredient') as FormGroup

    if (ingredient) {
      const ingredientId = ingredient.get('id')?.value;
      if (ingredientId) { return true }
    }

    return false
  }

  getDetailMacroInfo(addedIngredient: AbstractControl) {

    const macros: Macros = {
      protein: addedIngredient.get('ingredient.protein')?.value,
      carbs: addedIngredient.get('ingredient.carb')?.value,
      fat: addedIngredient.get('ingredient.fat')?.value
    }

    return 'P: ' + macros.protein + 'g ' + 'C: ' + macros.carbs + 'g ' + 'F: ' + macros.fat + 'g ' + 'Kcal: ' + calculateKcalFromMacros(macros)

  }



  onCreateNewIngredient() {
    this.newIngredientToggler = !this.newIngredientToggler
  }

  onInputSearch(addedIngredient: AbstractControl, index: number) {
    this.searchSubject.next({ ing: addedIngredient, index: index });
  }

  performSearch(searchObj: { ing: AbstractControl, index: number }) {
    if (searchObj.ing && (searchObj.index || searchObj.index === 0)) {
      this.toggleSearch()
      this.currenSearchIndex = searchObj.index
      this.searchIngFromDB(searchObj.ing, searchObj.index)
    } else {

      this.toggleSearch()
    }
  }

  onSearchIngFromDB(addedIngredient: AbstractControl, index: number) {
    this.toggleSearch()
    this.searchIngFromDB(addedIngredient, index)

  }

  toggleSearch() {
    this.searchToggler = !this.searchToggler;
  }

  onDelete() {
    this.delete.emit();
  }

  onDeleteIngredient(index: number) {
    this.addedIngredients.removeAt(index)
  }

  // Function to check if any meal has an ingredient with amount set
  hasAmount(): boolean {



    for (const ingredient of this.addedIngredients.controls) {
      const amount = (ingredient as FormGroup).get('grams')?.value;
      if (amount !== null && amount !== undefined && amount !== '' && amount !== 0) {
        return true;
      }
    }


    return false;
  }
}

