import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ex } from '@fullcalendar/core/internal-common';
import * as firebase from 'firebase/compat';
import { Subject, debounceTime } from 'rxjs';
import { Exercise, SlotModel } from 'src/app/core/thecoach';
import { ExerciseTSService } from 'src/app/services/typeSense/exercise-ts.service';
import * as typesense from 'typesense';
@Component({
  selector: 'app-template-training-slot',
  template: `
    <form [formGroup]="trainingSlot" class="flex w-full flex-row">
      <div class="flex flex-col justify-center items-center pr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 border-2 rounded"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 border-2 rounded"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
      <div class="flex flex-col w-full mr-4">
        <div class="flex flex-row w-full ">
          <input
            (input)="onSearchExercise()"
            type="search"
            autocomplete="off"
            name="exercise"
            id="exercise"
            formControlName="exercise"
            class="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Exercise Name..."
          />

          <button
            type="button"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>

        <div class="flex flex-row w-full pt-2">
          <textarea
            rows="1"
            formControlName="exerciseSetup"
            name="description"
            id="description"
            placeholder="Enter your Exercise description..."
            class="block w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
          ></textarea>
        </div>
      </div>
      <button
        (click)="searchExerciseFromDB('test')"
        type="button"
        class="rounded-md  bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Button text
      </button>

      <div class="flex flex-row justify-center w-full mr-2" *ngIf="slotModel">
        <app-template-slot-generation
          [slotModelForm]="slotModelForm"
        ></app-template-slot-generation>
      </div>
    </form>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class TemplateTrainingSlotComponent implements OnInit {
  @Input({ required: true }) trainingSlot!: FormGroup;
  @Input() userTrainingModels?: SlotModel[];
  @Input({ required: true }) exerciseDB!: Exercise[];

  isMenu = false;

  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)

  searchResults = new Map<string, Exercise>();
  constructor() {}
  ngOnInit(): void {
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchObj) => {
        this.perfromSearch(searchObj);
      });
  }

  perfromSearch(searchString: string) {
    if (searchString) {
      this.searchExerciseFromDB(searchString);
    } else {
    }
  }

  findEmpty() {}

  searchExerciseFromDB(searchString: string) {
    console.log(searchString, this.exerciseDB);
    this.exerciseDB.some((exercise) => {
      if (
        exercise.exerciseName
          ?.toLowerCase()
          .includes(searchString.toLowerCase())
      ) {
        this.searchResults.set(exercise.id as string, exercise);
      }
    });
    console.log('res', this.searchResults);
    // this.exerciseTS.searchForExercise(searchString);
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  onSearchExercise() {
    const searchValue = this.trainingSlot.get('exercise')?.value as string;
    this.searchSubject.next(searchValue);
  }
  /* Example of TrainingSlot passed as Input
  const trainingSlot = this.fb.group({
      id: [''],
      date: [],
      slotNr: [],
      slotModel: [],jkkkkkkkkkk
      exerciseSetup: [''],
      exercise: [, Validators.required],
    });
*/
  get slotModelForm() {
    return this.trainingSlot.get('slotModel') as FormGroup;
  }
  get slotModel() {
    const slotModel = this.trainingSlot.get('slotModel')?.value;
    if (slotModel) {
      return slotModel as SlotModel;
    } else {
      return undefined;
    }
  }

  setTrainingModelForSlot(model: SlotModel) {
    this.trainingSlot.get('slotModel')?.patchValue(model);
    this.toggleMenu();
  }
}
