import { TemplateTrainingplanService } from '../../services/templatetrainingplan.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateTrainingPlan } from 'src/app/core/thecoach';

@Component({
  selector: 'app-trainingplan-library',
  template: `
    <div class="flex w-full justify-between">
      <div class="flex w-full py-4 flex-col ">
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Client Name..."
          />
        </div>
      </div>

      <div class="flex items-center">
        <button
          type="button"
          [routerLink]="'/trainingplan-creator/new'"
          class="flex items-center h-fit w-fit whitespace-nowrap rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
        >
          New Template
        </button>
      </div>
    </div>

    <ul role="list" class="space-y-3 ">
      <li
        *ngFor="let templatePlan of displayplans; let i = index"
        class="w-fit min-w-full bg-white px-4 py-4 shadow rounded-md px-6"
      >
        <div class="flex w-full justify-between items-center text-center">
          <p class="text-md font-semibold">
            {{ templatePlan.trainingPlanName }}
          </p>
          <p class="text-xs italic text-gray-700 line-clamp-1 w-3/5">
            {{ templatePlan.trainingPlanDescription }}
          </p>

          <button
            type="button"
            (click)="onDeleteTrainingplan(templatePlan.id!)"
            class="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Delete
          </button>

          <button
            type="button"
            [routerLink]="['/trainingplan-creator', templatePlan.id]"
            class="inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Edit
          </button>
        </div>
      </li>
    </ul>

    <div>
      <app-simple-modal
        *ngIf="deletePlanToggle"
        (close)="onDeletePlan($event)"
        [positiveButtonText]="'Delete'"
        [negativeButtonText]="'Cancel'"
        [title]="'Delete Trainingplan?'"
        [message]="'Do you want to delete this Trainingplan?'"
      >
        ></app-simple-modal
      >
    </div>
  `,
  styles: [],
})
export class TrainingplanLibraryComponent implements OnInit, OnDestroy {
  trainingplanSubscription: Subscription | undefined; // subscription
  trainingplans: TemplateTrainingPlan[] = [];
  displayplans: TemplateTrainingPlan[] = [];
  deletePlanToggle = false;
  deleteId: string | null = null;

  searchNameValue = '';

  constructor(private tpService: TemplateTrainingplanService) {}

  ngOnInit(): void {
    this.trainingplanSubscription = this.tpService
      .getAllTrainingplansForUser()
      .subscribe((data) => {
        this.trainingplans = data.sort((a, b) =>
          a.trainingPlanName!.localeCompare(b.trainingPlanName!),
        );

        this.displayplans = this.trainingplans;
      });
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayplans = this.trainingplans;
    } else {
      this.displayplans = this.trainingplans.filter((plan) =>
        plan.trainingPlanName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayplans = this.trainingplans;
    }
  }

  onDeletePlan(event: boolean) {
    if (event && this.deleteId) {
      this.tpService.delete(this.deleteId);
      this.deleteId = null;
    }

    this.deletePlanToggle = false;
  }

  onDeleteTrainingplan(id: string) {
    this.deletePlanToggle = true;
    this.deleteId = id;
  }

  ngOnDestroy(): void {
    this.trainingplanSubscription?.unsubscribe;
  }

  oldTemps: TemplateTrainingPlan[] = [];
  getAll() {
    this.tpService.getAllOLD().subscribe((data) => {
      this.oldTemps = data;
      console.log(this.oldTemps);
    });
  }

  saveToNEw() {
    this.tpService.saveOldToNew(this.oldTemps);
  }

  deleteOld() {
    this.tpService.deleteOld(this.oldTemps);
  }
}
