import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Form,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Exercise } from 'src/app/core/thecoach';

@Component({
  selector: 'app-new-exercises-modal',
  template: `
    <div
      @opacity
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <div
            @opacityTranslateY
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div class="bg-white shadow sm:rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  New Exercsies detected!
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Do you want to save the new Exercises?</p>
                </div>
                <form [formGroup]="exercisesForm">
                  <div
                    *ngFor="
                      let exerciseFrom of getexercisesFormControls();
                      let i = index
                    "
                    formArrayName="exercises"
                  >
                    <div
                      formGroupName="{{ i }}"
                      class="flex flex-row w-full justify-between"
                    >
                      <div class="flex flex-col w-full">
                        <div class="mt-2 flex flex-row">
                          <div class="mt-2 w-fit flex flex-col justify-start ">
                            <input
                              type="text"
                              name="exerciseName"
                              id="exerciseName"
                              formControlName="exerciseName"
                              class="block w-full m-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                              placeholder="Exercise Name"
                            />

                            <input
                              type="text"
                              name="muscleGroup"
                              id="muscleGroup"
                              formControlName="muscleGroup"
                              class="block w-full m-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                              placeholder="Muscle Group"
                            />
                          </div>

                          <div class="mt-2 w-full ml-2">
                            <textarea
                              type="text"
                              name="exerciseDescription"
                              id="exerciseDescription"
                              rows="3"
                              formControlName="exerciseDescription"
                              class="block w-full rounded-md border-0 m-1  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                              placeholder="Exercise Description"
                            ></textarea>
                          </div>
                        </div>
                        <div class="w-full">
                          <input
                            type="url"
                            name="exerciseVideoURL"
                            id="exerciseVideoURL"
                            formControlName="exerciseVideoURL"
                            class="block w-full rounded-md border-0 m-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                            placeholder="Exercise URL"
                          />
                        </div>
                      </div>
                      <div class="flex ml-1 mt-4 text-red-400">
                        <svg
                          (click)="onDeleteExercise(i)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="flex flex-row w-full justify-between mt-2">
                  <button
                    type="button"
                    class="rounded-md bg-red-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    (click)="onCancel()"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="rounded-md bg-teal-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    (click)="onSave()"
                  >
                    Save All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class NewExercisesModalComponent implements OnInit {
  @Input() modifedExercises?: Exercise[];
  @Output() close = new EventEmitter<boolean>();

  exercisesForm = this.fb.group({
    exercises: this.fb.array([]),
  });

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    if (this.modifedExercises) {
      this.modifedExercises.forEach((exercise) => {
        (this.exercisesForm.get('exercises') as FormArray).push(
          this.generateExerciseFormFromObject(exercise)
        );
      });
    }

    //console.log(this.modifedExercises);
  }

  getexercisesFormControls() {
    return (this.exercisesForm.get('exercises') as FormArray).controls;
  }

  generateExerciseFormFromObject(exercise: Exercise): FormGroup {
    return this.fb.group({
      id: exercise.id,
      exerciseName: exercise.exerciseName,
      exerciseVideoURL: exercise.exerciseVideoURL,
      exerciseDescription: exercise.exerciseDescription,
      muscleGroup: exercise.muscleGroup,
      ownerId: exercise.ownerId,
    });
  }

  isNotification = true;

  onSave() {
    let newExArr: Exercise[] = [];
    (this.exercisesForm.get('exercises') as FormArray).controls.forEach(
      (ex) => {
        let newEx: Exercise = {};
        Object.assign(newEx, ex.value);
        if (
          newEx.exerciseVideoURL === undefined ||
          newEx.exerciseVideoURL === null
        ) {
          newEx.exerciseVideoURL = '';
        }
        if (newEx.muscleGroup === undefined || newEx.muscleGroup === null) {
          newEx.muscleGroup = '';
        }
        if (
          newEx.exerciseDescription === undefined ||
          newEx.exerciseDescription === null
        ) {
          newEx.exerciseDescription = '';
        }

        newExArr.push(newEx);
      }
    );

    this.modifedExercises?.splice(0, this.modifedExercises.length, ...newExArr);
    this.close.emit(true);
  }

  onCancel() {
    this.close.emit(false);
  }

  onDeleteExercise(index: number) {
    (this.exercisesForm.get('exercises') as FormArray).removeAt(index);
    this.modifedExercises?.splice(index, 1);
  }
}
