import { Component, Input } from '@angular/core';
import { AppUser, ClientCheckin, CustomCheckinFormType, CustomCheckinFormTypeNumber, CustomCheckinFormTypeRange, CustomCheckinFormTypeString, CustomCheckinFormValue } from 'src/app/core/thecoach';

@Component({
  selector: 'app-display-checkin-form-dashboard',
  template: `
<div *ngIf="selectedCheckin.CustomCheckinModel"

      class="flex flex-col gap-6 pb-4"
>
      <p class="text-lg font-semibold pt-4">
        {{ selectedCheckin.title || 'Check In - '}}
        {{ selectedCheckin.date | date : 'dd-MM-yyyy' }}
      </p>

      <div *ngFor="let ccf of selectedCheckin.CustomCheckinModel?.ccfs"
  >
    <ng-container [ngSwitch]="ccf.type" >
     <div *ngSwitchCase="customCheckinFormType.Text">
          <app-display-dashboard-checkin-text [ccf]='ccf' *ngIf="isCustomCheckinFormTypeString(ccf)"></app-display-dashboard-checkin-text>
      </div>
      <div *ngSwitchCase="customCheckinFormType.Number">

          <app-display-dashboard-checkin-number [ccf]='ccf' *ngIf="isCustomCheckinFormTypeNumber(ccf)"></app-display-dashboard-checkin-number>
      </div>

      <div *ngSwitchCase="customCheckinFormType.Range">

          <app-display-dashboard-checkin-range [ccf]='ccf' *ngIf="isCustomCheckinFormTypeRange(ccf)"></app-display-dashboard-checkin-range>
      </div>
      <div *ngSwitchDefault>No Type found</div>
    </ng-container>
      </div>

</div>
 `,
  styles: [
  ]
})
export class DisplayCheckinFormDashboardComponent {
  @Input() coach!: AppUser;
  @Input() selectedCheckin!: ClientCheckin;

  customCheckinFormType = CustomCheckinFormType;


  isCustomCheckinFormTypeRange(ccf:CustomCheckinFormValue):ccf is CustomCheckinFormTypeRange{
    return ccf.type === CustomCheckinFormType.Range
  }

  isCustomCheckinFormTypeNumber(ccf:CustomCheckinFormValue):ccf is CustomCheckinFormTypeNumber{
    return ccf.type === CustomCheckinFormType.Number
  }


  isCustomCheckinFormTypeString(ccf:CustomCheckinFormValue):ccf is CustomCheckinFormTypeString{
    return ccf.type === CustomCheckinFormType.Text
  }


}
