import { AppUser, Message, formatUrlsWithLinks } from './../../core/thecoach';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Note } from 'src/app/core/thecoach';
import { NoteService } from 'src/app/services/note.service';

@Component({
  selector: 'app-notes-module',
  template: `
    <div class="flex flex-col p-4">
      <div class="relative self-end">
        <div
          class="absolute -top-4 -right-4 text-red-400 cursor-pointer"
          (click)="onDeleteNote(note)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
      <h3 class="text-sm font-medium text-gray-900">
        {{ note.title }}
      </h3>
      <dl
        class="mt-1 flex flex-grow flex-col justify-between whitespace-pre-wrap break-all"
      >
        <dd
          class="text-sm text-gray-500 text-justify break-normal "
          [innerHTML]="frmtUrlsWithLinks(note.message)"
        ></dd>
      </dl>
    </div>
    <div class="">
      <div class="-mt-px flex divide-x divide-gray-200  w-full justify-between">
        <div
          class="flex flex-1 w-1/2 flex-col  justify-center text-center items-center"
        >
          <span class="text-sm italic text-gray-700 px-4">{{
            note.lastEdit | date : 'dd-mm-yyyy'
          }}</span>
          <span class="text-sm italic text-gray-700 px-4">{{
            note.lastEdit | date : 'HH:mm'
          }}</span>
        </div>
        <div class="-ml-px w-1/2 flex">
          <div
            class="relative inline-flex px-4  hover:bg-gray-200 cursor-pointer flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            (click)="onEditNote(note); $event.stopPropagation()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-gray-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
            <span class="pl-1"> Edit</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="deleteNoteToggle">
      <app-simple-modal
        [title]="'Delete Note'"
        [message]="'Do you want to delete the note?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onCloseDelete($event)"
      >
      </app-simple-modal>
    </div>
  `,
  styles: [],
})
export class NotesModuleComponent {
  constructor(private noteService: NoteService) {}
  @Input() note!: Note;
  @Input() client!: AppUser | undefined;
  @Output() editNote = new EventEmitter<Note>();

  deleteNoteToggle: boolean = false;

  onEditNote(note: Note) {
    this.editNote.emit(note);
  }

  onDeleteNote(note: Note) {
    this.deleteNoteToggle = !this.deleteNoteToggle;
  }

  onCloseDelete(event: boolean) {
    if (event && this.client) {
      this.noteService.deleteNote(this.client, this.note);
    }

    this.deleteNoteToggle = !this.deleteNoteToggle;
  }

  frmtUrlsWithLinks(text: string | undefined): string {
    return formatUrlsWithLinks(text);
  }
}
