import { LoginActiveGuard } from './../../common/login-active.guard';
import { CheckinService } from '../../services/checkin.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  AppUser,
  CheckinStatus,
  ClientCheckin,
  CustomCheckinModel,
  Message,
  deleteCheckinMessage,
  downloadObject,
} from '../../core/thecoach';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isSameDay, startOfDay, startOfToday } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { Subscription, first, forkJoin, map, take } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { SafeUrl } from '@angular/platform-browser';
import { FileService } from 'src/app/services/file.service';
import * as _ from 'lodash';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MealplanService } from 'src/app/services/mealplaner/mealplan.service';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-customer-check-ins',
  template: `
    <!--
  This example requires some changes to your config:


  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }

-->
    <div class="mt-2" *ngIf="!isNewCheckin">
      <div class="text-center">
        <div class="">
          <button
            (click)="onNewCheckin(true)"
            type="button"
            class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal<-600"
          >
            <svg
              class="-ml-0.5 mr-1.5 h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
              />
            </svg>
            New Check In
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isNewCheckin" class="my-4">
      <form
        class="flex flex-col"
        [formGroup]="checkInForm"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="flex flex-col relative overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-teal-500 focus-within:ring-1 focus-within:ring-teal-500"
        >
          <div
            class="absolute inset right-0 top-0 h-6 w-6 rounded-tr-lg text-gray-500 hover:text-teal-500"
          >
            <button
              type="button"
              (click)="cancelCheckinToggle()"
              class="w-full h-full  flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 stroke-red-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>

          <label for="title" class="sr-only">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            formControlName="title"
            class="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
            placeholder="Title"
          />
          <label for="message" class="sr-only">Message</label>
          <div
            *ngIf="!this.audioAttachment; else gotVoiceMessage"
            class="flex w-full justify-between items-center mb-14"
            [ngClass]="{
              'justify-end': voicetoggler,
              'justify-between': !voicetoggler
            }"
          >
            <textarea
              *ngIf="!voicetoggler"
              rows="3"
              formControlName="message"
              name="message"
              id="message"
              class="block w-full ml-2 py-2  mr-4 border-1 border-gray-200 rounded-lg  pb-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:ring-teal-400 focus:border-teal-400 sm:text-sm sm:leading-6"
              placeholder="Write your checkin text..."
            >

                ></textarea
            >
            <app-voice-recorder
              class="mr-2"
              *ngIf="!checkTextInput()"
              (click)="$event.stopPropagation(); $event.preventDefault()"
              (blob)="onSendBlob($event)"
              (clearRecord)="onClearRecord($event)"
              (recordStart)="voicetoggler = true"
              [uploadAnimation]="this.uploadAnimation"
              [recorderAsAttachement]="true"
              [isCoach]="false"
            ></app-voice-recorder>
          </div>
          <ng-template #gotVoiceMessage>
            <div class="flex w-full justify-start items-center mb-14">
              <app-voice-player [audioBlobUrl]="audioUrl!"></app-voice-player>
              <button
                class="rounded-full bg-[#F1F3F4] hover:bg-[#E5E7E8] ml-2 p-2"
                (click)="clearAudioAttachment()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 stroke-red-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </div>
          </ng-template>

          <!-- Spacer element to match the height of the toolbar -->
          <div aria-hidden="true" class="hidden">
            <div class="py-2">
              <div class="h-9"></div>
            </div>
            <div class="h-px"></div>
            <div class="py-2">
              <div class="py-px">
                <div class="h-9"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col inset-x-px bottom-0">
          <!-- Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. -->
          <div class="relative w-full h-full">
            <div
              class="absolute -bottom-1 -right-1 flex-nowrap justify-end space-x-2 py-2 px-2 sm:px-3"
            >
              <div class="flex-shrink-0">
                <label id="listbox-label" class="sr-only">
                  Add a due date
                </label>
                <div class="relative z-10">
                  <button
                    type="button"
                    (click)="toggleCalendar()"
                    class="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 py-2 px-2 text-sm font-medium text-gray-400 hover:bg-gray-100 sm:px-3 border border-gray-200"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                  >
                    {{ selectedDay | date : 'dd-MM-yyyy' }}

                    <!-- Selected: "text-gray-300", Default: "text-gray-500" -->
                    <svg
                      class="h-5 w-5 flex-shrink-0 text-gray-300 sm:-ml-1 pl-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <!-- Selected: "text-gray-900" -->
                  </button>

                  <!--
            Select popover, show/hide based on select state.

            Entering: ""
              From: ""
              To: ""
            Leaving: "transition ease-in duration-100"
              From: "opacity-100"
              To: "opacity-0"
          -->

                  <div
                    @opacityLeave
                    *ngIf="showCalendar"
                    class="absolute right-0 z-10 mt-1 h-fit w-60 overflow-auto rounded-lg bg-white py-2 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    tabindex="-1"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-option-0"
                  >
                    <div class="w-full flex justify-center items-center">
                      <app-datepicker
                        class="w-full px-2"
                        [preSelectedDay]="this.selectedDay"
                        (onSelectedDay)="onSetCheckinDate($event)"
                      ></app-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- checkin Forms Question -->

<div class="flex mb-2 mt-4 w-full  justify-center items-center flex-col
rounded-lg border border-gray-300 shadow-sm focus-within:border-teal-500 focus-within:ring-1 focus-within:ring-teal-500
" *ngIf="user && user?.enableCMFs && user.ccmId && user.ccmId !== 'No Checkin Model'">
<h3 class="text-md italic font-medium text-gray-700 pt-2">Checkin Questionnaire</h3>
<hr>
            <app-checkin-custom-models-form [user]="user" [customCheckinForm]="checkinModelForm"
            [customCheckinModel]="clientCheckIn.CustomCheckinModel"
            class="w-full p-4"
            ></app-checkin-custom-models-form>
          </div>

<!-- end Checkin Forms Questin -->
          <div
            class="flex items-center justify-between mt-2 space-x-3 border rounded-lg border-gray-200 px-2 py-2 sm:px-3"
          >
            <div class="flex flex-col w-full">
              <div class="flex flex-col">
                <label
                  for="files-upload"
                  class="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
                >
                  <svg
                    class="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span
                    class="text-sm italic text-gray-500 group-hover:text-gray-600"
                    >Attach form fotos</span
                  >
                </label>
                <input
                  type="file"
                  id="files-upload"
                  accept="image/*"
                  class="hidden"
                  multiple="multiple"
                  (change)="onFileUpload($event)"
                />
              </div>
              <div class="flex flex-col" *ngIf="this.files.length > 0">

              <div class='flex w-full flex-col'>

                <app-upload-files-task

                [checkinId]="clientCheckIn.id!"
                [files]='files'
                 (imgUrl)="onAddPictureUrl($event)"
                    (uploading)="checkFileUpload($event)"


                > </app-upload-files-task>
              </div>


              </div>
              <div
                class="isolate flex -space-x-2 overflow-hidden items-end mt-5"
                *ngIf="this.downloadObjectArr.length > 0 && !isUpdating"
              >
                <div class="grid grid-cols-4 items-end gap-px">
                  <div
                    *ngFor="let imgUrl of downloadObjectArr; let i = index"
                    class="relative inline-block"
                  >
                    <img
                      class="relative z-0 inline-block h-10 w-10 rounded-md ring-2 ring-white"
                      src="{{ imgUrl.downloadURL }}"
                      alt=""
                    />
                    <span
                      (click)="onDeleteImgFromCheckin(i, clientCheckIn)"
                      class="absolute top-0 right-0 block h-3 w-3 rounded-full ring-1 bg-white ring-white z-10 items-center justify-center flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="red"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-shrink-0" *ngIf="voicetoggler && audioAttachment">
              <button
                type="submit"
                class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Send
              </button>
            </div>

            <div *ngIf="checkTextInput() && !isUploading">
              <button
                type="button"
                (click)="onSubmit()"
                class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Send
              </button>
            </div>
            <!--
                        <button
                type="button"
                (click)="onLog()"
                class="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Log
              </button> -->
          </div>
        </div>
      </form>
    </div>
    <hr class="mt-4" />
    <div class="mt-4">
      <ul role="list" class="space-y-3">
        <li
          class="rounded-md bg-white px-2 py-2 shadow"
          *ngFor="let checkin of checkInDatabase; let j = index"
          (click)="
            onSelectCheckin(checkin);
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          <div class="flex justify-between">
            <h3 class="truncate w-4/12 text-sm font-semibold  text-gray-900">
              {{ checkin.title }}
            </h3>
            <div>
              <ng-container *ngIf="checkin.status === checkinStatus.Sent">
                <span
                  class="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700"
                  >Sent</span
                >
              </ng-container>

              <ng-container *ngIf="checkin.status === checkinStatus.Answered">
                <span
                  class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                  >Answered</span
                >
              </ng-container>

              <ng-container *ngIf="checkin.status === checkinStatus.Closed">
                <span
                  class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                  >Closed</span
                >
              </ng-container>
            </div>
            <span class="whitespace-nowrap text-gray-400 italic text-sm">{{
              checkin.date | date : 'dd-MM-yyyy'
            }}</span>
          </div>
          <div
            class="px-2 py-2"
            *ngIf="selectedCheckin && selectedCheckin.id === checkin.id"
          >
            <app-checkin-message
              (click)="$event.stopPropagation()"
              [checkIn]="selectedCheckin"
              [customer]="true"
              (ondelete)="onDeleteMsg($event)"
            ></app-checkin-message>

            <div *ngIf="toggleAnswer" class="w-full">
              <app-checkin-form-answer
                (click)="$event.stopPropagation(); $event.preventDefault()"
                [selectedCheckin]="selectedCheckin"
                [user]="this.user!"
                (toggleAnswer)="onCancelAnswer()"
              ></app-checkin-form-answer>
            </div>

            <div class="flex justify-between">
              <div class=" grid grid-cols-4 items-end mt-5 gap-px">
                <img
                  *ngFor="let imgUrl of checkin.imgUrls; let i = index"
                  class="flex inline-block h-10 w-10 rounded-md ring-2 ring-white"
                  src="{{ imgUrl.downloadURL || imgUrl }}"
                  alt=""
                />
              </div>
              <div class="mt-5 flex justify-between items-end flex-col">
                <button
                  *ngIf="
                    checkin.messages!.length <= 1 &&
                    checkin.status !== checkinStatus.Closed
                  "
                  type="button"
                  (click)="onEditCheckIn(j, checkin); $event.stopPropagation()"
                  class="flex  h-fit items-center rounded-md bg-teal-600 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500"
                >
                  Edit
                </button>

                <button
                  *ngIf="checkin.messages!.length > 1 && !toggleAnswer"
                  type="button"
                  (click)="onSendMessageToCheckin(j); $event.stopPropagation()"
                  class="flex  h-fit items-center rounded-md bg-teal-600 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500"
                >
                  Send Message
                </button>

                <span
                  *ngIf="checkin.lastEdit"
                  class="text-gray-400 text-xs  italic pt-2"
                  >Last Edit on
                  {{ checkin.lastEdit | date : 'dd-MM-yyyy HH:mm' }}</span
                >
              </div>
            </div>
          </div>

          <div>
            <app-simple-modal
              *ngIf="isSameDate"
              (close)="onOverwrite($event)"
              [positiveButtonText]="'Save'"
              [negativeButtonText]="'Cancel'"
              [title]="'Check In with same Date found!'"
              [message]="'Do you want to overwrite the Check In?'"
            ></app-simple-modal>
          </div>
          <div>
            <app-simple-modal
              *ngIf="isExistingCheckIn"
              (close)="onLoadExisting($event)"
              [positiveButtonText]="'Yes'"
              [negativeButtonText]="'No'"
              [title]="'Check In exists for today!'"
              [message]="'Load Check In for today?'"
            ></app-simple-modal>
          </div>

          <div>
            <app-simple-modal
              *ngIf="cancelCheckinToggler"
              (close)="cancelCheckin($event)"
              [positiveButtonText]="'Yes'"
              [negativeButtonText]="'No'"
              [title]="'Cancel Edit?'"
              [message]="'Do you want to cancel the Edit?'"
            ></app-simple-modal>
          </div>
        </li>
      </ul>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CustomerCheckInsComponent implements OnInit, OnDestroy {
  today = new Date();
  selectedDay = this.today;
  files: File[] = [];
  downloadObjectArr: downloadObject[] = [];
  status = CheckinStatus.Draft;
  clientCheckIn: ClientCheckin = {};
  checkInSubscription: Subscription | undefined;
  checkInDatabase: ClientCheckin[] = [];
  isSameDate = false;
  isExistingCheckIn = false;
  isEditing = false;
  answerToggler: boolean[] = [];
  toggleAnswer = false;

  checkinStatus = CheckinStatus;

  selectedCheckin: ClientCheckin | undefined;

  showCalendar = false;

  isNewCheckin = false;

  cancelCheckinToggler = false;

  messageForm = this.fb.group({
    message: ['', Validators.required],
  });

  voicetoggler = false;
  uploadAnimation = false;
  private voiceUploadSubscription: Subscription | undefined;
  audioBlob: Blob | undefined;
  audioName: string | undefined;
  audioAttachment: boolean = false;
  audioUrl: SafeUrl | undefined;

  checkInForm = this.fb.group({
    id: [''],
    clientId: [''],
    clientEmail: [''],
    checkinModelForm: this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      ccfs: this.fb.array([])
    }, { validators: this.atLeastOneInput })
    ,
    title: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    messages: this.fb.array([]),
    voiceUrl: new FormControl(''),
    date: [this.selectedDay],
    status: [''],
  });


  user: AppUser | undefined;
  userSubscription: Subscription | undefined;

  isFlyoutMenu = false;
  selectedMessage: Message | undefined;
  oldDate: Date | undefined;

  isUploading = false;
  isUpdating = false;
  fileUploadToggle = false;

  constructor(
    private fb: FormBuilder,
    private checkInService: CheckinService,
    private auth: AuthService,
    private clipBoardService: ClipboardService,
    private fileService: FileService,
    private db: AngularFirestore,
    private cdRef: ChangeDetectorRef
  ) { }


  atLeastOneInput(control: AbstractControl) {
    const daysArray = control.get('ccfs') as FormArray;

    if (daysArray && daysArray.length > 0) {
      return null;

    } else {
      return { 'atLeastOneInput': true }
    }

  }




  onDeleteMsg(event: { checkin: ClientCheckin; msg: Message }) {
    deleteCheckinMessage(event, this.checkInService);
  }

  get checkinModelForm() {
    return this.checkInForm.get('checkinModelForm') as FormGroup
  }

  onLog() {
    //console.log(this.checkInForm)
    console.log('checkinModelFom: ', this.checkinModelForm)
    const cstVal: CustomCheckinModel = this.checkinModelForm.value;
    console.log('Form Converted to Model: ', cstVal);
  }


  ngOnInit() {
    this.loadCheckInDatabase();
    this.userSubscription = this.auth.appUser$
      .pipe()
      .subscribe((appUser) => (this.user = appUser as AppUser));

    this.voiceUploadSubscription = this.checkInService
      .getVoiceUploadCompletedObservable()
      .subscribe(() => {
        this.uploadAnimation = false;
        this.onCancelAnswer();
      });
  }

  onSelectCheckin(checkin: ClientCheckin) {
    if (this.selectedCheckin === checkin) {
      this.selectedCheckin = undefined;
    } else {
      this.updateIMGs(checkin);

      this.selectedCheckin = checkin;
      //console.log(this.selectedCheckin);
      this.toggleAnswer = false;
    }
  }
  updateIMGs(checkin: ClientCheckin) {

    checkin.imgUrls?.forEach((imgUrlsObject, index) => {
      // console.log(imgUrlsObject);
      /* const fileName = (imgUrlsObject as unknown as string)
         .substring((imgUrlsObject as unknown as string)
           .lastIndexOf("%") + 3, (imgUrlsObject as unknown as string)
             .lastIndexOf("?"))
 */
      // this.getNewFileUrl(fileName, checkin.clientId as string)
      if (!this.conversionCheck(imgUrlsObject.storagePath)) {
        this.fileService
          .getIMGObject(checkin.clientId as string, imgUrlsObject)
          .pipe(first())
          .subscribe((res) => {
            if (checkin.imgUrls && res?.fileId === imgUrlsObject.fileId) {
              checkin.imgUrls[index] = _.cloneDeep(res);
            }
          });
      }
    });

  }

  conversionCheck(path: string) {
    if (path.substring(path.length - 9, path.length).includes('_798x1064')) {
      return true
    } else { return false; }
  }
  onCancelAnswer() {
    this.messages.reset();
    this.toggleAnswer = !this.toggleAnswer;
  }

  get messages() {
    return this.messageForm.get('message') as FormControl;
  }

  createInputElement(): FormGroup {
    return new FormGroup({ message: new FormControl('') });
  }

  createForms(index: number) {
    (this.checkInForm.get('messages') as FormArray).push(
      this.createInputElement()
    );
  }

  onSubmitMessage() {
    if (this.messages.value.length > 1 && this.selectedCheckin) {
      const answerMessage: Message = {
        author: this.user?.displayName,
        authorId: this.user?.id,
        message: this.messages.value,
        date: new Date(),
      };

      this.checkInService.sendAnswerToCheckin(
        this.selectedCheckin,
        answerMessage,
        CheckinStatus.Sent
      );
      this.toggleAnswer = !this.toggleAnswer;
    }
  }

  onEditCheckIn(index: number, checkin: ClientCheckin) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (this.isEditing) {
      this.onNewCheckin(false);
    }

    this.checkInDatabase.splice(index, 1);

    if (checkin.imgUrls) {
      this.downloadObjectArr = checkin.imgUrls;
    }

    this.isNewCheckin = true;
    this.status = CheckinStatus.Edit;
    this.selectedDay = checkin.date!;
    checkin.lastEdit = new Date();

    this.clientCheckIn = checkin;


    let lastmsg = this.clientCheckIn.messages?.pop();
    //console.log(lastmsg);

    if (!lastmsg?.voiceUrl) {
      this.checkInForm.patchValue({
        id: checkin.id,
        clientId: checkin.clientId,
        clientEmail: checkin.clientEmail,
        title: checkin.title,
        date: checkin.date,
        message: lastmsg?.message,

        status: this.status,
      });
    } else {
      this.checkInForm.patchValue({
        id: checkin.id,
        clientId: checkin.clientId,
        clientEmail: checkin.clientEmail,
        title: checkin.title,
        date: checkin.date,
        voiceUrl: lastmsg?.voiceUrl,

        status: this.status,
      });



      this.audioAttachment = true;
      this.voicetoggler = true;
    }


    this.isEditing = true;
  }

  onSendMessageToCheckin(index: number) {
    this.toggleAnswer = !this.toggleAnswer;
    (this.checkInForm.get('messages') as FormArray).push(
      this.createInputElement()
    );
  }

  onLoadExisting(event: boolean) {
    if (event) {
      const index = this.checkInDatabase.findIndex(
        (checkin) => checkin.id === this.clientCheckIn.id
      );

      this.onEditCheckIn(index, this.clientCheckIn);
    } else {
      this.onCreateNewCheckin(true);
    }

    this.isExistingCheckIn = false;
  }

  checkOriginalAuthor(checkin: ClientCheckin, message: Message): boolean {
    if (!checkin.messages) return false;

    if (!message.authorId) {
      if (checkin.messages[0].author === message.author) return true;
    } else {
      if (checkin.messages[0].authorId === message.authorId) return true;
    }

    return false;
  }

  onOverwrite(event: boolean) {
    if (event) {
      this.onSubmitCheckin();
    }

    this.isSameDate = false;
  }

  loadCheckInDatabase() {
    this.checkInSubscription = this.checkInService
      .getAllCheckins()
      .subscribe((checkInData) => {
        checkInData.forEach((checkIn, index) => {
          checkIn.date = (checkIn.date as unknown as Timestamp).toDate();

          if (checkIn.messages) {
            checkIn.messages?.forEach((msg) => {
              msg.date = (msg.date as unknown as Timestamp).toDate();
            });
          }

          this.answerToggler.push(false);
          this.createForms(index);

          if (checkIn.lastEdit)
            checkIn.lastEdit = (
              checkIn.lastEdit as unknown as Timestamp
            ).toDate();
        });

        checkInData.sort((a, b) => b.date!.getTime() - a.date!.getTime());

        this.checkInDatabase = checkInData;
      });
  }
  //
  ngOnDestroy() {
    this.checkInSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.voiceUploadSubscription?.unsubscribe();
  }

  onNewCheckin(value: boolean) {
    if (!this.isExistingCheckIn && value) {
      this.checkInDatabase.forEach((checkIn) => {
        if (isSameDay(checkIn.date!, this.today)) {
          this.isExistingCheckIn = true;
          this.clientCheckIn = checkIn;
        }
      });
    } else {
      return this.onCreateNewCheckin(value);
    }

    if (!value) {
      this.onClearRecord(true);
      this.selectedCheckin = undefined;
      this.clientCheckIn = {};
    }

    this.isNewCheckin = value;

    if (localStorage.getItem('fileURLs') !== null) {

      this.isUpdating = true;
      const timimg: downloadObject[] = JSON.parse(
        localStorage.getItem('fileURLs') as string
      );

      const observables = timimg.map((obj) =>
        this.fileService.getIMGObject(this.user?.id as string, obj).pipe(
          take(1), // Ensure only one emission
          map((updatedObj) => (updatedObj ? updatedObj : obj)) // Keep the original object if updatedObj is null
        )
      );

      // Use forkJoin to execute all observables in parallel
      forkJoin(observables).subscribe((updatedObjects) => {
        // Replace the original array with the updated objects
        this.downloadObjectArr = updatedObjects;
        localStorage.setItem(
          'fileURLs',
          JSON.stringify(this.downloadObjectArr)
        );
        this.clientCheckIn.imgUrls = this.downloadObjectArr;
        this.isUpdating = false;
      });
    }
    this.clientCheckIn.id = this.db.createId();
  }
  messageURLCheck() {
    if (this.clientCheckIn.messages?.some((msg) => msg.voiceUrl)) {
      return true;
    }
    return false;
  }
  onCreateNewCheckin(value: boolean) {
    this.isNewCheckin = value;
    this.clientCheckIn = {};

    this.clientCheckIn.messages = [];
    this.downloadObjectArr = [];
    this.checkInForm.reset();
    this.selectedDay = this.today;
    this.loadCheckInDatabase();
    this.toggleAnswer = !this.toggleAnswer;
    if (value) {
      this.status = CheckinStatus.Created;
    } else {
      this.status = CheckinStatus.Draft;
    }
  }

  onSubmit() {
    /* console.log(
      this.audioBlob,
      this.audioUrl,
      this.audioAttachment,
      this.audioName,
      this.voicetoggler
    );
*/

    this.checkInDatabase.forEach((checkIn) => {
      if (isSameDay(checkIn.date!, this.clientCheckIn.date!)) {
        this.isSameDate = true;
      }
    });

    if (!this.isSameDate) {
      this.onSubmitCheckin();
    }
  }

  onSubmitCheckin() {
    this.isEditing = false;
    // this.clientCheckIn = this.checkInForm.value as ClientCheckin

    this.clientCheckIn.clientName = this.user!.displayName;
    this.clientCheckIn.clientId = this.user!.id;
    this.clientCheckIn.title = this.checkInForm.value.title as string;
    this.clientCheckIn.imgUrls = this.downloadObjectArr;
    this.status = CheckinStatus.Sent;
    this.clientCheckIn.status = this.status;
    this.clientCheckIn.date = this.selectedDay;
    this.clientCheckIn.lastEdit = new Date();

  //  console.log(this.checkInForm)

    if (!this.clientCheckIn.messages) {
      this.clientCheckIn.messages = [];
    }


    if(this.user?.enableCMFs && this.user.ccmId && this.user.ccmId !== 'No Checkin Model'){
      this.clientCheckIn.CustomCheckinModel = this.checkinModelForm.value;
    }

    this.clientCheckIn.messages.push({
      author: this.clientCheckIn.clientName,
      authorId: this.clientCheckIn.clientId,
      message: this.checkInForm.value.message as string,
      date: this.today,
    });

    if (!this.audioAttachment) {
      this.checkInService.sendCheckIn(this.clientCheckIn);
    } else {
      this.checkInService.sendCheckInWithVoice(
        this.clientCheckIn,
        this.clientCheckIn.messages.pop() as Message,
        this.audioBlob as Blob,
        this.audioName as string
      );
    }



    /*
    if (this.oldDate && this.clientCheckIn.clientId) {
      this.checkInService.deleteClientCheckin(
        this.clientCheckIn.clientId,
        this.clientCheckIn
      );
    }
*/


    this.resetCeckin();
  }

  resetCeckin() {
    this.toggleAnswer = false;
    this.selectedCheckin = undefined;
    this.onNewCheckin(false);
    this.checkInForm.reset();
    (this.checkinModelForm.get('ccfs') as FormArray).reset()
    this.checkinModelForm.reset();
    this.files = [];
    this.downloadObjectArr = [];
    localStorage.clear();
  }

  onDeleteImgFromCheckin(index: number, checkIn: ClientCheckin) {
    const delImg = _.cloneDeep(this.downloadObjectArr[index]);
    this.fileService.deleteIMG(delImg);
    this.downloadObjectArr.splice(index, 1);
    this.files.splice(index, 1);

    localStorage.setItem('fileURLs', JSON.stringify(this.downloadObjectArr));
    checkIn.imgUrls = this.downloadObjectArr;
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  onAddPictureUrl(dlObjArr: downloadObject[]) {
    dlObjArr.forEach(dlo => {
      if (this.downloadObjectArr.find(obj => dlo.downloadURL === obj.downloadURL)) {

      } else {
        this.downloadObjectArr.push(dlo); //
      }
    })

    this.clientCheckIn.imgUrls = this.downloadObjectArr;
    localStorage.setItem('fileURLs', JSON.stringify(this.downloadObjectArr));
  }

  onAddPictureUrlToMessage(downloadObj: downloadObject, index: number) {
    this.downloadObjectArr = this.checkInDatabase[index]
      .imgUrls as downloadObject[];
    this.downloadObjectArr.push(downloadObj);
    this.checkInDatabase[index].imgUrls = this.downloadObjectArr;
  }

  onSetCheckinDate(date: Date) {
    if (this.clientCheckIn.id) {
      this.oldDate = this.clientCheckIn.date;
    }

    this.selectedDay = date;
    this.clientCheckIn.date = date;
    this.toggleCalendar();
  }

  onFileUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      this.files = Array.from(target.files)

    }

    this.cdRef.detectChanges();

    // this.fileUploadToggle = !this.fileUploadToggle;
  }

  checkTextInput() {
    if (!this.message.value) return false;

    if (this.message.value && this.message.value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkFileUpload(value: boolean) {
    if (value) {
      this.isUpdating = true;
    } else {
      this.isUpdating = false;
      this.fileUploadToggle = !this.fileUploadToggle
    }

    this.cdRef.detectChanges();
  }

  onClearRecord(event: boolean) {
    if (event) {
      this.voicetoggler = false;
    }
  }

  get message() {
    return this.checkInForm.get('message') as FormControl;
  }

  onSendBlob(event: { blob: Blob; name: string; url?: SafeUrl }) {
    if (event.blob && event.name) {
      this.audioBlob = event.blob;
      this.audioName = event.name;
      this.audioAttachment = true;

      if (event.url) this.audioUrl = event.url;

      // this.onSubmit();
    }
  }

  clearAudioAttachment() {
    this.voicetoggler = false;
    this.audioAttachment = false;
    this.audioBlob = undefined;
    this.audioName = undefined;
    this.audioUrl = undefined;
  }

  cancelCheckin(value: boolean) {
    if (!value) {
      this.cancelCheckinToggler = !this.cancelCheckinToggler;
    } else {
      this.downloadObjectArr.forEach((downloadObject) => {
        this.fileService.deleteIMG(downloadObject);
      });

      localStorage.clear();
      this.resetCeckin();
      this.cancelCheckinToggler = !this.cancelCheckinToggler;
    }
  }

  cancelCheckinToggle() {
    this.cancelCheckinToggler = !this.cancelCheckinToggler;
  }


}
