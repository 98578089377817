import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  switchMap,
  Observable,
  combineLatest,
  map,
  Subscription,
  first,
} from 'rxjs';
import { AppUser, convertDateObject } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-coach-customer-menu',
  template: `
    <div *ngIf="selectedClient !== undefined">
      <div class="py-4">
        <button
          (click)="onResetSelectedClient()"
          type="button"
          class="flex flex-row justify-center items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 pr-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>

          Back
        </button>
      </div>
    </div>

    <div *ngIf="!selectedClient">
      <div class="py-4">
        <div>
          <label
            for="search"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Search by Name</label
          >
          <div class="mt-2">
            <input
              type="search"
              name="search"
              id="search"
              [(ngModel)]="searchNameValue"
              (keyup)="onKeyDownSearch($event)"
              (input)="onClearSearch()"
              class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
              placeholder="Enter Client Name..."
            />
          </div>
        </div>
      </div>

      <ul
        role="list"
        class="space-y-3"
        *ngIf="displayClients; else loadingClients"
      >
        <li
          *ngFor="let client of displayClients; let i = index"
          class="overflow-hidden bg-white px-4 py-4 shadow rounded-md px-6"
        >
          <div class="flex w-full justify-between">
            <div class="flex w-2/6 justify-start items-center">
              <img
                class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
                src="{{
                  client.imgUrl ||
                    '.././assets/images/default-person-image.webp'
                }}"
              />
              <h3 class="pl-2">{{ client.displayName }}</h3>
              <span class="italic text-sm text-gray-400 pl-2 align-bottom">{{
                client.email
              }}</span>
            </div>

            <div
              class="flex text-gray-500 items-center

          "
            >
              <div
                class="w-fit h-fit p-2 border-2 rounded-lg border-gray-500 hover:bg-gray-200 cursor-pointer"
                [ngClass]="{
                  'bg-gray-200': selectedClientIndex === i,
                  'bg-white': selectedClientIndex !== i
                }"
                (click)="onOpenClient(client, i)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <ng-template #loadingClients>
        <ngx-skeleton-loader count="5" animation="pulse"></ngx-skeleton-loader>
      </ng-template>
    </div>
  `,
  styles: [],
})
export class CoachCustomerMenuComponent {
  @Output() clientWasSelected = new EventEmitter<AppUser>();
  @Input() selectedClient: AppUser | undefined;

  constructor(
    protected userService: UserService,
    protected auth: AuthService,
  ) {}
  user: AppUser = {};
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];

  userSubscription: Subscription | undefined;
  searchNameValue = '';
  selectedClientIndex: number | undefined = undefined;

  onOpenClient(client: AppUser, index: number) {
    this.selectedClient = client;
    this.selectedClientIndex = index;

    this.clientWasSelected.emit(this.selectedClient);
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  onResetSelectedClient() {
    this.selectedClient = undefined;
    this.selectedClientIndex = undefined;
    this.clientWasSelected.emit(undefined);
  }

  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$
      .pipe(
        switchMap((user: AppUser | null) => {
          const observables: Observable<AppUser>[] = [];
          if (user) {
            this.user = user;
            if (this.user.clientIds && this.user.clientIds.length > 0) {
              for (const clientid of user.clientIds!) {
                this.clientIdDatabase = user.clientIds as string[];

                observables.push(
                  this.userService.getUserFromDatabase(
                    clientid,
                  ) as Observable<AppUser>,
                );
              }
            }
          }
          return combineLatest(observables);
        }),
      )
      .pipe(
        map((clients: AppUser[]) => {
          return clients
            .filter((client) => client && client.coachId === this.user.id)
            .map((client) => {
              let cl = client as AppUser;
              if (cl.birthdate) cl.birthdate = convertDateObject(cl.birthdate);

              return cl;
            });
        }),
      )
      .subscribe((clients) => {
        for (const client of clients) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            let i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }

        this.displayClients = this.clientDatabase;
      });

    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        }
      });
  }
}
