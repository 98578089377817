import { AuthService } from './auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppUser, Exercise } from '../core/thecoach';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  first,
  forkJoin,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { QuerySnapshot } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ExerciseService {
  user: AppUser | undefined;

  private allExercises$: Observable<Exercise[]> | undefined;
  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
  ) {}

  createNew(exercise: Exercise) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      const id = this.db.createId();
      if (!exercise.id) exercise.id = id;

      exercise.ownerId = this.user.id;

      return this.db
        .collection('exercises')
        .doc(this.user.id)
        .collection<Exercise>('/exercises')
        .doc(exercise.id)
        .set(Object.assign({}, exercise));
    });
  }

  createModified(exercise: Exercise) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      return this.db
        .collection('exercises')
        .doc(this.user.id)
        .collection<Exercise>('/exercises')
        .doc(exercise.id)
        .set(Object.assign({}, exercise));
    });
  }

  ///Old function for Transfer
  isDuplicate(exercise: Exercise, exerciseArray: Exercise[]) {
    return exerciseArray.some(
      (e) =>
        exercise.exerciseName === e.exerciseName &&
        exercise.exerciseDescription === e.exerciseDescription,
    );
  }

  ///Old function for Transfer
  saveAllOldExercisesToNewUser(exercises: Exercise[]) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }

      let newArray: Exercise[] = [];
      for (const entry of exercises) {
        if (!this.isDuplicate(entry, newArray)) {
          newArray.push(entry);
        }
      }
      console.log(newArray);
      newArray.forEach((exercise) => {
        const id = this.db.createId();
        if (!exercise.id) exercise.id = id;

        exercise.ownerId = appUser.id;

        this.db
          .collection('exercises')
          .doc(appUser.id)
          .collection<Exercise>('/exercises')
          .doc(exercise.id)
          .set(Object.assign({}, exercise));
      });

      return;
    });
  }

  exerciseData: Exercise[] = [];
  migrateToTypesense() {
    this.getQueryData().subscribe(() => {
      console.log(this.exerciseData.length);
      /*
      if (this.exerciseData) {
        this.exerciseData.forEach((exercise) => {
          const ex = exercise as Exercise;
          console.log(ex);

          this.db
            .collection('exercises')
            .doc(ex.ownerId)
            .collection('/exercises')
            .doc(ex.id)
            .delete()
            .then(() => {
              this.db
                .collection('exercises')
                .doc(ex.ownerId)
                .collection<Exercise>('/exercises')
                .doc(ex.id)
                .set(Object.assign({}, ex));
            });
        });
      }*/
    });
  }

  getQueryData() {
    return this.db
      .collectionGroup('exercises')
      .get()
      .pipe(
        tap((querySnap) => {
          querySnap.forEach((doc) => {
            const data = doc.data() as Exercise;
            this.exerciseData.push(data);
          });
        }),
      );
  }

  getAll(): Observable<Exercise[]> {
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        this.user = appUser as AppUser;

        return this.db
          .collection('exercises')
          .doc(this.user.id)
          .collection<Exercise>('/exercises')
          .valueChanges({ idField: 'id' });
      }),
    );
  }

  ///Old function for Transfer
  getAllOldExercisesForUser(): Observable<Exercise[]> {
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        this.user = appUser as AppUser;
        console.log(appUser?.id);
        return this.db

          .collection<Exercise>('/exercises')
          .valueChanges({ idField: 'id' });
      }),
    );
  }

  getAllExercisesForUser(): Observable<Exercise[]> {
    if (!this.allExercises$) {
      this.allExercises$ = this.auth.appUser$.pipe(
        switchMap((appUser) => {
          if (!appUser) {
            return of([]);
          }

          return this.db
            .collection('exercises')
            .doc(appUser.id)
            .collection<Exercise[]>('/exercises', (ref) => {
              return ref.where('ownerId', 'in', [appUser.id]);
            })
            .valueChanges({ idField: 'id' });
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }

    return this.allExercises$;
  }

  getAllExercisesPerPageForUser(
    pageSize: number,
    offset: number,
  ): Observable<Exercise[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection('exercises')
          .doc(appUser.id)
          .collection<Exercise[]>('/exercises', (ref) => {
            let query = ref.where('ownerId', 'in', [appUser.id]);

            return query;
          })
          .valueChanges({ idField: 'id' })
          .pipe(map((data) => data.slice(offset, offset + pageSize)));

        /*
        return this.db
          .collection<Exercise[]>('/exercises', (ref) => {
            return ref
              .where('ownerId', 'in', [appUser.id])
              .orderBy('exerciseName', 'asc')
              .limit(pageSize)
              .startAfter(offset);
          })
          .valueChanges({ idField: 'id' });

        */
      }),
    );
  }

  get(exerciseId: string) {
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection('exercises')
          .doc(appUser.id)
          .collection<Exercise>('/exercises')
          .doc(exerciseId)
          .valueChanges();
      }),
    );
  }

  update(exerciseId: string, exercise: Exercise) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      return this.db
        .collection('exercises')
        .doc(this.user.id)
        .collection('/exercises')
        .doc(exerciseId)
        .set(Object.assign({}, exercise));
    });
  }

  delete(exerciseId: string) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      return this.db
        .collection('exercises')
        .doc(this.user.id)
        .collection('/exercises')
        .doc(exerciseId)
        .delete();
    });
  }

  deleteBatch(exercises: Exercise[]) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      if (appUser) {
        const batchSize = 100; // Set the desired batch size
        let batchCounter = 0;
        let batch = this.db.firestore.batch();

        exercises.forEach((e, index) => {
          const exerciseDocRef = this.db
            .collection('exercises')
            .doc(appUser.id)
            .collection('/exercises')
            .doc(e.id).ref;

          if (exerciseDocRef) {
            batch.delete(exerciseDocRef);

            // Commit the batch if it reaches the batchSize or if it's the last document
            if (
              (index + 1) % batchSize === 0 ||
              index === exercises.length - 1
            ) {
              batch
                .commit()
                .then(() => {})
                .catch((error) => {
                  console.error('Error during batch delete:', error);
                });

              // Start a new batch for the next set of documents
              batchCounter++;
              batch = this.db.firestore.batch();
            }
          } else {
            console.warn(
              `Document reference not found for exercise ID: ${e.id}`,
            );
          }
        });

        // Check if there are any remaining operations in the batch and commit them
        if (batchCounter === 0 || exercises.length % batchSize !== 0) {
          batch
            .commit()
            .then(() => {})
            .catch((error) => {
              console.error('Error during final batch delete:', error);
            });
        }
      } else {
        console.warn('App user not found');
      }
    });
  }

  deleteOld(exerciseId: string) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      return this.db.collection('/exercises').doc(exerciseId).delete();
    });
  }
}
