import { weekdays } from './../../core/thecoach';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  WeightEntryData,
  AppUser,
  Macros,
  calculateKcalFromMacros,
  CustomInputType,
  calcuclateDailydifferenceMaster,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
  calculateSevenDayAverageMaster,
} from 'src/app/core/thecoach';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-weightlog-table-with-custom-tracking',
  template: `
    <ng-container *ngIf="clientWeightLog$ | async as logs; else skeleton">
      <div *ngIf="client && client.ctmId && client.coachId">
        <div
          *ngIf="
            this.ctmService.getModelByIdByCoach(client.ctmId, client.coachId)
              | async as customTrackingModel
          "
        >
          <table class="divide-y divide-gray-300 ">
            <thead class="">
              <tr class="divide-x divide-gray-200">
                <th
                  scope="col"
                  class="pt-3.5 pl-4 pr-1 text-center text-sm font-semibold text-gray-900 sm:pl-0 "
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Weight
                </th>
                <th
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Diff.
                </th>
                <th
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Diff.
                </th>
                <th
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  7 Day
                </th>
                <th
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  ROG/L
                </th>

                <th
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  ROG/L
                </th>
                <th
                  scope="col"
                  colspan="4"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Daily Macros
                </th>

                <th
                  *ngIf="client.enableOffDayMacros"
                  scope="col"
                  colspan="4"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Off Day Macros
                </th>

                <th
                  scope="col"
                  colspan="4"
                  class=" pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Achieved Macros
                </th>
                <th
                  *ngFor="let ctv of customTrackingModel.ccts"
                  scope="col"
                  class="pt-3.5 px-1 text-center text-sm font-semibold text-gray-900"
                >
                  <div *ngIf="ctv.name.length <= 8">
                    {{ ctv.name }}
                  </div>

                  <div
                    class=" group flex relative items-center justify-center"
                    *ngIf="ctv.name.length > 8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span
                      class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                    >
                      {{ ctv.name }}</span
                    >
                  </div>
                </th>
              </tr>
              <tr class="divide-x divide-gray-200">
                <th scope="col"></th>
                <th scope="col"></th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  (daily)
                </th>
                <th
                  scope="col"
                  class="whitespace-nowrap px-1 text-center text-sm font-semibold text-gray-900"
                >
                  (from Start)
                </th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Average
                </th>
                <th
                  scope="col"
                  class="px-1 text-center text-sm font-semibold text-gray-900"
                >
                  (daily)
                </th>

                <th
                  scope="col"
                  class="whitespace-nowrap px-1 text-center text-sm font-semibold text-gray-900"
                >
                  (7-Day)
                </th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Protein
                </th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Carbs
                </th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Fat
                </th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  KCAL
                </th>
                <ng-container *ngIf="client.enableOffDayMacros">
                  <th
                    scope="col"
                    class="px-1 text-center text-sm font-semibold text-gray-900"
                  >
                    Protein
                  </th>
                  <th
                    scope="col"
                    class="px-1 text-center text-sm font-semibold text-gray-900"
                  >
                    Carbs
                  </th>
                  <th
                    scope="col"
                    class=" px-1 text-center text-sm font-semibold text-gray-900"
                  >
                    Fat
                  </th>
                  <th
                    scope="col"
                    class="px-1 text-center text-sm font-semibold text-gray-900"
                  >
                    KCAL
                  </th>
                </ng-container>
                <th
                  scope="col"
                  class="px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Protein
                </th>
                <th
                  scope="col"
                  class="px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Carbs
                </th>
                <th
                  scope="col"
                  class=" px-1 text-center text-sm font-semibold text-gray-900"
                >
                  Fat
                </th>
                <th
                  scope="col"
                  class="px-1 text-center text-sm font-semibold text-gray-900"
                >
                  KCAL
                </th>
                <th
                  *ngFor="let ctv of customTrackingModel.ccts"
                  scope="col"
                  class="px-1 text-center text-sm font-semibold text-gray-900"
                >
                  <div>
                    <ng-container
                      *ngIf="ctv.type === CustomInputType.Number"
                      class="flex flex-row"
                    >
                      <p class="">
                        {{ ctv.defaultValue }}
                      </p>
                    </ng-container>

                    <ng-container
                      *ngIf="ctv.type === CustomInputType.Range"
                      class="flex flex-row"
                    >
                      <p class="">{{ ctv.min }}-{{ ctv.max }}</p>
                    </ng-container>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                class="divide-x divide-gray-200"
                *ngFor="let weighEntry of logs; let j = index"
                [ngClass]="{
                  'bg-gray-200': j % 7 === 0
                }"
              >
                <td
                  class="whitespace-nowrap  text-center py-0.5 pl-4 pr-1 text-sm font-medium text-gray-900 sm:pl-0"
                >
                  {{ weighEntry.date | date : 'dd-MM-yyyy' }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500 flex flex-row justify-center"
                >
                  {{
                    weighEntry.weight !== undefined
                      ? (weighEntry.weight | number : '1.1-1')
                      : '-'
                  }}

                  <div
                    class="group flex relative items-center pl-0.5"
                    *ngIf="weighEntry.comment"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 stroke-yellow-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span
                      class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-[20%] translate-y-2 opacity-0 m-4 mx-auto"
                      >{{ weighEntry.comment }}</span
                    >
                  </div>
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{
                    calculateDailydifference(
                      weighEntry.weight!,
                      j,
                      client,
                      logs
                    )
                  }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ calculateDifferenceFromStart(j, client, logs) }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ calculateSevenDayAverage(j, client, logs) }}
                </td>
                <td
                  class="whitespace-nowrap  text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ calculateRoglDaily(weighEntry.weight!, j, client, logs) }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ calculateRoglWeekly(j, client, logs) }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{
                    weighEntry.refeedMacros?.protein ||
                      weighEntry.setMacros?.protein
                  }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{
                    weighEntry.refeedMacros?.carbs ||
                      weighEntry.setMacros?.carbs
                  }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{
                    weighEntry.refeedMacros?.fat || weighEntry.setMacros?.fat
                  }}
                </td>

                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                  *ngIf="weighEntry.refeedMacros; else setMacros"
                >
                  {{ calculateKcal(weighEntry.refeedMacros) }}
                </td>
                <ng-template #setMacros>
                  <td
                    class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                    *ngIf="weighEntry.setMacros"
                  >
                    {{ calculateKcal(weighEntry.setMacros) }}
                  </td>
                </ng-template>

                <ng-container *ngIf="client.enableOffDayMacros">
                  <td
                    class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                  >
                    {{ weighEntry.offDayMacros?.protein || '-' }}
                  </td>
                  <td
                    class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                  >
                    {{ weighEntry.offDayMacros?.carbs || '-' }}
                  </td>
                  <td
                    class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                  >
                    {{ weighEntry.offDayMacros?.fat || '-' }}
                  </td>

                  <td
                    class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                  >
                    {{ calculateKcal(weighEntry.offDayMacros!) }}
                  </td>
                </ng-container>

                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ weighEntry.achivedMacros?.protein || '-' }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ weighEntry.achivedMacros?.carbs || '-' }}
                </td>
                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ weighEntry.achivedMacros?.fat || '-' }}
                </td>

                <td
                  class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
                >
                  {{ calculateKcal(weighEntry.achivedMacros!) }}
                </td>
                <ng-container
                  *ngIf="
                    weighEntry.customTrackingLog &&
                    weighEntry.customTrackingLog.ccts
                  "
                >
                  <td
                    class="whitespace-wrap px-1 text-xs text-gray-500 text-center"
                    *ngFor="let cct of weighEntry.customTrackingLog.ccts"
                  >
                    <ng-container *ngIf="cct.type !== 'boolean'">
                      <ng-container
                        *ngIf="
                          cct.type === CustomInputType.TextLong;
                          else displayOtherValues
                        "
                      >
                        <div
                          class=" group flex relative items-center justify-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                          </svg>
                          <span
                            class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                          >
                            {{ cct.value }}</span
                          >
                        </div>
                      </ng-container>
                      <ng-template #displayOtherValues>{{
                        cct.value
                      }}</ng-template>
                    </ng-container>

                    <ng-container *ngIf="cct.type === 'boolean'">
                      <ng-container *ngIf="cct.value === true">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 stroke-green-500"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </ng-container>
                      <ng-container *ngIf="cct.value === false || !cct.value">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 stroke-red-500"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>

              <!-- More people... -->
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
    <ng-template #skeleton>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>
  `,
  styles: [],
})
export class WeightlogTableWithCustomTrackingComponent implements OnInit {
  CustomInputType = CustomInputType;

  clientWeightLog$: Observable<WeightEntryData[]> | undefined;

  run = true;
  constructor(
    protected ctmService: CustomTrackingModelService,
    private weightLogService: WeightlogService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.fetchWeightLogData();
  }

  private fetchWeightLogData() {
    if (this.client) {
      this.clientWeightLog$ =
        this.weightLogService.getWeightLogDataOfXDaysUpdateDate(
          this.client?.id!,
          this.duration
        );
    }
  }
  ngOnInit(): void {
    this.fetchWeightLogData();
  }

  log(value: any) {
    if (this.run) {
      // console.log(value);
      this.run = false;
    }
  }

  @Input() client!: AppUser | undefined;
  @Input() duration!: number;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  calculateDailydifference(
    weight: number,
    index: number,
    client: AppUser,
    weightLogData: WeightEntryData[]
  ): string {
    return calcuclateDailydifferenceMaster(
      weight,
      index,
      client,
      weightLogData
    );
  }

  calculateDifferenceFromStart(
    index: number,
    client: AppUser,
    weightLogData: WeightEntryData[]
  ): string {
    return calculateDifferenceFromStartMaster(index, client, weightLogData);
  }

  calculateFiveDayAverage(
    index: number,
    client: AppUser,
    weightLogData: WeightEntryData[]
  ): string {
    return calculateFiveDayAverageMaster(index, client, weightLogData);
  }

  calculateSevenDayAverage(
    index: number,
    client: AppUser,
    weightLogData: WeightEntryData[]
  ): string {
    return calculateSevenDayAverageMaster(index, client, weightLogData);
  }

  calculateRoglDaily(
    weight: number,
    index: number,
    client: AppUser,
    weightLogData: WeightEntryData[]
  ): string {
    return calculateRoglDailyMaster(weight, index, client, weightLogData);
  }

  calculateRoglWeekly(
    index: number,
    client: AppUser,
    weightLogData: WeightEntryData[]
  ): string {
    return calculateRoglWeeklyMaster(index, client, weightLogData);
  }
}
