import { MiniMacroRadarComponent } from './../mini-macro-radar/mini-macro-radar.component';
import {
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { isSameDay } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { Subscription, map, of, take, Observable, combineLatest } from 'rxjs';
import {
  AppUser,
  Macros,
  TrainingLog,
  WeightEntryData,
  calculateKcalFromMacros,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { UserService } from 'src/app/services/user.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-coach-dashboard',
  template: `
    <div *ngIf="coach; let user">
      <div>
        <ul
          role="list"
          class="grid  grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2"
        >
          <li
            class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            *ngFor="let clientId of user.clientIds; let i = index"
          >
            <div *ngIf="clients$[clientId] | async as client; else loading">
              <div
                class="flex w-full items-center justify-between space-x-6 p-2"
              >
                <div class="truncate">
                  <div class="flex items-center space-x-3">
                    <h3 class="truncate text-sm font-medium text-gray-900">
                      {{ client.displayName }}
                    </h3>
                    <span
                      class="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                      >{{ client.role }}</span
                    >
                  </div>
                  <p class="mt-1 truncate text-sm text-gray-500">
                    {{ client.email }}
                  </p>
                </div>

                <dl class="mt-1 grid grid-cols-4 gap-2 text-center mr-2">
                  <div
                    class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow "
                  >
                    <dt class="truncate text-sm font-medium text-gray-500">
                      Protein
                    </dt>
                    <dd
                      class="mt-1 text-sm font-semibold tracking-tight text-gray-400"
                    >
                      {{ client.setMacros?.protein }}
                    </dd>
                  </div>

                  <div
                    class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow "
                  >
                    <dt class="truncate text-sm font-medium text-gray-500">
                      Carbs
                    </dt>
                    <dd
                      class="mt-1 text-sm font-semibold tracking-tight text-gray-400"
                    >
                      {{ client.setMacros?.carbs }}
                    </dd>
                  </div>

                  <div
                    class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow "
                  >
                    <dt class="truncate text-sm font-medium text-gray-500">
                      Fat
                    </dt>
                    <dd
                      class="mt-1 text-sm font-semibold tracking-tight text-gray-400"
                    >
                      {{ client.setMacros?.fat }}
                    </dd>
                  </div>

                  <div
                    class="overflow-hidden rounded-lg bg-white px-2 py-1 shadow "
                  >
                    <dt class="truncate text-sm font-medium text-gray-500">
                      KCAL
                    </dt>
                    <dd
                      class="mt-1 text-sm font-semibold tracking-tight text-gray-400"
                    >
                      {{ calculateKcal(client.setMacros!) }}
                    </dd>
                  </div>
                </dl>

                <!--
                <div>
                  <span
                    class=" mx-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-orange-50 px-1.5 py-0.5 text-xs font-medium text-black-700 ring-1 ring-inset ring-orange-600/20"
                    >P: {{ client.setMacros?.protein }}</span
                  >
                  <span
                    class="mx-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-black-700 ring-1 ring-inset ring-green-600/20"
                    >C: {{ client.setMacros?.carbs }}</span
                  >
                  <span
                    class="mx-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-black-700 ring-1 ring-inset ring-blue-600/20"
                    >F: {{ client.setMacros?.fat }}</span
                  >
                  <span
                    class="mx-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-black-700 ring-1 ring-inset ring-gray-600/20"
                    >KCAL : {{ calculateKcal(client.setMacros!) }}</span
                  >
                </div>
-->
                <img
                  class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                  src="{{
                    client.imgUrl ||
                      '.././assets/images/default-person-image.webp'
                  }}"
                  alt=""
                />
              </div>
              <div
                class="flex w-full items-center flex-col justify-between pt-4 pl-2 pb-2 pr-2 "
                *ngIf="weightLogs$[clientId] | async as logs; else loading"
              >
                <div class="flex w-full" *ngIf="logs.length > 1">
                  <div class="whitespace-nowrap  px-4">
                    <div class="flex items-center space-x-3">
                      <h3 class="text-sm font-medium text-gray-900">
                        Last Weight-In:
                      </h3>
                    </div>

                    <div
                      *ngFor="let log of filteredLogs(logs)"
                      class="flex flex-row w-2/5 justify-between"
                    >
                      <p class="mt-1  text-sm text-gray-500">
                        {{ log.date | date : 'dd-MM' }}
                      </p>

                      <p class="mt-1  text-sm text-gray-500 px-1">-</p>

                      <p class="mt-1  text-sm text-gray-500">
                        {{ log.weight }} kg
                      </p>
                    </div>
                  </div>
                  <div class="sm:pl-4 pt-4 w-full overflow-auto">
                    <app-mini-line-chart
                      [logs]="filteredLogs(logs)"
                      [position]="i"
                      [fullContent]="true"
                    ></app-mini-line-chart>
                  </div>
                </div>
                <div
                  class="flex w-full pt-2 flex-col sm:flex-row"
                  *ngIf="checkForAchievedMacros(logs)"
                >
                  <div class="whitespace-nowrap">
                    <div class="flex items-center space-x-3">
                      <h3 class="text-sm font-medium text-gray-900 pl-4">
                        Achieved Macros
                      </h3>
                    </div>

                    <div
                      *ngFor="let log of logs"
                      class="flex flex-row w-2/5 justify-between"
                    >
                      <div
                        *ngIf="log.achivedMacros"
                        class="flex flex-row text-xs"
                      >
                        <p class="mt-1   text-gray-500 px-1">
                          {{ log.date | date : 'dd-MM' }}g
                        </p>
                        <p class="mt-1   text-gray-500 px-1">
                          P: {{ log.achivedMacros.protein }}g
                        </p>

                        <p class="mt-1   text-gray-500 px-1">
                          C: {{ log.achivedMacros.carbs }}g
                        </p>

                        <p class="mt-1   text-gray-500 px-1">
                          F: {{ log.achivedMacros.fat }}g
                        </p>

                        <p class="mt-1   text-gray-500 px-1">
                          KCAL: {{ calculateKcal(log.achivedMacros!) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="sm:pl-4 w-full overflow-auto flex items-center justify-center"
                  >
                    <app-mini-macro-pie
                      class="w-full"
                      [logs]="logs"
                      [position]="i"
                    ></app-mini-macro-pie>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #loading>
              <ngx-skeleton-loader
                count="5"
                appearance="line"
              ></ngx-skeleton-loader>
            </ng-template>
          </li>

          <!-- Display your log data here -->

          <!-- More people... -->
        </ul>
      </div>
    </div>
  `,
  styles: [],
})
export class CoachDashboardComponent implements OnInit, OnDestroy {
  coach: AppUser = {};

  clients$: { [key: string]: Observable<AppUser | undefined> } = {};
  weightLogs$: { [key: string]: Observable<WeightEntryData[] | undefined> } =
    {};

  trainingSessionLog$: {
    [key: string]: Observable<TrainingLog | undefined>;
  } = {};
  weightLogs: Observable<WeightEntryData[] | undefined> | undefined;
  constructor(
    protected auth: AuthService,
    protected userService: UserService,
    private weightLogService: WeightlogService,
    private trainingLogService: LogService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.auth.appUser$.pipe(take(1)).subscribe((appUser) => {
      this.coach = appUser as AppUser;
      this.getLogs(this.coach.clientIds as string[]);
      this.getClients(this.coach.clientIds as string[]);

      //  this.getTrainingLogs(this.coach.clientIds as string[]);
      //  this.clients$ = this.getClients(this.coach.clientIds as string[]);
    });
  }

  getLogs(clientIds: string[]) {
    clientIds.forEach((clientId) => {
      const logCollection = this.weightLogService.getWeightLogDataOfXDaysNew(
        clientId,
        7
      );
      this.weightLogs$[clientId] = logCollection.valueChanges().pipe(
        map((logs) => {
          return logs.map((log) => {
            return {
              ...log,
              date: log['date'].toDate(), // Convert Firestore timestamp to Date object
            };
          });
        })
      );
    });
  }

  getClients(clientIds: string[]) {
    clientIds.forEach((clientId) => {
      const clientDoc =
        this.userService.getUserCollectionFromDatabase(clientId);
      this.clients$[clientId] = clientDoc.valueChanges();
    });
  }

  getTrainingLogs(clientIds: string[]) {
    clientIds.forEach((clientId) => {
      const trainingDoc =
        this.trainingLogService.getTrainingLogCollectionForUser(clientId);
      this.trainingSessionLog$[clientId] = trainingDoc.valueChanges();
    });
  }

  log(val: any) {
    console.log(val);
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  checkForAchievedMacros(logs: WeightEntryData[]): boolean {
    return logs.some((log) => log.achivedMacros);
  }

  filteredLogs(logs: WeightEntryData[]) {
    return logs.filter(
      (item) => item.weight !== undefined && item.weight !== null
    );
  }

  checkIfDiffrentMacrosToday(logs: WeightEntryData[]) {
    if (logs) {
      return logs.find(
        (item) =>
          isSameDay(item.date!, new Date()) && item.setMacros !== undefined
      );

      // this.todayWeightEntry = { ...tempitem };
    }

    return undefined;
  }
}
