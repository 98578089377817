<ng-container *ngIf="auth.appUser$ | async as appUser; else noUser">
  <app-coach-navigation [user]="appUser"
    *ngIf="appUser.role === 'coach' || appUser.role === 'admin'"></app-coach-navigation>

  <app-customer-navigation *ngIf="appUser.role === 'client'"></app-customer-navigation>


  <app-notification *ngIf="message | async as msg" [messageHeader]="msg.notification?.title"
    [messageBody]="msg.notification?.body" [disableBackground]="false" (onbuttonClick)="removeNotification(msg)">
  </app-notification>
</ng-container>

<ng-template #noUser><router-outlet></router-outlet> </ng-template>