import { sub } from 'date-fns';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subscription, first, switchMap, take } from 'rxjs';
import {
  MessagePayload,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MESSAGES_CONTAINER_ID } from '@angular/cdk/a11y';
import { FirebaseApp } from 'firebase/app';
import { FCMTokens } from '../../../../backend/core/thecoach';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private db: AngularFirestore,
    private auth: AuthService
  ) { }

  requestPermission() {
    this.angularFireMessaging.requestPermission.subscribe(
      (value: NotificationPermission) => {
        if (value.includes('granted')) {
          this.getToken();
        } else {
          return;
        }
      }
    );
  }

  checkToken() {
    return this.angularFireMessaging.tokenChanges;
  }

  async getServerTokens() {
    const appUser = await this.auth.appUser$.pipe(first()).toPromise();
    if (!appUser) {
      return [];
    }

    try {
      const data = await this.db
        .collection('fcmTokens')
        .doc(appUser.id)
        .valueChanges()
        .pipe(first())
        .toPromise();
      return (data as { regTokens: FCMTokens[] })?.regTokens || [];
    } catch (error) {
      console.error('Error fetching tokens:', error);
      return [];
    }
  }

  async updateToken(newToken: string) {
    await this.getServerTokens().then((tokens) => {
      // console.log('Tokens:', tokens);

      this.auth.appUser$.pipe(first()).subscribe((appUser) => {
        if (!appUser) return;

        if (!tokens.find((token) => token.token === newToken)) {
          tokens.push({ token: newToken, date: new Date() });
          const data = { regTokens: tokens };

          //console.log('updating data', appUser, data);

          this.db.collection('fcmTokens').doc(appUser.id).set(data);
        }
        //  console.log('no update needed');
      });
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ');
      this.currentMessage.next(payload as any);
    });
  }

  getToken() {
    this.angularFireMessaging.getToken.subscribe(
      (token) => {
        if (token) this.updateToken(token);
        // console.log('requestPermission ', token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
}
