import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomSlotInputTarget } from 'src/app/core/thecoach';

@Component({
  selector: 'app-target-slot',
  template: `
<form [formGroup]="customModelInput">
    <div class="flex flex-col justify-center w-full text-center px-2">
      <p>{{ customModelInput.get('name')?.value }}</p>
      <input
        type="text"
        name="value"
        id="value"
        formControlName="value"
        class="block w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
        [placeholder]="customModelInput.get('name')?.value + ' Value'"
      />
      <p class="text-xs italic text-gray-400">Display only</p>
    </div>
    </form>
  `,
  styles: [],
})
export class TargetSlotComponent {
  @Input({ required: true }) customModelInput!: FormGroup;
  /*export interface CustomSlotInputTarget extends BaseModel {
  value?: string;
  name: string;
  description?: string;
  type: CustomSlotModelInputType.Target;
  slot: number;
}
*/
}
