import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormBuilder, AbstractControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { CustomCheckinFormType, customCheckinInputToggler } from 'src/app/core/thecoach';

@Component({
  selector: 'app-custom-checkin-inputs-creator',
  template: `
<div class="flex w-full flex-col">
<div class="flex w-full justify-around flex-row">
<!-- Text Field Toggler -->

          <div class="">
            <div class="flex flex-row items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showTextInput()"
                *ngIf="!customCheckinInputToggler.textInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showTextInput()"
                *ngIf="customCheckinInputToggler.textInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="font-medium">Text Input</span>
            </div>
          </div>

<!-- Number Field Toggler -->
          <div class="">
            <div class="flex flex-row  items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showNumberInput()"
                *ngIf="!customCheckinInputToggler.numberInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showNumberInput()"
                *ngIf="customCheckinInputToggler.numberInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="font-medium">Number Input</span>
            </div>
          </div>
<!-- Range Field Toggler -->
        <div
          class="flex flex-row w-fit items-center justify-between "
        >
          <!-- Custom Tracking Type: Range -->
          <div class="mb-4">
            <div class="flex items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showRangeInput()"
                *ngIf="!customCheckinInputToggler.rangeInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showRangeInput()"
                *ngIf="customCheckinInputToggler.rangeInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <span class="font-medium">Range Input</span>
            </div>
          </div>
</div>
</div>
<!-- Range Input Form -->
<div>

          <div id="range-input" class="mt-2" *ngIf="customCheckinInputToggler.rangeInputToggle">
            <div class="flex items-center justify-center">
              <form
                [formGroup]="rangeInputForm"
                (ngSubmit)="addRangeToCheckin()"
                class="w-full max-w-md flex flex-row"
              >
                <div class="w-full flex flex-col">
                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        for="name"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Name</label
                      >
                      <input
                        type="text"
                        id="name"
                        formControlName="name"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Name"
                      />
                    </div>
                    <div>
                      <label
                        for="min"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Min</label
                      >
                      <input
                        type="number"
                        id="min"
                        formControlName="min"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Min"
                      />
                    </div>
                    <div>
                      <label
                        for="max"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Max</label
                      >
                      <input
                        type="number"
                        id="max"
                        formControlName="max"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Max"
                      />
                    </div>
                    <div>
                      <label
                        for="value"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Default Value</label
                      >
                      <input
                        type="number"
                        id="value"
                        formControlName="value"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Default Value"
                      />
                    </div>
                  </div>

                  <div class="mt-4">
                    <label class="block text-gray-700 text-sm font-medium mb-1"
                      >Step Size</label
                    >
                    <p class="text-sm text-gray-500 mb-2">
                      Which step size do you prefer?
                    </p>
                    <div
                      class="pt-2 flex flex-row w-full justify-between items-center"
                    >
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="1"
                          checked
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">1.0 - Coarse</span>
                      </label>
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="0.5"
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">0.5 - Medium</span>
                      </label>
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="0.1"
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">0.1 - Fine</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <textarea
                      formControlName="description"
                      rows="2"
                      class="border border-gray-300 px-3 py-2 rounded-md mt-4 w-full"
                      placeholder="Description..."
                    ></textarea>
                  </div>
                </div>
                <div class="pl-4 pt-2 w-fit">
                  <button
                    type="submit"
                    [disabled]="!rangeInputForm.valid"
                    class="mt-4 bg-teal-500 text-white py-2 disabled:opacity-50 px-4 rounded-md flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-4 h-4 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- Text Input -->
                    <div
            id="text-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="customCheckinInputToggler.textInputToggle"
          >
            <form
              [formGroup]="textInput"
              (ngSubmit)="addTextToCheckin()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="shortName"
                    type="text"
                    placeholder="Short Text Input"
                    formControlName="name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!textInput.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 flex disabled:opacity-50 pr-2 flex-row text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>
<!-- number input -->
          <div
            id="number-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="customCheckinInputToggler.numberInputToggle"
          >
            <form
              [formGroup]="numberInput"
              (ngSubmit)="addNumberToCheckin()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="number"
                    type="text"
                    formControlName="name"
                    placeholder="Name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <input
                    id="defaultValue"
                    type="number"
                    placeholder="Enter a default value..."
                    formControlName="defaultValue"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!numberInput.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 flex disabled:opacity-50 flex-row pr-2 text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>
</div>
  `,
  styles: [
  ]
})
export class CustomCheckinInputsCreatorComponent {
  @Input() currentLength!: number;
  @Output() addToArray = new EventEmitter<FormGroup>();

  customCheckinInputToggler: customCheckinInputToggler = {
    textInputToggle: false,
    numberInputToggle: false,
    rangeInputToggle: false
  }


  textInput = this.fb.group({
    slot: [0],
    type: [CustomCheckinFormType.Text],
    name: ['', Validators.required],
    description: ['']
  });

  numberInput = this.fb.group({
    slot: [0],
    type: [CustomCheckinFormType.Number],
    name: ['', Validators.required],
    defaultValue: [''],
    description: ['']
  })

  rangeInputForm = this.fb.group(
    {
      slot: [0],
      type: [CustomCheckinFormType.Range],
      name: ['', [Validators.required]],
      min: ['', [Validators.required]],
      max: ['', [Validators.required]],
      value: ['', [Validators.required]],
      step: [1, [Validators.required]],
      description: [''],
    },
    { validators: this.validateMinMax }
  );

  constructor(private fb: FormBuilder) { }


  validateMinMax(control: AbstractControl): { [key: string]: boolean } | null {
    const min = control.get('min')?.value;
    const max = control.get('max')?.value;
    const value = control.get('value')?.value;

    if (
      (min !== '' &&
        max !== '' &&
        value !== '' &&
        (value < min || value > max)) ||
      min >= max
    ) {
      return { minMaxError: true };
    }

    return null;
  }
  showTextInput() {
    this.customCheckinInputToggler.textInputToggle = !this.customCheckinInputToggler.textInputToggle
    this.customCheckinInputToggler.rangeInputToggle = false;
    this.customCheckinInputToggler.numberInputToggle = false;
  }


  showNumberInput() {
    this.customCheckinInputToggler.numberInputToggle = !this.customCheckinInputToggler.numberInputToggle
    this.customCheckinInputToggler.textInputToggle = false;
    this.customCheckinInputToggler.rangeInputToggle = false;
  }
  showRangeInput() {
    this.customCheckinInputToggler.rangeInputToggle = !this.customCheckinInputToggler.rangeInputToggle
    this.customCheckinInputToggler.textInputToggle = false;
    this.customCheckinInputToggler.numberInputToggle = false;
  }

  addTextToCheckin() {
    this.textInput.get('slot')?.patchValue(this.currentLength);
    this.addToArray.emit(_.cloneDeep(this.textInput))
    this.textInput.reset();
    this.textInput.get('type')?.patchValue(CustomCheckinFormType.Text)
    this.resetInputToggles();
  }

  addNumberToCheckin() {
    this.numberInput.get('slot')?.patchValue(this.currentLength);
    this.addToArray.emit(_.cloneDeep(this.numberInput))
    this.numberInput.reset();
    this.numberInput.get('type')?.patchValue(CustomCheckinFormType.Number);
    this.resetInputToggles();
  }

  addRangeToCheckin() {
    this.rangeInputForm.get('slot')?.patchValue(this.currentLength);
    this.addToArray.emit(_.cloneDeep(this.rangeInputForm))
    this.rangeInputForm.reset()
    this.rangeInputForm.get('type')?.patchValue(CustomCheckinFormType.Range);
    this.resetInputToggles()

  }


  resetInputToggles() {
    this.customCheckinInputToggler.numberInputToggle = false;
    this.customCheckinInputToggler.textInputToggle = false;
    this.customCheckinInputToggler.rangeInputToggle = false;
  }



}
