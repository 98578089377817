import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { convertToParamMap } from '@angular/router';
import { addHours, getHours, addMinutes, getMinutes, addSeconds, getSeconds, format, startOfDay, isBefore, isSameDay } from 'date-fns';
import * as _ from 'lodash';
import { Subscription, first } from 'rxjs';
import { AppUser, Macros, MesoCycle, Specification, TemplateTrainingPlan, TrainingPlan, WeightEntryData, calculateKcalFromMacros, convertDateObject, convertFormToModel } from 'src/app/core/thecoach';
import { TrainingplanService } from 'src/app/services/trainingplan.service';

@Component({
  selector: 'app-coach-specsentry-modal',
  template: `
  <div
    @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
    <div  class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg">
   <div class="flex justify-center flex-col pt-6 items-center">
      <form
        [formGroup]="specFormEntry"
        (ngSubmit)="onSubmit()"
        class="flex flex-col w-full h-full justify-center items-center"
      >
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
          >
            <!-- Heroicon name: outline/check -->
            <img src="../../assets/icons/spec-checked.webp" />
          </div>
          <h3
            class="text-base my-2 font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Specifications for {{ selectedSpecEntry.start | date : 'dd.MM.yyyy' }} - {{ selectedSpecEntry.end | date :'dd.MM.yyyy' }}
          </h3>
          <div class='flex flex-row w-full justify-center items-center'>
           <label class='mr-2' for="trainingDay">Title:</label>
        <input type="text" id="title" formControlName="title" class=" rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm">
</div>
<div class="w-full flex justify-between space-x-4 px-4 mt-4">
  <div class="flex  w-full ">
                  <input
                  (change)="changeStartDate()"
                    type="date"
                    name="start"
                    id="start"
                    formControlName="start"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div class="flex w-full">
                  <input
                  (change)="changeEndDate()"
                    type="date"
                    name="end"
                    id="end"
                    formControlName="end"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  />
                </div></div>
                <div class="relative w-11/12 py-4">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
  <div class="relative flex justify-center items-center">
    <span class="bg-white px-2 text-sm text-gray-500">Message</span>
      <input type="checkbox" id="setMessage" formControlName="setMessage" (change)="toggleMessageInput()"  class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600">
  <span class="bg-white  pr-1 prtext-sm text-gray-500">&nbsp;</span>

  </div>
</div>


  <textarea *ngIf='messageToggler' id="message" formControlName="message"
     rows="5"
   
            class="border-2 border-gray-300 rounded-md text-sm w-5/6 focus:border-teal-500 focus:ring-teal-500 "></textarea>

            <div class="relative w-11/12 py-4">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
  <div class="relative flex justify-center items-center">
    <span class="bg-white px-2 text-sm text-gray-500">Training</span>
      <input type="checkbox" id="setTrainingDay" formControlName="setTrainingDay" (change)="toggleTrainingDayInput()"  class=" h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600">
  <span class="bg-white  pr-1 prtext-sm text-gray-500">&nbsp;</span>
  </div>
</div>

  <div class="flex flex-row w-full justify-center items-center">
</div>




  <div *ngIf="trainingDayToggler" class="flex w-full justify-center items-center">
          <label class="mr-2"for="trainingDay">Training Day </label>
              <div class="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    (click)="toggleTdMenu()"
                    class="inline-flex w-48 justify-center gap-x-1.5 rounded-md bg-white px-1 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                  >
                    {{selectedTd || 'Select Trainingday'}}
                    <svg
                      class="-mr-1 h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>


                <div
                  @opacityScale
                  *ngIf="tdMenu"
                  class="fixed z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <a
                      *ngFor="let tdName of trainingDayNames; let i = index"
                      (click)="setTrainingDay(tdName, i)"
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm hover:cursor-pointer"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      >{{ tdName }}</a
                    >
                  </div>
                </div>
              </div>
              <div
                *ngIf="tdMenu"
                (click)="toggleTdMenu()"
                class="fixed inset-0"
              ></div>

    
      </div>
              <div *ngIf="customTdToggler" class="mt-4 flex flex-row justify-center items-center w-full">
        <label class='mr-2' for="trainingDay">Custom Trainingday</label>
        <input type="text" id="trainingDay" formControlName="trainingDay" class=" rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm">
      </div>

      <div class="relative w-11/12 py-4">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
  <div class="relative flex justify-center items-center">
    <span class="bg-white px-2 text-sm text-gray-500">Macros</span>
      <input type="checkbox" id="setMacros" formControlName="setMacros" (change)="toggleMacrosInput()"  class="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600">
  <span class="bg-white  pr-1 prtext-sm text-gray-500">&nbsp;</span>

  </div>
</div>

    <div *ngIf="macrosToggler">
         <form [formGroup]="macrosForm"> 
          <div class="flex flex-row justify-evenly w-full mx-2">
            <div class="flex flex-col justify-center items-center">
              <span>Protein</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #protein
                  name="protein"
                  formControlName="protein"
                  id="protein"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="protein-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-center items-center">
              <span>Carbs</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #carbs
                  name="carbs"
                  formControlName='carbs'
                  id="carbs"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="carbs-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-center items-center">
              <span>Fat</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  #fat
                  name="fat"
                  formControlName="fat"
                  id="fat"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="fat-input"
                />
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <span class="text-gray-500 " id="unit-suffix">g</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-center items-center ml-2 ">
              <span>Kcal</span>
              <div class="relative mt-1 rounded-md shadow-sm w-16">
                <input
                  type="number"
                  [value]="
                    calculateKcal(macrosForm)
                  "
                  id="kcal"
                  disabled
                  class="block w-full  rounded-md border-gray-300 shadow-sm bg-gray-200 sm:text-sm"
                  placeholder="50,0"
                  aria-describedby="fat-input"
                />
              </div>
            </div>
          </div>
         </form>
        </div>


        <div class="flex flex-row justify-around w-5/6 mt-2 pb-4">
          <button
            [disabled]="!this.specFormEntry.valid || checkForWrongDateSetup()"
            id="btn-save"
            type="submit"
            class="mt-3 inline-flex w-fit disabled:opacity-25 justify-center rounded-md border border-gray-300 bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-400 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Save
          </button>

          <button
            (click)="onCancel(false)"
            type="button"
            id="btn-cancel"
            class="mt-3 inline-flex w-fit justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
    </div>
</div>
  
  `,
  styles: [
  ],
  animations: [

    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class CoachSpecsentryModalComponent implements OnInit, OnDestroy {
  @Input() client!: AppUser;
  @Input() selectedSpecEntry!: Specification;
  @Input() inputStart?: Date;
  @Input() inputEnd?: Date;


  @Output() cancel = new EventEmitter<boolean>();
  @Output() onSaveData = new EventEmitter<Specification>();

  macrosForm = this.fb.group({
    protein: [''],
    carbs: [''],
    fat: ['']
  });

  specFormEntry = this.fb.group({
    id: [''],
    title: ['', Validators.required],
    start: ['', Validators.required],
    end: [''],
    setMessage: [false],
    message: [''],
    setTrainingDay: [false],
    trainingDay: [''],
    setMacros: [false],
    specMacros: this.macrosForm

  })

  macrosToggler = false;
  trainingDayToggler = false;
  tdMenu = false;
  trainingplanSubscription: Subscription | undefined;
  currentTrainingPlan: TrainingPlan | undefined;
  trainingDayNames: string[] = []
  selectedTd: string | undefined;
  customTdToggler = false;
  messageToggler = false;

  constructor(private fb: FormBuilder, private trainingplanService: TrainingplanService) {


  }
  ngOnDestroy(): void {
    this.trainingplanSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.trainingplanSubscription = this.trainingplanService.getAllTrainingplansForClient(this.client).pipe(first()).subscribe(plans => {
      if (plans) {
        plans.forEach(plan => plan.lastEdit = convertDateObject(plan.lastEdit as Date))
        plans.sort((a, b) => b.lastEdit!.getTime() - a.lastEdit!.getTime());
        this.currentTrainingPlan = plans[0]
        this.trainingDayNames = this.getTrainingDaysFromPlan(plans[0])
      }
    })

    this.patchForm(this.selectedSpecEntry)
  }

  patchForm(inputData: Specification) {
    if (inputData.id) {
      this.specFormEntry.get('id')?.setValue(inputData.id)
    }

    if (inputData.title) {
      this.specFormEntry.get('title')?.setValue(inputData.title);
    }

    if (inputData.start) {
      this.specFormEntry.get('start')?.setValue(format(inputData.start, 'yyyy-MM-dd'));
    }



    if (inputData.end) {
      this.specFormEntry.get('end')?.setValue(format(inputData.end, 'yyyy-MM-dd'));
    }

    if (this.inputStart) {
      this.specFormEntry.get('start')?.setValue(format(this.inputStart, 'yyyy-MM-dd'));

    }

    if (this.inputEnd) {
      this.specFormEntry.get('end')?.setValue(format(this.inputEnd, 'yyyy-MM-dd'));

    }

    if (inputData.setMessage) {
      this.messageToggler = !this.messageToggler
      this.specFormEntry.get('setMessage')?.setValue(inputData.setMessage)
      this.specFormEntry.get('message')?.setValue(inputData.message as string)
    }

    if (inputData.setTrainingDay) {
      this.trainingDayToggler = !this.trainingDayToggler
      this.specFormEntry.get('setTrainingDay')?.setValue(inputData.setTrainingDay)
      this.specFormEntry.get('trainingDay')?.setValue(inputData.trainingDay as string)
    }

    if (inputData.setMacros && inputData.specMacros?.protein && inputData.specMacros.carbs && inputData.specMacros.fat) {
      this.macrosToggler = !this.macrosToggler;
      this.specFormEntry.get('setMacros')?.setValue(inputData.setMacros)
      this.specFormEntry.get('specMacros')?.patchValue({
        protein: inputData.specMacros.protein.toString(),
        carbs: inputData.specMacros.carbs.toString(),
        fat: inputData.specMacros.fat.toString()
      });
    }


  }

  toggleTdMenu() {
    this.tdMenu = !this.tdMenu;
  }

  getTrainingDaysFromPlan(plan: TrainingPlan) {
    const tdNames: string[] = [];
    let mesoCycles: MesoCycle[] | undefined;
    if (plan.mesoCycles) {
      mesoCycles = plan.mesoCycles;
    }

    if (mesoCycles) {

      const lastMeso = mesoCycles.pop();

      lastMeso?.trainingDays?.forEach(day => tdNames.push(day.trainingDayName as string))

    }

    tdNames.push('Custom Trainingday')

    return tdNames

  }

  onCancel(val: boolean): void {
    this.cancel.emit(val)
  }

  mapFromToObject(form: FormGroup): Specification {
    const formData = form.value;
    const spec: Specification = {
      start: this.selectedSpecEntry.start || this.inputStart,
      end: this.selectedSpecEntry.end || this.inputEnd,
      title: formData.title,
      id: formData.id,
      isEdit: true,
      setMessage: formData.setMessage,
      message: formData.message,
      setTrainingDay: formData.setTrainingDay,
      trainingDay: formData.trainingDay,
      setMacros: formData.setMacros,
      specMacros: { // Map the values from macrosForm to specMacros
        protein: Number(form.get('specMacros.protein')?.value), // Assuming protein, carbs, and fat are of type number
        carbs: Number(form.get('specMacros.carbs')?.value),
        fat: Number(form.get('specMacros.fat')?.value),
      },
    };

    return spec;
  }


  onSubmit() {
    this.selectedSpecEntry = this.mapFromToObject(this.specFormEntry);
    this.onSaveData.emit(this.selectedSpecEntry)
  }

  toggleMacrosInput() {
    this.macrosToggler = !this.macrosToggler
  }

  toggleTrainingDayInput() {
    if (this.customTdToggler) this.customTdToggler = !this.customTdToggler;
    if (this.trainingDayToggler) this.selectedTd = undefined
    this.trainingDayToggler = !this.trainingDayToggler
  }

  calculateKcal(macrosForm: FormGroup) {
    const macrosValue = macrosForm.value;


    return calculateKcalFromMacros({
      protein: Number(macrosValue.protein), carbs: Number(macrosValue.carbs),
      fat: Number(macrosValue.fat)
    });


  }

  setTrainingDay(tdName: string, index: number) {
    this.selectedTd = tdName;
    this.specFormEntry.get('trainingDay')?.setValue(tdName)

    if (index + 1 === this.trainingDayNames.length) {
      this.specFormEntry.get('trainingDay')?.reset();
      this.customTdToggler = !this.customTdToggler;
    } else {
      if (this.customTdToggler) {
        this.customTdToggler = !this.customTdToggler
      }
    }
    this.toggleTdMenu();
  }

  toggleMessageInput() {
    this.messageToggler = !this.messageToggler
  }

  changeEndDate() {
    this.selectedSpecEntry.end = convertDateObject(this.specFormEntry.get('end')?.value as string)
  }


  changeStartDate() {
    this.selectedSpecEntry.start = convertDateObject(this.specFormEntry.get('start')?.value as string)
  }


  checkForWrongDateSetup() {
    const startDate = convertDateObject(this.specFormEntry.get('start')?.value as string)
    const endDate = convertDateObject(this.specFormEntry.get('end')?.value as string)

    if (isBefore(endDate as Date, startDate as Date)) {
      return true
    } else {
      return false
    }
  }


}
