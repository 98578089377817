import { forEach } from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppUser, Exercise, TemplateTrainingPlan } from '../core/thecoach';
import { Observable, Subscription, first, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateTrainingplanService {
  user: AppUser | undefined;
  constructor(private db: AngularFirestore, private auth: AuthService) { }

  save(trainingPlan: TemplateTrainingPlan) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;
      if (!trainingPlan.id) {
        const id = this.db.createId();
        trainingPlan.id = id;
      }

      trainingPlan.trainingDays?.forEach((day) => {
        if (!day.id) {
          day.id = this.db.createId();

        }

        if (!day.trainingDayId) {
          day.trainingDayId = this.db.createId();
        }

        day.trainingSlots?.forEach(slot => {
          if (!slot.exerciseId) {

            slot.exerciseId = this.db.createId();
          }

        })

      })

      trainingPlan.ownerId = this.user.id;

      const hasUndefined = this.findUndefinedProperties(trainingPlan);
      console.log(`Has undefined property: ${hasUndefined}`);

      return this.db
        .collection('/trainingplantemplates')
        .doc(this.user.id)
        .collection<TemplateTrainingPlan>('/trainingplantemplates')
        .doc(trainingPlan.id)
        .set(Object.assign({}, trainingPlan));
    });
  }

  isUndefined(value: any): boolean {
    return typeof value === 'undefined';
  }

  findUndefinedProperties(obj: any, parentKey?: string): string[] {
    const undefinedProperties: string[] = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const property = obj[key];

        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (this.isUndefined(property)) {
          undefinedProperties.push(currentKey);
        }

        if (typeof property === 'object' && property !== null) {
          const nestedUndefined = this.findUndefinedProperties(property, currentKey);
          undefinedProperties.push(...nestedUndefined);
        }
      }
    }

    return undefinedProperties;
  }

  getAll(): Observable<TemplateTrainingPlan[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }
        return this.db
          .collection('/trainingplantemplates')
          .doc(appUser.id)
          .collection<TemplateTrainingPlan>('/trainingplantemplates')
          .valueChanges({ idField: 'id' });
      })
    );
  }

  get(trainingPlanId: string) {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }
        return this.db
          .collection('/trainingplantemplates')
          .doc(appUser.id)
          .collection<TemplateTrainingPlan>('/trainingplantemplates')
          .doc(trainingPlanId)
          .valueChanges();
      })
    );
  }

  getAllTrainingplansForUser(): Observable<TemplateTrainingPlan[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }

        return this.db
          .collection('/trainingplantemplates')
          .doc(appUser.id)
          .collection<TemplateTrainingPlan[]>(
            '/trainingplantemplates',
            (ref) => {
              return ref.where('ownerId', 'in', [appUser.id]);
            }
          )
          .valueChanges({ idField: 'id' });
      })
    );
  }

  delete(id: string) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }
      return this.db
        .collection('/trainingplantemplates')
        .doc(appUser.id)
        .collection('/trainingplantemplates')
        .doc(id)
        .delete();
    });
  }

  getAllOLD(): Observable<TemplateTrainingPlan[]> {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of([]);
        }
        return this.db
          .collection<TemplateTrainingPlan>('/trainingplantemplates')
          .valueChanges({ idField: 'id' });
      })
    );
  }

  saveOldToNew(trainingPlans: TemplateTrainingPlan[]) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;

      for (const trainingPlan of trainingPlans) {
        if (!trainingPlan.id) {
          const id = this.db.createId();
          trainingPlan.id = id;
        }

        trainingPlan.trainingDays?.forEach((day) => {
          if (!day.id) {
            day.id = this.db.createId();
          }

          if (!day.trainingDayId) {
            day.trainingDayId = this.db.createId();
          }
        })

        trainingPlan.ownerId = this.user.id;
        return this.db
          .collection('/trainingplantemplates')
          .doc(this.user.id)
          .collection<TemplateTrainingPlan>('/trainingplantemplates')
          .doc(trainingPlan.id)
          .set(Object.assign({}, trainingPlan));
      }

      return;
    });
  }

  deleteOld(trainingPlans: TemplateTrainingPlan[]) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) {
        return of([]);
      }

      for (const trainingPlan of trainingPlans) {
        return this.db
          .collection('/trainingplantemplates')
          .doc(trainingPlan.id)
          .delete();
      }
      return;
    });
  }
}
