import { AdminService } from './../services/admin.service';
import { Component, OnInit } from '@angular/core';
import { Observable, catchError, combineLatest, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { AppUser, ClientCheckin, UserRole, downloadObject } from '../core/thecoach';
import { CheckinService } from '../services/checkin.service';
import { FileService } from '../services/file.service';
import { UserService } from '../services/user.service';
import { first } from 'lodash';


@Component({
  selector: 'app-admin-user-view',
  template: `
  <div *ngIf="users$ | async as users">
  

<div>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm">
      <option selected>Admins</option>
      <option>Coaches</option>
      <option >Clients</option>
    </select>
  </div>
  
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <!-- Current: "border-teal-500 text-teal-600", Default: "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700" -->
        <div (click)="onGroupSelect(userRoles.Admin)" [ngClass]="{'border-teal-500 text-teal-600': selectedGroup === userRoles.Admin,'border-transparent text-gray-900':selectedGroup !== userRoles.Admin}"class=" cursor-pointer hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
 
         Admins
        <!-- Current: "bg-teal-100 text-teal-600", Default: "bg-gray-100 text-gray-900" -->

          <span [ngClass]="{'bg-teal-100 text-teal-600':selectedGroup === userRoles.Admin, 
      'bg-gray-100 text-gray-900': selectedGroup !== userRoles.Admin
        }"class=" ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{getCustomersOnRole(users,userRoles.Admin).length}}</span>
        </div>
        <div (click)="onGroupSelect(userRoles.Coach)" [ngClass]="{'border-teal-500 text-teal-600': selectedGroup === userRoles.Coach,'border-transparent text-gray-900':selectedGroup !== userRoles.Coach}"class=" cursor-pointer hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
        Coaches  
        <span [ngClass]="{'bg-teal-100 text-teal-600':selectedGroup === userRoles.Coach, 
      'bg-gray-100 text-gray-900': selectedGroup !== userRoles.Coach
      }"class=" ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{getCustomersOnRole(users,userRoles.Coach).length}}</span>
        </div>
        <div (click)="onGroupSelect(userRoles.Client)" [ngClass]="{'border-teal-500 text-teal-600': selectedGroup === userRoles.Client,'border-transparent text-gray-900':selectedGroup !== userRoles.Client}"class="cursor-pointer hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
        Clients  
        <span [ngClass]="{'bg-teal-100 text-teal-600':selectedGroup=== userRoles.Client, 
      'bg-gray-100 text-gray-900': selectedGroup !== userRoles.Client
      }"class=" ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{getCustomersOnRole(users,userRoles.Client).length}}</span>
        </div>
           <div (click)="onGroupSelect(undefined)" [ngClass]="{'border-teal-500 text-teal-600': selectedGroup === undefined,'border-transparent text-gray-900':selectedGroup !== undefined}"class="cursor-pointer hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
        Unknown
        <span [ngClass]="{'bg-teal-100 text-teal-600':selectedGroup=== undefined,
      'bg-gray-100 text-gray-900': selectedGroup !== undefined
      }"class=" ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{getCustomersOnRole(users,undefined).length}}</span>
        </div>

      </nav>
    </div>
  </div>
</div>


<div *ngIf="selectedGroup">
  <div *ngIf="selectedGroup === userRoles.Coach || selectedGroup === userRoles.Admin">
      <ul role="list" class="mx-auto mt-4 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-2 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-5">
      <li *ngFor="let user of getCustomersOnRole(users,selectedGroup)" (click)="onSelectUser(user)" class="cursor-pointer">
        <img class="mx-auto h-12 w-12 rounded-full" src="{{user.imgUrl  || '.././assets/images/default-person-image.webp'}}" alt="">
        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">{{user.displayName}}</h3>
        <p class="text-xs italic text-gray-600">{{user.id}}</p>
        <p class="text-xs italic text-gray-600">{{user.email}}</p>
      </li>
      <!-- More people... -->
    </ul>
</div>

<div *ngIf="selectedGroup === userRoles.Client">
      <ul role="list" class="mx-auto mt-4 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-2 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-5">
      <li *ngFor="let user of getCustomersOnRole(users,selectedGroup)" (click)="onSelectUser(user)" class="cursor-pointer">
        <img class="mx-auto h-12 w-12 rounded-full" src="{{user.imgUrl  || '.././assets/images/default-person-image.webp'}}" alt="">
        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">{{user.displayName}}</h3>
        <p class="text-xs italic text-gray-600">{{user.email}}</p>
        <div *ngIf="user.coachId && getUserById(users, user.coachId) as coach; else noCoach">
           <p class="text-xs italic text-gray-600">{{user.id}}</p>
      <p class="text-xs italic text-gray-600">Coach: {{coach.displayName}}</p>
        </div>
        <ng-template #noCoach>
          <div class="flex flex-row w-full justify-center text-center items-center">
      <p class="text-xs italic text-gray-600">Coach: </p>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-red-600">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
</svg>
    </div>

        </ng-template>

     
      </li>
      <!-- More people... -->
    </ul>
</div>
    </div>
<div *ngIf="selectedUser" class="pt-4">

<div class="relative">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
  <div class="relative flex justify-center">
    <span class="bg-white px-2 text-sm text-gray-500">Selected User: {{selectedUser.displayName}}</span>
  </div>
</div>

<div class="flex pt-4">
  <div class="mr-4 flex-shrink-0">
     <img class="mx-auto h-12 w-12 rounded-full" src="{{selectedUser.imgUrl  || '.././assets/images/default-person-image.webp'}}" alt="">
       </div>
    <div class="w-full">
    <div class="flex flex-row items-center justify-between w-full">
          <div>
    <h4 class="text-lg font-bold">{{selectedUser.displayName}}</h4>
  <p class="text-xs leading-6 text-gray-600">{{selectedUser.email}}</p>
  </div>
  <div class="overflow-hidden bg-white shadow-sm shadow-gray-700 sm:rounded-lg w-fit mx-4">
    <div class="w-fit flex justify-between items-center" (click)="openDetails = !openDetails">
      <p  *ngIf="!openDetails" class="p-4 text-xs text-gray-600">Show Details</p>
       <p  *ngIf="openDetails" class="p-4 text-xs text-gray-600">Close Details</p>

       <svg *ngIf="!openDetails" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 m-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
</svg>

<svg *ngIf="openDetails" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 m-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
</svg>


    </div>
    </div>
    </div>
      <app-admin-user-form *ngIf="openDetails" [user]="selectedUser" (closeUser)="onUserClose($event)" ></app-admin-user-form>

    <ul>
    <li class="flex flex-col items-center justify-between w-full" *ngFor="let clientId of selectedUser.clientIds; let i = index">
  
      <div class="flex flex-row w-full justify-between items-center">
          <div class="mt-6 flex flex-col" *ngIf="getUserById(users,clientId) as user">
          <div class="mr-4 flex-shrink-0">
           <img class="h-12 w-12 rounded-full" src="{{user.imgUrl  || '.././assets/images/default-person-image.webp'}}" alt="">
   
            </div>
            <div>
            <h4 class="text-lg font-bold">{{user.displayName}}</h4>
              <p class="text-xs leading-6 text-gray-600">{{user.id}}</p>
            <p class="text-xs leading-6 text-gray-600">{{user.email}}</p>
            </div>
          </div>
    
      <div class="overflow-hidden bg-white shadow-sm shadow-gray-700 sm:rounded-lg w-fit mx-4">

     <div class="w-fit flex justify-between items-center" *ngIf="clientId" (click)="selectedUserClientsToggle[i] = !selectedUserClientsToggle[i]; onLoadSubUser(getUserById(users,clientId))">
     <p  *ngIf="!selectedUserClientsToggle[i]" class="p-4 text-xs text-gray-600">Show Client Details</p>
       <p  *ngIf="selectedUserClientsToggle[i]" class="p-4 text-xs text-gray-600">Close Client Details</p>

       <svg *ngIf="!selectedUserClientsToggle[i]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 m-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
          </svg>

           <svg *ngIf="selectedUserClientsToggle[i]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 m-4">
         <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
       </svg>
     </div>

  </div> </div>
<app-admin-user-form (closeUser)="onUserClose($event)" *ngIf="selectedUserClientsToggle[i] && subUser" [user]="subUser"></app-admin-user-form>
   </li>
  </ul>
  </div>
</div>
</div>
  `,
  styles: [
  ]
})
export class AdminUserViewComponent implements OnInit {
  userRoles = UserRole
  selectedGroup: UserRole | undefined = UserRole.Admin;
  selectedUser: AppUser | undefined;
  selectedUserClientsToggle: boolean[] = [];
  subUser: AppUser | undefined;
  openDetails: boolean = false;
  users$: Observable<AppUser[] | undefined> | undefined;
  constructor(private adminService: AdminService,
    private userService: UserService,
    private fileService: FileService,
    private checkinService: CheckinService) {
    this.users$ = this.adminService.getAllUsers();
  }
  ngOnInit(): void {

  }

  getUserById(users: AppUser[], userid: string): AppUser | undefined {
    return users.find(user => user.id === userid)
  }

  onLoadSubUser(user: AppUser | undefined): void {
    //console.log(user)
    if (this.subUser === user) {
      this.subUser = undefined;
      if (this.selectedUser && this.selectedUser.clientIds) {
        this.selectedUserClientsToggle = Array.from({ length: this.selectedUser.clientIds.length }, () => false)

      }
    } else {
      this.subUser = user;
    }
  }

  onSelectUser(user: AppUser) {

    if (this.selectedUser === user) {
      this.selectedUser = undefined;
      if (user.clientIds) {
        this.selectedUserClientsToggle = Array.from({ length: user.clientIds.length }, () => false)
      }
    } else {
      this.selectedUser = user;
      this.selectedUserClientsToggle

    }


  }


  onGroupSelect(userRole: UserRole | undefined) {
    this.openDetails = false;
    this.selectedUserClientsToggle = [];
    this.selectedUser = undefined
    this.selectedGroup = userRole

  }
  getCustomersOnRole(users: AppUser[], role: UserRole | undefined) {
    let customer: AppUser[] = [];
    users.forEach(user => {
      if (user.role === role) {
        customer.push(user)
      }
    })
    return customer


  }

  onUserClose(val: boolean) {

    if (val) {

      if (this.selectedUser && this.selectedUser.clientIds) {

        this.selectedUserClientsToggle = Array.from({ length: this.selectedUser.clientIds.length }, () => false)
      }
      this.subUser = undefined;
      this.openDetails = false;
    }
  }



  //THIS IS LEGACY IMG FIXING - DONT USE THIS ANYMORE
  //LOT OF ERRORS BUT IT HAS DONE ITS JOB!
  /**
    allclients: AppUser[] = [];
    allCheckins: ClientCheckin[] = [];
    getAllClients() {
      this.userService.getAllClientsFromDatabase().subscribe(clients => {
        this.allclients = clients
        console.log(this.allclients);
      })
    }
  
    getAllCheckins() {
      this.allclients.forEach(client => {
        this.checkinService.getAllClientCheckins(client.id as string).subscribe(checkins => {
          checkins.forEach(checkin => {
            this.allCheckins.push(checkin)
          })
  
          console.log(this.allCheckins)
        })
  
      })
    }
  
    modifyFiles() {
      this.fileService.getAllFilesOfClient(this.allclients[0].id!).subscribe((files) => {
        console.log(files)
        if (files) {
          console.log('files:', files)
        }
      })
    }
  
    getAllCheckinsMaster() {
      this.userService
        .getAllClientsFromDatabase()
        .pipe(
          switchMap((clients) => {
            const observables = clients.map((client) => {
              //console.log(client)
              return this.checkinService.getAllClientCheckins(client.id as string);
            });
            //  console.log(observables)
            return combineLatest(observables);
          }),
          map((checkins) => {
            // console.log(checkins)
  
            return checkins.flat()
          }) // Flatten the nested arrays
        )
        .subscribe(
  
          (allCheckins) => {
            console.log('All check-ins:', allCheckins);
            // Do something with allCheckins
  
  
            this.allCheckins = allCheckins;
  
          },
          (error) => {
            console.error('Error:', error);
          }
        );
    }
  
  
    async modifyAllClientsAllCheckins() {
      if (this.allclients) {
        for (let index = 0; index < this.allclients.length; index++) {
          const client = this.allclients[index];
          console.log('UPDATING: ' + (index + 1) + '/' + this.allclients.length);
          await this.modifyAllCheckins(client.id as string);
        }
        console.log("Update Done");
      }
    }
  
    async modifyAllCheckins(clientId: string) {
      // console.log("Modifying all checkins", checkin)
      if (!clientId) return;
      try {
        //   console.log("trying");
        this.checkinService.getAllClientCheckins(clientId)
          .pipe(
            mergeMap(checkins => {
              if (!checkins || checkins.length === 0) {
                return [];
              }
  
              const observables = checkins.map(async checkin => {
  
                if (checkin.imgUrls) {
                  const updatedUrls = await Promise.all(
                    checkin.imgUrls.map(async url => {
                      return this.getNewFileUrl(url as unknown as string, checkin.clientId!);
                    })
                  );
  
                  checkin.imgUrls = updatedUrls;
                  //  console.log(checkin.imgUrls);
                  return this.checkinService.updateLegacyImgUrls(checkin);
                } else { return }
              });
  
              return forkJoin(observables);
            })
          )
          .subscribe(
            () => {
              console.log("All check-ins updated");
            },
            error => {
              console.error(error);
            }
          );
      } catch (error) {
        console.error(error);
      }
  
  
    }
  
    async getNewFileUrl(fileName: string, clientId: string) {
      try {
        //  console.log(fileName)
        const subName = (fileName as unknown as string)
          .substring((fileName as unknown as string)
            .lastIndexOf("%") + 3, (fileName as unknown as string)
              .lastIndexOf("?"))
  
        const url = await this.fileService.getNewFileDownloadURL(subName, clientId);
        return url
        console.log(url);
      } catch (error) { console.error(error); }
    }
    **/
}