import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-voice-player',
  template: `
    <div
      *ngIf="!iOS()"
      class="flex items-center bg-[#F1F3F4] rounded-l-full rounded-r-full w-fit"
    >
      <div class="relative w-[220px] overflow-hidden  rounded-r-full ">
        <audio [id]="playerName" controls>
          <source [src]="audioBlobUrl" type="audio/mp3" />
        </audio>
      </div>
      <div class="mr-2">
        <button
          class="rounded-full bg-[#F1F3F4] p-2 hover:bg-[#E5E7E8]"
          (click)="
            increasePlayback();
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          x{{ this.speedValues[this.i] }}
        </button>
      </div>
    </div>

    <div
      *ngIf="iOS()"
      class="flex items-center fit rounded-l-full rounded-r-full w-fit"
    >
      <div class="relative w-[80%] overflow-hidden  rounded-r-lg ">
        <audio
          [id]="playerName"
          controls
          controlslist="nodownload noplaybackrate noremoteplayback"
        >
          <source [src]="audioBlobUrl" type="audio/mp3" />
        </audio>
      </div>
      <div class="mr-2">
        <button
          class="rounded-full text-gray-200 bg-[#818282] ml-1 p-1 hover:bg-[#5f5e5e]"
          (click)="
            increasePlayback();
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          x{{ this.speedValues[this.i] }}
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class VoicePlayerComponent {
  @Input() audioBlobUrl!: SafeUrl | string;

  randomNum: number = Math.random();
  playerName: string = uuidv4();

  constructor(private sanitizer: DomSanitizer) {
    // this.playerName = this.playerName + '-' + this.randomNum.toString();

    if (typeof this.audioBlobUrl === 'string') {
      console.log('string conversion');
      this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(
        this.audioBlobUrl
      );
    }
  }

  i = 0;

  speedValues = [1.0, 1.5, 2.0, 2.5];
  iOSspeedValues = [1.0, 1.25, 1.5, 2];

  increasePlayback() {
    let vid = document.getElementById(this.playerName) as HTMLAudioElement;
    this.i++;
    if (this.i > this.speedValues.length - 1) {
      this.i = 0;
    }

    vid.playbackRate = this.speedValues[this.i];
    vid.defaultPlaybackRate = this.iOSspeedValues[this.i];
  }

  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
}
