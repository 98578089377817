import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { AppUser, CalendarEventData, convertDateObject } from '../core/thecoach';
import {
  Observable,
  combineLatest,
  first,
  forkJoin,
  map,
  merge,
  mergeAll,
  of,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  user: AppUser | undefined;
  constructor(private db: AngularFirestore, private auth: AuthService) { }

  saveNewCalendarEntry(entry: CalendarEventData) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (appUser) {
        const dateFormat = format(entry.start!, 'yyyy-MM-dd');

        if (!entry.id) entry.id = this.db.createId(); // Generate a unique ID

        return this.db
          .collection('calendars')
          .doc(appUser.id)
          .collection<CalendarEventData>('entries')
          .doc(entry.id)
          .set(Object.assign({}, entry)).then(() => { }); // Use `add` to create a new document
      } else {
        return of(null);
      }
    });
  }
  saveNewCalendarEntryForUser(entry: CalendarEventData, user: AppUser) {

    if (!entry.id) entry.id = this.db.createId(); // Generate a unique ID

    return this.db
      .collection('calendars')
      .doc(user.id)
      .collection<CalendarEventData>('entries')
      .doc(entry.id)
      .set(Object.assign({}, entry)).then(() => { }); // Use `add` to create a new document
  }


  updateCalendarEntry(entry: CalendarEventData) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (appUser) {
        const dateFormat = format(entry.start!, 'yyyy-MM-dd');

        return this.db
          .collection('calendars')
          .doc(appUser.id)
          .collection('entries')
          .doc(entry.id)
          .set(Object.assign({}, entry), { merge: false });
      } else {
        return of(null);
      }
    });
  }

  updateCalendarEntryForUser(entry: CalendarEventData, user: AppUser) {

    return this.db
      .collection('calendars')
      .doc(user.id)
      .collection('entries')
      .doc(entry.id)
      .set(Object.assign({}, entry), { merge: false });
  }

  deleteCalendarEntry(entry: CalendarEventData) {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      if (!appUser) return of([]);

      if (appUser) {
        const dateFormat = format(entry.start!, 'yyyy-MM-dd');

        if (entry.id) {


          // Use the object's ID to delete the corresponding document
          return this.db
            .collection('calendars')
            .doc(appUser.id)
            .collection('entries')
            .doc(entry.id)
            .delete();
        } else {
          return of(null);
        }
      } else {
        return;
      }
    });
  }

  deleteCalendarEntryForUser(entry: CalendarEventData, user: AppUser) {
    if (entry.id) {


      // Use the object's ID to delete the corresponding document
      return this.db
        .collection('calendars')
        .doc(user.id)
        .collection('entries')
        .doc(entry.id)
        .delete();
    } else {
      return of(null);
    }
    return;
  }



  getCalendarEntriesPerDay(day: Date) {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) return of([]);

        const dateFormat = format(day, 'yyyy-MM-dd');

        return this.db
          .collection('calendars')
          .doc(appUser.id)
          .collection<CalendarEventData>('entries')
          .valueChanges();
      })
    );
  }

  getCalendarForUserBetweenDates(userId: string, startDate: Date, endDate: Date) {
    //  console.log('Getting Calendar')
    // Create two separate observables, one for end >= startDate and one for start <= endDate
    // Create two separate observables, one for end >= startDate and one for start <= endDate
    const query1 = this.db
      .collection('calendars')
      .doc(userId)
      .collection<CalendarEventData>('entries', ref =>
        ref.orderBy('end', 'desc')
          .where('end', '>=', startDate)
      )
      .valueChanges()
    // Limit to one emission to avoid duplicates

    const query2 = this.db
      .collection('calendars')
      .doc(userId)
      .collection<CalendarEventData>('entries', ref =>
        ref.orderBy('start', 'desc')
          .where('start', '>=', startDate)
          .where('start', '<=', endDate)
      )
      .valueChanges()
    // Limit to one emission to avoid duplicates

    // Combine the results of the two queries and remove duplicates
    return combineLatest([query1, query2]).pipe(
      map(([result1, result2]) => {
        const mergedResults = result1.concat(result2);

        // Use a Set to track unique event IDs
        const uniqueEventIds = new Set();
        const filteredEntries = mergedResults.filter(entry => {
          if (uniqueEventIds.has(entry.id)) {
            // If the entry ID is already in the Set, it's a duplicate; exclude it
            return false;
          }
          // Otherwise, add the entry ID to the Set and include the entry
          uniqueEventIds.add(entry.id);
          if (entry.start) entry.start = convertDateObject(entry.start);
          if (entry.end) entry.end = convertDateObject(entry.end);
          return true;
        });

        return filteredEntries;
      })
    );
  }




}
