import { Component, Input } from '@angular/core';
import { Macros, calculateKcalFromMacros } from 'src/app/core/thecoach';

@Component({
  selector: 'app-show-off-day-macros-module',
  template: `
    <dl class="mt-1 grid grid-cols-4  gap-2 text-center">
      <div class="col-span-4">
        <h3 class="font-semibold text-gray-900">Off-Day Macros</h3>
      </div>
      <div class="overflow-hidden rounded-lg bg-gray-50 px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">Protein</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ offDayMacros.protein }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-gray-50 px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">Carbs</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ offDayMacros.carbs }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-gray-50 px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">Fat</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ offDayMacros.fat }}
        </dd>
      </div>

      <div class="overflow-hidden rounded-lg bg-gray-50 px-2 py-1 shadow ">
        <dt class="truncate text-sm font-medium text-gray-500">KCAL</dt>
        <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-400">
          {{ calculateKcal(offDayMacros) }}
        </dd>
      </div>
    </dl>
  `,
  styles: [],
})
export class ShowOffDayMacrosModuleComponent {
  @Input() offDayMacros!: Macros;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }
}
