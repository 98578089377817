import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  CustomSlotInputNumber,
  CustomSlotInputTime,
  CustomSlotModelInputType,
  CustomSlotModelInputValue,
  SlotModel,
  isModelInputType,
} from 'src/app/core/thecoach';
import { ModelService } from 'src/app/services/trainingplans/model.service';

@Component({
  selector: 'app-model-dashboard',
  template: `
    <div class="py-4 flex w-full flex-row justify-between items-center">
      <div class="w-10/12 flex flex-col ">
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Model Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            class="block w-6/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Model Name..."
          />
        </div>
      </div>
      <button
        type="button"
        [routerLink]="'/model-generator/new'"
        class="rounded-md bg-teal-600 whitespace-nowrap h-fit w-fit px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
      >
        New Model
      </button>
    </div>

    <div *ngIf="modelService.currentModels$ | async as currentModels">
      <ul role="list" class="space-y-3">
        <li
          *ngFor="let model of currentModels"
          class="flex flex-row justify-between items-center overflow-hidden w-full rounded-md bg-white px-6 py-4 shadow-md"
        >
          <div>
            <p class="text-md font-semibold text-gray-900">
              {{ model.name }}
            </p>
          </div>
          <div class="flex flex-row">
            <div *ngFor="let customInput of model.customInput.csmiv">
              <div class="flex flex-col justify-center text-center">
                <p class="text-gray-400 italic text-xs pr-2">
                  {{ customInput.name }} {{ customInput.type }},
                </p>
                <div
                  class="text-gray-400  text-xs pr-4"
                  *ngIf="isInputType(customInput.type)"
                >
                  <p *ngIf="isIncludedCalc(customInput)">🔢✅</p>
                  <p *ngIf="!isIncludedCalc(customInput)">🔢❌</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center">
            <p class="text-xs text-gray-600">Progress Direction</p>
            <p *ngIf="!model.customInput.progressDirection">📈</p>
            <p *ngIf="model.customInput.progressDirection">📉</p>
          </div>

          <div class="flex flex-row justify-center items-center space-x-2">
            <button
              type="button"
              class="rounded-md bg-teal-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              (click)="onEditModel(model)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </button>
            <div>
              <button
                type="button"
                class="rounded-md bg-red-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                (click)="onDeleteModel(model)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </div>
          </div>
        </li>

        <!-- More items... -->
      </ul>
    </div>
    <div *ngIf="editModalToggler">
      <app-simple-modal
        [title]="'Edit Model?'"
        [message]="
          'If you Edit this Model, changes may break older Logs.<br>Consider creating a new Model'
        "
        [positiveButtonText]="'Edit'"
        [negativeButtonText]="'Cancel'"
        (close)="onEditModalResult($event)"
      ></app-simple-modal>
    </div>
  `,
  styles: [],
})
export class ModelDashboardComponent {
  constructor(
    protected modelService: ModelService,
    private router: Router,
  ) {}

  editModalToggler = false;
  selectedModel: SlotModel | undefined = undefined;

  toggleEditModal() {
    this.editModalToggler = !this.editModalToggler;
  }

  onDeleteModel(model: SlotModel) {
    this.modelService.deleteModel(model);
  }

  onEditModalResult(val: boolean) {
    if (val && this.selectedModel) {
      this.router.navigate(['model-generator/', this.selectedModel.id]);
    }

    this.selectedModel = undefined;
    this.toggleEditModal();
  }

  onEditModel(model: SlotModel) {
    this.selectedModel = model;
    this.toggleEditModal();
  }

  isInputType(type: CustomSlotModelInputType) {
    return isModelInputType(type);
  }

  isIncludedCalc(input: CustomSlotModelInputValue) {
    if (this.isInputType(input.type)) {
      return (input as CustomSlotInputNumber | CustomSlotInputTime).includeCalc;
    }

    return false;
  }
}
