import { CustomTrackingModelService } from './../../services/custom-tracking-model.service';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { th } from 'date-fns/locale';
import * as _ from 'lodash';
import { Observable, first } from 'rxjs';
import {
  CustomInputType,
  CustomTrackingModel,
  CustomTrackingValue,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-custom-tracking',
  template: `
    <div class="py-4 flex flex-row justify-between">
      <div class="w-10/12 flex flex-col ">
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Model Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Model Name..."
          />
        </div>
      </div>
      <div class="flex items-center">
        <button
          type="button"
          *ngIf="!newModelToggle"
          (click)="onNewModel()"
          class="rounded-md bg-teal-600 whitespace-nowrap h-fit w-fit px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        >
          Create New Model
        </button>
        <button
          *ngIf="newModelToggle"
          type="button"
          (click)="onNewModel()"
          class="rounded-md bg-red-600 px-2.5 h-fit w-fit py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Cancel
        </button>
      </div>
    </div>

    <div class="w-full border-t border-gray-300 py-4"></div>

    <div class="max-w-3xl mx-auto flex flex-col text-center">
      <!-- Display the Custom Tracking Model -->

      <div *ngIf="!newModelToggle" class="flex w-full">
        <div
          *ngIf="customTrackingModels$ | async as customTrackingModels"
          class="w-full  px-4 mb-8"
        >
          <div
            *ngIf="customTrackingModels.length > 0"
            class="max-w-3xl mx-auto p-4 shadow-lg bg-gray-50"
          >
            <div
              *ngFor="
                let model of customTrackingModels
                  | filterSearchModels : searchNameValue;
                let i = index
              "
              class="shadow-md p-4 mb-8 rounded-md bg-white"
            >
              <div class="">
                <div class="flex flex-row justify-between">
                  <h2
                    class="text-md flex flew-row font-bold w-fit whitespace-nowrap"
                  >
                    {{ model.name }}
                  </h2>
                  <div
                    class="text-gray-600  text-xs italic flex flex-row items-center px-4 "
                  >
                    <p class="line-clamp-3">
                      {{ model.description }}
                    </p>
                  </div>
                  <div
                    class="flex flex-row justify-center items-center space-x-2"
                  >
                    <button
                      type="button"
                      class="rounded-md bg-teal-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      (click)="onEditModel(model)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                    <div>
                      <button
                        type="button"
                        class="rounded-md bg-red-600 flex flex-row items-center px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        (click)="onDeleteModel(model)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      class="flex text-gray-500 items-center border rounded-md p-1.5 cursor-pointer hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      (click)="onOpenModel(model)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedCustomTrackingModel === model">
                  <h3 class="text-lg font-bold my-2">
                    Custom Tracking Values:
                  </h3>
                  <ul class="flex flex-col" *ngIf="selectedCustomTrackingModel">
                    <li
                      *ngFor="let cct of selectedCustomTrackingModel.ccts"
                      class="mb-4 flex flex-row justify-between"
                    >
                      <div class="flex-flex-col justify-start text-left">
                        <p class="text-gray-500 text-sm mb-1">
                          Name: {{ cct.name }}
                        </p>
                        <p class="text-gray-500 text-sm mb-1">
                          Type: {{ getCustomTrackingTypeName(cct.type) }}
                        </p>
                      </div>

                      <div class="flex flex-col text-right justify-end">
                        <div
                          class="text-gray-500 text-sm mb-1 flex flex-row items-center justify-end"
                        >
                          Description:
                          <div class=" ml-1 group flex relative items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                              />
                            </svg>
                            <span
                              class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
                          -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                              >{{ cct.description }}</span
                            >
                          </div>
                        </div>

                        <div class="flex flex-row text-right justify-end">
                          <ng-container
                            *ngIf="cct.type === CustomInputType.Range"
                            class="flex flex-row"
                          >
                            <p class="text-gray-500 text-sm mb-1 mx-1">
                              Min: {{ cct.min }}
                            </p>
                            <p class="text-gray-500 text-sm mb-1 mx-1">
                              Max: {{ cct.max }}
                            </p>
                            <p class="text-gray-500 text-sm mb-1 mx-1">
                              Step: {{ cct.step }}
                            </p>
                          </ng-container>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="newModelToggle">
        <div>
          <form
            [formGroup]="model"
            (ngSubmit)="saveModel()"
            class="w-full mt-2 flex flex-row justify-center"
          >
            <input
              type="text"
              placeholder="Name"
              formControlName="name"
              class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
            />

            <textarea
              formControlName="description"
              rows="2"
              placeholder="Description..."
              class="border border-gray-300 mx-2 py-1 rounded-md w-11/12 "
            ></textarea>

            <button
              *ngIf="CCTS.controls.length > 0"
              type="sumbit"
              class="bg-teal-500 ml-3 text-white py-1 rounded-md  items-center text-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 m-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                />
              </svg>
            </button>
          </form>
        </div>

        <div
          class="flex flex-row w-full items-center justify-between mt-4"
          *ngIf="newModelToggle"
        >
          <!-- Custom Tracking Type: Range -->
          <div class="mb-4">
            <div class="flex items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showRangeInput()"
                *ngIf="!rangeInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showRangeInput()"
                *ngIf="rangeInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <span class="font-medium">Range Input</span>
            </div>
          </div>

          <!-- Custom Tracking Type: Text Short -->
          <div class="mb-4">
            <div class="flex items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showShortTextInput()"
                *ngIf="!textShortInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showShortTextInput()"
                *ngIf="textShortInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <span class="font-medium">Short Text Input</span>
            </div>
          </div>

          <!-- Custom Tracking Type: Text Long -->
          <div class="mb-4">
            <div class="flex items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showLongTextInput()"
                *ngIf="!textLongInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showLongTextInput()"
                *ngIf="textLongInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="font-medium">Long Text Input</span>
            </div>
          </div>

          <!-- Custom Tracking Type: Number -->
          <div class="mb-4">
            <div class="flex items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showNumberInput()"
                *ngIf="!numberInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showNumberInput()"
                *ngIf="numberInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="font-medium">Number Input</span>
            </div>
          </div>

          <!-- Custom Tracking Type: BOOLEAN -->
          <div class="mb-4">
            <div class="flex items-center w-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-teal-500 mr-1"
                (click)="showBooleanInput()"
                *ngIf="!booleanInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 text-red-500 mr-1"
                (click)="showBooleanInput()"
                *ngIf="booleanInputToggle"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="font-medium">Boolean Input</span>
            </div>
          </div>
        </div>
        <!-- INPUT FIELDS -->
        <div>
          <div id="range-input" class="mt-2" *ngIf="rangeInputToggle">
            <div class="flex items-center justify-center">
              <form
                [formGroup]="rangeInputForm"
                (ngSubmit)="addRange()"
                class="w-full max-w-md flex flex-row"
              >
                <div class="w-full flex flex-col">
                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        for="name"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Name</label
                      >
                      <input
                        type="text"
                        id="name"
                        formControlName="name"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Name"
                      />
                    </div>
                    <div>
                      <label
                        for="min"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Min</label
                      >
                      <input
                        type="number"
                        id="min"
                        formControlName="min"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Min"
                      />
                    </div>
                    <div>
                      <label
                        for="max"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Max</label
                      >
                      <input
                        type="number"
                        id="max"
                        formControlName="max"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Max"
                      />
                    </div>
                    <div>
                      <label
                        for="value"
                        class="block text-gray-700 text-sm font-medium mb-1"
                        >Default Value</label
                      >
                      <input
                        type="number"
                        id="value"
                        formControlName="value"
                        class="border border-gray-300 px-3 py-2 rounded-md w-full"
                        placeholder="Default Value"
                      />
                    </div>
                  </div>

                  <div class="mt-4">
                    <label class="block text-gray-700 text-sm font-medium mb-1"
                      >Step Size</label
                    >
                    <p class="text-sm text-gray-500 mb-2">
                      Which step size do you prefer?
                    </p>
                    <div
                      class="pt-2 flex flex-row w-full justify-between items-center"
                    >
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="1"
                          checked
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">1.0 - Coarse</span>
                      </label>
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="0.5"
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">0.5 - Medium</span>
                      </label>
                      <label class="flex items-center">
                        <input
                          type="radio"
                          formControlName="step"
                          value="0.1"
                          class="h-4 w-4 text-teal-600 focus:ring-teal-600"
                        />
                        <span class="ml-2 text-gray-900">0.1 - Fine</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <textarea
                      formControlName="description"
                      rows="2"
                      class="border border-gray-300 px-3 py-2 rounded-md mt-4 w-full"
                      placeholder="Description..."
                    ></textarea>
                  </div>
                </div>
                <div class="pl-4 pt-2 w-fit">
                  <button
                    type="submit"
                    [disabled]="!rangeInputForm.valid"
                    class="mt-4 bg-teal-500 text-white py-2 disabled:opacity-50 px-4 rounded-md flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-4 h-4 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            id="text-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="textShortInputToggle"
          >
            <form
              [formGroup]="textShortInput"
              (ngSubmit)="addShortText()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="shortName"
                    type="text"
                    placeholder="Short Text Input"
                    formControlName="name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!textShortInput.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 flex disabled:opacity-50 pr-2 flex-row text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>

          <div
            id="text-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="textLongInputToggle"
          >
            <form
              [formGroup]="textLongInput"
              (ngSubmit)="addLongText()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="range-min"
                    type="text"
                    placeholder="Long Text Input"
                    formControlName="name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!textLongInput.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 disabled:opacity-50 flex flex-row pr-2 text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>

          <div
            id="boolean-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="booleanInputToggle"
          >
            <form
              [formGroup]="booleanInput"
              (ngSubmit)="addBoolean()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="range-min"
                    type="text"
                    placeholder="Name the Boolean"
                    formControlName="name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!booleanInput.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 disabled:opacity-50 flex flex-row pr-2 text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>

          <div
            id="number-input"
            class="mt-2 flex flex-row text-center items-center justify-center"
            *ngIf="numberInputToggle"
          >
            <form
              [formGroup]="numberInput"
              (ngSubmit)="addNumber()"
              class="w-full flex flex-row justify-center"
            >
              <div class="w-full flex flex-col justify-center items-center">
                <div class="w-full flex flex-row justify-center mb-2">
                  <input
                    id="number"
                    type="text"
                    formControlName="name"
                    placeholder="Name"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <input
                    id="defaultValue"
                    type="number"
                    formControlName="defaultValue"
                    class="border border-gray-300 mx-2 py-1 rounded-md w-5/12 "
                  />

                  <button
                    [disabled]="!numberInput.valid"
                    type="submit"
                    class="bg-teal-500 ml-3 flex disabled:opacity-50 flex-row pr-2 text-white py-1 rounded-md  items-center text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 m-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <span>SAVE</span>
                  </button>
                </div>
                <textarea
                  formControlName="description"
                  rows="2"
                  placeholder="Description..."
                  class="border border-gray-300 px-4 py-1 rounded-md w-6/12 "
                ></textarea>
              </div>
            </form>
          </div>
        </div>

        <ul role="list" class="space-y-3 pt-4" *ngIf="CCTS.controls.length > 0">
          <li
            *ngFor="let cct of CCTS.controls; let i = index"
            class="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6 border border-gray-200"
          >
            <div class="flex w-full flex-row justify-between">
              <div class="w-fit flex items-center text-center justify-start">
                <div class="flex mr-2">
                  {{ getParameterFromControl(cct, 'slot') }}
                </div>
                <div class="flex flex-col items-center pr-2">
                  <svg (click)="moveCCTSlot(true, i)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 border-2 rounded cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>

                  <svg
                    (click)="moveCCTSlot(false, i)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 border-2 rounded cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>

              <div
                *ngIf="cct.get('type')!.value !== 'range'"
                class="flex items-center justify-start flex-col w-full"
              >
                <div class="w-full flex justify-between flex-row">
                  <div>
                    <span class="font-bold"
                      >Name: {{ getParameterFromControl(cct, 'name') }}</span
                    >
                  </div>

                  <div>
                    <span class="font-bold">Description</span>
                  </div>
                </div>
                <div class="w-full flex justify-between flex-row text-gray-500">
                  <div>
                    <span class="text-gray-500"
                      >Type: {{ getParameterFromControl(cct, 'type') }}</span
                    >
                  </div>
                  <div class="text-gray-500">
                    {{ getParameterFromControl(cct, 'description') }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="cct.get('type')!.value === 'range'"
                class="flex items-center justify-start flex-col w-full"
              >
                <div class="w-full flex justify-between flex-row">
                  <div class="">
                    <span class="font-bold"
                      >Name: {{ getParameterFromControl(cct, 'name') }}</span
                    >
                  </div>
                  <div class="text-gray-500">
                    Min: {{ getParameterFromControl(cct, 'min') }}, Max:
                    {{ getParameterFromControl(cct, 'max') }}, Default:
                    {{ getParameterFromControl(cct, 'value') }}, Tick:
                    {{ getParameterFromControl(cct, 'step') }}
                  </div>
                </div>
                <div class="w-full flex justify-between flex-row text-gray-500">
                  <div>
                    <span class="font-bold">Type: </span>
                    {{ getParameterFromControl(cct, 'type') }}
                  </div>
                  <div>
                    <span class="font-bold text-black">Description</span>
                  </div>
                </div>
                <div
                  class="w-full flex justify-end flex-row text-gray-500 italic"
                >
                  <div>
                    {{ getParameterFromControl(cct, 'description') }}
                  </div>
                </div>
              </div>

              <div
                class="w-fit flex items-center text-center text-red-500 ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 cursor-pointer"
                  (click)="onDeleteToggle(i)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </div>
              <div
                class="w-fit flex items-center text-center text-red-500 ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 cursor-pointer"
                  (click)="onEditToggle(i)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </div>
            </div>
          </li>

          <!-- More items... -->
        </ul>
      </div>
    </div>

    <div>
      <app-simple-modal
        *ngIf="deleteSlotToggle"
        (close)="onDeleteSlot($event)"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        [title]="'Delete Custom Tracking Type?'"
        [message]="'Do you want to delete the Slot?'"
      >
        ></app-simple-modal
      >
    </div>

    <div>
      <app-simple-modal
        *ngIf="deleteModelToggle"
        (close)="onDelteModelModal($event)"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        [title]="'Delete Custom Tracking Model?'"
        [message]="'Do you want to delete the Model?'"
      >
        ></app-simple-modal
      >
    </div>
  `,
  styles: [],
})
export class CustomTrackingComponent implements OnInit {
  rangeInputToggle = false;
  textShortInputToggle = false;
  textLongInputToggle = false;
  numberInputToggle = false;
  booleanInputToggle = false;
  newModelToggle = false;

  deleteSlotToggle = false;
  selectedSlotIndex: number | undefined = undefined;

  deleteModelToggle = false;

  run = true;

  searchNameValue = '';

  model = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    ownerId: [''],
    clientIds: [''],
    description: [''],
    ccts: this.fb.array([]),
  });

  textShortInput = this.fb.group({
    slot: [0],
    type: [CustomInputType.TextShort],
    name: ['', [Validators.required]],
    description: [''],
  });

  textLongInput = this.fb.group({
    slot: [0],
    type: [CustomInputType.TextLong],
    name: ['', [Validators.required]],
    description: [''],
  });

  numberInput = this.fb.group({
    slot: [0],
    type: [CustomInputType.Number],
    name: ['', [Validators.required]],
    defaultValue: [''],
    description: [''],
  });

  booleanInput = this.fb.group({
    slot: [0],
    type: [CustomInputType.Boolean],
    name: ['', [Validators.required]],
    description: [''],
  });

  rangeInputForm = this.fb.group(
    {
      slot: [0],
      type: [CustomInputType.Range],
      name: ['', [Validators.required]],
      min: ['', [Validators.required]],
      max: ['', [Validators.required]],
      value: ['', [Validators.required]],
      step: ['1', [Validators.required]],
      description: [''],
    },
    { validators: this.validateMinMax }
  );

  CustomInputType = CustomInputType;
  customTrackingModels$: Observable<CustomTrackingModel[]> | undefined;
  selectedCustomTrackingModel: CustomTrackingModel | undefined;

  validateMinMax(control: AbstractControl): { [key: string]: boolean } | null {
    const min = control.get('min')?.value;
    const max = control.get('max')?.value;
    const value = control.get('value')?.value;

    if (
      (min !== '' &&
        max !== '' &&
        value !== '' &&
        (value < min || value > max)) ||
      min >= max
    ) {
      return { minMaxError: true };
    }

    return null;
  }

  ngOnInit(): void {
    const minControl = this.rangeInputForm.get('min');
    const maxControl = this.rangeInputForm.get('max');

    minControl?.valueChanges.pipe(first()).subscribe(() => {
      maxControl?.updateValueAndValidity();
    });

    maxControl?.valueChanges.pipe(first()).subscribe(() => {
      minControl?.updateValueAndValidity();
    });

    this.customTrackingModels$ = this.ctms.getModelsForCreator();
  }

  onOpenModel(model: CustomTrackingModel) {
    model.ccts.sort((a, b) => a.slot - b.slot);

    if (this.selectedCustomTrackingModel === model) {
      this.selectedCustomTrackingModel = undefined;
    } else {
      this.selectedCustomTrackingModel = model;
    }
  }
  saveModel() {
    const model: CustomTrackingModel = {
      id: this.model.value.id!,
      name: this.model.value.name!,
      ownerId: '',
      clientIds: [],
      description: this.model.value.description!,
      ccts: this.model.value.ccts! as unknown as CustomTrackingValue[],
    };

    this.ctms.addModelToDatabase(model);
    this.onNewModel();
  }

  constructor(
    private fb: FormBuilder,
    private ctms: CustomTrackingModelService
  ) { }

  showRangeInput() {
    this.rangeInputToggle = !this.rangeInputToggle;
  }

  showShortTextInput() {
    this.textShortInputToggle = !this.textShortInputToggle;
  }

  showLongTextInput() {
    this.textLongInputToggle = !this.textLongInputToggle;
  }

  showNumberInput() {
    this.numberInputToggle = !this.numberInputToggle;
  }

  showBooleanInput() {
    this.booleanInputToggle = !this.booleanInputToggle;
  }

  onNewModel() {
    this.newModelToggle = !this.newModelToggle;
    if (this.newModelToggle) {
      this.selectedCustomTrackingModel = undefined;
      this.model.reset();
      const cctsArray = this.model.get('ccts') as FormArray;

      while (cctsArray.length !== 0) {
        cctsArray.removeAt(0);
      }
    }
  }

  addRange() {
    let val = this.CCTS.controls.find(
      (control) =>
        control.get('name')?.value === this.rangeInputForm.get('name')?.value
    );

    if (val) {
      val.patchValue(this.rangeInputForm.value);
      this.showRangeInput();
    } else {
      this.rangeInputForm
        .get('slot')
        ?.patchValue((this.model.get('ccts') as FormArray).length);
      (this.model.get('ccts') as FormArray).push(
        _.cloneDeep(this.rangeInputForm)
      );
      this.rangeInputForm.reset();
      this.rangeInputForm.get('type')?.patchValue(CustomInputType.Range);
      this.showRangeInput();
    }
  }

  addLongText() {
    let val = this.CCTS.controls.find(
      (control) =>
        control.get('name')?.value === this.textLongInput.get('name')?.value
    );

    if (val) {
      val.patchValue(this.textLongInput.value);
      this.showLongTextInput();
    } else {
      this.textLongInput
        .get('slot')
        ?.patchValue((this.model.get('ccts') as FormArray).length);
      (this.model.get('ccts') as FormArray).push(
        _.cloneDeep(this.textLongInput)
      );
      this.textLongInput.reset();
      this.textLongInput.get('type')?.patchValue(CustomInputType.TextLong);

      this.showLongTextInput();
    }
  }

  addBoolean() {
    let val = this.CCTS.controls.find(
      (control) =>
        control.get('name')?.value === this.booleanInput.get('name')?.value
    );

    if (val) {
      val.patchValue(this.booleanInput.value);
      this.showBooleanInput();
    } else {
      this.booleanInput
        .get('slot')
        ?.patchValue((this.model.get('ccts') as FormArray).length);
      (this.model.get('ccts') as FormArray).push(
        _.cloneDeep(this.booleanInput)
      );
      this.booleanInput.reset();
      this.booleanInput.get('type')?.patchValue(CustomInputType.Boolean);

      this.showBooleanInput();
    }
  }
  addShortText() {
    let val = this.CCTS.controls.find(
      (control) =>
        control.get('name')?.value === this.textShortInput.get('name')?.value
    );

    if (val) {
      val.patchValue(this.textShortInput.value);
      this.showShortTextInput();
    } else {
      this.textShortInput
        .get('slot')
        ?.patchValue((this.model.get('ccts') as FormArray).length);
      (this.model.get('ccts') as FormArray).push(
        _.cloneDeep(this.textShortInput)
      );
      this.textShortInput.reset();

      this.textShortInput.get('type')?.patchValue(CustomInputType.TextShort);
      this.showShortTextInput();
    }
  }

  addNumber() {
    let val = this.CCTS.controls.find(
      (control) =>
        control.get('name')?.value === this.numberInput.get('name')?.value
    );

    if (val) {
      val.patchValue(this.numberInput.value);
      this.showNumberInput();
    } else {
      this.numberInput
        .get('slot')
        ?.patchValue((this.model.get('ccts') as FormArray).length);
      (this.model.get('ccts') as FormArray).push(_.cloneDeep(this.numberInput));
      this.numberInput.reset();
      this.numberInput.get('type')?.patchValue(CustomInputType.Number);
      this.showNumberInput();
    }
  }

  get CCTS() {
    return this.model.get('ccts') as FormArray;
  }

  getParameterFromControl(val: AbstractControl, parameter: string): string {
    const parameterControl = val.get(parameter);

    if (
      parameterControl !== null &&
      parameterControl.value !== undefined &&
      parameterControl.value !== null
    ) {
      return parameterControl.value.toString();
    }

    return '';
  }


  moveCCTSlot(direction: boolean, index: number) {
    if (direction && index > 0) {
      this.swapElemets(this.CCTS.controls, index, index - 1);
    } else if (!direction && index < this.CCTS.controls.length - 1) {
      this.swapElemets(this.CCTS.controls, index, index + 1);
    }
    this.renumerateSlots();
  }
  renumerateSlots() {
    this.CCTS.controls.forEach((control, index) => {
      control.get('slot')?.patchValue(index);
    });
  }

  swapElemets(arr: Array<any>, position1: number, position2: number) {
    const tmp = arr[position1];
    arr[position1] = arr[position2];
    arr[position2] = tmp;
    return arr;
  }

  onDeleteToggle(index: number) {
    this.selectedSlotIndex = index;
    this.deleteSlotToggle = !this.deleteSlotToggle;
  }

  onEditToggle(index: number) {
    this.selectedSlotIndex = index;
    if (this.selectedSlotIndex !== undefined) {
      const typeCCT = this.CCTS.at(this.selectedSlotIndex).get('type')?.value;
      switch (typeCCT) {
        case CustomInputType.Range:
          this.showRangeInput();
          this.rangeInputForm.patchValue(
            this.CCTS.at(this.selectedSlotIndex).value
          );
          break;

        case CustomInputType.TextShort:
          this.showShortTextInput();
          this.textShortInput.patchValue(
            this.CCTS.at(this.selectedSlotIndex).value
          );
          break;
        case CustomInputType.TextLong:
          this.showLongTextInput();
          this.textLongInput.patchValue(
            this.CCTS.at(this.selectedSlotIndex).value
          );
          break;
        case CustomInputType.Number:
          this.showNumberInput();
          this.numberInput.patchValue(
            this.CCTS.at(this.selectedSlotIndex).value
          );
          break;

        case CustomInputType.Boolean:
          this.showBooleanInput();
          this.booleanInput.patchValue(
            this.CCTS.at(this.selectedSlotIndex).value
          );
          break;
        default:
          break;
      }
    }
  }

  onDeleteSlot(event: boolean) {
    if (event) {
      if (this.selectedSlotIndex !== undefined) {
        this.CCTS.removeAt(this.selectedSlotIndex);
        this.renumerateSlots();
      }
    }
    this.deleteSlotToggle = !this.deleteSlotToggle;
  }

  /*
  generateSimpleTmpForm(simpleInputForm: FormGroup): FormGroup {
    const tmpForm = this.fb.group({
      name: [_.cloneDeep(simpleInputForm.get('name')?.value)],
    });

    return tmpForm;
  }
  */

  getCustomTrackingTypeName(type: CustomInputType): string {
    switch (type) {
      case CustomInputType.Range:
        return 'Range';
      case CustomInputType.TextShort:
        return 'Text (Short)';
      case CustomInputType.TextLong:
        return 'Text (Long)';
      case CustomInputType.Number:
        return 'Number';

      case CustomInputType.Boolean:
        return 'Boolean';
      default:
        return '';
    }
  }

  onEditModel(model: CustomTrackingModel) {
    model.ccts.sort((a, b) => a.slot - b.slot);
    this.newModelToggle = !this.newModelToggle;

    this.model.get('name')?.patchValue(model.name);
    this.model.get('description')?.patchValue(model.description);
    this.model.get('id')?.patchValue(model.id as string);
    this.patchCCTS(model);
  }

  patchCCTS(model: CustomTrackingModel) {
    const cctsArray = this.model.get('ccts') as FormArray;

    while (cctsArray.length !== 0) {
      cctsArray.removeAt(0);
    }

    model.ccts.forEach((cct) => {
      let cctForm: FormGroup;

      if (cct.type === CustomInputType.TextShort) {
        cctForm = this.fb.group({
          slot: [cct.slot],
          type: [CustomInputType.TextShort],
          name: [cct.name, Validators.required],
          description: [cct.description],
        });
      } else if (cct.type === CustomInputType.TextLong) {
        cctForm = this.fb.group({
          slot: [cct.slot],
          type: [CustomInputType.TextLong],
          name: [cct.name, Validators.required],
          description: [cct.description],
        });
      } else if (cct.type === CustomInputType.Number) {
        cctForm = this.fb.group({
          slot: [cct.slot],
          type: [CustomInputType.Number],
          name: [cct.name, Validators.required],
          description: [cct.description],
        });
      } else if (cct.type === CustomInputType.Boolean) {
        cctForm = this.fb.group({
          slot: [cct.slot],
          type: [CustomInputType.Boolean],
          name: [cct.name, Validators.required],
          description: [cct.description],
        });
      } else if (cct.type === CustomInputType.Range) {
        cctForm = this.fb.group(
          {
            slot: [cct.slot],
            type: [CustomInputType.Range],
            name: [cct.name, Validators.required],
            min: [cct.min, Validators.required],
            max: [cct.max, Validators.required],
            value: [cct.value, Validators.required],
            step: [cct.step.toString(), Validators.required],
            description: [cct.description],
          },
          { validators: this.validateMinMax }
        );
      }

      cctsArray.push(cctForm!);
    });
  }

  onDeleteModel(model: CustomTrackingModel) {
    this.deleteModelToggle = !this.deleteModelToggle;
    this.selectedCustomTrackingModel = model;
  }

  onDelteModelModal(event: boolean) {
    if (event && this.selectedCustomTrackingModel) {
      this.ctms.deleteModel(this.selectedCustomTrackingModel.id as string);
    }

    this.deleteModelToggle = !this.deleteModelToggle;
  }
}
