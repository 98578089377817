import { Component, Input, OnInit } from '@angular/core';
import { AppUser } from '../core/thecoach';

@Component({
  selector: 'app-admin-display-user',
  template: `
   
   <div class="w-full max-w-2xl mx-auto p-4">
  <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 grid grid-cols-2 gap-4">

  <div>
        <!-- Display the role if available -->
    <div class="mb-4" *ngIf="appUser?.id">
      <label class="block text-gray-700 font-bold mb-2">User Id:</label>
      <p>{{ appUser.id }}</p>
    </div>
    <!-- Display the role if available -->
    <div class="mb-4" *ngIf="appUser?.role">
      <label class="block text-gray-700 font-bold mb-2">Role:</label>
      <p>{{ appUser.role }}</p>
    </div>

    <!-- Display the email if available -->
    <div class="mb-4" *ngIf="appUser?.email">
      <label class="block text-gray-700 font-bold mb-2">Email:</label>
      <p>{{ appUser.email }}</p>
    </div>

    <!-- Display displayName if available -->
    <div class="mb-4" *ngIf="appUser?.displayName">
      <label class="block text-gray-700 font-bold mb-2">Display Name:</label>
      <p>{{ appUser.displayName }}</p>
    </div>

    <!-- Display birthdate if available -->
    <div class="mb-4" *ngIf="appUser?.birthdate">
      <label class="block text-gray-700 font-bold mb-2">Birthdate:</label>
      <p>{{ appUser.birthdate | date }}</p>
    </div>

    <!-- Display imgUrl if available -->
    <div class="mb-4" *ngIf="appUser?.imgUrl">
      <label class="block text-gray-700 font-bold mb-2">Image URL:</label>
      <img [src]="appUser.imgUrl" alt="User Image" class="w-24 h-24 rounded-full">
    </div>

    <!-- Display height if available -->
    <div class="mb-4" *ngIf="appUser?.height">
      <label class="block text-gray-700 font-bold mb-2">Height (cm):</label>
      <p>{{ appUser.height }}</p>
    </div>

    <!-- Display startingWeight if available -->
    <div class="mb-4" *ngIf="appUser?.startingWeight">
      <label class="block text-gray-700 font-bold mb-2">Starting Weight (kg):</label>
      <p>{{ appUser.startingWeight }}</p>
    </div>

    <!-- Display customerId if available -->
    <div class="mb-4" *ngIf="appUser?.customerId">
      <label class="block text-gray-700 font-bold mb-2">Customer ID:</label>
      <p>{{ appUser.customerId }}</p>
    </div>
</div>

<div>

<!-- Display enableWeightLog if available -->
    <div class="mb-4" *ngIf="appUser?.enableWeightLog">
      <label class="block text-gray-700 font-bold mb-2">Enable Weight Log:</label>
      <p>{{ appUser.enableWeightLog }}</p>
    </div>

    <!-- Display enableTraining if available -->
    <div class="mb-4" *ngIf="appUser?.enableTraining">
      <label class="block text-gray-700 font-bold mb-2">Enable Training:</label>
      <p>{{ appUser.enableTraining }}</p>
    </div>

    <!-- Display checkInWeekdayIndex if available -->
    <div class="mb-4" *ngIf="appUser?.checkInWeekdayIndex">
      <label class="block text-gray-700 font-bold mb-2">Check-In Weekday Index:</label>
      <p>{{ appUser.checkInWeekdayIndex }}</p>
    </div>

    <!-- Display checkInSlotIndex if available -->
    <div class="mb-4" *ngIf="appUser?.checkInSlotIndex">
      <label class="block text-gray-700 font-bold mb-2">Check-In Slot Index:</label>
      <p>{{ appUser.checkInSlotIndex }}</p>
    </div>

    <!-- Display emailVerified if available -->
    <div class="mb-4" *ngIf="appUser?.emailVerified">
      <label class="block text-gray-700 font-bold mb-2">Email Verified:</label>
      <p>{{ appUser.emailVerified }}</p>
    </div>

    <!-- Display coachId if available -->
    <div class="mb-4" *ngIf="appUser?.coachId">
      <label class="block text-gray-700 font-bold mb-2">Coach ID:</label>
      <p>{{ appUser.coachId }}</p>
    </div>

    <!-- Display lastWeightLog if available -->
    <div class="mb-4" *ngIf="appUser?.lastWeightLog">
      <label class="block text-gray-700 font-bold mb-2">Last Weight Log:</label>
      <p>{{ appUser.lastWeightLog | json }}</p>
    </div>




    <!-- Display registrationLinkId if available -->
    <div class="mb-4" *ngIf="appUser?.registrationLinkId">
      <label class="block text-gray-700 font-bold mb-2">Registration Link ID:</label>
      <p>{{ appUser.registrationLinkId }}</p>
    </div>

    <!-- Display registrationDate if available -->
    <div class="mb-4" *ngIf="appUser?.registrationDate">
      <label class="block text-gray-700 font-bold mb-2">Registration Date:</label>
      <p>{{ appUser.registrationDate | date }}</p>
    </div>

    <!-- Display isEditing if available -->
    <div class="mb-4" *ngIf="appUser?.isEditing">
      <label class="block text-gray-700 font-bold mb-2">Is Editing:</label>
      <p>{{ appUser.isEditing }}</p>
    </div>

    <!-- Display ctmId if available -->
    <div class="mb-4" *ngIf="appUser?.ctmId">
      <label class="block text-gray-700 font-bold mb-2">CTM ID:</label>
      <p>{{ appUser.ctmId }}</p>
    </div>

</div>
    <!-- Display trainingplanIds if available -->
    <div class="mb-4" *ngIf="appUser?.trainingplanIds">
      <label class="block text-gray-700 font-bold mb-2">Training Plan IDs:</label>
      <p>{{ appUser.trainingplanIds | json }}</p>
    </div>

    <!-- Display traininglogIds if available -->
    <div class="mb-4" *ngIf="appUser?.traininglogIds">
      <label class="block text-gray-700 font-bold mb-2">Training Log IDs:</label>
      <p>{{ appUser.traininglogIds | json }}</p>
    </div>

    <!-- Display weightlogIds if available -->
    <div class="mb-4" *ngIf="appUser?.weightlogIds">
      <label class="block text-gray-700 font-bold mb-2">Weight Log IDs:</label>
      <p>{{ appUser.weightlogIds }}</p>
    </div>

    
    <!-- Display clientIds if available -->
    <div class="mb-4" *ngIf="appUser?.clientIds">
      <label class="block text-gray-700 font-bold mb-2">Client IDs:</label>
      <p>{{ appUser.clientIds | json }}</p>
    </div>

    <!-- Display userDetails if available -->
    <div class="mb-4" *ngIf="appUser?.userDetails">
      <label class="block text-gray-700 font-bold mb-2">User Details:</label>
      <p>{{ appUser.userDetails | json }}</p>
    </div>

    
    <!-- Display setMacros if available -->
    <div class="mb-4" *ngIf="appUser?.setMacros">
      <label class="block text-gray-700 font-bold mb-2">Set Macros:</label>
      <p>Protein: {{ appUser.setMacros?.protein}}</p>
    <p>Carbs: {{ appUser.setMacros?.carbs}}</p>
 <p>Fat: {{ appUser.setMacros?.fat}}</p>

    
    </div>

    <!-- Display enableOffDayMacros if available -->
    <div class="mb-4" *ngIf="appUser?.enableOffDayMacros">
      <label class="block text-gray-700 font-bold mb-2">Enable Off-Day Macros:</label>
      <p>{{ appUser.enableOffDayMacros }}</p>
    </div>

    <!-- Display offDayMacros if available -->
    <div class="mb-4" *ngIf="appUser?.offDayMacros">
      <label class="block text-gray-700 font-bold mb-2">Off-Day Macros:</label>
         <p>Protein: {{ appUser.offDayMacros?.protein}}</p>
    <p>Carbs: {{ appUser.offDayMacros?.carbs}}</p>
 <p>Fat: {{ appUser.offDayMacros?.fat}}</p>


    </div>
  </div>
</div>
  `,
  styles: [
  ]
})
export class AdminDisplayUserComponent implements OnInit {
  @Input() appUser!: AppUser;
  constructor() {

  }
  ngOnInit(): void {
    // console.log(this.appUser)
  }
}
