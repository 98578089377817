import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Exercise,
  TemplateTrainingDay,
  TemplateTrainingPlan,
  TemplateTrainingSlot,
} from '../../core/thecoach';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { ExerciseService } from '../../services/exercise.service';
import { Subscription, take } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { TemplateTrainingplanService } from '../../services/templatetrainingplan.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-trainingplan-creator',
  template: `
    <div class="py-2 flex w-full justify-between flex-row">
      <button
        (click)="onGoBack()"
        type="button"
        class="flex flex-row justify-center items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 pr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
          />
        </svg>

        Back
      </button>
      <app-csvimport
        (trainingPlan)="importTrainingplan($event)"
      ></app-csvimport>
    </div>

    <div class="flex justify-center py-4">
      <h1 class="text-3xl text-bold uppercase">Trainingplan Creator</h1>
    </div>
    <div class="flex justify-center flex-col items-center w-full">
      <form
        [formGroup]="trainingPlanForm"
        (ngSubmit)="onSubmit()"
        class="w-full px-4 flex flex-col"
      >
        <div class="flex flex-row w-10/12 justify-between self-center pb-4">
          <div class="flex flex-col text-center w-3/12">
            <label
              for="city"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pr-2"
              >Plan Name</label
            >
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                formControlName="trainingPlanName"
                id="trainingPlanName"
                class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
              />
            </div>
          </div>

          <div class="flex flex-col  w-4/12">
            <label
              for="region"
              class="block text-sm font-medium text-center text-gray-700 sm:mt-px sm:pt-2 pr-2"
              >Plan Description</label
            >

            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <textarea
                rows="4"
                formControlName="trainingPlanDescription"
                id="trainingPlanDescription"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
              ></textarea>
            </div>
          </div>

          <div class="flex w-3/12 items-center">
            <button
              type="button"
              (click)="addTrainingDay()"
              class="inline-flex items-center h-fit rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
            >
              Generate Trainingday
            </button>
          </div>
        </div>

        <ng-container
          *ngFor="let trainingDay of trainingDays.controls; let i = index"
          formArrayName="trainingDays"
        >
          <div
            [formGroupName]="i"
            class="flex flex-col justify-between items-center w-full pt-2"
          >
            <div class="flex flex-row items-center">
              <div
                class="flex flex-row sm:border-t sm:border-gray-200 sm:pt-5 pb-5"
              >
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pr-2"
                  >Trainingday Name</label
                >
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    formControlName="trainingDayName"
                    id="trainingDayName"
                    class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>

              <button
                type="button"
                (click)="addTrainingSlots(i)"
                class="ml-2 h-fit inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
              >
                + Slots
              </button>
              <button
                type="button"
                (click)="deleteTrainingDay(i)"
                class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
              >
                DELETE
              </button>
            </div>

            <!--
            <ng-container *ngIf="trainingSlots">
              <ng-container
                *ngFor="
                  let trainingSlot of trainingSlots(i).controls;
                  let j = index
                "
                formArrayName="trainingSlots"
              >
                <div [formGroupName]="j" class="flex justify-center flex-col">
                  <div class="flex flex-row px-2 py-2">
                    <div class="flex justify-center items-center">
                      <label
                        for="city"
                        class="block text-sm font-medium text-gray-700 px-2"
                        >Slot</label
                      >
                      <div class="mt-1 sm:col-span-2 sm:mt-0">
                        <p
                          type="text"
                          id="slot-{{ j }}"
                          class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
                        >
                          {{ j }}
                        </p>
                      </div>
                    </div>

                    <div class="flex sm:col-span-2 sm:mt-0">
                      <div class="flex items-center pl-2">
                        <div class="relative mt-1">
                          <input
                            id="exerciseinput"
                            formControlName="exerciseName"
                            type="text"
                            #exerciseinput
                            (keyup)="filterExercises(exerciseinput.value)"
                            (focus)="toggleSelect(i, j)"
                            class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500 sm:text-sm"
                            role="combobox"
                            aria-controls="options"
                            aria-expanded="false"
                          />
                          <button
                            type="button"
                            (click)="toggleSelect(i, j)"
                            class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                          >

                            <svg
                              class="h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                          <ul
                            @AnimationTrigger0
                            *ngIf="dropDownValue(i, j)"
                            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            id="options"
                            role="listbox"
                          >

                            <li
                              *ngFor="let exercise of exercises"
                              (click)="addExercise(exercise, i, j)"
                              class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                              id="option-0"
                              role="option"
                              tabindex="-1"
                            >

                              <button type="button">
                                <span class="block truncate">{{
                                  exercise.exerciseName
                                }}</span>
                              </button>
                            </li>


                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="flex justify-center items-center  pl-2">
                      <div class="mt-1 sm:col-span-2 sm:mt-0">
                        <textarea
                          type="text"
                          formControlName="exerciseDescription"
                          id="reps"
                          rows="2"
                          class="w-96 min-h-[2.5rem] italic text-xs text-center pl-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
                        >
                        </textarea>
                      </div>
                    </div>

                    <div class="flex justify-center items-center">
                      <label
                        class="block text-sm font-medium text-gray-700 px-2"
                        >Sets</label
                      >
                      <div class="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          formControlName="sets"
                          id="sets"
                          class="block w-[3rem] text-center max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                    </div>

                    <div class="flex justify-center items-center">
                      <label
                        class="block text-sm font-medium text-gray-700 px-2"
                        >Reps</label
                      >
                      <div class="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          formControlName="reps"
                          id="reps"
                          class="block w-[5rem] text-center max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                    </div>

                    <div class="flex justify-center items-center">
                      <label
                        class="block text-sm font-medium text-gray-700 px-2"
                        >RIR</label
                      >
                      <div class="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          formControlName="rir"
                          id="rir"
                          class="block w-[3rem] text- center max-w-lg rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                    </div>
                    <div class="items-center flex justify-center pl-2">
                      <button
                        type="button"
                        (click)="deleteSlot(i, j)"
                        class="inline-flex items-center rounded-md border border-transparent bg-red-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                      >
                        DELETE
                      </button>

                      <div class="pl-2">
                        <span class="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            (click)="moveSlotUp(i, j)"
                            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                          >

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 15.75l7.5-7.5 7.5 7.5"
                              />
                            </svg>
                          </button>
                          <button
                            type="button"
                            (click)="moveSlotDown(i, j)"
                            class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
                          >

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
                  -->
          </div>
          <div
            formGroupName="{{ i }}"
            *ngIf="getTrainingDaySlots(trainingDay).controls.length > 0"
          >
            <table class="divide-y divide-gray-300 w-full table-auto">
              <thead>
                <tr class="divide-x divide-gray-200">
                  <th
                    scope="col"
                    class="py-2 px-2 text-left text-sm font-semibold text-gray-900 "
                  >
                    Slot
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Exercise
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-sm font-semibold text-gray-900 "
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="py-2 px-2 text-center text-sm font-semibold text-gray-900 "
                  >
                    Sets
                  </th>
                  <th
                    scope="col"
                    class="py-2 px-2 text-center text-sm font-semibold text-gray-900 "
                  >
                    Reps
                  </th>
                  <th
                    scope="col"
                    class="py-2 px-2 text-center text-sm font-semibold text-gray-900"
                  >
                    RIR
                  </th>
                  <th
                    scope="col"
                    class="py-2 px-2 text-center text-sm font-semibold text-gray-900"
                  >
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                formArrayName="trainingSlots"
              >
                <tr
                  class="divide-x divide-gray-200"
                  *ngFor="
                    let trainingSlot of getTrainingDaySlots(trainingDay)
                      .controls!;
                    let k = index
                  "
                  formGroupName="{{ k }}"
                >
                  <td
                    class="w-[2rem] whitespace-normal py-2  text-sm font-medium text-gray-900 text-center w-auto"
                  >
                    <div class="flex flex-row items-center">
                      <div class="flex flex-col items-center pr-2">
                        <svg
                          (click)="moveSlotUpNew(trainingSlot, trainingDay)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 border-2 rounded"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>

                        <svg
                          (click)="moveSlotDownNew(trainingSlot, trainingDay)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 border-2 rounded"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </div>
                      {{ getParameterFromTrainingSlot(trainingSlot, 'slot') }}
                    </div>
                  </td>

                  <td
                    class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                  >
                    <div
                      class="flex flex-row items-center justify-center text-center"
                    >
                      <span
                        class="input w-full h-full"
                        role="input"
                        tabindex="-1"
                        contenteditable="true"
                      >
                        <input
                          class="rounded-md border-0.5  outline-0  w-full border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                          type="text"
                          formControlName="exerciseName"
                          #exName
                          autocomplete="off"
                          (click)="toggleActiveInput(exName, k, i)"
                          (keyup)="filterExercisesByName(exName, k, i)"
                          id="{{
                            getParameterFromTrainingSlot(
                              trainingSlot,
                              'exerciseName'
                            )
                          }}"
                        />
                        <div class="absolute z-10 w-3/5">
                          <ul
                            *ngIf="
                              activeInputIndex === k &&
                              activeTrainingDayIndex === i &&
                              filteredExercises.length > 0
                            "
                            class="absolute z-20 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            id="options"
                            role="listbox"
                          >
                            <li
                              *ngFor="let exercise of filteredExercises"
                              class="hover:text-white hover:bg-teal-600 relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                              id="option-0"
                              role="option"
                              tabindex="-1"
                              (click)="
                                patchExerciseOnSlot(exercise, trainingSlot)
                              "
                            >
                              <div class="flex flex-row items-center">
                                <!-- Selected: "font-semibold" -->
                                <span
                                  class="flex flex-row whitespace-nowrap pr-1"
                                  >{{ exercise.exerciseName }}</span
                                >
                                -
                                <span
                                  class="block truncate italic text-xs pl-1"
                                >
                                  {{ exercise.exerciseDescription }}</span
                                >
                              </div>
                              <!--
          Checkmark, only display for selected option.

          Active: "text-white", Not Active: "text-teal-600"
        -->
                            </li>

                            <!-- More items... -->
                          </ul>
                        </div>
                      </span>
                      <div *ngIf="false">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5 cursor-pointer"
                          (click)="onExerciseLibraryLoad(trainingSlot)"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap p-2 text-sm text-gray-500 w-auto"
                  >
                    <span
                      class="input w-full h-full"
                      role="input"
                      tabindex="-1"
                      contenteditable="true"
                    >
                      <textarea
                        class="w-full min-h-[2.5rem] rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                        rows="2"
                        type="text"
                        formControlName="exerciseDescription"
                      ></textarea>
                    </span>
                  </td>
                  <!--
                                    <td
                                      class="whitespace-nowrap items-center p-2 text-sm text-gray-500 w-auto"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6 cursor-pointer"
                                        (click)="
                                          onOpenEditVideoLink(
                                            trainingSlot,
                                            trainingDay
                                          )
                                        "
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                                        />
                                      </svg>
                                    </td>-->
                  <td
                    class="whitespace-nowrap py-2 px-2 w-[4.5rem]  text-sm text-gray-500 text-center"
                  >
                    <input
                      class="w-full text-center rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                      type="text"
                      formControlName="sets"
                      id="{{
                        getParameterFromTrainingSlot(trainingSlot, 'sets')
                      }}"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-2 px-2 w-[6.5rem] text-sm text-gray-500 text-center"
                  >
                    <input
                      class="w-full  text-center rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                      type="text"
                      formControlName="reps"
                      id="{{
                        getParameterFromTrainingSlot(trainingSlot, 'reps')
                      }}"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-2 w-[4.5rem] px-2 text-sm text-gray-500 text-center"
                  >
                    <input
                      class="w-full text-center rounded-md border-0.5 outline-0  border-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                      type="text"
                      formControlName="rir"
                      id="{{
                        getParameterFromTrainingSlot(trainingSlot, 'rir')
                      }}"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                  >
                    <button
                      (click)="onDeleteSlot(trainingSlot, trainingDay, k)"
                      type="button"
                      class="ml-4 w-fit rounded-md bg-red-600 py-0.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                    <button
                      (click)="onCopySlot(trainingSlot, trainingDay, k)"
                      type="button"
                      class="ml-4 w-fit rounded-md bg-teal-600 py-0.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </ng-container>

        <div class="flex justify-between">
          <button
            type="submit"
            class="mt-10 inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
          >
            Save
          </button>

          <button
            (click)="onCancel()"
            type="button"
            class="mt-10 inline-flex items-center rounded-md border border-transparent bg-gray-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>

    <app-exercise-search-modal
      class="z-10"
      (close)="onSelectedExerciseFromDatabase($event)"
      [slotElement]="selectedTrainingSlotForLoad"
      [exercises]="this.exercises"
      *ngIf="toggleExerciseLibrarySearch"
    >
    </app-exercise-search-modal>
  `,
  styles: [],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class TrainingplanCreatorComponent implements OnInit, OnDestroy {
  trainingPlan: TemplateTrainingPlan = {};
  id: string | null = null;
  exercises: Exercise[] = [];
  filteredExercises: Exercise[] = [];
  backupexercises: Exercise[] = [];
  exerciseSubscription: Subscription | undefined;
  trainingplanSubscription: Subscription | undefined;
  isSelect = false;
  selectedDropdowns: boolean[] = [];
  newDropdowns: { id: number; posId: number; val: boolean }[] = [];

  toggleExerciseLibrarySearch = false;
  selectedTrainingSlotForLoad: AbstractControl | undefined = undefined;
  exerciseNameWidth = 125;

  run = true;
  showDropdown = false;
  activeInput: HTMLInputElement | null = null;
  activeInputIndex: number | null = null;
  activeTrainingDayIndex: number | null = null;

  trainingPlanForm = this.fb.group({
    id: [''],
    trainingPlanName: [''],
    trainingPlanDescription: [''],
    trainingDays: this.fb.array([], []),
  });

  constructor(
    private _eref: ElementRef,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private exerciseService: ExerciseService,
    private trainingPlanService: TemplateTrainingplanService,
    private location: Location,
  ) {
    this.exerciseSubscription = this.exerciseService
      .getAllExercisesForUser()
      .subscribe((data) => {
        this.exercises = data;
        this.backupexercises = data;
        this.filteredExercises = data;
        // console.log(this.exercises);
      });
  }

  onClick(event: any) {
    if (event.target !== this.activeInput) this.activeInput = null;

    if (!this._eref.nativeElement.contains(this.activeInput)) {
      this.activeInput = null;
      this.activeInputIndex = null;
      this.activeTrainingDayIndex = null;
    }
    // or some similar check
  }

  patchExerciseOnSlot(exercise: Exercise, trainingSlot: AbstractControl) {
    if (exercise) {
      trainingSlot?.get('exerciseName')?.patchValue(exercise.exerciseName, {
        onlySelf: false,
        emitEvent: true,
      });
      trainingSlot
        ?.get('exerciseDescription')
        ?.patchValue(exercise.exerciseDescription, {
          onlySelf: false,
          emitEvent: true,
        });
      trainingSlot
        ?.get('exerciseId')
        ?.patchValue(exercise.id, { onlySelf: false, emitEvent: true });
    }
  }

  toggleActiveInput(input: HTMLInputElement, index: number, day: number): void {
    this.activeInput = this.activeInput === input ? null : input;
    if (index === 0 && day === 0) {
      this.activeInputIndex = 0;
      this.activeTrainingDayIndex = 0;
    } else {
      this.activeInputIndex = this.activeInputIndex === index ? null : index;
      this.activeTrainingDayIndex = this.activeInputIndex === day ? null : day;
    }
  }

  getTrainingDaySlots(trainingDay: AbstractControl) {
    return trainingDay.get('trainingSlots') as FormArray;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.filteredExercises = _.cloneDeep(this.exercises);

    if (this.id) {
      this.trainingplanSubscription = this.trainingPlanService
        .get(this.id)
        .pipe(take(1))
        .subscribe((data) => {
          if (!data) {
            return;
          }
          this.loadTrainingPlan(data as TemplateTrainingPlan);
        });
    }
  }

  loadTrainingPlan(data: TemplateTrainingPlan) {
    this.trainingPlan = data as TemplateTrainingPlan;
    this.trainingPlanForm.patchValue(this.trainingPlan);

    let tpdays = this.trainingPlan.trainingDays;

    tpdays?.forEach((tpday) => {
      const tpdayForm = this.generateTrainingDay();
      tpdayForm.patchValue(tpday);

      tpday.trainingSlots?.forEach((slot, index) => {
        slot.slot = index + 1;
        const slotForm = this.generateTrainingSlot();
        slotForm.patchValue(slot);
        (tpdayForm.get('trainingSlots') as FormArray).push(slotForm);
      });

      (this.trainingPlanForm.get('trainingDays') as FormArray).push(tpdayForm);
    });
  }

  importTrainingplan(event: TemplateTrainingPlan) {
    this.loadTrainingPlan(event);
  }

  ngOnDestroy() {
    this.exerciseSubscription?.unsubscribe();
    this.trainingplanSubscription?.unsubscribe();
  }

  get trainingDays() {
    return this.trainingPlanForm.get('trainingDays') as FormArray;
  }

  trainingSlots(index: number) {
    return (this.trainingPlanForm.get('trainingDays') as FormArray)
      .at(index)
      .get('trainingSlots') as FormArray;
  }

  exerciseFormInput(i: number, j: number) {
    return (
      (this.trainingPlanForm.get('trainingDays') as FormArray)
        .at(i)
        .get('trainingSlots') as FormArray
    )
      .at(j)
      .get('exerciseName');
  }

  exerciseDescriptionFormInput(i: number, j: number) {
    return (
      (this.trainingPlanForm.get('trainingDays') as FormArray)
        .at(i)
        .get('trainingSlots') as FormArray
    )
      .at(j)
      .get('exerciseDescription');
  }

  exerciseIdFormInput(i: number, j: number) {
    return (
      (this.trainingPlanForm.get('trainingDays') as FormArray)
        .at(i)
        .get('trainingSlots') as FormArray
    )
      .at(j)
      .get('exerciseId');
  }

  addTrainingDay() {
    this.trainingDays.push(this.generateTrainingDay());
  }

  generateTrainingDay() {
    return this.fb.group({
      trainingDayName: [''],
      trainingSlots: this.fb.array([], []),
    });
  }

  onSubmit() {
    this.trainingPlan = Object.assign(
      this.trainingPlan,
      this.trainingPlanForm.value,
    );
    console.log(this.trainingPlan);
    this.trainingPlanService.save(this.trainingPlan);

    this.router.navigate(['/trainingplan-library']);
  }

  addTrainingSlots(index: number) {
    // console.log(this.trainingPlanForm)
    let obj = {
      id: index,
      posId: this.trainingSlots(index).length,
      val: false,
    };
    this.newDropdowns.push(obj);
    this.selectedDropdowns.push(false);
    this.trainingSlots(index).push(this.generateTrainingSlot());
    (
      this.trainingSlots(index)
        .at(this.trainingSlots(index).length - 1)
        .get('slot') as FormControl
    ).patchValue(this.trainingSlots(index).length);
  }

  generateTrainingSlot() {
    return this.fb.group({
      slot: [0],
      exerciseName: [''],
      exerciseDescription: [''],
      exerciseId: [''],
      sets: [''],
      reps: [''],
      rir: [''],
    });
  }

  toggleSelect(i: number, j: number) {
    console.log(i, j, this.newDropdowns, this.selectedDropdowns);
    this.newDropdowns.find((obj) => {
      if (obj.id === i && obj.posId === j) obj.val = !obj.val;
    });
    this.selectedDropdowns[i] = !this.selectedDropdowns[i];
    this.isSelect = !this.isSelect;
  }

  dropDownValue(i: number, j: number) {
    return !!this.newDropdowns.find((obj) => {
      if (obj.id === i && obj.posId === j) return obj.val;

      return false;
    });
  }

  filterExercises($event: string) {
    this.exercises = this.backupexercises.filter((obj) => {
      return obj.exerciseName?.toLowerCase().includes($event.toLowerCase());
    });
  }

  filterExercisesByName(value: HTMLInputElement, index: number, day: number) {
    this.showDropdown = true;

    this.activeInput = value;
    if (index === 0 && day === 0) {
      this.activeInputIndex = 0;
      this.activeTrainingDayIndex = 0;
    } else {
      this.activeInputIndex = index;
      this.activeTrainingDayIndex = day;
    }

    const val = value.value;

    this.filteredExercises = this.backupexercises.filter((element) => {
      return element.exerciseName!.toLowerCase().includes(val.toLowerCase());
    });
  }

  getExerciseName(i: number, j: number) {
    if (this.run) {
      console.log(this.exerciseDescriptionFormInput(i, j));
      this.run = false;
    }

    return this.exerciseDescriptionFormInput(i, j)?.value;
  }

  addExercise(exercise: Exercise, i: number, j: number) {
    this.exerciseFormInput(i, j)?.setValue(exercise.exerciseName);
    this.exerciseDescriptionFormInput(i, j)?.setValue(
      exercise.exerciseDescription,
    );
    this.exerciseIdFormInput(i, j)?.setValue(exercise.id);
    this.toggleSelect(i, j);
    this.exercises = this.backupexercises;
  }

  deleteSlot(i: number, j: number) {
    this.trainingSlots(i).removeAt(j);
  }

  deleteTrainingDay(i: number) {
    this.trainingDays.removeAt(i);
  }

  moveSlotUp(i: number, j: number) {
    const element = this.trainingSlots(i).at(j);

    if (j > 0) {
      (this.trainingSlots(i).at(j).get('slot') as FormControl).patchValue(j);
      (
        this.trainingSlots(i)
          .at(j - 1)
          .get('slot') as FormControl
      ).patchValue(j + 1);

      this.trainingSlots(i).removeAt(j);
      this.trainingSlots(i).insert(j - 1, element);
    }
  }

  moveSlotDown(i: number, j: number) {
    if (j < this.trainingSlots(i).length - 1) {
      const element = this.trainingSlots(i).at(j);
      this.trainingSlots(i).removeAt(j);
      this.trainingSlots(i).insert(j + 1, element);

      (this.trainingSlots(i).at(j).get('slot') as FormControl).patchValue(
        j + 1,
      );
      (
        this.trainingSlots(i)
          .at(j + 1)
          .get('slot') as FormControl
      ).patchValue(j + 2);
    }
  }

  onCancel() {
    this.router.navigate(['/trainingplan-library']);
  }

  onGoBack() {
    this.location.back();
  }

  onCopySlot(
    trainingSlot: AbstractControl,
    trainingDay: AbstractControl,
    position: number,
  ) {
    (trainingDay.get('trainingSlots') as FormArray).insert(
      position + 1,
      _.cloneDeep(trainingSlot),
    );

    this.renumberSlots(trainingDay);
  }

  renumberSlots(td: AbstractControl) {
    (td.get('trainingSlots') as FormArray).controls.forEach(
      (slotForm, index) => {
        slotForm.get('slot')?.patchValue(index + 1);
      },
    );
  }

  onDeleteSlot(
    trainingSlot: AbstractControl,
    trainingDay: AbstractControl,
    position: number,
  ) {
    (trainingDay.get('trainingSlots') as FormArray).removeAt(position);
    this.renumberSlots(trainingDay);
  }

  getParameterFromTrainingSlot(trainingSlot: AbstractControl, param: string) {
    return trainingSlot.get(param)?.value;
  }

  onSelectedExerciseFromDatabase(event: boolean) {
    this.toggleExerciseLibrarySearch = !this.toggleExerciseLibrarySearch;
    this.selectedTrainingSlotForLoad = undefined;
  }

  onExerciseLibraryLoad(trainingSlot: AbstractControl) {
    this.selectedTrainingSlotForLoad = trainingSlot;
    this.toggleExerciseLibrarySearch = !this.toggleExerciseLibrarySearch;
  }

  moveSlotUpNew(trainingSlot: AbstractControl, trainingDay: AbstractControl) {
    if (trainingSlot.get('slot')!.value > 1) {
      this.arrayMove(
        (trainingDay.get('trainingSlots') as FormArray).controls,
        trainingSlot.get('slot')!.value - 1,
        trainingSlot.get('slot')!.value - 2,
      );
      this.renumberSlots(trainingDay);
    }
  }

  moveSlotDownNew(trainingSlot: AbstractControl, trainingDay: AbstractControl) {
    if (
      trainingSlot.get('slot')!.value <
      (trainingDay.get('trainingSlots') as FormArray).controls.length
    ) {
      this.arrayMove(
        (trainingDay.get('trainingSlots') as FormArray).controls,
        trainingSlot.get('slot')!.value - 1,
        trainingSlot.get('slot')!.value,
      );
      this.renumberSlots(trainingDay);
    }
  }

  arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  log(value: any) {
    if (this.run) {
      console.log(value);
      this.run = false;
    }
  }

  calculateExNameWidth() {
    let val = this.findMaxLengthOfInputInForm('exerciseName', 8.5, 30);
    if (val > this.exerciseNameWidth) {
      this.exerciseNameWidth = val;
    }
  }
  findMaxLengthOfInputInForm(parameter: string, val: number, offset: number) {
    let lenghtValues: number[] = [];
    (this.trainingPlanForm?.get('trainingDays') as FormArray).controls.forEach(
      (control) => {
        (control.get('trainingSlots') as FormArray).controls.forEach((slot) => {
          if (slot.get(parameter)!.value === null) {
            lenghtValues.push(15);
          } else {
            lenghtValues.push((slot.get(parameter)!.value as string).length);
          }
        });
      },
    );

    return Math.max(...lenghtValues) * val + offset;
  }
}
