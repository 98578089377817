import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { ChartItem, ChartType, Chart } from 'chart.js';
import { format, startOfToday } from 'date-fns';
import { WeightEntryData } from 'src/app/core/thecoach';

@Component({
  selector: 'app-weightlog-line-chart-dashboard',
  template: `
  <div class="flex w-full flex-row">
    
    <div (window:resize)="onResize($event)" class="w-full">
      <canvas id="myChart" class="relative w-fit h-fit">
        
        
        {{
        weightLogChart
      }}</canvas>
    </div>

    <div class="relative w-fit">
      <div (click)="reverseDataset()" class="absolute right-0 -top-4 pointer-cursor">
        <button  type="button" class="rounded-full z-10 bg-teal-600 p-1.5 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
</svg>

</button>
      </div>
    </div>
    </div>
  `,
  styles: [],
})
export class WeightlogLineChartDashboardComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.createChart();

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['logs'].currentValue &&
      changes['logs'].currentValue.length !==
      changes['logs'].previousValue?.length
    ) {
      if (!this.firstrun) {
        this.createChart();
      }
      this.firstrun = false;
    }
  }

  @Input() logs!: WeightEntryData[] | null;

  weightLogChart: ChartItem | undefined;
  lineChartType: ChartType = 'line';

  firstrun = true;
  firstreverse = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.logs) {

      this.logs = this.logs.filter((log) => log.weight);
      this.createChart();
    }
  }

  reverseDataset() {
    if (this.logs) {
      this.logs = this.logs.reverse()

      this.createChart();
    }

  }

  onResize(event: any) {
    this.createChart();
  }

  getWeightLogGraphData(data: WeightEntryData[]) {
    let today = startOfToday();
    today.setHours(23, 59, 59, 59);

    return data.filter((item) => {
      let itemDate = new Date(item.date!);

      return itemDate <= today;
    });
  }

  createChart() {
    if (this.logs) {
      this.getWeightLogGraphData(this.logs);

      const dates = this.logs
        .map((log) => format(log.date!, 'dd-MM'))
        .reverse();
      const weights = this.logs.map((log) => log.weight).reverse();

      let minDataValue = Math.min(...(weights as number[])) - 0.5;
      let maxDataValue = Math.max(...(weights as number[])) + 0.5;

      if (Chart.getChart('myChart') != undefined) {
        Chart.getChart('myChart')!.destroy();
      }

      this.weightLogChart = new Chart('myChart', {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'Weight',
              data: weights,
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              tension: 0.2,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              ticks: {
                display: false,
              },
              suggestedMin: minDataValue,
              suggestedMax: maxDataValue,
            },
            x: {
              ticks: {
                display: true,
              },
            },
          },
        },
      });
    }
  }
}
