import { Ingredient } from '../../../../../backend/node_modules/@prisma/client';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Unit } from '../../../../../backend/node_modules/@prisma/client';
import { animate, style, transition, trigger } from '@angular/animations';
import * as firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { manipulateNumbersUp } from '../../../../../backend/core/thecoach';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-new-ingredient-modal',
  template: `
   <div @opacity *ngIf='isModal' class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div @opacityTranslateY *ngIf='isModal' class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">New Ingredient</h3>
            <div class="mt-2 flex justify-center">
              <form [formGroup]="ingredientForm" id="ingredientForm" (ngSubmit)="onSubmit()" class="flex flex-col w-fit justify-center">

  <div class="flex flex-row w-full justify-between items-center">
    <label for="name" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Name</label>
    <div class="mt-2 w-7/12">
      <input type="text" id="name" formControlName="name" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" required />
    </div>
  </div>

  <div class="flex flex-row w-full justify-between items-center">
    <label for="merchant" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Merchant</label>
    <div class="mt-2 w-7/12">
      <input type="text" id="merchant" formControlName="merchant" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>



  <div class="flex flex-row w-full justify-between items-center">
    <label for="packageSize" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Package Size</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="packageSize" formControlName="packageSize" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

  <div class="flex flex-row w-full justify-center items-center">
    <label for="packageSize" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Package Unit</label>
    <div class="mt-2 w-7/12">
<div class="relative inline-block text-left">
  <div>
    <button type="button" (click)="toggleMenu()" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
      {{selectedUnit}}
      <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
      </svg>
    </button>
  </div>
<div @opacityScale *ngIf='isMenu' class="absolute left-0 z-30  mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="py-1" role="none">
      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
      <div *ngFor="let unit of ingredientUnit | keyvalue" (click)="onSelectUnit(unit.value)" href="#" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">{{unit.value}}</div>
    </div>
  </div>
</div>
<div *ngIf='isMenu' (click)="toggleMenu()" class="fixed inset-0"></div>

    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="packageCost" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Package Cost</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="packageCost" formControlName="packageCost" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="protein" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Protein</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="protein" formControlName="protein" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" required />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="fat" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Fat</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="fat" formControlName="fat" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" required />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="saturatedFat" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Saturated Fat</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="saturatedFat" formControlName="saturatedFat" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="carb" class="block text-sm font-medium leading-6 text-gray-900">Carb</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="carb" formControlName="carb" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" required />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="sugar" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Sugar:</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="sugar" formControlName="sugar" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="fibre" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Fibre</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="fibre" formControlName="fibre" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

  <div class="flex flex-row w-full justify-between items-center">
    <label for="fibre" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Salt</label>
    <div class="mt-2 w-7/12">
      <input type="number" id="salt" formControlName="salt" class="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

<div class="flex flex-row w-full justify-between items-center">
    <label for="public" class="block text-sm font-medium leading-6 text-gray-900 pr-4">Public</label>
    <div class="mt-2 w-7/12">
      <input type="checkbox" id="public" formControlName="public" class="block w-4 h-4 text-teal-600 border-gray-300 rounded-md focus:ring-teal-500" />
    </div>
  </div>

</form>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button type="submit" form="ingredientForm" [disabled]="!ingredientForm.valid || ingredientForm.pristine" class="disabled:opacity-25 inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 sm:col-start-2">
            <div *ngIf="uploadingToggler; else textView " class="flex flex-row items-center">
                <object type="image/svg+xml" data="../../assets/icons/ring-resize.svg" class="invert"></object>
                <p class="text-sm pl-2">Processing...</p>
            </div>
            <ng-template #textView>
              Submit
            </ng-template>

          </button>
          <button type="button" (click)="toggleModal()" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>

  `,
  styles: [
  ],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class NewIngredientModalComponent implements OnInit {
  @Input() editIng?: Ingredient;
  @Output() editChanges = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter();

  ingredientUnit = Unit
  isModal = true;
  isMenu = false;
  uploadingToggler = false;
  selectedUnit: string = Unit.g


  constructor(private fb: FormBuilder, private http: HttpClient, private httpUser: HttpUserService) { }
  ngOnInit(): void {
    if (this.editIng) {
      this.ingredientForm.patchValue(this.editIng)
      if (this.editIng.packageUnitSize) {
        const unitVal = this.editIng.packageUnitSize
        this.selectedUnit = unitVal
        this.ingredientForm.get('packageUnitSize')?.patchValue(unitVal)
      }
      // console.log(this.ingredientForm)
    }

  }

  ingredientForm = this.fb.group({
    name: ['', Validators.required],
    merchant: [''],
    packageSize: [0],
    packageUnitSize: ['g'],
    packageCost: [0],
    protein: [0, Validators.required],
    fat: [0, Validators.required],
    saturatedFat: [0],
    carb: [0, Validators.required],
    sugar: [0],
    fibre: [0],
    salt: [0],
    public: true,

  });



  toggleModal() {
    this.cancel.emit();
    this.isModal = !this.isModal;
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  toggleLoadingSpinner() {
    this.uploadingToggler = !this.uploadingToggler
  }

  onSelectUnit(val: string) {
    this.selectedUnit = val
    this.ingredientForm.get('packageUnitSize')?.patchValue(val)
    this.toggleMenu();
  }




  onSubmit() {
    this.toggleLoadingSpinner()
    if (this.ingredientForm) {
      const formData = this.ingredientForm.value;

      this.httpUser.currentUser$.pipe(first()).subscribe(user => {
        if (this.editIng) {
          let mergedData = { ...this.editIng, ...formData };
          //console.log(mergedData);
          if (user) {
            mergedData = manipulateNumbersUp(mergedData as Ingredient)
            user.getIdToken(true).then((idToken) => {
              const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })
              this.http.patch(environment.apiUrl + '/ingredients/' + mergedData.id, {

                ingredient: mergedData

              }, { headers: headers }).subscribe(res => {
                //console.log('Server responded', res)
                this.editChanges.emit(true)
                this.toggleLoadingSpinner()
                this.toggleModal()
              }), (error: Error) => {
                console.error('Error', error)
                this.toggleLoadingSpinner()
                this.editChanges.emit(false)
              }
            })
          }


        } else {

          if (user) {
            user.getIdToken(true).then((idToken) => {
              const data = manipulateNumbersUp(formData as Ingredient)
              const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })
              this.http.post(environment.apiUrl + '/ingredients', {

                ingredient: data

              }, { headers: headers }).subscribe(res => {
                // console.log('Server responded', res)
                this.editChanges.emit(true);
                this.toggleLoadingSpinner()
                this.toggleModal()
              }), (error: Error) => {
                console.error('Error', error)
                this.toggleLoadingSpinner()
                this.editChanges.emit(false);
              }
            })
          }



        }
      })




    }

  }
}










