import { Component, Input } from '@angular/core';
import {
  AppUser,
  Macros,
  WeightEntryData,
  calcuclateDailydifferenceMaster,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateKcalFromMacros,
  calculateRoglDailyAbsoluteMaster,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
  calculateRoglWeeklyPercentageMaster,
  calculateSevenDayAverageMaster,
} from 'src/app/core/thecoach';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';

@Component({
  selector: '[app-weightlog-custombody-dashboard]',
  template: `
    <tr
      class="divide-x divide-gray-200"
      *ngIf="weightEntry"
      [ngClass]="{
        'bg-gray-200': j % 7 === 0
      }"
    >
      <td
        class="whitespace-nowrap w-[5.25rem] text-center py-0.5 pl-4 pr-1 text-sm font-medium text-gray-900 sm:pl-0"
      >
        {{ weightEntry.date | date: 'dd-MM-yyyy' }}
      </td>
      <td
        class="whitespace-nowrap w-[3.25rem] text-center py-0.5 px-1 text-sm text-gray-500 flex flex-row justify-center"
      >
        {{
          weightEntry.weight !== undefined
            ? (weightEntry.weight | number: '1.1-2')
            : '-'
        }}

        <div
          class="group flex relative items-center pl-0.5"
          *ngIf="weightEntry.comment"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 stroke-yellow-600"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <span
            class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-[20%] translate-y-2 opacity-0 m-4 mx-auto"
            >{{ weightEntry.comment }}</span
          >
        </div>
      </td>

      <td
        class="whitespace-nowrap min-w-[2.75rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateSevenDayAverage(j, client) }}
      </td>
      <td
        class="whitespace-nowrap min-w-[4rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateRoglDailyAbsolute(weightEntry.weight!, j, client) }}
      </td>
      <td
        class="whitespace-nowrap min-w-[4rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateRoglDaily(weightEntry.weight!, j, client) }}
      </td>
      <td
        class="whitespace-nowrap min-w-[4rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateRoglWeekly(j, client) }}
      </td>
      <td
        class="whitespace-nowrap min-w-[4rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateRoglWeeklyPercentage(j, client) }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{
          weightEntry.refeedMacros?.protein || weightEntry.setMacros?.protein
        }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.refeedMacros?.carbs || weightEntry.setMacros?.carbs }}
      </td>
      <td
        class="whitespace-nowrap text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.refeedMacros?.fat || weightEntry.setMacros?.fat }}
      </td>

      <td
        class="whitespace-nowrap w-[3rem] text-center py-0.5 px-1 text-sm text-gray-500"
        *ngIf="weightEntry.refeedMacros; else setMacros"
      >
        {{ calculateKcal(weightEntry.refeedMacros!) }}
      </td>
      <ng-template #setMacros>
        <td
          class="whitespace-nowrap min-w-[3rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ calculateKcal(weightEntry.setMacros!) }}
        </td>
      </ng-template>

      <ng-container *ngIf="client.enableOffDayMacros">
        <td
          class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ weightEntry.offDayMacros?.protein || '-' }}
        </td>
        <td
          class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ weightEntry.offDayMacros?.carbs || '-' }}
        </td>
        <td
          class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
        >
          {{ weightEntry.offDayMacros?.fat || '-' }}
        </td>

        <td
          class="whitespace-nowrap text-center min-w-[3rem] py-0.5 px-1 text-sm text-gray-500"
        >
          {{ calculateKcal(weightEntry.offDayMacros!) }}
        </td>
      </ng-container>

      <td
        class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.achivedMacros?.protein || '-' }}
      </td>
      <td
        class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.achivedMacros?.carbs || '-' }}
      </td>
      <td
        class="whitespace-nowrap min-w-[2rem] text-center py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.achivedMacros?.fat || '-' }}
      </td>

      <td
        class="whitespace-nowrap text-center min-w-[3rem] py-0.5 px-1 text-sm text-gray-500"
      >
        {{ calculateKcal(weightEntry.achivedMacros!) }}
      </td>

      <td
        *ngIf="client.enableTraining"
        class="whitespace-nowrap text-center min-w-[5rem] py-0.5 px-1 text-sm text-gray-500"
      >
        {{ weightEntry.sessionName }}
      </td>
      <ng-container
        *ngIf="
          displayCTV &&
          weightEntry.customTrackingLog &&
          weightEntry.customTrackingLog.ccts
        "
      >
        <td
          class="whitespace-wrap px-1 text-xs text-gray-500 text-center"
          *ngFor="let cct of weightEntry.customTrackingLog.ccts"
        >
          <div *ngIf="cct.type !== 'boolean'">
            <div
              *ngIf="
                cct.type === 'textShort' || cct.type === 'textLong';
                else number
              "
            >
              <div
                class="group flex relative items-center pl-0.5"
                *ngIf="cct.value"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>

                <span
                  class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute
    -translate-x-[100%] translate-y-4 opacity-0 m-4 mx-auto"
                  >{{ cct.value }}</span
                >
              </div>
            </div>
            <ng-template #number>
              {{ cct.value }}
            </ng-template>
          </div>

          <div *ngIf="cct.type === 'boolean'">
            <div *ngIf="cct.value === true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-green-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div *ngIf="cct.value === false || !cct.value">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 stroke-red-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div>
        </td>
      </ng-container>
    </tr>
  `,
  styles: [],
})
export class WeightlogCustombodyDashboardComponent {
  constructor(protected ctmService: CustomTrackingModelService) {}
  @Input({ required: true }) weightEntry!: WeightEntryData | null;
  @Input({ required: true }) weightLogData!: WeightEntryData[] | null;
  @Input() j!: number;
  @Input() displayCTV!: boolean;
  @Input() client!: AppUser;

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  calculateDailydifference(
    weight: number,
    index: number,
    client: AppUser,
  ): string {
    return calcuclateDailydifferenceMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateDifferenceFromStart(index: number, client: AppUser): string {
    return calculateDifferenceFromStartMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateFiveDayAverage(index: number, client: AppUser): string {
    return calculateFiveDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateSevenDayAverage(index: number, client: AppUser): string {
    return calculateSevenDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglDaily(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglDailyAbsolute(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyAbsoluteMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
  calculateRoglWeekly(index: number, client: AppUser): string {
    return calculateRoglWeeklyMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
  calculateRoglWeeklyPercentage(index: number, client: AppUser): string {
    return calculateRoglWeeklyPercentageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
}
