import { UserService } from './../../services/user.service';
import { ClipboardService } from 'ngx-clipboard';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { format } from 'date-fns';
import { Subscription, first, switchMap } from 'rxjs';
import { AppUser, convertDateObject } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { Timestamp } from 'firebase/firestore';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-customer-settings',
  template: `
    <div>
      <form
        class="space-y-8 divide-y divide-gray-200"
        [formGroup]="userForm"
        (ngSubmit)="onSave()"
      >
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div class="space-y-6 sm:space-y-5">
            <div>
              <h3 class="text-base font-semibold leading-6 text-gray-900">
                Your Profile
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Welcome to your personal profile, change settings here.
              </p>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="photo"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Photo</label
              >
              <div
                class="mt-2 ml-4 sm:col-span-2 sm:mt-0 flex flex-row justify-between"
              >
                <div class="flex items-center">
                  <span
                    class="h-12 w-12 overflow-hidden rounded-full bg-gray-100"
                  >
                    <div *ngIf="!onCheckUserImgUrl()">
                      <svg
                        class="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </div>
                    <div *ngIf="onCheckUserImgUrl()">
                      <img src="{{ user.imgUrl }}" />
                    </div>
                  </span>
                  <label
                    for="file-upload"
                    class="ml-5 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </label>

                  <input
                    type="file"
                    id="file-upload"
                      accept="image/*"
                    class="hidden"
                    (change)="onChangeProfilePicture($event)"
                  />
                </div>
                <div *ngIf="this.file">
                  <app-upload-profile-picture
                    [file]="this.file"
                    class="hidden"
                    (imgUrl)="onChangeUserImgUrl($event)"
                  ></app-upload-profile-picture>
                </div>
              </div>
            </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Email</label
              >
              <div class="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  formControlName="email"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                  aria-describedby="email-description"
                />
              </div>
            </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Displayname</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  name="displayName"
                  id="displayName"
                  formControlName="displayName"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="John Wayne"
                />
              </div>
            </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Coach ID</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  name="coachId"
                  id="coachId"
                  formControlName="coachId"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="NQ6GjjZNKcjSZvZAAhx"
                />
              </div>
              <p class="mt-2 text-sm text-gray-500" id="email-description">
                Enter your Coaches ID here! (provided by your Coach)
              </p>
            </div>

            <div>
              <label
                for="id"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Your Customer ID</label
              >
              <div class="mt-2" *ngIf="user">
                <button
                  type="button"
                  (click)="onCopyToClipboard(user.id!)"
                  class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  {{ user.id }}
                </button>
              </div>
              <p class="mt-2 text-sm text-gray-500" id="id">
                Prvoide this ID to your Coach for Synchronisation.
              </p>
            </div>

            <div class="mt-2 flex flex-row items-center">
              <div *ngIf="tokenCheck; else noPermission">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 stroke-green-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>

              <ng-template #noPermission>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 stroke-red-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </ng-template>
              <button
                *ngIf="tokenCheck"
                (click)="getPermToken()"
                type="button"
                class="ml-2 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Refresh Notification Permission
              </button>
              <button
                *ngIf="!tokenCheck"
                (click)="getPermToken()"
                type="button"
                class="ml-2 cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Get Notification Permission
              </button>
            </div>

            <div>
              <label
                for="birthdate"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Birthdate</label
              >
              <div class="mt-2">
                <input
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  formControlName="birthdate"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="flex flex-row">
              <div class="mr-4 pr-4 border-r-2 ">
                <label
                  for="startingWeight"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Starting Weight</label
                >
                <div class="relative mt-2 w-24">
                  <input
                    type="number"
                    name="startingWeight"
                    id="startingWeight"
                    formControlName="startingWeight"
                    class="block w-24 rounded-md border-0 py-1.5text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                    placeholder="150.56"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <span class="text-gray-400 italic text-sm">kg</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <label
                    for="height"
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Height</label
                  >
                  <div class="relative mt-2 w-24">
                    <input
                      type="number"
                      name="height"
                      id="height"
                      formControlName="height"
                      class="block w-24 rounded-md border-0 py-1.5text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                      placeholder="180.5"
                    />
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <span class="text-gray-400 italic text-sm">cm</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end gap-x-3">
            <button
              type="button"
              class="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="inline-flex justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <app-notification
        *ngIf="toggleSave"
        [messageHeader]="'Settings saved'"
        [messageBody]="'Settings has been saved.'"
        (onbuttonClick)="changeToggle()"
      >
      </app-notification>
    </div>
  `,
  styles: [],
})
export class CustomerSettingsComponent implements OnInit, OnDestroy{
  user: AppUser = {};
  appUser$ = this.auth.appUser$;
  file: File | undefined;
  tokenCheck = false;

  toggleSave = false;

  userSubscription : Subscription | undefined;
  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private clipBoardService: ClipboardService,
    private userService: UserService,
    private notification: NotificationService
  ) { }
    ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  userForm = this.fb.group({
    id: [''],
    imgUrl: [''],
    email: new FormControl('', [Validators.required]),
    displayName: new FormControl(''),
    coachId: new FormControl(''),
    birthdate: new FormControl('', ),
    startingWeight: new FormControl('', ),
    height: new FormControl('', ),
  });

  getPermToken() {
    this.notification.requestPermission();
    this.checkIfTokenExists();
  }

  log(){
    console.log(this.userForm)
  }

  onSave() {

    /*
    if(this.user.imgUrl && this.user.imgUrl.includes("100x100")){
      const convertedUrl = this.user.imgUrl;
      this.userForm.get('imgUrl')?.patchValue(convertedUrl)
    }
*/

    this.user = this.userForm.value as AppUser;
    this.user.birthdate = convertDateObject(this.userForm.get('birthdate')?.value as string)

    this.userService.saveUserData(this.user).then(() => {
      this.toggleSave = !this.toggleSave;
    });
    // this.authService.SetUserData(this.userForm.value);
  }

  changeToggle() {
    this.toggleSave = !this.toggleSave;
  }

  checkIfTokenExists() {
    this.notification
      .checkToken()
      .pipe(first())
      .subscribe((token: string | null) => {
        if (token === null || token === undefined) {
          this.tokenCheck = false;
        } else if (typeof token === 'string' && token.length > 0) {
          this.tokenCheck = true;
        }
      });
  }

  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$.subscribe((appUser) => {
      this.user = appUser as AppUser;
      if (this.user) {
        this.patchOnLoad(this.user);
      }
    });
    this.checkIfTokenExists();
  }

  onChangeUserImgUrl(downloadUrl: string) {
    if (downloadUrl) {
     // this.userForm.get('imgUrl')?.patchValue(downloadUrl);
      this.user.imgUrl = downloadUrl;
    }
  }

  onChangeProfilePicture(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      this.file = target.files[0];
    }
  }

  onCheckUserImgUrl(): boolean {
    if (this.user) {
      if (!this.user.imgUrl || this.user.imgUrl === '') {
        return false;
      }

      this.userForm.patchValue({
        imgUrl: this.user.imgUrl,
      });
      return true;
    }

    return false;
  }

  onCopyToClipboard(value: string) {
    this.clipBoardService.copyFromContent(value);
  }

  patchOnLoad(user: AppUser) {
    if (user.email) {
      this.userForm.patchValue({
        email: user.email,
      });
    }

    if (user.birthdate) {
      this.userForm.patchValue({
        birthdate: format(convertDateObject(user.birthdate) as Date, 'yyyy-MM-dd'),
      });
    }

    if (user.displayName) {
      this.userForm.patchValue({
        displayName: user.displayName,
      });
    }

    if (user.coachId) {
      this.userForm.patchValue({
        coachId: user.coachId,
      });
    }

    if (user.imgUrl) {
      this.userForm.patchValue({
        imgUrl: user.imgUrl,
      });
    }

    if (user.id) {
      this.userForm.patchValue({
        id: user.id,
      });
    }

    if (user.startingWeight) {
      this.userForm.patchValue({
        startingWeight: user.startingWeight.toString(),
      });
    }

    if (user.height) {
      this.userForm.patchValue({
        height: user.height.toString(),
      });
    }
  }
}

