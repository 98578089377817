import { Pipe, PipeTransform } from '@angular/core';
import { Note } from './core/thecoach';

@Pipe({
  name: 'filterSearchTerm',
})
export class FilterSearchTermPipe implements PipeTransform {
  transform(models: Note[], searchTerm: string): any[] {
    if (!models || !searchTerm) {
      return models;
    }

    searchTerm = searchTerm.toLowerCase();

    return models.filter(
      (model) =>
        model.message.toLowerCase().includes(searchTerm) ||
        model.title.toLowerCase().includes(searchTerm)
    );
  }
}
