import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AppUser } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-navigation',
  template: `
  <div *ngIf="isLoggedIn$ | async;" class="h-full pt-[6rem]">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div
        *ngIf="isOffCanvasMenuDialog"
        class="relative z-40 lg:hidden"
        role="dialog"
        aria-modal="true"
      >
        <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div
          @opacityLinear
          *ngIf="isOffCanvasMenu"
          class="fixed inset-0 bg-gray-600 bg-opacity-75"
        ></div>

        <div
          @translateX
          *ngIf="isOffCanvasMenu"
          class="fixed inset-0 z-40 flex"
        >
          <div
            *ngIf="isOffCanvasMenu"
            (click)="toggleOffCanvasMenu()"
            class="fixed inset-0"
          ></div>

          <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
          <div
            @translateX
            *ngIf="isOffCanvasMenu"
            class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4"
          >
            <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
            <div
              @opacityInOut
              *ngIf="isOffCanvasMenu"
              class="absolute top-0 right-0 -mr-12 pt-2"
            >
              <button
                type="button"
                (click)="toggleOffCanvasMenu()"
                class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span class="sr-only">Close sidebar</span>

                <svg
                  class="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div class="flex flex-shrink-0 items-center px-4">
              <img
                class="h-8 w-auto"
                src=".././assets/icons/TCCLogo-min-white.webp"
                alt="The Coach Cloud"
              />
            </div>
            <div class="mt-5 h-0 flex-1 overflow-y-auto">
              <nav class="space-y-1 px-2">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <a
                  (click)="toggleOffCanvasMenu()"
                  [routerLink]="'/coach-dashboard'"
                  class="bg-gray-900 text-white group flex items-center rounded-md px-2 py-2 text-base font-medium"
                >
                  <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" -->
                  <svg
                    class="text-gray-300 mr-4 h-6 w-6 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Dashboard
                </a>
                <div class="relative flex py-2 items-center">
                  <div class="flex-grow border-t border-gray-400"></div>
                  <span class="flex-shrink mx-4 text-gray-400">Training</span>
                  <div class="flex-grow border-t border-gray-400"></div>
                </div>

                <ng-container *ngFor="let menu of adminMenu; let i = index">
                  <a
                    (click)="toggleOffCanvasMenu(i)"
                    *ngIf="menu.showMenu"
                    [routerLink]="menu.menuRoute!"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-base font-medium"
                    [ngClass]="{
                      'bg-gray-700': i === activeMenuIndex
                    }"
                  >
                    <svg
                      class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        [attr.d]="menu.svgPath"
                      />
                    </svg>
                    {{ menu.menuTitle }}
                  </a>
                </ng-container>

              </nav>
               </div>
          </div>

          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>



      <!-- Static sidebar for desktop -->
      <div
        class="z-21 h-full hidden lg:flex lg:w-64 lg:flex-col"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex min-h-0 flex-1 flex-col bg-gray-800">
          <div class="flex flex-1 flex-col overflow-y-auto">
            <nav class="flex-1 space-y-1 px-2 py-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

              <div class="relative flex py-2 items-center">
                <div class="flex-grow border-t border-gray-400"></div>
                <span class="flex-shrink mx-4 text-gray-400">Admin Panel</span>
                <div class="flex-grow border-t border-gray-400"></div>
              </div>

              <ng-container *ngFor="let menu of adminMenu; let i = index">
                <a
                  (click)="toggleOffCanvasMenu(i)"
                  *ngIf="menu.showMenu"
                  [routerLink]="menu.menuRoute!"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                  [ngClass]="{
                    'bg-gray-700': i === activeMenuIndex
                  }"
                >
                  <svg
                    class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      [attr.d]="menu.svgPath"
                    />
                  </svg>
                  {{ menu.menuTitle }}
                </a>
              </ng-container>
            </nav>


  </div>

  `,
  styles: [],
  animations: [
    trigger('opacityLinear', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms linear', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms linear', style({ opacity: 0 })),
      ]),
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('opacityInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],

})
export class AdminNavigationComponent implements OnInit {
  isOffCanvasMenu = false;
  isOffCanvasMenuDialog = false;
  isMenu = false;
  isLoggedIn$: Observable<boolean> | undefined;
  user: AppUser = {};
  activeMenuIndex: number | undefined;

  observer: ResizeObserver | undefined;

  adminMenu: {
    showMenu: boolean;
    menuTitle: string;
    menuRoute: string;
    svgPath: string;
  }[] = [];



  constructor(private authService: AuthService) { }
  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedInAppUser;
    this.initMenu();

  }

  toggleOffCanvasMenu(index?: number) {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
    if (index || index === 0) this.activeMenuIndex = index;

    if (this.isOffCanvasMenuDialog) {
      setTimeout(() => {
        this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
      }, 400);
    } else {
      this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
    }
  }



  initMenu() {
    this.adminMenu = [
      {
        showMenu: true,
        menuTitle: 'Template Trainingplans',
        menuRoute: '/trainingplan-library',
        svgPath:
          'M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z',
      },
      {
        showMenu: true,
        menuTitle: 'Assigned Traininplans',
        menuRoute: '/coach-assigned-trainingplans',
        svgPath:
          'M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75',
      },
      {
        showMenu: true,
        menuTitle: 'Exercise Library',
        menuRoute: '/exercise-library',
        svgPath:
          'M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z',
      },
    ];


  }
}
