import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { Observable, Subscription, finalize, first, tap } from 'rxjs';
import { AppUser } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-upload-profile-picture',
  template: `
    <div class="w-full justify-center" *ngIf="percentage | async as pct">
      <div class="shadown w-full bg-grey-light">
        <div
          class="bg-teal text-xs leading-none py-1 text-center text-white"
          style="width:{pct| number}"
        >
          {{ pct | number }}%
        </div>
        <div *ngIf="snapshot | async as snap" class="w-fit">
          {{ snap.bytesTransferred / 1024 | number : '1.0-1' }} of
          {{ snap.totalBytes / 1024 | number : '1.0-1' }} KB
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class UploadProfilePictureComponent implements OnInit {
  @Input() file: File | undefined;
  task: AngularFireUploadTask | undefined;
  percentage: Observable<number> | undefined;
  snapshot: Observable<any> | undefined;
  downloadUrl: string = '';

  uerSubscription: Subscription | undefined;
  user: AppUser | undefined;

  @Output() imgUrl = new EventEmitter<string>();

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.auth.appUser$.pipe(first()).subscribe((appUser) => {
      this.user = appUser as AppUser;
      this.startUpload();
    });
  }

  startUpload() {
    const id = this.user!.id;

    if (id && this.file) {
      // console.log('starting Upload', this.file);
      let safeName = this.file.name.replace(/([^a-zA-Z0-9]+)/gi, '');
      let timestamp = Date.now();
      const uniquqeSafeName = timestamp + '_' + safeName;

      const path = id + '/profilepicture/' + uniquqeSafeName;
      const ref = this.storage.ref(path);

      const metaData = {
        destination: 'profilepicture',
        userId: id,
      }

      //  console.log(metaData)
      this.task = this.storage.upload(path, this.file, { customMetadata: metaData, });
      this.percentage = this.task.percentageChanges() as Observable<number>;

      this.snapshot = this.task.snapshotChanges().pipe(
        tap(),
        finalize(async () => {
          this.downloadUrl = await ref.getDownloadURL().toPromise();

          this.imgUrl.emit(this.downloadUrl);

          this.db
            .collection('users')
            .doc(id)
            .set(
              {
                imgUrl: this.downloadUrl,
              },
              { merge: true }
            )
            .then(() => {
              //  console.log('document written!');
            })
            .catch((error) => {
              console.error('error writing document', error);
            }
            );

        })
      );
    }
  }

  isActive(snapshot: any) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
