import { CustomerSettingsComponent } from './../../CustomerView/customer-settings/customer-settings.component';
import { WeightlogService } from 'src/app/services/weightlog.service';
import { UserService } from './../../services/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  Observable,
  Subscription,
  first,
  forkJoin,
  map,
  of,
  pipe,
  take,
} from 'rxjs';
import {
  AppUser,
  WeightEntryData,
  calculateAgeMaster,
  convertDateObject,
} from 'src/app/core/thecoach';
import { Timestamp } from 'firebase/firestore';
import { add, differenceInYears, format, parse } from 'date-fns';
import { Router } from '@angular/router';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { Invite, UserConfig } from '../../../../../backend/core/thecoach';
import { ClipboardService } from 'ngx-clipboard';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as _ from 'lodash';
import { InviteService } from 'src/app/services/inviteSystem/invite.service';
import { cl } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-customer-library',
  template: `
    <div *ngIf="quantity$ | async as userConfig; else noSpots">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between flex-row">
          <div class="flex w-[20rem]  py-4">
            <div class="flex w-full  flex-col">
              <label
                for="search"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Search by Name</label
              >
              <div class="mt-2">
                <input
                  type="search"
                  name="search"
                  id="search"
                  [(ngModel)]="searchNameValue"
                  (keyup)="onKeyDownSearch($event)"
                  (input)="onClearSearch()"
                  class="block  w-full rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
                  placeholder="Enter Client Name..."
                />
              </div>
            </div>
          </div>

          <div
            class="w-fit flex flex-col text-sm font-medium leading-6 text-gray-900 text-center items-center pt-4"
          >
            <div *ngIf="quantity$ | async as userConfig; else noSpots">
              <!-- Use subscriptionData in your template -->
              <div *ngIf="user.clientIds; else noIds" class="flex flex-col">
                <p>Coaching Spots: {{ userConfig.quantity }}</p>
                <p>Clients: {{ user.clientIds.length }}</p>
              </div>
              <ng-template #noIds
                ><p>Coaching Spots: {{ userConfig.quantity }}</p></ng-template
              >
              <div>
                Next Payment due:
                {{ userConfig.currentPeriodEnd * 1000 | date: 'dd-MM-yyyy' }}
              </div>
              <button
                type="button"
                (click)="reRouteToMangement()"
                class="flex w-fit h-fit items-center whitespace-nowrap rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Manage Coaching Spots
              </button>
            </div>
          </div>
          <div class="flex items-center  flex-col">
            <button
              type="button"
              *ngIf="!addClientManuallyToggle"
              [disabled]="
                !checkSpotAvailability(userConfig.quantity) ||
                checkEquality(userConfig.quantity)
              "
              (click)="reRouteToPayment()"
              class="flex h-fit items-center hidden disabled:opacity-25 whitespace-nowrap rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Add Client manually
            </button>

            <button
              type="button"
              *ngIf="addClientManuallyToggle"
              (click)="toggleManualClientAdd()"
              class="flex items-center h-fit rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              Close
            </button>

            <div class="mt-4 text-center">
              <label
                for="regLink"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Registration Link</label
              >

              <div class="mt-2 flex justify-center items-center">
                <button
                  *ngIf="!user.registrationLinkId"
                  type="button"
                  (click)="onCopyToClipboard(createRegLink(user))"
                  class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Create
                </button>
                <div
                  *ngIf="user.registrationLinkId"
                  class="flex flex-row justify-between"
                >
                  <button
                    type="button"
                    (click)="onCopyToClipboard(getRegLink(user))"
                    class="cursor-pointer hidden rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Copy to Clipboard
                  </button>
                  <button
                    type="button"
                    (click)="createRegistrationLink()"
                    class="cursor-pointer rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-400  italicshadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Create New
                  </button>
                </div>
              </div>
              <p class="mt-2 text-sm text-gray-500" id="regLink">
                Prvoide this Link to your Client for Registration.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="addClientManuallyToggle">
        <form #CustomerForm="ngForm" (ngSubmit)="onAddClient()">
          <div class="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class=" mx-auto h-10 w-10 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
              />
            </svg>

            <p class="mt-1 text-sm text-gray-500">
              Get started by adding a new client.
            </p>

            <div class="flex justify-center text-center mt-2">
              <label for="CustomerId" class="sr-only">ClientId</label>
              <input
                type="text"
                name="CustomerId"
                id="CustomerId"
                [(ngModel)]="clientId"
                class="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                placeholder="NQ6GjjZNKcjSZvZAAhx"
              />
            </div>
            <div class="mt-6 pb-4">
              <button
                [disabled]="clientId.length < 19"
                type="submit"
                class="inline-flex items-center disabled:opacity-25 rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                <svg
                  class="-ml-0.5 mr-1.5 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                  />
                </svg>
                Add Client
              </button>
            </div>
          </div>
        </form>
      </div>
      <hr class="my-4" />
      <div class="flex flex-row w-full">
        <div class="w-6/12 flex justify-center items-center flex-col">
          <h1 class="text-xl font-bold uppercase">Pending Clients</h1>
          <ul
            role="list"
            class="space-y-3"
            *ngIf="
              this.newPendingClientDatabase &&
              this.newPendingClientDatabase.size > 0
            "
          >
            <div
              *ngFor="
                let pendingClient of newPendingClientDatabase | keyvalue;
                let j = index
              "
            >
              <li
                class="overflow-hidden w-fit bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
              >
                <div class="relative">
                  <div class="flex flex-col ml-4">
                    <h3>
                      {{ pendingClient.value.displayName }}
                    </h3>
                    <div class="text-gray-400 text-xs italic">
                      <p>ID: {{ pendingClient.value.id }}</p>
                      <p>E-Mail: {{ pendingClient.value.email }}</p>
                    </div>
                    <div class="flex w-full justify-between">
                      <button
                        (click)="addClientToCoach(pendingClient.value)"
                        [disabled]="
                          !checkSpotAvailability(userConfig.quantity) ||
                          checkEquality(userConfig.quantity)
                        "
                        type="button"
                        class="mt-2 inline-flex disabled:opacity-25 items-center w-fit rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        <svg
                          class="-ll-0.5 mr-1.5 h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                          />
                        </svg>
                        Add Client
                      </button>
                      <button
                        *ngIf="
                          !this.coachSpotClient && userConfig.quantity >= 1
                        "
                        (click)="addClientToCoachSpot(pendingClient.value)"
                        type="button"
                        class="mt-2 inline-flex disabled:opacity-25 items-center w-fit rounded-md bg-teal-600 px-1.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        Coach Spot
                      </button>
                    </div>
                  </div>
                  <div
                    (click)="onDeletePendingClient(pendingClient.value)"
                    class="absolute -top-4 -right-6 text-red-400 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="flex  w-6/12 justif-center flex-col items-center">
          <h1 class="text-xl font-bold uppercase">Your Coaching Spot</h1>
          <div *ngIf="coachSpotClient; else addCoachingSpotClient">
            <app-customer-panel
              [client]="coachSpotClient"
              [user]="user"
              [quantity]="1"
              (delete)="onToggleDeleteClient($event)"
            />
          </div>
          <ng-template #addCoachingSpotClient
            ><p class="italic text-gray-600">
              You can add yourself as a Client.
            </p>
          </ng-template>
        </div>
      </div>
      <hr class="my-2" />

      <div
        class="w-full text-center"
        *ngIf="!checkSpotAvailability(userConfig.quantity)"
      >
        <h3 class="font-semibold text-red-600">
          You do not have enough available seats for your subscription. Please
          purchase additional seats to continue.
        </h3>
      </div>
      <div *ngIf="this.user" class="flex justify-center items-center flex-col">
        <ul
          *ngIf="contentLoaded; else clientloading"
          role="list"
          class=" w-full grid  grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
        >
          <div *ngFor="let client of newDisplayClients; let i = index">
            <app-customer-panel
              [client]="client"
              [user]="user"
              [quantity]="userConfig.quantity"
              (delete)="onToggleDeleteClient($event)"
            />
          </div>

          <!-- More items... -->
        </ul>
        <ng-template #clientloading>
          <ngx-skeleton-loader
            count="5"
            [animation]="'pulse'"
          ></ngx-skeleton-loader>
        </ng-template>
      </div>
    </div>

    <ng-template #noSpots>
      <button
        type="button"
        (click)="reRouteToPayment()"
        class="flex w-fit h-fit items-center whitespace-nowrap rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
      >
        Buy Coaching Spots
      </button>
    </ng-template>

    <div>
      <app-simple-modal
        *ngIf="deleteCoachingSpotToggle"
        (close)="onDeleteCoachSpot($event)"
        [positiveButtonText]="'Delete'"
        [negativeButtonText]="'Cancel'"
        [title]="'Delete Coaching Spot?'"
        [message]="'Do you want to delete the Coaching Spot?'"
      >
        ></app-simple-modal
      >
    </div>
    <div>
      <app-simple-modal
        *ngIf="deleteClientToggle"
        (close)="onDeleteClient($event)"
        [positiveButtonText]="'Delete'"
        [negativeButtonText]="'Cancel'"
        [title]="'Delete Client?'"
        [message]="'Do you want to delete this client?'"
      >
        ></app-simple-modal
      >
    </div>

    <app-notification
      *ngIf="toggleNotificationCopyToClipboard"
      [messageHeader]="'Copy to clipboard'"
      [messageBody]="'Link has been copied to clipboard'"
      (onbuttonClick)="changeCopyToClipBoardToggle()"
    >
    </app-notification>

    <app-notification
      *ngIf="toggleNotification"
      [messageHeader]="notificationHeaderStr"
      [messageBody]="notificationBodyStr"
      (onbuttonClick)="onCloseNotification()"
    >
    </app-notification>
    <app-simple-modal
      *ngIf="toggleCreateNewRegId"
      (close)="onCloseModal($event)"
      [title]="'Create new registration link?'"
      [message]="
        'Old registration links will be abandoned and pending clients cannot be retrived!'
      "
      [negativeButtonText]="'Cancel'"
      [positiveButtonText]="'Save'"
    >
    </app-simple-modal>

    <app-simple-loading-modal
      *ngIf="toggleCreateNewInvite"
      [title]="'New Customer Invite'"
      [message]="'Creating new invite for customer...'"
    >
    </app-simple-loading-modal>
  `,
  styles: [],
})
export class CustomerLibraryComponent implements OnInit, OnDestroy {
  clientId: string = '';
  user: AppUser = {};
  coachSubscription: Subscription | undefined;
  clientSubscription: Subscription | undefined;
  waitingClientSubscription: Subscription | undefined;
  pendingClientSubscription: Subscription | undefined;
  weightLogSubscription: Subscription | undefined;
  clientIdDatabase: string[] = [];
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];
  lastweightLogs: { [clientId: string]: WeightEntryData } = {};
  pendingClientDatabase: AppUser[] = [];

  newPendingClientDatabase = new Map();
  newClientDatabase = new Map();
  newDisplayClients: AppUser[] = [];

  deleteClientToggle: boolean = false;
  deleteCoachingSpotToggle: boolean = false;
  selectedClient: AppUser = {};
  coachSpotClient: AppUser | undefined = undefined;

  addClientManuallyToggle: boolean = false;
  userSubscription: Subscription | undefined;
  contentLoaded = false;

  isEnabled = false;
  searchNameValue = '';

  quantity$: Observable<UserConfig | undefined> | undefined;
  isSpotAvailable = false;

  toggleNotificationCopyToClipboard = false;
  toggleCreateNewRegId = false;
  toggleCreateNewInvite = false;
  toggleNotification = false;
  notificationHeaderStr: string | undefined;
  notificationBodyStr: string | undefined;
  constructor(
    protected auth: AuthService,
    private weightLogService: WeightlogService,
    protected userService: UserService,
    private fireFunctions: AngularFireFunctions,
    protected subscriptionService: SubscriptionService,
    private clipboardService: ClipboardService,
    private db: AngularFirestore,
    private inviteService: InviteService,
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.auth.appUser$
      .pipe()
      .subscribe((appUser) => (this.user = appUser as AppUser));
    if (this.user) {
      this.getWaitingClientsData();
      this.getPendingClientsData();
      if (this.user.coachSpotId) {
        this.getCoachSpot(this.user.coachSpotId);
      }
      if (this.user.clientIds) {
        this.clientIdDatabase = this.user.clientIds;
        // this.getClientData(this.clientIdDatabase);
        this.getClientDataNew(this.clientIdDatabase);
      }
      this.contentLoaded = true;
      this.quantity$ = this.subscriptionService.getQuantityOfSubscriptions();
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          this.coachSpotClient = client;
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
        } else {
          this.coachSpotClient = undefined;
        }
        // console.log(this.clientDatabase);
      });
  }
  async getClientDataNew(clientIds: string[]) {
    const clientPromises: Promise<any>[] = [];
    //console.log('starting New Function');
    clientIds.forEach((id) => {
      clientPromises.push(
        this.userService.getUserFromDatabase(id).pipe(first()).toPromise(),
      );
    });
    // console.log('entering', clientsPromises);
    await Promise.all(clientPromises)
      .finally(() => {
        //   console.log('finally');
      })
      .then((clients) => {
        clients.forEach((client) => {
          if (client?.coachId === this.user.id) {
            let cl = client as AppUser;

            if (cl.birthdate) {
              cl.birthdate = convertDateObject(cl.birthdate);
            }

            this.newClientDatabase.set(cl.id, cl);
            if (this.clientDatabase.some((e) => e.id === cl.id)) {
              let i = this.clientDatabase.indexOf(cl);
              this.clientDatabase[i] = cl;
            } else {
              this.clientDatabase.push(cl);
            }
          }
        });
        this.newDisplayClients = [...this.newClientDatabase.values()].sort(
          (a: AppUser, b: AppUser) => {
            const nameA = a.displayName ?? '';
            const nameB = b.displayName ?? '';

            return nameA.localeCompare(nameB);
          },
        );

        console.log(this.newDisplayClients);
        this.displayClients = clients;
        return clients;
      })
      .catch((error) => {
        console.error(error);
      });

    //console.log('afterwards', values);
  }

  getClientData(clientIds: string[]) {
    clientIds.forEach((id) => {
      this.clientSubscription = this.userService
        .getUserFromDatabase(id)
        .subscribe((client) => {
          if (client?.coachId === this.user!.id) {
            let cl = client as AppUser;

            if (cl.birthdate) {
              cl.birthdate = convertDateObject(cl.birthdate);
            }

            if (this.clientDatabase.some((e) => e.id === cl.id)) {
              let i = this.clientDatabase.indexOf(cl);
              this.clientDatabase[i] = cl;
            } else {
              this.clientDatabase.push(cl);
            }
          }

          this.displayClients = this.clientDatabase;
        });
    });
  }
  /*
  getClientDataNew(clientIds: string[]) {
    const obs: Observable<AppUser | undefined>[] = [];
    console.log('input', clientIds);

    clientIds.forEach((clientId) => {
      obs.push(this.userService.getUserFromDatabase(clientId));
    });

    console.log(obs);
  }
*/
  onReload(val: boolean) {
    this.contentLoaded = false;
    if (val) {
      this.coachSpotClient = undefined;
    }
    this.getClientData(this.clientIdDatabase);
    this.contentLoaded = true;
  }

  log(val: any) {
    console.log(val);
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  onDeleteCoachSpot(event: boolean) {
    if (event) {
      this.contentLoaded = false;
      delete this.user.coachSpotId;
      this.coachSpotClient = undefined;
      this.userService.overWriteUser(this.user);
      this.disableAllToggles(this.selectedClient);
      this.selectedClient.registrationLinkId = this.user.registrationLinkId;
      this.userService.saveUserData(this.selectedClient);
    }
    this.deleteCoachingSpotToggle = false;
    this.contentLoaded = true;
  }

  disableAllToggles(client: AppUser) {
    client.enableCMFs = false;
    client.enableCTMs = false;
    client.enableTraining = false;
    client.enableMealPlans = false;
    client.enableWeightLog = false;
    client.enableMacroTracking = false;
  }

  onDeleteClient(event: boolean) {
    if (event) {
      const index = this.clientDatabase.findIndex(
        (client) => client.id === this.selectedClient.id,
      );
      this.clientDatabase.splice(index, 1);
      this.clientIdDatabase.splice(index, 1);
      this.user.clientIds?.splice(index, 1);
      this.userService.saveUserData(this.user);

      this.selectedClient.coachId = 'NONE';

      this.userService.saveUserData(this.selectedClient);
    }

    this.deleteClientToggle = false;
  }

  onToggleDeleteClient(client: AppUser) {
    if (this.user.coachSpotId && this.user.coachSpotId === client.id) {
      this.deleteCoachingSpotToggle = true;
      this.selectedClient = client;
    } else {
      this.deleteClientToggle = true;
      this.selectedClient = client;
    }
  }

  getWeightLogData(client: AppUser, days: number) {
    this.weightLogSubscription = this.weightLogService
      .getWeightLogDataOfXDaysNew(client.id!, days)
      .valueChanges()
      .subscribe((log: WeightEntryData[]) => {
        if (log && log.length > 0) {
          this.lastweightLogs[client.id as string] = log[0];

          if (
            this.lastweightLogs[client.id as string] &&
            typeof this.lastweightLogs[client.id as string].date === 'string'
          ) {
            this.lastweightLogs[client.id as string].date = new Date(
              this.lastweightLogs[client.id as string].date!,
            );
          } else {
            this.lastweightLogs[client.id as string].date = (
              this.lastweightLogs[client.id as string]
                .date as unknown as Timestamp
            ).toDate();
          }
        }
      });
  }

  onAddClient() {
    if (!this.user) {
      this.userSubscription = this.auth.appUser$
        .pipe(first())
        .subscribe((appUser) => (this.user = appUser as AppUser));
    }

    this.clientSubscription = this.userService
      .getUserFromDatabase(this.clientId)
      .subscribe((client) => {
        if (client?.coachId === this.user.id) {
          if (this.user.clientIds) {
            if (this.user.clientIds.includes(this.clientId)) {
            } else {
              this.user.clientIds?.push(this.clientId);
            }
          } else {
            this.user.clientIds = [this.clientId];
          }

          this.userService.saveUserData(this.user);
        } else {
          window.alert('Client not found!');
        }
      });
  }

  filterCoachSpot() {
    this.coachSpotClient = _.cloneDeep(
      this.clientDatabase.find((client) => client.id === this.user.coachSpotId),
    );
    console.log(this.coachSpotClient);
    if (this.coachSpotClient && this.coachSpotClient.id) {
      const index = this.displayClients.findIndex(
        (client) => client.id === this.coachSpotClient!.id,
      );
      this.displayClients.splice(1, index);
    }
  }

  //This is legacy, dont work in future versions anymore
  getWaitingClientsData() {
    this.waitingClientSubscription = this.userService
      .getClientsFromDatabase(this.user!)
      .subscribe((clients) => {
        console.log(clients);
        this.pendingClientDatabase = clients as AppUser[];
        (clients as AppUser[]).forEach((client: AppUser) => {
          this.newPendingClientDatabase.set(client.id, client);
        });

        //   console.log(this.pendingClientDatabase);
      });
  }

  getPendingClientsData() {
    this.pendingClientSubscription = this.userService
      .getPendingClientsFromDatabase(this.user!)
      .subscribe((clients) => {
        if (!clients) return of([]);
        console.log(clients);
        return (clients as AppUser[]).forEach((client: AppUser) => {
          this.newPendingClientDatabase.set(client.id, client);
        });
      });
  }

  calculateAge(birthday: Date) {
    return calculateAgeMaster(birthday);
  }

  ngOnDestroy(): void {
    this.coachSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
    this.weightLogSubscription?.unsubscribe();
    this.pendingClientSubscription?.unsubscribe();
    this.waitingClientSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  toggleManualClientAdd() {
    this.addClientManuallyToggle = !this.addClientManuallyToggle;
  }

  addClientToCoach(pendingClient: AppUser) {
    if (!this.user) {
      this.userSubscription = this.auth.appUser$
        .pipe()
        .subscribe((appUser) => (this.user = appUser as AppUser));
    }

    pendingClient.coachId = this.user.id;
    Reflect.deleteProperty(pendingClient, 'registrationLinkId');
    Reflect.deleteProperty(pendingClient, 'pending');
    this.userService.deletePendingClientFormDatabase(pendingClient.id!);

    if (this.user.clientIds) {
      this.user.clientIds.push(pendingClient.id!);
    } else {
      this.user.clientIds = [];
      this.user.clientIds.push(pendingClient.id!);
    }

    this.userService.saveUserData(pendingClient);
    this.userService.saveUserData(this.user);
    this.ngOnInit();
  }

  onDeletePendingClient(pendingClient: AppUser) {
    this.newPendingClientDatabase.clear();
    this.userService.deletePendingClientFormDatabase(pendingClient.id!);
  }

  reRouteToPayment() {
    // console.log('rerouting');

    const callable = this.fireFunctions.httpsCallable(
      'stripeCreatePaymentLink',
    );

    // console.log(callable);
    callable({}).subscribe(
      (result) => {
        // Handle the result of your Cloud Function here
        //  console.log('Cloud Function response:', result);

        // Access the session URL from the result
        const sessionUrl = result as string;

        // Perform any action with sessionUrl, like redirection
        window.location.href =
          sessionUrl +
          '?prefilled_email=' +
          this.user.email +
          '&client_reference_id=' +
          this.user.id;
      },
      (error) => {
        // Handle errors if the Cloud Function call fails
        console.error('Cloud Function error:', error);
      },
    );
  }

  reRouteToMangement() {
    const portal = this.fireFunctions.httpsCallable(
      'stripeCreateCustomerPortal',
    );

    //console.log(portal);
    portal({ customerId: this.user.customerId }).subscribe(
      (result) => {
        // Handle the result of your Cloud Function here
        // console.log('Cloud Function response:', result);

        // Access the session URL from the result
        const sessionUrl = result as string;

        // Perform any action with sessionUrl, like redirection

        window.location.href = sessionUrl + '#';
      },
      (error) => {
        // Handle errors if the Cloud Function call fails
        console.error('Cloud Function error:', error);
      },
    );
  }

  checkSpotAvailability(quantity: number) {
    if (quantity && !this.user.clientIds) {
      return true;
    }

    if (this.user.clientIds && quantity >= this.user.clientIds?.length) {
      return true;
    } else {
      return false;
    }
  }

  checkEquality(quantity: number) {
    if (this.user.clientIds) {
      if (quantity <= this.user.clientIds.length) {
        return true;
      }
    }
    return false;
  }

  onCopyToClipboard(value: string) {
    this.toggleNotificationCopyToClipboard =
      !this.toggleNotificationCopyToClipboard;
    this.clipboardService.copyFromContent(value);
  }

  createRegLink(user: AppUser): string {
    user.registrationLinkId = this.db.createId();
    this.userService.saveUserData(this.user);

    return (
      window.location.protocol +
      '//' +
      window.location.host +
      '/register/' +
      user.registrationLinkId
    );
  }

  toggleCreateNewInviteLoadingModal() {
    this.toggleCreateNewInvite = !this.toggleCreateNewInvite;
  }

  inviteErrorNotification() {
    this.toggleNotification = !this.toggleNotification;
    this.notificationHeaderStr = 'ERROR';
    this.notificationBodyStr = 'There has been an error creating the invite...';
  }

  createRegistrationLink() {
    this.toggleCreateNewInviteLoadingModal();
    const invite: Invite = {
      id: this.db.createId(),
      creatorId: this.user.id as string,
      createdAt: new Date(),
      expiresAt: add(new Date(), { hours: 48 }),
    };

    this.inviteService.saveInvite(invite).subscribe({
      next: (result: { token: string }) => {
        if (result) {
          const regLink =
            window.location.protocol +
            '//' +
            window.location.host +
            '/register/' +
            result.token;
          this.toggleCreateNewInviteLoadingModal();
          this.onCopyToClipboard(regLink);
        } else {
          this.toggleCreateNewInviteLoadingModal();
          this.inviteErrorNotification();
          console.error('Error: No result received');
        }
      },
      error: (error: Error) => {
        this.toggleCreateNewInviteLoadingModal();
        this.inviteErrorNotification();
        console.error('Error in saving invite:', error);
      },
    });
  }

  createRegisterToken(invite: Invite) {
    const str = JSON.stringify([invite.creatorId, invite.id]);
    //do this with atob und btoa
    //

    console.log(str, btoa(str));
    // return btoa(str);
  }

  getRegLink(user: AppUser): string {
    return (
      window.location.protocol +
      '//' +
      window.location.host +
      '/register/' +
      user.registrationLinkId
    );
  }

  changeCopyToClipBoardToggle() {
    this.toggleNotificationCopyToClipboard =
      !this.toggleNotificationCopyToClipboard;
  }

  onCloseNotification() {
    this.toggleNotification = !this.toggleNotification;
  }
  onCloseModal(event: boolean) {
    if (event) {
      this.onCopyToClipboard(this.createRegLink(this.user));
    }

    this.toggleCreateNewRegId = !this.toggleCreateNewRegId;
  }

  createNewRegLink(user: AppUser) {
    this.toggleCreateNewRegId = !this.toggleCreateNewRegId;
  }

  addClientToCoachSpot(client: AppUser) {
    this.user.coachSpotId = client.id;
    this.newPendingClientDatabase.delete(client.id);
    client.coachId = this.user.id;
    Reflect.deleteProperty(client, 'registrationLinkId');
    Reflect.deleteProperty(client, 'pending');
    this.userService.deletePendingClientFormDatabase(client.id!);
    this.userService.saveUserData(client);
    this.userService.saveUserData(this.user);
    this.getCoachSpot(client.id as string);
  }

  dateHelper(date: Date) {
    return convertDateObject(date);
  }
}
